@charset "UTF-8";
/* components */
html, body, div, h1, h2, h3, h4, h5, h6, ul, li, table, tr, td, form, input, textarea, p, a, select, label, button,
header, footer, nav, main, aside {
  margin: 0;
  padding: 0;
  font-family: "SegoeUI", Helvetica, Tahoma;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #4b4b4b; }

ol, ul {
  list-style: none; }

a {
  text-decoration: none; }
  a:hover, a:active {
    color: black; }

header, footer, nav, main, aside {
  display: block; }

.clear {
  display: block;
  height: 0px;
  width: 100% !important;
  margin: 0;
  padding: 0;
  clear: both;
  line-height: 0px;
  font-size: 0px; }

img {
  border: none; }

fieldset {
  border: 0;
  padding: 0;
  margin: 0; }

input, button {
  outline: none; }

button {
  border: 0;
  cursor: pointer; }

/* JS SELECT */
.js-select-container {
  display: inline-block;
  position: relative; }
  .js-select-container .js-select-view p {
    overflow: hidden;
    white-space: nowrap; }
  .js-select-container .js-select-options-container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10; }
    .js-select-container .js-select-options-container button {
      display: block;
      border: 0;
      width: 100%;
      background: none;
      padding: 0;
      margin: 0;
      text-align: left;
      cursor: pointer; }
  .js-select-container .js-options-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    cursor: pointer; }
  .js-select-container select {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%; }

select.js-select {
  opacity: 0;
  filter: alpha(opacity=0);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)'; }

/* FILTER */
/* SIDEBAR */
/* LAYOUT */
/* CABINET */
/* DATEPICKER */
/* POPUP ACTIONS COL */
/* MOBILE ICON FILTER */
/* PLATE CARDS LIST */
/* FORM LAYOUT */
/* ANIMATION */
@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes popup-fade-in-margin {
  from {
    margin-top: 40px;
    opacity: 0; }
  to {
    margin-top: 0;
    opacity: 1; } }

@keyframes popup-background-fade-in {
  from {
    background: rgba(255, 255, 255, 0); }
  to {
    background: rgba(255, 255, 255, 0.7); } }

/*
@font-face {
    font-family: 'Segoe UI';
    src: url('/css/fonts/SegoeUI.eot');
    src: local('Segoe UI'), local('SegoeUI'),
        url('/css/fonts/SegoeUI.eot?#iefix') format('embedded-opentype'),
        url('/css/fonts/SegoeUI.woff') format('woff'),
        url('/css/fonts/SegoeUI.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('S/css/fonts/SegoeUI-SemiBold.eot');
    src: local('Segoe UI SemiBold'), local('SegoeUI-SemiBold'),
        url('/css/fonts/SegoeUI-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/css/fonts/SegoeUI-SemiBold.woff') format('woff'),
        url('/css/fonts/SegoeUI-SemiBold.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
*/
@font-face {
  font-family: "SegoeUI";
  src: url("/assets/css/fonts/SegoeGenerated/SegoeUI.eot");
  src: local("☺"), url("/assets/css/fonts/SegoeGenerated/SegoeUI.woff") format("woff"), url("/assets/css/fonts/SegoeGenerated/SegoeUI.ttf") format("truetype"), url("/assets/css/fonts/SegoeGenerated/SegoeUI.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "SegoeUI";
  src: url("/assets/css/fonts/SegoeGenerated/SegoeUI-SemiBold.eot");
  src: local("☺"), url("/assets/css/fonts/SegoeGenerated/SegoeUI-SemiBold.woff") format("woff"), url("/assets/css/fonts/SegoeGenerated/SegoeUI-SemiBold.ttf") format("truetype"), url("/assets/css/fonts/SegoeGenerated/SegoeUI-SemiBold.svg") format("svg");
  font-weight: 500;
  font-style: normal; }

html {
  min-height: 100%; }

/* STD */
.std-errors-list {
  text-align: center; }
  .std-errors-list .error-item {
    margin-bottom: 8px;
    color: #f32424; }
    .std-errors-list .error-item:last-child {
      margin-bottom: 0; }

/* PAGE LIMITER */
.page-limiter {
  position: relative;
  max-width: 1220px;
  margin: 100px auto 0 auto; }
  .page-limiter .page-body {
    flex: 1; }

/* AUTH PAGE */
.auth-float-container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -292px 0 0 -171px;
  width: 342px; }
  .auth-float-container .auth-logo {
    margin-bottom: 68px; }
  .auth-float-container .auth-form {
    margin-bottom: 91px; }
  .auth-float-container.unfloat {
    position: relative;
    top: 0;
    left: 0;
    margin: 20px auto !important; }

.auth-logo {
  width: 231px;
  height: 67px;
  margin: 0 auto;
  background: url(images/logo.png) no-repeat center center;
  background-size: contain; }

.auth-form .form-field {
  margin-bottom: 38px;
  width: 100%; }
  .auth-form .form-field .form-label {
    display: block;
    font-size: 17px;
    color: #8494a9; }
  .auth-form .form-field.field-type-colorpicker .form-label {
    margin-bottom: 8px; }
  .auth-form .form-field.field-type-files .form-label {
    display: none; }
  .auth-form .form-field .custom-checkput {
    position: relative;
    display: inline-block;
    width: auto;
    height: auto; }
    .auth-form .form-field .custom-checkput input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      filter: alpha(opacity=0);
      filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
    .auth-form .form-field .custom-checkput .checkput-icon {
      position: relative;
      display: inline-block;
      width: 18px;
      height: 18px;
      border: 2px solid #8494a9;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      border-radius: 3px;
      cursor: pointer; }
    .auth-form .form-field .custom-checkput.checked .checkput-icon {
      position: relative;
      display: inline-block;
      width: 22px;
      height: 22px;
      background: #81bc52;
      border: 0;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      border-radius: 3px; }
      .auth-form .form-field .custom-checkput.checked .checkput-icon::after {
        content: "";
        width: 16px;
        height: 12px;
        background: url(images/icon-check-white.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 7px;
        left: 4px;
        width: 13px;
        height: 9px; }
  .auth-form .form-field.field-type-checkbox .custom-checkput, .auth-form .form-field.field-type-agreement .custom-checkput, .auth-form .form-field.field-type-license-agreement .custom-checkput {
    float: left; }
  .auth-form .form-field.field-type-checkbox .form-label, .auth-form .form-field.field-type-agreement .form-label, .auth-form .form-field.field-type-license-agreement .form-label {
    margin-left: 34px;
    cursor: pointer; }
  .auth-form .form-field.field-type-agreement button, .auth-form .form-field.field-type-license-agreement button {
    display: block;
    margin-left: 34px;
    height: auto;
    line-height: 22px;
    font-size: 17px;
    color: #8494a9;
    text-decoration: underline;
    background: none; }
  .auth-form .form-field .form-field-password-generator button {
    color: #2f80ed;
    cursor: pointer;
    background: transparent; }
  .auth-form .form-field .form-field-password-check {
    line-height: 16px;
    font-size: 12px;
    position: relative;
    margin-top: -2px;
    z-index: 1; }
    .auth-form .form-field .form-field-password-check.active-red {
      width: 30%;
      border-top: 2px solid #F35E84;
      margin-bottom: 11px; }
    .auth-form .form-field .form-field-password-check.active-green {
      width: 100%;
      border-top: 2px solid #5A9C76;
      margin-bottom: 11px; }
    .auth-form .form-field .form-field-password-check.active-yellow {
      width: 70%;
      border-top: 2px solid #C99300;
      margin-bottom: 11px; }
    .auth-form .form-field .form-field-password-check .password-check-hint-green {
      color: #5A9C76; }
    .auth-form .form-field .form-field-password-check .password-check-hint-yellow {
      color: #C99300; }
    .auth-form .form-field .form-field-password-check .password-check-hint-red {
      color: #F35E84; }

@media screen and (max-width: 500px) {
  .auth-form .form-field {
    margin-bottom: 28px; }
    .auth-form .form-field .form-label {
      font-size: 14px;
      line-height: 14px; } }
  .auth-form .form-field .form-button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    width: 100%; }
    .auth-form .form-field .form-button .text,
    .auth-form .form-field .form-button .icon {
      display: inline-block;
      vertical-align: top; }
    .auth-form .form-field .form-button .icon {
      margin-right: 0; }
    .auth-form .form-field .form-button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .auth-form .form-field .form-button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .auth-form .form-field .form-button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .auth-form .form-field .form-button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .auth-form .form-field .form-button {
    font-weight: normal; } }
  .auth-form .form-field.field-error .form-label {
    color: #f32424; }
  .auth-form .form-field.field-error .form-control .form-input,
  .auth-form .form-field.field-error .form-control textarea,
  .auth-form .form-field.field-error .form-control select {
    border-color: #f32424; }
  .auth-form .form-field.field-error .form-field-error p {
    color: #f32424;
    white-space: pre-wrap; }

.auth-form .form-control {
  position: relative; }
  .auth-form .form-control .form-input,
  .auth-form .form-control textarea,
  .auth-form .form-control select {
    width: 100%;
    height: 34px;
    line-height: 34px;
    padding: 0;
    background: none;
    border: 0;
    border-bottom: 1px solid #8d76f0;
    font-size: 17px;
    outline: none; }
    .auth-form .form-control .form-input::-webkit-input-placeholder,
    .auth-form .form-control textarea::-webkit-input-placeholder,
    .auth-form .form-control select::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: rgba(132, 148, 169, 0.5); }
    .auth-form .form-control .form-input:-moz-placeholder,
    .auth-form .form-control textarea:-moz-placeholder,
    .auth-form .form-control select:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: rgba(132, 148, 169, 0.5);
      opacity: 1; }
    .auth-form .form-control .form-input::-moz-placeholder,
    .auth-form .form-control textarea::-moz-placeholder,
    .auth-form .form-control select::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: rgba(132, 148, 169, 0.5);
      opacity: 1; }
    .auth-form .form-control .form-input:-ms-input-placeholder,
    .auth-form .form-control textarea:-ms-input-placeholder,
    .auth-form .form-control select:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(132, 148, 169, 0.5); }
    .auth-form .form-control .form-input::-ms-input-placeholder,
    .auth-form .form-control textarea::-ms-input-placeholder,
    .auth-form .form-control select::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(132, 148, 169, 0.5); }
    .auth-form .form-control .form-input::placeholder,
    .auth-form .form-control textarea::placeholder,
    .auth-form .form-control select::placeholder {
      /* Most modern browsers support this now. */
      color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .auth-form .form-control .form-input,
  .auth-form .form-control textarea,
  .auth-form .form-control select {
    font-size: 16px; } }
  .auth-form .form-control textarea {
    resize: none;
    padding: 5px 0;
    line-height: 22px; }
  .auth-form .form-control select {
    cursor: pointer; }
  .auth-form .form-control .styled-select {
    position: relative; }
    .auth-form .form-control .styled-select select {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      filter: alpha(opacity=0);
      filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
    .auth-form .form-control .styled-select .select-button {
      position: relative;
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      width: 100%;
      height: auto;
      padding: 15px 30px;
      line-height: 20px;
      background: none;
      color: #4b4b4b;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      border-radius: 8px;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
      .auth-form .form-control .styled-select .select-button .text,
      .auth-form .form-control .styled-select .select-button .icon {
        display: inline-block;
        vertical-align: top; }
      .auth-form .form-control .styled-select .select-button .icon {
        margin-right: 0; }
      .auth-form .form-control .styled-select .select-button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .auth-form .form-control .styled-select .select-button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .auth-form .form-control .styled-select .select-button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .auth-form .form-control .styled-select .select-button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .auth-form .form-control .styled-select .select-button {
    font-weight: normal; } }
      .auth-form .form-control .styled-select .select-button .post-icon {
        position: relative;
        top: -1px;
        left: 8px;
        display: inline-block;
        width: 0; }
        .auth-form .form-control .styled-select .select-button .post-icon:after {
          content: "";
          width: 14px;
          height: 7px;
          background: url(images/arrow-bottom.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
      .auth-form .form-control .styled-select .select-button:hover {
        background: none;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
    .auth-form .form-control .styled-select .select-list {
      display: block;
      background: #fff;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border-radius: 10px;
      -webkit-transition: box-shadow 0.1s ease;
      -moz-transition: box-shadow 0.1s ease;
      -ms-transition: box-shadow 0.1s ease;
      -o-transition: box-shadow 0.1s ease;
      transition: box-shadow 0.1s ease;
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .auth-form .form-control .styled-select .select-list {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
      .auth-form .form-control .styled-select .select-list .select-list-button {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        position: relative;
        width: 100%;
        height: auto;
        padding: 15px 50px;
        line-height: 20px;
        background: none;
        color: #4b4b4b;
        font-weight: normal;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0; }
        .auth-form .form-control .styled-select .select-list .select-list-button .text,
        .auth-form .form-control .styled-select .select-list .select-list-button .icon {
          display: inline-block;
          vertical-align: top; }
        .auth-form .form-control .styled-select .select-list .select-list-button .icon {
          margin-right: 0; }
        .auth-form .form-control .styled-select .select-list .select-list-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .auth-form .form-control .styled-select .select-list .select-list-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .auth-form .form-control .styled-select .select-list .select-list-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .auth-form .form-control .styled-select .select-list .select-list-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .auth-form .form-control .styled-select .select-list .select-list-button {
    font-weight: normal; } }
        .auth-form .form-control .styled-select .select-list .select-list-button.active .pre-icon {
          position: relative;
          left: -26px;
          display: inline-block;
          width: 0; }
          .auth-form .form-control .styled-select .select-list .select-list-button.active .pre-icon:after {
            content: "";
            width: 16px;
            height: 12px;
            background: url(images/icon-check.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
        .auth-form .form-control .styled-select .select-list .select-list-button:after {
          content: "";
          display: block;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          height: 1px;
          background: rgba(0, 0, 0, 0.1);
          opacity: 0.5;
          filter: alpha(opacity=50);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .auth-form .form-control .styled-select .select-list .select-list-button:hover {
          background: #f2f5fb; }
        .auth-form .form-control .styled-select .select-list .select-list-button:first-child:after {
          left: 23px;
          right: 23px;
          opacity: 1;
          filter: alpha(opacity=100);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .auth-form .form-control .styled-select .select-list .select-list-button:last-child {
          margin-bottom: 40px; }
      .auth-form .form-control .styled-select .select-list .select-list-action {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        position: relative;
        width: 100%;
        height: auto;
        padding: 15px 23px;
        line-height: 20px;
        background: none;
        color: #4b4b4b;
        font-weight: normal;
        text-align: left;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0; }
        .auth-form .form-control .styled-select .select-list .select-list-action .text,
        .auth-form .form-control .styled-select .select-list .select-list-action .icon {
          display: inline-block;
          vertical-align: top; }
        .auth-form .form-control .styled-select .select-list .select-list-action .icon {
          margin-right: 0; }
        .auth-form .form-control .styled-select .select-list .select-list-action.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .auth-form .form-control .styled-select .select-list .select-list-action.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .auth-form .form-control .styled-select .select-list .select-list-action.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .auth-form .form-control .styled-select .select-list .select-list-action:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .auth-form .form-control .styled-select .select-list .select-list-action {
    font-weight: normal; } }
        .auth-form .form-control .styled-select .select-list .select-list-action:hover {
          background: #f2f5fb; }
      .auth-form .form-control .styled-select .select-list.show {
        display: block; }
    .auth-form .form-control .styled-select.show-list .select-button {
      opacity: 0;
      filter: alpha(opacity=0);
      filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
  .auth-form .form-control .search-select {
    position: relative; }
    .auth-form .form-control .search-select select {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      filter: alpha(opacity=0);
      filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
    .auth-form .form-control .search-select .search-field {
      position: relative;
      border-bottom: 1px solid #8d76f0; }
      .auth-form .form-control .search-select .search-field input {
        width: 100%;
        height: 34px;
        line-height: 34px;
        padding: 0;
        background: none;
        border: 0;
        border-bottom: 1px solid #8d76f0;
        font-size: 17px;
        outline: none;
        width: 90%;
        border-bottom: 0; }
        .auth-form .form-control .search-select .search-field input::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: rgba(132, 148, 169, 0.5); }
        .auth-form .form-control .search-select .search-field input:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .auth-form .form-control .search-select .search-field input::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .auth-form .form-control .search-select .search-field input:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba(132, 148, 169, 0.5); }
        .auth-form .form-control .search-select .search-field input::-ms-input-placeholder {
          /* Microsoft Edge */
          color: rgba(132, 148, 169, 0.5); }
        .auth-form .form-control .search-select .search-field input::placeholder {
          /* Most modern browsers support this now. */
          color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .auth-form .form-control .search-select .search-field input {
    font-size: 16px; } }
        .auth-form .form-control .search-select .search-field input::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: #4b4b4b; }
        .auth-form .form-control .search-select .search-field input:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #4b4b4b;
          opacity: 1; }
        .auth-form .form-control .search-select .search-field input::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #4b4b4b;
          opacity: 1; }
        .auth-form .form-control .search-select .search-field input:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #4b4b4b; }
        .auth-form .form-control .search-select .search-field input::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #4b4b4b; }
        .auth-form .form-control .search-select .search-field input::placeholder {
          /* Most modern browsers support this now. */
          color: #4b4b4b; }
      .auth-form .form-control .search-select .search-field .field-dropdown-icon {
        position: absolute;
        top: 7px;
        right: 21px;
        width: 0; }
        .auth-form .form-control .search-select .search-field .field-dropdown-icon:after {
          content: "";
          width: 14px;
          height: 7px;
          background: url(images/arrow-bottom.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
    .auth-form .form-control .search-select .select-list-container {
      display: block;
      background: #fff;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border-radius: 10px;
      -webkit-transition: box-shadow 0.1s ease;
      -moz-transition: box-shadow 0.1s ease;
      -ms-transition: box-shadow 0.1s ease;
      -o-transition: box-shadow 0.1s ease;
      transition: box-shadow 0.1s ease;
      display: none;
      position: absolute;
      top: -17px;
      left: -17px;
      right: -17px;
      padding-top: 60px;
      padding-bottom: 17px;
      z-index: 1;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .auth-form .form-control .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
    .auth-form .form-control .search-select .select-list {
      position: relative;
      max-height: 200px;
      overflow: auto; }
      .auth-form .form-control .search-select .select-list .select-list-button {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px 17px;
        line-height: 20px;
        background: none;
        color: #4b4b4b;
        font-weight: normal;
        text-align: left;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0; }
        .auth-form .form-control .search-select .select-list .select-list-button .text,
        .auth-form .form-control .search-select .select-list .select-list-button .icon {
          display: inline-block;
          vertical-align: top; }
        .auth-form .form-control .search-select .select-list .select-list-button .icon {
          margin-right: 0; }
        .auth-form .form-control .search-select .select-list .select-list-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .auth-form .form-control .search-select .select-list .select-list-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .auth-form .form-control .search-select .select-list .select-list-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .auth-form .form-control .search-select .select-list .select-list-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .auth-form .form-control .search-select .select-list .select-list-button {
    font-weight: normal; } }
        .auth-form .form-control .search-select .select-list .select-list-button.active {
          background: #f2f5fb; }
        .auth-form .form-control .search-select .select-list .select-list-button.selected {
          background: #f2f5fb; }
        .auth-form .form-control .search-select .select-list .select-list-button:hover {
          background: #f2f5fb; }
      .auth-form .form-control .search-select .select-list .select-list-action {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px 17px;
        line-height: 20px;
        background: none;
        color: #4b4b4b;
        font-weight: normal;
        text-align: left;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0; }
        .auth-form .form-control .search-select .select-list .select-list-action .text,
        .auth-form .form-control .search-select .select-list .select-list-action .icon {
          display: inline-block;
          vertical-align: top; }
        .auth-form .form-control .search-select .select-list .select-list-action .icon {
          margin-right: 0; }
        .auth-form .form-control .search-select .select-list .select-list-action.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .auth-form .form-control .search-select .select-list .select-list-action.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .auth-form .form-control .search-select .select-list .select-list-action.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .auth-form .form-control .search-select .select-list .select-list-action:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .auth-form .form-control .search-select .select-list .select-list-action {
    font-weight: normal; } }
        .auth-form .form-control .search-select .select-list .select-list-action:hover {
          background: #f2f5fb; }
    .auth-form .form-control .search-select.show-list .select-list-container {
      display: block; }
    .auth-form .form-control .search-select.show-list .search-field {
      z-index: 1;
      border-bottom-color: rgba(0, 0, 0, 0.1); }
  .auth-form .form-control .field-price-input-container {
    position: relative;
    width: 100%; }
    .auth-form .form-control .field-price-input-container .unit-box {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      text-align: center;
      white-space: nowrap; }
      .auth-form .form-control .field-price-input-container .unit-box span {
        display: inline-block; }
      .auth-form .form-control .field-price-input-container .unit-box .unit-background-text {
        color: transparent;
        font-size: 38px;
        max-width: 95%;
        overflow: hidden; }
      .auth-form .form-control .field-price-input-container .unit-box .unit-background-text-space {
        width: 24px; }
    .auth-form .form-control .field-price-input-container input {
      position: relative;
      height: 50px;
      line-height: 50px;
      box-sizing: border-box;
      text-align: center;
      font-size: 38px; }
    .auth-form .form-control .field-price-input-container .unit-ruble {
      line-height: 52px;
      font-size: 32px;
      color: #8494a9;
      opacity: 0.7;
      filter: alpha(opacity=70);
      filter: progid:DXImageTransform.Microsoft.Alpha Opacity=70;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .auth-form .form-control .field-price-input-container .unit-ruble:after {
        content: "\20bd"; }
  .auth-form .form-control .colorpicker {
    position: relative; }
    .auth-form .form-control .colorpicker .picker-button {
      display: block;
      width: 105px;
      height: 30px;
      background: none;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .auth-form .form-control .colorpicker .picker-palette {
      display: block;
      background: #fff;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border-radius: 10px;
      -webkit-transition: box-shadow 0.1s ease;
      -moz-transition: box-shadow 0.1s ease;
      -ms-transition: box-shadow 0.1s ease;
      -o-transition: box-shadow 0.1s ease;
      transition: box-shadow 0.1s ease;
      position: absolute;
      left: 0;
      bottom: -200px;
      width: 504px;
      padding: 32px 20px;
      z-index: 4;
      -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 450px) {
  .auth-form .form-control .colorpicker .picker-palette {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
      .auth-form .form-control .colorpicker .picker-palette .palette-header {
        margin-bottom: 40px;
        margin-left: 10px;
        font-size: 22px; }
      .auth-form .form-control .colorpicker .picker-palette .palette-color-buttons {
        margin-bottom: 12px; }
      .auth-form .form-control .colorpicker .picker-palette .palette-color-button {
        display: inline-block;
        width: 60px;
        height: 60px;
        margin: 0 12px 24px 12px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%; }
        .auth-form .form-control .colorpicker .picker-palette .palette-color-button.active, .auth-form .form-control .colorpicker .picker-palette .palette-color-button:hover {
          margin: -10px 2px 14px 2px;
          width: 80px;
          height: 80px;
          border: 10px solid #f2f5fb; }
      .auth-form .form-control .colorpicker .picker-palette .palette-corner-close-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 80px;
        height: 80px;
        vertical-align: middle;
        text-align: center;
        font-size: 38px;
        font-family: "times new roman", verdana;
        background: none; }
      .auth-form .form-control .colorpicker .picker-palette .palette-bottom-buttons {
        text-align: right; }
        .auth-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          background: none;
          border: 1px solid #4b4b4b;
          color: #4b4b4b;
          font-size: 16px;
          min-width: 191px; }
          .auth-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
          .auth-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
            display: inline-block;
            vertical-align: top; }
          .auth-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
            margin-right: 0; }
          .auth-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .auth-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .auth-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .auth-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .auth-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
    font-weight: normal; } }
          .auth-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
            background: #f2f5fb; }
    .auth-form .form-control .colorpicker .picker-palette-overlay {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      z-index: 2; }
  .auth-form .form-control .form-autocomplete-input {
    position: relative; }
    .auth-form .form-control .form-autocomplete-input .form-autocomplete-dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: 300px;
      background: #fff;
      -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
      -ms-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
      -o-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
      z-index: 1;
      overflow: auto; }
      .auth-form .form-control .form-autocomplete-input .form-autocomplete-dropdown button {
        background: none;
        border: none;
        width: 100%;
        padding: 8px 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        text-align: left; }
        .auth-form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover {
          background: #f2f5fb; }
        .auth-form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child {
          border-bottom: 0; }
  .auth-form .form-control .field-text-list-container .text-list-item {
    position: relative;
    margin-bottom: 10px; }
    .auth-form .form-control .field-text-list-container .text-list-item .remove-text-item {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b;
      position: absolute;
      top: 0;
      right: 0;
      color: #f32424;
      font-size: 22px; }
      .auth-form .form-control .field-text-list-container .text-list-item .remove-text-item:hover {
        color: black; }
  .auth-form .form-control .field-text-list-container .add-text-item {
    background: none;
    border: none;
    cursor: pointer;
    color: #4b4b4b; }
    .auth-form .form-control .field-text-list-container .add-text-item:hover {
      color: black; }
  .auth-form .form-control .files-loader-container .files-loader-input-container {
    margin-bottom: 20px; }
  .auth-form .form-control .files-loader-input-container {
    position: relative; }
    .auth-form .form-control .files-loader-input-container .input-label-area {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: none;
      border: 1px solid #4b4b4b;
      color: #4b4b4b;
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px;
      width: 100%;
      height: 60px;
      line-height: 60px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      border-radius: 8px; }
      .auth-form .form-control .files-loader-input-container .input-label-area .text,
      .auth-form .form-control .files-loader-input-container .input-label-area .icon {
        display: inline-block;
        vertical-align: top; }
      .auth-form .form-control .files-loader-input-container .input-label-area .icon {
        margin-right: 0; }
      .auth-form .form-control .files-loader-input-container .input-label-area.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .auth-form .form-control .files-loader-input-container .input-label-area.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .auth-form .form-control .files-loader-input-container .input-label-area.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .auth-form .form-control .files-loader-input-container .input-label-area:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .auth-form .form-control .files-loader-input-container .input-label-area {
    font-weight: normal; } }
      .auth-form .form-control .files-loader-input-container .input-label-area:hover {
        background: #f2f5fb; }
    .auth-form .form-control .files-loader-input-container input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0);
      filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
    .auth-form .form-control .files-loader-input-container:hover .input-label-area {
      background: #f2f5fb; }
  .auth-form .form-control .files-loader-list .files-list-item {
    display: flex;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 10px; }
    .auth-form .form-control .files-loader-list .files-list-item .list-item-col {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .auth-form .form-control .files-loader-list .files-list-item .list-item-col.icon {
        width: 38px;
        flex-shrink: 0; }
      .auth-form .form-control .files-loader-list .files-list-item .list-item-col.caption {
        margin-right: auto;
        margin-left: 18px; }
      .auth-form .form-control .files-loader-list .files-list-item .list-item-col.price {
        flex-shrink: 0;
        text-align: right; }
      .auth-form .form-control .files-loader-list .files-list-item .list-item-col .note {
        font-size: 12px;
        color: #8494a9; }
    .auth-form .form-control .files-loader-list .files-list-item .icon {
      display: inline-block;
      width: 38px;
      height: 38px;
      line-height: 37px;
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      color: #fff;
      background: #bed6f9;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      border-radius: 50%;
      font-size: 12px;
      font-weight: normal; }
      .auth-form .form-control .files-loader-list .files-list-item .icon.icon-sub-correction {
        line-height: 34.2px; }
      .auth-form .form-control .files-loader-list .files-list-item .icon.color-1 {
        background: #bed6f9; }
      .auth-form .form-control .files-loader-list .files-list-item .icon.color-2 {
        background: #b9e398; }
      .auth-form .form-control .files-loader-list .files-list-item .icon.color-3 {
        background: #e4e597; }
      .auth-form .form-control .files-loader-list .files-list-item .icon.color-4 {
        background: #e7c740; }
      .auth-form .form-control .files-loader-list .files-list-item .icon.color-5 {
        background: #e74040; }
      .auth-form .form-control .files-loader-list .files-list-item .icon.color-6 {
        background: #cf91af; }
    .auth-form .form-control .files-loader-list .files-list-item .caption {
      height: 38px;
      line-height: 38px;
      margin-left: 12px; }
    .auth-form .form-control .files-loader-list .files-list-item .remove {
      flex-shrink: 0;
      margin-left: 0; }
      .auth-form .form-control .files-loader-list .files-list-item .remove button {
        background: none;
        border: none;
        height: 38px;
        line-height: 38px;
        font-size: 22px;
        color: #f32424; }
  .auth-form .form-control .select-container .note {
    position: relative;
    padding-left: 25px;
    padding-top: 6px;
    font-size: 14px;
    color: #8494a9;
    line-height: 14px; }
    .auth-form .form-control .select-container .note:before {
      content: "i";
      position: absolute;
      top: 5px;
      left: 0;
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 10px;
      line-height: 14px;
      border: 1px solid #8494a9;
      border-radius: 50%;
      text-align: center; }
  .auth-form .form-control .input-phone {
    display: flex; }
    .auth-form .form-control .input-phone .phone-country-code .styled-select {
      position: inherit; }
      .auth-form .form-control .input-phone .phone-country-code .styled-select .select-button {
        padding: 0;
        width: 80px;
        text-align: left;
        box-shadow: none;
        line-height: 34px;
        border-bottom: 1px solid #8d76f0;
        border-radius: 0; }
        .auth-form .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after {
          width: 8px; }
      .auth-form .form-control .input-phone .phone-country-code .styled-select .select-list {
        top: 100%;
        width: 100%;
        max-height: 200px;
        margin-top: 10px;
        overflow: auto; }
        .auth-form .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button {
          text-align: left; }
      .auth-form .form-control .input-phone .phone-country-code .styled-select select {
        width: 80px; }
      .auth-form .form-control .input-phone .phone-country-code .styled-select.show-list .select-button {
        opacity: 1;
        filter: alpha(opacity=100);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
    .auth-form .form-control .input-phone .phone-number {
      flex: 1; }
  .auth-form .form-control .password-input {
    position: relative; }
    .auth-form .form-control .password-input .eye-icon {
      font-size: 18px;
      background-color: transparent;
      color: #8494a9;
      position: absolute;
      right: 0; }

@media screen and (max-width: 500px) {
  .auth-form .form-control .search-select .search-field .field-dropdown-icon {
    top: 3px; } }
  .auth-form .form-control .datepicker-popup {
    position: absolute;
    top: 100%;
    left: 0; }

.auth-form .form-errors-list {
  margin-bottom: 24px; }
  .auth-form .form-errors-list .error-item {
    margin-bottom: 8px;
    color: #f32424; }
    .auth-form .form-errors-list .error-item:last-child {
      margin-bottom: 0; }

.auth-form .form-messages-list {
  margin-bottom: 24px;
  text-align: center; }
  .auth-form .form-messages-list .message-item {
    margin-bottom: 8px;
    color: #4dbd7d;
    font-size: 17px;
    line-height: 22px; }
    .auth-form .form-messages-list .message-item:last-child {
      margin-bottom: 0; }

.auth-form .form-fieldset {
  margin-bottom: 78px; }

.auth-form .form-field input {
  font-size: 22px; }

.auth-form .form-field .password-input .eye-icon {
  top: 20px; }

.auth-form .form-field button {
  height: 0;
  line-height: 0; }

.auth-form button {
  height: 60px;
  line-height: 58px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px; }

.auth-links {
  text-align: center; }
  .auth-links .auth-link {
    margin-bottom: 38px; }
    .auth-links .auth-link a,
    .auth-links .auth-link button {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b; }
      .auth-links .auth-link a:hover,
      .auth-links .auth-link button:hover {
        color: black; }

.accent-page .auth-float-container {
  width: 578px;
  padding: 76px 0 36px 0;
  margin: -320px 0 0 -289px;
  background: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px; }
  .accent-page .auth-float-container .auth-form {
    max-width: 342px;
    margin: 0 auto; }
  .accent-page .auth-float-container .auth-link {
    margin-bottom: 20px; }

.auth-form .form-fieldset .form-field, .settings-form .form-fieldset .form-field {
  position: relative; }
  .auth-form .form-fieldset .form-field .popup-info, .settings-form .form-fieldset .form-field .popup-info {
    position: absolute;
    right: 0;
    top: 3px;
    width: 18px;
    height: 18px;
    background: url(images/icon-info.png) no-repeat center center;
    display: inline-block;
    background-size: contain; }
  .auth-form .form-fieldset .form-field .popup-hint, .settings-form .form-fieldset .form-field .popup-hint {
    width: 352px;
    padding: 56px 24px 24px 24px;
    position: absolute;
    right: -410px;
    top: -70px;
    background: #fff;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    border-radius: 12px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
    .auth-form .form-fieldset .form-field .popup-hint .popup-hint-container, .settings-form .form-fieldset .form-field .popup-hint .popup-hint-container {
      position: relative; }
      .auth-form .form-fieldset .form-field .popup-hint .popup-hint-container .popup-hint-annotation, .settings-form .form-fieldset .form-field .popup-hint .popup-hint-container .popup-hint-annotation {
        color: #8694AA; }
      .auth-form .form-fieldset .form-field .popup-hint .popup-hint-container .popup-hint-icon, .settings-form .form-fieldset .form-field .popup-hint .popup-hint-container .popup-hint-icon {
        width: 18px;
        height: 18px;
        background: url(images/icon-info.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: -30px; }
      .auth-form .form-fieldset .form-field .popup-hint .popup-hint-container .close-button, .settings-form .form-fieldset .form-field .popup-hint .popup-hint-container .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        width: 18px;
        height: 18px;
        top: -30px;
        right: 0; }

/* DASHBOARD */
.dashboard {
  padding-bottom: 24px;
  background: #f2f2f2; }

/* PAGE HEADER */
.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  background: #fff;
  z-index: 10;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
  .page-header .page-header-limiter {
    position: relative;
    display: flex;
    max-width: 1220px;
    height: 100%;
    margin: 0 auto; }
  .page-header .page-header-logo {
    width: 240px;
    margin-right: 18px; }
    .page-header .page-header-logo .logo {
      display: inline-block; }
  .page-header .page-header-center {
    position: relative;
    height: 100%; }
    .page-header .page-header-center .page-header-menu {
      margin-right: 80px; }
  .page-header .page-header-userbar {
    text-align: center;
    margin-right: -20px; }
    .page-header .page-header-userbar .userbar-button .icon-close {
      line-height: 56px; }
    .page-header .page-header-userbar .userbar-dropdown-container {
      height: 100%; }
    .page-header .page-header-userbar .userbar-dropdown {
      padding-top: 76px; }
  .page-header .page-header-feedback-bar {
    margin-left: auto;
    text-align: center;
    margin-right: -10px; }
    .page-header .page-header-feedback-bar .feedback-bar-button .icon-close {
      line-height: 56px; }
    .page-header .page-header-feedback-bar .feedback-bar-dropdown-container {
      height: 100%; }
    .page-header .page-header-feedback-bar .feedback-bar-dropdown {
      padding-top: 46px; }

.page-header-center {
  display: flex;
  justify-content: space-between; }

.page-header-logo .logo {
  width: 115px;
  height: 33px;
  background: url(images/logo.png) no-repeat center center;
  display: inline-block;
  background-size: contain;
  margin-top: 14px; }

.page-header-menu {
  font-size: 0; }
  .page-header-menu a,
  .page-header-menu button {
    background: none;
    border: none;
    cursor: pointer;
    color: #4b4b4b;
    position: relative;
    height: 100%;
    margin-right: 46px;
    font-size: 16px;
    line-height: 30px; }
    .page-header-menu a:hover,
    .page-header-menu button:hover {
      color: black; }
  .page-header-menu .active {
    color: #4b4b4b; }
    .page-header-menu .active:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background: linear-gradient(73.01deg, #e9e122 8.78%, #f64399 113.32%), #956dbb; }

.page-header-menu-button {
  position: relative;
  top: -2px;
  display: none;
  background: none;
  padding: 0 24px;
  margin-left: auto;
  color: #4b4b4b;
  font-size: 22px;
  font-weight: 500; }

.page-header-mobile-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 10;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }
  .page-header-mobile-menu .menu-container {
    position: relative;
    display: flex;
    background: #fff; }
    .page-header-mobile-menu .menu-container button {
      background: none;
      border: none;
      flex: 1;
      display: flex;
      height: 50px;
      text-align: center;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #8494a9; }
      .page-header-mobile-menu .menu-container button .item-label {
        display: block;
        font-size: 10px;
        line-height: 12px; }
      .page-header-mobile-menu .menu-container button .item-icon-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 30px; }
      .page-header-mobile-menu .menu-container button.name-home .item-icon {
        width: 31px;
        height: 26px;
        background: url(images/icon-home-mobile.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .menu-container button.name-transactions .item-icon {
        width: 35px;
        height: 25px;
        background: url(images/icon-transactions-mobile.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .menu-container button.name-analytics .item-icon {
        width: 29px;
        height: 29px;
        background: url(images/icon-analytics-mobile.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .menu-container button.name-investments .item-icon {
        width: 35px;
        height: 25px;
        background: url(images/icon-investments-mobile.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .menu-container button.name-account .item-icon {
        width: 29px;
        height: 29px;
        background: url(images/icon-account-mobile.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .menu-container button.name-targets .item-icon {
        width: 28px;
        height: 28px;
        background: url(images/icon-targets-mobile.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .menu-container button.active {
        color: #4b4b4b; }
        .page-header-mobile-menu .menu-container button.active .item-icon {
          -webkit-filter: invert(50%) sepia(54%) saturate(2041%) hue-rotate(210deg) brightness(100%) contrast(95%);
          filter: invert(50%) sepia(54%) saturate(2041%) hue-rotate(210deg) brightness(100%) contrast(95%); }
  .page-header-mobile-menu .userbar-dropdown {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -500%;
    padding: 10px 0;
    background: #fff;
    -webkit-box-shadow: 0 -9px 6px -3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 -9px 6px -3px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 -9px 6px -3px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 -9px 6px -3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 -9px 6px -3px rgba(0, 0, 0, 0.1);
    -webkit-transition: bottom 0.5s ease;
    -moz-transition: bottom 0.5s ease;
    -ms-transition: bottom 0.5s ease;
    -o-transition: bottom 0.5s ease;
    transition: bottom 0.5s ease; }
    .page-header-mobile-menu .userbar-dropdown button {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b;
      display: block;
      position: relative;
      font-size: 15px;
      font-weight: 500;
      text-align: left;
      box-sizing: border-box;
      width: 100%;
      padding: 10px 20px;
      font-weight: normal;
      align-items: start; }
      .page-header-mobile-menu .userbar-dropdown button:hover {
        color: black; }
      .page-header-mobile-menu .userbar-dropdown button .icon {
        position: absolute;
        top: 0;
        left: 0; }
      .page-header-mobile-menu .userbar-dropdown button .icon-about {
        width: 22px;
        height: 23px;
        background: url(images/icon-about-black.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .userbar-dropdown button .icon-companies,
      .page-header-mobile-menu .userbar-dropdown button .icon-categories {
        width: 21px;
        height: 22px;
        background: url(images/icon-companies-black.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .userbar-dropdown button .icon-settings,
      .page-header-mobile-menu .userbar-dropdown button .icon-owners,
      .page-header-mobile-menu .userbar-dropdown button .icon-workers,
      .page-header-mobile-menu .userbar-dropdown button .icon-partners {
        width: 22px;
        height: 22px;
        background: url(images/icon-profile-black.svg) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .userbar-dropdown button .icon-license,
      .page-header-mobile-menu .userbar-dropdown button .icon-accounts {
        width: 22px;
        height: 22px;
        background: url(images/icon-ruble-black.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .userbar-dropdown button .icon-logout {
        top: 2px;
        width: 21px;
        height: 19px;
        background: url(images/icon-logout-black.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .userbar-dropdown button .icon-feedback {
        width: 22px;
        height: 22px;
        background: url(images/icon-edit-black.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .userbar-dropdown button .icon-telegram {
        width: 22px;
        height: 22px;
        background: url(images/icon-telegram.svg) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .userbar-dropdown button .icon-whatsapp {
        width: 22px;
        height: 22px;
        background: url(images/icon-whatsapp.svg) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .userbar-dropdown button .text {
        display: block;
        margin-left: 34px; }
      .page-header-mobile-menu .userbar-dropdown button.button-telegram {
        color: #47aaed; }
      .page-header-mobile-menu .userbar-dropdown button.button-whatsapp {
        color: #4cba3f; }
      .page-header-mobile-menu .userbar-dropdown button .icon-xlsx {
        width: 22px;
        height: 22px;
        background: url(images/icon-xlsx.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .userbar-dropdown button .icon-pdf {
        width: 22px;
        height: 22px;
        background: url(images/icon-pdf.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .userbar-dropdown button .icon-word {
        width: 22px;
        height: 22px;
        background: url(images/icon-word.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .userbar-dropdown button .icon-print {
        width: 23px;
        height: 16px;
        background: url(images/icon-print.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .page-header-mobile-menu .userbar-dropdown button .icon {
        top: 50%;
        left: 20px;
        margin: -10px 0 0 0; }
  .page-header-mobile-menu.userbar-dropdown-opened .userbar-dropdown {
    bottom: 100%; }

.page-header-search {
  position: relative;
  top: 6px; }
  .page-header-search .field-input {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 24px;
    width: 100%;
    height: 40px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px; }
    .page-header-search .field-input .form-control {
      position: relative; }
      .page-header-search .field-input .form-control .form-input,
      .page-header-search .field-input .form-control textarea,
      .page-header-search .field-input .form-control select {
        width: 100%;
        height: 34px;
        line-height: 34px;
        padding: 0;
        background: none;
        border: 0;
        border-bottom: 1px solid #8d76f0;
        font-size: 17px;
        outline: none; }
        .page-header-search .field-input .form-control .form-input::-webkit-input-placeholder,
        .page-header-search .field-input .form-control textarea::-webkit-input-placeholder,
        .page-header-search .field-input .form-control select::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: rgba(132, 148, 169, 0.5); }
        .page-header-search .field-input .form-control .form-input:-moz-placeholder,
        .page-header-search .field-input .form-control textarea:-moz-placeholder,
        .page-header-search .field-input .form-control select:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .page-header-search .field-input .form-control .form-input::-moz-placeholder,
        .page-header-search .field-input .form-control textarea::-moz-placeholder,
        .page-header-search .field-input .form-control select::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .page-header-search .field-input .form-control .form-input:-ms-input-placeholder,
        .page-header-search .field-input .form-control textarea:-ms-input-placeholder,
        .page-header-search .field-input .form-control select:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba(132, 148, 169, 0.5); }
        .page-header-search .field-input .form-control .form-input::-ms-input-placeholder,
        .page-header-search .field-input .form-control textarea::-ms-input-placeholder,
        .page-header-search .field-input .form-control select::-ms-input-placeholder {
          /* Microsoft Edge */
          color: rgba(132, 148, 169, 0.5); }
        .page-header-search .field-input .form-control .form-input::placeholder,
        .page-header-search .field-input .form-control textarea::placeholder,
        .page-header-search .field-input .form-control select::placeholder {
          /* Most modern browsers support this now. */
          color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .page-header-search .field-input .form-control .form-input,
  .page-header-search .field-input .form-control textarea,
  .page-header-search .field-input .form-control select {
    font-size: 16px; } }
      .page-header-search .field-input .form-control textarea {
        resize: none;
        padding: 5px 0;
        line-height: 22px; }
      .page-header-search .field-input .form-control select {
        cursor: pointer; }
      .page-header-search .field-input .form-control .styled-select {
        position: relative; }
        .page-header-search .field-input .form-control .styled-select select {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .page-header-search .field-input .form-control .styled-select .select-button {
          position: relative;
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          width: 100%;
          height: auto;
          padding: 15px 30px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          border-radius: 8px;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
          .page-header-search .field-input .form-control .styled-select .select-button .text,
          .page-header-search .field-input .form-control .styled-select .select-button .icon {
            display: inline-block;
            vertical-align: top; }
          .page-header-search .field-input .form-control .styled-select .select-button .icon {
            margin-right: 0; }
          .page-header-search .field-input .form-control .styled-select .select-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .page-header-search .field-input .form-control .styled-select .select-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .page-header-search .field-input .form-control .styled-select .select-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .page-header-search .field-input .form-control .styled-select .select-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .page-header-search .field-input .form-control .styled-select .select-button {
    font-weight: normal; } }
          .page-header-search .field-input .form-control .styled-select .select-button .post-icon {
            position: relative;
            top: -1px;
            left: 8px;
            display: inline-block;
            width: 0; }
            .page-header-search .field-input .form-control .styled-select .select-button .post-icon:after {
              content: "";
              width: 14px;
              height: 7px;
              background: url(images/arrow-bottom.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
          .page-header-search .field-input .form-control .styled-select .select-button:hover {
            background: none;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
        .page-header-search .field-input .form-control .styled-select .select-list {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 1;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .page-header-search .field-input .form-control .styled-select .select-list {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
          .page-header-search .field-input .form-control .styled-select .select-list .select-list-button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 15px 50px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-button .text,
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-button .icon {
              display: inline-block;
              vertical-align: top; }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-button .icon {
              margin-right: 0; }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .page-header-search .field-input .form-control .styled-select .select-list .select-list-button {
    font-weight: normal; } }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-button.active .pre-icon {
              position: relative;
              left: -26px;
              display: inline-block;
              width: 0; }
              .page-header-search .field-input .form-control .styled-select .select-list .select-list-button.active .pre-icon:after {
                content: "";
                width: 16px;
                height: 12px;
                background: url(images/icon-check.png) no-repeat center center;
                display: inline-block;
                background-size: contain; }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-button:after {
              content: "";
              display: block;
              position: absolute;
              top: 100%;
              left: 0;
              right: 0;
              height: 1px;
              background: rgba(0, 0, 0, 0.1);
              opacity: 0.5;
              filter: alpha(opacity=50);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-button:hover {
              background: #f2f5fb; }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-button:first-child:after {
              left: 23px;
              right: 23px;
              opacity: 1;
              filter: alpha(opacity=100);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-button:last-child {
              margin-bottom: 40px; }
          .page-header-search .field-input .form-control .styled-select .select-list .select-list-action {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 15px 23px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-action .text,
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-action .icon {
              display: inline-block;
              vertical-align: top; }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-action .icon {
              margin-right: 0; }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-action.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-action.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-action.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-action:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .page-header-search .field-input .form-control .styled-select .select-list .select-list-action {
    font-weight: normal; } }
            .page-header-search .field-input .form-control .styled-select .select-list .select-list-action:hover {
              background: #f2f5fb; }
          .page-header-search .field-input .form-control .styled-select .select-list.show {
            display: block; }
        .page-header-search .field-input .form-control .styled-select.show-list .select-button {
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .page-header-search .field-input .form-control .search-select {
        position: relative; }
        .page-header-search .field-input .form-control .search-select select {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .page-header-search .field-input .form-control .search-select .search-field {
          position: relative;
          border-bottom: 1px solid #8d76f0; }
          .page-header-search .field-input .form-control .search-select .search-field input {
            width: 100%;
            height: 34px;
            line-height: 34px;
            padding: 0;
            background: none;
            border: 0;
            border-bottom: 1px solid #8d76f0;
            font-size: 17px;
            outline: none;
            width: 90%;
            border-bottom: 0; }
            .page-header-search .field-input .form-control .search-select .search-field input::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: rgba(132, 148, 169, 0.5); }
            .page-header-search .field-input .form-control .search-select .search-field input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .page-header-search .field-input .form-control .search-select .search-field input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .page-header-search .field-input .form-control .search-select .search-field input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: rgba(132, 148, 169, 0.5); }
            .page-header-search .field-input .form-control .search-select .search-field input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: rgba(132, 148, 169, 0.5); }
            .page-header-search .field-input .form-control .search-select .search-field input::placeholder {
              /* Most modern browsers support this now. */
              color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .page-header-search .field-input .form-control .search-select .search-field input {
    font-size: 16px; } }
            .page-header-search .field-input .form-control .search-select .search-field input::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: #4b4b4b; }
            .page-header-search .field-input .form-control .search-select .search-field input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: #4b4b4b;
              opacity: 1; }
            .page-header-search .field-input .form-control .search-select .search-field input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: #4b4b4b;
              opacity: 1; }
            .page-header-search .field-input .form-control .search-select .search-field input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #4b4b4b; }
            .page-header-search .field-input .form-control .search-select .search-field input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: #4b4b4b; }
            .page-header-search .field-input .form-control .search-select .search-field input::placeholder {
              /* Most modern browsers support this now. */
              color: #4b4b4b; }
          .page-header-search .field-input .form-control .search-select .search-field .field-dropdown-icon {
            position: absolute;
            top: 7px;
            right: 21px;
            width: 0; }
            .page-header-search .field-input .form-control .search-select .search-field .field-dropdown-icon:after {
              content: "";
              width: 14px;
              height: 7px;
              background: url(images/arrow-bottom.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
        .page-header-search .field-input .form-control .search-select .select-list-container {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          display: none;
          position: absolute;
          top: -17px;
          left: -17px;
          right: -17px;
          padding-top: 60px;
          padding-bottom: 17px;
          z-index: 1;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .page-header-search .field-input .form-control .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
        .page-header-search .field-input .form-control .search-select .select-list {
          position: relative;
          max-height: 200px;
          overflow: auto; }
          .page-header-search .field-input .form-control .search-select .select-list .select-list-button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 10px 17px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .page-header-search .field-input .form-control .search-select .select-list .select-list-button .text,
            .page-header-search .field-input .form-control .search-select .select-list .select-list-button .icon {
              display: inline-block;
              vertical-align: top; }
            .page-header-search .field-input .form-control .search-select .select-list .select-list-button .icon {
              margin-right: 0; }
            .page-header-search .field-input .form-control .search-select .select-list .select-list-button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .page-header-search .field-input .form-control .search-select .select-list .select-list-button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .page-header-search .field-input .form-control .search-select .select-list .select-list-button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .page-header-search .field-input .form-control .search-select .select-list .select-list-button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .page-header-search .field-input .form-control .search-select .select-list .select-list-button {
    font-weight: normal; } }
            .page-header-search .field-input .form-control .search-select .select-list .select-list-button.active {
              background: #f2f5fb; }
            .page-header-search .field-input .form-control .search-select .select-list .select-list-button.selected {
              background: #f2f5fb; }
            .page-header-search .field-input .form-control .search-select .select-list .select-list-button:hover {
              background: #f2f5fb; }
          .page-header-search .field-input .form-control .search-select .select-list .select-list-action {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 10px 17px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .page-header-search .field-input .form-control .search-select .select-list .select-list-action .text,
            .page-header-search .field-input .form-control .search-select .select-list .select-list-action .icon {
              display: inline-block;
              vertical-align: top; }
            .page-header-search .field-input .form-control .search-select .select-list .select-list-action .icon {
              margin-right: 0; }
            .page-header-search .field-input .form-control .search-select .select-list .select-list-action.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .page-header-search .field-input .form-control .search-select .select-list .select-list-action.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .page-header-search .field-input .form-control .search-select .select-list .select-list-action.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .page-header-search .field-input .form-control .search-select .select-list .select-list-action:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .page-header-search .field-input .form-control .search-select .select-list .select-list-action {
    font-weight: normal; } }
            .page-header-search .field-input .form-control .search-select .select-list .select-list-action:hover {
              background: #f2f5fb; }
        .page-header-search .field-input .form-control .search-select.show-list .select-list-container {
          display: block; }
        .page-header-search .field-input .form-control .search-select.show-list .search-field {
          z-index: 1;
          border-bottom-color: rgba(0, 0, 0, 0.1); }
      .page-header-search .field-input .form-control .field-price-input-container {
        position: relative;
        width: 100%; }
        .page-header-search .field-input .form-control .field-price-input-container .unit-box {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          text-align: center;
          white-space: nowrap; }
          .page-header-search .field-input .form-control .field-price-input-container .unit-box span {
            display: inline-block; }
          .page-header-search .field-input .form-control .field-price-input-container .unit-box .unit-background-text {
            color: transparent;
            font-size: 38px;
            max-width: 95%;
            overflow: hidden; }
          .page-header-search .field-input .form-control .field-price-input-container .unit-box .unit-background-text-space {
            width: 24px; }
        .page-header-search .field-input .form-control .field-price-input-container input {
          position: relative;
          height: 50px;
          line-height: 50px;
          box-sizing: border-box;
          text-align: center;
          font-size: 38px; }
        .page-header-search .field-input .form-control .field-price-input-container .unit-ruble {
          line-height: 52px;
          font-size: 32px;
          color: #8494a9;
          opacity: 0.7;
          filter: alpha(opacity=70);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=70;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .page-header-search .field-input .form-control .field-price-input-container .unit-ruble:after {
            content: "\20bd"; }
      .page-header-search .field-input .form-control .colorpicker {
        position: relative; }
        .page-header-search .field-input .form-control .colorpicker .picker-button {
          display: block;
          width: 105px;
          height: 30px;
          background: none;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .page-header-search .field-input .form-control .colorpicker .picker-palette {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          position: absolute;
          left: 0;
          bottom: -200px;
          width: 504px;
          padding: 32px 20px;
          z-index: 4;
          -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 450px) {
  .page-header-search .field-input .form-control .colorpicker .picker-palette {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
          .page-header-search .field-input .form-control .colorpicker .picker-palette .palette-header {
            margin-bottom: 40px;
            margin-left: 10px;
            font-size: 22px; }
          .page-header-search .field-input .form-control .colorpicker .picker-palette .palette-color-buttons {
            margin-bottom: 12px; }
          .page-header-search .field-input .form-control .colorpicker .picker-palette .palette-color-button {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin: 0 12px 24px 12px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            border-radius: 50%; }
            .page-header-search .field-input .form-control .colorpicker .picker-palette .palette-color-button.active, .page-header-search .field-input .form-control .colorpicker .picker-palette .palette-color-button:hover {
              margin: -10px 2px 14px 2px;
              width: 80px;
              height: 80px;
              border: 10px solid #f2f5fb; }
          .page-header-search .field-input .form-control .colorpicker .picker-palette .palette-corner-close-button {
            position: absolute;
            top: 0;
            right: 0;
            width: 80px;
            height: 80px;
            vertical-align: middle;
            text-align: center;
            font-size: 38px;
            font-family: "times new roman", verdana;
            background: none; }
          .page-header-search .field-input .form-control .colorpicker .picker-palette .palette-bottom-buttons {
            text-align: right; }
            .page-header-search .field-input .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              background: none;
              border: 1px solid #4b4b4b;
              color: #4b4b4b;
              font-size: 16px;
              min-width: 191px; }
              .page-header-search .field-input .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
              .page-header-search .field-input .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                display: inline-block;
                vertical-align: top; }
              .page-header-search .field-input .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                margin-right: 0; }
              .page-header-search .field-input .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .page-header-search .field-input .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .page-header-search .field-input .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .page-header-search .field-input .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .page-header-search .field-input .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
    font-weight: normal; } }
              .page-header-search .field-input .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                background: #f2f5fb; }
        .page-header-search .field-input .form-control .colorpicker .picker-palette-overlay {
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.7);
          z-index: 2; }
      .page-header-search .field-input .form-control .form-autocomplete-input {
        position: relative; }
        .page-header-search .field-input .form-control .form-autocomplete-input .form-autocomplete-dropdown {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          max-height: 300px;
          background: #fff;
          -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          z-index: 1;
          overflow: auto; }
          .page-header-search .field-input .form-control .form-autocomplete-input .form-autocomplete-dropdown button {
            background: none;
            border: none;
            width: 100%;
            padding: 8px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            text-align: left; }
            .page-header-search .field-input .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover {
              background: #f2f5fb; }
            .page-header-search .field-input .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child {
              border-bottom: 0; }
      .page-header-search .field-input .form-control .field-text-list-container .text-list-item {
        position: relative;
        margin-bottom: 10px; }
        .page-header-search .field-input .form-control .field-text-list-container .text-list-item .remove-text-item {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b;
          position: absolute;
          top: 0;
          right: 0;
          color: #f32424;
          font-size: 22px; }
          .page-header-search .field-input .form-control .field-text-list-container .text-list-item .remove-text-item:hover {
            color: black; }
      .page-header-search .field-input .form-control .field-text-list-container .add-text-item {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .page-header-search .field-input .form-control .field-text-list-container .add-text-item:hover {
          color: black; }
      .page-header-search .field-input .form-control .files-loader-container .files-loader-input-container {
        margin-bottom: 20px; }
      .page-header-search .field-input .form-control .files-loader-input-container {
        position: relative; }
        .page-header-search .field-input .form-control .files-loader-input-container .input-label-area {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          background: none;
          border: 1px solid #4b4b4b;
          color: #4b4b4b;
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px;
          width: 100%;
          height: 60px;
          line-height: 60px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          border-radius: 8px; }
          .page-header-search .field-input .form-control .files-loader-input-container .input-label-area .text,
          .page-header-search .field-input .form-control .files-loader-input-container .input-label-area .icon {
            display: inline-block;
            vertical-align: top; }
          .page-header-search .field-input .form-control .files-loader-input-container .input-label-area .icon {
            margin-right: 0; }
          .page-header-search .field-input .form-control .files-loader-input-container .input-label-area.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .page-header-search .field-input .form-control .files-loader-input-container .input-label-area.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .page-header-search .field-input .form-control .files-loader-input-container .input-label-area.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .page-header-search .field-input .form-control .files-loader-input-container .input-label-area:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .page-header-search .field-input .form-control .files-loader-input-container .input-label-area {
    font-weight: normal; } }
          .page-header-search .field-input .form-control .files-loader-input-container .input-label-area:hover {
            background: #f2f5fb; }
        .page-header-search .field-input .form-control .files-loader-input-container input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .page-header-search .field-input .form-control .files-loader-input-container:hover .input-label-area {
          background: #f2f5fb; }
      .page-header-search .field-input .form-control .files-loader-list .files-list-item {
        display: flex;
        justify-content: space-between;
        text-align: left;
        margin-bottom: 10px; }
        .page-header-search .field-input .form-control .files-loader-list .files-list-item .list-item-col {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
          .page-header-search .field-input .form-control .files-loader-list .files-list-item .list-item-col.icon {
            width: 38px;
            flex-shrink: 0; }
          .page-header-search .field-input .form-control .files-loader-list .files-list-item .list-item-col.caption {
            margin-right: auto;
            margin-left: 18px; }
          .page-header-search .field-input .form-control .files-loader-list .files-list-item .list-item-col.price {
            flex-shrink: 0;
            text-align: right; }
          .page-header-search .field-input .form-control .files-loader-list .files-list-item .list-item-col .note {
            font-size: 12px;
            color: #8494a9; }
        .page-header-search .field-input .form-control .files-loader-list .files-list-item .icon {
          display: inline-block;
          width: 38px;
          height: 38px;
          line-height: 37px;
          text-align: center;
          font-size: 22px;
          font-weight: 500;
          color: #fff;
          background: #bed6f9;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%;
          font-size: 12px;
          font-weight: normal; }
          .page-header-search .field-input .form-control .files-loader-list .files-list-item .icon.icon-sub-correction {
            line-height: 34.2px; }
          .page-header-search .field-input .form-control .files-loader-list .files-list-item .icon.color-1 {
            background: #bed6f9; }
          .page-header-search .field-input .form-control .files-loader-list .files-list-item .icon.color-2 {
            background: #b9e398; }
          .page-header-search .field-input .form-control .files-loader-list .files-list-item .icon.color-3 {
            background: #e4e597; }
          .page-header-search .field-input .form-control .files-loader-list .files-list-item .icon.color-4 {
            background: #e7c740; }
          .page-header-search .field-input .form-control .files-loader-list .files-list-item .icon.color-5 {
            background: #e74040; }
          .page-header-search .field-input .form-control .files-loader-list .files-list-item .icon.color-6 {
            background: #cf91af; }
        .page-header-search .field-input .form-control .files-loader-list .files-list-item .caption {
          height: 38px;
          line-height: 38px;
          margin-left: 12px; }
        .page-header-search .field-input .form-control .files-loader-list .files-list-item .remove {
          flex-shrink: 0;
          margin-left: 0; }
          .page-header-search .field-input .form-control .files-loader-list .files-list-item .remove button {
            background: none;
            border: none;
            height: 38px;
            line-height: 38px;
            font-size: 22px;
            color: #f32424; }
      .page-header-search .field-input .form-control .select-container .note {
        position: relative;
        padding-left: 25px;
        padding-top: 6px;
        font-size: 14px;
        color: #8494a9;
        line-height: 14px; }
        .page-header-search .field-input .form-control .select-container .note:before {
          content: "i";
          position: absolute;
          top: 5px;
          left: 0;
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-right: 10px;
          line-height: 14px;
          border: 1px solid #8494a9;
          border-radius: 50%;
          text-align: center; }
      .page-header-search .field-input .form-control .input-phone {
        display: flex; }
        .page-header-search .field-input .form-control .input-phone .phone-country-code .styled-select {
          position: inherit; }
          .page-header-search .field-input .form-control .input-phone .phone-country-code .styled-select .select-button {
            padding: 0;
            width: 80px;
            text-align: left;
            box-shadow: none;
            line-height: 34px;
            border-bottom: 1px solid #8d76f0;
            border-radius: 0; }
            .page-header-search .field-input .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after {
              width: 8px; }
          .page-header-search .field-input .form-control .input-phone .phone-country-code .styled-select .select-list {
            top: 100%;
            width: 100%;
            max-height: 200px;
            margin-top: 10px;
            overflow: auto; }
            .page-header-search .field-input .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button {
              text-align: left; }
          .page-header-search .field-input .form-control .input-phone .phone-country-code .styled-select select {
            width: 80px; }
          .page-header-search .field-input .form-control .input-phone .phone-country-code .styled-select.show-list .select-button {
            opacity: 1;
            filter: alpha(opacity=100);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .page-header-search .field-input .form-control .input-phone .phone-number {
          flex: 1; }
      .page-header-search .field-input .form-control .password-input {
        position: relative; }
        .page-header-search .field-input .form-control .password-input .eye-icon {
          font-size: 18px;
          background-color: transparent;
          color: #8494a9;
          position: absolute;
          right: 0; }

@media screen and (max-width: 500px) {
  .page-header-search .field-input .form-control .search-select .search-field .field-dropdown-icon {
    top: 3px; } }
      .page-header-search .field-input .form-control .form-input {
        height: 40px;
        line-height: 40px;
        border: none; }
  .page-header-search .button-submit {
    width: 27px;
    height: 26px;
    margin-top: 7px;
    background: url(images/icon-search.png) no-repeat center center;
    background-size: contain;
    border: 0;
    vertical-align: top; }
  .page-header-search.opened {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff; }
    .page-header-search.opened form {
      position: relative;
      margin-top: 7px; }
    .page-header-search.opened .field-input {
      display: block; }
    .page-header-search.opened .button-submit {
      position: absolute;
      top: 0;
      right: 0;
      height: 40px;
      width: 57px;
      margin: 0;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
      .page-header-search.opened .button-submit:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 27px;
        height: 26px;
        margin: -13px 0 0 -13px;
        background: url(images/icon-search-white.png) no-repeat center center;
        background-size: contain; }

.page-header-userbar .userbar-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #4b4b4b;
  position: relative;
  height: 100%;
  font-size: 0;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden; }
  .page-header-userbar .userbar-button:hover {
    color: black; }
  .page-header-userbar .userbar-button .icon {
    width: 17px;
    height: 17px;
    background: url(images/icon-profile-black.svg) no-repeat center center;
    display: inline-block;
    background-size: contain;
    position: relative;
    top: 2px;
    background-size: contain;
    margin-right: 16px; }
  .page-header-userbar .userbar-button .text {
    position: relative;
    top: 3px;
    display: inline-block;
    max-width: 100%;
    font-size: 17px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .page-header-userbar .userbar-button .icon-close {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    font-family: "Times New Roman";
    font-size: 38px;
    color: #4b4b4b;
    font-weight: normal;
    background: #fff;
    -webkit-box-shadow: 0 0 20px 20px #fff;
    -moz-box-shadow: 0 0 20px 20px #fff;
    -ms-box-shadow: 0 0 20px 20px #fff;
    -o-box-shadow: 0 0 20px 20px #fff;
    box-shadow: 0 0 20px 20px #fff; }

.page-header-userbar .userbar-dropdown {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  min-width: 262px;
  padding: 25px 0;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
  .page-header-userbar .userbar-dropdown button {
    background: none;
    border: none;
    cursor: pointer;
    color: #4b4b4b;
    display: block;
    position: relative;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 20px;
    font-weight: normal; }
    .page-header-userbar .userbar-dropdown button:hover {
      color: black; }
    .page-header-userbar .userbar-dropdown button .icon {
      position: absolute;
      top: 0;
      left: 0; }
    .page-header-userbar .userbar-dropdown button .icon-about {
      width: 22px;
      height: 23px;
      background: url(images/icon-about-black.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-userbar .userbar-dropdown button .icon-companies,
    .page-header-userbar .userbar-dropdown button .icon-categories {
      width: 21px;
      height: 22px;
      background: url(images/icon-companies-black.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-userbar .userbar-dropdown button .icon-settings,
    .page-header-userbar .userbar-dropdown button .icon-owners,
    .page-header-userbar .userbar-dropdown button .icon-workers,
    .page-header-userbar .userbar-dropdown button .icon-partners {
      width: 22px;
      height: 22px;
      background: url(images/icon-profile-black.svg) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-userbar .userbar-dropdown button .icon-license,
    .page-header-userbar .userbar-dropdown button .icon-accounts {
      width: 22px;
      height: 22px;
      background: url(images/icon-ruble-black.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-userbar .userbar-dropdown button .icon-logout {
      top: 2px;
      width: 21px;
      height: 19px;
      background: url(images/icon-logout-black.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-userbar .userbar-dropdown button .icon-feedback {
      width: 22px;
      height: 22px;
      background: url(images/icon-edit-black.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-userbar .userbar-dropdown button .icon-telegram {
      width: 22px;
      height: 22px;
      background: url(images/icon-telegram.svg) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-userbar .userbar-dropdown button .icon-whatsapp {
      width: 22px;
      height: 22px;
      background: url(images/icon-whatsapp.svg) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-userbar .userbar-dropdown button .text {
      display: block;
      margin-left: 34px; }
    .page-header-userbar .userbar-dropdown button.button-telegram {
      color: #47aaed; }
    .page-header-userbar .userbar-dropdown button.button-whatsapp {
      color: #4cba3f; }
    .page-header-userbar .userbar-dropdown button .icon-xlsx {
      width: 22px;
      height: 22px;
      background: url(images/icon-xlsx.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-userbar .userbar-dropdown button .icon-pdf {
      width: 22px;
      height: 22px;
      background: url(images/icon-pdf.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-userbar .userbar-dropdown button .icon-word {
      width: 22px;
      height: 22px;
      background: url(images/icon-word.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-userbar .userbar-dropdown button .icon-print {
      width: 23px;
      height: 16px;
      background: url(images/icon-print.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-userbar .userbar-dropdown button .icon {
      top: 50%;
      left: 20px;
      margin: -10px 0 0 0; }
    .page-header-userbar .userbar-dropdown button:hover {
      background: #f2f5fb; }

.page-header-userbar .userbar-dropdown-container {
  position: relative;
  display: inline-block;
  padding: 0 20px; }
  .page-header-userbar .userbar-dropdown-container.opened .userbar-dropdown {
    display: block; }
  .page-header-userbar .userbar-dropdown-container.opened .userbar-button .icon-close {
    display: block; }

.page-header-feedback-bar .feedback-bar-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #4b4b4b;
  position: relative;
  height: 100%;
  font-size: 0;
  white-space: nowrap; }
  .page-header-feedback-bar .feedback-bar-button:hover {
    color: black; }
  .page-header-feedback-bar .feedback-bar-button .icon {
    width: 17px;
    height: 17px;
    background: url(images/icon-feedback.svg) no-repeat center center;
    display: inline-block;
    background-size: contain;
    position: relative;
    top: 6px;
    background-size: contain; }
  .page-header-feedback-bar .feedback-bar-button .icon-close {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    font-family: "Times New Roman";
    font-size: 38px;
    color: #4b4b4b;
    font-weight: normal;
    background: #fff;
    -webkit-box-shadow: 0 0 20px 20px #fff;
    -moz-box-shadow: 0 0 20px 20px #fff;
    -ms-box-shadow: 0 0 20px 20px #fff;
    -o-box-shadow: 0 0 20px 20px #fff;
    box-shadow: 0 0 20px 20px #fff;
    -webkit-box-shadow: 0;
    -moz-box-shadow: 0;
    -ms-box-shadow: 0;
    -o-box-shadow: 0;
    box-shadow: 0; }

.page-header-feedback-bar .feedback-bar-dropdown {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  min-width: 250px;
  padding: 25px 0;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
  .page-header-feedback-bar .feedback-bar-dropdown button,
  .page-header-feedback-bar .feedback-bar-dropdown a {
    background: none;
    border: none;
    cursor: pointer;
    color: #4b4b4b;
    display: block;
    position: relative;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 20px;
    font-weight: normal; }
    .page-header-feedback-bar .feedback-bar-dropdown button:hover,
    .page-header-feedback-bar .feedback-bar-dropdown a:hover {
      color: black; }
    .page-header-feedback-bar .feedback-bar-dropdown button .icon,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon {
      position: absolute;
      top: 0;
      left: 0; }
    .page-header-feedback-bar .feedback-bar-dropdown button .icon-about,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon-about {
      width: 22px;
      height: 23px;
      background: url(images/icon-about-black.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-feedback-bar .feedback-bar-dropdown button .icon-companies,
    .page-header-feedback-bar .feedback-bar-dropdown button .icon-categories,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon-companies,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon-categories {
      width: 21px;
      height: 22px;
      background: url(images/icon-companies-black.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-feedback-bar .feedback-bar-dropdown button .icon-settings,
    .page-header-feedback-bar .feedback-bar-dropdown button .icon-owners,
    .page-header-feedback-bar .feedback-bar-dropdown button .icon-workers,
    .page-header-feedback-bar .feedback-bar-dropdown button .icon-partners,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon-settings,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon-owners,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon-workers,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon-partners {
      width: 22px;
      height: 22px;
      background: url(images/icon-profile-black.svg) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-feedback-bar .feedback-bar-dropdown button .icon-license,
    .page-header-feedback-bar .feedback-bar-dropdown button .icon-accounts,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon-license,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon-accounts {
      width: 22px;
      height: 22px;
      background: url(images/icon-ruble-black.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-feedback-bar .feedback-bar-dropdown button .icon-logout,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon-logout {
      top: 2px;
      width: 21px;
      height: 19px;
      background: url(images/icon-logout-black.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-feedback-bar .feedback-bar-dropdown button .icon-feedback,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon-feedback {
      width: 22px;
      height: 22px;
      background: url(images/icon-edit-black.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-feedback-bar .feedback-bar-dropdown button .icon-telegram,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon-telegram {
      width: 22px;
      height: 22px;
      background: url(images/icon-telegram.svg) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-feedback-bar .feedback-bar-dropdown button .icon-whatsapp,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon-whatsapp {
      width: 22px;
      height: 22px;
      background: url(images/icon-whatsapp.svg) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-feedback-bar .feedback-bar-dropdown button .text,
    .page-header-feedback-bar .feedback-bar-dropdown a .text {
      display: block;
      margin-left: 34px; }
    .page-header-feedback-bar .feedback-bar-dropdown button.button-telegram,
    .page-header-feedback-bar .feedback-bar-dropdown a.button-telegram {
      color: #47aaed; }
    .page-header-feedback-bar .feedback-bar-dropdown button.button-whatsapp,
    .page-header-feedback-bar .feedback-bar-dropdown a.button-whatsapp {
      color: #4cba3f; }
    .page-header-feedback-bar .feedback-bar-dropdown button .icon-xlsx,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon-xlsx {
      width: 22px;
      height: 22px;
      background: url(images/icon-xlsx.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-feedback-bar .feedback-bar-dropdown button .icon-pdf,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon-pdf {
      width: 22px;
      height: 22px;
      background: url(images/icon-pdf.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-feedback-bar .feedback-bar-dropdown button .icon-word,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon-word {
      width: 22px;
      height: 22px;
      background: url(images/icon-word.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-feedback-bar .feedback-bar-dropdown button .icon-print,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon-print {
      width: 23px;
      height: 16px;
      background: url(images/icon-print.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .page-header-feedback-bar .feedback-bar-dropdown button .icon,
    .page-header-feedback-bar .feedback-bar-dropdown a .icon {
      top: 50%;
      left: 20px;
      margin: -10px 0 0 0; }
    .page-header-feedback-bar .feedback-bar-dropdown button:hover,
    .page-header-feedback-bar .feedback-bar-dropdown a:hover {
      background: #f2f5fb; }

.page-header-feedback-bar .feedback-bar-dropdown-container {
  position: relative;
  display: inline-block;
  padding: 0 20px; }
  .page-header-feedback-bar .feedback-bar-dropdown-container.opened .feedback-bar-dropdown {
    display: block; }
  .page-header-feedback-bar .feedback-bar-dropdown-container.opened .feedback-bar-button .icon-close {
    display: block; }

/* COMPANIES */
.companies-list .companies-list-item {
  display: block;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: box-shadow 0.1s ease;
  -moz-transition: box-shadow 0.1s ease;
  -ms-transition: box-shadow 0.1s ease;
  -o-transition: box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease;
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  padding: 36px 32px;
  margin-bottom: 14px;
  color: #4b4b4b; }

@media screen and (max-width: 450px) {
  .companies-list .companies-list-item {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
  .companies-list .companies-list-item:hover {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
  .companies-list .companies-list-item .list-item-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .companies-list .companies-list-item .list-item-col.icon {
      width: 38px;
      flex-shrink: 0; }
    .companies-list .companies-list-item .list-item-col.caption {
      margin-right: auto;
      margin-left: 18px; }
    .companies-list .companies-list-item .list-item-col.price {
      flex-shrink: 0;
      text-align: right; }
    .companies-list .companies-list-item .list-item-col .note {
      font-size: 12px;
      color: #8494a9; }
  .companies-list .companies-list-item .list-item-col {
    height: 38px;
    line-height: 38px; }
  .companies-list .companies-list-item .icon-content {
    display: inline-block;
    width: 38px;
    height: 38px;
    line-height: 37px;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    color: #fff;
    background: #bed6f9;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%; }
    .companies-list .companies-list-item .icon-content.icon-sub-correction {
      line-height: 34.2px; }
    .companies-list .companies-list-item .icon-content.color-1 {
      background: #bed6f9; }
    .companies-list .companies-list-item .icon-content.color-2 {
      background: #b9e398; }
    .companies-list .companies-list-item .icon-content.color-3 {
      background: #e4e597; }
    .companies-list .companies-list-item .icon-content.color-4 {
      background: #e7c740; }
    .companies-list .companies-list-item .icon-content.color-5 {
      background: #e74040; }
    .companies-list .companies-list-item .icon-content.color-6 {
      background: #cf91af; }
  .companies-list .companies-list-item .caption {
    font-size: 17px;
    font-weight: 500; }
  .companies-list .companies-list-item .price {
    font-size: 0;
    width: 200px; }
    .companies-list .companies-list-item .price .int,
    .companies-list .companies-list-item .price .delim {
      font-size: 32px; }
    .companies-list .companies-list-item .price .float,
    .companies-list .companies-list-item .price .unit {
      font-size: 22px; }
    .companies-list .companies-list-item .price .unit {
      margin-left: 8px; }
    .companies-list .companies-list-item .price .unit-ruble:after {
      content: "\20bd"; }
    .companies-list .companies-list-item .price.income, .companies-list .companies-list-item .price.transfer-income {
      color: #4dbd7d; }
      .companies-list .companies-list-item .price.income .int,
      .companies-list .companies-list-item .price.income .delim,
      .companies-list .companies-list-item .price.income .float,
      .companies-list .companies-list-item .price.income .unit, .companies-list .companies-list-item .price.transfer-income .int,
      .companies-list .companies-list-item .price.transfer-income .delim,
      .companies-list .companies-list-item .price.transfer-income .float,
      .companies-list .companies-list-item .price.transfer-income .unit {
        color: #4dbd7d; }
  .companies-list .companies-list-item:hover {
    color: black; }

.companies-compact-list .companies-list-item {
  display: block;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: box-shadow 0.1s ease;
  -moz-transition: box-shadow 0.1s ease;
  -ms-transition: box-shadow 0.1s ease;
  -o-transition: box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease;
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  padding: 20px;
  margin-bottom: 16px;
  box-sizing: border-box;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.12); }

@media screen and (max-width: 450px) {
  .companies-compact-list .companies-list-item {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
  .companies-compact-list .companies-list-item .list-item-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .companies-compact-list .companies-list-item .list-item-col.icon {
      width: 38px;
      flex-shrink: 0; }
    .companies-compact-list .companies-list-item .list-item-col.caption {
      margin-right: auto;
      margin-left: 18px; }
    .companies-compact-list .companies-list-item .list-item-col.price {
      flex-shrink: 0;
      text-align: right; }
    .companies-compact-list .companies-list-item .list-item-col .note {
      font-size: 12px;
      color: #8494a9; }
  .companies-compact-list .companies-list-item .icon {
    width: 24px; }
    .companies-compact-list .companies-list-item .icon .icon-content {
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 23px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      background: #bed6f9;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      border-radius: 50%; }
      .companies-compact-list .companies-list-item .icon .icon-content.icon-sub-correction {
        line-height: 21.6px; }
      .companies-compact-list .companies-list-item .icon .icon-content.color-1 {
        background: #bed6f9; }
      .companies-compact-list .companies-list-item .icon .icon-content.color-2 {
        background: #b9e398; }
      .companies-compact-list .companies-list-item .icon .icon-content.color-3 {
        background: #e4e597; }
      .companies-compact-list .companies-list-item .icon .icon-content.color-4 {
        background: #e7c740; }
      .companies-compact-list .companies-list-item .icon .icon-content.color-5 {
        background: #e74040; }
      .companies-compact-list .companies-list-item .icon .icon-content.color-6 {
        background: #cf91af; }
  .companies-compact-list .companies-list-item .info {
    margin-left: 8px;
    margin-right: auto;
    white-space: normal;
    color: #4b4b4b; }
    .companies-compact-list .companies-list-item .info .caption,
    .companies-compact-list .companies-list-item .info .price {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .companies-compact-list .companies-list-item .info .price {
      font-size: 0;
      color: #8494a9; }
      .companies-compact-list .companies-list-item .info .price .int,
      .companies-compact-list .companies-list-item .info .price .delim {
        font-size: 17px; }
      .companies-compact-list .companies-list-item .info .price .float,
      .companies-compact-list .companies-list-item .info .price .unit {
        font-size: 14px; }
      .companies-compact-list .companies-list-item .info .price .unit {
        margin-left: 8px; }
      .companies-compact-list .companies-list-item .info .price .unit-ruble:after {
        content: "\20bd"; }
      .companies-compact-list .companies-list-item .info .price.income, .companies-compact-list .companies-list-item .info .price.transfer-income {
        color: #4dbd7d; }
        .companies-compact-list .companies-list-item .info .price.income .int,
        .companies-compact-list .companies-list-item .info .price.income .delim,
        .companies-compact-list .companies-list-item .info .price.income .float,
        .companies-compact-list .companies-list-item .info .price.income .unit, .companies-compact-list .companies-list-item .info .price.transfer-income .int,
        .companies-compact-list .companies-list-item .info .price.transfer-income .delim,
        .companies-compact-list .companies-list-item .info .price.transfer-income .float,
        .companies-compact-list .companies-list-item .info .price.transfer-income .unit {
          color: #4dbd7d; }
  .companies-compact-list .companies-list-item.active {
    display: none; }

.companies-layout {
  width: 100%;
  max-width: 780px;
  margin: 0 auto; }
  .companies-layout .companies-header {
    display: block;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: box-shadow 0.1s ease;
    -moz-transition: box-shadow 0.1s ease;
    -ms-transition: box-shadow 0.1s ease;
    -o-transition: box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease;
    background: url(images/accent-plate.jpg) no-repeat #6880fc;
    background-size: cover;
    color: #fff;
    position: relative;
    height: 170px;
    margin-bottom: 14px; }

@media screen and (max-width: 450px) {
  .companies-layout .companies-header {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
    .companies-layout .companies-header label,
    .companies-layout .companies-header p,
    .companies-layout .companies-header a {
      color: #fff; }
    .companies-layout .companies-header .header-content {
      position: absolute;
      bottom: 24px;
      left: 37px; }
    .companies-layout .companies-header .price-value {
      font-size: 0;
      font-weight: 500; }
      .companies-layout .companies-header .price-value .int,
      .companies-layout .companies-header .price-value .delim {
        font-size: 54px; }
      .companies-layout .companies-header .price-value .float,
      .companies-layout .companies-header .price-value .unit {
        font-size: 32px; }
      .companies-layout .companies-header .price-value .unit {
        margin-left: 8px; }
      .companies-layout .companies-header .price-value .unit-ruble:after {
        content: "\20bd"; }
      .companies-layout .companies-header .price-value.income, .companies-layout .companies-header .price-value.transfer-income {
        color: #4dbd7d; }
        .companies-layout .companies-header .price-value.income .int,
        .companies-layout .companies-header .price-value.income .delim,
        .companies-layout .companies-header .price-value.income .float,
        .companies-layout .companies-header .price-value.income .unit, .companies-layout .companies-header .price-value.transfer-income .int,
        .companies-layout .companies-header .price-value.transfer-income .delim,
        .companies-layout .companies-header .price-value.transfer-income .float,
        .companies-layout .companies-header .price-value.transfer-income .unit {
          color: #4dbd7d; }
      .companies-layout .companies-header .price-value .int,
      .companies-layout .companies-header .price-value .delim {
        line-height: 54px; }
      .companies-layout .companies-header .price-value .float,
      .companies-layout .companies-header .price-value .unit {
        line-height: 32px; }
    .companies-layout .companies-header .companies-selector {
      position: absolute;
      top: 32px;
      left: 32px;
      right: 32px; }
    .companies-layout .companies-header .companies-selector button,
    .companies-layout .companies-header .companies-selector-popup .selector-button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: none;
      border: 1px solid #fff;
      color: #fff;
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px;
      height: 34px;
      max-width: 320px;
      line-height: 22px;
      -webkit-border-radius: 17px;
      -moz-border-radius: 17px;
      -ms-border-radius: 17px;
      -o-border-radius: 17px;
      border-radius: 17px;
      font-size: 17px;
      white-space: nowrap;
      overflow: hidden; }
      .companies-layout .companies-header .companies-selector button .text,
      .companies-layout .companies-header .companies-selector button .icon,
      .companies-layout .companies-header .companies-selector-popup .selector-button .text,
      .companies-layout .companies-header .companies-selector-popup .selector-button .icon {
        display: inline-block;
        vertical-align: top; }
      .companies-layout .companies-header .companies-selector button .icon,
      .companies-layout .companies-header .companies-selector-popup .selector-button .icon {
        margin-right: 0; }
      .companies-layout .companies-header .companies-selector button.button-xs,
      .companies-layout .companies-header .companies-selector-popup .selector-button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .companies-layout .companies-header .companies-selector button.button-s,
      .companies-layout .companies-header .companies-selector-popup .selector-button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .companies-layout .companies-header .companies-selector button.button-l,
      .companies-layout .companies-header .companies-selector-popup .selector-button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .companies-layout .companies-header .companies-selector button:hover,
      .companies-layout .companies-header .companies-selector-popup .selector-button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .companies-layout .companies-header .companies-selector button,
  .companies-layout .companies-header .companies-selector-popup .selector-button {
    font-weight: normal; } }
      .companies-layout .companies-header .companies-selector button:hover,
      .companies-layout .companies-header .companies-selector-popup .selector-button:hover {
        background: rgba(255, 255, 255, 0.1); }
      .companies-layout .companies-header .companies-selector button .icon,
      .companies-layout .companies-header .companies-selector button .text,
      .companies-layout .companies-header .companies-selector-popup .selector-button .icon,
      .companies-layout .companies-header .companies-selector-popup .selector-button .text {
        vertical-align: top; }
      .companies-layout .companies-header .companies-selector button .text,
      .companies-layout .companies-header .companies-selector-popup .selector-button .text {
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .companies-layout .companies-header .companies-selector button .icon,
      .companies-layout .companies-header .companies-selector-popup .selector-button .icon {
        width: 12px;
        height: 10px;
        background: url(images/icon-burger-white.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        margin-top: 6px;
        margin-right: 8px; }
    .companies-layout .companies-header .companies-selector-popup .selector-button {
      display: block;
      max-width: 80%; }
    .companies-layout .companies-header .companies-selector-popup {
      position: relative;
      display: none;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 20;
      position: absolute;
      top: 22px;
      left: 24px;
      padding-bottom: 32px;
      min-width: 500px; }
      .companies-layout .companies-header .companies-selector-popup.show {
        display: block; }
      .companies-layout .companies-header .companies-selector-popup .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 25px; }

@media screen and (max-width: 500px) {
  .companies-layout .companies-header .companies-selector-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .companies-layout .companies-header .companies-selector-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .companies-layout .companies-header .companies-selector-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
      .companies-layout .companies-header .companies-selector-popup .selector-button {
        margin: 11px 0 23px 8px;
        color: #4b4b4b;
        border-color: #4b4b4b; }
        .companies-layout .companies-header .companies-selector-popup .selector-button .icon {
          background: url(images/icon-burger-black.png) no-repeat center center;
          background-size: contain; }
      .companies-layout .companies-header .companies-selector-popup .checklist {
        min-width: 240px;
        padding-bottom: 14px; }
        .companies-layout .companies-header .companies-selector-popup .checklist button {
          position: relative;
          display: block;
          width: 390px;
          height: 48px;
          line-height: 48px;
          padding-right: 32px;
          background: none;
          font-size: 17px;
          color: #4b4b4b; }
          .companies-layout .companies-header .companies-selector-popup .checklist button .icon {
            position: absolute;
            top: 13px;
            left: 24px;
            display: inline-block;
            width: 18px;
            height: 18px;
            border: 2px solid #8494a9;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px; }
          .companies-layout .companies-header .companies-selector-popup .checklist button .icon-checked {
            position: relative;
            display: inline-block;
            width: 22px;
            height: 22px;
            background: #81bc52;
            border: 0;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px;
            position: absolute;
            top: 13px;
            left: 24px; }
            .companies-layout .companies-header .companies-selector-popup .checklist button .icon-checked::after {
              content: "";
              width: 16px;
              height: 12px;
              background: url(images/icon-check-white.png) no-repeat center center;
              display: inline-block;
              background-size: contain;
              position: absolute;
              top: 7px;
              left: 4px;
              width: 13px;
              height: 9px; }
          .companies-layout .companies-header .companies-selector-popup .checklist button .text {
            display: block;
            margin-left: 60px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            font-size: 14px; }
          .companies-layout .companies-header .companies-selector-popup .checklist button:hover {
            background: #f2f5fb; }
          .companies-layout .companies-header .companies-selector-popup .checklist button.hidden {
            display: none; }

@media screen and (max-width: 500px) {
  .companies-layout .companies-header .companies-selector-popup .checklist button {
    width: 100%;
    box-sizing: border-box; }
    .companies-layout .companies-header .companies-selector-popup .checklist button .icon,
    .companies-layout .companies-header .companies-selector-popup .checklist button .icon-checked {
      top: 7px; } }

@media screen and (max-width: 450px) {
  .companies-layout .companies-header .companies-selector-popup .checklist button {
    font-weight: normal;
    height: 36px;
    line-height: 36px; } }
      .companies-layout .companies-header .companies-selector-popup .selector-action-container {
        display: none; }
        .companies-layout .companies-header .companies-selector-popup .selector-action-container button {
          background: none;
          border: none;
          cursor: pointer;
          color: #8494a9; }
          .companies-layout .companies-header .companies-selector-popup .selector-action-container button:hover {
            color: #5c6e84; }
        .companies-layout .companies-header .companies-selector-popup .selector-action-container.show {
          display: block;
          margin-bottom: 30px; }
      .companies-layout .companies-header .companies-selector-popup .selector-delim {
        height: 1px;
        margin: 28px 0;
        background: rgba(0, 0, 0, 0.1); }
      .companies-layout .companies-header .companies-selector-popup .selector-balance-container {
        margin: 0 0 30px 90px; }
        .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-label {
          margin-bottom: 6px;
          color: #8494a9; }
        .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-value {
          font-size: 0;
          color: #4b4b4b; }
          .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-value .int,
          .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-value .delim {
            font-size: 17px; }
          .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-value .float,
          .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-value .unit {
            font-size: 14px; }
          .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-value .unit {
            margin-left: 8px; }
          .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-value .unit-ruble:after {
            content: "\20bd"; }
          .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-value.income, .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-value.transfer-income {
            color: #4dbd7d; }
            .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-value.income .int,
            .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-value.income .delim,
            .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-value.income .float,
            .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-value.income .unit, .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-value.transfer-income .int,
            .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-value.transfer-income .delim,
            .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-value.transfer-income .float,
            .companies-layout .companies-header .companies-selector-popup .selector-balance-container .balance-value.transfer-income .unit {
              color: #4dbd7d; }
      .companies-layout .companies-header .companies-selector-popup .company-menu {
        margin-left: 22px; }
        .companies-layout .companies-header .companies-selector-popup .company-menu button {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b;
          display: block;
          position: relative;
          font-size: 15px;
          font-weight: 500;
          text-align: left;
          box-sizing: border-box;
          margin-bottom: 16px; }
          .companies-layout .companies-header .companies-selector-popup .company-menu button:hover {
            color: black; }
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon {
            position: absolute;
            top: 0;
            left: 0; }
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon-about {
            width: 22px;
            height: 23px;
            background: url(images/icon-about-black.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon-companies,
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon-categories {
            width: 21px;
            height: 22px;
            background: url(images/icon-companies-black.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon-settings,
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon-owners,
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon-workers,
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon-partners {
            width: 22px;
            height: 22px;
            background: url(images/icon-profile-black.svg) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon-license,
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon-accounts {
            width: 22px;
            height: 22px;
            background: url(images/icon-ruble-black.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon-logout {
            top: 2px;
            width: 21px;
            height: 19px;
            background: url(images/icon-logout-black.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon-feedback {
            width: 22px;
            height: 22px;
            background: url(images/icon-edit-black.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon-telegram {
            width: 22px;
            height: 22px;
            background: url(images/icon-telegram.svg) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon-whatsapp {
            width: 22px;
            height: 22px;
            background: url(images/icon-whatsapp.svg) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .companies-layout .companies-header .companies-selector-popup .company-menu button .text {
            display: block;
            margin-left: 34px; }
          .companies-layout .companies-header .companies-selector-popup .company-menu button.button-telegram {
            color: #47aaed; }
          .companies-layout .companies-header .companies-selector-popup .company-menu button.button-whatsapp {
            color: #4cba3f; }
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon-xlsx {
            width: 22px;
            height: 22px;
            background: url(images/icon-xlsx.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon-pdf {
            width: 22px;
            height: 22px;
            background: url(images/icon-pdf.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon-word {
            width: 22px;
            height: 22px;
            background: url(images/icon-word.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .companies-layout .companies-header .companies-selector-popup .company-menu button .icon-print {
            width: 23px;
            height: 16px;
            background: url(images/icon-print.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
      .companies-layout .companies-header .companies-selector-popup .companies-label {
        display: block;
        margin-bottom: 12px;
        color: #8494a9; }
      .companies-layout .companies-header .companies-selector-popup .companies-compact-list {
        display: none; }
        .companies-layout .companies-header .companies-selector-popup .companies-compact-list .companies-list-item:hover {
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
        .companies-layout .companies-header .companies-selector-popup .companies-compact-list.show {
          display: block; }
      .companies-layout .companies-header .companies-selector-popup .dropdown-button {
        background: none;
        border: none;
        float: right;
        color: #8494a9; }
        .companies-layout .companies-header .companies-selector-popup .dropdown-button:after {
          content: "";
          width: 14px;
          height: 7px;
          background: url(images/arrow-bottom.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          position: relative;
          top: -2px;
          width: 7px;
          margin-left: 10px; }
      .companies-layout .companies-header .companies-selector-popup .active-company {
        display: block;
        background: #fff;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        -webkit-transition: box-shadow 0.1s ease;
        -moz-transition: box-shadow 0.1s ease;
        -ms-transition: box-shadow 0.1s ease;
        -o-transition: box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease;
        display: flex;
        justify-content: space-between;
        text-align: left;
        width: 100%;
        padding: 20px;
        margin-bottom: 16px;
        box-sizing: border-box;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px;
        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
        -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
        -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: 0;
        -moz-box-shadow: 0;
        -ms-box-shadow: 0;
        -o-box-shadow: 0;
        box-shadow: 0;
        margin-bottom: 24px;
        background: radial-gradient(122.91% 173.67% at 84.84% -3.52%, #d15ed3 0%, #2c3aba 100%);
        cursor: pointer; }

@media screen and (max-width: 450px) {
  .companies-layout .companies-header .companies-selector-popup .active-company {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
        .companies-layout .companies-header .companies-selector-popup .active-company .list-item-col {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
          .companies-layout .companies-header .companies-selector-popup .active-company .list-item-col.icon {
            width: 38px;
            flex-shrink: 0; }
          .companies-layout .companies-header .companies-selector-popup .active-company .list-item-col.caption {
            margin-right: auto;
            margin-left: 18px; }
          .companies-layout .companies-header .companies-selector-popup .active-company .list-item-col.price {
            flex-shrink: 0;
            text-align: right; }
          .companies-layout .companies-header .companies-selector-popup .active-company .list-item-col .note {
            font-size: 12px;
            color: #8494a9; }
        .companies-layout .companies-header .companies-selector-popup .active-company .icon {
          width: 24px; }
          .companies-layout .companies-header .companies-selector-popup .active-company .icon .icon-content {
            display: inline-block;
            width: 24px;
            height: 24px;
            line-height: 23px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
            background: #bed6f9;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            border-radius: 50%; }
            .companies-layout .companies-header .companies-selector-popup .active-company .icon .icon-content.icon-sub-correction {
              line-height: 21.6px; }
            .companies-layout .companies-header .companies-selector-popup .active-company .icon .icon-content.color-1 {
              background: #bed6f9; }
            .companies-layout .companies-header .companies-selector-popup .active-company .icon .icon-content.color-2 {
              background: #b9e398; }
            .companies-layout .companies-header .companies-selector-popup .active-company .icon .icon-content.color-3 {
              background: #e4e597; }
            .companies-layout .companies-header .companies-selector-popup .active-company .icon .icon-content.color-4 {
              background: #e7c740; }
            .companies-layout .companies-header .companies-selector-popup .active-company .icon .icon-content.color-5 {
              background: #e74040; }
            .companies-layout .companies-header .companies-selector-popup .active-company .icon .icon-content.color-6 {
              background: #cf91af; }
        .companies-layout .companies-header .companies-selector-popup .active-company .info {
          margin-left: 8px;
          margin-right: auto;
          white-space: normal;
          color: #4b4b4b; }
          .companies-layout .companies-header .companies-selector-popup .active-company .info .caption,
          .companies-layout .companies-header .companies-selector-popup .active-company .info .price {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden; }
          .companies-layout .companies-header .companies-selector-popup .active-company .info .price {
            font-size: 0;
            color: #8494a9; }
            .companies-layout .companies-header .companies-selector-popup .active-company .info .price .int,
            .companies-layout .companies-header .companies-selector-popup .active-company .info .price .delim {
              font-size: 17px; }
            .companies-layout .companies-header .companies-selector-popup .active-company .info .price .float,
            .companies-layout .companies-header .companies-selector-popup .active-company .info .price .unit {
              font-size: 14px; }
            .companies-layout .companies-header .companies-selector-popup .active-company .info .price .unit {
              margin-left: 8px; }
            .companies-layout .companies-header .companies-selector-popup .active-company .info .price .unit-ruble:after {
              content: "\20bd"; }
            .companies-layout .companies-header .companies-selector-popup .active-company .info .price.income, .companies-layout .companies-header .companies-selector-popup .active-company .info .price.transfer-income {
              color: #4dbd7d; }
              .companies-layout .companies-header .companies-selector-popup .active-company .info .price.income .int,
              .companies-layout .companies-header .companies-selector-popup .active-company .info .price.income .delim,
              .companies-layout .companies-header .companies-selector-popup .active-company .info .price.income .float,
              .companies-layout .companies-header .companies-selector-popup .active-company .info .price.income .unit, .companies-layout .companies-header .companies-selector-popup .active-company .info .price.transfer-income .int,
              .companies-layout .companies-header .companies-selector-popup .active-company .info .price.transfer-income .delim,
              .companies-layout .companies-header .companies-selector-popup .active-company .info .price.transfer-income .float,
              .companies-layout .companies-header .companies-selector-popup .active-company .info .price.transfer-income .unit {
                color: #4dbd7d; }
        .companies-layout .companies-header .companies-selector-popup .active-company .info {
          color: #fff; }
          .companies-layout .companies-header .companies-selector-popup .active-company .info .price {
            color: #fff; }
      .companies-layout .companies-header .companies-selector-popup .selector-limiter {
        margin-left: 90px;
        max-width: 326px; }
    .companies-layout .companies-header .confirm-delete-popup {
      display: none;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 20;
      background: rgba(255, 255, 255, 0.7);
      animation: popup-background-fade-in 0.8s ease; }
      .companies-layout .companies-header .confirm-delete-popup .popup-container {
        position: relative;
        display: none;
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        z-index: 20;
        display: block;
        width: 550px;
        margin: 0 auto;
        padding-bottom: 58px;
        top: 10%;
        max-height: 75%;
        overflow: auto;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        border-radius: 8px;
        /* Scrollbar styles */ }
        .companies-layout .companies-header .confirm-delete-popup .popup-container.show {
          display: block; }
        .companies-layout .companies-header .confirm-delete-popup .popup-container .close-button {
          width: 19px;
          height: 19px;
          background: url(images/icon-close.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          position: absolute;
          top: 25px;
          right: 25px; }

@media screen and (max-width: 500px) {
  .companies-layout .companies-header .confirm-delete-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .companies-layout .companies-header .confirm-delete-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .companies-layout .companies-header .confirm-delete-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
        .companies-layout .companies-header .confirm-delete-popup .popup-container::-webkit-scrollbar {
          width: 12px;
          height: 12px; }
        .companies-layout .companies-header .confirm-delete-popup .popup-container::-webkit-scrollbar-track {
          background: #f5f5f5;
          border-radius: 10px; }
        .companies-layout .companies-header .confirm-delete-popup .popup-container::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #ccc; }
        .companies-layout .companies-header .confirm-delete-popup .popup-container .popup-content form .form-control .datepicker-popup {
          position: fixed;
          top: 50%;
          left: 50%;
          margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .companies-layout .companies-header .confirm-delete-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
        .companies-layout .companies-header .confirm-delete-popup .popup-container .popup-content .popup-actions {
          width: 340px;
          margin: 0 auto; }
          .companies-layout .companies-header .confirm-delete-popup .popup-container .popup-content .popup-actions button {
            background: none;
            border: none;
            cursor: pointer;
            color: #4b4b4b; }
            .companies-layout .companies-header .confirm-delete-popup .popup-container .popup-content .popup-actions button:hover {
              color: black; }
      .companies-layout .companies-header .confirm-delete-popup .header {
        padding: 32px 24px;
        font-size: 22px; }
      .companies-layout .companies-header .confirm-delete-popup .body {
        padding: 0 24px 32px 24px; }
      .companies-layout .companies-header .confirm-delete-popup.show {
        display: block; }

@media screen and (max-width: 600px) {
  .companies-layout .companies-header .confirm-delete-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .companies-layout .companies-header .confirm-delete-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .companies-layout .companies-header .confirm-delete-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
      .companies-layout .companies-header .confirm-delete-popup .popup-container {
        text-align: center;
        padding-bottom: 0; }
      .companies-layout .companies-header .confirm-delete-popup .popup-content {
        padding: 0 64px 52px 64px; }
        .companies-layout .companies-header .confirm-delete-popup .popup-content .header {
          padding: 64px 0 56px 0; }
        .companies-layout .companies-header .confirm-delete-popup .popup-content .body {
          margin-bottom: 32px; }
        .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset {
          display: flex; }
          .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field {
            flex: 1;
            margin: 0 12px; }
            .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              background: none;
              border: 1px solid #4b4b4b;
              color: #4b4b4b;
              width: 100%;
              font-size: 16px; }
              .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .text,
              .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
                display: inline-block;
                vertical-align: top; }
              .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
                margin-right: 0; }
              .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
    font-weight: normal; } }
              .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
                background: #f2f5fb; }
            .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              width: 100%;
              font-size: 16px; }
              .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
              .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
                display: inline-block;
                vertical-align: top; }
              .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
                margin-right: 0; }
              .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
      .companies-layout .companies-header .confirm-delete-popup.show .popup-container {
        animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

@media screen and (max-width: 450px) {
  .companies-layout .companies-header .confirm-delete-popup .popup-content {
    padding-right: 32px;
    padding-left: 32px; }
    .companies-layout .companies-header .confirm-delete-popup .popup-content .header {
      padding: 32px 0 28px 0; }
    .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; } }
      .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        background: #f35e84;
        width: 100%;
        font-size: 16px; }
        .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
        .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
          display: inline-block;
          vertical-align: top; }
        .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
          margin-right: 0; }
        .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
        .companies-layout .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
          background: #e64871; }
      .companies-layout .companies-header .confirm-delete-popup p {
        color: #4b4b4b; }
    .companies-layout .companies-header .header-actions {
      position: relative;
      overflow: visible;
      padding-left: 10px;
      position: absolute;
      top: 0;
      right: 0; }
      .companies-layout .companies-header .header-actions .actions-button {
        position: relative;
        background: none;
        border: none;
        font-size: 17px;
        color: #8494a9; }
      .companies-layout .companies-header .header-actions .actions-popup {
        position: relative;
        display: none;
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        z-index: 20;
        display: none;
        position: absolute;
        top: 0;
        right: -30px;
        padding: 18px 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0; }
        .companies-layout .companies-header .header-actions .actions-popup.show {
          display: block; }
        .companies-layout .companies-header .header-actions .actions-popup .close-button {
          width: 19px;
          height: 19px;
          background: url(images/icon-close.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          position: absolute;
          top: 25px;
          right: 25px; }

@media screen and (max-width: 500px) {
  .companies-layout .companies-header .header-actions .actions-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .companies-layout .companies-header .header-actions .actions-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .companies-layout .companies-header .header-actions .actions-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
        .companies-layout .companies-header .header-actions .actions-popup .action-item {
          position: relative;
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b;
          display: block;
          min-width: 260px;
          padding: 5px 0 5px 60px;
          font-size: 16px;
          text-align: left; }
          .companies-layout .companies-header .header-actions .actions-popup .action-item:hover {
            color: black; }
          .companies-layout .companies-header .header-actions .actions-popup .action-item::after {
            content: "";
            display: block;
            position: absolute;
            top: 5px;
            left: 25px; }
          .companies-layout .companies-header .header-actions .actions-popup .action-item.action-edit::after {
            width: 22px;
            height: 22px;
            background: url(images/icon-edit-black.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .companies-layout .companies-header .header-actions .actions-popup .action-item.action-copy::after {
            width: 19px;
            height: 20px;
            background: url(images/icon-copy-link.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .companies-layout .companies-header .header-actions .actions-popup .action-item.action-delete::after {
            width: 19px;
            height: 19px;
            background: url(images/icon-close-black.png) no-repeat center center;
            display: inline-block;
            background-size: contain;
            top: 9px;
            left: 27px;
            width: 14px;
            height: 14px; }
      .companies-layout .companies-header .header-actions.opened .actions-button {
        z-index: 30; }
      .companies-layout .companies-header .header-actions.opened .actions-popup {
        display: block; }

@media screen and (max-width: 450px) {
  .companies-layout .companies-header .header-actions .actions-popup .close-button {
    width: 19px;
    height: 19px;
    background: url(images/icon-close.png) no-repeat center center;
    display: inline-block;
    background-size: contain;
    top: 23px;
    right: 23px;
    left: auto; } }
      .companies-layout .companies-header .header-actions .actions-button {
        padding: 15px 20px;
        color: #fff; }
      .companies-layout .companies-header .header-actions .actions-popup {
        right: 0;
        border-radius: 10px; }
      .companies-layout .companies-header .header-actions.opened .actions-button {
        color: #4b4b4b; }
  .companies-layout .companies-action a,
  .companies-layout .companies-action button {
    background: none;
    border: none;
    cursor: pointer;
    color: #4b4b4b; }
    .companies-layout .companies-action a:hover,
    .companies-layout .companies-action button:hover {
      color: black; }

.companies-sidebar {
  width: 240px;
  flex-shrink: 0;
  margin-right: 18px;
  overflow: auto; }
  .companies-sidebar .sidebar-plate {
    display: block;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: box-shadow 0.1s ease;
    -moz-transition: box-shadow 0.1s ease;
    -ms-transition: box-shadow 0.1s ease;
    -o-transition: box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease;
    padding: 24px 16px; }

@media screen and (max-width: 450px) {
  .companies-sidebar .sidebar-plate {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
  .companies-sidebar .block-delim {
    display: block;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
    margin: 40px 0 35px 0; }
  .companies-sidebar .header-content label {
    margin-bottom: 12px;
    color: #8494a9; }
  .companies-sidebar .header-content .price-value .price {
    font-size: 0; }
    .companies-sidebar .header-content .price-value .price .int,
    .companies-sidebar .header-content .price-value .price .delim {
      font-size: 17px; }
    .companies-sidebar .header-content .price-value .price .float,
    .companies-sidebar .header-content .price-value .price .unit {
      font-size: 14px; }
    .companies-sidebar .header-content .price-value .price .unit {
      margin-left: 8px; }
    .companies-sidebar .header-content .price-value .price .unit-ruble:after {
      content: "\20bd"; }
    .companies-sidebar .header-content .price-value .price.income, .companies-sidebar .header-content .price-value .price.transfer-income {
      color: #4dbd7d; }
      .companies-sidebar .header-content .price-value .price.income .int,
      .companies-sidebar .header-content .price-value .price.income .delim,
      .companies-sidebar .header-content .price-value .price.income .float,
      .companies-sidebar .header-content .price-value .price.income .unit, .companies-sidebar .header-content .price-value .price.transfer-income .int,
      .companies-sidebar .header-content .price-value .price.transfer-income .delim,
      .companies-sidebar .header-content .price-value .price.transfer-income .float,
      .companies-sidebar .header-content .price-value .price.transfer-income .unit {
        color: #4dbd7d; }
  .companies-sidebar .header-actions {
    margin-top: 20px; }
    .companies-sidebar .header-actions button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: linear-gradient(73.01deg, #e9e122 8.78%, #f64399 113.32%), #956dbb;
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
      .companies-sidebar .header-actions button .text,
      .companies-sidebar .header-actions button .icon {
        display: inline-block;
        vertical-align: top; }
      .companies-sidebar .header-actions button .icon {
        margin-right: 0; }
      .companies-sidebar .header-actions button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .companies-sidebar .header-actions button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .companies-sidebar .header-actions button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .companies-sidebar .header-actions button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .companies-sidebar .header-actions button {
    font-weight: normal; } }
      .companies-sidebar .header-actions button:hover {
        background: linear-gradient(73.01deg, #e0d820 8.78%, #e8398d 113.32%), #956dbb; }
  .companies-sidebar .companies-label {
    display: block;
    margin-bottom: 12px;
    color: #8494a9; }
  .companies-sidebar .companies-action {
    display: none;
    margin-bottom: 30px; }
    .companies-sidebar .companies-action button,
    .companies-sidebar .companies-action a {
      background: none;
      border: none;
      cursor: pointer;
      color: #8494a9; }
      .companies-sidebar .companies-action button:hover,
      .companies-sidebar .companies-action a:hover {
        color: #5c6e84; }
    .companies-sidebar .companies-action.show {
      display: block; }
  .companies-sidebar .companies-compact-list {
    display: none; }
    .companies-sidebar .companies-compact-list .companies-list-item:hover {
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
    .companies-sidebar .companies-compact-list.show {
      display: block; }
  .companies-sidebar .dropdown-button {
    background: none;
    border: none;
    float: right;
    color: #8494a9; }
    .companies-sidebar .dropdown-button:after {
      content: "";
      width: 14px;
      height: 7px;
      background: url(images/arrow-bottom.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: relative;
      top: -2px;
      width: 7px;
      margin-left: 10px; }
  .companies-sidebar .active-company {
    display: block;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: box-shadow 0.1s ease;
    -moz-transition: box-shadow 0.1s ease;
    -ms-transition: box-shadow 0.1s ease;
    -o-transition: box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease;
    display: flex;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    padding: 20px;
    margin-bottom: 16px;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
    -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
    -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0;
    -moz-box-shadow: 0;
    -ms-box-shadow: 0;
    -o-box-shadow: 0;
    box-shadow: 0;
    margin-bottom: 24px;
    background: radial-gradient(122.91% 173.67% at 84.84% -3.52%, #d15ed3 0%, #2c3aba 100%);
    cursor: pointer; }

@media screen and (max-width: 450px) {
  .companies-sidebar .active-company {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
    .companies-sidebar .active-company .list-item-col {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .companies-sidebar .active-company .list-item-col.icon {
        width: 38px;
        flex-shrink: 0; }
      .companies-sidebar .active-company .list-item-col.caption {
        margin-right: auto;
        margin-left: 18px; }
      .companies-sidebar .active-company .list-item-col.price {
        flex-shrink: 0;
        text-align: right; }
      .companies-sidebar .active-company .list-item-col .note {
        font-size: 12px;
        color: #8494a9; }
    .companies-sidebar .active-company .icon {
      width: 24px; }
      .companies-sidebar .active-company .icon .icon-content {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 23px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        background: #bed6f9;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%; }
        .companies-sidebar .active-company .icon .icon-content.icon-sub-correction {
          line-height: 21.6px; }
        .companies-sidebar .active-company .icon .icon-content.color-1 {
          background: #bed6f9; }
        .companies-sidebar .active-company .icon .icon-content.color-2 {
          background: #b9e398; }
        .companies-sidebar .active-company .icon .icon-content.color-3 {
          background: #e4e597; }
        .companies-sidebar .active-company .icon .icon-content.color-4 {
          background: #e7c740; }
        .companies-sidebar .active-company .icon .icon-content.color-5 {
          background: #e74040; }
        .companies-sidebar .active-company .icon .icon-content.color-6 {
          background: #cf91af; }
    .companies-sidebar .active-company .info {
      margin-left: 8px;
      margin-right: auto;
      white-space: normal;
      color: #4b4b4b; }
      .companies-sidebar .active-company .info .caption,
      .companies-sidebar .active-company .info .price {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
      .companies-sidebar .active-company .info .price {
        font-size: 0;
        color: #8494a9; }
        .companies-sidebar .active-company .info .price .int,
        .companies-sidebar .active-company .info .price .delim {
          font-size: 17px; }
        .companies-sidebar .active-company .info .price .float,
        .companies-sidebar .active-company .info .price .unit {
          font-size: 14px; }
        .companies-sidebar .active-company .info .price .unit {
          margin-left: 8px; }
        .companies-sidebar .active-company .info .price .unit-ruble:after {
          content: "\20bd"; }
        .companies-sidebar .active-company .info .price.income, .companies-sidebar .active-company .info .price.transfer-income {
          color: #4dbd7d; }
          .companies-sidebar .active-company .info .price.income .int,
          .companies-sidebar .active-company .info .price.income .delim,
          .companies-sidebar .active-company .info .price.income .float,
          .companies-sidebar .active-company .info .price.income .unit, .companies-sidebar .active-company .info .price.transfer-income .int,
          .companies-sidebar .active-company .info .price.transfer-income .delim,
          .companies-sidebar .active-company .info .price.transfer-income .float,
          .companies-sidebar .active-company .info .price.transfer-income .unit {
            color: #4dbd7d; }
    .companies-sidebar .active-company .info {
      color: #fff; }
      .companies-sidebar .active-company .info .price {
        color: #fff; }
  .companies-sidebar .company-menu button {
    background: none;
    border: none;
    cursor: pointer;
    color: #4b4b4b;
    display: block;
    position: relative;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding: 10px 20px;
    font-weight: normal;
    align-items: start; }
    .companies-sidebar .company-menu button:hover {
      color: black; }
    .companies-sidebar .company-menu button .icon {
      position: absolute;
      top: 0;
      left: 0; }
    .companies-sidebar .company-menu button .icon-about {
      width: 22px;
      height: 23px;
      background: url(images/icon-about-black.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .companies-sidebar .company-menu button .icon-companies,
    .companies-sidebar .company-menu button .icon-categories {
      width: 21px;
      height: 22px;
      background: url(images/icon-companies-black.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .companies-sidebar .company-menu button .icon-settings,
    .companies-sidebar .company-menu button .icon-owners,
    .companies-sidebar .company-menu button .icon-workers,
    .companies-sidebar .company-menu button .icon-partners {
      width: 22px;
      height: 22px;
      background: url(images/icon-profile-black.svg) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .companies-sidebar .company-menu button .icon-license,
    .companies-sidebar .company-menu button .icon-accounts {
      width: 22px;
      height: 22px;
      background: url(images/icon-ruble-black.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .companies-sidebar .company-menu button .icon-logout {
      top: 2px;
      width: 21px;
      height: 19px;
      background: url(images/icon-logout-black.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .companies-sidebar .company-menu button .icon-feedback {
      width: 22px;
      height: 22px;
      background: url(images/icon-edit-black.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .companies-sidebar .company-menu button .icon-telegram {
      width: 22px;
      height: 22px;
      background: url(images/icon-telegram.svg) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .companies-sidebar .company-menu button .icon-whatsapp {
      width: 22px;
      height: 22px;
      background: url(images/icon-whatsapp.svg) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .companies-sidebar .company-menu button .text {
      display: block;
      margin-left: 34px; }
    .companies-sidebar .company-menu button.button-telegram {
      color: #47aaed; }
    .companies-sidebar .company-menu button.button-whatsapp {
      color: #4cba3f; }
    .companies-sidebar .company-menu button .icon-xlsx {
      width: 22px;
      height: 22px;
      background: url(images/icon-xlsx.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .companies-sidebar .company-menu button .icon-pdf {
      width: 22px;
      height: 22px;
      background: url(images/icon-pdf.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .companies-sidebar .company-menu button .icon-word {
      width: 22px;
      height: 22px;
      background: url(images/icon-word.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .companies-sidebar .company-menu button .icon-print {
      width: 23px;
      height: 16px;
      background: url(images/icon-print.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .companies-sidebar .company-menu button .icon {
      top: 50%;
      left: 20px;
      margin: -10px 0 0 0; }
    .companies-sidebar .company-menu button.active {
      color: #4b4b4b; }
      .companies-sidebar .company-menu button.active .icon {
        filter: grayscale(1) brightness(0%); }
      .companies-sidebar .company-menu button.active:after {
        content: "";
        position: absolute;
        top: 0;
        left: -16px;
        height: 100%;
        width: 3px;
        background: linear-gradient(73.01deg, #e9e122 8.78%, #f64399 113.32%), #956dbb; }
  .companies-sidebar.sticky {
    position: fixed; }
  .companies-sidebar.overflowed {
    scrollbar-width: thin; }
    .companies-sidebar.overflowed::-webkit-scrollbar {
      width: 0px; }

.company-layout,
.transactions-layout {
  position: relative;
  display: flex;
  justify-content: space-between; }
  .company-layout .company-page-left-col,
  .transactions-layout .company-page-left-col {
    width: 550px; }
    .company-layout .company-page-left-col .operations,
    .transactions-layout .company-page-left-col .operations {
      display: block;
      background: #fff;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border-radius: 10px;
      -webkit-transition: box-shadow 0.1s ease;
      -moz-transition: box-shadow 0.1s ease;
      -ms-transition: box-shadow 0.1s ease;
      -o-transition: box-shadow 0.1s ease;
      transition: box-shadow 0.1s ease;
      padding-bottom: 34px; }

@media screen and (max-width: 450px) {
  .company-layout .company-page-left-col .operations,
  .transactions-layout .company-page-left-col .operations {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
      .company-layout .company-page-left-col .operations .operations-header,
      .transactions-layout .company-page-left-col .operations .operations-header {
        background: none;
        border: none;
        font-size: 17px;
        font-weight: 500;
        padding: 24px; }
      .company-layout .company-page-left-col .operations .operations-button,
      .transactions-layout .company-page-left-col .operations .operations-button {
        margin: 0 24px; }
        .company-layout .company-page-left-col .operations .operations-button a,
        .company-layout .company-page-left-col .operations .operations-button button,
        .transactions-layout .company-page-left-col .operations .operations-button a,
        .transactions-layout .company-page-left-col .operations .operations-button button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          background: none;
          border: 1px solid #4b4b4b;
          color: #4b4b4b;
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px;
          width: 100%; }
          .company-layout .company-page-left-col .operations .operations-button a .text,
          .company-layout .company-page-left-col .operations .operations-button a .icon,
          .company-layout .company-page-left-col .operations .operations-button button .text,
          .company-layout .company-page-left-col .operations .operations-button button .icon,
          .transactions-layout .company-page-left-col .operations .operations-button a .text,
          .transactions-layout .company-page-left-col .operations .operations-button a .icon,
          .transactions-layout .company-page-left-col .operations .operations-button button .text,
          .transactions-layout .company-page-left-col .operations .operations-button button .icon {
            display: inline-block;
            vertical-align: top; }
          .company-layout .company-page-left-col .operations .operations-button a .icon,
          .company-layout .company-page-left-col .operations .operations-button button .icon,
          .transactions-layout .company-page-left-col .operations .operations-button a .icon,
          .transactions-layout .company-page-left-col .operations .operations-button button .icon {
            margin-right: 0; }
          .company-layout .company-page-left-col .operations .operations-button a.button-xs,
          .company-layout .company-page-left-col .operations .operations-button button.button-xs,
          .transactions-layout .company-page-left-col .operations .operations-button a.button-xs,
          .transactions-layout .company-page-left-col .operations .operations-button button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .company-layout .company-page-left-col .operations .operations-button a.button-s,
          .company-layout .company-page-left-col .operations .operations-button button.button-s,
          .transactions-layout .company-page-left-col .operations .operations-button a.button-s,
          .transactions-layout .company-page-left-col .operations .operations-button button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .company-layout .company-page-left-col .operations .operations-button a.button-l,
          .company-layout .company-page-left-col .operations .operations-button button.button-l,
          .transactions-layout .company-page-left-col .operations .operations-button a.button-l,
          .transactions-layout .company-page-left-col .operations .operations-button button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .company-layout .company-page-left-col .operations .operations-button a:hover,
          .company-layout .company-page-left-col .operations .operations-button button:hover,
          .transactions-layout .company-page-left-col .operations .operations-button a:hover,
          .transactions-layout .company-page-left-col .operations .operations-button button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-layout .company-page-left-col .operations .operations-button a,
  .company-layout .company-page-left-col .operations .operations-button button,
  .transactions-layout .company-page-left-col .operations .operations-button a,
  .transactions-layout .company-page-left-col .operations .operations-button button {
    font-weight: normal; } }
          .company-layout .company-page-left-col .operations .operations-button a:hover,
          .company-layout .company-page-left-col .operations .operations-button button:hover,
          .transactions-layout .company-page-left-col .operations .operations-button a:hover,
          .transactions-layout .company-page-left-col .operations .operations-button button:hover {
            background: #f2f5fb; }
      .company-layout .company-page-left-col .operations.filter-closed .field-name-date_range,
      .company-layout .company-page-left-col .operations.filter-closed .field-name-partners,
      .company-layout .company-page-left-col .operations.filter-closed .field-name-price_range,
      .company-layout .company-page-left-col .operations.filter-closed .field-name-categories,
      .company-layout .company-page-left-col .operations.filter-closed .field-name-accounts,
      .transactions-layout .company-page-left-col .operations.filter-closed .field-name-date_range,
      .transactions-layout .company-page-left-col .operations.filter-closed .field-name-partners,
      .transactions-layout .company-page-left-col .operations.filter-closed .field-name-price_range,
      .transactions-layout .company-page-left-col .operations.filter-closed .field-name-categories,
      .transactions-layout .company-page-left-col .operations.filter-closed .field-name-accounts {
        display: none; }
    .company-layout .company-page-left-col .companies-header,
    .transactions-layout .company-page-left-col .companies-header {
      display: block;
      background: #fff;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border-radius: 10px;
      -webkit-transition: box-shadow 0.1s ease;
      -moz-transition: box-shadow 0.1s ease;
      -ms-transition: box-shadow 0.1s ease;
      -o-transition: box-shadow 0.1s ease;
      transition: box-shadow 0.1s ease;
      background: url(images/accent-plate.jpg) no-repeat #6880fc;
      background-size: cover;
      color: #fff;
      position: relative;
      height: 170px;
      position: relative;
      height: 210px;
      margin-bottom: 16px; }

@media screen and (max-width: 450px) {
  .company-layout .company-page-left-col .companies-header,
  .transactions-layout .company-page-left-col .companies-header {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
      .company-layout .company-page-left-col .companies-header label,
      .company-layout .company-page-left-col .companies-header p,
      .company-layout .company-page-left-col .companies-header a,
      .transactions-layout .company-page-left-col .companies-header label,
      .transactions-layout .company-page-left-col .companies-header p,
      .transactions-layout .company-page-left-col .companies-header a {
        color: #fff; }
      .company-layout .company-page-left-col .companies-header .header-content,
      .transactions-layout .company-page-left-col .companies-header .header-content {
        position: absolute;
        bottom: 24px;
        left: 37px; }
      .company-layout .company-page-left-col .companies-header .price-value,
      .transactions-layout .company-page-left-col .companies-header .price-value {
        font-size: 0;
        font-weight: 500; }
        .company-layout .company-page-left-col .companies-header .price-value .int,
        .company-layout .company-page-left-col .companies-header .price-value .delim,
        .transactions-layout .company-page-left-col .companies-header .price-value .int,
        .transactions-layout .company-page-left-col .companies-header .price-value .delim {
          font-size: 54px; }
        .company-layout .company-page-left-col .companies-header .price-value .float,
        .company-layout .company-page-left-col .companies-header .price-value .unit,
        .transactions-layout .company-page-left-col .companies-header .price-value .float,
        .transactions-layout .company-page-left-col .companies-header .price-value .unit {
          font-size: 32px; }
        .company-layout .company-page-left-col .companies-header .price-value .unit,
        .transactions-layout .company-page-left-col .companies-header .price-value .unit {
          margin-left: 8px; }
        .company-layout .company-page-left-col .companies-header .price-value .unit-ruble:after,
        .transactions-layout .company-page-left-col .companies-header .price-value .unit-ruble:after {
          content: "\20bd"; }
        .company-layout .company-page-left-col .companies-header .price-value.income, .company-layout .company-page-left-col .companies-header .price-value.transfer-income,
        .transactions-layout .company-page-left-col .companies-header .price-value.income,
        .transactions-layout .company-page-left-col .companies-header .price-value.transfer-income {
          color: #4dbd7d; }
          .company-layout .company-page-left-col .companies-header .price-value.income .int,
          .company-layout .company-page-left-col .companies-header .price-value.income .delim,
          .company-layout .company-page-left-col .companies-header .price-value.income .float,
          .company-layout .company-page-left-col .companies-header .price-value.income .unit, .company-layout .company-page-left-col .companies-header .price-value.transfer-income .int,
          .company-layout .company-page-left-col .companies-header .price-value.transfer-income .delim,
          .company-layout .company-page-left-col .companies-header .price-value.transfer-income .float,
          .company-layout .company-page-left-col .companies-header .price-value.transfer-income .unit,
          .transactions-layout .company-page-left-col .companies-header .price-value.income .int,
          .transactions-layout .company-page-left-col .companies-header .price-value.income .delim,
          .transactions-layout .company-page-left-col .companies-header .price-value.income .float,
          .transactions-layout .company-page-left-col .companies-header .price-value.income .unit,
          .transactions-layout .company-page-left-col .companies-header .price-value.transfer-income .int,
          .transactions-layout .company-page-left-col .companies-header .price-value.transfer-income .delim,
          .transactions-layout .company-page-left-col .companies-header .price-value.transfer-income .float,
          .transactions-layout .company-page-left-col .companies-header .price-value.transfer-income .unit {
            color: #4dbd7d; }
        .company-layout .company-page-left-col .companies-header .price-value .int,
        .company-layout .company-page-left-col .companies-header .price-value .delim,
        .transactions-layout .company-page-left-col .companies-header .price-value .int,
        .transactions-layout .company-page-left-col .companies-header .price-value .delim {
          line-height: 54px; }
        .company-layout .company-page-left-col .companies-header .price-value .float,
        .company-layout .company-page-left-col .companies-header .price-value .unit,
        .transactions-layout .company-page-left-col .companies-header .price-value .float,
        .transactions-layout .company-page-left-col .companies-header .price-value .unit {
          line-height: 32px; }
      .company-layout .company-page-left-col .companies-header .companies-selector,
      .transactions-layout .company-page-left-col .companies-header .companies-selector {
        position: absolute;
        top: 32px;
        left: 32px;
        right: 32px; }
      .company-layout .company-page-left-col .companies-header .companies-selector button,
      .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button,
      .transactions-layout .company-page-left-col .companies-header .companies-selector button,
      .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        background: none;
        border: 1px solid #fff;
        color: #fff;
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px;
        height: 34px;
        max-width: 320px;
        line-height: 22px;
        -webkit-border-radius: 17px;
        -moz-border-radius: 17px;
        -ms-border-radius: 17px;
        -o-border-radius: 17px;
        border-radius: 17px;
        font-size: 17px;
        white-space: nowrap;
        overflow: hidden; }
        .company-layout .company-page-left-col .companies-header .companies-selector button .text,
        .company-layout .company-page-left-col .companies-header .companies-selector button .icon,
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button .text,
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button .icon,
        .transactions-layout .company-page-left-col .companies-header .companies-selector button .text,
        .transactions-layout .company-page-left-col .companies-header .companies-selector button .icon,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button .text,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button .icon {
          display: inline-block;
          vertical-align: top; }
        .company-layout .company-page-left-col .companies-header .companies-selector button .icon,
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button .icon,
        .transactions-layout .company-page-left-col .companies-header .companies-selector button .icon,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button .icon {
          margin-right: 0; }
        .company-layout .company-page-left-col .companies-header .companies-selector button.button-xs,
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button.button-xs,
        .transactions-layout .company-page-left-col .companies-header .companies-selector button.button-xs,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .company-layout .company-page-left-col .companies-header .companies-selector button.button-s,
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button.button-s,
        .transactions-layout .company-page-left-col .companies-header .companies-selector button.button-s,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .company-layout .company-page-left-col .companies-header .companies-selector button.button-l,
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button.button-l,
        .transactions-layout .company-page-left-col .companies-header .companies-selector button.button-l,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .company-layout .company-page-left-col .companies-header .companies-selector button:hover,
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button:hover,
        .transactions-layout .company-page-left-col .companies-header .companies-selector button:hover,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-layout .company-page-left-col .companies-header .companies-selector button,
  .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button,
  .transactions-layout .company-page-left-col .companies-header .companies-selector button,
  .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button {
    font-weight: normal; } }
        .company-layout .company-page-left-col .companies-header .companies-selector button:hover,
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button:hover,
        .transactions-layout .company-page-left-col .companies-header .companies-selector button:hover,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button:hover {
          background: rgba(255, 255, 255, 0.1); }
        .company-layout .company-page-left-col .companies-header .companies-selector button .icon,
        .company-layout .company-page-left-col .companies-header .companies-selector button .text,
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button .icon,
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button .text,
        .transactions-layout .company-page-left-col .companies-header .companies-selector button .icon,
        .transactions-layout .company-page-left-col .companies-header .companies-selector button .text,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button .icon,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button .text {
          vertical-align: top; }
        .company-layout .company-page-left-col .companies-header .companies-selector button .text,
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button .text,
        .transactions-layout .company-page-left-col .companies-header .companies-selector button .text,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button .text {
          max-width: 90%;
          overflow: hidden;
          text-overflow: ellipsis; }
        .company-layout .company-page-left-col .companies-header .companies-selector button .icon,
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button .icon,
        .transactions-layout .company-page-left-col .companies-header .companies-selector button .icon,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button .icon {
          width: 12px;
          height: 10px;
          background: url(images/icon-burger-white.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          margin-top: 6px;
          margin-right: 8px; }
      .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button,
      .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button {
        display: block;
        max-width: 80%; }
      .company-layout .company-page-left-col .companies-header .companies-selector-popup,
      .transactions-layout .company-page-left-col .companies-header .companies-selector-popup {
        position: relative;
        display: none;
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        z-index: 20;
        position: absolute;
        top: 22px;
        left: 24px;
        padding-bottom: 32px;
        min-width: 500px; }
        .company-layout .company-page-left-col .companies-header .companies-selector-popup.show,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup.show {
          display: block; }
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .close-button,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .close-button {
          width: 19px;
          height: 19px;
          background: url(images/icon-close.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          position: absolute;
          top: 25px;
          right: 25px; }

@media screen and (max-width: 500px) {
  .company-layout .company-page-left-col .companies-header .companies-selector-popup,
  .transactions-layout .company-page-left-col .companies-header .companies-selector-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .company-layout .company-page-left-col .companies-header .companies-selector-popup,
  .transactions-layout .company-page-left-col .companies-header .companies-selector-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .company-layout .company-page-left-col .companies-header .companies-selector-popup .close-button,
    .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button {
          margin: 11px 0 23px 8px;
          color: #4b4b4b;
          border-color: #4b4b4b; }
          .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button .icon,
          .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button .icon {
            background: url(images/icon-burger-black.png) no-repeat center center;
            background-size: contain; }
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .checklist,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .checklist {
          min-width: 240px;
          padding-bottom: 14px; }
          .company-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button,
          .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button {
            position: relative;
            display: block;
            width: 390px;
            height: 48px;
            line-height: 48px;
            padding-right: 32px;
            background: none;
            font-size: 17px;
            color: #4b4b4b; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button .icon,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button .icon {
              position: absolute;
              top: 13px;
              left: 24px;
              display: inline-block;
              width: 18px;
              height: 18px;
              border: 2px solid #8494a9;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              -ms-border-radius: 3px;
              -o-border-radius: 3px;
              border-radius: 3px; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button .icon-checked,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button .icon-checked {
              position: relative;
              display: inline-block;
              width: 22px;
              height: 22px;
              background: #81bc52;
              border: 0;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              -ms-border-radius: 3px;
              -o-border-radius: 3px;
              border-radius: 3px;
              position: absolute;
              top: 13px;
              left: 24px; }
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button .icon-checked::after,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button .icon-checked::after {
                content: "";
                width: 16px;
                height: 12px;
                background: url(images/icon-check-white.png) no-repeat center center;
                display: inline-block;
                background-size: contain;
                position: absolute;
                top: 7px;
                left: 4px;
                width: 13px;
                height: 9px; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button .text,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button .text {
              display: block;
              margin-left: 60px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              font-size: 14px; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button:hover,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button:hover {
              background: #f2f5fb; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button.hidden,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button.hidden {
              display: none; }

@media screen and (max-width: 500px) {
  .company-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button,
  .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button {
    width: 100%;
    box-sizing: border-box; }
    .company-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button .icon,
    .company-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button .icon-checked,
    .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button .icon,
    .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button .icon-checked {
      top: 7px; } }

@media screen and (max-width: 450px) {
  .company-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button,
  .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .checklist button {
    font-weight: normal;
    height: 36px;
    line-height: 36px; } }
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-action-container,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-action-container {
          display: none; }
          .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-action-container button,
          .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-action-container button {
            background: none;
            border: none;
            cursor: pointer;
            color: #8494a9; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-action-container button:hover,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-action-container button:hover {
              color: #5c6e84; }
          .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-action-container.show,
          .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-action-container.show {
            display: block;
            margin-bottom: 30px; }
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-delim,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-delim {
          height: 1px;
          margin: 28px 0;
          background: rgba(0, 0, 0, 0.1); }
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container {
          margin: 0 0 30px 90px; }
          .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-label,
          .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-label {
            margin-bottom: 6px;
            color: #8494a9; }
          .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value,
          .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value {
            font-size: 0;
            color: #4b4b4b; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value .int,
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value .delim,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value .int,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value .delim {
              font-size: 17px; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value .float,
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value .unit,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value .float,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value .unit {
              font-size: 14px; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value .unit,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value .unit {
              margin-left: 8px; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value .unit-ruble:after,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value .unit-ruble:after {
              content: "\20bd"; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.income, .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.transfer-income,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.income,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.transfer-income {
              color: #4dbd7d; }
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.income .int,
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.income .delim,
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.income .float,
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.income .unit, .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.transfer-income .int,
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.transfer-income .delim,
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.transfer-income .float,
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.transfer-income .unit,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.income .int,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.income .delim,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.income .float,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.income .unit,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.transfer-income .int,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.transfer-income .delim,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.transfer-income .float,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-balance-container .balance-value.transfer-income .unit {
                color: #4dbd7d; }
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu {
          margin-left: 22px; }
          .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button,
          .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button {
            background: none;
            border: none;
            cursor: pointer;
            color: #4b4b4b;
            display: block;
            position: relative;
            font-size: 15px;
            font-weight: 500;
            text-align: left;
            box-sizing: border-box;
            margin-bottom: 16px; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button:hover,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button:hover {
              color: black; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon {
              position: absolute;
              top: 0;
              left: 0; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-about,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-about {
              width: 22px;
              height: 23px;
              background: url(images/icon-about-black.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-companies,
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-categories,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-companies,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-categories {
              width: 21px;
              height: 22px;
              background: url(images/icon-companies-black.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-settings,
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-owners,
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-workers,
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-partners,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-settings,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-owners,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-workers,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-partners {
              width: 22px;
              height: 22px;
              background: url(images/icon-profile-black.svg) no-repeat center center;
              display: inline-block;
              background-size: contain; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-license,
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-accounts,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-license,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-accounts {
              width: 22px;
              height: 22px;
              background: url(images/icon-ruble-black.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-logout,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-logout {
              top: 2px;
              width: 21px;
              height: 19px;
              background: url(images/icon-logout-black.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-feedback,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-feedback {
              width: 22px;
              height: 22px;
              background: url(images/icon-edit-black.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-telegram,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-telegram {
              width: 22px;
              height: 22px;
              background: url(images/icon-telegram.svg) no-repeat center center;
              display: inline-block;
              background-size: contain; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-whatsapp,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-whatsapp {
              width: 22px;
              height: 22px;
              background: url(images/icon-whatsapp.svg) no-repeat center center;
              display: inline-block;
              background-size: contain; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .text,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .text {
              display: block;
              margin-left: 34px; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button.button-telegram,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button.button-telegram {
              color: #47aaed; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button.button-whatsapp,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button.button-whatsapp {
              color: #4cba3f; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-xlsx,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-xlsx {
              width: 22px;
              height: 22px;
              background: url(images/icon-xlsx.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-pdf,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-pdf {
              width: 22px;
              height: 22px;
              background: url(images/icon-pdf.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-word,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-word {
              width: 22px;
              height: 22px;
              background: url(images/icon-word.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-print,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .company-menu button .icon-print {
              width: 23px;
              height: 16px;
              background: url(images/icon-print.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .companies-label,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .companies-label {
          display: block;
          margin-bottom: 12px;
          color: #8494a9; }
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .companies-compact-list,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .companies-compact-list {
          display: none; }
          .company-layout .company-page-left-col .companies-header .companies-selector-popup .companies-compact-list .companies-list-item:hover,
          .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .companies-compact-list .companies-list-item:hover {
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
          .company-layout .company-page-left-col .companies-header .companies-selector-popup .companies-compact-list.show,
          .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .companies-compact-list.show {
            display: block; }
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .dropdown-button,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .dropdown-button {
          background: none;
          border: none;
          float: right;
          color: #8494a9; }
          .company-layout .company-page-left-col .companies-header .companies-selector-popup .dropdown-button:after,
          .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .dropdown-button:after {
            content: "";
            width: 14px;
            height: 7px;
            background: url(images/arrow-bottom.png) no-repeat center center;
            display: inline-block;
            background-size: contain;
            position: relative;
            top: -2px;
            width: 7px;
            margin-left: 10px; }
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          display: flex;
          justify-content: space-between;
          text-align: left;
          width: 100%;
          padding: 20px;
          margin-bottom: 16px;
          box-sizing: border-box;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-border-radius: 4px;
          border-radius: 4px;
          -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
          -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
          -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
          -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
          -webkit-box-shadow: 0;
          -moz-box-shadow: 0;
          -ms-box-shadow: 0;
          -o-box-shadow: 0;
          box-shadow: 0;
          margin-bottom: 24px;
          background: radial-gradient(122.91% 173.67% at 84.84% -3.52%, #d15ed3 0%, #2c3aba 100%);
          cursor: pointer; }

@media screen and (max-width: 450px) {
  .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company,
  .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
          .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .list-item-col,
          .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .list-item-col {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .list-item-col.icon,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .list-item-col.icon {
              width: 38px;
              flex-shrink: 0; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .list-item-col.caption,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .list-item-col.caption {
              margin-right: auto;
              margin-left: 18px; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .list-item-col.price,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .list-item-col.price {
              flex-shrink: 0;
              text-align: right; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .list-item-col .note,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .list-item-col .note {
              font-size: 12px;
              color: #8494a9; }
          .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon,
          .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon {
            width: 24px; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon .icon-content,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon .icon-content {
              display: inline-block;
              width: 24px;
              height: 24px;
              line-height: 23px;
              text-align: center;
              font-size: 14px;
              font-weight: 500;
              color: #fff;
              background: #bed6f9;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
              border-radius: 50%; }
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon .icon-content.icon-sub-correction,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon .icon-content.icon-sub-correction {
                line-height: 21.6px; }
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon .icon-content.color-1,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon .icon-content.color-1 {
                background: #bed6f9; }
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon .icon-content.color-2,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon .icon-content.color-2 {
                background: #b9e398; }
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon .icon-content.color-3,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon .icon-content.color-3 {
                background: #e4e597; }
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon .icon-content.color-4,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon .icon-content.color-4 {
                background: #e7c740; }
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon .icon-content.color-5,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon .icon-content.color-5 {
                background: #e74040; }
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon .icon-content.color-6,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .icon .icon-content.color-6 {
                background: #cf91af; }
          .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info,
          .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info {
            margin-left: 8px;
            margin-right: auto;
            white-space: normal;
            color: #4b4b4b; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .caption,
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .caption,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price {
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price {
              font-size: 0;
              color: #8494a9; }
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price .int,
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price .delim,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price .int,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price .delim {
                font-size: 17px; }
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price .float,
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price .unit,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price .float,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price .unit {
                font-size: 14px; }
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price .unit,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price .unit {
                margin-left: 8px; }
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price .unit-ruble:after,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price .unit-ruble:after {
                content: "\20bd"; }
              .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.income, .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.transfer-income,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.income,
              .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.transfer-income {
                color: #4dbd7d; }
                .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.income .int,
                .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.income .delim,
                .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.income .float,
                .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.income .unit, .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.transfer-income .int,
                .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.transfer-income .delim,
                .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.transfer-income .float,
                .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.transfer-income .unit,
                .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.income .int,
                .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.income .delim,
                .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.income .float,
                .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.income .unit,
                .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.transfer-income .int,
                .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.transfer-income .delim,
                .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.transfer-income .float,
                .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price.transfer-income .unit {
                  color: #4dbd7d; }
          .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info,
          .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info {
            color: #fff; }
            .company-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price,
            .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .active-company .info .price {
              color: #fff; }
        .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-limiter,
        .transactions-layout .company-page-left-col .companies-header .companies-selector-popup .selector-limiter {
          margin-left: 90px;
          max-width: 326px; }
      .company-layout .company-page-left-col .companies-header .confirm-delete-popup,
      .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup {
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 20;
        background: rgba(255, 255, 255, 0.7);
        animation: popup-background-fade-in 0.8s ease; }
        .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container,
        .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container {
          position: relative;
          display: none;
          background: #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          z-index: 20;
          display: block;
          width: 550px;
          margin: 0 auto;
          padding-bottom: 58px;
          top: 10%;
          max-height: 75%;
          overflow: auto;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          border-radius: 8px;
          /* Scrollbar styles */ }
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container.show,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container.show {
            display: block; }
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .close-button,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .close-button {
            width: 19px;
            height: 19px;
            background: url(images/icon-close.png) no-repeat center center;
            display: inline-block;
            background-size: contain;
            position: absolute;
            top: 25px;
            right: 25px; }

@media screen and (max-width: 500px) {
  .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container,
  .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container,
  .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .close-button,
    .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container::-webkit-scrollbar,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container::-webkit-scrollbar {
            width: 12px;
            height: 12px; }
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container::-webkit-scrollbar-track,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container::-webkit-scrollbar-track {
            background: #f5f5f5;
            border-radius: 10px; }
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container::-webkit-scrollbar-thumb,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #ccc; }
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .popup-content form .form-control .datepicker-popup,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .popup-content form .form-control .datepicker-popup {
            position: fixed;
            top: 50%;
            left: 50%;
            margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .popup-content form .form-control .datepicker-popup,
  .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .popup-content .popup-actions,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .popup-content .popup-actions {
            width: 340px;
            margin: 0 auto; }
            .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .popup-content .popup-actions button,
            .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .popup-content .popup-actions button {
              background: none;
              border: none;
              cursor: pointer;
              color: #4b4b4b; }
              .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .popup-content .popup-actions button:hover,
              .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .popup-content .popup-actions button:hover {
                color: black; }
        .company-layout .company-page-left-col .companies-header .confirm-delete-popup .header,
        .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .header {
          padding: 32px 24px;
          font-size: 22px; }
        .company-layout .company-page-left-col .companies-header .confirm-delete-popup .body,
        .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .body {
          padding: 0 24px 32px 24px; }
        .company-layout .company-page-left-col .companies-header .confirm-delete-popup.show,
        .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup.show {
          display: block; }

@media screen and (max-width: 600px) {
  .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container,
  .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .header,
    .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .header,
  .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
        .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container,
        .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-container {
          text-align: center;
          padding-bottom: 0; }
        .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content,
        .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content {
          padding: 0 64px 52px 64px; }
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .header,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .header {
            padding: 64px 0 56px 0; }
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .body,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .body {
            margin-bottom: 32px; }
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset {
            display: flex; }
            .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field,
            .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field {
              flex: 1;
              margin: 0 12px; }
              .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button,
              .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
                display: inline-block;
                height: 50px;
                line-height: 48px;
                padding: 0 20px;
                text-align: center;
                vertical-align: top;
                background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
                border: 0;
                color: #fff;
                font-size: 17px;
                font-weight: 500;
                box-sizing: border-box;
                -webkit-border-radius: 25px;
                -moz-border-radius: 25px;
                -ms-border-radius: 25px;
                -o-border-radius: 25px;
                border-radius: 25px;
                -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
                -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
                -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
                -o-transition: background 0.1s ease, box-shadow 0.1s ease;
                transition: background 0.1s ease, box-shadow 0.1s ease;
                background: none;
                border: 1px solid #4b4b4b;
                color: #4b4b4b;
                width: 100%;
                font-size: 16px; }
                .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .text,
                .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .icon,
                .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .text,
                .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
                  display: inline-block;
                  vertical-align: top; }
                .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .icon,
                .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
                  margin-right: 0; }
                .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-xs,
                .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-xs {
                  height: 32px;
                  line-height: 32px;
                  font-size: 12px;
                  font-weight: normal;
                  -webkit-border-radius: 15px;
                  -moz-border-radius: 15px;
                  -ms-border-radius: 15px;
                  -o-border-radius: 15px;
                  border-radius: 15px; }
                .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-s,
                .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-s {
                  height: 40px;
                  line-height: 38px;
                  font-size: 14px;
                  font-weight: normal;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  -ms-border-radius: 20px;
                  -o-border-radius: 20px;
                  border-radius: 20px; }
                .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-l,
                .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-l {
                  height: 60px;
                  line-height: 58px;
                  -webkit-border-radius: 30px;
                  -moz-border-radius: 30px;
                  -ms-border-radius: 30px;
                  -o-border-radius: 30px;
                  border-radius: 30px; }
                .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button:hover,
                .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
                  background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button,
  .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
    font-weight: normal; } }
                .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button:hover,
                .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
                  background: #f2f5fb; }
              .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button,
              .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
                display: inline-block;
                height: 50px;
                line-height: 48px;
                padding: 0 20px;
                text-align: center;
                vertical-align: top;
                background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
                border: 0;
                color: #fff;
                font-size: 17px;
                font-weight: 500;
                box-sizing: border-box;
                -webkit-border-radius: 25px;
                -moz-border-radius: 25px;
                -ms-border-radius: 25px;
                -o-border-radius: 25px;
                border-radius: 25px;
                -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
                -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
                -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
                -o-transition: background 0.1s ease, box-shadow 0.1s ease;
                transition: background 0.1s ease, box-shadow 0.1s ease;
                width: 100%;
                font-size: 16px; }
                .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
                .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon,
                .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
                .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
                  display: inline-block;
                  vertical-align: top; }
                .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon,
                .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
                  margin-right: 0; }
                .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs,
                .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
                  height: 32px;
                  line-height: 32px;
                  font-size: 12px;
                  font-weight: normal;
                  -webkit-border-radius: 15px;
                  -moz-border-radius: 15px;
                  -ms-border-radius: 15px;
                  -o-border-radius: 15px;
                  border-radius: 15px; }
                .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s,
                .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
                  height: 40px;
                  line-height: 38px;
                  font-size: 14px;
                  font-weight: normal;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  -ms-border-radius: 20px;
                  -o-border-radius: 20px;
                  border-radius: 20px; }
                .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l,
                .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
                  height: 60px;
                  line-height: 58px;
                  -webkit-border-radius: 30px;
                  -moz-border-radius: 30px;
                  -ms-border-radius: 30px;
                  -o-border-radius: 30px;
                  border-radius: 30px; }
                .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover,
                .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
                  background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button,
  .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
        .company-layout .company-page-left-col .companies-header .confirm-delete-popup.show .popup-container,
        .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup.show .popup-container {
          animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

@media screen and (max-width: 450px) {
  .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content,
  .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content {
    padding-right: 32px;
    padding-left: 32px; }
    .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .header,
    .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .header {
      padding: 32px 0 28px 0; }
    .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button,
    .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button,
    .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; } }
        .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button,
        .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          background: #f35e84;
          width: 100%;
          font-size: 16px; }
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
            display: inline-block;
            vertical-align: top; }
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
            margin-right: 0; }
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button,
  .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
          .company-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover,
          .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
            background: #e64871; }
        .company-layout .company-page-left-col .companies-header .confirm-delete-popup p,
        .transactions-layout .company-page-left-col .companies-header .confirm-delete-popup p {
          color: #4b4b4b; }
      .company-layout .company-page-left-col .companies-header .header-actions,
      .transactions-layout .company-page-left-col .companies-header .header-actions {
        position: relative;
        overflow: visible;
        padding-left: 10px;
        position: absolute;
        top: 0;
        right: 0; }
        .company-layout .company-page-left-col .companies-header .header-actions .actions-button,
        .transactions-layout .company-page-left-col .companies-header .header-actions .actions-button {
          position: relative;
          background: none;
          border: none;
          font-size: 17px;
          color: #8494a9; }
        .company-layout .company-page-left-col .companies-header .header-actions .actions-popup,
        .transactions-layout .company-page-left-col .companies-header .header-actions .actions-popup {
          position: relative;
          display: none;
          background: #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          z-index: 20;
          display: none;
          position: absolute;
          top: 0;
          right: -30px;
          padding: 18px 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .company-layout .company-page-left-col .companies-header .header-actions .actions-popup.show,
          .transactions-layout .company-page-left-col .companies-header .header-actions .actions-popup.show {
            display: block; }
          .company-layout .company-page-left-col .companies-header .header-actions .actions-popup .close-button,
          .transactions-layout .company-page-left-col .companies-header .header-actions .actions-popup .close-button {
            width: 19px;
            height: 19px;
            background: url(images/icon-close.png) no-repeat center center;
            display: inline-block;
            background-size: contain;
            position: absolute;
            top: 25px;
            right: 25px; }

@media screen and (max-width: 500px) {
  .company-layout .company-page-left-col .companies-header .header-actions .actions-popup,
  .transactions-layout .company-page-left-col .companies-header .header-actions .actions-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .company-layout .company-page-left-col .companies-header .header-actions .actions-popup,
  .transactions-layout .company-page-left-col .companies-header .header-actions .actions-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .company-layout .company-page-left-col .companies-header .header-actions .actions-popup .close-button,
    .transactions-layout .company-page-left-col .companies-header .header-actions .actions-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
          .company-layout .company-page-left-col .companies-header .header-actions .actions-popup .action-item,
          .transactions-layout .company-page-left-col .companies-header .header-actions .actions-popup .action-item {
            position: relative;
            background: none;
            border: none;
            cursor: pointer;
            color: #4b4b4b;
            display: block;
            min-width: 260px;
            padding: 5px 0 5px 60px;
            font-size: 16px;
            text-align: left; }
            .company-layout .company-page-left-col .companies-header .header-actions .actions-popup .action-item:hover,
            .transactions-layout .company-page-left-col .companies-header .header-actions .actions-popup .action-item:hover {
              color: black; }
            .company-layout .company-page-left-col .companies-header .header-actions .actions-popup .action-item::after,
            .transactions-layout .company-page-left-col .companies-header .header-actions .actions-popup .action-item::after {
              content: "";
              display: block;
              position: absolute;
              top: 5px;
              left: 25px; }
            .company-layout .company-page-left-col .companies-header .header-actions .actions-popup .action-item.action-edit::after,
            .transactions-layout .company-page-left-col .companies-header .header-actions .actions-popup .action-item.action-edit::after {
              width: 22px;
              height: 22px;
              background: url(images/icon-edit-black.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
            .company-layout .company-page-left-col .companies-header .header-actions .actions-popup .action-item.action-copy::after,
            .transactions-layout .company-page-left-col .companies-header .header-actions .actions-popup .action-item.action-copy::after {
              width: 19px;
              height: 20px;
              background: url(images/icon-copy-link.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
            .company-layout .company-page-left-col .companies-header .header-actions .actions-popup .action-item.action-delete::after,
            .transactions-layout .company-page-left-col .companies-header .header-actions .actions-popup .action-item.action-delete::after {
              width: 19px;
              height: 19px;
              background: url(images/icon-close-black.png) no-repeat center center;
              display: inline-block;
              background-size: contain;
              top: 9px;
              left: 27px;
              width: 14px;
              height: 14px; }
        .company-layout .company-page-left-col .companies-header .header-actions.opened .actions-button,
        .transactions-layout .company-page-left-col .companies-header .header-actions.opened .actions-button {
          z-index: 30; }
        .company-layout .company-page-left-col .companies-header .header-actions.opened .actions-popup,
        .transactions-layout .company-page-left-col .companies-header .header-actions.opened .actions-popup {
          display: block; }

@media screen and (max-width: 450px) {
  .company-layout .company-page-left-col .companies-header .header-actions .actions-popup .close-button,
  .transactions-layout .company-page-left-col .companies-header .header-actions .actions-popup .close-button {
    width: 19px;
    height: 19px;
    background: url(images/icon-close.png) no-repeat center center;
    display: inline-block;
    background-size: contain;
    top: 23px;
    right: 23px;
    left: auto; } }
        .company-layout .company-page-left-col .companies-header .header-actions .actions-button,
        .transactions-layout .company-page-left-col .companies-header .header-actions .actions-button {
          padding: 15px 20px;
          color: #fff; }
        .company-layout .company-page-left-col .companies-header .header-actions .actions-popup,
        .transactions-layout .company-page-left-col .companies-header .header-actions .actions-popup {
          right: 0;
          border-radius: 10px; }
        .company-layout .company-page-left-col .companies-header .header-actions.opened .actions-button,
        .transactions-layout .company-page-left-col .companies-header .header-actions.opened .actions-button {
          color: #4b4b4b; }
      .company-layout .company-page-left-col .companies-header .header-content,
      .transactions-layout .company-page-left-col .companies-header .header-content {
        position: absolute;
        bottom: 24px;
        left: 36px; }
    .company-layout .company-page-left-col .graphs-plate,
    .transactions-layout .company-page-left-col .graphs-plate {
      display: block;
      background: #fff;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border-radius: 10px;
      -webkit-transition: box-shadow 0.1s ease;
      -moz-transition: box-shadow 0.1s ease;
      -ms-transition: box-shadow 0.1s ease;
      -o-transition: box-shadow 0.1s ease;
      transition: box-shadow 0.1s ease; }

@media screen and (max-width: 450px) {
  .company-layout .company-page-left-col .graphs-plate,
  .transactions-layout .company-page-left-col .graphs-plate {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
    .company-layout .company-page-left-col .targets-plate,
    .transactions-layout .company-page-left-col .targets-plate {
      display: block;
      background: #fff;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border-radius: 10px;
      -webkit-transition: box-shadow 0.1s ease;
      -moz-transition: box-shadow 0.1s ease;
      -ms-transition: box-shadow 0.1s ease;
      -o-transition: box-shadow 0.1s ease;
      transition: box-shadow 0.1s ease;
      padding: 20px 24px 6px 24px;
      margin-bottom: 18px; }

@media screen and (max-width: 450px) {
  .company-layout .company-page-left-col .targets-plate,
  .transactions-layout .company-page-left-col .targets-plate {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
  .company-layout .company-page-right-col,
  .transactions-layout .company-page-right-col {
    width: 390px;
    margin-left: 18px; }
    .company-layout .company-page-right-col .sticky-container,
    .transactions-layout .company-page-right-col .sticky-container {
      position: fixed; }
    .company-layout .company-page-right-col .graphs-plate,
    .company-layout .company-page-right-col .debts-plate,
    .company-layout .company-page-right-col .targets-plate,
    .transactions-layout .company-page-right-col .graphs-plate,
    .transactions-layout .company-page-right-col .debts-plate,
    .transactions-layout .company-page-right-col .targets-plate {
      display: block;
      background: #fff;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border-radius: 10px;
      -webkit-transition: box-shadow 0.1s ease;
      -moz-transition: box-shadow 0.1s ease;
      -ms-transition: box-shadow 0.1s ease;
      -o-transition: box-shadow 0.1s ease;
      transition: box-shadow 0.1s ease; }

@media screen and (max-width: 450px) {
  .company-layout .company-page-right-col .graphs-plate,
  .company-layout .company-page-right-col .debts-plate,
  .company-layout .company-page-right-col .targets-plate,
  .transactions-layout .company-page-right-col .graphs-plate,
  .transactions-layout .company-page-right-col .debts-plate,
  .transactions-layout .company-page-right-col .targets-plate {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
    .company-layout .company-page-right-col .targets-plate,
    .transactions-layout .company-page-right-col .targets-plate {
      padding: 20px 24px 6px 24px;
      margin-bottom: 18px; }
    .company-layout .company-page-right-col .filter,
    .transactions-layout .company-page-right-col .filter {
      text-align: right; }
  .company-layout .company-payment-accounts,
  .transactions-layout .company-payment-accounts {
    display: block;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: box-shadow 0.1s ease;
    -moz-transition: box-shadow 0.1s ease;
    -ms-transition: box-shadow 0.1s ease;
    -o-transition: box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease;
    margin-bottom: 32px; }

@media screen and (max-width: 450px) {
  .company-layout .company-payment-accounts,
  .transactions-layout .company-payment-accounts {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
  .company-layout .operations-actions-container,
  .transactions-layout .operations-actions-container {
    height: 40px;
    margin-bottom: 32px; }
    .company-layout .operations-actions-container .operations-actions,
    .transactions-layout .operations-actions-container .operations-actions {
      padding: 0;
      top: 0;
      transition: padding 0.2s ease, top 0.2s ease; }
    .company-layout .operations-actions-container.sticky .operations-actions,
    .transactions-layout .operations-actions-container.sticky .operations-actions {
      display: block;
      background: #fff;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border-radius: 10px;
      -webkit-transition: box-shadow 0.1s ease;
      -moz-transition: box-shadow 0.1s ease;
      -ms-transition: box-shadow 0.1s ease;
      -o-transition: box-shadow 0.1s ease;
      transition: box-shadow 0.1s ease;
      position: fixed;
      top: 100px;
      width: 550px;
      padding: 20px;
      box-sizing: border-box; }

@media screen and (max-width: 450px) {
  .company-layout .operations-actions-container.sticky .operations-actions,
  .transactions-layout .operations-actions-container.sticky .operations-actions {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
  .company-layout .action-add-operation,
  .company-layout .action-add-income-operation,
  .transactions-layout .action-add-operation,
  .transactions-layout .action-add-income-operation {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    background: linear-gradient(73.01deg, #e9e122 8.78%, #f64399 113.32%), #956dbb;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    margin-right: 12px;
    vertical-align: middle; }
    .company-layout .action-add-operation .text,
    .company-layout .action-add-operation .icon,
    .company-layout .action-add-income-operation .text,
    .company-layout .action-add-income-operation .icon,
    .transactions-layout .action-add-operation .text,
    .transactions-layout .action-add-operation .icon,
    .transactions-layout .action-add-income-operation .text,
    .transactions-layout .action-add-income-operation .icon {
      display: inline-block;
      vertical-align: top; }
    .company-layout .action-add-operation .icon,
    .company-layout .action-add-income-operation .icon,
    .transactions-layout .action-add-operation .icon,
    .transactions-layout .action-add-income-operation .icon {
      margin-right: 0; }
    .company-layout .action-add-operation.button-xs,
    .company-layout .action-add-income-operation.button-xs,
    .transactions-layout .action-add-operation.button-xs,
    .transactions-layout .action-add-income-operation.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .company-layout .action-add-operation.button-s,
    .company-layout .action-add-income-operation.button-s,
    .transactions-layout .action-add-operation.button-s,
    .transactions-layout .action-add-income-operation.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .company-layout .action-add-operation.button-l,
    .company-layout .action-add-income-operation.button-l,
    .transactions-layout .action-add-operation.button-l,
    .transactions-layout .action-add-income-operation.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .company-layout .action-add-operation:hover,
    .company-layout .action-add-income-operation:hover,
    .transactions-layout .action-add-operation:hover,
    .transactions-layout .action-add-income-operation:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-layout .action-add-operation,
  .company-layout .action-add-income-operation,
  .transactions-layout .action-add-operation,
  .transactions-layout .action-add-income-operation {
    font-weight: normal; } }
    .company-layout .action-add-operation:hover,
    .company-layout .action-add-income-operation:hover,
    .transactions-layout .action-add-operation:hover,
    .transactions-layout .action-add-income-operation:hover {
      background: linear-gradient(73.01deg, #e0d820 8.78%, #e8398d 113.32%), #956dbb; }
  .company-layout .action-add-outcome-operation,
  .transactions-layout .action-add-outcome-operation {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    margin-right: 12px;
    vertical-align: middle; }
    .company-layout .action-add-outcome-operation .text,
    .company-layout .action-add-outcome-operation .icon,
    .transactions-layout .action-add-outcome-operation .text,
    .transactions-layout .action-add-outcome-operation .icon {
      display: inline-block;
      vertical-align: top; }
    .company-layout .action-add-outcome-operation .icon,
    .transactions-layout .action-add-outcome-operation .icon {
      margin-right: 0; }
    .company-layout .action-add-outcome-operation.button-xs,
    .transactions-layout .action-add-outcome-operation.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .company-layout .action-add-outcome-operation.button-s,
    .transactions-layout .action-add-outcome-operation.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .company-layout .action-add-outcome-operation.button-l,
    .transactions-layout .action-add-outcome-operation.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .company-layout .action-add-outcome-operation:hover,
    .transactions-layout .action-add-outcome-operation:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-layout .action-add-outcome-operation,
  .transactions-layout .action-add-outcome-operation {
    font-weight: normal; } }
  .company-layout .action-close,
  .transactions-layout .action-close {
    width: 19px;
    height: 19px;
    background: url(images/icon-close.png) no-repeat center center;
    display: inline-block;
    background-size: contain;
    vertical-align: middle; }
  .company-layout .action-transfer,
  .transactions-layout .action-transfer {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    background: none;
    border: 1px solid #4b4b4b;
    color: #4b4b4b;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    padding: 0 10px;
    margin-right: 12px;
    vertical-align: middle; }
    .company-layout .action-transfer .text,
    .company-layout .action-transfer .icon,
    .transactions-layout .action-transfer .text,
    .transactions-layout .action-transfer .icon {
      display: inline-block;
      vertical-align: top; }
    .company-layout .action-transfer .icon,
    .transactions-layout .action-transfer .icon {
      margin-right: 0; }
    .company-layout .action-transfer.button-xs,
    .transactions-layout .action-transfer.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .company-layout .action-transfer.button-s,
    .transactions-layout .action-transfer.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .company-layout .action-transfer.button-l,
    .transactions-layout .action-transfer.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .company-layout .action-transfer:hover,
    .transactions-layout .action-transfer:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-layout .action-transfer,
  .transactions-layout .action-transfer {
    font-weight: normal; } }
    .company-layout .action-transfer:hover,
    .transactions-layout .action-transfer:hover {
      background: #f2f5fb; }
    .company-layout .action-transfer .action-icon,
    .transactions-layout .action-transfer .action-icon {
      width: 19px;
      height: 19px;
      background: url(images/arrows-opposite.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      vertical-align: middle; }
    .company-layout .action-transfer .action-label,
    .transactions-layout .action-transfer .action-label {
      display: none; }
  .company-layout .action-add-operation,
  .transactions-layout .action-add-operation {
    -webkit-animation: add-operation-in 0.5s;
    -moz-animation: add-operation-in 0.5s;
    animation: add-operation-in 0.5s; }
  .company-layout .action-add-income-operation,
  .transactions-layout .action-add-income-operation {
    padding: 0 30px;
    -webkit-animation: add-income-operation-in 0.5s;
    -moz-animation: add-income-operation-in 0.5s;
    animation: add-income-operation-in 0.5s; }
  .company-layout .action-add-outcome-operation,
  .transactions-layout .action-add-outcome-operation {
    padding: 0 30px;
    -webkit-animation: add-outcome-operation-in 0.5s;
    -moz-animation: add-outcome-operation-in 0.5s;
    animation: add-outcome-operation-in 0.5s; }
  .company-layout .action-upload,
  .transactions-layout .action-upload {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    background: none;
    border: 1px solid #4b4b4b;
    color: #4b4b4b;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px; }
    .company-layout .action-upload .text,
    .company-layout .action-upload .icon,
    .transactions-layout .action-upload .text,
    .transactions-layout .action-upload .icon {
      display: inline-block;
      vertical-align: top; }
    .company-layout .action-upload .icon,
    .transactions-layout .action-upload .icon {
      margin-right: 0; }
    .company-layout .action-upload.button-xs,
    .transactions-layout .action-upload.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .company-layout .action-upload.button-s,
    .transactions-layout .action-upload.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .company-layout .action-upload.button-l,
    .transactions-layout .action-upload.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .company-layout .action-upload:hover,
    .transactions-layout .action-upload:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-layout .action-upload,
  .transactions-layout .action-upload {
    font-weight: normal; } }
    .company-layout .action-upload .text,
    .company-layout .action-upload .icon,
    .transactions-layout .action-upload .text,
    .transactions-layout .action-upload .icon {
      display: inline-block;
      vertical-align: top; }
    .company-layout .action-upload .icon,
    .transactions-layout .action-upload .icon {
      margin-right: 0; }
    .company-layout .action-upload.button-xs,
    .transactions-layout .action-upload.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .company-layout .action-upload.button-s,
    .transactions-layout .action-upload.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .company-layout .action-upload.button-l,
    .transactions-layout .action-upload.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .company-layout .action-upload:hover,
    .transactions-layout .action-upload:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-layout .action-upload,
  .transactions-layout .action-upload {
    font-weight: normal; } }
    .company-layout .action-upload:hover,
    .transactions-layout .action-upload:hover {
      background: #f2f5fb; }
  .company-layout .back-link-container,
  .transactions-layout .back-link-container {
    margin-bottom: 18px; }
    .company-layout .back-link-container .back-link,
    .transactions-layout .back-link-container .back-link {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b; }
      .company-layout .back-link-container .back-link:hover,
      .transactions-layout .back-link-container .back-link:hover {
        color: black; }
      .company-layout .back-link-container .back-link:before,
      .transactions-layout .back-link-container .back-link:before {
        content: "\2190";
        margin-right: 4px; }

.transactions-layout .operations {
  padding-top: 24px; }

.transactions-layout .operations-actions-container {
  height: 40px;
  margin-bottom: 32px; }
  .transactions-layout .operations-actions-container .operations-actions {
    padding: 0;
    top: 0;
    transition: padding 0.2s ease, top 0.2s ease; }
  .transactions-layout .operations-actions-container.sticky .operations-actions {
    display: block;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: box-shadow 0.1s ease;
    -moz-transition: box-shadow 0.1s ease;
    -ms-transition: box-shadow 0.1s ease;
    -o-transition: box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease;
    position: fixed;
    top: 100px;
    width: 550px;
    padding: 20px;
    box-sizing: border-box; }

@media screen and (max-width: 450px) {
  .transactions-layout .operations-actions-container.sticky .operations-actions {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }

.analytics-layout,
.targets-layout {
  position: relative;
  display: flex;
  justify-content: space-between; }

@-webkit-keyframes add-operation-in {
  0% {
    padding: 0 40px; }
  100% {
    padding: 0 20px; } }

@-moz-keyframes add-operation-in {
  0% {
    padding: 0 40px; }
  100% {
    padding: 0 20px; } }

@keyframes add-operation-in {
  0% {
    padding: 0 40px; }
  100% {
    padding: 0 20px; } }

@-webkit-keyframes add-income-operation-in {
  0% {
    padding: 0 40px;
    margin-right: -30px; }
  80% {
    margin-right: 12px; }
  100% {
    padding: 0 30px; } }

@-moz-keyframes add-income-operation-in {
  0% {
    padding: 0 40px;
    margin-right: -30px; }
  80% {
    margin-right: 12px; }
  100% {
    padding: 0 30px; } }

@keyframes add-income-operation-in {
  0% {
    padding: 0 40px;
    margin-right: -30px; }
  80% {
    margin-right: 12px; }
  100% {
    padding: 0 30px; } }

@-webkit-keyframes add-outcome-operation-in {
  0% {
    padding: 0 40px; }
  100% {
    padding: 0 30px; } }

@-moz-keyframes add-outcome-operation-in {
  0% {
    padding: 0 40px; }
  100% {
    padding: 0 30px; } }

@keyframes add-outcome-operation-in {
  0% {
    padding: 0 40px; }
  100% {
    padding: 0 30px; } }

.company-payment-accounts {
  padding: 8px 0; }
  .company-payment-accounts .account-list-item {
    display: flex;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    height: 50px;
    line-height: 38px;
    padding: 6px 24px;
    color: #4b4b4b;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: none; }
    .company-payment-accounts .account-list-item .list-item-col {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .company-payment-accounts .account-list-item .list-item-col.icon {
        width: 38px;
        flex-shrink: 0; }
      .company-payment-accounts .account-list-item .list-item-col.caption {
        margin-right: auto;
        margin-left: 18px; }
      .company-payment-accounts .account-list-item .list-item-col.price {
        flex-shrink: 0;
        text-align: right; }
      .company-payment-accounts .account-list-item .list-item-col .note {
        font-size: 12px;
        color: #8494a9; }
    .company-payment-accounts .account-list-item .icon .icon-content {
      display: inline-block;
      width: 38px;
      height: 38px;
      line-height: 37px;
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      color: #fff;
      background: #bed6f9;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      border-radius: 50%;
      font-size: 17px; }
      .company-payment-accounts .account-list-item .icon .icon-content.icon-sub-correction {
        line-height: 34.2px; }
      .company-payment-accounts .account-list-item .icon .icon-content.color-1 {
        background: #bed6f9; }
      .company-payment-accounts .account-list-item .icon .icon-content.color-2 {
        background: #b9e398; }
      .company-payment-accounts .account-list-item .icon .icon-content.color-3 {
        background: #e4e597; }
      .company-payment-accounts .account-list-item .icon .icon-content.color-4 {
        background: #e7c740; }
      .company-payment-accounts .account-list-item .icon .icon-content.color-5 {
        background: #e74040; }
      .company-payment-accounts .account-list-item .icon .icon-content.color-6 {
        background: #cf91af; }
    .company-payment-accounts .account-list-item .caption {
      padding-right: 20px; }
      .company-payment-accounts .account-list-item .caption .note {
        margin-left: 8px;
        opacity: 0.6;
        filter: alpha(opacity=60);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=60;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
    .company-payment-accounts .account-list-item .price {
      font-size: 0;
      color: #4b4b4b; }
      .company-payment-accounts .account-list-item .price .int,
      .company-payment-accounts .account-list-item .price .delim {
        font-size: 22px; }
      .company-payment-accounts .account-list-item .price .float,
      .company-payment-accounts .account-list-item .price .unit {
        font-size: 14px; }
      .company-payment-accounts .account-list-item .price .unit {
        margin-left: 8px; }
      .company-payment-accounts .account-list-item .price .unit-ruble:after {
        content: "\20bd"; }
      .company-payment-accounts .account-list-item .price.income, .company-payment-accounts .account-list-item .price.transfer-income {
        color: #4dbd7d; }
        .company-payment-accounts .account-list-item .price.income .int,
        .company-payment-accounts .account-list-item .price.income .delim,
        .company-payment-accounts .account-list-item .price.income .float,
        .company-payment-accounts .account-list-item .price.income .unit, .company-payment-accounts .account-list-item .price.transfer-income .int,
        .company-payment-accounts .account-list-item .price.transfer-income .delim,
        .company-payment-accounts .account-list-item .price.transfer-income .float,
        .company-payment-accounts .account-list-item .price.transfer-income .unit {
          color: #4dbd7d; }
      .company-payment-accounts .account-list-item .price .float,
      .company-payment-accounts .account-list-item .price .unit {
        color: #8494a9; }
    .company-payment-accounts .account-list-item .arrow {
      margin-left: 14px;
      flex-shrink: 0; }
      .company-payment-accounts .account-list-item .arrow .icon-graph {
        width: 21px;
        height: 22px;
        background: url(images/icon-circle-graph.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        vertical-align: middle; }
      .company-payment-accounts .account-list-item .arrow .icon-arrow {
        display: inline-block;
        width: 8px;
        height: 14px;
        margin-left: 4px;
        background: url(images/arrow-right.png) no-repeat center center;
        vertical-align: middle; }
    .company-payment-accounts .account-list-item:hover {
      color: black;
      background: #f2f5fb; }
  .company-payment-accounts .accounts-actions {
    display: flex; }
  .company-payment-accounts .accounts-action {
    padding: 12px 0; }
    .company-payment-accounts .accounts-action a,
    .company-payment-accounts .accounts-action button {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b; }
      .company-payment-accounts .accounts-action a:hover,
      .company-payment-accounts .accounts-action button:hover {
        color: black; }
    .company-payment-accounts .accounts-action.name-add {
      margin-left: 70px; }
      .company-payment-accounts .accounts-action.name-add a:before,
      .company-payment-accounts .accounts-action.name-add button:before {
        content: "+ "; }
    .company-payment-accounts .accounts-action.name-transfer {
      margin-left: auto;
      margin-right: 25px; }
      .company-payment-accounts .accounts-action.name-transfer a:before,
      .company-payment-accounts .accounts-action.name-transfer button:before {
        content: "";
        width: 19px;
        height: 19px;
        background: url(images/arrows-opposite.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        vertical-align: middle;
        margin-right: 5px; }

/* OPERATIONS */
.operations-filter {
  position: relative;
  margin: 0 18px 24px 18px; }
  .operations-filter .filter-field {
    display: inline-block;
    margin: 0 6px 12px 6px;
    vertical-align: top; }
  .operations-filter .filter-label {
    display: block;
    color: #8494a9; }
  .operations-filter .filter-datepicker {
    position: relative; }
    .operations-filter .filter-datepicker .filter-label-button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px;
      background: #f2f5fb;
      color: #4b4b4b;
      font-size: 14px; }
      .operations-filter .filter-datepicker .filter-label-button .text,
      .operations-filter .filter-datepicker .filter-label-button .icon {
        display: inline-block;
        vertical-align: top; }
      .operations-filter .filter-datepicker .filter-label-button .icon {
        margin-right: 0; }
      .operations-filter .filter-datepicker .filter-label-button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .operations-filter .filter-datepicker .filter-label-button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .operations-filter .filter-datepicker .filter-label-button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .operations-filter .filter-datepicker .filter-label-button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .operations-filter .filter-datepicker .filter-label-button {
    font-weight: normal; } }
      .operations-filter .filter-datepicker .filter-label-button .icon-date {
        width: 17px;
        height: 16px;
        background: url(images/icon-date-black.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        margin-right: 6px;
        margin-top: 6px; }
      .operations-filter .filter-datepicker .filter-label-button:hover {
        background: #eaeff9; }
    .operations-filter .filter-datepicker .filter-select-button {
      width: 100%;
      height: 34px;
      line-height: 34px;
      padding: 0;
      background: none;
      border: 0;
      border-bottom: 1px solid #8d76f0;
      font-size: 17px;
      outline: none;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .operations-filter .filter-datepicker .filter-select-button::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: rgba(132, 148, 169, 0.5); }
      .operations-filter .filter-datepicker .filter-select-button:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .operations-filter .filter-datepicker .filter-select-button::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .operations-filter .filter-datepicker .filter-select-button:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(132, 148, 169, 0.5); }
      .operations-filter .filter-datepicker .filter-select-button::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(132, 148, 169, 0.5); }
      .operations-filter .filter-datepicker .filter-select-button::placeholder {
        /* Most modern browsers support this now. */
        color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .operations-filter .filter-datepicker .filter-select-button {
    font-size: 16px; } }
      .operations-filter .filter-datepicker .filter-select-button .text {
        margin-right: 8px; }
      .operations-filter .filter-datepicker .filter-select-button .icon-arrow {
        width: 14px;
        height: 7px;
        background: url(images/arrow-bottom.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
    .operations-filter .filter-datepicker .filter-date-checklist-popup {
      position: relative;
      display: none;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 20;
      position: absolute;
      top: -24px;
      left: -24px;
      padding-bottom: 24px; }
      .operations-filter .filter-datepicker .filter-date-checklist-popup.show {
        display: block; }
      .operations-filter .filter-datepicker .filter-date-checklist-popup .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 25px; }

@media screen and (max-width: 500px) {
  .operations-filter .filter-datepicker .filter-date-checklist-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .operations-filter .filter-datepicker .filter-date-checklist-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .operations-filter .filter-datepicker .filter-date-checklist-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
      .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist-header {
        margin: 24px 0 18px 24px;
        font-size: 14px;
        color: #8494a9;
        background: none; }
        .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist-header .icon,
        .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist-header .text {
          vertical-align: top; }
      .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist {
        min-width: 240px; }
        .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist button {
          position: relative;
          display: block;
          width: 390px;
          height: 48px;
          line-height: 48px;
          padding-right: 32px;
          background: none;
          font-size: 17px;
          color: #4b4b4b; }
          .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon {
            position: absolute;
            top: 13px;
            left: 24px;
            display: inline-block;
            width: 18px;
            height: 18px;
            border: 2px solid #8494a9;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px; }
          .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon-checked {
            position: relative;
            display: inline-block;
            width: 22px;
            height: 22px;
            background: #81bc52;
            border: 0;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px;
            position: absolute;
            top: 13px;
            left: 24px; }
            .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon-checked::after {
              content: "";
              width: 16px;
              height: 12px;
              background: url(images/icon-check-white.png) no-repeat center center;
              display: inline-block;
              background-size: contain;
              position: absolute;
              top: 7px;
              left: 4px;
              width: 13px;
              height: 9px; }
          .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist button .text {
            display: block;
            margin-left: 60px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            font-size: 14px; }
          .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist button:hover {
            background: #f2f5fb; }
          .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist button.hidden {
            display: none; }

@media screen and (max-width: 500px) {
  .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist button {
    width: 100%;
    box-sizing: border-box; }
    .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon,
    .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon-checked {
      top: 7px; } }

@media screen and (max-width: 450px) {
  .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist button {
    font-weight: normal;
    height: 36px;
    line-height: 36px; } }
        .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon {
          width: 14px;
          height: 14px;
          border: 2px solid #4f4f4f;
          border-radius: 50%;
          opacity: 0.7; }
        .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon-checked {
          width: 6px;
          height: 6px;
          border: 6px solid #4b4b4b;
          border-radius: 50%;
          background: none; }
          .operations-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon-checked::after {
            display: none; }
      .operations-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs {
        padding-left: 60px; }
        .operations-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input {
          width: 100%;
          height: 34px;
          line-height: 34px;
          padding: 0;
          background: none;
          border: 0;
          border-bottom: 1px solid #8d76f0;
          font-size: 17px;
          outline: none;
          width: 67px;
          padding: 0;
          font-size: 14px !important; }
          .operations-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: rgba(132, 148, 169, 0.5); }
          .operations-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .operations-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .operations-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(132, 148, 169, 0.5); }
          .operations-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(132, 148, 169, 0.5); }
          .operations-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input::placeholder {
            /* Most modern browsers support this now. */
            color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .operations-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input {
    font-size: 16px; } }
      .operations-filter .filter-datepicker .filter-date-checklist-popup .apply-button {
        display: none; }
    .operations-filter .filter-datepicker .datepicker-popup {
      position: absolute;
      top: 24px;
      left: 24px; }
  .operations-filter .filter-search-string {
    position: relative; }
    .operations-filter .filter-search-string .icon {
      position: absolute;
      top: 50%;
      right: 18px;
      margin-top: -11px;
      width: 22px;
      height: 21px;
      background: url(images/icon-filter-search-string.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .operations-filter .filter-search-string input {
      position: relative;
      width: 305px;
      height: 38px;
      line-height: 38px;
      padding: 0 48px 0 16px;
      background: none;
      border: 1px solid rgba(60, 136, 238, 0.3);
      color: #4b4b4b;
      -webkit-border-radius: 19px;
      -moz-border-radius: 19px;
      -ms-border-radius: 19px;
      -o-border-radius: 19px;
      border-radius: 19px; }
  .operations-filter .filter-checklist-buttons {
    position: relative; }
    .operations-filter .filter-checklist-buttons .filter-label-button,
    .operations-filter .filter-checklist-buttons .filter-checked-item,
    .operations-filter .filter-checklist-buttons .filter-button-add {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px;
      margin-bottom: 12px;
      background: #fff;
      color: #4b4b4b;
      font-size: 14px;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
      .operations-filter .filter-checklist-buttons .filter-label-button .text,
      .operations-filter .filter-checklist-buttons .filter-label-button .icon,
      .operations-filter .filter-checklist-buttons .filter-checked-item .text,
      .operations-filter .filter-checklist-buttons .filter-checked-item .icon,
      .operations-filter .filter-checklist-buttons .filter-button-add .text,
      .operations-filter .filter-checklist-buttons .filter-button-add .icon {
        display: inline-block;
        vertical-align: top; }
      .operations-filter .filter-checklist-buttons .filter-label-button .icon,
      .operations-filter .filter-checklist-buttons .filter-checked-item .icon,
      .operations-filter .filter-checklist-buttons .filter-button-add .icon {
        margin-right: 0; }
      .operations-filter .filter-checklist-buttons .filter-label-button.button-xs,
      .operations-filter .filter-checklist-buttons .filter-checked-item.button-xs,
      .operations-filter .filter-checklist-buttons .filter-button-add.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .operations-filter .filter-checklist-buttons .filter-label-button.button-s,
      .operations-filter .filter-checklist-buttons .filter-checked-item.button-s,
      .operations-filter .filter-checklist-buttons .filter-button-add.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .operations-filter .filter-checklist-buttons .filter-label-button.button-l,
      .operations-filter .filter-checklist-buttons .filter-checked-item.button-l,
      .operations-filter .filter-checklist-buttons .filter-button-add.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .operations-filter .filter-checklist-buttons .filter-label-button:hover,
      .operations-filter .filter-checklist-buttons .filter-checked-item:hover,
      .operations-filter .filter-checklist-buttons .filter-button-add:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .operations-filter .filter-checklist-buttons .filter-label-button,
  .operations-filter .filter-checklist-buttons .filter-checked-item,
  .operations-filter .filter-checklist-buttons .filter-button-add {
    font-weight: normal; } }
      .operations-filter .filter-checklist-buttons .filter-label-button:hover,
      .operations-filter .filter-checklist-buttons .filter-checked-item:hover,
      .operations-filter .filter-checklist-buttons .filter-button-add:hover {
        background: #eaeff9; }
    .operations-filter .filter-checklist-buttons .filter-checked-item {
      margin-right: 12px; }
      .operations-filter .filter-checklist-buttons .filter-checked-item .icon-remove {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: relative;
        top: -1px;
        width: 9px;
        height: 9px;
        margin-left: 8px; }
    .operations-filter .filter-checklist-buttons .filter-button-add {
      width: 32px;
      padding: 0; }
      .operations-filter .filter-checklist-buttons .filter-button-add .text {
        line-height: 26px;
        font-size: 22px; }
    .operations-filter .filter-checklist-buttons .filter-checklist-popup {
      position: relative;
      display: none;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 20;
      position: absolute;
      top: -21px;
      left: -12px; }
      .operations-filter .filter-checklist-buttons .filter-checklist-popup.show {
        display: block; }
      .operations-filter .filter-checklist-buttons .filter-checklist-popup .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 25px; }

@media screen and (max-width: 500px) {
  .operations-filter .filter-checklist-buttons .filter-checklist-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .operations-filter .filter-checklist-buttons .filter-checklist-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .operations-filter .filter-checklist-buttons .filter-checklist-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
      .operations-filter .filter-checklist-buttons .filter-checklist-popup .checklist-header {
        margin: 24px 0 18px 24px;
        font-size: 14px;
        background: none; }
        .operations-filter .filter-checklist-buttons .filter-checklist-popup .checklist-header .icon,
        .operations-filter .filter-checklist-buttons .filter-checklist-popup .checklist-header .text {
          vertical-align: top; }
      .operations-filter .filter-checklist-buttons .filter-checklist-popup .checklist {
        min-width: 240px;
        max-height: 220px;
        padding-bottom: 24px;
        overflow: auto; }
        .operations-filter .filter-checklist-buttons .filter-checklist-popup .checklist button {
          position: relative;
          display: block;
          width: 390px;
          height: 48px;
          line-height: 48px;
          padding-right: 32px;
          background: none;
          font-size: 17px;
          color: #4b4b4b; }
          .operations-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .icon {
            position: absolute;
            top: 13px;
            left: 24px;
            display: inline-block;
            width: 18px;
            height: 18px;
            border: 2px solid #8494a9;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px; }
          .operations-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .icon-checked {
            position: relative;
            display: inline-block;
            width: 22px;
            height: 22px;
            background: #81bc52;
            border: 0;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px;
            position: absolute;
            top: 13px;
            left: 24px; }
            .operations-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .icon-checked::after {
              content: "";
              width: 16px;
              height: 12px;
              background: url(images/icon-check-white.png) no-repeat center center;
              display: inline-block;
              background-size: contain;
              position: absolute;
              top: 7px;
              left: 4px;
              width: 13px;
              height: 9px; }
          .operations-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .text {
            display: block;
            margin-left: 60px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            font-size: 14px; }
          .operations-filter .filter-checklist-buttons .filter-checklist-popup .checklist button:hover {
            background: #f2f5fb; }
          .operations-filter .filter-checklist-buttons .filter-checklist-popup .checklist button.hidden {
            display: none; }

@media screen and (max-width: 500px) {
  .operations-filter .filter-checklist-buttons .filter-checklist-popup .checklist button {
    width: 100%;
    box-sizing: border-box; }
    .operations-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .icon,
    .operations-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .icon-checked {
      top: 7px; } }

@media screen and (max-width: 450px) {
  .operations-filter .filter-checklist-buttons .filter-checklist-popup .checklist button {
    font-weight: normal;
    height: 36px;
    line-height: 36px; } }
  .operations-filter .filter-checklist {
    position: relative; }
    .operations-filter .filter-checklist .checklist-value-button {
      width: 100%;
      height: 34px;
      line-height: 34px;
      padding: 0;
      background: none;
      border: 0;
      border-bottom: 1px solid #8d76f0;
      font-size: 17px;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .operations-filter .filter-checklist .checklist-value-button::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: rgba(132, 148, 169, 0.5); }
      .operations-filter .filter-checklist .checklist-value-button:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .operations-filter .filter-checklist .checklist-value-button::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .operations-filter .filter-checklist .checklist-value-button:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(132, 148, 169, 0.5); }
      .operations-filter .filter-checklist .checklist-value-button::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(132, 148, 169, 0.5); }
      .operations-filter .filter-checklist .checklist-value-button::placeholder {
        /* Most modern browsers support this now. */
        color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .operations-filter .filter-checklist .checklist-value-button {
    font-size: 16px; } }
      .operations-filter .filter-checklist .checklist-value-button .text-value,
      .operations-filter .filter-checklist .checklist-value-button .count-value {
        margin-right: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 16px; }
      .operations-filter .filter-checklist .checklist-value-button .count-value {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        padding: 0 14px;
        height: 20px;
        line-height: 20px;
        color: #4b4b4b;
        background: #bed6f9;
        font-size: 16px;
        font-weight: normal;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px; }
        .operations-filter .filter-checklist .checklist-value-button .count-value .text,
        .operations-filter .filter-checklist .checklist-value-button .count-value .icon {
          display: inline-block;
          vertical-align: top; }
        .operations-filter .filter-checklist .checklist-value-button .count-value .icon {
          margin-right: 0; }
        .operations-filter .filter-checklist .checklist-value-button .count-value.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .operations-filter .filter-checklist .checklist-value-button .count-value.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .operations-filter .filter-checklist .checklist-value-button .count-value.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .operations-filter .filter-checklist .checklist-value-button .count-value:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .operations-filter .filter-checklist .checklist-value-button .count-value {
    font-weight: normal; } }
        .operations-filter .filter-checklist .checklist-value-button .count-value:hover {
          color: #4b4b4b;
          background: #bed6f9; }
      .operations-filter .filter-checklist .checklist-value-button .button-icon {
        width: 14px;
        height: 7px;
        background: url(images/arrow-bottom.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
    .operations-filter .filter-checklist .filter-checklist-popup {
      position: relative;
      display: none;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 20;
      position: absolute;
      top: -24px;
      left: -24px; }
      .operations-filter .filter-checklist .filter-checklist-popup.show {
        display: block; }
      .operations-filter .filter-checklist .filter-checklist-popup .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 25px; }

@media screen and (max-width: 500px) {
  .operations-filter .filter-checklist .filter-checklist-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .operations-filter .filter-checklist .filter-checklist-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .operations-filter .filter-checklist .filter-checklist-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
      .operations-filter .filter-checklist .filter-checklist-popup .checklist-header {
        margin: 24px 0 18px 24px;
        font-size: 14px;
        color: #8494a9;
        background: none; }
        .operations-filter .filter-checklist .filter-checklist-popup .checklist-header .icon,
        .operations-filter .filter-checklist .filter-checklist-popup .checklist-header .text {
          vertical-align: top; }
      .operations-filter .filter-checklist .filter-checklist-popup .search-field {
        position: relative;
        padding-left: 24px;
        margin-bottom: 8px;
        width: 190px; }
        .operations-filter .filter-checklist .filter-checklist-popup .search-field input {
          width: 100%;
          height: 34px;
          line-height: 34px;
          padding: 0;
          background: none;
          border: 0;
          border-bottom: 1px solid #8d76f0;
          font-size: 17px;
          outline: none;
          font-size: 14px; }
          .operations-filter .filter-checklist .filter-checklist-popup .search-field input::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: rgba(132, 148, 169, 0.5); }
          .operations-filter .filter-checklist .filter-checklist-popup .search-field input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .operations-filter .filter-checklist .filter-checklist-popup .search-field input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .operations-filter .filter-checklist .filter-checklist-popup .search-field input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(132, 148, 169, 0.5); }
          .operations-filter .filter-checklist .filter-checklist-popup .search-field input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(132, 148, 169, 0.5); }
          .operations-filter .filter-checklist .filter-checklist-popup .search-field input::placeholder {
            /* Most modern browsers support this now. */
            color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .operations-filter .filter-checklist .filter-checklist-popup .search-field input {
    font-size: 16px; } }
        .operations-filter .filter-checklist .filter-checklist-popup .search-field .icon {
          width: 14px;
          height: 13px;
          background: url(images/icon-search.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          position: absolute;
          bottom: 10px;
          right: 0;
          opacity: 0.7; }
      .operations-filter .filter-checklist .filter-checklist-popup .checklist {
        min-width: 240px;
        max-height: 220px;
        padding-bottom: 24px;
        overflow: auto; }
        .operations-filter .filter-checklist .filter-checklist-popup .checklist button {
          position: relative;
          display: block;
          width: 390px;
          height: 48px;
          line-height: 48px;
          padding-right: 32px;
          background: none;
          font-size: 17px;
          color: #4b4b4b; }
          .operations-filter .filter-checklist .filter-checklist-popup .checklist button .icon {
            position: absolute;
            top: 13px;
            left: 24px;
            display: inline-block;
            width: 18px;
            height: 18px;
            border: 2px solid #8494a9;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px; }
          .operations-filter .filter-checklist .filter-checklist-popup .checklist button .icon-checked {
            position: relative;
            display: inline-block;
            width: 22px;
            height: 22px;
            background: #81bc52;
            border: 0;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px;
            position: absolute;
            top: 13px;
            left: 24px; }
            .operations-filter .filter-checklist .filter-checklist-popup .checklist button .icon-checked::after {
              content: "";
              width: 16px;
              height: 12px;
              background: url(images/icon-check-white.png) no-repeat center center;
              display: inline-block;
              background-size: contain;
              position: absolute;
              top: 7px;
              left: 4px;
              width: 13px;
              height: 9px; }
          .operations-filter .filter-checklist .filter-checklist-popup .checklist button .text {
            display: block;
            margin-left: 60px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            font-size: 14px; }
          .operations-filter .filter-checklist .filter-checklist-popup .checklist button:hover {
            background: #f2f5fb; }
          .operations-filter .filter-checklist .filter-checklist-popup .checklist button.hidden {
            display: none; }

@media screen and (max-width: 500px) {
  .operations-filter .filter-checklist .filter-checklist-popup .checklist button {
    width: 100%;
    box-sizing: border-box; }
    .operations-filter .filter-checklist .filter-checklist-popup .checklist button .icon,
    .operations-filter .filter-checklist .filter-checklist-popup .checklist button .icon-checked {
      top: 7px; } }

@media screen and (max-width: 450px) {
  .operations-filter .filter-checklist .filter-checklist-popup .checklist button {
    font-weight: normal;
    height: 36px;
    line-height: 36px; } }
      .operations-filter .filter-checklist .filter-checklist-popup .apply-button,
      .operations-filter .filter-checklist .filter-checklist-popup .reset-button {
        display: none; }
  .operations-filter .filter-price-range .range-input {
    width: 100%;
    height: 34px;
    line-height: 34px;
    padding: 0;
    background: none;
    border: 0;
    border-bottom: 1px solid #8d76f0;
    font-size: 17px;
    outline: none;
    font-size: 16px; }
    .operations-filter .filter-price-range .range-input::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: rgba(132, 148, 169, 0.5); }
    .operations-filter .filter-price-range .range-input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: rgba(132, 148, 169, 0.5);
      opacity: 1; }
    .operations-filter .filter-price-range .range-input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: rgba(132, 148, 169, 0.5);
      opacity: 1; }
    .operations-filter .filter-price-range .range-input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(132, 148, 169, 0.5); }
    .operations-filter .filter-price-range .range-input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(132, 148, 169, 0.5); }
    .operations-filter .filter-price-range .range-input::placeholder {
      /* Most modern browsers support this now. */
      color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .operations-filter .filter-price-range .range-input {
    font-size: 16px; } }
  .operations-filter .filter-price-range .range-delim {
    display: inline-block;
    width: 34px;
    color: #8494a9;
    text-align: center; }
  .operations-filter .filter-price-range-buttons {
    position: relative; }
    .operations-filter .filter-price-range-buttons .filter-label-button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px;
      margin-bottom: 12px;
      background: #fff;
      color: #4b4b4b;
      font-size: 14px;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
      .operations-filter .filter-price-range-buttons .filter-label-button .text,
      .operations-filter .filter-price-range-buttons .filter-label-button .icon {
        display: inline-block;
        vertical-align: top; }
      .operations-filter .filter-price-range-buttons .filter-label-button .icon {
        margin-right: 0; }
      .operations-filter .filter-price-range-buttons .filter-label-button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .operations-filter .filter-price-range-buttons .filter-label-button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .operations-filter .filter-price-range-buttons .filter-label-button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .operations-filter .filter-price-range-buttons .filter-label-button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .operations-filter .filter-price-range-buttons .filter-label-button {
    font-weight: normal; } }
      .operations-filter .filter-price-range-buttons .filter-label-button .icon {
        margin-right: 8px;
        color: #b9e398;
        font-size: 22px; }
        .operations-filter .filter-price-range-buttons .filter-label-button .icon:after {
          content: "\20bd"; }
      .operations-filter .filter-price-range-buttons .filter-label-button:hover {
        background: #eaeff9; }
    .operations-filter .filter-price-range-buttons .filter-price-range-popup {
      position: relative;
      display: none;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 20;
      position: absolute;
      top: -21px;
      left: -12px; }
      .operations-filter .filter-price-range-buttons .filter-price-range-popup.show {
        display: block; }
      .operations-filter .filter-price-range-buttons .filter-price-range-popup .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 25px; }

@media screen and (max-width: 500px) {
  .operations-filter .filter-price-range-buttons .filter-price-range-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .operations-filter .filter-price-range-buttons .filter-price-range-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .operations-filter .filter-price-range-buttons .filter-price-range-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
      .operations-filter .filter-price-range-buttons .filter-price-range-popup .checklist-header {
        margin: 26px 0 23px 32px;
        font-size: 14px;
        background: none; }
        .operations-filter .filter-price-range-buttons .filter-price-range-popup .checklist-header .icon,
        .operations-filter .filter-price-range-buttons .filter-price-range-popup .checklist-header .text {
          vertical-align: top; }
        .operations-filter .filter-price-range-buttons .filter-price-range-popup .checklist-header .icon {
          margin-right: 8px;
          color: #b9e398;
          font-size: 22px; }
          .operations-filter .filter-price-range-buttons .filter-price-range-popup .checklist-header .icon:after {
            content: "\20bd"; }
      .operations-filter .filter-price-range-buttons .filter-price-range-popup .popup-content {
        padding: 0 24px 24px 32px; }
      .operations-filter .filter-price-range-buttons .filter-price-range-popup .range-selector {
        width: 300px;
        margin-bottom: 18px;
        font-size: 0;
        border-bottom: 1px solid #8d76f0; }
        .operations-filter .filter-price-range-buttons .filter-price-range-popup .range-selector input {
          width: 125px;
          border: none;
          font-size: 22px; }
          .operations-filter .filter-price-range-buttons .filter-price-range-popup .range-selector input.range-from {
            text-align: right; }
        .operations-filter .filter-price-range-buttons .filter-price-range-popup .range-selector .range-delim {
          display: inline-block;
          width: 50px;
          font-size: 22px;
          text-align: center; }
      .operations-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons {
        text-align: center; }
        .operations-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          background: none;
          border: 1px solid #4b4b4b;
          color: #4b4b4b;
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px;
          line-height: 30px;
          margin: 0 8px; }
          .operations-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button .text,
          .operations-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button .icon {
            display: inline-block;
            vertical-align: top; }
          .operations-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button .icon {
            margin-right: 0; }
          .operations-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .operations-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .operations-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .operations-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .operations-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button {
    font-weight: normal; } }
          .operations-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button:hover {
            background: #f2f5fb; }
  .operations-filter .search-select {
    position: relative; }
    .operations-filter .search-select select {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      filter: alpha(opacity=0);
      filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
    .operations-filter .search-select .search-field {
      position: relative;
      border-bottom: 1px solid #8d76f0; }
      .operations-filter .search-select .search-field input {
        width: 100%;
        height: 34px;
        line-height: 34px;
        padding: 0;
        background: none;
        border: 0;
        border-bottom: 1px solid #8d76f0;
        font-size: 17px;
        outline: none;
        width: 90%;
        border-bottom: 0; }
        .operations-filter .search-select .search-field input::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: rgba(132, 148, 169, 0.5); }
        .operations-filter .search-select .search-field input:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .operations-filter .search-select .search-field input::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .operations-filter .search-select .search-field input:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba(132, 148, 169, 0.5); }
        .operations-filter .search-select .search-field input::-ms-input-placeholder {
          /* Microsoft Edge */
          color: rgba(132, 148, 169, 0.5); }
        .operations-filter .search-select .search-field input::placeholder {
          /* Most modern browsers support this now. */
          color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .operations-filter .search-select .search-field input {
    font-size: 16px; } }
        .operations-filter .search-select .search-field input::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: #4b4b4b; }
        .operations-filter .search-select .search-field input:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #4b4b4b;
          opacity: 1; }
        .operations-filter .search-select .search-field input::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #4b4b4b;
          opacity: 1; }
        .operations-filter .search-select .search-field input:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #4b4b4b; }
        .operations-filter .search-select .search-field input::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #4b4b4b; }
        .operations-filter .search-select .search-field input::placeholder {
          /* Most modern browsers support this now. */
          color: #4b4b4b; }
      .operations-filter .search-select .search-field .field-dropdown-icon {
        position: absolute;
        top: 7px;
        right: 21px;
        width: 0; }
        .operations-filter .search-select .search-field .field-dropdown-icon:after {
          content: "";
          width: 14px;
          height: 7px;
          background: url(images/arrow-bottom.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
    .operations-filter .search-select .select-list-container {
      display: block;
      background: #fff;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border-radius: 10px;
      -webkit-transition: box-shadow 0.1s ease;
      -moz-transition: box-shadow 0.1s ease;
      -ms-transition: box-shadow 0.1s ease;
      -o-transition: box-shadow 0.1s ease;
      transition: box-shadow 0.1s ease;
      display: none;
      position: absolute;
      top: -17px;
      left: -17px;
      right: -17px;
      padding-top: 60px;
      padding-bottom: 17px;
      z-index: 1;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .operations-filter .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
    .operations-filter .search-select .select-list {
      position: relative;
      max-height: 200px;
      overflow: auto; }
      .operations-filter .search-select .select-list .select-list-button {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px 17px;
        line-height: 20px;
        background: none;
        color: #4b4b4b;
        font-weight: normal;
        text-align: left;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0; }
        .operations-filter .search-select .select-list .select-list-button .text,
        .operations-filter .search-select .select-list .select-list-button .icon {
          display: inline-block;
          vertical-align: top; }
        .operations-filter .search-select .select-list .select-list-button .icon {
          margin-right: 0; }
        .operations-filter .search-select .select-list .select-list-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .operations-filter .search-select .select-list .select-list-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .operations-filter .search-select .select-list .select-list-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .operations-filter .search-select .select-list .select-list-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .operations-filter .search-select .select-list .select-list-button {
    font-weight: normal; } }
        .operations-filter .search-select .select-list .select-list-button.active {
          background: #f2f5fb; }
        .operations-filter .search-select .select-list .select-list-button.selected {
          background: #f2f5fb; }
        .operations-filter .search-select .select-list .select-list-button:hover {
          background: #f2f5fb; }
      .operations-filter .search-select .select-list .select-list-action {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px 17px;
        line-height: 20px;
        background: none;
        color: #4b4b4b;
        font-weight: normal;
        text-align: left;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0; }
        .operations-filter .search-select .select-list .select-list-action .text,
        .operations-filter .search-select .select-list .select-list-action .icon {
          display: inline-block;
          vertical-align: top; }
        .operations-filter .search-select .select-list .select-list-action .icon {
          margin-right: 0; }
        .operations-filter .search-select .select-list .select-list-action.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .operations-filter .search-select .select-list .select-list-action.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .operations-filter .search-select .select-list .select-list-action.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .operations-filter .search-select .select-list .select-list-action:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .operations-filter .search-select .select-list .select-list-action {
    font-weight: normal; } }
        .operations-filter .search-select .select-list .select-list-action:hover {
          background: #f2f5fb; }
    .operations-filter .search-select.show-list .select-list-container {
      display: block; }
    .operations-filter .search-select.show-list .search-field {
      z-index: 1;
      border-bottom-color: rgba(0, 0, 0, 0.1); }
  .operations-filter .filter-button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    background: none;
    color: #4b4b4b;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    line-height: 38px;
    height: 38px;
    -webkit-border-radius: 19px;
    -moz-border-radius: 19px;
    -ms-border-radius: 19px;
    -o-border-radius: 19px;
    border-radius: 19px;
    display: flex;
    align-items: center; }
    .operations-filter .filter-button .text,
    .operations-filter .filter-button .icon {
      display: inline-block;
      vertical-align: top; }
    .operations-filter .filter-button .icon {
      margin-right: 0; }
    .operations-filter .filter-button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .operations-filter .filter-button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .operations-filter .filter-button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .operations-filter .filter-button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .operations-filter .filter-button {
    font-weight: normal; } }
    .operations-filter .filter-button:hover {
      background: none;
      color: black;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }
    .operations-filter .filter-button:active {
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 500px) {
  .operations-filter .filter-field {
    margin-right: 0;
    margin-left: 0; }
  .operations-filter .filter-control .filter-datepicker .filter-date-checklist-popup {
    left: 0;
    top: 0;
    width: 100%; }
  .operations-filter .filter-control .filter-checklist .filter-checklist-popup {
    left: 0;
    top: 0;
    width: 100%; } }

@media screen and (max-width: 450px) {
  .operations-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
    .operations-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button .text,
    .operations-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button .icon {
      display: inline-block;
      vertical-align: top; }
    .operations-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button .icon {
      margin-right: 0; }
    .operations-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .operations-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .operations-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .operations-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); } }

@media screen and (max-width: 450px) and (max-width: 450px) {
  .operations-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button {
    font-weight: normal; } }

@media screen and (max-width: 450px) {
  .operations-filter .filter-control .filter-datepicker .filter-date-checklist-popup .checklist-header {
    margin-top: 28px;
    margin-left: 67px;
    font-size: 16px;
    color: #8494a9; }
    .operations-filter .filter-control .filter-datepicker .filter-date-checklist-popup .checklist-header .icon {
      display: none; }
  .operations-filter .filter-control .filter-datepicker .filter-date-checklist-popup .checklist {
    max-height: 9999px; }
    .operations-filter .filter-control .filter-datepicker .filter-date-checklist-popup .checklist button {
      font-weight: normal;
      color: #4b4b4b; }
  .operations-filter .filter-control .filter-checklist .filter-checklist-popup .checklist-header {
    margin-top: 28px;
    margin-left: 67px;
    font-size: 16px; }
  .operations-filter .filter-control .filter-checklist .filter-checklist-popup .checklist {
    position: absolute;
    top: 70px;
    bottom: 40px;
    left: 0;
    width: 100%;
    max-height: 9999px; }
    .operations-filter .filter-control .filter-checklist .filter-checklist-popup .checklist button {
      height: 48px;
      color: #4b4b4b; }
      .operations-filter .filter-control .filter-checklist .filter-checklist-popup .checklist button .text {
        font-size: 16px; }
      .operations-filter .filter-control .filter-checklist .filter-checklist-popup .checklist button .icon,
      .operations-filter .filter-control .filter-checklist .filter-checklist-popup .checklist button .icon-checked {
        top: 12px; }
  .operations-filter .filter-control .filter-checklist .filter-checklist-popup .search-field + .checklist {
    top: 120px; }
  .operations-filter .filter-control .filter-checklist .filter-checklist-popup .search-field {
    width: 100%;
    padding-right: 24px;
    box-sizing: border-box; }
    .operations-filter .filter-control .filter-checklist .filter-checklist-popup .search-field .icon {
      right: 24px; }
  .operations-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
    .operations-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button .text,
    .operations-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button .icon {
      display: inline-block;
      vertical-align: top; }
    .operations-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button .icon {
      margin-right: 0; }
    .operations-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .operations-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .operations-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .operations-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); } }

@media screen and (max-width: 450px) and (max-width: 450px) {
  .operations-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button {
    font-weight: normal; } }

@media screen and (max-width: 450px) {
  .operations-filter .filter-control .filter-checklist .filter-checklist-popup .reset-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #4b4b4b;
    display: block;
    position: absolute;
    top: 30px;
    right: 24px; }
    .operations-filter .filter-control .filter-checklist .filter-checklist-popup .reset-button:hover {
      color: black; } }
  .operations-filter .filter-field {
    margin-bottom: 24px; }
  .operations-filter .field-name-open_filter .filter-button .button-icon {
    width: 20px;
    height: 20px;
    background: url(images/icon-filter.png) no-repeat center center;
    display: inline-block;
    background-size: contain;
    margin-right: 8px; }
  .operations-filter .field-name-date_range,
  .operations-filter .field-name-accounts {
    width: 254px;
    margin-right: 35px; }
  .operations-filter .field-name-partners,
  .operations-filter .field-name-categories {
    width: 190px; }
  .operations-filter .field-name-price_range {
    margin-right: 35px; }
    .operations-filter .field-name-price_range .range-input {
      width: 110px; }
  .operations-filter .filter-checklist .filter-checklist-popup {
    top: -24px;
    left: -24px; }
  .operations-filter .field-name-send {
    display: none; }

.operations-dated-list .operations-date {
  padding: 0 24px;
  margin-bottom: 12px;
  color: #8494a9; }

.operations-dated-list .operations-list {
  margin-bottom: 24px; }

.operations-dated-list .operations-list-item {
  display: flex;
  justify-content: space-between;
  text-align: left;
  height: 70px;
  padding: 0 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer; }
  .operations-dated-list .operations-list-item .list-item-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .operations-dated-list .operations-list-item .list-item-col.icon {
      width: 38px;
      flex-shrink: 0; }
    .operations-dated-list .operations-list-item .list-item-col.caption {
      margin-right: auto;
      margin-left: 18px; }
    .operations-dated-list .operations-list-item .list-item-col.price {
      flex-shrink: 0;
      text-align: right; }
    .operations-dated-list .operations-list-item .list-item-col .note {
      font-size: 12px;
      color: #8494a9; }
  .operations-dated-list .operations-list-item:hover {
    background: #f2f5fb; }

.operations-list-item .list-item-col {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .operations-list-item .list-item-col .text,
  .operations-list-item .list-item-col .note {
    height: 18px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis; }
  .operations-list-item .list-item-col .text {
    font-size: 16px; }
  .operations-list-item .list-item-col.icon .icon-content {
    display: inline-block;
    width: 38px;
    height: 38px;
    line-height: 37px;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    color: #fff;
    background: #bed6f9;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%; }
    .operations-list-item .list-item-col.icon .icon-content.icon-sub-correction {
      line-height: 34.2px; }
    .operations-list-item .list-item-col.icon .icon-content.color-1 {
      background: #bed6f9; }
    .operations-list-item .list-item-col.icon .icon-content.color-2 {
      background: #b9e398; }
    .operations-list-item .list-item-col.icon .icon-content.color-3 {
      background: #e4e597; }
    .operations-list-item .list-item-col.icon .icon-content.color-4 {
      background: #e7c740; }
    .operations-list-item .list-item-col.icon .icon-content.color-5 {
      background: #e74040; }
    .operations-list-item .list-item-col.icon .icon-content.color-6 {
      background: #cf91af; }
  .operations-list-item .list-item-col.price {
    max-width: 150px; }
    .operations-list-item .list-item-col.price .text {
      font-size: 0; }
      .operations-list-item .list-item-col.price .text .int,
      .operations-list-item .list-item-col.price .text .delim {
        font-size: 22px; }
      .operations-list-item .list-item-col.price .text .float,
      .operations-list-item .list-item-col.price .text .unit {
        font-size: 17px; }
      .operations-list-item .list-item-col.price .text .unit {
        margin-left: 8px; }
      .operations-list-item .list-item-col.price .text .unit-ruble:after {
        content: "\20bd"; }
      .operations-list-item .list-item-col.price .text.income, .operations-list-item .list-item-col.price .text.transfer-income {
        color: #4dbd7d; }
        .operations-list-item .list-item-col.price .text.income .int,
        .operations-list-item .list-item-col.price .text.income .delim,
        .operations-list-item .list-item-col.price .text.income .float,
        .operations-list-item .list-item-col.price .text.income .unit, .operations-list-item .list-item-col.price .text.transfer-income .int,
        .operations-list-item .list-item-col.price .text.transfer-income .delim,
        .operations-list-item .list-item-col.price .text.transfer-income .float,
        .operations-list-item .list-item-col.price .text.transfer-income .unit {
          color: #4dbd7d; }
      .operations-list-item .list-item-col.price .text .float,
      .operations-list-item .list-item-col.price .text .unit {
        color: #8494a9; }
  .operations-list-item .list-item-col.actions {
    position: relative;
    overflow: visible;
    padding-left: 10px; }
    .operations-list-item .list-item-col.actions .actions-button {
      position: relative;
      background: none;
      border: none;
      font-size: 17px;
      color: #8494a9; }
    .operations-list-item .list-item-col.actions .actions-popup {
      position: relative;
      display: none;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 20;
      display: none;
      position: absolute;
      top: 0;
      right: -30px;
      padding: 18px 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0; }
      .operations-list-item .list-item-col.actions .actions-popup.show {
        display: block; }
      .operations-list-item .list-item-col.actions .actions-popup .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 25px; }

@media screen and (max-width: 500px) {
  .operations-list-item .list-item-col.actions .actions-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .operations-list-item .list-item-col.actions .actions-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .operations-list-item .list-item-col.actions .actions-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
      .operations-list-item .list-item-col.actions .actions-popup .action-item {
        position: relative;
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b;
        display: block;
        min-width: 260px;
        padding: 5px 0 5px 60px;
        font-size: 16px;
        text-align: left; }
        .operations-list-item .list-item-col.actions .actions-popup .action-item:hover {
          color: black; }
        .operations-list-item .list-item-col.actions .actions-popup .action-item::after {
          content: "";
          display: block;
          position: absolute;
          top: 5px;
          left: 25px; }
        .operations-list-item .list-item-col.actions .actions-popup .action-item.action-edit::after {
          width: 22px;
          height: 22px;
          background: url(images/icon-edit-black.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
        .operations-list-item .list-item-col.actions .actions-popup .action-item.action-copy::after {
          width: 19px;
          height: 20px;
          background: url(images/icon-copy-link.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
        .operations-list-item .list-item-col.actions .actions-popup .action-item.action-delete::after {
          width: 19px;
          height: 19px;
          background: url(images/icon-close-black.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          top: 9px;
          left: 27px;
          width: 14px;
          height: 14px; }
    .operations-list-item .list-item-col.actions.opened .actions-button {
      z-index: 30; }
    .operations-list-item .list-item-col.actions.opened .actions-popup {
      display: block; }

@media screen and (max-width: 450px) {
  .operations-list-item .list-item-col.actions .actions-popup .close-button {
    width: 19px;
    height: 19px;
    background: url(images/icon-close.png) no-repeat center center;
    display: inline-block;
    background-size: contain;
    top: 23px;
    right: 23px;
    left: auto; } }

/* GRAPHS */
.graphs-filter-button {
  display: inline-block;
  height: 50px;
  line-height: 48px;
  padding: 0 20px;
  text-align: center;
  vertical-align: top;
  background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
  border: 0;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  box-sizing: border-box;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
  -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
  -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
  -o-transition: background 0.1s ease, box-shadow 0.1s ease;
  transition: background 0.1s ease, box-shadow 0.1s ease;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  font-weight: normal;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  background: #f2f5fb;
  color: #4b4b4b; }
  .graphs-filter-button .text,
  .graphs-filter-button .icon {
    display: inline-block;
    vertical-align: top; }
  .graphs-filter-button .icon {
    margin-right: 0; }
  .graphs-filter-button.button-xs {
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    font-weight: normal;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    border-radius: 15px; }
  .graphs-filter-button.button-s {
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px; }
  .graphs-filter-button.button-l {
    height: 60px;
    line-height: 58px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px; }
  .graphs-filter-button:hover {
    background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .graphs-filter-button {
    font-weight: normal; } }
  .graphs-filter-button .icon-date {
    width: 17px;
    height: 16px;
    background: url(images/icon-date-black.png) no-repeat center center;
    display: inline-block;
    background-size: contain;
    margin-right: 6px;
    margin-top: 6px; }
  .graphs-filter-button:hover {
    background: #eaeff9; }

.graphs-plate {
  margin-bottom: 18px; }
  .graphs-plate .filter-container {
    position: relative;
    padding: 24px 24px 0 24px; }
  .graphs-plate .filter-date-popup {
    position: relative;
    display: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    position: absolute;
    top: 24px;
    right: 24px; }
    .graphs-plate .filter-date-popup.show {
      display: block; }
    .graphs-plate .filter-date-popup .close-button {
      width: 19px;
      height: 19px;
      background: url(images/icon-close.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: absolute;
      top: 25px;
      right: 25px; }

@media screen and (max-width: 500px) {
  .graphs-plate .filter-date-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .graphs-plate .filter-date-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .graphs-plate .filter-date-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
    .graphs-plate .filter-date-popup .selector-button {
      margin: 26px 0 23px 32px;
      font-size: 17px;
      background: none; }
      .graphs-plate .filter-date-popup .selector-button .icon,
      .graphs-plate .filter-date-popup .selector-button .text {
        vertical-align: top; }
      .graphs-plate .filter-date-popup .selector-button .icon {
        width: 21px;
        height: 18px;
        background: url(images/icon-date.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        margin-top: 2px;
        margin-right: 4px; }
    .graphs-plate .filter-date-popup .checklist {
      min-width: 240px;
      padding-bottom: 24px; }
      .graphs-plate .filter-date-popup .checklist button {
        position: relative;
        display: block;
        width: 390px;
        height: 48px;
        line-height: 48px;
        padding-right: 32px;
        background: none;
        font-size: 17px;
        color: #4b4b4b; }
        .graphs-plate .filter-date-popup .checklist button .icon {
          position: absolute;
          top: 13px;
          left: 24px;
          display: inline-block;
          width: 18px;
          height: 18px;
          border: 2px solid #8494a9;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          border-radius: 3px; }
        .graphs-plate .filter-date-popup .checklist button .icon-checked {
          position: relative;
          display: inline-block;
          width: 22px;
          height: 22px;
          background: #81bc52;
          border: 0;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          border-radius: 3px;
          position: absolute;
          top: 13px;
          left: 24px; }
          .graphs-plate .filter-date-popup .checklist button .icon-checked::after {
            content: "";
            width: 16px;
            height: 12px;
            background: url(images/icon-check-white.png) no-repeat center center;
            display: inline-block;
            background-size: contain;
            position: absolute;
            top: 7px;
            left: 4px;
            width: 13px;
            height: 9px; }
        .graphs-plate .filter-date-popup .checklist button .text {
          display: block;
          margin-left: 60px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          font-size: 14px; }
        .graphs-plate .filter-date-popup .checklist button:hover {
          background: #f2f5fb; }
        .graphs-plate .filter-date-popup .checklist button.hidden {
          display: none; }

@media screen and (max-width: 500px) {
  .graphs-plate .filter-date-popup .checklist button {
    width: 100%;
    box-sizing: border-box; }
    .graphs-plate .filter-date-popup .checklist button .icon,
    .graphs-plate .filter-date-popup .checklist button .icon-checked {
      top: 7px; } }

@media screen and (max-width: 450px) {
  .graphs-plate .filter-date-popup .checklist button {
    font-weight: normal;
    height: 36px;
    line-height: 36px; } }
  .graphs-plate .datepicker-popup {
    position: absolute;
    top: 24px;
    right: 24px; }
  .graphs-plate .graphs-delim {
    display: block;
    height: 1px;
    background: rgba(0, 0, 0, 0.1); }
  .graphs-plate .graph-container {
    position: relative;
    padding: 24px; }
  .graphs-plate .graph-header {
    background: none;
    border: none;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 24px; }
    .graphs-plate .graph-header:hover {
      color: black; }
  .graphs-plate .graph-info-container {
    margin-bottom: 12px; }
  .graphs-plate .more-link {
    text-align: right; }
    .graphs-plate .more-link a,
    .graphs-plate .more-link button {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b; }
      .graphs-plate .more-link a:hover,
      .graphs-plate .more-link button:hover {
        color: black; }

.stats-graph {
  display: flex;
  justify-content: space-between; }
  .stats-graph .left-col {
    width: 120px;
    flex-shrink: 0; }
  .stats-graph .right-col {
    width: 220px; }
    .stats-graph .right-col .price {
      font-size: 0;
      display: block;
      margin-bottom: 18px;
      text-align: right; }
      .stats-graph .right-col .price .int,
      .stats-graph .right-col .price .delim {
        font-size: 22px; }
      .stats-graph .right-col .price .float,
      .stats-graph .right-col .price .unit {
        font-size: 17px; }
      .stats-graph .right-col .price .unit {
        margin-left: 8px; }
      .stats-graph .right-col .price .unit-ruble:after {
        content: "\20bd"; }
      .stats-graph .right-col .price.income, .stats-graph .right-col .price.transfer-income {
        color: #4dbd7d; }
        .stats-graph .right-col .price.income .int,
        .stats-graph .right-col .price.income .delim,
        .stats-graph .right-col .price.income .float,
        .stats-graph .right-col .price.income .unit, .stats-graph .right-col .price.transfer-income .int,
        .stats-graph .right-col .price.transfer-income .delim,
        .stats-graph .right-col .price.transfer-income .float,
        .stats-graph .right-col .price.transfer-income .unit {
          color: #4dbd7d; }
    .stats-graph .right-col .target-price {
      margin-bottom: 24px;
      text-align: right; }
      .stats-graph .right-col .target-price .label {
        margin-bottom: 6px;
        font-size: 12px; }
      .stats-graph .right-col .target-price .progress-line {
        width: 100%;
        height: 4px;
        background: rgba(0, 0, 0, 0.1);
        overflow: hidden;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        -ms-border-radius: 2px;
        -o-border-radius: 2px;
        border-radius: 2px;
        margin-bottom: 6px; }
        .stats-graph .right-col .target-price .progress-line .filling {
          height: 100%;
          background: #b9e398;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          -ms-border-radius: 2px;
          -o-border-radius: 2px;
          border-radius: 2px; }
      .stats-graph .right-col .target-price .unit-ruble:after {
        content: "\20bd"; }
  .stats-graph .markers-list-item {
    display: flex;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 6px; }
    .stats-graph .markers-list-item .list-item-col {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .stats-graph .markers-list-item .list-item-col.icon {
        width: 38px;
        flex-shrink: 0; }
      .stats-graph .markers-list-item .list-item-col.caption {
        margin-right: auto;
        margin-left: 18px; }
      .stats-graph .markers-list-item .list-item-col.price {
        flex-shrink: 0;
        text-align: right; }
      .stats-graph .markers-list-item .list-item-col .note {
        font-size: 12px;
        color: #8494a9; }
    .stats-graph .markers-list-item .list-item-col.icon {
      width: 6px;
      height: 6px;
      margin-top: 8px;
      background: #b9e398;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      border-radius: 50%; }
      .stats-graph .markers-list-item .list-item-col.icon.color-2 {
        background: #7bb0f9; }
      .stats-graph .markers-list-item .list-item-col.icon.color-3 {
        background: #c581dd; }
      .stats-graph .markers-list-item .list-item-col.icon.color-4 {
        background: #e9eb86; }
      .stats-graph .markers-list-item .list-item-col.icon.color-5 {
        background: #f671c3; }
      .stats-graph .markers-list-item .list-item-col.icon.color-6 {
        background: #efd47b; }
      .stats-graph .markers-list-item .list-item-col.icon.color-7 {
        background: #9da4fa; }
    .stats-graph .markers-list-item .list-item-col.caption {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b;
      margin-left: 8px; }
      .stats-graph .markers-list-item .list-item-col.caption:hover {
        color: black; }
    .stats-graph .markers-list-item .value {
      flex-shrink: 0;
      margin-left: 8px; }
  .stats-graph .pie-chart {
    width: 100px;
    height: 100px; }
  .stats-graph .pie-chart-empty {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    border: 9px solid #8494a9;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    opacity: 0.6;
    filter: alpha(opacity=60);
    filter: progid:DXImageTransform.Microsoft.Alpha Opacity=60;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
  .stats-graph .chart-note {
    width: 100px;
    text-align: center;
    color: #8494a9; }

.stats-graph-compact .price {
  font-size: 0;
  display: block;
  margin-bottom: 12px;
  text-align: center; }
  .stats-graph-compact .price .int,
  .stats-graph-compact .price .delim {
    font-size: 22px; }
  .stats-graph-compact .price .float,
  .stats-graph-compact .price .unit {
    font-size: 17px; }
  .stats-graph-compact .price .unit {
    margin-left: 8px; }
  .stats-graph-compact .price .unit-ruble:after {
    content: "\20bd"; }
  .stats-graph-compact .price.income, .stats-graph-compact .price.transfer-income {
    color: #4dbd7d; }
    .stats-graph-compact .price.income .int,
    .stats-graph-compact .price.income .delim,
    .stats-graph-compact .price.income .float,
    .stats-graph-compact .price.income .unit, .stats-graph-compact .price.transfer-income .int,
    .stats-graph-compact .price.transfer-income .delim,
    .stats-graph-compact .price.transfer-income .float,
    .stats-graph-compact .price.transfer-income .unit {
      color: #4dbd7d; }

.stats-graph-compact .target-price {
  margin-bottom: 24px;
  text-align: center; }
  .stats-graph-compact .target-price .label {
    margin-bottom: 6px;
    font-size: 12px; }
  .stats-graph-compact .target-price .progress-line {
    width: 100%;
    height: 4px;
    background: rgba(0, 0, 0, 0.1);
    overflow: hidden;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    margin-bottom: 6px; }
    .stats-graph-compact .target-price .progress-line .filling {
      height: 100%;
      background: #b9e398;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      -o-border-radius: 2px;
      border-radius: 2px; }
  .stats-graph-compact .target-price .unit-ruble:after {
    content: "\20bd"; }

.stats-graph-compact .pie-chart {
  width: 100px;
  height: 100px;
  margin: 0 auto 10px auto; }

.stats-graph-compact .pie-chart-empty {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  border: 9px solid #8494a9;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  opacity: 0.6;
  filter: alpha(opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha Opacity=60;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }

.stats-graph-compact .more-link {
  margin-bottom: 12px;
  text-align: center; }

/* TARGETS */
.targets-content .layout-title {
  margin-bottom: 32px; }
  .targets-content .layout-title h1 {
    font-size: 22px;
    font-weight: 500; }

.targets-content .layout-actions {
  margin-bottom: 40px; }
  .targets-content .layout-actions button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    min-width: 206px; }
    .targets-content .layout-actions button .text,
    .targets-content .layout-actions button .icon {
      display: inline-block;
      vertical-align: top; }
    .targets-content .layout-actions button .icon {
      margin-right: 0; }
    .targets-content .layout-actions button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .targets-content .layout-actions button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .targets-content .layout-actions button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .targets-content .layout-actions button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .targets-content .layout-actions button {
    font-weight: normal; } }
    .targets-content .layout-actions button.name-bank-integration {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: none;
      border: 1px solid #4b4b4b;
      color: #4b4b4b;
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
      .targets-content .layout-actions button.name-bank-integration .text,
      .targets-content .layout-actions button.name-bank-integration .icon {
        display: inline-block;
        vertical-align: top; }
      .targets-content .layout-actions button.name-bank-integration .icon {
        margin-right: 0; }
      .targets-content .layout-actions button.name-bank-integration.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .targets-content .layout-actions button.name-bank-integration.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .targets-content .layout-actions button.name-bank-integration.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .targets-content .layout-actions button.name-bank-integration:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .targets-content .layout-actions button.name-bank-integration {
    font-weight: normal; } }
      .targets-content .layout-actions button.name-bank-integration:hover {
        background: #f2f5fb; }

.targets-content .layout-plate {
  display: block;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: box-shadow 0.1s ease;
  -moz-transition: box-shadow 0.1s ease;
  -ms-transition: box-shadow 0.1s ease;
  -o-transition: box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease; }

@media screen and (max-width: 450px) {
  .targets-content .layout-plate {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }

.targets-content .layout-cards-list {
  padding: 34px 0; }
  .targets-content .layout-cards-list .list-item {
    position: relative;
    padding: 26px 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .targets-content .layout-cards-list .list-item .list-item-container {
      position: relative; }
    .targets-content .layout-cards-list .list-item .li-title {
      margin-bottom: 3px; }
      .targets-content .layout-cards-list .list-item .li-title p {
        color: #0e0e0e;
        font-size: 32px; }
      .targets-content .layout-cards-list .list-item .li-title .price {
        font-size: 0;
        color: #0e0e0e; }
        .targets-content .layout-cards-list .list-item .li-title .price .int,
        .targets-content .layout-cards-list .list-item .li-title .price .delim {
          font-size: 32px; }
        .targets-content .layout-cards-list .list-item .li-title .price .float,
        .targets-content .layout-cards-list .list-item .li-title .price .unit {
          font-size: 22px; }
        .targets-content .layout-cards-list .list-item .li-title .price .unit {
          margin-left: 8px; }
        .targets-content .layout-cards-list .list-item .li-title .price .unit-ruble:after {
          content: "\20bd"; }
        .targets-content .layout-cards-list .list-item .li-title .price.income, .targets-content .layout-cards-list .list-item .li-title .price.transfer-income {
          color: #4dbd7d; }
          .targets-content .layout-cards-list .list-item .li-title .price.income .int,
          .targets-content .layout-cards-list .list-item .li-title .price.income .delim,
          .targets-content .layout-cards-list .list-item .li-title .price.income .float,
          .targets-content .layout-cards-list .list-item .li-title .price.income .unit, .targets-content .layout-cards-list .list-item .li-title .price.transfer-income .int,
          .targets-content .layout-cards-list .list-item .li-title .price.transfer-income .delim,
          .targets-content .layout-cards-list .list-item .li-title .price.transfer-income .float,
          .targets-content .layout-cards-list .list-item .li-title .price.transfer-income .unit {
            color: #4dbd7d; }
        .targets-content .layout-cards-list .list-item .li-title .price .unit {
          color: #8494a9; }
    .targets-content .layout-cards-list .list-item .li-field p {
      font-size: 16px; }
    .targets-content .layout-cards-list .list-item .li-actions {
      position: absolute;
      top: 4px;
      right: 0; }
      .targets-content .layout-cards-list .list-item .li-actions .action-edit {
        width: 22px;
        height: 22px;
        background: url(images/icon-edit-gray.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
    .targets-content .layout-cards-list .list-item .li-mark {
      display: inline-block;
      padding: 0 7px;
      background: #b9e398;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      border-radius: 3px; }

@media screen and (max-width: 590px) {
  .targets-content .layout-cards-list {
    padding: 0; }
    .targets-content .layout-cards-list .list-item {
      padding: 24px 16px; } }

.targets-content .form-layout {
  display: block;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: box-shadow 0.1s ease;
  -moz-transition: box-shadow 0.1s ease;
  -ms-transition: box-shadow 0.1s ease;
  -o-transition: box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease; }

@media screen and (max-width: 450px) {
  .targets-content .form-layout {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
  .targets-content .form-layout .back-button {
    margin-bottom: 24px; }
    .targets-content .form-layout .back-button button {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b; }
      .targets-content .form-layout .back-button button:hover {
        color: black; }
      .targets-content .form-layout .back-button button:before {
        content: "\2190";
        margin-right: 4px; }
  .targets-content .form-layout .form-content {
    padding: 60px 50px; }
    .targets-content .form-layout .form-content .form-field {
      margin-bottom: 38px;
      width: 100%; }
      .targets-content .form-layout .form-content .form-field .form-label {
        display: block;
        font-size: 17px;
        color: #8494a9; }
      .targets-content .form-layout .form-content .form-field.field-type-colorpicker .form-label {
        margin-bottom: 8px; }
      .targets-content .form-layout .form-content .form-field.field-type-files .form-label {
        display: none; }
      .targets-content .form-layout .form-content .form-field .custom-checkput {
        position: relative;
        display: inline-block;
        width: auto;
        height: auto; }
        .targets-content .form-layout .form-content .form-field .custom-checkput input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .targets-content .form-layout .form-content .form-field .custom-checkput .checkput-icon {
          position: relative;
          display: inline-block;
          width: 18px;
          height: 18px;
          border: 2px solid #8494a9;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          border-radius: 3px;
          cursor: pointer; }
        .targets-content .form-layout .form-content .form-field .custom-checkput.checked .checkput-icon {
          position: relative;
          display: inline-block;
          width: 22px;
          height: 22px;
          background: #81bc52;
          border: 0;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          border-radius: 3px; }
          .targets-content .form-layout .form-content .form-field .custom-checkput.checked .checkput-icon::after {
            content: "";
            width: 16px;
            height: 12px;
            background: url(images/icon-check-white.png) no-repeat center center;
            display: inline-block;
            background-size: contain;
            position: absolute;
            top: 7px;
            left: 4px;
            width: 13px;
            height: 9px; }
      .targets-content .form-layout .form-content .form-field.field-type-checkbox .custom-checkput, .targets-content .form-layout .form-content .form-field.field-type-agreement .custom-checkput, .targets-content .form-layout .form-content .form-field.field-type-license-agreement .custom-checkput {
        float: left; }
      .targets-content .form-layout .form-content .form-field.field-type-checkbox .form-label, .targets-content .form-layout .form-content .form-field.field-type-agreement .form-label, .targets-content .form-layout .form-content .form-field.field-type-license-agreement .form-label {
        margin-left: 34px;
        cursor: pointer; }
      .targets-content .form-layout .form-content .form-field.field-type-agreement button, .targets-content .form-layout .form-content .form-field.field-type-license-agreement button {
        display: block;
        margin-left: 34px;
        height: auto;
        line-height: 22px;
        font-size: 17px;
        color: #8494a9;
        text-decoration: underline;
        background: none; }
      .targets-content .form-layout .form-content .form-field .form-field-password-generator button {
        color: #2f80ed;
        cursor: pointer;
        background: transparent; }
      .targets-content .form-layout .form-content .form-field .form-field-password-check {
        line-height: 16px;
        font-size: 12px;
        position: relative;
        margin-top: -2px;
        z-index: 1; }
        .targets-content .form-layout .form-content .form-field .form-field-password-check.active-red {
          width: 30%;
          border-top: 2px solid #F35E84;
          margin-bottom: 11px; }
        .targets-content .form-layout .form-content .form-field .form-field-password-check.active-green {
          width: 100%;
          border-top: 2px solid #5A9C76;
          margin-bottom: 11px; }
        .targets-content .form-layout .form-content .form-field .form-field-password-check.active-yellow {
          width: 70%;
          border-top: 2px solid #C99300;
          margin-bottom: 11px; }
        .targets-content .form-layout .form-content .form-field .form-field-password-check .password-check-hint-green {
          color: #5A9C76; }
        .targets-content .form-layout .form-content .form-field .form-field-password-check .password-check-hint-yellow {
          color: #C99300; }
        .targets-content .form-layout .form-content .form-field .form-field-password-check .password-check-hint-red {
          color: #F35E84; }

@media screen and (max-width: 500px) {
  .targets-content .form-layout .form-content .form-field {
    margin-bottom: 28px; }
    .targets-content .form-layout .form-content .form-field .form-label {
      font-size: 14px;
      line-height: 14px; } }
      .targets-content .form-layout .form-content .form-field .form-button {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        width: 100%; }
        .targets-content .form-layout .form-content .form-field .form-button .text,
        .targets-content .form-layout .form-content .form-field .form-button .icon {
          display: inline-block;
          vertical-align: top; }
        .targets-content .form-layout .form-content .form-field .form-button .icon {
          margin-right: 0; }
        .targets-content .form-layout .form-content .form-field .form-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .targets-content .form-layout .form-content .form-field .form-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .targets-content .form-layout .form-content .form-field .form-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .targets-content .form-layout .form-content .form-field .form-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .targets-content .form-layout .form-content .form-field .form-button {
    font-weight: normal; } }
      .targets-content .form-layout .form-content .form-field.field-error .form-label {
        color: #f32424; }
      .targets-content .form-layout .form-content .form-field.field-error .form-control .form-input,
      .targets-content .form-layout .form-content .form-field.field-error .form-control textarea,
      .targets-content .form-layout .form-content .form-field.field-error .form-control select {
        border-color: #f32424; }
      .targets-content .form-layout .form-content .form-field.field-error .form-field-error p {
        color: #f32424;
        white-space: pre-wrap; }
    .targets-content .form-layout .form-content .form-control {
      position: relative; }
      .targets-content .form-layout .form-content .form-control .form-input,
      .targets-content .form-layout .form-content .form-control textarea,
      .targets-content .form-layout .form-content .form-control select {
        width: 100%;
        height: 34px;
        line-height: 34px;
        padding: 0;
        background: none;
        border: 0;
        border-bottom: 1px solid #8d76f0;
        font-size: 17px;
        outline: none; }
        .targets-content .form-layout .form-content .form-control .form-input::-webkit-input-placeholder,
        .targets-content .form-layout .form-content .form-control textarea::-webkit-input-placeholder,
        .targets-content .form-layout .form-content .form-control select::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: rgba(132, 148, 169, 0.5); }
        .targets-content .form-layout .form-content .form-control .form-input:-moz-placeholder,
        .targets-content .form-layout .form-content .form-control textarea:-moz-placeholder,
        .targets-content .form-layout .form-content .form-control select:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .targets-content .form-layout .form-content .form-control .form-input::-moz-placeholder,
        .targets-content .form-layout .form-content .form-control textarea::-moz-placeholder,
        .targets-content .form-layout .form-content .form-control select::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .targets-content .form-layout .form-content .form-control .form-input:-ms-input-placeholder,
        .targets-content .form-layout .form-content .form-control textarea:-ms-input-placeholder,
        .targets-content .form-layout .form-content .form-control select:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba(132, 148, 169, 0.5); }
        .targets-content .form-layout .form-content .form-control .form-input::-ms-input-placeholder,
        .targets-content .form-layout .form-content .form-control textarea::-ms-input-placeholder,
        .targets-content .form-layout .form-content .form-control select::-ms-input-placeholder {
          /* Microsoft Edge */
          color: rgba(132, 148, 169, 0.5); }
        .targets-content .form-layout .form-content .form-control .form-input::placeholder,
        .targets-content .form-layout .form-content .form-control textarea::placeholder,
        .targets-content .form-layout .form-content .form-control select::placeholder {
          /* Most modern browsers support this now. */
          color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .targets-content .form-layout .form-content .form-control .form-input,
  .targets-content .form-layout .form-content .form-control textarea,
  .targets-content .form-layout .form-content .form-control select {
    font-size: 16px; } }
      .targets-content .form-layout .form-content .form-control textarea {
        resize: none;
        padding: 5px 0;
        line-height: 22px; }
      .targets-content .form-layout .form-content .form-control select {
        cursor: pointer; }
      .targets-content .form-layout .form-content .form-control .styled-select {
        position: relative; }
        .targets-content .form-layout .form-content .form-control .styled-select select {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .targets-content .form-layout .form-content .form-control .styled-select .select-button {
          position: relative;
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          width: 100%;
          height: auto;
          padding: 15px 30px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          border-radius: 8px;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
          .targets-content .form-layout .form-content .form-control .styled-select .select-button .text,
          .targets-content .form-layout .form-content .form-control .styled-select .select-button .icon {
            display: inline-block;
            vertical-align: top; }
          .targets-content .form-layout .form-content .form-control .styled-select .select-button .icon {
            margin-right: 0; }
          .targets-content .form-layout .form-content .form-control .styled-select .select-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .targets-content .form-layout .form-content .form-control .styled-select .select-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .targets-content .form-layout .form-content .form-control .styled-select .select-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .targets-content .form-layout .form-content .form-control .styled-select .select-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .targets-content .form-layout .form-content .form-control .styled-select .select-button {
    font-weight: normal; } }
          .targets-content .form-layout .form-content .form-control .styled-select .select-button .post-icon {
            position: relative;
            top: -1px;
            left: 8px;
            display: inline-block;
            width: 0; }
            .targets-content .form-layout .form-content .form-control .styled-select .select-button .post-icon:after {
              content: "";
              width: 14px;
              height: 7px;
              background: url(images/arrow-bottom.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
          .targets-content .form-layout .form-content .form-control .styled-select .select-button:hover {
            background: none;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
        .targets-content .form-layout .form-content .form-control .styled-select .select-list {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 1;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .targets-content .form-layout .form-content .form-control .styled-select .select-list {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
          .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 15px 50px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button .text,
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button .icon {
              display: inline-block;
              vertical-align: top; }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button .icon {
              margin-right: 0; }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button {
    font-weight: normal; } }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button.active .pre-icon {
              position: relative;
              left: -26px;
              display: inline-block;
              width: 0; }
              .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button.active .pre-icon:after {
                content: "";
                width: 16px;
                height: 12px;
                background: url(images/icon-check.png) no-repeat center center;
                display: inline-block;
                background-size: contain; }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button:after {
              content: "";
              display: block;
              position: absolute;
              top: 100%;
              left: 0;
              right: 0;
              height: 1px;
              background: rgba(0, 0, 0, 0.1);
              opacity: 0.5;
              filter: alpha(opacity=50);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button:hover {
              background: #f2f5fb; }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button:first-child:after {
              left: 23px;
              right: 23px;
              opacity: 1;
              filter: alpha(opacity=100);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button:last-child {
              margin-bottom: 40px; }
          .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 15px 23px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action .text,
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action .icon {
              display: inline-block;
              vertical-align: top; }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action .icon {
              margin-right: 0; }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action {
    font-weight: normal; } }
            .targets-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action:hover {
              background: #f2f5fb; }
          .targets-content .form-layout .form-content .form-control .styled-select .select-list.show {
            display: block; }
        .targets-content .form-layout .form-content .form-control .styled-select.show-list .select-button {
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .targets-content .form-layout .form-content .form-control .search-select {
        position: relative; }
        .targets-content .form-layout .form-content .form-control .search-select select {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .targets-content .form-layout .form-content .form-control .search-select .search-field {
          position: relative;
          border-bottom: 1px solid #8d76f0; }
          .targets-content .form-layout .form-content .form-control .search-select .search-field input {
            width: 100%;
            height: 34px;
            line-height: 34px;
            padding: 0;
            background: none;
            border: 0;
            border-bottom: 1px solid #8d76f0;
            font-size: 17px;
            outline: none;
            width: 90%;
            border-bottom: 0; }
            .targets-content .form-layout .form-content .form-control .search-select .search-field input::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: rgba(132, 148, 169, 0.5); }
            .targets-content .form-layout .form-content .form-control .search-select .search-field input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .targets-content .form-layout .form-content .form-control .search-select .search-field input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .targets-content .form-layout .form-content .form-control .search-select .search-field input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: rgba(132, 148, 169, 0.5); }
            .targets-content .form-layout .form-content .form-control .search-select .search-field input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: rgba(132, 148, 169, 0.5); }
            .targets-content .form-layout .form-content .form-control .search-select .search-field input::placeholder {
              /* Most modern browsers support this now. */
              color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .targets-content .form-layout .form-content .form-control .search-select .search-field input {
    font-size: 16px; } }
            .targets-content .form-layout .form-content .form-control .search-select .search-field input::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: #4b4b4b; }
            .targets-content .form-layout .form-content .form-control .search-select .search-field input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: #4b4b4b;
              opacity: 1; }
            .targets-content .form-layout .form-content .form-control .search-select .search-field input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: #4b4b4b;
              opacity: 1; }
            .targets-content .form-layout .form-content .form-control .search-select .search-field input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #4b4b4b; }
            .targets-content .form-layout .form-content .form-control .search-select .search-field input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: #4b4b4b; }
            .targets-content .form-layout .form-content .form-control .search-select .search-field input::placeholder {
              /* Most modern browsers support this now. */
              color: #4b4b4b; }
          .targets-content .form-layout .form-content .form-control .search-select .search-field .field-dropdown-icon {
            position: absolute;
            top: 7px;
            right: 21px;
            width: 0; }
            .targets-content .form-layout .form-content .form-control .search-select .search-field .field-dropdown-icon:after {
              content: "";
              width: 14px;
              height: 7px;
              background: url(images/arrow-bottom.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
        .targets-content .form-layout .form-content .form-control .search-select .select-list-container {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          display: none;
          position: absolute;
          top: -17px;
          left: -17px;
          right: -17px;
          padding-top: 60px;
          padding-bottom: 17px;
          z-index: 1;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .targets-content .form-layout .form-content .form-control .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
        .targets-content .form-layout .form-content .form-control .search-select .select-list {
          position: relative;
          max-height: 200px;
          overflow: auto; }
          .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 10px 17px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-button .text,
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-button .icon {
              display: inline-block;
              vertical-align: top; }
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-button .icon {
              margin-right: 0; }
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-button {
    font-weight: normal; } }
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-button.active {
              background: #f2f5fb; }
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-button.selected {
              background: #f2f5fb; }
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-button:hover {
              background: #f2f5fb; }
          .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-action {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 10px 17px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-action .text,
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-action .icon {
              display: inline-block;
              vertical-align: top; }
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-action .icon {
              margin-right: 0; }
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-action.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-action.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-action.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-action:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-action {
    font-weight: normal; } }
            .targets-content .form-layout .form-content .form-control .search-select .select-list .select-list-action:hover {
              background: #f2f5fb; }
        .targets-content .form-layout .form-content .form-control .search-select.show-list .select-list-container {
          display: block; }
        .targets-content .form-layout .form-content .form-control .search-select.show-list .search-field {
          z-index: 1;
          border-bottom-color: rgba(0, 0, 0, 0.1); }
      .targets-content .form-layout .form-content .form-control .field-price-input-container {
        position: relative;
        width: 100%; }
        .targets-content .form-layout .form-content .form-control .field-price-input-container .unit-box {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          text-align: center;
          white-space: nowrap; }
          .targets-content .form-layout .form-content .form-control .field-price-input-container .unit-box span {
            display: inline-block; }
          .targets-content .form-layout .form-content .form-control .field-price-input-container .unit-box .unit-background-text {
            color: transparent;
            font-size: 38px;
            max-width: 95%;
            overflow: hidden; }
          .targets-content .form-layout .form-content .form-control .field-price-input-container .unit-box .unit-background-text-space {
            width: 24px; }
        .targets-content .form-layout .form-content .form-control .field-price-input-container input {
          position: relative;
          height: 50px;
          line-height: 50px;
          box-sizing: border-box;
          text-align: center;
          font-size: 38px; }
        .targets-content .form-layout .form-content .form-control .field-price-input-container .unit-ruble {
          line-height: 52px;
          font-size: 32px;
          color: #8494a9;
          opacity: 0.7;
          filter: alpha(opacity=70);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=70;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .targets-content .form-layout .form-content .form-control .field-price-input-container .unit-ruble:after {
            content: "\20bd"; }
      .targets-content .form-layout .form-content .form-control .colorpicker {
        position: relative; }
        .targets-content .form-layout .form-content .form-control .colorpicker .picker-button {
          display: block;
          width: 105px;
          height: 30px;
          background: none;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          position: absolute;
          left: 0;
          bottom: -200px;
          width: 504px;
          padding: 32px 20px;
          z-index: 4;
          -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 450px) {
  .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
          .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-header {
            margin-bottom: 40px;
            margin-left: 10px;
            font-size: 22px; }
          .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-color-buttons {
            margin-bottom: 12px; }
          .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-color-button {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin: 0 12px 24px 12px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            border-radius: 50%; }
            .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-color-button.active, .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-color-button:hover {
              margin: -10px 2px 14px 2px;
              width: 80px;
              height: 80px;
              border: 10px solid #f2f5fb; }
          .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-corner-close-button {
            position: absolute;
            top: 0;
            right: 0;
            width: 80px;
            height: 80px;
            vertical-align: middle;
            text-align: center;
            font-size: 38px;
            font-family: "times new roman", verdana;
            background: none; }
          .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons {
            text-align: right; }
            .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              background: none;
              border: 1px solid #4b4b4b;
              color: #4b4b4b;
              font-size: 16px;
              min-width: 191px; }
              .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
              .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                display: inline-block;
                vertical-align: top; }
              .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                margin-right: 0; }
              .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
    font-weight: normal; } }
              .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                background: #f2f5fb; }
        .targets-content .form-layout .form-content .form-control .colorpicker .picker-palette-overlay {
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.7);
          z-index: 2; }
      .targets-content .form-layout .form-content .form-control .form-autocomplete-input {
        position: relative; }
        .targets-content .form-layout .form-content .form-control .form-autocomplete-input .form-autocomplete-dropdown {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          max-height: 300px;
          background: #fff;
          -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          z-index: 1;
          overflow: auto; }
          .targets-content .form-layout .form-content .form-control .form-autocomplete-input .form-autocomplete-dropdown button {
            background: none;
            border: none;
            width: 100%;
            padding: 8px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            text-align: left; }
            .targets-content .form-layout .form-content .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover {
              background: #f2f5fb; }
            .targets-content .form-layout .form-content .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child {
              border-bottom: 0; }
      .targets-content .form-layout .form-content .form-control .field-text-list-container .text-list-item {
        position: relative;
        margin-bottom: 10px; }
        .targets-content .form-layout .form-content .form-control .field-text-list-container .text-list-item .remove-text-item {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b;
          position: absolute;
          top: 0;
          right: 0;
          color: #f32424;
          font-size: 22px; }
          .targets-content .form-layout .form-content .form-control .field-text-list-container .text-list-item .remove-text-item:hover {
            color: black; }
      .targets-content .form-layout .form-content .form-control .field-text-list-container .add-text-item {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .targets-content .form-layout .form-content .form-control .field-text-list-container .add-text-item:hover {
          color: black; }
      .targets-content .form-layout .form-content .form-control .files-loader-container .files-loader-input-container {
        margin-bottom: 20px; }
      .targets-content .form-layout .form-content .form-control .files-loader-input-container {
        position: relative; }
        .targets-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          background: none;
          border: 1px solid #4b4b4b;
          color: #4b4b4b;
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px;
          width: 100%;
          height: 60px;
          line-height: 60px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          border-radius: 8px; }
          .targets-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area .text,
          .targets-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area .icon {
            display: inline-block;
            vertical-align: top; }
          .targets-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area .icon {
            margin-right: 0; }
          .targets-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .targets-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .targets-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .targets-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .targets-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area {
    font-weight: normal; } }
          .targets-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area:hover {
            background: #f2f5fb; }
        .targets-content .form-layout .form-content .form-control .files-loader-input-container input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .targets-content .form-layout .form-content .form-control .files-loader-input-container:hover .input-label-area {
          background: #f2f5fb; }
      .targets-content .form-layout .form-content .form-control .files-loader-list .files-list-item {
        display: flex;
        justify-content: space-between;
        text-align: left;
        margin-bottom: 10px; }
        .targets-content .form-layout .form-content .form-control .files-loader-list .files-list-item .list-item-col {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
          .targets-content .form-layout .form-content .form-control .files-loader-list .files-list-item .list-item-col.icon {
            width: 38px;
            flex-shrink: 0; }
          .targets-content .form-layout .form-content .form-control .files-loader-list .files-list-item .list-item-col.caption {
            margin-right: auto;
            margin-left: 18px; }
          .targets-content .form-layout .form-content .form-control .files-loader-list .files-list-item .list-item-col.price {
            flex-shrink: 0;
            text-align: right; }
          .targets-content .form-layout .form-content .form-control .files-loader-list .files-list-item .list-item-col .note {
            font-size: 12px;
            color: #8494a9; }
        .targets-content .form-layout .form-content .form-control .files-loader-list .files-list-item .icon {
          display: inline-block;
          width: 38px;
          height: 38px;
          line-height: 37px;
          text-align: center;
          font-size: 22px;
          font-weight: 500;
          color: #fff;
          background: #bed6f9;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%;
          font-size: 12px;
          font-weight: normal; }
          .targets-content .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.icon-sub-correction {
            line-height: 34.2px; }
          .targets-content .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-1 {
            background: #bed6f9; }
          .targets-content .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-2 {
            background: #b9e398; }
          .targets-content .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-3 {
            background: #e4e597; }
          .targets-content .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-4 {
            background: #e7c740; }
          .targets-content .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-5 {
            background: #e74040; }
          .targets-content .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-6 {
            background: #cf91af; }
        .targets-content .form-layout .form-content .form-control .files-loader-list .files-list-item .caption {
          height: 38px;
          line-height: 38px;
          margin-left: 12px; }
        .targets-content .form-layout .form-content .form-control .files-loader-list .files-list-item .remove {
          flex-shrink: 0;
          margin-left: 0; }
          .targets-content .form-layout .form-content .form-control .files-loader-list .files-list-item .remove button {
            background: none;
            border: none;
            height: 38px;
            line-height: 38px;
            font-size: 22px;
            color: #f32424; }
      .targets-content .form-layout .form-content .form-control .select-container .note {
        position: relative;
        padding-left: 25px;
        padding-top: 6px;
        font-size: 14px;
        color: #8494a9;
        line-height: 14px; }
        .targets-content .form-layout .form-content .form-control .select-container .note:before {
          content: "i";
          position: absolute;
          top: 5px;
          left: 0;
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-right: 10px;
          line-height: 14px;
          border: 1px solid #8494a9;
          border-radius: 50%;
          text-align: center; }
      .targets-content .form-layout .form-content .form-control .input-phone {
        display: flex; }
        .targets-content .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select {
          position: inherit; }
          .targets-content .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select .select-button {
            padding: 0;
            width: 80px;
            text-align: left;
            box-shadow: none;
            line-height: 34px;
            border-bottom: 1px solid #8d76f0;
            border-radius: 0; }
            .targets-content .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after {
              width: 8px; }
          .targets-content .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select .select-list {
            top: 100%;
            width: 100%;
            max-height: 200px;
            margin-top: 10px;
            overflow: auto; }
            .targets-content .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button {
              text-align: left; }
          .targets-content .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select select {
            width: 80px; }
          .targets-content .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select.show-list .select-button {
            opacity: 1;
            filter: alpha(opacity=100);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .targets-content .form-layout .form-content .form-control .input-phone .phone-number {
          flex: 1; }
      .targets-content .form-layout .form-content .form-control .password-input {
        position: relative; }
        .targets-content .form-layout .form-content .form-control .password-input .eye-icon {
          font-size: 18px;
          background-color: transparent;
          color: #8494a9;
          position: absolute;
          right: 0; }

@media screen and (max-width: 500px) {
  .targets-content .form-layout .form-content .form-control .search-select .search-field .field-dropdown-icon {
    top: 3px; } }
      .targets-content .form-layout .form-content .form-control .datepicker-popup {
        position: absolute;
        top: 100%;
        left: 0; }
    .targets-content .form-layout .form-content .form-errors-list {
      margin-bottom: 24px; }
      .targets-content .form-layout .form-content .form-errors-list .error-item {
        margin-bottom: 8px;
        color: #f32424; }
        .targets-content .form-layout .form-content .form-errors-list .error-item:last-child {
          margin-bottom: 0; }
    .targets-content .form-layout .form-content .form-messages-list {
      margin-bottom: 24px;
      text-align: center; }
      .targets-content .form-layout .form-content .form-messages-list .message-item {
        margin-bottom: 8px;
        color: #4dbd7d;
        font-size: 17px;
        line-height: 22px; }
        .targets-content .form-layout .form-content .form-messages-list .message-item:last-child {
          margin-bottom: 0; }
    .targets-content .form-layout .form-content form {
      display: inline-block;
      max-width: 350px;
      margin-right: 16px; }
    .targets-content .form-layout .form-content .header {
      font-size: 26px;
      margin-bottom: 48px; }
    .targets-content .form-layout .form-content .body {
      margin-bottom: 30px;
      font-size: 16px; }
    .targets-content .form-layout .form-content .layout-actions {
      display: inline-block; }
    .targets-content .form-layout .form-content .form-control .field-price-input-container input {
      text-align: left; }
    .targets-content .form-layout .form-content .form-control .field-price-input-container .unit-box {
      text-align: left; }
      .targets-content .form-layout .form-content .form-control .field-price-input-container .unit-box .unit-background-text {
        max-width: 100%; }
      .targets-content .form-layout .form-content .form-control .field-price-input-container .unit-box .unit-background-text-space {
        width: 8px; }
  .targets-content .form-layout .layout-actions .action-delete {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    background: none;
    border: 1px solid #f35e84;
    color: #f35e84;
    min-width: 208px; }
    .targets-content .form-layout .layout-actions .action-delete .text,
    .targets-content .form-layout .layout-actions .action-delete .icon {
      display: inline-block;
      vertical-align: top; }
    .targets-content .form-layout .layout-actions .action-delete .icon {
      margin-right: 0; }
    .targets-content .form-layout .layout-actions .action-delete.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .targets-content .form-layout .layout-actions .action-delete.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .targets-content .form-layout .layout-actions .action-delete.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .targets-content .form-layout .layout-actions .action-delete:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .targets-content .form-layout .layout-actions .action-delete {
    font-weight: normal; } }
    .targets-content .form-layout .layout-actions .action-delete .text,
    .targets-content .form-layout .layout-actions .action-delete .icon {
      display: inline-block;
      vertical-align: top; }
    .targets-content .form-layout .layout-actions .action-delete .icon {
      margin-right: 0; }
    .targets-content .form-layout .layout-actions .action-delete.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .targets-content .form-layout .layout-actions .action-delete.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .targets-content .form-layout .layout-actions .action-delete.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .targets-content .form-layout .layout-actions .action-delete:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .targets-content .form-layout .layout-actions .action-delete {
    font-weight: normal; } }
    .targets-content .form-layout .layout-actions .action-delete:hover {
      background: #f2f5fb; }

@media screen and (max-width: 590px) {
  .targets-content .form-layout .form-content {
    padding: 24px 16px; } }

@media screen and (max-width: 450px) {
  .targets-content .form-layout {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
    .targets-content .form-layout .form-content {
      padding-top: 14px; }
      .targets-content .form-layout .form-content form {
        max-width: 100%;
        margin: 0; }
      .targets-content .form-layout .form-content .layout-actions {
        display: block;
        text-align: center; }
      .targets-content .form-layout .form-content .form-field.field-type-submit {
        padding-top: 16px; } }

@media screen and (max-width: 450px) {
  .targets-content .layout-plate {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
  .targets-content .layout-title {
    margin-right: 16px;
    margin-left: 16px; }
  .targets-content .layout-actions {
    margin-bottom: 0;
    padding: 0 16px 28px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .targets-content.layout-add .layout-title,
  .targets-content.layout-add .layout-actions, .targets-content.layout-edit .layout-title,
  .targets-content.layout-edit .layout-actions {
    display: none; } }

.targets-layout .targets-content {
  flex: 1;
  width: 900px; }

.targets-table .list-item {
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  padding: 24px 28px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box; }
  .targets-table .list-item .list-item-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .targets-table .list-item .list-item-col.icon {
      width: 38px;
      flex-shrink: 0; }
    .targets-table .list-item .list-item-col.caption {
      margin-right: auto;
      margin-left: 18px; }
    .targets-table .list-item .list-item-col.price {
      flex-shrink: 0;
      text-align: right; }
    .targets-table .list-item .list-item-col .note {
      font-size: 12px;
      color: #8494a9; }
  .targets-table .list-item .list-item-col {
    flex: 1;
    margin-right: 16px; }
    .targets-table .list-item .list-item-col p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px; }
    .targets-table .list-item .list-item-col label {
      display: none; }
    .targets-table .list-item .list-item-col.caption {
      flex: 3; }
      .targets-table .list-item .list-item-col.caption p {
        font-size: 17px;
        font-weight: 500;
        color: #4b4b4b; }
    .targets-table .list-item .list-item-col.actions {
      position: relative;
      overflow: visible;
      padding-left: 10px;
      flex: 0.1;
      margin-right: 0;
      text-align: right; }
      .targets-table .list-item .list-item-col.actions .actions-button {
        position: relative;
        background: none;
        border: none;
        font-size: 17px;
        color: #8494a9; }
      .targets-table .list-item .list-item-col.actions .actions-popup {
        position: relative;
        display: none;
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        z-index: 20;
        display: none;
        position: absolute;
        top: 0;
        right: -30px;
        padding: 18px 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0; }
        .targets-table .list-item .list-item-col.actions .actions-popup.show {
          display: block; }
        .targets-table .list-item .list-item-col.actions .actions-popup .close-button {
          width: 19px;
          height: 19px;
          background: url(images/icon-close.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          position: absolute;
          top: 25px;
          right: 25px; }

@media screen and (max-width: 500px) {
  .targets-table .list-item .list-item-col.actions .actions-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .targets-table .list-item .list-item-col.actions .actions-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .targets-table .list-item .list-item-col.actions .actions-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
        .targets-table .list-item .list-item-col.actions .actions-popup .action-item {
          position: relative;
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b;
          display: block;
          min-width: 260px;
          padding: 5px 0 5px 60px;
          font-size: 16px;
          text-align: left; }
          .targets-table .list-item .list-item-col.actions .actions-popup .action-item:hover {
            color: black; }
          .targets-table .list-item .list-item-col.actions .actions-popup .action-item::after {
            content: "";
            display: block;
            position: absolute;
            top: 5px;
            left: 25px; }
          .targets-table .list-item .list-item-col.actions .actions-popup .action-item.action-edit::after {
            width: 22px;
            height: 22px;
            background: url(images/icon-edit-black.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .targets-table .list-item .list-item-col.actions .actions-popup .action-item.action-copy::after {
            width: 19px;
            height: 20px;
            background: url(images/icon-copy-link.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .targets-table .list-item .list-item-col.actions .actions-popup .action-item.action-delete::after {
            width: 19px;
            height: 19px;
            background: url(images/icon-close-black.png) no-repeat center center;
            display: inline-block;
            background-size: contain;
            top: 9px;
            left: 27px;
            width: 14px;
            height: 14px; }
      .targets-table .list-item .list-item-col.actions.opened .actions-button {
        z-index: 30; }
      .targets-table .list-item .list-item-col.actions.opened .actions-popup {
        display: block; }

@media screen and (max-width: 450px) {
  .targets-table .list-item .list-item-col.actions .actions-popup .close-button {
    width: 19px;
    height: 19px;
    background: url(images/icon-close.png) no-repeat center center;
    display: inline-block;
    background-size: contain;
    top: 23px;
    right: 23px;
    left: auto; } }
    .targets-table .list-item .list-item-col.amount {
      text-align: right; }
      .targets-table .list-item .list-item-col.amount .price {
        font-size: 0; }
        .targets-table .list-item .list-item-col.amount .price .int,
        .targets-table .list-item .list-item-col.amount .price .delim {
          font-size: 22px; }
        .targets-table .list-item .list-item-col.amount .price .float,
        .targets-table .list-item .list-item-col.amount .price .unit {
          font-size: 17px; }
        .targets-table .list-item .list-item-col.amount .price .unit {
          margin-left: 8px; }
        .targets-table .list-item .list-item-col.amount .price .unit-ruble:after {
          content: "\20bd"; }
        .targets-table .list-item .list-item-col.amount .price.income, .targets-table .list-item .list-item-col.amount .price.transfer-income {
          color: #4dbd7d; }
          .targets-table .list-item .list-item-col.amount .price.income .int,
          .targets-table .list-item .list-item-col.amount .price.income .delim,
          .targets-table .list-item .list-item-col.amount .price.income .float,
          .targets-table .list-item .list-item-col.amount .price.income .unit, .targets-table .list-item .list-item-col.amount .price.transfer-income .int,
          .targets-table .list-item .list-item-col.amount .price.transfer-income .delim,
          .targets-table .list-item .list-item-col.amount .price.transfer-income .float,
          .targets-table .list-item .list-item-col.amount .price.transfer-income .unit {
            color: #4dbd7d; }
        .targets-table .list-item .list-item-col.amount .price .unit {
          color: #8494a9; }
    .targets-table .list-item .list-item-col .field-flag {
      float: right;
      margin-left: 10px;
      font-size: 12px;
      font-weight: normal;
      color: #8494a9; }
  .targets-table .list-item.headers {
    border-bottom: 0;
    padding-bottom: 32px; }
    .targets-table .list-item.headers .list-item-col {
      color: #4b4b4b;
      font-size: 17px;
      font-weight: 500; }
  .targets-table .list-item:last-child {
    border-bottom: 0; }

.targets-list .list-item .item-amount {
  display: inline-block;
  margin-right: 25px; }

.targets-list .list-item .item-caption {
  margin-bottom: 7px; }
  .targets-list .list-item .item-caption p {
    color: #8494a9; }

.targets-list .list-item .item-date,
.targets-list .list-item .item-remain {
  display: inline-block;
  margin-right: 15px; }

.targets-list .list-item .item-mark-main {
  position: relative;
  top: -3px; }

.targets-list .list-item.expired .item-remain p {
  color: #f35e84; }

.targets-plate .target-price {
  margin-bottom: 12px;
  text-align: right; }
  .targets-plate .target-price .label {
    background: none;
    border: none;
    font-size: 17px;
    font-weight: 500;
    margin-bottom: -10px;
    text-align: left; }
  .targets-plate .target-price .value {
    margin-bottom: 6px;
    font-size: 12px; }
  .targets-plate .target-price .progress-line {
    width: 100%;
    height: 4px;
    background: rgba(0, 0, 0, 0.1);
    overflow: hidden;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    margin-bottom: 6px; }
    .targets-plate .target-price .progress-line .filling {
      height: 100%;
      background: #b9e398;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      -o-border-radius: 2px;
      border-radius: 2px; }
  .targets-plate .target-price .unit-ruble:after {
    content: "\20bd"; }
  .targets-plate .target-price .actions {
    margin-bottom: 6px; }
    .targets-plate .target-price .actions button {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b; }
      .targets-plate .target-price .actions button:hover {
        color: black; }

.targets-result-banner {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(255, 255, 255, 0.7);
  animation: popup-background-fade-in 0.8s ease; }
  .targets-result-banner .popup-container {
    position: relative;
    display: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    display: block;
    width: 550px;
    margin: 0 auto;
    padding-bottom: 58px;
    top: 10%;
    max-height: 75%;
    overflow: auto;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    /* Scrollbar styles */ }
    .targets-result-banner .popup-container.show {
      display: block; }
    .targets-result-banner .popup-container .close-button {
      width: 19px;
      height: 19px;
      background: url(images/icon-close.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: absolute;
      top: 25px;
      right: 25px; }

@media screen and (max-width: 500px) {
  .targets-result-banner .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .targets-result-banner .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .targets-result-banner .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
    .targets-result-banner .popup-container::-webkit-scrollbar {
      width: 12px;
      height: 12px; }
    .targets-result-banner .popup-container::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 10px; }
    .targets-result-banner .popup-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #ccc; }
    .targets-result-banner .popup-container .popup-content form .form-control .datepicker-popup {
      position: fixed;
      top: 50%;
      left: 50%;
      margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .targets-result-banner .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
    .targets-result-banner .popup-container .popup-content .popup-actions {
      width: 340px;
      margin: 0 auto; }
      .targets-result-banner .popup-container .popup-content .popup-actions button {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .targets-result-banner .popup-container .popup-content .popup-actions button:hover {
          color: black; }
  .targets-result-banner .header {
    padding: 32px 24px;
    font-size: 22px; }
  .targets-result-banner .body {
    padding: 0 24px 32px 24px; }
  .targets-result-banner.show {
    display: block; }

@media screen and (max-width: 600px) {
  .targets-result-banner .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .targets-result-banner .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .targets-result-banner .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
  .targets-result-banner .header {
    text-align: center;
    background: linear-gradient(73.01deg, #e9e122 8.78%, #f64399 113.32%), #956dbb;
    padding: 60px 0;
    margin-bottom: 54px; }
    .targets-result-banner .header .header-icon {
      width: 60px;
      height: 50px;
      background: url(images/icon-logo-white.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      margin-bottom: 18px; }
    .targets-result-banner .header p {
      font-size: 38px;
      color: #fff; }
  .targets-result-banner .sum-container {
    margin-bottom: 54px;
    text-align: center; }
    .targets-result-banner .sum-container span {
      vertical-align: middle; }
    .targets-result-banner .sum-container .target-amount {
      font-size: 38px; }
    .targets-result-banner .sum-container .target-amount-delim {
      margin: 0 10px;
      font-size: 22px;
      color: #8494a9; }
    .targets-result-banner .sum-container .unit-ruble {
      margin-left: 10px;
      font-size: 22px;
      color: #8494a9; }
      .targets-result-banner .sum-container .unit-ruble:after {
        content: "\20bd"; }
  .targets-result-banner .popup-buttons {
    text-align: center; }
    .targets-result-banner .popup-buttons button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      min-width: 340px;
      margin-bottom: 10px; }
      .targets-result-banner .popup-buttons button .text,
      .targets-result-banner .popup-buttons button .icon {
        display: inline-block;
        vertical-align: top; }
      .targets-result-banner .popup-buttons button .icon {
        margin-right: 0; }
      .targets-result-banner .popup-buttons button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .targets-result-banner .popup-buttons button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .targets-result-banner .popup-buttons button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .targets-result-banner .popup-buttons button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .targets-result-banner .popup-buttons button {
    font-weight: normal; } }
      .targets-result-banner .popup-buttons button.cancel {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        background: none;
        border: 1px solid #4b4b4b;
        color: #4b4b4b;
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
        .targets-result-banner .popup-buttons button.cancel .text,
        .targets-result-banner .popup-buttons button.cancel .icon {
          display: inline-block;
          vertical-align: top; }
        .targets-result-banner .popup-buttons button.cancel .icon {
          margin-right: 0; }
        .targets-result-banner .popup-buttons button.cancel.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .targets-result-banner .popup-buttons button.cancel.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .targets-result-banner .popup-buttons button.cancel.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .targets-result-banner .popup-buttons button.cancel:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .targets-result-banner .popup-buttons button.cancel {
    font-weight: normal; } }
        .targets-result-banner .popup-buttons button.cancel:hover {
          background: #f2f5fb; }

/* DEBTS */
.debts-company-item .caption {
  margin-bottom: 12px; }

.debts-company-item .name {
  color: #8494a9; }

.debts-company-item .value {
  font-size: 0; }
  .debts-company-item .value .int,
  .debts-company-item .value .delim {
    font-size: 17px; }
  .debts-company-item .value .float,
  .debts-company-item .value .unit {
    font-size: 14px; }
  .debts-company-item .value .unit {
    margin-left: 8px; }
  .debts-company-item .value .unit-ruble:after {
    content: "\20bd"; }
  .debts-company-item .value.income, .debts-company-item .value.transfer-income {
    color: #4dbd7d; }
    .debts-company-item .value.income .int,
    .debts-company-item .value.income .delim,
    .debts-company-item .value.income .float,
    .debts-company-item .value.income .unit, .debts-company-item .value.transfer-income .int,
    .debts-company-item .value.transfer-income .delim,
    .debts-company-item .value.transfer-income .float,
    .debts-company-item .value.transfer-income .unit {
      color: #4dbd7d; }

.debts-company-item .debts-list-item {
  display: flex;
  justify-content: space-between;
  text-align: left; }
  .debts-company-item .debts-list-item .list-item-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .debts-company-item .debts-list-item .list-item-col.icon {
      width: 38px;
      flex-shrink: 0; }
    .debts-company-item .debts-list-item .list-item-col.caption {
      margin-right: auto;
      margin-left: 18px; }
    .debts-company-item .debts-list-item .list-item-col.price {
      flex-shrink: 0;
      text-align: right; }
    .debts-company-item .debts-list-item .list-item-col .note {
      font-size: 12px;
      color: #8494a9; }

.debts-plate {
  display: block;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: box-shadow 0.1s ease;
  -moz-transition: box-shadow 0.1s ease;
  -ms-transition: box-shadow 0.1s ease;
  -o-transition: box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease; }

@media screen and (max-width: 450px) {
  .debts-plate {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
  .debts-plate .debts-header {
    background: none;
    border: none;
    font-size: 17px;
    font-weight: 500;
    padding: 24px; }
  .debts-plate .debts-company-item {
    padding: 0 24px 16px 24px; }
  .debts-plate .companies-delim {
    display: block;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
    margin-bottom: 18px; }

/* ADD OPERATION POPUP */
.add-operation-popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(255, 255, 255, 0.7);
  animation: popup-background-fade-in 0.8s ease; }
  .add-operation-popup .popup-container {
    position: relative;
    display: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    display: block;
    width: 550px;
    margin: 0 auto;
    padding-bottom: 58px;
    top: 10%;
    max-height: 75%;
    overflow: auto;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    /* Scrollbar styles */ }
    .add-operation-popup .popup-container.show {
      display: block; }
    .add-operation-popup .popup-container .close-button {
      width: 19px;
      height: 19px;
      background: url(images/icon-close.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: absolute;
      top: 25px;
      right: 25px; }

@media screen and (max-width: 500px) {
  .add-operation-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .add-operation-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .add-operation-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
    .add-operation-popup .popup-container::-webkit-scrollbar {
      width: 12px;
      height: 12px; }
    .add-operation-popup .popup-container::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 10px; }
    .add-operation-popup .popup-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #ccc; }
    .add-operation-popup .popup-container .popup-content form .form-control .datepicker-popup {
      position: fixed;
      top: 50%;
      left: 50%;
      margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .add-operation-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
    .add-operation-popup .popup-container .popup-content .popup-actions {
      width: 340px;
      margin: 0 auto; }
      .add-operation-popup .popup-container .popup-content .popup-actions button {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .add-operation-popup .popup-container .popup-content .popup-actions button:hover {
          color: black; }
  .add-operation-popup .header {
    padding: 32px 24px;
    font-size: 22px; }
  .add-operation-popup .body {
    padding: 0 24px 32px 24px; }
  .add-operation-popup.show {
    display: block; }

@media screen and (max-width: 600px) {
  .add-operation-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .add-operation-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .add-operation-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
  .add-operation-popup .popup-container {
    text-align: center;
    padding-bottom: 0; }
  .add-operation-popup .popup-content {
    padding: 0 64px 32px 64px; }
    .add-operation-popup .popup-content .header {
      padding: 32px 0; }
    .add-operation-popup .popup-content button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: none;
      border: 1px solid #4b4b4b;
      color: #4b4b4b;
      width: 100%;
      margin-bottom: 24px; }
      .add-operation-popup .popup-content button .text,
      .add-operation-popup .popup-content button .icon {
        display: inline-block;
        vertical-align: top; }
      .add-operation-popup .popup-content button .icon {
        margin-right: 0; }
      .add-operation-popup .popup-content button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .add-operation-popup .popup-content button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .add-operation-popup .popup-content button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .add-operation-popup .popup-content button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-operation-popup .popup-content button {
    font-weight: normal; } }
      .add-operation-popup .popup-content button:hover {
        background: #f2f5fb; }
  .add-operation-popup.show .popup-container {
    animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

.add-income-popup,
.add-outcome-popup,
.edit-operation-popup,
.upload-popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(255, 255, 255, 0.7);
  animation: popup-background-fade-in 0.8s ease; }
  .add-income-popup .popup-container,
  .add-outcome-popup .popup-container,
  .edit-operation-popup .popup-container,
  .upload-popup .popup-container {
    position: relative;
    display: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    display: block;
    width: 550px;
    margin: 0 auto;
    padding-bottom: 58px;
    top: 10%;
    max-height: 75%;
    overflow: auto;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    /* Scrollbar styles */ }
    .add-income-popup .popup-container.show,
    .add-outcome-popup .popup-container.show,
    .edit-operation-popup .popup-container.show,
    .upload-popup .popup-container.show {
      display: block; }
    .add-income-popup .popup-container .close-button,
    .add-outcome-popup .popup-container .close-button,
    .edit-operation-popup .popup-container .close-button,
    .upload-popup .popup-container .close-button {
      width: 19px;
      height: 19px;
      background: url(images/icon-close.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: absolute;
      top: 25px;
      right: 25px; }

@media screen and (max-width: 500px) {
  .add-income-popup .popup-container,
  .add-outcome-popup .popup-container,
  .edit-operation-popup .popup-container,
  .upload-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .add-income-popup .popup-container,
  .add-outcome-popup .popup-container,
  .edit-operation-popup .popup-container,
  .upload-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .add-income-popup .popup-container .close-button,
    .add-outcome-popup .popup-container .close-button,
    .edit-operation-popup .popup-container .close-button,
    .upload-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
    .add-income-popup .popup-container::-webkit-scrollbar,
    .add-outcome-popup .popup-container::-webkit-scrollbar,
    .edit-operation-popup .popup-container::-webkit-scrollbar,
    .upload-popup .popup-container::-webkit-scrollbar {
      width: 12px;
      height: 12px; }
    .add-income-popup .popup-container::-webkit-scrollbar-track,
    .add-outcome-popup .popup-container::-webkit-scrollbar-track,
    .edit-operation-popup .popup-container::-webkit-scrollbar-track,
    .upload-popup .popup-container::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 10px; }
    .add-income-popup .popup-container::-webkit-scrollbar-thumb,
    .add-outcome-popup .popup-container::-webkit-scrollbar-thumb,
    .edit-operation-popup .popup-container::-webkit-scrollbar-thumb,
    .upload-popup .popup-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #ccc; }
    .add-income-popup .popup-container .popup-content form .form-control .datepicker-popup,
    .add-outcome-popup .popup-container .popup-content form .form-control .datepicker-popup,
    .edit-operation-popup .popup-container .popup-content form .form-control .datepicker-popup,
    .upload-popup .popup-container .popup-content form .form-control .datepicker-popup {
      position: fixed;
      top: 50%;
      left: 50%;
      margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .add-income-popup .popup-container .popup-content form .form-control .datepicker-popup,
  .add-outcome-popup .popup-container .popup-content form .form-control .datepicker-popup,
  .edit-operation-popup .popup-container .popup-content form .form-control .datepicker-popup,
  .upload-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
    .add-income-popup .popup-container .popup-content .popup-actions,
    .add-outcome-popup .popup-container .popup-content .popup-actions,
    .edit-operation-popup .popup-container .popup-content .popup-actions,
    .upload-popup .popup-container .popup-content .popup-actions {
      width: 340px;
      margin: 0 auto; }
      .add-income-popup .popup-container .popup-content .popup-actions button,
      .add-outcome-popup .popup-container .popup-content .popup-actions button,
      .edit-operation-popup .popup-container .popup-content .popup-actions button,
      .upload-popup .popup-container .popup-content .popup-actions button {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .add-income-popup .popup-container .popup-content .popup-actions button:hover,
        .add-outcome-popup .popup-container .popup-content .popup-actions button:hover,
        .edit-operation-popup .popup-container .popup-content .popup-actions button:hover,
        .upload-popup .popup-container .popup-content .popup-actions button:hover {
          color: black; }
  .add-income-popup .header,
  .add-outcome-popup .header,
  .edit-operation-popup .header,
  .upload-popup .header {
    padding: 32px 24px;
    font-size: 22px; }
  .add-income-popup .body,
  .add-outcome-popup .body,
  .edit-operation-popup .body,
  .upload-popup .body {
    padding: 0 24px 32px 24px; }
  .add-income-popup.show,
  .add-outcome-popup.show,
  .edit-operation-popup.show,
  .upload-popup.show {
    display: block; }

@media screen and (max-width: 600px) {
  .add-income-popup .popup-container,
  .add-outcome-popup .popup-container,
  .edit-operation-popup .popup-container,
  .upload-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .add-income-popup .popup-container .header,
    .add-outcome-popup .popup-container .header,
    .edit-operation-popup .popup-container .header,
    .upload-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .add-income-popup .popup-container .header,
  .add-outcome-popup .popup-container .header,
  .edit-operation-popup .popup-container .header,
  .upload-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
  .add-income-popup .popup-content .header,
  .add-outcome-popup .popup-content .header,
  .edit-operation-popup .popup-content .header,
  .upload-popup .popup-content .header {
    padding: 32px 24px; }
  .add-income-popup .popup-content form,
  .add-outcome-popup .popup-content form,
  .edit-operation-popup .popup-content form,
  .upload-popup .popup-content form {
    width: 340px;
    margin: 0 auto; }
    .add-income-popup .popup-content form .form-field,
    .add-outcome-popup .popup-content form .form-field,
    .edit-operation-popup .popup-content form .form-field,
    .upload-popup .popup-content form .form-field {
      margin-bottom: 38px;
      width: 100%; }
      .add-income-popup .popup-content form .form-field .form-label,
      .add-outcome-popup .popup-content form .form-field .form-label,
      .edit-operation-popup .popup-content form .form-field .form-label,
      .upload-popup .popup-content form .form-field .form-label {
        display: block;
        font-size: 17px;
        color: #8494a9; }
      .add-income-popup .popup-content form .form-field.field-type-colorpicker .form-label,
      .add-outcome-popup .popup-content form .form-field.field-type-colorpicker .form-label,
      .edit-operation-popup .popup-content form .form-field.field-type-colorpicker .form-label,
      .upload-popup .popup-content form .form-field.field-type-colorpicker .form-label {
        margin-bottom: 8px; }
      .add-income-popup .popup-content form .form-field.field-type-files .form-label,
      .add-outcome-popup .popup-content form .form-field.field-type-files .form-label,
      .edit-operation-popup .popup-content form .form-field.field-type-files .form-label,
      .upload-popup .popup-content form .form-field.field-type-files .form-label {
        display: none; }
      .add-income-popup .popup-content form .form-field .custom-checkput,
      .add-outcome-popup .popup-content form .form-field .custom-checkput,
      .edit-operation-popup .popup-content form .form-field .custom-checkput,
      .upload-popup .popup-content form .form-field .custom-checkput {
        position: relative;
        display: inline-block;
        width: auto;
        height: auto; }
        .add-income-popup .popup-content form .form-field .custom-checkput input,
        .add-outcome-popup .popup-content form .form-field .custom-checkput input,
        .edit-operation-popup .popup-content form .form-field .custom-checkput input,
        .upload-popup .popup-content form .form-field .custom-checkput input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .add-income-popup .popup-content form .form-field .custom-checkput .checkput-icon,
        .add-outcome-popup .popup-content form .form-field .custom-checkput .checkput-icon,
        .edit-operation-popup .popup-content form .form-field .custom-checkput .checkput-icon,
        .upload-popup .popup-content form .form-field .custom-checkput .checkput-icon {
          position: relative;
          display: inline-block;
          width: 18px;
          height: 18px;
          border: 2px solid #8494a9;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          border-radius: 3px;
          cursor: pointer; }
        .add-income-popup .popup-content form .form-field .custom-checkput.checked .checkput-icon,
        .add-outcome-popup .popup-content form .form-field .custom-checkput.checked .checkput-icon,
        .edit-operation-popup .popup-content form .form-field .custom-checkput.checked .checkput-icon,
        .upload-popup .popup-content form .form-field .custom-checkput.checked .checkput-icon {
          position: relative;
          display: inline-block;
          width: 22px;
          height: 22px;
          background: #81bc52;
          border: 0;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          border-radius: 3px; }
          .add-income-popup .popup-content form .form-field .custom-checkput.checked .checkput-icon::after,
          .add-outcome-popup .popup-content form .form-field .custom-checkput.checked .checkput-icon::after,
          .edit-operation-popup .popup-content form .form-field .custom-checkput.checked .checkput-icon::after,
          .upload-popup .popup-content form .form-field .custom-checkput.checked .checkput-icon::after {
            content: "";
            width: 16px;
            height: 12px;
            background: url(images/icon-check-white.png) no-repeat center center;
            display: inline-block;
            background-size: contain;
            position: absolute;
            top: 7px;
            left: 4px;
            width: 13px;
            height: 9px; }
      .add-income-popup .popup-content form .form-field.field-type-checkbox .custom-checkput, .add-income-popup .popup-content form .form-field.field-type-agreement .custom-checkput, .add-income-popup .popup-content form .form-field.field-type-license-agreement .custom-checkput,
      .add-outcome-popup .popup-content form .form-field.field-type-checkbox .custom-checkput,
      .add-outcome-popup .popup-content form .form-field.field-type-agreement .custom-checkput,
      .add-outcome-popup .popup-content form .form-field.field-type-license-agreement .custom-checkput,
      .edit-operation-popup .popup-content form .form-field.field-type-checkbox .custom-checkput,
      .edit-operation-popup .popup-content form .form-field.field-type-agreement .custom-checkput,
      .edit-operation-popup .popup-content form .form-field.field-type-license-agreement .custom-checkput,
      .upload-popup .popup-content form .form-field.field-type-checkbox .custom-checkput,
      .upload-popup .popup-content form .form-field.field-type-agreement .custom-checkput,
      .upload-popup .popup-content form .form-field.field-type-license-agreement .custom-checkput {
        float: left; }
      .add-income-popup .popup-content form .form-field.field-type-checkbox .form-label, .add-income-popup .popup-content form .form-field.field-type-agreement .form-label, .add-income-popup .popup-content form .form-field.field-type-license-agreement .form-label,
      .add-outcome-popup .popup-content form .form-field.field-type-checkbox .form-label,
      .add-outcome-popup .popup-content form .form-field.field-type-agreement .form-label,
      .add-outcome-popup .popup-content form .form-field.field-type-license-agreement .form-label,
      .edit-operation-popup .popup-content form .form-field.field-type-checkbox .form-label,
      .edit-operation-popup .popup-content form .form-field.field-type-agreement .form-label,
      .edit-operation-popup .popup-content form .form-field.field-type-license-agreement .form-label,
      .upload-popup .popup-content form .form-field.field-type-checkbox .form-label,
      .upload-popup .popup-content form .form-field.field-type-agreement .form-label,
      .upload-popup .popup-content form .form-field.field-type-license-agreement .form-label {
        margin-left: 34px;
        cursor: pointer; }
      .add-income-popup .popup-content form .form-field.field-type-agreement button, .add-income-popup .popup-content form .form-field.field-type-license-agreement button,
      .add-outcome-popup .popup-content form .form-field.field-type-agreement button,
      .add-outcome-popup .popup-content form .form-field.field-type-license-agreement button,
      .edit-operation-popup .popup-content form .form-field.field-type-agreement button,
      .edit-operation-popup .popup-content form .form-field.field-type-license-agreement button,
      .upload-popup .popup-content form .form-field.field-type-agreement button,
      .upload-popup .popup-content form .form-field.field-type-license-agreement button {
        display: block;
        margin-left: 34px;
        height: auto;
        line-height: 22px;
        font-size: 17px;
        color: #8494a9;
        text-decoration: underline;
        background: none; }
      .add-income-popup .popup-content form .form-field .form-field-password-generator button,
      .add-outcome-popup .popup-content form .form-field .form-field-password-generator button,
      .edit-operation-popup .popup-content form .form-field .form-field-password-generator button,
      .upload-popup .popup-content form .form-field .form-field-password-generator button {
        color: #2f80ed;
        cursor: pointer;
        background: transparent; }
      .add-income-popup .popup-content form .form-field .form-field-password-check,
      .add-outcome-popup .popup-content form .form-field .form-field-password-check,
      .edit-operation-popup .popup-content form .form-field .form-field-password-check,
      .upload-popup .popup-content form .form-field .form-field-password-check {
        line-height: 16px;
        font-size: 12px;
        position: relative;
        margin-top: -2px;
        z-index: 1; }
        .add-income-popup .popup-content form .form-field .form-field-password-check.active-red,
        .add-outcome-popup .popup-content form .form-field .form-field-password-check.active-red,
        .edit-operation-popup .popup-content form .form-field .form-field-password-check.active-red,
        .upload-popup .popup-content form .form-field .form-field-password-check.active-red {
          width: 30%;
          border-top: 2px solid #F35E84;
          margin-bottom: 11px; }
        .add-income-popup .popup-content form .form-field .form-field-password-check.active-green,
        .add-outcome-popup .popup-content form .form-field .form-field-password-check.active-green,
        .edit-operation-popup .popup-content form .form-field .form-field-password-check.active-green,
        .upload-popup .popup-content form .form-field .form-field-password-check.active-green {
          width: 100%;
          border-top: 2px solid #5A9C76;
          margin-bottom: 11px; }
        .add-income-popup .popup-content form .form-field .form-field-password-check.active-yellow,
        .add-outcome-popup .popup-content form .form-field .form-field-password-check.active-yellow,
        .edit-operation-popup .popup-content form .form-field .form-field-password-check.active-yellow,
        .upload-popup .popup-content form .form-field .form-field-password-check.active-yellow {
          width: 70%;
          border-top: 2px solid #C99300;
          margin-bottom: 11px; }
        .add-income-popup .popup-content form .form-field .form-field-password-check .password-check-hint-green,
        .add-outcome-popup .popup-content form .form-field .form-field-password-check .password-check-hint-green,
        .edit-operation-popup .popup-content form .form-field .form-field-password-check .password-check-hint-green,
        .upload-popup .popup-content form .form-field .form-field-password-check .password-check-hint-green {
          color: #5A9C76; }
        .add-income-popup .popup-content form .form-field .form-field-password-check .password-check-hint-yellow,
        .add-outcome-popup .popup-content form .form-field .form-field-password-check .password-check-hint-yellow,
        .edit-operation-popup .popup-content form .form-field .form-field-password-check .password-check-hint-yellow,
        .upload-popup .popup-content form .form-field .form-field-password-check .password-check-hint-yellow {
          color: #C99300; }
        .add-income-popup .popup-content form .form-field .form-field-password-check .password-check-hint-red,
        .add-outcome-popup .popup-content form .form-field .form-field-password-check .password-check-hint-red,
        .edit-operation-popup .popup-content form .form-field .form-field-password-check .password-check-hint-red,
        .upload-popup .popup-content form .form-field .form-field-password-check .password-check-hint-red {
          color: #F35E84; }

@media screen and (max-width: 500px) {
  .add-income-popup .popup-content form .form-field,
  .add-outcome-popup .popup-content form .form-field,
  .edit-operation-popup .popup-content form .form-field,
  .upload-popup .popup-content form .form-field {
    margin-bottom: 28px; }
    .add-income-popup .popup-content form .form-field .form-label,
    .add-outcome-popup .popup-content form .form-field .form-label,
    .edit-operation-popup .popup-content form .form-field .form-label,
    .upload-popup .popup-content form .form-field .form-label {
      font-size: 14px;
      line-height: 14px; } }
      .add-income-popup .popup-content form .form-field .form-button,
      .add-outcome-popup .popup-content form .form-field .form-button,
      .edit-operation-popup .popup-content form .form-field .form-button,
      .upload-popup .popup-content form .form-field .form-button {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        width: 100%; }
        .add-income-popup .popup-content form .form-field .form-button .text,
        .add-income-popup .popup-content form .form-field .form-button .icon,
        .add-outcome-popup .popup-content form .form-field .form-button .text,
        .add-outcome-popup .popup-content form .form-field .form-button .icon,
        .edit-operation-popup .popup-content form .form-field .form-button .text,
        .edit-operation-popup .popup-content form .form-field .form-button .icon,
        .upload-popup .popup-content form .form-field .form-button .text,
        .upload-popup .popup-content form .form-field .form-button .icon {
          display: inline-block;
          vertical-align: top; }
        .add-income-popup .popup-content form .form-field .form-button .icon,
        .add-outcome-popup .popup-content form .form-field .form-button .icon,
        .edit-operation-popup .popup-content form .form-field .form-button .icon,
        .upload-popup .popup-content form .form-field .form-button .icon {
          margin-right: 0; }
        .add-income-popup .popup-content form .form-field .form-button.button-xs,
        .add-outcome-popup .popup-content form .form-field .form-button.button-xs,
        .edit-operation-popup .popup-content form .form-field .form-button.button-xs,
        .upload-popup .popup-content form .form-field .form-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .add-income-popup .popup-content form .form-field .form-button.button-s,
        .add-outcome-popup .popup-content form .form-field .form-button.button-s,
        .edit-operation-popup .popup-content form .form-field .form-button.button-s,
        .upload-popup .popup-content form .form-field .form-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .add-income-popup .popup-content form .form-field .form-button.button-l,
        .add-outcome-popup .popup-content form .form-field .form-button.button-l,
        .edit-operation-popup .popup-content form .form-field .form-button.button-l,
        .upload-popup .popup-content form .form-field .form-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .add-income-popup .popup-content form .form-field .form-button:hover,
        .add-outcome-popup .popup-content form .form-field .form-button:hover,
        .edit-operation-popup .popup-content form .form-field .form-button:hover,
        .upload-popup .popup-content form .form-field .form-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-income-popup .popup-content form .form-field .form-button,
  .add-outcome-popup .popup-content form .form-field .form-button,
  .edit-operation-popup .popup-content form .form-field .form-button,
  .upload-popup .popup-content form .form-field .form-button {
    font-weight: normal; } }
      .add-income-popup .popup-content form .form-field.field-error .form-label,
      .add-outcome-popup .popup-content form .form-field.field-error .form-label,
      .edit-operation-popup .popup-content form .form-field.field-error .form-label,
      .upload-popup .popup-content form .form-field.field-error .form-label {
        color: #f32424; }
      .add-income-popup .popup-content form .form-field.field-error .form-control .form-input,
      .add-income-popup .popup-content form .form-field.field-error .form-control textarea,
      .add-income-popup .popup-content form .form-field.field-error .form-control select,
      .add-outcome-popup .popup-content form .form-field.field-error .form-control .form-input,
      .add-outcome-popup .popup-content form .form-field.field-error .form-control textarea,
      .add-outcome-popup .popup-content form .form-field.field-error .form-control select,
      .edit-operation-popup .popup-content form .form-field.field-error .form-control .form-input,
      .edit-operation-popup .popup-content form .form-field.field-error .form-control textarea,
      .edit-operation-popup .popup-content form .form-field.field-error .form-control select,
      .upload-popup .popup-content form .form-field.field-error .form-control .form-input,
      .upload-popup .popup-content form .form-field.field-error .form-control textarea,
      .upload-popup .popup-content form .form-field.field-error .form-control select {
        border-color: #f32424; }
      .add-income-popup .popup-content form .form-field.field-error .form-field-error p,
      .add-outcome-popup .popup-content form .form-field.field-error .form-field-error p,
      .edit-operation-popup .popup-content form .form-field.field-error .form-field-error p,
      .upload-popup .popup-content form .form-field.field-error .form-field-error p {
        color: #f32424;
        white-space: pre-wrap; }
    .add-income-popup .popup-content form .form-control,
    .add-outcome-popup .popup-content form .form-control,
    .edit-operation-popup .popup-content form .form-control,
    .upload-popup .popup-content form .form-control {
      position: relative; }
      .add-income-popup .popup-content form .form-control .form-input,
      .add-income-popup .popup-content form .form-control textarea,
      .add-income-popup .popup-content form .form-control select,
      .add-outcome-popup .popup-content form .form-control .form-input,
      .add-outcome-popup .popup-content form .form-control textarea,
      .add-outcome-popup .popup-content form .form-control select,
      .edit-operation-popup .popup-content form .form-control .form-input,
      .edit-operation-popup .popup-content form .form-control textarea,
      .edit-operation-popup .popup-content form .form-control select,
      .upload-popup .popup-content form .form-control .form-input,
      .upload-popup .popup-content form .form-control textarea,
      .upload-popup .popup-content form .form-control select {
        width: 100%;
        height: 34px;
        line-height: 34px;
        padding: 0;
        background: none;
        border: 0;
        border-bottom: 1px solid #8d76f0;
        font-size: 17px;
        outline: none; }
        .add-income-popup .popup-content form .form-control .form-input::-webkit-input-placeholder,
        .add-income-popup .popup-content form .form-control textarea::-webkit-input-placeholder,
        .add-income-popup .popup-content form .form-control select::-webkit-input-placeholder,
        .add-outcome-popup .popup-content form .form-control .form-input::-webkit-input-placeholder,
        .add-outcome-popup .popup-content form .form-control textarea::-webkit-input-placeholder,
        .add-outcome-popup .popup-content form .form-control select::-webkit-input-placeholder,
        .edit-operation-popup .popup-content form .form-control .form-input::-webkit-input-placeholder,
        .edit-operation-popup .popup-content form .form-control textarea::-webkit-input-placeholder,
        .edit-operation-popup .popup-content form .form-control select::-webkit-input-placeholder,
        .upload-popup .popup-content form .form-control .form-input::-webkit-input-placeholder,
        .upload-popup .popup-content form .form-control textarea::-webkit-input-placeholder,
        .upload-popup .popup-content form .form-control select::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: rgba(132, 148, 169, 0.5); }
        .add-income-popup .popup-content form .form-control .form-input:-moz-placeholder,
        .add-income-popup .popup-content form .form-control textarea:-moz-placeholder,
        .add-income-popup .popup-content form .form-control select:-moz-placeholder,
        .add-outcome-popup .popup-content form .form-control .form-input:-moz-placeholder,
        .add-outcome-popup .popup-content form .form-control textarea:-moz-placeholder,
        .add-outcome-popup .popup-content form .form-control select:-moz-placeholder,
        .edit-operation-popup .popup-content form .form-control .form-input:-moz-placeholder,
        .edit-operation-popup .popup-content form .form-control textarea:-moz-placeholder,
        .edit-operation-popup .popup-content form .form-control select:-moz-placeholder,
        .upload-popup .popup-content form .form-control .form-input:-moz-placeholder,
        .upload-popup .popup-content form .form-control textarea:-moz-placeholder,
        .upload-popup .popup-content form .form-control select:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .add-income-popup .popup-content form .form-control .form-input::-moz-placeholder,
        .add-income-popup .popup-content form .form-control textarea::-moz-placeholder,
        .add-income-popup .popup-content form .form-control select::-moz-placeholder,
        .add-outcome-popup .popup-content form .form-control .form-input::-moz-placeholder,
        .add-outcome-popup .popup-content form .form-control textarea::-moz-placeholder,
        .add-outcome-popup .popup-content form .form-control select::-moz-placeholder,
        .edit-operation-popup .popup-content form .form-control .form-input::-moz-placeholder,
        .edit-operation-popup .popup-content form .form-control textarea::-moz-placeholder,
        .edit-operation-popup .popup-content form .form-control select::-moz-placeholder,
        .upload-popup .popup-content form .form-control .form-input::-moz-placeholder,
        .upload-popup .popup-content form .form-control textarea::-moz-placeholder,
        .upload-popup .popup-content form .form-control select::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .add-income-popup .popup-content form .form-control .form-input:-ms-input-placeholder,
        .add-income-popup .popup-content form .form-control textarea:-ms-input-placeholder,
        .add-income-popup .popup-content form .form-control select:-ms-input-placeholder,
        .add-outcome-popup .popup-content form .form-control .form-input:-ms-input-placeholder,
        .add-outcome-popup .popup-content form .form-control textarea:-ms-input-placeholder,
        .add-outcome-popup .popup-content form .form-control select:-ms-input-placeholder,
        .edit-operation-popup .popup-content form .form-control .form-input:-ms-input-placeholder,
        .edit-operation-popup .popup-content form .form-control textarea:-ms-input-placeholder,
        .edit-operation-popup .popup-content form .form-control select:-ms-input-placeholder,
        .upload-popup .popup-content form .form-control .form-input:-ms-input-placeholder,
        .upload-popup .popup-content form .form-control textarea:-ms-input-placeholder,
        .upload-popup .popup-content form .form-control select:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba(132, 148, 169, 0.5); }
        .add-income-popup .popup-content form .form-control .form-input::-ms-input-placeholder,
        .add-income-popup .popup-content form .form-control textarea::-ms-input-placeholder,
        .add-income-popup .popup-content form .form-control select::-ms-input-placeholder,
        .add-outcome-popup .popup-content form .form-control .form-input::-ms-input-placeholder,
        .add-outcome-popup .popup-content form .form-control textarea::-ms-input-placeholder,
        .add-outcome-popup .popup-content form .form-control select::-ms-input-placeholder,
        .edit-operation-popup .popup-content form .form-control .form-input::-ms-input-placeholder,
        .edit-operation-popup .popup-content form .form-control textarea::-ms-input-placeholder,
        .edit-operation-popup .popup-content form .form-control select::-ms-input-placeholder,
        .upload-popup .popup-content form .form-control .form-input::-ms-input-placeholder,
        .upload-popup .popup-content form .form-control textarea::-ms-input-placeholder,
        .upload-popup .popup-content form .form-control select::-ms-input-placeholder {
          /* Microsoft Edge */
          color: rgba(132, 148, 169, 0.5); }
        .add-income-popup .popup-content form .form-control .form-input::placeholder,
        .add-income-popup .popup-content form .form-control textarea::placeholder,
        .add-income-popup .popup-content form .form-control select::placeholder,
        .add-outcome-popup .popup-content form .form-control .form-input::placeholder,
        .add-outcome-popup .popup-content form .form-control textarea::placeholder,
        .add-outcome-popup .popup-content form .form-control select::placeholder,
        .edit-operation-popup .popup-content form .form-control .form-input::placeholder,
        .edit-operation-popup .popup-content form .form-control textarea::placeholder,
        .edit-operation-popup .popup-content form .form-control select::placeholder,
        .upload-popup .popup-content form .form-control .form-input::placeholder,
        .upload-popup .popup-content form .form-control textarea::placeholder,
        .upload-popup .popup-content form .form-control select::placeholder {
          /* Most modern browsers support this now. */
          color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .add-income-popup .popup-content form .form-control .form-input,
  .add-income-popup .popup-content form .form-control textarea,
  .add-income-popup .popup-content form .form-control select,
  .add-outcome-popup .popup-content form .form-control .form-input,
  .add-outcome-popup .popup-content form .form-control textarea,
  .add-outcome-popup .popup-content form .form-control select,
  .edit-operation-popup .popup-content form .form-control .form-input,
  .edit-operation-popup .popup-content form .form-control textarea,
  .edit-operation-popup .popup-content form .form-control select,
  .upload-popup .popup-content form .form-control .form-input,
  .upload-popup .popup-content form .form-control textarea,
  .upload-popup .popup-content form .form-control select {
    font-size: 16px; } }
      .add-income-popup .popup-content form .form-control textarea,
      .add-outcome-popup .popup-content form .form-control textarea,
      .edit-operation-popup .popup-content form .form-control textarea,
      .upload-popup .popup-content form .form-control textarea {
        resize: none;
        padding: 5px 0;
        line-height: 22px; }
      .add-income-popup .popup-content form .form-control select,
      .add-outcome-popup .popup-content form .form-control select,
      .edit-operation-popup .popup-content form .form-control select,
      .upload-popup .popup-content form .form-control select {
        cursor: pointer; }
      .add-income-popup .popup-content form .form-control .styled-select,
      .add-outcome-popup .popup-content form .form-control .styled-select,
      .edit-operation-popup .popup-content form .form-control .styled-select,
      .upload-popup .popup-content form .form-control .styled-select {
        position: relative; }
        .add-income-popup .popup-content form .form-control .styled-select select,
        .add-outcome-popup .popup-content form .form-control .styled-select select,
        .edit-operation-popup .popup-content form .form-control .styled-select select,
        .upload-popup .popup-content form .form-control .styled-select select {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .add-income-popup .popup-content form .form-control .styled-select .select-button,
        .add-outcome-popup .popup-content form .form-control .styled-select .select-button,
        .edit-operation-popup .popup-content form .form-control .styled-select .select-button,
        .upload-popup .popup-content form .form-control .styled-select .select-button {
          position: relative;
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          width: 100%;
          height: auto;
          padding: 15px 30px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          border-radius: 8px;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
          .add-income-popup .popup-content form .form-control .styled-select .select-button .text,
          .add-income-popup .popup-content form .form-control .styled-select .select-button .icon,
          .add-outcome-popup .popup-content form .form-control .styled-select .select-button .text,
          .add-outcome-popup .popup-content form .form-control .styled-select .select-button .icon,
          .edit-operation-popup .popup-content form .form-control .styled-select .select-button .text,
          .edit-operation-popup .popup-content form .form-control .styled-select .select-button .icon,
          .upload-popup .popup-content form .form-control .styled-select .select-button .text,
          .upload-popup .popup-content form .form-control .styled-select .select-button .icon {
            display: inline-block;
            vertical-align: top; }
          .add-income-popup .popup-content form .form-control .styled-select .select-button .icon,
          .add-outcome-popup .popup-content form .form-control .styled-select .select-button .icon,
          .edit-operation-popup .popup-content form .form-control .styled-select .select-button .icon,
          .upload-popup .popup-content form .form-control .styled-select .select-button .icon {
            margin-right: 0; }
          .add-income-popup .popup-content form .form-control .styled-select .select-button.button-xs,
          .add-outcome-popup .popup-content form .form-control .styled-select .select-button.button-xs,
          .edit-operation-popup .popup-content form .form-control .styled-select .select-button.button-xs,
          .upload-popup .popup-content form .form-control .styled-select .select-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .add-income-popup .popup-content form .form-control .styled-select .select-button.button-s,
          .add-outcome-popup .popup-content form .form-control .styled-select .select-button.button-s,
          .edit-operation-popup .popup-content form .form-control .styled-select .select-button.button-s,
          .upload-popup .popup-content form .form-control .styled-select .select-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .add-income-popup .popup-content form .form-control .styled-select .select-button.button-l,
          .add-outcome-popup .popup-content form .form-control .styled-select .select-button.button-l,
          .edit-operation-popup .popup-content form .form-control .styled-select .select-button.button-l,
          .upload-popup .popup-content form .form-control .styled-select .select-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .add-income-popup .popup-content form .form-control .styled-select .select-button:hover,
          .add-outcome-popup .popup-content form .form-control .styled-select .select-button:hover,
          .edit-operation-popup .popup-content form .form-control .styled-select .select-button:hover,
          .upload-popup .popup-content form .form-control .styled-select .select-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-income-popup .popup-content form .form-control .styled-select .select-button,
  .add-outcome-popup .popup-content form .form-control .styled-select .select-button,
  .edit-operation-popup .popup-content form .form-control .styled-select .select-button,
  .upload-popup .popup-content form .form-control .styled-select .select-button {
    font-weight: normal; } }
          .add-income-popup .popup-content form .form-control .styled-select .select-button .post-icon,
          .add-outcome-popup .popup-content form .form-control .styled-select .select-button .post-icon,
          .edit-operation-popup .popup-content form .form-control .styled-select .select-button .post-icon,
          .upload-popup .popup-content form .form-control .styled-select .select-button .post-icon {
            position: relative;
            top: -1px;
            left: 8px;
            display: inline-block;
            width: 0; }
            .add-income-popup .popup-content form .form-control .styled-select .select-button .post-icon:after,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-button .post-icon:after,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-button .post-icon:after,
            .upload-popup .popup-content form .form-control .styled-select .select-button .post-icon:after {
              content: "";
              width: 14px;
              height: 7px;
              background: url(images/arrow-bottom.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
          .add-income-popup .popup-content form .form-control .styled-select .select-button:hover,
          .add-outcome-popup .popup-content form .form-control .styled-select .select-button:hover,
          .edit-operation-popup .popup-content form .form-control .styled-select .select-button:hover,
          .upload-popup .popup-content form .form-control .styled-select .select-button:hover {
            background: none;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
        .add-income-popup .popup-content form .form-control .styled-select .select-list,
        .add-outcome-popup .popup-content form .form-control .styled-select .select-list,
        .edit-operation-popup .popup-content form .form-control .styled-select .select-list,
        .upload-popup .popup-content form .form-control .styled-select .select-list {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 1;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .add-income-popup .popup-content form .form-control .styled-select .select-list,
  .add-outcome-popup .popup-content form .form-control .styled-select .select-list,
  .edit-operation-popup .popup-content form .form-control .styled-select .select-list,
  .upload-popup .popup-content form .form-control .styled-select .select-list {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
          .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-button,
          .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-button,
          .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-button,
          .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 15px 50px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-button .text,
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-button .icon,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-button .text,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-button .icon,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-button .text,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-button .icon,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-button .text,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-button .icon {
              display: inline-block;
              vertical-align: top; }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-button .icon,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-button .icon,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-button .icon,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-button .icon {
              margin-right: 0; }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-xs,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-xs,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-xs,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-s,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-s,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-s,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-l,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-l,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-l,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-button:hover,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-button:hover,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-button:hover,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-button,
  .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-button,
  .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-button,
  .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-button {
    font-weight: normal; } }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-button.active .pre-icon,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-button.active .pre-icon,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-button.active .pre-icon,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-button.active .pre-icon {
              position: relative;
              left: -26px;
              display: inline-block;
              width: 0; }
              .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-button.active .pre-icon:after,
              .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-button.active .pre-icon:after,
              .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-button.active .pre-icon:after,
              .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-button.active .pre-icon:after {
                content: "";
                width: 16px;
                height: 12px;
                background: url(images/icon-check.png) no-repeat center center;
                display: inline-block;
                background-size: contain; }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-button:after,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-button:after,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-button:after,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-button:after {
              content: "";
              display: block;
              position: absolute;
              top: 100%;
              left: 0;
              right: 0;
              height: 1px;
              background: rgba(0, 0, 0, 0.1);
              opacity: 0.5;
              filter: alpha(opacity=50);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-button:hover,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-button:hover,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-button:hover,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-button:hover {
              background: #f2f5fb; }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-button:first-child:after,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-button:first-child:after,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-button:first-child:after,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-button:first-child:after {
              left: 23px;
              right: 23px;
              opacity: 1;
              filter: alpha(opacity=100);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-button:last-child,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-button:last-child,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-button:last-child,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-button:last-child {
              margin-bottom: 40px; }
          .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-action,
          .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-action,
          .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-action,
          .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-action {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 15px 23px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-action .text,
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-action .icon,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-action .text,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-action .icon,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-action .text,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-action .icon,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-action .text,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-action .icon {
              display: inline-block;
              vertical-align: top; }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-action .icon,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-action .icon,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-action .icon,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-action .icon {
              margin-right: 0; }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-xs,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-xs,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-xs,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-s,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-s,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-s,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-l,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-l,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-l,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-action:hover,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-action:hover,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-action:hover,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-action:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-action,
  .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-action,
  .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-action,
  .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-action {
    font-weight: normal; } }
            .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-action:hover,
            .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-action:hover,
            .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-action:hover,
            .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-action:hover {
              background: #f2f5fb; }
          .add-income-popup .popup-content form .form-control .styled-select .select-list.show,
          .add-outcome-popup .popup-content form .form-control .styled-select .select-list.show,
          .edit-operation-popup .popup-content form .form-control .styled-select .select-list.show,
          .upload-popup .popup-content form .form-control .styled-select .select-list.show {
            display: block; }
        .add-income-popup .popup-content form .form-control .styled-select.show-list .select-button,
        .add-outcome-popup .popup-content form .form-control .styled-select.show-list .select-button,
        .edit-operation-popup .popup-content form .form-control .styled-select.show-list .select-button,
        .upload-popup .popup-content form .form-control .styled-select.show-list .select-button {
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .add-income-popup .popup-content form .form-control .search-select,
      .add-outcome-popup .popup-content form .form-control .search-select,
      .edit-operation-popup .popup-content form .form-control .search-select,
      .upload-popup .popup-content form .form-control .search-select {
        position: relative; }
        .add-income-popup .popup-content form .form-control .search-select select,
        .add-outcome-popup .popup-content form .form-control .search-select select,
        .edit-operation-popup .popup-content form .form-control .search-select select,
        .upload-popup .popup-content form .form-control .search-select select {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .add-income-popup .popup-content form .form-control .search-select .search-field,
        .add-outcome-popup .popup-content form .form-control .search-select .search-field,
        .edit-operation-popup .popup-content form .form-control .search-select .search-field,
        .upload-popup .popup-content form .form-control .search-select .search-field {
          position: relative;
          border-bottom: 1px solid #8d76f0; }
          .add-income-popup .popup-content form .form-control .search-select .search-field input,
          .add-outcome-popup .popup-content form .form-control .search-select .search-field input,
          .edit-operation-popup .popup-content form .form-control .search-select .search-field input,
          .upload-popup .popup-content form .form-control .search-select .search-field input {
            width: 100%;
            height: 34px;
            line-height: 34px;
            padding: 0;
            background: none;
            border: 0;
            border-bottom: 1px solid #8d76f0;
            font-size: 17px;
            outline: none;
            width: 90%;
            border-bottom: 0; }
            .add-income-popup .popup-content form .form-control .search-select .search-field input::-webkit-input-placeholder,
            .add-outcome-popup .popup-content form .form-control .search-select .search-field input::-webkit-input-placeholder,
            .edit-operation-popup .popup-content form .form-control .search-select .search-field input::-webkit-input-placeholder,
            .upload-popup .popup-content form .form-control .search-select .search-field input::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: rgba(132, 148, 169, 0.5); }
            .add-income-popup .popup-content form .form-control .search-select .search-field input:-moz-placeholder,
            .add-outcome-popup .popup-content form .form-control .search-select .search-field input:-moz-placeholder,
            .edit-operation-popup .popup-content form .form-control .search-select .search-field input:-moz-placeholder,
            .upload-popup .popup-content form .form-control .search-select .search-field input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .add-income-popup .popup-content form .form-control .search-select .search-field input::-moz-placeholder,
            .add-outcome-popup .popup-content form .form-control .search-select .search-field input::-moz-placeholder,
            .edit-operation-popup .popup-content form .form-control .search-select .search-field input::-moz-placeholder,
            .upload-popup .popup-content form .form-control .search-select .search-field input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .add-income-popup .popup-content form .form-control .search-select .search-field input:-ms-input-placeholder,
            .add-outcome-popup .popup-content form .form-control .search-select .search-field input:-ms-input-placeholder,
            .edit-operation-popup .popup-content form .form-control .search-select .search-field input:-ms-input-placeholder,
            .upload-popup .popup-content form .form-control .search-select .search-field input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: rgba(132, 148, 169, 0.5); }
            .add-income-popup .popup-content form .form-control .search-select .search-field input::-ms-input-placeholder,
            .add-outcome-popup .popup-content form .form-control .search-select .search-field input::-ms-input-placeholder,
            .edit-operation-popup .popup-content form .form-control .search-select .search-field input::-ms-input-placeholder,
            .upload-popup .popup-content form .form-control .search-select .search-field input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: rgba(132, 148, 169, 0.5); }
            .add-income-popup .popup-content form .form-control .search-select .search-field input::placeholder,
            .add-outcome-popup .popup-content form .form-control .search-select .search-field input::placeholder,
            .edit-operation-popup .popup-content form .form-control .search-select .search-field input::placeholder,
            .upload-popup .popup-content form .form-control .search-select .search-field input::placeholder {
              /* Most modern browsers support this now. */
              color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .add-income-popup .popup-content form .form-control .search-select .search-field input,
  .add-outcome-popup .popup-content form .form-control .search-select .search-field input,
  .edit-operation-popup .popup-content form .form-control .search-select .search-field input,
  .upload-popup .popup-content form .form-control .search-select .search-field input {
    font-size: 16px; } }
            .add-income-popup .popup-content form .form-control .search-select .search-field input::-webkit-input-placeholder,
            .add-outcome-popup .popup-content form .form-control .search-select .search-field input::-webkit-input-placeholder,
            .edit-operation-popup .popup-content form .form-control .search-select .search-field input::-webkit-input-placeholder,
            .upload-popup .popup-content form .form-control .search-select .search-field input::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: #4b4b4b; }
            .add-income-popup .popup-content form .form-control .search-select .search-field input:-moz-placeholder,
            .add-outcome-popup .popup-content form .form-control .search-select .search-field input:-moz-placeholder,
            .edit-operation-popup .popup-content form .form-control .search-select .search-field input:-moz-placeholder,
            .upload-popup .popup-content form .form-control .search-select .search-field input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: #4b4b4b;
              opacity: 1; }
            .add-income-popup .popup-content form .form-control .search-select .search-field input::-moz-placeholder,
            .add-outcome-popup .popup-content form .form-control .search-select .search-field input::-moz-placeholder,
            .edit-operation-popup .popup-content form .form-control .search-select .search-field input::-moz-placeholder,
            .upload-popup .popup-content form .form-control .search-select .search-field input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: #4b4b4b;
              opacity: 1; }
            .add-income-popup .popup-content form .form-control .search-select .search-field input:-ms-input-placeholder,
            .add-outcome-popup .popup-content form .form-control .search-select .search-field input:-ms-input-placeholder,
            .edit-operation-popup .popup-content form .form-control .search-select .search-field input:-ms-input-placeholder,
            .upload-popup .popup-content form .form-control .search-select .search-field input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #4b4b4b; }
            .add-income-popup .popup-content form .form-control .search-select .search-field input::-ms-input-placeholder,
            .add-outcome-popup .popup-content form .form-control .search-select .search-field input::-ms-input-placeholder,
            .edit-operation-popup .popup-content form .form-control .search-select .search-field input::-ms-input-placeholder,
            .upload-popup .popup-content form .form-control .search-select .search-field input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: #4b4b4b; }
            .add-income-popup .popup-content form .form-control .search-select .search-field input::placeholder,
            .add-outcome-popup .popup-content form .form-control .search-select .search-field input::placeholder,
            .edit-operation-popup .popup-content form .form-control .search-select .search-field input::placeholder,
            .upload-popup .popup-content form .form-control .search-select .search-field input::placeholder {
              /* Most modern browsers support this now. */
              color: #4b4b4b; }
          .add-income-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon,
          .add-outcome-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon,
          .edit-operation-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon,
          .upload-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon {
            position: absolute;
            top: 7px;
            right: 21px;
            width: 0; }
            .add-income-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon:after,
            .add-outcome-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon:after,
            .edit-operation-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon:after,
            .upload-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon:after {
              content: "";
              width: 14px;
              height: 7px;
              background: url(images/arrow-bottom.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
        .add-income-popup .popup-content form .form-control .search-select .select-list-container,
        .add-outcome-popup .popup-content form .form-control .search-select .select-list-container,
        .edit-operation-popup .popup-content form .form-control .search-select .select-list-container,
        .upload-popup .popup-content form .form-control .search-select .select-list-container {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          display: none;
          position: absolute;
          top: -17px;
          left: -17px;
          right: -17px;
          padding-top: 60px;
          padding-bottom: 17px;
          z-index: 1;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .add-income-popup .popup-content form .form-control .search-select .select-list-container,
  .add-outcome-popup .popup-content form .form-control .search-select .select-list-container,
  .edit-operation-popup .popup-content form .form-control .search-select .select-list-container,
  .upload-popup .popup-content form .form-control .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
        .add-income-popup .popup-content form .form-control .search-select .select-list,
        .add-outcome-popup .popup-content form .form-control .search-select .select-list,
        .edit-operation-popup .popup-content form .form-control .search-select .select-list,
        .upload-popup .popup-content form .form-control .search-select .select-list {
          position: relative;
          max-height: 200px;
          overflow: auto; }
          .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-button,
          .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-button,
          .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-button,
          .upload-popup .popup-content form .form-control .search-select .select-list .select-list-button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 10px 17px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-button .text,
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-button .icon,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-button .text,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-button .icon,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-button .text,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-button .icon,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-button .text,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-button .icon {
              display: inline-block;
              vertical-align: top; }
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-button .icon,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-button .icon,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-button .icon,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-button .icon {
              margin-right: 0; }
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-xs,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-xs,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-xs,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-s,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-s,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-s,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-l,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-l,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-l,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-button:hover,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-button:hover,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-button:hover,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-button,
  .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-button,
  .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-button,
  .upload-popup .popup-content form .form-control .search-select .select-list .select-list-button {
    font-weight: normal; } }
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-button.active,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-button.active,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-button.active,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-button.active {
              background: #f2f5fb; }
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-button.selected,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-button.selected,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-button.selected,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-button.selected {
              background: #f2f5fb; }
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-button:hover,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-button:hover,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-button:hover,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-button:hover {
              background: #f2f5fb; }
          .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-action,
          .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-action,
          .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-action,
          .upload-popup .popup-content form .form-control .search-select .select-list .select-list-action {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 10px 17px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-action .text,
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-action .icon,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-action .text,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-action .icon,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-action .text,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-action .icon,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-action .text,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-action .icon {
              display: inline-block;
              vertical-align: top; }
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-action .icon,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-action .icon,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-action .icon,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-action .icon {
              margin-right: 0; }
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-xs,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-xs,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-xs,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-s,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-s,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-s,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-l,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-l,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-l,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-action:hover,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-action:hover,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-action:hover,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-action:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-action,
  .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-action,
  .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-action,
  .upload-popup .popup-content form .form-control .search-select .select-list .select-list-action {
    font-weight: normal; } }
            .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-action:hover,
            .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-action:hover,
            .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-action:hover,
            .upload-popup .popup-content form .form-control .search-select .select-list .select-list-action:hover {
              background: #f2f5fb; }
        .add-income-popup .popup-content form .form-control .search-select.show-list .select-list-container,
        .add-outcome-popup .popup-content form .form-control .search-select.show-list .select-list-container,
        .edit-operation-popup .popup-content form .form-control .search-select.show-list .select-list-container,
        .upload-popup .popup-content form .form-control .search-select.show-list .select-list-container {
          display: block; }
        .add-income-popup .popup-content form .form-control .search-select.show-list .search-field,
        .add-outcome-popup .popup-content form .form-control .search-select.show-list .search-field,
        .edit-operation-popup .popup-content form .form-control .search-select.show-list .search-field,
        .upload-popup .popup-content form .form-control .search-select.show-list .search-field {
          z-index: 1;
          border-bottom-color: rgba(0, 0, 0, 0.1); }
      .add-income-popup .popup-content form .form-control .field-price-input-container,
      .add-outcome-popup .popup-content form .form-control .field-price-input-container,
      .edit-operation-popup .popup-content form .form-control .field-price-input-container,
      .upload-popup .popup-content form .form-control .field-price-input-container {
        position: relative;
        width: 100%; }
        .add-income-popup .popup-content form .form-control .field-price-input-container .unit-box,
        .add-outcome-popup .popup-content form .form-control .field-price-input-container .unit-box,
        .edit-operation-popup .popup-content form .form-control .field-price-input-container .unit-box,
        .upload-popup .popup-content form .form-control .field-price-input-container .unit-box {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          text-align: center;
          white-space: nowrap; }
          .add-income-popup .popup-content form .form-control .field-price-input-container .unit-box span,
          .add-outcome-popup .popup-content form .form-control .field-price-input-container .unit-box span,
          .edit-operation-popup .popup-content form .form-control .field-price-input-container .unit-box span,
          .upload-popup .popup-content form .form-control .field-price-input-container .unit-box span {
            display: inline-block; }
          .add-income-popup .popup-content form .form-control .field-price-input-container .unit-box .unit-background-text,
          .add-outcome-popup .popup-content form .form-control .field-price-input-container .unit-box .unit-background-text,
          .edit-operation-popup .popup-content form .form-control .field-price-input-container .unit-box .unit-background-text,
          .upload-popup .popup-content form .form-control .field-price-input-container .unit-box .unit-background-text {
            color: transparent;
            font-size: 38px;
            max-width: 95%;
            overflow: hidden; }
          .add-income-popup .popup-content form .form-control .field-price-input-container .unit-box .unit-background-text-space,
          .add-outcome-popup .popup-content form .form-control .field-price-input-container .unit-box .unit-background-text-space,
          .edit-operation-popup .popup-content form .form-control .field-price-input-container .unit-box .unit-background-text-space,
          .upload-popup .popup-content form .form-control .field-price-input-container .unit-box .unit-background-text-space {
            width: 24px; }
        .add-income-popup .popup-content form .form-control .field-price-input-container input,
        .add-outcome-popup .popup-content form .form-control .field-price-input-container input,
        .edit-operation-popup .popup-content form .form-control .field-price-input-container input,
        .upload-popup .popup-content form .form-control .field-price-input-container input {
          position: relative;
          height: 50px;
          line-height: 50px;
          box-sizing: border-box;
          text-align: center;
          font-size: 38px; }
        .add-income-popup .popup-content form .form-control .field-price-input-container .unit-ruble,
        .add-outcome-popup .popup-content form .form-control .field-price-input-container .unit-ruble,
        .edit-operation-popup .popup-content form .form-control .field-price-input-container .unit-ruble,
        .upload-popup .popup-content form .form-control .field-price-input-container .unit-ruble {
          line-height: 52px;
          font-size: 32px;
          color: #8494a9;
          opacity: 0.7;
          filter: alpha(opacity=70);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=70;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .add-income-popup .popup-content form .form-control .field-price-input-container .unit-ruble:after,
          .add-outcome-popup .popup-content form .form-control .field-price-input-container .unit-ruble:after,
          .edit-operation-popup .popup-content form .form-control .field-price-input-container .unit-ruble:after,
          .upload-popup .popup-content form .form-control .field-price-input-container .unit-ruble:after {
            content: "\20bd"; }
      .add-income-popup .popup-content form .form-control .colorpicker,
      .add-outcome-popup .popup-content form .form-control .colorpicker,
      .edit-operation-popup .popup-content form .form-control .colorpicker,
      .upload-popup .popup-content form .form-control .colorpicker {
        position: relative; }
        .add-income-popup .popup-content form .form-control .colorpicker .picker-button,
        .add-outcome-popup .popup-content form .form-control .colorpicker .picker-button,
        .edit-operation-popup .popup-content form .form-control .colorpicker .picker-button,
        .upload-popup .popup-content form .form-control .colorpicker .picker-button {
          display: block;
          width: 105px;
          height: 30px;
          background: none;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .add-income-popup .popup-content form .form-control .colorpicker .picker-palette,
        .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette,
        .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette,
        .upload-popup .popup-content form .form-control .colorpicker .picker-palette {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          position: absolute;
          left: 0;
          bottom: -200px;
          width: 504px;
          padding: 32px 20px;
          z-index: 4;
          -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 450px) {
  .add-income-popup .popup-content form .form-control .colorpicker .picker-palette,
  .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette,
  .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette,
  .upload-popup .popup-content form .form-control .colorpicker .picker-palette {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
          .add-income-popup .popup-content form .form-control .colorpicker .picker-palette .palette-header,
          .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette .palette-header,
          .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette .palette-header,
          .upload-popup .popup-content form .form-control .colorpicker .picker-palette .palette-header {
            margin-bottom: 40px;
            margin-left: 10px;
            font-size: 22px; }
          .add-income-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-buttons,
          .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-buttons,
          .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-buttons,
          .upload-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-buttons {
            margin-bottom: 12px; }
          .add-income-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button,
          .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button,
          .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button,
          .upload-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin: 0 12px 24px 12px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            border-radius: 50%; }
            .add-income-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button.active, .add-income-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button:hover,
            .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button.active,
            .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button:hover,
            .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button.active,
            .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button:hover,
            .upload-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button.active,
            .upload-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button:hover {
              margin: -10px 2px 14px 2px;
              width: 80px;
              height: 80px;
              border: 10px solid #f2f5fb; }
          .add-income-popup .popup-content form .form-control .colorpicker .picker-palette .palette-corner-close-button,
          .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette .palette-corner-close-button,
          .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette .palette-corner-close-button,
          .upload-popup .popup-content form .form-control .colorpicker .picker-palette .palette-corner-close-button {
            position: absolute;
            top: 0;
            right: 0;
            width: 80px;
            height: 80px;
            vertical-align: middle;
            text-align: center;
            font-size: 38px;
            font-family: "times new roman", verdana;
            background: none; }
          .add-income-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons,
          .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons,
          .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons,
          .upload-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons {
            text-align: right; }
            .add-income-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button,
            .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button,
            .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button,
            .upload-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              background: none;
              border: 1px solid #4b4b4b;
              color: #4b4b4b;
              font-size: 16px;
              min-width: 191px; }
              .add-income-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
              .add-income-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon,
              .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
              .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon,
              .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
              .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon,
              .upload-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
              .upload-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                display: inline-block;
                vertical-align: top; }
              .add-income-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon,
              .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon,
              .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon,
              .upload-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                margin-right: 0; }
              .add-income-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs,
              .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs,
              .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs,
              .upload-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .add-income-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s,
              .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s,
              .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s,
              .upload-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .add-income-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l,
              .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l,
              .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l,
              .upload-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .add-income-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover,
              .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover,
              .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover,
              .upload-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-income-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button,
  .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button,
  .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button,
  .upload-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
    font-weight: normal; } }
              .add-income-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover,
              .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover,
              .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover,
              .upload-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                background: #f2f5fb; }
        .add-income-popup .popup-content form .form-control .colorpicker .picker-palette-overlay,
        .add-outcome-popup .popup-content form .form-control .colorpicker .picker-palette-overlay,
        .edit-operation-popup .popup-content form .form-control .colorpicker .picker-palette-overlay,
        .upload-popup .popup-content form .form-control .colorpicker .picker-palette-overlay {
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.7);
          z-index: 2; }
      .add-income-popup .popup-content form .form-control .form-autocomplete-input,
      .add-outcome-popup .popup-content form .form-control .form-autocomplete-input,
      .edit-operation-popup .popup-content form .form-control .form-autocomplete-input,
      .upload-popup .popup-content form .form-control .form-autocomplete-input {
        position: relative; }
        .add-income-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown,
        .add-outcome-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown,
        .edit-operation-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown,
        .upload-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          max-height: 300px;
          background: #fff;
          -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          z-index: 1;
          overflow: auto; }
          .add-income-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button,
          .add-outcome-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button,
          .edit-operation-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button,
          .upload-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button {
            background: none;
            border: none;
            width: 100%;
            padding: 8px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            text-align: left; }
            .add-income-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover,
            .add-outcome-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover,
            .edit-operation-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover,
            .upload-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover {
              background: #f2f5fb; }
            .add-income-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child,
            .add-outcome-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child,
            .edit-operation-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child,
            .upload-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child {
              border-bottom: 0; }
      .add-income-popup .popup-content form .form-control .field-text-list-container .text-list-item,
      .add-outcome-popup .popup-content form .form-control .field-text-list-container .text-list-item,
      .edit-operation-popup .popup-content form .form-control .field-text-list-container .text-list-item,
      .upload-popup .popup-content form .form-control .field-text-list-container .text-list-item {
        position: relative;
        margin-bottom: 10px; }
        .add-income-popup .popup-content form .form-control .field-text-list-container .text-list-item .remove-text-item,
        .add-outcome-popup .popup-content form .form-control .field-text-list-container .text-list-item .remove-text-item,
        .edit-operation-popup .popup-content form .form-control .field-text-list-container .text-list-item .remove-text-item,
        .upload-popup .popup-content form .form-control .field-text-list-container .text-list-item .remove-text-item {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b;
          position: absolute;
          top: 0;
          right: 0;
          color: #f32424;
          font-size: 22px; }
          .add-income-popup .popup-content form .form-control .field-text-list-container .text-list-item .remove-text-item:hover,
          .add-outcome-popup .popup-content form .form-control .field-text-list-container .text-list-item .remove-text-item:hover,
          .edit-operation-popup .popup-content form .form-control .field-text-list-container .text-list-item .remove-text-item:hover,
          .upload-popup .popup-content form .form-control .field-text-list-container .text-list-item .remove-text-item:hover {
            color: black; }
      .add-income-popup .popup-content form .form-control .field-text-list-container .add-text-item,
      .add-outcome-popup .popup-content form .form-control .field-text-list-container .add-text-item,
      .edit-operation-popup .popup-content form .form-control .field-text-list-container .add-text-item,
      .upload-popup .popup-content form .form-control .field-text-list-container .add-text-item {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .add-income-popup .popup-content form .form-control .field-text-list-container .add-text-item:hover,
        .add-outcome-popup .popup-content form .form-control .field-text-list-container .add-text-item:hover,
        .edit-operation-popup .popup-content form .form-control .field-text-list-container .add-text-item:hover,
        .upload-popup .popup-content form .form-control .field-text-list-container .add-text-item:hover {
          color: black; }
      .add-income-popup .popup-content form .form-control .files-loader-container .files-loader-input-container,
      .add-outcome-popup .popup-content form .form-control .files-loader-container .files-loader-input-container,
      .edit-operation-popup .popup-content form .form-control .files-loader-container .files-loader-input-container,
      .upload-popup .popup-content form .form-control .files-loader-container .files-loader-input-container {
        margin-bottom: 20px; }
      .add-income-popup .popup-content form .form-control .files-loader-input-container,
      .add-outcome-popup .popup-content form .form-control .files-loader-input-container,
      .edit-operation-popup .popup-content form .form-control .files-loader-input-container,
      .upload-popup .popup-content form .form-control .files-loader-input-container {
        position: relative; }
        .add-income-popup .popup-content form .form-control .files-loader-input-container .input-label-area,
        .add-outcome-popup .popup-content form .form-control .files-loader-input-container .input-label-area,
        .edit-operation-popup .popup-content form .form-control .files-loader-input-container .input-label-area,
        .upload-popup .popup-content form .form-control .files-loader-input-container .input-label-area {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          background: none;
          border: 1px solid #4b4b4b;
          color: #4b4b4b;
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px;
          width: 100%;
          height: 60px;
          line-height: 60px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          border-radius: 8px; }
          .add-income-popup .popup-content form .form-control .files-loader-input-container .input-label-area .text,
          .add-income-popup .popup-content form .form-control .files-loader-input-container .input-label-area .icon,
          .add-outcome-popup .popup-content form .form-control .files-loader-input-container .input-label-area .text,
          .add-outcome-popup .popup-content form .form-control .files-loader-input-container .input-label-area .icon,
          .edit-operation-popup .popup-content form .form-control .files-loader-input-container .input-label-area .text,
          .edit-operation-popup .popup-content form .form-control .files-loader-input-container .input-label-area .icon,
          .upload-popup .popup-content form .form-control .files-loader-input-container .input-label-area .text,
          .upload-popup .popup-content form .form-control .files-loader-input-container .input-label-area .icon {
            display: inline-block;
            vertical-align: top; }
          .add-income-popup .popup-content form .form-control .files-loader-input-container .input-label-area .icon,
          .add-outcome-popup .popup-content form .form-control .files-loader-input-container .input-label-area .icon,
          .edit-operation-popup .popup-content form .form-control .files-loader-input-container .input-label-area .icon,
          .upload-popup .popup-content form .form-control .files-loader-input-container .input-label-area .icon {
            margin-right: 0; }
          .add-income-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-xs,
          .add-outcome-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-xs,
          .edit-operation-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-xs,
          .upload-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .add-income-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-s,
          .add-outcome-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-s,
          .edit-operation-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-s,
          .upload-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .add-income-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-l,
          .add-outcome-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-l,
          .edit-operation-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-l,
          .upload-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .add-income-popup .popup-content form .form-control .files-loader-input-container .input-label-area:hover,
          .add-outcome-popup .popup-content form .form-control .files-loader-input-container .input-label-area:hover,
          .edit-operation-popup .popup-content form .form-control .files-loader-input-container .input-label-area:hover,
          .upload-popup .popup-content form .form-control .files-loader-input-container .input-label-area:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-income-popup .popup-content form .form-control .files-loader-input-container .input-label-area,
  .add-outcome-popup .popup-content form .form-control .files-loader-input-container .input-label-area,
  .edit-operation-popup .popup-content form .form-control .files-loader-input-container .input-label-area,
  .upload-popup .popup-content form .form-control .files-loader-input-container .input-label-area {
    font-weight: normal; } }
          .add-income-popup .popup-content form .form-control .files-loader-input-container .input-label-area:hover,
          .add-outcome-popup .popup-content form .form-control .files-loader-input-container .input-label-area:hover,
          .edit-operation-popup .popup-content form .form-control .files-loader-input-container .input-label-area:hover,
          .upload-popup .popup-content form .form-control .files-loader-input-container .input-label-area:hover {
            background: #f2f5fb; }
        .add-income-popup .popup-content form .form-control .files-loader-input-container input,
        .add-outcome-popup .popup-content form .form-control .files-loader-input-container input,
        .edit-operation-popup .popup-content form .form-control .files-loader-input-container input,
        .upload-popup .popup-content form .form-control .files-loader-input-container input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .add-income-popup .popup-content form .form-control .files-loader-input-container:hover .input-label-area,
        .add-outcome-popup .popup-content form .form-control .files-loader-input-container:hover .input-label-area,
        .edit-operation-popup .popup-content form .form-control .files-loader-input-container:hover .input-label-area,
        .upload-popup .popup-content form .form-control .files-loader-input-container:hover .input-label-area {
          background: #f2f5fb; }
      .add-income-popup .popup-content form .form-control .files-loader-list .files-list-item,
      .add-outcome-popup .popup-content form .form-control .files-loader-list .files-list-item,
      .edit-operation-popup .popup-content form .form-control .files-loader-list .files-list-item,
      .upload-popup .popup-content form .form-control .files-loader-list .files-list-item {
        display: flex;
        justify-content: space-between;
        text-align: left;
        margin-bottom: 10px; }
        .add-income-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col,
        .add-outcome-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col,
        .edit-operation-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col,
        .upload-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
          .add-income-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.icon,
          .add-outcome-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.icon,
          .edit-operation-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.icon,
          .upload-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.icon {
            width: 38px;
            flex-shrink: 0; }
          .add-income-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.caption,
          .add-outcome-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.caption,
          .edit-operation-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.caption,
          .upload-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.caption {
            margin-right: auto;
            margin-left: 18px; }
          .add-income-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.price,
          .add-outcome-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.price,
          .edit-operation-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.price,
          .upload-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.price {
            flex-shrink: 0;
            text-align: right; }
          .add-income-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col .note,
          .add-outcome-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col .note,
          .edit-operation-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col .note,
          .upload-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col .note {
            font-size: 12px;
            color: #8494a9; }
        .add-income-popup .popup-content form .form-control .files-loader-list .files-list-item .icon,
        .add-outcome-popup .popup-content form .form-control .files-loader-list .files-list-item .icon,
        .edit-operation-popup .popup-content form .form-control .files-loader-list .files-list-item .icon,
        .upload-popup .popup-content form .form-control .files-loader-list .files-list-item .icon {
          display: inline-block;
          width: 38px;
          height: 38px;
          line-height: 37px;
          text-align: center;
          font-size: 22px;
          font-weight: 500;
          color: #fff;
          background: #bed6f9;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%;
          font-size: 12px;
          font-weight: normal; }
          .add-income-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.icon-sub-correction,
          .add-outcome-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.icon-sub-correction,
          .edit-operation-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.icon-sub-correction,
          .upload-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.icon-sub-correction {
            line-height: 34.2px; }
          .add-income-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-1,
          .add-outcome-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-1,
          .edit-operation-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-1,
          .upload-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-1 {
            background: #bed6f9; }
          .add-income-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-2,
          .add-outcome-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-2,
          .edit-operation-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-2,
          .upload-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-2 {
            background: #b9e398; }
          .add-income-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-3,
          .add-outcome-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-3,
          .edit-operation-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-3,
          .upload-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-3 {
            background: #e4e597; }
          .add-income-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-4,
          .add-outcome-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-4,
          .edit-operation-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-4,
          .upload-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-4 {
            background: #e7c740; }
          .add-income-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-5,
          .add-outcome-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-5,
          .edit-operation-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-5,
          .upload-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-5 {
            background: #e74040; }
          .add-income-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-6,
          .add-outcome-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-6,
          .edit-operation-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-6,
          .upload-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-6 {
            background: #cf91af; }
        .add-income-popup .popup-content form .form-control .files-loader-list .files-list-item .caption,
        .add-outcome-popup .popup-content form .form-control .files-loader-list .files-list-item .caption,
        .edit-operation-popup .popup-content form .form-control .files-loader-list .files-list-item .caption,
        .upload-popup .popup-content form .form-control .files-loader-list .files-list-item .caption {
          height: 38px;
          line-height: 38px;
          margin-left: 12px; }
        .add-income-popup .popup-content form .form-control .files-loader-list .files-list-item .remove,
        .add-outcome-popup .popup-content form .form-control .files-loader-list .files-list-item .remove,
        .edit-operation-popup .popup-content form .form-control .files-loader-list .files-list-item .remove,
        .upload-popup .popup-content form .form-control .files-loader-list .files-list-item .remove {
          flex-shrink: 0;
          margin-left: 0; }
          .add-income-popup .popup-content form .form-control .files-loader-list .files-list-item .remove button,
          .add-outcome-popup .popup-content form .form-control .files-loader-list .files-list-item .remove button,
          .edit-operation-popup .popup-content form .form-control .files-loader-list .files-list-item .remove button,
          .upload-popup .popup-content form .form-control .files-loader-list .files-list-item .remove button {
            background: none;
            border: none;
            height: 38px;
            line-height: 38px;
            font-size: 22px;
            color: #f32424; }
      .add-income-popup .popup-content form .form-control .select-container .note,
      .add-outcome-popup .popup-content form .form-control .select-container .note,
      .edit-operation-popup .popup-content form .form-control .select-container .note,
      .upload-popup .popup-content form .form-control .select-container .note {
        position: relative;
        padding-left: 25px;
        padding-top: 6px;
        font-size: 14px;
        color: #8494a9;
        line-height: 14px; }
        .add-income-popup .popup-content form .form-control .select-container .note:before,
        .add-outcome-popup .popup-content form .form-control .select-container .note:before,
        .edit-operation-popup .popup-content form .form-control .select-container .note:before,
        .upload-popup .popup-content form .form-control .select-container .note:before {
          content: "i";
          position: absolute;
          top: 5px;
          left: 0;
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-right: 10px;
          line-height: 14px;
          border: 1px solid #8494a9;
          border-radius: 50%;
          text-align: center; }
      .add-income-popup .popup-content form .form-control .input-phone,
      .add-outcome-popup .popup-content form .form-control .input-phone,
      .edit-operation-popup .popup-content form .form-control .input-phone,
      .upload-popup .popup-content form .form-control .input-phone {
        display: flex; }
        .add-income-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select,
        .add-outcome-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select,
        .edit-operation-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select,
        .upload-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select {
          position: inherit; }
          .add-income-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-button,
          .add-outcome-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-button,
          .edit-operation-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-button,
          .upload-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-button {
            padding: 0;
            width: 80px;
            text-align: left;
            box-shadow: none;
            line-height: 34px;
            border-bottom: 1px solid #8d76f0;
            border-radius: 0; }
            .add-income-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after,
            .add-outcome-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after,
            .edit-operation-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after,
            .upload-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after {
              width: 8px; }
          .add-income-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-list,
          .add-outcome-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-list,
          .edit-operation-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-list,
          .upload-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-list {
            top: 100%;
            width: 100%;
            max-height: 200px;
            margin-top: 10px;
            overflow: auto; }
            .add-income-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button,
            .add-outcome-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button,
            .edit-operation-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button,
            .upload-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button {
              text-align: left; }
          .add-income-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select select,
          .add-outcome-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select select,
          .edit-operation-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select select,
          .upload-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select select {
            width: 80px; }
          .add-income-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select.show-list .select-button,
          .add-outcome-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select.show-list .select-button,
          .edit-operation-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select.show-list .select-button,
          .upload-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select.show-list .select-button {
            opacity: 1;
            filter: alpha(opacity=100);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .add-income-popup .popup-content form .form-control .input-phone .phone-number,
        .add-outcome-popup .popup-content form .form-control .input-phone .phone-number,
        .edit-operation-popup .popup-content form .form-control .input-phone .phone-number,
        .upload-popup .popup-content form .form-control .input-phone .phone-number {
          flex: 1; }
      .add-income-popup .popup-content form .form-control .password-input,
      .add-outcome-popup .popup-content form .form-control .password-input,
      .edit-operation-popup .popup-content form .form-control .password-input,
      .upload-popup .popup-content form .form-control .password-input {
        position: relative; }
        .add-income-popup .popup-content form .form-control .password-input .eye-icon,
        .add-outcome-popup .popup-content form .form-control .password-input .eye-icon,
        .edit-operation-popup .popup-content form .form-control .password-input .eye-icon,
        .upload-popup .popup-content form .form-control .password-input .eye-icon {
          font-size: 18px;
          background-color: transparent;
          color: #8494a9;
          position: absolute;
          right: 0; }

@media screen and (max-width: 500px) {
  .add-income-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon,
  .add-outcome-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon,
  .edit-operation-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon,
  .upload-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon {
    top: 3px; } }
      .add-income-popup .popup-content form .form-control .datepicker-popup,
      .add-outcome-popup .popup-content form .form-control .datepicker-popup,
      .edit-operation-popup .popup-content form .form-control .datepicker-popup,
      .upload-popup .popup-content form .form-control .datepicker-popup {
        position: absolute;
        top: 100%;
        left: 0; }
    .add-income-popup .popup-content form .form-errors-list,
    .add-outcome-popup .popup-content form .form-errors-list,
    .edit-operation-popup .popup-content form .form-errors-list,
    .upload-popup .popup-content form .form-errors-list {
      margin-bottom: 24px; }
      .add-income-popup .popup-content form .form-errors-list .error-item,
      .add-outcome-popup .popup-content form .form-errors-list .error-item,
      .edit-operation-popup .popup-content form .form-errors-list .error-item,
      .upload-popup .popup-content form .form-errors-list .error-item {
        margin-bottom: 8px;
        color: #f32424; }
        .add-income-popup .popup-content form .form-errors-list .error-item:last-child,
        .add-outcome-popup .popup-content form .form-errors-list .error-item:last-child,
        .edit-operation-popup .popup-content form .form-errors-list .error-item:last-child,
        .upload-popup .popup-content form .form-errors-list .error-item:last-child {
          margin-bottom: 0; }
    .add-income-popup .popup-content form .form-messages-list,
    .add-outcome-popup .popup-content form .form-messages-list,
    .edit-operation-popup .popup-content form .form-messages-list,
    .upload-popup .popup-content form .form-messages-list {
      margin-bottom: 24px;
      text-align: center; }
      .add-income-popup .popup-content form .form-messages-list .message-item,
      .add-outcome-popup .popup-content form .form-messages-list .message-item,
      .edit-operation-popup .popup-content form .form-messages-list .message-item,
      .upload-popup .popup-content form .form-messages-list .message-item {
        margin-bottom: 8px;
        color: #4dbd7d;
        font-size: 17px;
        line-height: 22px; }
        .add-income-popup .popup-content form .form-messages-list .message-item:last-child,
        .add-outcome-popup .popup-content form .form-messages-list .message-item:last-child,
        .edit-operation-popup .popup-content form .form-messages-list .message-item:last-child,
        .upload-popup .popup-content form .form-messages-list .message-item:last-child {
          margin-bottom: 0; }
    .add-income-popup .popup-content form .form-field,
    .add-outcome-popup .popup-content form .form-field,
    .edit-operation-popup .popup-content form .form-field,
    .upload-popup .popup-content form .form-field {
      margin-bottom: 24px; }
      .add-income-popup .popup-content form .form-field .form-label,
      .add-outcome-popup .popup-content form .form-field .form-label,
      .edit-operation-popup .popup-content form .form-field .form-label,
      .upload-popup .popup-content form .form-field .form-label {
        font-size: 14px; }
      .add-income-popup .popup-content form .form-field.field-type-select .form-label,
      .add-outcome-popup .popup-content form .form-field.field-type-select .form-label,
      .edit-operation-popup .popup-content form .form-field.field-type-select .form-label,
      .upload-popup .popup-content form .form-field.field-type-select .form-label {
        display: none; }
      .add-income-popup .popup-content form .form-field.field-type-select.field-name-account_to .form-label,
      .add-outcome-popup .popup-content form .form-field.field-type-select.field-name-account_to .form-label,
      .edit-operation-popup .popup-content form .form-field.field-type-select.field-name-account_to .form-label,
      .upload-popup .popup-content form .form-field.field-type-select.field-name-account_to .form-label {
        display: block;
        margin-bottom: 30px;
        text-align: center;
        font-size: 16px; }
      .add-income-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button,
      .add-outcome-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button,
      .edit-operation-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button,
      .upload-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        background: none;
        border: 1px solid #4b4b4b;
        color: #4b4b4b;
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px;
        width: 100%; }
        .add-income-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button .text,
        .add-income-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button .icon,
        .add-outcome-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button .text,
        .add-outcome-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button .icon,
        .edit-operation-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button .text,
        .edit-operation-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button .icon,
        .upload-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button .text,
        .upload-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button .icon {
          display: inline-block;
          vertical-align: top; }
        .add-income-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button .icon,
        .add-outcome-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button .icon,
        .edit-operation-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button .icon,
        .upload-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button .icon {
          margin-right: 0; }
        .add-income-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button.button-xs,
        .add-outcome-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button.button-xs,
        .edit-operation-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button.button-xs,
        .upload-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .add-income-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button.button-s,
        .add-outcome-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button.button-s,
        .edit-operation-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button.button-s,
        .upload-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .add-income-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button.button-l,
        .add-outcome-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button.button-l,
        .edit-operation-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button.button-l,
        .upload-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .add-income-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button:hover,
        .add-outcome-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button:hover,
        .edit-operation-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button:hover,
        .upload-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-income-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button,
  .add-outcome-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button,
  .edit-operation-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button,
  .upload-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button {
    font-weight: normal; } }
        .add-income-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button:hover,
        .add-outcome-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button:hover,
        .edit-operation-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button:hover,
        .upload-popup .popup-content form .form-field.field-type-button.field-name-cancel .form-button:hover {
          background: #f2f5fb; }
      .add-income-popup .popup-content form .form-field.field-error .form-control select,
      .add-outcome-popup .popup-content form .form-field.field-error .form-control select,
      .edit-operation-popup .popup-content form .form-field.field-error .form-control select,
      .upload-popup .popup-content form .form-field.field-error .form-control select {
        opacity: 1;
        color: #fff;
        border: 1px solid #f32424;
        border-radius: 8px; }
    .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-action .pre-icon:after,
    .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-action .pre-icon:after,
    .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-action .pre-icon:after,
    .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-action .pre-icon:after,
    .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-action .pre-icon:after,
    .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-action .pre-icon:after,
    .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-action .pre-icon:after,
    .upload-popup .popup-content form .form-control .search-select .select-list .select-list-action .pre-icon:after {
      content: "+ "; }
    .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-action.lesser-action,
    .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-action.lesser-action,
    .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-action.lesser-action,
    .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-action.lesser-action,
    .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-action.lesser-action,
    .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-action.lesser-action,
    .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-action.lesser-action,
    .upload-popup .popup-content form .form-control .search-select .select-list .select-list-action.lesser-action {
      font-size: 14px; }
      .add-income-popup .popup-content form .form-control .styled-select .select-list .select-list-action.lesser-action:after,
      .add-income-popup .popup-content form .form-control .search-select .select-list .select-list-action.lesser-action:after,
      .add-outcome-popup .popup-content form .form-control .styled-select .select-list .select-list-action.lesser-action:after,
      .add-outcome-popup .popup-content form .form-control .search-select .select-list .select-list-action.lesser-action:after,
      .edit-operation-popup .popup-content form .form-control .styled-select .select-list .select-list-action.lesser-action:after,
      .edit-operation-popup .popup-content form .form-control .search-select .select-list .select-list-action.lesser-action:after,
      .upload-popup .popup-content form .form-control .styled-select .select-list .select-list-action.lesser-action:after,
      .upload-popup .popup-content form .form-control .search-select .select-list .select-list-action.lesser-action:after {
        content: "";
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 1px;
        background: rgba(0, 0, 0, 0.1);
        opacity: 0.5;
        filter: alpha(opacity=50);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }

.remove-operation-popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(255, 255, 255, 0.7);
  animation: popup-background-fade-in 0.8s ease; }
  .remove-operation-popup .popup-container {
    position: relative;
    display: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    display: block;
    width: 550px;
    margin: 0 auto;
    padding-bottom: 58px;
    top: 10%;
    max-height: 75%;
    overflow: auto;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    /* Scrollbar styles */ }
    .remove-operation-popup .popup-container.show {
      display: block; }
    .remove-operation-popup .popup-container .close-button {
      width: 19px;
      height: 19px;
      background: url(images/icon-close.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: absolute;
      top: 25px;
      right: 25px; }

@media screen and (max-width: 500px) {
  .remove-operation-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .remove-operation-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .remove-operation-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
    .remove-operation-popup .popup-container::-webkit-scrollbar {
      width: 12px;
      height: 12px; }
    .remove-operation-popup .popup-container::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 10px; }
    .remove-operation-popup .popup-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #ccc; }
    .remove-operation-popup .popup-container .popup-content form .form-control .datepicker-popup {
      position: fixed;
      top: 50%;
      left: 50%;
      margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .remove-operation-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
    .remove-operation-popup .popup-container .popup-content .popup-actions {
      width: 340px;
      margin: 0 auto; }
      .remove-operation-popup .popup-container .popup-content .popup-actions button {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .remove-operation-popup .popup-container .popup-content .popup-actions button:hover {
          color: black; }
  .remove-operation-popup .header {
    padding: 32px 24px;
    font-size: 22px; }
  .remove-operation-popup .body {
    padding: 0 24px 32px 24px; }
  .remove-operation-popup.show {
    display: block; }

@media screen and (max-width: 600px) {
  .remove-operation-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .remove-operation-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .remove-operation-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
  .remove-operation-popup .popup-container {
    text-align: center;
    padding-bottom: 0; }
  .remove-operation-popup .popup-content {
    padding: 0 64px 52px 64px; }
    .remove-operation-popup .popup-content .header {
      padding: 64px 0 56px 0; }
    .remove-operation-popup .popup-content .body {
      margin-bottom: 32px; }
    .remove-operation-popup .popup-content .form-fieldset {
      display: flex; }
      .remove-operation-popup .popup-content .form-fieldset .form-field {
        flex: 1;
        margin: 0 12px; }
        .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-button button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          background: none;
          border: 1px solid #4b4b4b;
          color: #4b4b4b;
          width: 100%;
          font-size: 16px; }
          .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-button button .text,
          .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
            display: inline-block;
            vertical-align: top; }
          .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
            margin-right: 0; }
          .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-button button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-button button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-button button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-button button {
    font-weight: normal; } }
          .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
            background: #f2f5fb; }
        .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          width: 100%;
          font-size: 16px; }
          .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
          .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
            display: inline-block;
            vertical-align: top; }
          .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
            margin-right: 0; }
          .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
  .remove-operation-popup.show .popup-container {
    animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

@media screen and (max-width: 450px) {
  .remove-operation-popup .popup-content {
    padding-right: 32px;
    padding-left: 32px; }
    .remove-operation-popup .popup-content .header {
      padding: 32px 0 28px 0; }
    .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-button button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; } }
  .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    background: #f35e84;
    width: 100%;
    font-size: 16px; }
    .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
    .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
      display: inline-block;
      vertical-align: top; }
    .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
      margin-right: 0; }
    .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
    .remove-operation-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
      background: #e64871; }

/* DATEPICKER */
.datepicker .datepicker-header {
  margin-bottom: 12px;
  text-align: center;
  font-size: 0; }
  .datepicker .datepicker-header label {
    margin: 0 16px;
    font-size: 17px;
    color: #4b4b4b; }
  .datepicker .datepicker-header button {
    padding: 0 8px; }
    .datepicker .datepicker-header button.month-prev {
      width: 10px;
      height: 13px;
      background: url(images/arrow-left-black.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .datepicker .datepicker-header button.month-next {
      width: 10px;
      height: 13px;
      background: url(images/arrow-right-black.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }

.datepicker .datepicker-cell {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-bottom: 8px;
  font-size: 17px;
  background: none;
  text-align: center; }
  .datepicker .datepicker-cell.other-month-day {
    opacity: 0.3;
    filter: alpha(opacity=30);
    filter: progid:DXImageTransform.Microsoft.Alpha Opacity=30;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
  .datepicker .datepicker-cell.datepicker-cell-today {
    background: #ffffbe; }
  .datepicker .datepicker-cell.selected {
    background: #7bb0f9;
    color: #fff; }
  .datepicker .datepicker-cell.range-selected {
    background: #e2ecfb; }
  .datepicker .datepicker-cell:hover {
    background: #f2f5fb; }

.datepicker .datepicker-days {
  width: 280px;
  font-size: 0; }

.datepicker .datepicker-weekdays .datepicker-cell {
  color: #8494a9; }

.datepicker .datepicker-actions {
  text-align: center; }
  .datepicker .datepicker-actions button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    background: none;
    border: 1px solid #4b4b4b;
    color: #4b4b4b;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px; }
    .datepicker .datepicker-actions button .text,
    .datepicker .datepicker-actions button .icon {
      display: inline-block;
      vertical-align: top; }
    .datepicker .datepicker-actions button .icon {
      margin-right: 0; }
    .datepicker .datepicker-actions button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .datepicker .datepicker-actions button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .datepicker .datepicker-actions button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .datepicker .datepicker-actions button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .datepicker .datepicker-actions button {
    font-weight: normal; } }
    .datepicker .datepicker-actions button:hover {
      background: #f2f5fb; }

.datepicker-popup {
  position: relative;
  display: none;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 20;
  width: 390px;
  padding: 32px 0; }
  .datepicker-popup.show {
    display: block; }
  .datepicker-popup .close-button {
    width: 19px;
    height: 19px;
    background: url(images/icon-close.png) no-repeat center center;
    display: inline-block;
    background-size: contain;
    position: absolute;
    top: 25px;
    right: 25px; }

@media screen and (max-width: 500px) {
  .datepicker-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .datepicker-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .datepicker-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
  .datepicker-popup .datepicker .datepicker-days {
    margin: 0 auto; }

/* LOADING POPUP */
.loading-popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(255, 255, 255, 0.7);
  animation: popup-background-fade-in 0.8s ease; }
  .loading-popup .popup-container {
    position: relative;
    display: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    display: block;
    width: 550px;
    margin: 0 auto;
    padding-bottom: 58px;
    top: 10%;
    max-height: 75%;
    overflow: auto;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    /* Scrollbar styles */ }
    .loading-popup .popup-container.show {
      display: block; }
    .loading-popup .popup-container .close-button {
      width: 19px;
      height: 19px;
      background: url(images/icon-close.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: absolute;
      top: 25px;
      right: 25px; }

@media screen and (max-width: 500px) {
  .loading-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .loading-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .loading-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
    .loading-popup .popup-container::-webkit-scrollbar {
      width: 12px;
      height: 12px; }
    .loading-popup .popup-container::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 10px; }
    .loading-popup .popup-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #ccc; }
    .loading-popup .popup-container .popup-content form .form-control .datepicker-popup {
      position: fixed;
      top: 50%;
      left: 50%;
      margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .loading-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
    .loading-popup .popup-container .popup-content .popup-actions {
      width: 340px;
      margin: 0 auto; }
      .loading-popup .popup-container .popup-content .popup-actions button {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .loading-popup .popup-container .popup-content .popup-actions button:hover {
          color: black; }
  .loading-popup .header {
    padding: 32px 24px;
    font-size: 22px; }
  .loading-popup .body {
    padding: 0 24px 32px 24px; }
  .loading-popup.show {
    display: block; }

@media screen and (max-width: 600px) {
  .loading-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .loading-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .loading-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
  .loading-popup .loading-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 140px;
    height: 140px;
    margin: -70px 0 0 -70px;
    background: url(images/loading-pulse.gif) no-repeat center center #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%; }
  .loading-popup .loading-message {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin-top: 70px;
    text-align: center; }
    .loading-popup .loading-message p {
      display: inline-block;
      padding: 10px;
      font-weight: 500;
      color: #8494a9;
      background: #fff;
      opacity: 0.8;
      filter: alpha(opacity=80);
      filter: progid:DXImageTransform.Microsoft.Alpha Opacity=80;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)';
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border-radius: 10px; }

/* INVESTMENTS */
.investments-actions {
  margin-bottom: 40px; }
  .investments-actions button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px; }
    .investments-actions button .text,
    .investments-actions button .icon {
      display: inline-block;
      vertical-align: top; }
    .investments-actions button .icon {
      margin-right: 0; }
    .investments-actions button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .investments-actions button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .investments-actions button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .investments-actions button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .investments-actions button {
    font-weight: normal; } }

.investments-plate {
  display: block;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: box-shadow 0.1s ease;
  -moz-transition: box-shadow 0.1s ease;
  -ms-transition: box-shadow 0.1s ease;
  -o-transition: box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease;
  padding: 32px 0 64px 0; }

@media screen and (max-width: 450px) {
  .investments-plate {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
  .investments-plate .investments-table .investments-headers {
    display: flex;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 40px;
    padding: 0 24px; }
    .investments-plate .investments-table .investments-headers .list-item-col {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .investments-plate .investments-table .investments-headers .list-item-col.icon {
        width: 38px;
        flex-shrink: 0; }
      .investments-plate .investments-table .investments-headers .list-item-col.caption {
        margin-right: auto;
        margin-left: 18px; }
      .investments-plate .investments-table .investments-headers .list-item-col.price {
        flex-shrink: 0;
        text-align: right; }
      .investments-plate .investments-table .investments-headers .list-item-col .note {
        font-size: 12px;
        color: #8494a9; }
    .investments-plate .investments-table .investments-headers .list-item-col {
      font-size: 17px;
      font-weight: 500; }
      .investments-plate .investments-table .investments-headers .list-item-col.caption {
        flex: 5;
        margin-left: 0; }
      .investments-plate .investments-table .investments-headers .list-item-col.sum, .investments-plate .investments-table .investments-headers .list-item-col.profit {
        flex: 3;
        text-align: right; }
      .investments-plate .investments-table .investments-headers .list-item-col.date, .investments-plate .investments-table .investments-headers .list-item-col.expire-date {
        flex: 2;
        text-align: right; }
      .investments-plate .investments-table .investments-headers .list-item-col.comment {
        flex: 2;
        padding-left: 30px; }
      .investments-plate .investments-table .investments-headers .list-item-col.edit, .investments-plate .investments-table .investments-headers .list-item-col.icon-dropdown {
        flex: 0.5;
        text-align: right; }
  .investments-plate .investments-table .investments-list .investment-item {
    display: flex;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    color: #4b4b4b; }
    .investments-plate .investments-table .investments-list .investment-item .list-item-col {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon {
        width: 38px;
        flex-shrink: 0; }
      .investments-plate .investments-table .investments-list .investment-item .list-item-col.caption {
        margin-right: auto;
        margin-left: 18px; }
      .investments-plate .investments-table .investments-list .investment-item .list-item-col.price {
        flex-shrink: 0;
        text-align: right; }
      .investments-plate .investments-table .investments-list .investment-item .list-item-col .note {
        font-size: 12px;
        color: #8494a9; }
    .investments-plate .investments-table .investments-list .investment-item label {
      display: none; }
    .investments-plate .investments-table .investments-list .investment-item .list-item-col {
      height: 38px;
      line-height: 38px;
      padding: 16px 0; }
      .investments-plate .investments-table .investments-list .investment-item .list-item-col p {
        line-height: 38px; }
      .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon {
        width: 38px;
        padding-left: 24px;
        flex-shirnk: 0;
        border-bottom: 0; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon .icon-content {
          display: inline-block;
          width: 38px;
          height: 38px;
          line-height: 37px;
          text-align: center;
          font-size: 22px;
          font-weight: 500;
          color: #fff;
          background: #bed6f9;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon .icon-content.icon-sub-correction {
            line-height: 34.2px; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon .icon-content.color-1 {
            background: #bed6f9; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon .icon-content.color-2 {
            background: #b9e398; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon .icon-content.color-3 {
            background: #e4e597; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon .icon-content.color-4 {
            background: #e7c740; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon .icon-content.color-5 {
            background: #e74040; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon .icon-content.color-6 {
            background: #cf91af; }
      .investments-plate .investments-table .investments-list .investment-item .list-item-col.caption {
        flex: 4;
        font-size: 17px;
        font-weight: 500;
        margin-left: 0;
        padding-left: 16px;
        border-bottom: 0; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.caption p {
          font-size: 17px;
          font-weight: 500;
          text-overflow: ellipsis;
          overflow: hidden; }
      .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum {
        flex: 3;
        font-size: 0;
        text-align: right; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum .int,
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum .delim {
          font-size: 22px; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum .float,
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum .unit {
          font-size: 17px; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum .unit {
          margin-left: 8px; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum .unit-ruble:after {
          content: "\20bd"; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum.income, .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum.transfer-income {
          color: #4dbd7d; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum.income .int,
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum.income .delim,
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum.income .float,
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum.income .unit, .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum.transfer-income .int,
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum.transfer-income .delim,
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum.transfer-income .float,
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum.transfer-income .unit {
            color: #4dbd7d; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum .int,
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum .delim {
          font-weight: 500; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum .float,
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum .unit {
          color: #8494a9; }
      .investments-plate .investments-table .investments-list .investment-item .list-item-col.date {
        flex: 2;
        text-align: right; }
      .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit {
        flex: 3;
        font-size: 0;
        text-align: right; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit .int,
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit .delim {
          font-size: 22px; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit .float,
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit .unit {
          font-size: 17px; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit .unit {
          margin-left: 8px; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit .unit-ruble:after {
          content: "\20bd"; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit.income, .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit.transfer-income {
          color: #4dbd7d; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit.income .int,
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit.income .delim,
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit.income .float,
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit.income .unit, .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit.transfer-income .int,
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit.transfer-income .delim,
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit.transfer-income .float,
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit.transfer-income .unit {
            color: #4dbd7d; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit .int,
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit .delim {
          font-weight: 500; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit .float,
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit .unit {
          color: #8494a9; }
      .investments-plate .investments-table .investments-list .investment-item .list-item-col.comment {
        flex: 2;
        padding-left: 30px; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.comment .icon {
          width: 24px;
          height: 21px;
          background: url(images/icon-comment-black.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          margin-right: 5px;
          vertical-align: middle; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.comment .value {
          display: inline-block;
          color: #4b4b4b;
          font-size: 16px;
          vertical-align: middle; }
      .investments-plate .investments-table .investments-list .investment-item .list-item-col.edit {
        flex: 0.5; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.edit .edit-button {
          width: 22px;
          height: 22px;
          background: url(images/icon-edit-gray.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          vertical-align: middle; }
      .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon-dropdown {
        flex: 0.5;
        padding-right: 24px;
        text-align: right; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon-dropdown .dropdown-show,
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon-dropdown .dropdown-hide {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b;
          display: none;
          margin-right: 5px; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon-dropdown .dropdown-show:hover,
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon-dropdown .dropdown-hide:hover {
            color: black; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon-dropdown .icon {
          width: 17px;
          height: 8px;
          background: url(images/arrow-bottom-black.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
  .investments-plate .investments-table .investments-list .investment-dropdown {
    display: none;
    padding: 0 0 20px 0; }
    .investments-plate .investments-table .investments-list .investment-dropdown .investment-item .list-item-col.icon, .investments-plate .investments-table .investments-list .investment-dropdown .investment-item .list-item-col.icon-dropdown {
      opacity: 0; }
    .investments-plate .investments-table .investments-list .investment-dropdown .investment-item .list-item-col.caption p {
      padding-left: 24px;
      font-weight: normal;
      font-size: 16px; }
    .investments-plate .investments-table .investments-list .investment-dropdown .investment-actions {
      padding: 20px 0 20px 100px; }
  .investments-plate .investments-table .investments-list .investment-actions button {
    background: none;
    border: none;
    cursor: pointer;
    color: #4b4b4b; }
    .investments-plate .investments-table .investments-list .investment-actions button:hover {
      color: black; }
  .investments-plate .investments-table .investments-list .investment-container:hover {
    background: #f2f5fb;
    cursor: pointer; }
  .investments-plate .investments-table .investments-list .investment-container.opened {
    background: #f2f5fb; }
    .investments-plate .investments-table .investments-list .investment-container.opened .investment-dropdown {
      display: block; }

/* ERROR LAYOUT */
.error-layout {
  text-align: center;
  padding: 60px 18px; }
  .error-layout p {
    color: #f32424;
    font-size: 22px; }

/* CABINET */
.cabinet-sidebar {
  width: 240px;
  flex-shrink: 0;
  margin-right: 18px;
  width: 300px; }
  .cabinet-sidebar .sidebar-plate {
    display: block;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: box-shadow 0.1s ease;
    -moz-transition: box-shadow 0.1s ease;
    -ms-transition: box-shadow 0.1s ease;
    -o-transition: box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease;
    padding: 24px 16px; }

@media screen and (max-width: 450px) {
  .cabinet-sidebar .sidebar-plate {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
  .cabinet-sidebar .userbar {
    padding-top: 18px;
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .cabinet-sidebar .userbar .username {
      position: relative;
      padding-left: 34px;
      font-size: 16px;
      font-weight: 500; }
      .cabinet-sidebar .userbar .username .icon {
        width: 22px;
        height: 22px;
        background: url(images/icon-profile-black.svg) no-repeat center center;
        display: inline-block;
        background-size: contain;
        display: block;
        position: absolute;
        top: 0;
        left: 0; }
  .cabinet-sidebar .menu-item {
    margin-bottom: 24px; }
    .cabinet-sidebar .menu-item button {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b;
      display: block;
      position: relative;
      font-size: 15px;
      font-weight: 500;
      text-align: left;
      box-sizing: border-box; }
      .cabinet-sidebar .menu-item button:hover {
        color: black; }
      .cabinet-sidebar .menu-item button .icon {
        position: absolute;
        top: 0;
        left: 0; }
      .cabinet-sidebar .menu-item button .icon-about {
        width: 22px;
        height: 23px;
        background: url(images/icon-about-black.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .cabinet-sidebar .menu-item button .icon-companies,
      .cabinet-sidebar .menu-item button .icon-categories {
        width: 21px;
        height: 22px;
        background: url(images/icon-companies-black.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .cabinet-sidebar .menu-item button .icon-settings,
      .cabinet-sidebar .menu-item button .icon-owners,
      .cabinet-sidebar .menu-item button .icon-workers,
      .cabinet-sidebar .menu-item button .icon-partners {
        width: 22px;
        height: 22px;
        background: url(images/icon-profile-black.svg) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .cabinet-sidebar .menu-item button .icon-license,
      .cabinet-sidebar .menu-item button .icon-accounts {
        width: 22px;
        height: 22px;
        background: url(images/icon-ruble-black.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .cabinet-sidebar .menu-item button .icon-logout {
        top: 2px;
        width: 21px;
        height: 19px;
        background: url(images/icon-logout-black.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .cabinet-sidebar .menu-item button .icon-feedback {
        width: 22px;
        height: 22px;
        background: url(images/icon-edit-black.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .cabinet-sidebar .menu-item button .icon-telegram {
        width: 22px;
        height: 22px;
        background: url(images/icon-telegram.svg) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .cabinet-sidebar .menu-item button .icon-whatsapp {
        width: 22px;
        height: 22px;
        background: url(images/icon-whatsapp.svg) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .cabinet-sidebar .menu-item button .text {
        display: block;
        margin-left: 34px; }
      .cabinet-sidebar .menu-item button.button-telegram {
        color: #47aaed; }
      .cabinet-sidebar .menu-item button.button-whatsapp {
        color: #4cba3f; }
      .cabinet-sidebar .menu-item button .icon-xlsx {
        width: 22px;
        height: 22px;
        background: url(images/icon-xlsx.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .cabinet-sidebar .menu-item button .icon-pdf {
        width: 22px;
        height: 22px;
        background: url(images/icon-pdf.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .cabinet-sidebar .menu-item button .icon-word {
        width: 22px;
        height: 22px;
        background: url(images/icon-word.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .cabinet-sidebar .menu-item button .icon-print {
        width: 23px;
        height: 16px;
        background: url(images/icon-print.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
    .cabinet-sidebar .menu-item.active button {
      color: #4b4b4b; }
    .cabinet-sidebar .menu-item .companies {
      padding: 24px 0 0 48px; }
      .cabinet-sidebar .menu-item .companies .companies-list-item {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px;
        display: block;
        width: 210px;
        background: #fff;
        color: #4b4b4b;
        text-align: left;
        font-size: 16px;
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 4px;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px; }
        .cabinet-sidebar .menu-item .companies .companies-list-item .text,
        .cabinet-sidebar .menu-item .companies .companies-list-item .icon {
          display: inline-block;
          vertical-align: top; }
        .cabinet-sidebar .menu-item .companies .companies-list-item .icon {
          margin-right: 0; }
        .cabinet-sidebar .menu-item .companies .companies-list-item.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .cabinet-sidebar .menu-item .companies .companies-list-item.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .cabinet-sidebar .menu-item .companies .companies-list-item.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .cabinet-sidebar .menu-item .companies .companies-list-item:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-sidebar .menu-item .companies .companies-list-item {
    font-weight: normal; } }
        .cabinet-sidebar .menu-item .companies .companies-list-item:hover {
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); }
        .cabinet-sidebar .menu-item .companies .companies-list-item.active {
          background: #bed6f9;
          color: #fff; }
      .cabinet-sidebar .menu-item .companies .actions {
        padding: 12px 0; }
        .cabinet-sidebar .menu-item .companies .actions button {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b;
          font-weight: normal;
          color: #8494a9; }
          .cabinet-sidebar .menu-item .companies .actions button:hover {
            color: black; }
          .cabinet-sidebar .menu-item .companies .actions button.action-add:before {
            content: "+ "; }

.cabinet-layout {
  display: flex; }
  .cabinet-layout .license-notification {
    margin-bottom: 48px; }
  .cabinet-layout .companies-selector-container {
    position: relative;
    margin-bottom: 24px; }
    .cabinet-layout .companies-selector-container .companies-selector button,
    .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: none;
      border: 1px solid #fff;
      color: #fff;
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px;
      height: 34px;
      max-width: 320px;
      line-height: 22px;
      -webkit-border-radius: 17px;
      -moz-border-radius: 17px;
      -ms-border-radius: 17px;
      -o-border-radius: 17px;
      border-radius: 17px;
      font-size: 17px;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      color: #4b4b4b;
      border-color: #4b4b4b; }
      .cabinet-layout .companies-selector-container .companies-selector button .text,
      .cabinet-layout .companies-selector-container .companies-selector button .icon,
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button .text,
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button .icon {
        display: inline-block;
        vertical-align: top; }
      .cabinet-layout .companies-selector-container .companies-selector button .icon,
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button .icon {
        margin-right: 0; }
      .cabinet-layout .companies-selector-container .companies-selector button.button-xs,
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .cabinet-layout .companies-selector-container .companies-selector button.button-s,
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .cabinet-layout .companies-selector-container .companies-selector button.button-l,
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .cabinet-layout .companies-selector-container .companies-selector button:hover,
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-layout .companies-selector-container .companies-selector button,
  .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button {
    font-weight: normal; } }
      .cabinet-layout .companies-selector-container .companies-selector button:hover,
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button:hover {
        background: rgba(255, 255, 255, 0.1); }
      .cabinet-layout .companies-selector-container .companies-selector button .icon,
      .cabinet-layout .companies-selector-container .companies-selector button .text,
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button .icon,
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button .text {
        vertical-align: top; }
      .cabinet-layout .companies-selector-container .companies-selector button .text,
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button .text {
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .cabinet-layout .companies-selector-container .companies-selector button .icon,
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button .icon {
        width: 12px;
        height: 10px;
        background: url(images/icon-burger-white.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        margin-top: 6px;
        margin-right: 8px; }
      .cabinet-layout .companies-selector-container .companies-selector button .icon,
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button .icon {
        background: url(images/icon-burger-black.png) no-repeat center center;
        background-size: contain; }
    .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button {
      max-width: 80%; }
    .cabinet-layout .companies-selector-container .companies-selector-popup {
      position: relative;
      display: none;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 20;
      position: absolute;
      top: 22px;
      left: 24px;
      padding-bottom: 32px;
      min-width: 500px;
      top: -11px;
      left: -8px;
      right: 0; }
      .cabinet-layout .companies-selector-container .companies-selector-popup.show {
        display: block; }
      .cabinet-layout .companies-selector-container .companies-selector-popup .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 25px; }

@media screen and (max-width: 500px) {
  .cabinet-layout .companies-selector-container .companies-selector-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .cabinet-layout .companies-selector-container .companies-selector-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .cabinet-layout .companies-selector-container .companies-selector-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button {
        margin: 11px 0 23px 8px;
        color: #4b4b4b;
        border-color: #4b4b4b; }
        .cabinet-layout .companies-selector-container .companies-selector-popup .selector-button .icon {
          background: url(images/icon-burger-black.png) no-repeat center center;
          background-size: contain; }
      .cabinet-layout .companies-selector-container .companies-selector-popup .checklist {
        min-width: 240px;
        padding-bottom: 14px; }
        .cabinet-layout .companies-selector-container .companies-selector-popup .checklist button {
          position: relative;
          display: block;
          width: 390px;
          height: 48px;
          line-height: 48px;
          padding-right: 32px;
          background: none;
          font-size: 17px;
          color: #4b4b4b; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .checklist button .icon {
            position: absolute;
            top: 13px;
            left: 24px;
            display: inline-block;
            width: 18px;
            height: 18px;
            border: 2px solid #8494a9;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .checklist button .icon-checked {
            position: relative;
            display: inline-block;
            width: 22px;
            height: 22px;
            background: #81bc52;
            border: 0;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px;
            position: absolute;
            top: 13px;
            left: 24px; }
            .cabinet-layout .companies-selector-container .companies-selector-popup .checklist button .icon-checked::after {
              content: "";
              width: 16px;
              height: 12px;
              background: url(images/icon-check-white.png) no-repeat center center;
              display: inline-block;
              background-size: contain;
              position: absolute;
              top: 7px;
              left: 4px;
              width: 13px;
              height: 9px; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .checklist button .text {
            display: block;
            margin-left: 60px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            font-size: 14px; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .checklist button:hover {
            background: #f2f5fb; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .checklist button.hidden {
            display: none; }

@media screen and (max-width: 500px) {
  .cabinet-layout .companies-selector-container .companies-selector-popup .checklist button {
    width: 100%;
    box-sizing: border-box; }
    .cabinet-layout .companies-selector-container .companies-selector-popup .checklist button .icon,
    .cabinet-layout .companies-selector-container .companies-selector-popup .checklist button .icon-checked {
      top: 7px; } }

@media screen and (max-width: 450px) {
  .cabinet-layout .companies-selector-container .companies-selector-popup .checklist button {
    font-weight: normal;
    height: 36px;
    line-height: 36px; } }
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-action-container {
        display: none; }
        .cabinet-layout .companies-selector-container .companies-selector-popup .selector-action-container button {
          background: none;
          border: none;
          cursor: pointer;
          color: #8494a9; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .selector-action-container button:hover {
            color: #5c6e84; }
        .cabinet-layout .companies-selector-container .companies-selector-popup .selector-action-container.show {
          display: block;
          margin-bottom: 30px; }
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-delim {
        height: 1px;
        margin: 28px 0;
        background: rgba(0, 0, 0, 0.1); }
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container {
        margin: 0 0 30px 90px; }
        .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-label {
          margin-bottom: 6px;
          color: #8494a9; }
        .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-value {
          font-size: 0;
          color: #4b4b4b; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-value .int,
          .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-value .delim {
            font-size: 17px; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-value .float,
          .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-value .unit {
            font-size: 14px; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-value .unit {
            margin-left: 8px; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-value .unit-ruble:after {
            content: "\20bd"; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-value.income, .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-value.transfer-income {
            color: #4dbd7d; }
            .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-value.income .int,
            .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-value.income .delim,
            .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-value.income .float,
            .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-value.income .unit, .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-value.transfer-income .int,
            .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-value.transfer-income .delim,
            .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-value.transfer-income .float,
            .cabinet-layout .companies-selector-container .companies-selector-popup .selector-balance-container .balance-value.transfer-income .unit {
              color: #4dbd7d; }
      .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu {
        margin-left: 22px; }
        .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b;
          display: block;
          position: relative;
          font-size: 15px;
          font-weight: 500;
          text-align: left;
          box-sizing: border-box;
          margin-bottom: 16px; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button:hover {
            color: black; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon {
            position: absolute;
            top: 0;
            left: 0; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon-about {
            width: 22px;
            height: 23px;
            background: url(images/icon-about-black.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon-companies,
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon-categories {
            width: 21px;
            height: 22px;
            background: url(images/icon-companies-black.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon-settings,
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon-owners,
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon-workers,
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon-partners {
            width: 22px;
            height: 22px;
            background: url(images/icon-profile-black.svg) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon-license,
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon-accounts {
            width: 22px;
            height: 22px;
            background: url(images/icon-ruble-black.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon-logout {
            top: 2px;
            width: 21px;
            height: 19px;
            background: url(images/icon-logout-black.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon-feedback {
            width: 22px;
            height: 22px;
            background: url(images/icon-edit-black.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon-telegram {
            width: 22px;
            height: 22px;
            background: url(images/icon-telegram.svg) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon-whatsapp {
            width: 22px;
            height: 22px;
            background: url(images/icon-whatsapp.svg) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .text {
            display: block;
            margin-left: 34px; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button.button-telegram {
            color: #47aaed; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button.button-whatsapp {
            color: #4cba3f; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon-xlsx {
            width: 22px;
            height: 22px;
            background: url(images/icon-xlsx.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon-pdf {
            width: 22px;
            height: 22px;
            background: url(images/icon-pdf.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon-word {
            width: 22px;
            height: 22px;
            background: url(images/icon-word.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .company-menu button .icon-print {
            width: 23px;
            height: 16px;
            background: url(images/icon-print.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
      .cabinet-layout .companies-selector-container .companies-selector-popup .companies-label {
        display: block;
        margin-bottom: 12px;
        color: #8494a9; }
      .cabinet-layout .companies-selector-container .companies-selector-popup .companies-compact-list {
        display: none; }
        .cabinet-layout .companies-selector-container .companies-selector-popup .companies-compact-list .companies-list-item:hover {
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
        .cabinet-layout .companies-selector-container .companies-selector-popup .companies-compact-list.show {
          display: block; }
      .cabinet-layout .companies-selector-container .companies-selector-popup .dropdown-button {
        background: none;
        border: none;
        float: right;
        color: #8494a9; }
        .cabinet-layout .companies-selector-container .companies-selector-popup .dropdown-button:after {
          content: "";
          width: 14px;
          height: 7px;
          background: url(images/arrow-bottom.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          position: relative;
          top: -2px;
          width: 7px;
          margin-left: 10px; }
      .cabinet-layout .companies-selector-container .companies-selector-popup .active-company {
        display: block;
        background: #fff;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        -webkit-transition: box-shadow 0.1s ease;
        -moz-transition: box-shadow 0.1s ease;
        -ms-transition: box-shadow 0.1s ease;
        -o-transition: box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease;
        display: flex;
        justify-content: space-between;
        text-align: left;
        width: 100%;
        padding: 20px;
        margin-bottom: 16px;
        box-sizing: border-box;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px;
        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
        -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
        -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: 0;
        -moz-box-shadow: 0;
        -ms-box-shadow: 0;
        -o-box-shadow: 0;
        box-shadow: 0;
        margin-bottom: 24px;
        background: radial-gradient(122.91% 173.67% at 84.84% -3.52%, #d15ed3 0%, #2c3aba 100%);
        cursor: pointer; }

@media screen and (max-width: 450px) {
  .cabinet-layout .companies-selector-container .companies-selector-popup .active-company {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
        .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .list-item-col {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .list-item-col.icon {
            width: 38px;
            flex-shrink: 0; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .list-item-col.caption {
            margin-right: auto;
            margin-left: 18px; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .list-item-col.price {
            flex-shrink: 0;
            text-align: right; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .list-item-col .note {
            font-size: 12px;
            color: #8494a9; }
        .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .icon {
          width: 24px; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .icon .icon-content {
            display: inline-block;
            width: 24px;
            height: 24px;
            line-height: 23px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
            background: #bed6f9;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            border-radius: 50%; }
            .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .icon .icon-content.icon-sub-correction {
              line-height: 21.6px; }
            .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .icon .icon-content.color-1 {
              background: #bed6f9; }
            .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .icon .icon-content.color-2 {
              background: #b9e398; }
            .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .icon .icon-content.color-3 {
              background: #e4e597; }
            .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .icon .icon-content.color-4 {
              background: #e7c740; }
            .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .icon .icon-content.color-5 {
              background: #e74040; }
            .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .icon .icon-content.color-6 {
              background: #cf91af; }
        .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info {
          margin-left: 8px;
          margin-right: auto;
          white-space: normal;
          color: #4b4b4b; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .caption,
          .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price {
            font-size: 0;
            color: #8494a9; }
            .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price .int,
            .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price .delim {
              font-size: 17px; }
            .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price .float,
            .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price .unit {
              font-size: 14px; }
            .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price .unit {
              margin-left: 8px; }
            .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price .unit-ruble:after {
              content: "\20bd"; }
            .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price.income, .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price.transfer-income {
              color: #4dbd7d; }
              .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price.income .int,
              .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price.income .delim,
              .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price.income .float,
              .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price.income .unit, .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price.transfer-income .int,
              .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price.transfer-income .delim,
              .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price.transfer-income .float,
              .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price.transfer-income .unit {
                color: #4dbd7d; }
        .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info {
          color: #fff; }
          .cabinet-layout .companies-selector-container .companies-selector-popup .active-company .info .price {
            color: #fff; }
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-limiter {
        margin-left: 90px;
        max-width: 326px; }
      .cabinet-layout .companies-selector-container .companies-selector-popup .selector-action-container .selector-action-add:before {
        content: "+ "; }

.cabinet-companies-list {
  display: block;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: box-shadow 0.1s ease;
  -moz-transition: box-shadow 0.1s ease;
  -ms-transition: box-shadow 0.1s ease;
  -o-transition: box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease; }

@media screen and (max-width: 450px) {
  .cabinet-companies-list {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
  .cabinet-companies-list .companies-list-item {
    display: flex;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    height: 70px;
    line-height: 70px;
    padding: 0 24px;
    color: #4b4b4b;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: none; }
    .cabinet-companies-list .companies-list-item .list-item-col {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .cabinet-companies-list .companies-list-item .list-item-col.icon {
        width: 38px;
        flex-shrink: 0; }
      .cabinet-companies-list .companies-list-item .list-item-col.caption {
        margin-right: auto;
        margin-left: 18px; }
      .cabinet-companies-list .companies-list-item .list-item-col.price {
        flex-shrink: 0;
        text-align: right; }
      .cabinet-companies-list .companies-list-item .list-item-col .note {
        font-size: 12px;
        color: #8494a9; }
    .cabinet-companies-list .companies-list-item .icon .icon-content {
      display: inline-block;
      width: 38px;
      height: 38px;
      line-height: 37px;
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      color: #fff;
      background: #bed6f9;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      border-radius: 50%;
      font-size: 17px; }
      .cabinet-companies-list .companies-list-item .icon .icon-content.icon-sub-correction {
        line-height: 34.2px; }
      .cabinet-companies-list .companies-list-item .icon .icon-content.color-1 {
        background: #bed6f9; }
      .cabinet-companies-list .companies-list-item .icon .icon-content.color-2 {
        background: #b9e398; }
      .cabinet-companies-list .companies-list-item .icon .icon-content.color-3 {
        background: #e4e597; }
      .cabinet-companies-list .companies-list-item .icon .icon-content.color-4 {
        background: #e7c740; }
      .cabinet-companies-list .companies-list-item .icon .icon-content.color-5 {
        background: #e74040; }
      .cabinet-companies-list .companies-list-item .icon .icon-content.color-6 {
        background: #cf91af; }
    .cabinet-companies-list .companies-list-item .caption {
      padding-right: 20px;
      font-size: 17px;
      font-weight: 500; }
    .cabinet-companies-list .companies-list-item .workers,
    .cabinet-companies-list .companies-list-item .accounts {
      color: #4b4b4b;
      width: 150px;
      flex-shrink: 0;
      text-align: right; }
    .cabinet-companies-list .companies-list-item .accounts {
      width: 100px; }
    .cabinet-companies-list .companies-list-item:last-child {
      border-bottom: 0; }
    .cabinet-companies-list .companies-list-item:hover {
      color: black;
      background: #f2f5fb; }

.cabinet-companies .companies-header {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 40px; }

.cabinet-actions {
  margin-bottom: 40px; }
  .cabinet-actions button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    background: #fff;
    color: #4b4b4b;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05); }
    .cabinet-actions button .text,
    .cabinet-actions button .icon {
      display: inline-block;
      vertical-align: top; }
    .cabinet-actions button .icon {
      margin-right: 0; }
    .cabinet-actions button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .cabinet-actions button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .cabinet-actions button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .cabinet-actions button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-actions button {
    font-weight: normal; } }
    .cabinet-actions button:hover {
      background: #fff;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); }

.cabinet-list-menu .menu-item {
  margin-bottom: 25px; }

.cabinet-list-menu button {
  background: none;
  border: none;
  cursor: pointer;
  color: #4b4b4b;
  font-size: 16px;
  font-weight: 500;
  text-align: left; }
  .cabinet-list-menu button:hover {
    color: black; }

.cabinet-breadcrumbs {
  display: none;
  margin-bottom: 20px;
  justify-content: flex-start; }
  .cabinet-breadcrumbs .breadcrumbs-delim {
    margin: 0 6px; }
    .cabinet-breadcrumbs .breadcrumbs-delim::after {
      content: "\2192";
      font-size: 17px; }
  .cabinet-breadcrumbs .breadcrumbs-item {
    background: none;
    border: none;
    cursor: pointer;
    color: #4b4b4b;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    padding: 0 10px 0 0;
    white-space: nowrap;
    flex-shrink: 0;
    background: #f2f2f2;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    border-radius: 12px; }
    .cabinet-breadcrumbs .breadcrumbs-item:hover {
      color: black; }
    .cabinet-breadcrumbs .breadcrumbs-item.item-company {
      flex-shrink: 1;
      overflow: hidden;
      text-overflow: ellipsis; }

/* LICENSE NOTIFICATION */
.license-notification {
  display: block;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: box-shadow 0.1s ease;
  -moz-transition: box-shadow 0.1s ease;
  -ms-transition: box-shadow 0.1s ease;
  -o-transition: box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease;
  display: flex;
  justify-content: space-between;
  padding: 12px; }

@media screen and (max-width: 450px) {
  .license-notification {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
  .license-notification .notification-logo {
    width: 115px;
    height: 33px;
    background: url(images/logo.png) no-repeat center center;
    display: inline-block;
    background-size: contain;
    margin: 7px 24px 0 12px; }
  .license-notification .notification-header {
    margin-right: auto;
    margin-top: 9px; }
  .license-notification .notification-actions {
    margin-right: 12px;
    margin-left: 24px; }
    .license-notification .notification-actions button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px;
      background: linear-gradient(73.01deg, #e9e122 8.78%, #f64399 113.32%), #956dbb;
      min-width: 170px; }
      .license-notification .notification-actions button .text,
      .license-notification .notification-actions button .icon {
        display: inline-block;
        vertical-align: top; }
      .license-notification .notification-actions button .icon {
        margin-right: 0; }
      .license-notification .notification-actions button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .license-notification .notification-actions button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .license-notification .notification-actions button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .license-notification .notification-actions button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .license-notification .notification-actions button {
    font-weight: normal; } }
      .license-notification .notification-actions button:hover {
        background: linear-gradient(73.01deg, #e0d820 8.78%, #e8398d 113.32%), #956dbb; }
  .license-notification.error .notification-header {
    color: #f32424; }

/* ADD COMPANY POPUP */
.add-company-popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(255, 255, 255, 0.7);
  animation: popup-background-fade-in 0.8s ease; }
  .add-company-popup .popup-container {
    position: relative;
    display: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    display: block;
    width: 550px;
    margin: 0 auto;
    padding-bottom: 58px;
    top: 10%;
    max-height: 75%;
    overflow: auto;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    /* Scrollbar styles */ }
    .add-company-popup .popup-container.show {
      display: block; }
    .add-company-popup .popup-container .close-button {
      width: 19px;
      height: 19px;
      background: url(images/icon-close.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: absolute;
      top: 25px;
      right: 25px; }

@media screen and (max-width: 500px) {
  .add-company-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .add-company-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .add-company-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
    .add-company-popup .popup-container::-webkit-scrollbar {
      width: 12px;
      height: 12px; }
    .add-company-popup .popup-container::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 10px; }
    .add-company-popup .popup-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #ccc; }
    .add-company-popup .popup-container .popup-content form .form-control .datepicker-popup {
      position: fixed;
      top: 50%;
      left: 50%;
      margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .add-company-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
    .add-company-popup .popup-container .popup-content .popup-actions {
      width: 340px;
      margin: 0 auto; }
      .add-company-popup .popup-container .popup-content .popup-actions button {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .add-company-popup .popup-container .popup-content .popup-actions button:hover {
          color: black; }
  .add-company-popup .header {
    padding: 32px 24px;
    font-size: 22px; }
  .add-company-popup .body {
    padding: 0 24px 32px 24px; }
  .add-company-popup.show {
    display: block; }

@media screen and (max-width: 600px) {
  .add-company-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .add-company-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .add-company-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
  .add-company-popup .popup-content form {
    width: 340px;
    margin: 0 auto; }
    .add-company-popup .popup-content form .form-field {
      margin-bottom: 38px;
      width: 100%; }
      .add-company-popup .popup-content form .form-field .form-label {
        display: block;
        font-size: 17px;
        color: #8494a9; }
      .add-company-popup .popup-content form .form-field.field-type-colorpicker .form-label {
        margin-bottom: 8px; }
      .add-company-popup .popup-content form .form-field.field-type-files .form-label {
        display: none; }
      .add-company-popup .popup-content form .form-field .custom-checkput {
        position: relative;
        display: inline-block;
        width: auto;
        height: auto; }
        .add-company-popup .popup-content form .form-field .custom-checkput input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .add-company-popup .popup-content form .form-field .custom-checkput .checkput-icon {
          position: relative;
          display: inline-block;
          width: 18px;
          height: 18px;
          border: 2px solid #8494a9;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          border-radius: 3px;
          cursor: pointer; }
        .add-company-popup .popup-content form .form-field .custom-checkput.checked .checkput-icon {
          position: relative;
          display: inline-block;
          width: 22px;
          height: 22px;
          background: #81bc52;
          border: 0;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          border-radius: 3px; }
          .add-company-popup .popup-content form .form-field .custom-checkput.checked .checkput-icon::after {
            content: "";
            width: 16px;
            height: 12px;
            background: url(images/icon-check-white.png) no-repeat center center;
            display: inline-block;
            background-size: contain;
            position: absolute;
            top: 7px;
            left: 4px;
            width: 13px;
            height: 9px; }
      .add-company-popup .popup-content form .form-field.field-type-checkbox .custom-checkput, .add-company-popup .popup-content form .form-field.field-type-agreement .custom-checkput, .add-company-popup .popup-content form .form-field.field-type-license-agreement .custom-checkput {
        float: left; }
      .add-company-popup .popup-content form .form-field.field-type-checkbox .form-label, .add-company-popup .popup-content form .form-field.field-type-agreement .form-label, .add-company-popup .popup-content form .form-field.field-type-license-agreement .form-label {
        margin-left: 34px;
        cursor: pointer; }
      .add-company-popup .popup-content form .form-field.field-type-agreement button, .add-company-popup .popup-content form .form-field.field-type-license-agreement button {
        display: block;
        margin-left: 34px;
        height: auto;
        line-height: 22px;
        font-size: 17px;
        color: #8494a9;
        text-decoration: underline;
        background: none; }
      .add-company-popup .popup-content form .form-field .form-field-password-generator button {
        color: #2f80ed;
        cursor: pointer;
        background: transparent; }
      .add-company-popup .popup-content form .form-field .form-field-password-check {
        line-height: 16px;
        font-size: 12px;
        position: relative;
        margin-top: -2px;
        z-index: 1; }
        .add-company-popup .popup-content form .form-field .form-field-password-check.active-red {
          width: 30%;
          border-top: 2px solid #F35E84;
          margin-bottom: 11px; }
        .add-company-popup .popup-content form .form-field .form-field-password-check.active-green {
          width: 100%;
          border-top: 2px solid #5A9C76;
          margin-bottom: 11px; }
        .add-company-popup .popup-content form .form-field .form-field-password-check.active-yellow {
          width: 70%;
          border-top: 2px solid #C99300;
          margin-bottom: 11px; }
        .add-company-popup .popup-content form .form-field .form-field-password-check .password-check-hint-green {
          color: #5A9C76; }
        .add-company-popup .popup-content form .form-field .form-field-password-check .password-check-hint-yellow {
          color: #C99300; }
        .add-company-popup .popup-content form .form-field .form-field-password-check .password-check-hint-red {
          color: #F35E84; }

@media screen and (max-width: 500px) {
  .add-company-popup .popup-content form .form-field {
    margin-bottom: 28px; }
    .add-company-popup .popup-content form .form-field .form-label {
      font-size: 14px;
      line-height: 14px; } }
      .add-company-popup .popup-content form .form-field .form-button {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        width: 100%; }
        .add-company-popup .popup-content form .form-field .form-button .text,
        .add-company-popup .popup-content form .form-field .form-button .icon {
          display: inline-block;
          vertical-align: top; }
        .add-company-popup .popup-content form .form-field .form-button .icon {
          margin-right: 0; }
        .add-company-popup .popup-content form .form-field .form-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .add-company-popup .popup-content form .form-field .form-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .add-company-popup .popup-content form .form-field .form-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .add-company-popup .popup-content form .form-field .form-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-company-popup .popup-content form .form-field .form-button {
    font-weight: normal; } }
      .add-company-popup .popup-content form .form-field.field-error .form-label {
        color: #f32424; }
      .add-company-popup .popup-content form .form-field.field-error .form-control .form-input,
      .add-company-popup .popup-content form .form-field.field-error .form-control textarea,
      .add-company-popup .popup-content form .form-field.field-error .form-control select {
        border-color: #f32424; }
      .add-company-popup .popup-content form .form-field.field-error .form-field-error p {
        color: #f32424;
        white-space: pre-wrap; }
    .add-company-popup .popup-content form .form-control {
      position: relative; }
      .add-company-popup .popup-content form .form-control .form-input,
      .add-company-popup .popup-content form .form-control textarea,
      .add-company-popup .popup-content form .form-control select {
        width: 100%;
        height: 34px;
        line-height: 34px;
        padding: 0;
        background: none;
        border: 0;
        border-bottom: 1px solid #8d76f0;
        font-size: 17px;
        outline: none; }
        .add-company-popup .popup-content form .form-control .form-input::-webkit-input-placeholder,
        .add-company-popup .popup-content form .form-control textarea::-webkit-input-placeholder,
        .add-company-popup .popup-content form .form-control select::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: rgba(132, 148, 169, 0.5); }
        .add-company-popup .popup-content form .form-control .form-input:-moz-placeholder,
        .add-company-popup .popup-content form .form-control textarea:-moz-placeholder,
        .add-company-popup .popup-content form .form-control select:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .add-company-popup .popup-content form .form-control .form-input::-moz-placeholder,
        .add-company-popup .popup-content form .form-control textarea::-moz-placeholder,
        .add-company-popup .popup-content form .form-control select::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .add-company-popup .popup-content form .form-control .form-input:-ms-input-placeholder,
        .add-company-popup .popup-content form .form-control textarea:-ms-input-placeholder,
        .add-company-popup .popup-content form .form-control select:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba(132, 148, 169, 0.5); }
        .add-company-popup .popup-content form .form-control .form-input::-ms-input-placeholder,
        .add-company-popup .popup-content form .form-control textarea::-ms-input-placeholder,
        .add-company-popup .popup-content form .form-control select::-ms-input-placeholder {
          /* Microsoft Edge */
          color: rgba(132, 148, 169, 0.5); }
        .add-company-popup .popup-content form .form-control .form-input::placeholder,
        .add-company-popup .popup-content form .form-control textarea::placeholder,
        .add-company-popup .popup-content form .form-control select::placeholder {
          /* Most modern browsers support this now. */
          color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .add-company-popup .popup-content form .form-control .form-input,
  .add-company-popup .popup-content form .form-control textarea,
  .add-company-popup .popup-content form .form-control select {
    font-size: 16px; } }
      .add-company-popup .popup-content form .form-control textarea {
        resize: none;
        padding: 5px 0;
        line-height: 22px; }
      .add-company-popup .popup-content form .form-control select {
        cursor: pointer; }
      .add-company-popup .popup-content form .form-control .styled-select {
        position: relative; }
        .add-company-popup .popup-content form .form-control .styled-select select {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .add-company-popup .popup-content form .form-control .styled-select .select-button {
          position: relative;
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          width: 100%;
          height: auto;
          padding: 15px 30px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          border-radius: 8px;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
          .add-company-popup .popup-content form .form-control .styled-select .select-button .text,
          .add-company-popup .popup-content form .form-control .styled-select .select-button .icon {
            display: inline-block;
            vertical-align: top; }
          .add-company-popup .popup-content form .form-control .styled-select .select-button .icon {
            margin-right: 0; }
          .add-company-popup .popup-content form .form-control .styled-select .select-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .add-company-popup .popup-content form .form-control .styled-select .select-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .add-company-popup .popup-content form .form-control .styled-select .select-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .add-company-popup .popup-content form .form-control .styled-select .select-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-company-popup .popup-content form .form-control .styled-select .select-button {
    font-weight: normal; } }
          .add-company-popup .popup-content form .form-control .styled-select .select-button .post-icon {
            position: relative;
            top: -1px;
            left: 8px;
            display: inline-block;
            width: 0; }
            .add-company-popup .popup-content form .form-control .styled-select .select-button .post-icon:after {
              content: "";
              width: 14px;
              height: 7px;
              background: url(images/arrow-bottom.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
          .add-company-popup .popup-content form .form-control .styled-select .select-button:hover {
            background: none;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
        .add-company-popup .popup-content form .form-control .styled-select .select-list {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 1;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .add-company-popup .popup-content form .form-control .styled-select .select-list {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
          .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 15px 50px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-button .text,
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-button .icon {
              display: inline-block;
              vertical-align: top; }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-button .icon {
              margin-right: 0; }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-button {
    font-weight: normal; } }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-button.active .pre-icon {
              position: relative;
              left: -26px;
              display: inline-block;
              width: 0; }
              .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-button.active .pre-icon:after {
                content: "";
                width: 16px;
                height: 12px;
                background: url(images/icon-check.png) no-repeat center center;
                display: inline-block;
                background-size: contain; }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-button:after {
              content: "";
              display: block;
              position: absolute;
              top: 100%;
              left: 0;
              right: 0;
              height: 1px;
              background: rgba(0, 0, 0, 0.1);
              opacity: 0.5;
              filter: alpha(opacity=50);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-button:hover {
              background: #f2f5fb; }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-button:first-child:after {
              left: 23px;
              right: 23px;
              opacity: 1;
              filter: alpha(opacity=100);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-button:last-child {
              margin-bottom: 40px; }
          .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-action {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 15px 23px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-action .text,
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-action .icon {
              display: inline-block;
              vertical-align: top; }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-action .icon {
              margin-right: 0; }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-action:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-action {
    font-weight: normal; } }
            .add-company-popup .popup-content form .form-control .styled-select .select-list .select-list-action:hover {
              background: #f2f5fb; }
          .add-company-popup .popup-content form .form-control .styled-select .select-list.show {
            display: block; }
        .add-company-popup .popup-content form .form-control .styled-select.show-list .select-button {
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .add-company-popup .popup-content form .form-control .search-select {
        position: relative; }
        .add-company-popup .popup-content form .form-control .search-select select {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .add-company-popup .popup-content form .form-control .search-select .search-field {
          position: relative;
          border-bottom: 1px solid #8d76f0; }
          .add-company-popup .popup-content form .form-control .search-select .search-field input {
            width: 100%;
            height: 34px;
            line-height: 34px;
            padding: 0;
            background: none;
            border: 0;
            border-bottom: 1px solid #8d76f0;
            font-size: 17px;
            outline: none;
            width: 90%;
            border-bottom: 0; }
            .add-company-popup .popup-content form .form-control .search-select .search-field input::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: rgba(132, 148, 169, 0.5); }
            .add-company-popup .popup-content form .form-control .search-select .search-field input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .add-company-popup .popup-content form .form-control .search-select .search-field input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .add-company-popup .popup-content form .form-control .search-select .search-field input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: rgba(132, 148, 169, 0.5); }
            .add-company-popup .popup-content form .form-control .search-select .search-field input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: rgba(132, 148, 169, 0.5); }
            .add-company-popup .popup-content form .form-control .search-select .search-field input::placeholder {
              /* Most modern browsers support this now. */
              color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .add-company-popup .popup-content form .form-control .search-select .search-field input {
    font-size: 16px; } }
            .add-company-popup .popup-content form .form-control .search-select .search-field input::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: #4b4b4b; }
            .add-company-popup .popup-content form .form-control .search-select .search-field input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: #4b4b4b;
              opacity: 1; }
            .add-company-popup .popup-content form .form-control .search-select .search-field input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: #4b4b4b;
              opacity: 1; }
            .add-company-popup .popup-content form .form-control .search-select .search-field input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #4b4b4b; }
            .add-company-popup .popup-content form .form-control .search-select .search-field input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: #4b4b4b; }
            .add-company-popup .popup-content form .form-control .search-select .search-field input::placeholder {
              /* Most modern browsers support this now. */
              color: #4b4b4b; }
          .add-company-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon {
            position: absolute;
            top: 7px;
            right: 21px;
            width: 0; }
            .add-company-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon:after {
              content: "";
              width: 14px;
              height: 7px;
              background: url(images/arrow-bottom.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
        .add-company-popup .popup-content form .form-control .search-select .select-list-container {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          display: none;
          position: absolute;
          top: -17px;
          left: -17px;
          right: -17px;
          padding-top: 60px;
          padding-bottom: 17px;
          z-index: 1;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .add-company-popup .popup-content form .form-control .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
        .add-company-popup .popup-content form .form-control .search-select .select-list {
          position: relative;
          max-height: 200px;
          overflow: auto; }
          .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 10px 17px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-button .text,
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-button .icon {
              display: inline-block;
              vertical-align: top; }
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-button .icon {
              margin-right: 0; }
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-button {
    font-weight: normal; } }
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-button.active {
              background: #f2f5fb; }
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-button.selected {
              background: #f2f5fb; }
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-button:hover {
              background: #f2f5fb; }
          .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-action {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 10px 17px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-action .text,
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-action .icon {
              display: inline-block;
              vertical-align: top; }
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-action .icon {
              margin-right: 0; }
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-action:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-action {
    font-weight: normal; } }
            .add-company-popup .popup-content form .form-control .search-select .select-list .select-list-action:hover {
              background: #f2f5fb; }
        .add-company-popup .popup-content form .form-control .search-select.show-list .select-list-container {
          display: block; }
        .add-company-popup .popup-content form .form-control .search-select.show-list .search-field {
          z-index: 1;
          border-bottom-color: rgba(0, 0, 0, 0.1); }
      .add-company-popup .popup-content form .form-control .field-price-input-container {
        position: relative;
        width: 100%; }
        .add-company-popup .popup-content form .form-control .field-price-input-container .unit-box {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          text-align: center;
          white-space: nowrap; }
          .add-company-popup .popup-content form .form-control .field-price-input-container .unit-box span {
            display: inline-block; }
          .add-company-popup .popup-content form .form-control .field-price-input-container .unit-box .unit-background-text {
            color: transparent;
            font-size: 38px;
            max-width: 95%;
            overflow: hidden; }
          .add-company-popup .popup-content form .form-control .field-price-input-container .unit-box .unit-background-text-space {
            width: 24px; }
        .add-company-popup .popup-content form .form-control .field-price-input-container input {
          position: relative;
          height: 50px;
          line-height: 50px;
          box-sizing: border-box;
          text-align: center;
          font-size: 38px; }
        .add-company-popup .popup-content form .form-control .field-price-input-container .unit-ruble {
          line-height: 52px;
          font-size: 32px;
          color: #8494a9;
          opacity: 0.7;
          filter: alpha(opacity=70);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=70;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .add-company-popup .popup-content form .form-control .field-price-input-container .unit-ruble:after {
            content: "\20bd"; }
      .add-company-popup .popup-content form .form-control .colorpicker {
        position: relative; }
        .add-company-popup .popup-content form .form-control .colorpicker .picker-button {
          display: block;
          width: 105px;
          height: 30px;
          background: none;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .add-company-popup .popup-content form .form-control .colorpicker .picker-palette {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          position: absolute;
          left: 0;
          bottom: -200px;
          width: 504px;
          padding: 32px 20px;
          z-index: 4;
          -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 450px) {
  .add-company-popup .popup-content form .form-control .colorpicker .picker-palette {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
          .add-company-popup .popup-content form .form-control .colorpicker .picker-palette .palette-header {
            margin-bottom: 40px;
            margin-left: 10px;
            font-size: 22px; }
          .add-company-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-buttons {
            margin-bottom: 12px; }
          .add-company-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin: 0 12px 24px 12px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            border-radius: 50%; }
            .add-company-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button.active, .add-company-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button:hover {
              margin: -10px 2px 14px 2px;
              width: 80px;
              height: 80px;
              border: 10px solid #f2f5fb; }
          .add-company-popup .popup-content form .form-control .colorpicker .picker-palette .palette-corner-close-button {
            position: absolute;
            top: 0;
            right: 0;
            width: 80px;
            height: 80px;
            vertical-align: middle;
            text-align: center;
            font-size: 38px;
            font-family: "times new roman", verdana;
            background: none; }
          .add-company-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons {
            text-align: right; }
            .add-company-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              background: none;
              border: 1px solid #4b4b4b;
              color: #4b4b4b;
              font-size: 16px;
              min-width: 191px; }
              .add-company-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
              .add-company-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                display: inline-block;
                vertical-align: top; }
              .add-company-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                margin-right: 0; }
              .add-company-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .add-company-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .add-company-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .add-company-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-company-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
    font-weight: normal; } }
              .add-company-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                background: #f2f5fb; }
        .add-company-popup .popup-content form .form-control .colorpicker .picker-palette-overlay {
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.7);
          z-index: 2; }
      .add-company-popup .popup-content form .form-control .form-autocomplete-input {
        position: relative; }
        .add-company-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          max-height: 300px;
          background: #fff;
          -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          z-index: 1;
          overflow: auto; }
          .add-company-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button {
            background: none;
            border: none;
            width: 100%;
            padding: 8px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            text-align: left; }
            .add-company-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover {
              background: #f2f5fb; }
            .add-company-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child {
              border-bottom: 0; }
      .add-company-popup .popup-content form .form-control .field-text-list-container .text-list-item {
        position: relative;
        margin-bottom: 10px; }
        .add-company-popup .popup-content form .form-control .field-text-list-container .text-list-item .remove-text-item {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b;
          position: absolute;
          top: 0;
          right: 0;
          color: #f32424;
          font-size: 22px; }
          .add-company-popup .popup-content form .form-control .field-text-list-container .text-list-item .remove-text-item:hover {
            color: black; }
      .add-company-popup .popup-content form .form-control .field-text-list-container .add-text-item {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .add-company-popup .popup-content form .form-control .field-text-list-container .add-text-item:hover {
          color: black; }
      .add-company-popup .popup-content form .form-control .files-loader-container .files-loader-input-container {
        margin-bottom: 20px; }
      .add-company-popup .popup-content form .form-control .files-loader-input-container {
        position: relative; }
        .add-company-popup .popup-content form .form-control .files-loader-input-container .input-label-area {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          background: none;
          border: 1px solid #4b4b4b;
          color: #4b4b4b;
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px;
          width: 100%;
          height: 60px;
          line-height: 60px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          border-radius: 8px; }
          .add-company-popup .popup-content form .form-control .files-loader-input-container .input-label-area .text,
          .add-company-popup .popup-content form .form-control .files-loader-input-container .input-label-area .icon {
            display: inline-block;
            vertical-align: top; }
          .add-company-popup .popup-content form .form-control .files-loader-input-container .input-label-area .icon {
            margin-right: 0; }
          .add-company-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .add-company-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .add-company-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .add-company-popup .popup-content form .form-control .files-loader-input-container .input-label-area:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-company-popup .popup-content form .form-control .files-loader-input-container .input-label-area {
    font-weight: normal; } }
          .add-company-popup .popup-content form .form-control .files-loader-input-container .input-label-area:hover {
            background: #f2f5fb; }
        .add-company-popup .popup-content form .form-control .files-loader-input-container input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .add-company-popup .popup-content form .form-control .files-loader-input-container:hover .input-label-area {
          background: #f2f5fb; }
      .add-company-popup .popup-content form .form-control .files-loader-list .files-list-item {
        display: flex;
        justify-content: space-between;
        text-align: left;
        margin-bottom: 10px; }
        .add-company-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
          .add-company-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.icon {
            width: 38px;
            flex-shrink: 0; }
          .add-company-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.caption {
            margin-right: auto;
            margin-left: 18px; }
          .add-company-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.price {
            flex-shrink: 0;
            text-align: right; }
          .add-company-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col .note {
            font-size: 12px;
            color: #8494a9; }
        .add-company-popup .popup-content form .form-control .files-loader-list .files-list-item .icon {
          display: inline-block;
          width: 38px;
          height: 38px;
          line-height: 37px;
          text-align: center;
          font-size: 22px;
          font-weight: 500;
          color: #fff;
          background: #bed6f9;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%;
          font-size: 12px;
          font-weight: normal; }
          .add-company-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.icon-sub-correction {
            line-height: 34.2px; }
          .add-company-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-1 {
            background: #bed6f9; }
          .add-company-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-2 {
            background: #b9e398; }
          .add-company-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-3 {
            background: #e4e597; }
          .add-company-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-4 {
            background: #e7c740; }
          .add-company-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-5 {
            background: #e74040; }
          .add-company-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-6 {
            background: #cf91af; }
        .add-company-popup .popup-content form .form-control .files-loader-list .files-list-item .caption {
          height: 38px;
          line-height: 38px;
          margin-left: 12px; }
        .add-company-popup .popup-content form .form-control .files-loader-list .files-list-item .remove {
          flex-shrink: 0;
          margin-left: 0; }
          .add-company-popup .popup-content form .form-control .files-loader-list .files-list-item .remove button {
            background: none;
            border: none;
            height: 38px;
            line-height: 38px;
            font-size: 22px;
            color: #f32424; }
      .add-company-popup .popup-content form .form-control .select-container .note {
        position: relative;
        padding-left: 25px;
        padding-top: 6px;
        font-size: 14px;
        color: #8494a9;
        line-height: 14px; }
        .add-company-popup .popup-content form .form-control .select-container .note:before {
          content: "i";
          position: absolute;
          top: 5px;
          left: 0;
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-right: 10px;
          line-height: 14px;
          border: 1px solid #8494a9;
          border-radius: 50%;
          text-align: center; }
      .add-company-popup .popup-content form .form-control .input-phone {
        display: flex; }
        .add-company-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select {
          position: inherit; }
          .add-company-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-button {
            padding: 0;
            width: 80px;
            text-align: left;
            box-shadow: none;
            line-height: 34px;
            border-bottom: 1px solid #8d76f0;
            border-radius: 0; }
            .add-company-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after {
              width: 8px; }
          .add-company-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-list {
            top: 100%;
            width: 100%;
            max-height: 200px;
            margin-top: 10px;
            overflow: auto; }
            .add-company-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button {
              text-align: left; }
          .add-company-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select select {
            width: 80px; }
          .add-company-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select.show-list .select-button {
            opacity: 1;
            filter: alpha(opacity=100);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .add-company-popup .popup-content form .form-control .input-phone .phone-number {
          flex: 1; }
      .add-company-popup .popup-content form .form-control .password-input {
        position: relative; }
        .add-company-popup .popup-content form .form-control .password-input .eye-icon {
          font-size: 18px;
          background-color: transparent;
          color: #8494a9;
          position: absolute;
          right: 0; }

@media screen and (max-width: 500px) {
  .add-company-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon {
    top: 3px; } }
      .add-company-popup .popup-content form .form-control .datepicker-popup {
        position: absolute;
        top: 100%;
        left: 0; }
    .add-company-popup .popup-content form .form-errors-list {
      margin-bottom: 24px; }
      .add-company-popup .popup-content form .form-errors-list .error-item {
        margin-bottom: 8px;
        color: #f32424; }
        .add-company-popup .popup-content form .form-errors-list .error-item:last-child {
          margin-bottom: 0; }
    .add-company-popup .popup-content form .form-messages-list {
      margin-bottom: 24px;
      text-align: center; }
      .add-company-popup .popup-content form .form-messages-list .message-item {
        margin-bottom: 8px;
        color: #4dbd7d;
        font-size: 17px;
        line-height: 22px; }
        .add-company-popup .popup-content form .form-messages-list .message-item:last-child {
          margin-bottom: 0; }
  .add-company-popup.show .popup-container {
    animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

/* MESSAGES POPUP */
/* ERRORS POPUP */
.messages-popup,
.errors-popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(255, 255, 255, 0.7);
  animation: popup-background-fade-in 0.8s ease; }
  .messages-popup .popup-container,
  .errors-popup .popup-container {
    position: relative;
    display: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    display: block;
    width: 550px;
    margin: 0 auto;
    padding-bottom: 58px;
    top: 10%;
    max-height: 75%;
    overflow: auto;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    /* Scrollbar styles */ }
    .messages-popup .popup-container.show,
    .errors-popup .popup-container.show {
      display: block; }
    .messages-popup .popup-container .close-button,
    .errors-popup .popup-container .close-button {
      width: 19px;
      height: 19px;
      background: url(images/icon-close.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: absolute;
      top: 25px;
      right: 25px; }

@media screen and (max-width: 500px) {
  .messages-popup .popup-container,
  .errors-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .messages-popup .popup-container,
  .errors-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .messages-popup .popup-container .close-button,
    .errors-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
    .messages-popup .popup-container::-webkit-scrollbar,
    .errors-popup .popup-container::-webkit-scrollbar {
      width: 12px;
      height: 12px; }
    .messages-popup .popup-container::-webkit-scrollbar-track,
    .errors-popup .popup-container::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 10px; }
    .messages-popup .popup-container::-webkit-scrollbar-thumb,
    .errors-popup .popup-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #ccc; }
    .messages-popup .popup-container .popup-content form .form-control .datepicker-popup,
    .errors-popup .popup-container .popup-content form .form-control .datepicker-popup {
      position: fixed;
      top: 50%;
      left: 50%;
      margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .messages-popup .popup-container .popup-content form .form-control .datepicker-popup,
  .errors-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
    .messages-popup .popup-container .popup-content .popup-actions,
    .errors-popup .popup-container .popup-content .popup-actions {
      width: 340px;
      margin: 0 auto; }
      .messages-popup .popup-container .popup-content .popup-actions button,
      .errors-popup .popup-container .popup-content .popup-actions button {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .messages-popup .popup-container .popup-content .popup-actions button:hover,
        .errors-popup .popup-container .popup-content .popup-actions button:hover {
          color: black; }
  .messages-popup .header,
  .errors-popup .header {
    padding: 32px 24px;
    font-size: 22px; }
  .messages-popup .body,
  .errors-popup .body {
    padding: 0 24px 32px 24px; }
  .messages-popup.show,
  .errors-popup.show {
    display: block; }

@media screen and (max-width: 600px) {
  .messages-popup .popup-container,
  .errors-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .messages-popup .popup-container .header,
    .errors-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .messages-popup .popup-container .header,
  .errors-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
  .messages-popup .popup-content,
  .errors-popup .popup-content {
    padding: 54px 64px 0 64px; }
    .messages-popup .popup-content .form-messages-list .message-item,
    .messages-popup .popup-content .form-messages-list .error-item,
    .messages-popup .popup-content .form-errors-list .message-item,
    .messages-popup .popup-content .form-errors-list .error-item,
    .errors-popup .popup-content .form-messages-list .message-item,
    .errors-popup .popup-content .form-messages-list .error-item,
    .errors-popup .popup-content .form-errors-list .message-item,
    .errors-popup .popup-content .form-errors-list .error-item {
      font-size: 17px; }
    .messages-popup .popup-content .form-messages-list .message-item,
    .messages-popup .popup-content .form-errors-list .message-item,
    .errors-popup .popup-content .form-messages-list .message-item,
    .errors-popup .popup-content .form-errors-list .message-item {
      color: #4dbd7d;
      font-weight: 500; }
    .messages-popup .popup-content .form-messages-list .error-item,
    .messages-popup .popup-content .form-errors-list .error-item,
    .errors-popup .popup-content .form-messages-list .error-item,
    .errors-popup .popup-content .form-errors-list .error-item {
      color: #f32424; }
  .messages-popup.show .popup-container,
  .errors-popup.show .popup-container {
    animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

/* CABINET COMPANY */
.cabinet-company .company-title-container {
  margin-bottom: 24px; }
  .cabinet-company .company-title-container .action-edit {
    position: relative;
    top: 5px;
    width: 22px;
    height: 22px;
    background: url(images/icon-edit-gray.png) no-repeat center center;
    display: inline-block;
    background-size: contain; }
  .cabinet-company .company-title-container .edit-actions-popup {
    position: relative;
    display: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    position: fixed;
    display: block;
    padding: 40px 80px 20px 60px; }
    .cabinet-company .company-title-container .edit-actions-popup.show {
      display: block; }
    .cabinet-company .company-title-container .edit-actions-popup .close-button {
      width: 19px;
      height: 19px;
      background: url(images/icon-close.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: absolute;
      top: 25px;
      right: 25px; }

@media screen and (max-width: 500px) {
  .cabinet-company .company-title-container .edit-actions-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .cabinet-company .company-title-container .edit-actions-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .cabinet-company .company-title-container .edit-actions-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
    .cabinet-company .company-title-container .edit-actions-popup .edit-action {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b;
      display: block;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 17px;
      white-space: nowrap; }
      .cabinet-company .company-title-container .edit-actions-popup .edit-action:hover {
        color: black; }

@media screen and (max-width: 500px) {
  .cabinet-company .company-title-container .edit-actions-popup {
    padding: 20px 40px 20px 20px; }
    .cabinet-company .company-title-container .edit-actions-popup .edit-action {
      font-size: 14px;
      font-weight: normal;
      line-height: 17px;
      margin-bottom: 10px;
      text-align: left; }
      .cabinet-company .company-title-container .edit-actions-popup .edit-action:last-child {
        margin-bottom: 0; }
    .cabinet-company .company-title-container .edit-actions-popup .close-button {
      top: 10px;
      right: 10px; } }

@media screen and (max-width: 450px) {
  .cabinet-company .company-title-container .edit-actions-popup {
    padding-top: 60px; }
    .cabinet-company .company-title-container .edit-actions-popup .edit-action {
      font-size: 17px;
      line-height: 22px; }
    .cabinet-company .company-title-container .edit-actions-popup .close-button {
      top: 32px;
      right: auto; } }
  .cabinet-company .company-title-container .caption-editor .form-field {
    margin-bottom: 38px;
    width: 100%; }
    .cabinet-company .company-title-container .caption-editor .form-field .form-label {
      display: block;
      font-size: 17px;
      color: #8494a9; }
    .cabinet-company .company-title-container .caption-editor .form-field.field-type-colorpicker .form-label {
      margin-bottom: 8px; }
    .cabinet-company .company-title-container .caption-editor .form-field.field-type-files .form-label {
      display: none; }
    .cabinet-company .company-title-container .caption-editor .form-field .custom-checkput {
      position: relative;
      display: inline-block;
      width: auto;
      height: auto; }
      .cabinet-company .company-title-container .caption-editor .form-field .custom-checkput input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-company .company-title-container .caption-editor .form-field .custom-checkput .checkput-icon {
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 2px solid #8494a9;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
        cursor: pointer; }
      .cabinet-company .company-title-container .caption-editor .form-field .custom-checkput.checked .checkput-icon {
        position: relative;
        display: inline-block;
        width: 22px;
        height: 22px;
        background: #81bc52;
        border: 0;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px; }
        .cabinet-company .company-title-container .caption-editor .form-field .custom-checkput.checked .checkput-icon::after {
          content: "";
          width: 16px;
          height: 12px;
          background: url(images/icon-check-white.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          position: absolute;
          top: 7px;
          left: 4px;
          width: 13px;
          height: 9px; }
    .cabinet-company .company-title-container .caption-editor .form-field.field-type-checkbox .custom-checkput, .cabinet-company .company-title-container .caption-editor .form-field.field-type-agreement .custom-checkput, .cabinet-company .company-title-container .caption-editor .form-field.field-type-license-agreement .custom-checkput {
      float: left; }
    .cabinet-company .company-title-container .caption-editor .form-field.field-type-checkbox .form-label, .cabinet-company .company-title-container .caption-editor .form-field.field-type-agreement .form-label, .cabinet-company .company-title-container .caption-editor .form-field.field-type-license-agreement .form-label {
      margin-left: 34px;
      cursor: pointer; }
    .cabinet-company .company-title-container .caption-editor .form-field.field-type-agreement button, .cabinet-company .company-title-container .caption-editor .form-field.field-type-license-agreement button {
      display: block;
      margin-left: 34px;
      height: auto;
      line-height: 22px;
      font-size: 17px;
      color: #8494a9;
      text-decoration: underline;
      background: none; }
    .cabinet-company .company-title-container .caption-editor .form-field .form-field-password-generator button {
      color: #2f80ed;
      cursor: pointer;
      background: transparent; }
    .cabinet-company .company-title-container .caption-editor .form-field .form-field-password-check {
      line-height: 16px;
      font-size: 12px;
      position: relative;
      margin-top: -2px;
      z-index: 1; }
      .cabinet-company .company-title-container .caption-editor .form-field .form-field-password-check.active-red {
        width: 30%;
        border-top: 2px solid #F35E84;
        margin-bottom: 11px; }
      .cabinet-company .company-title-container .caption-editor .form-field .form-field-password-check.active-green {
        width: 100%;
        border-top: 2px solid #5A9C76;
        margin-bottom: 11px; }
      .cabinet-company .company-title-container .caption-editor .form-field .form-field-password-check.active-yellow {
        width: 70%;
        border-top: 2px solid #C99300;
        margin-bottom: 11px; }
      .cabinet-company .company-title-container .caption-editor .form-field .form-field-password-check .password-check-hint-green {
        color: #5A9C76; }
      .cabinet-company .company-title-container .caption-editor .form-field .form-field-password-check .password-check-hint-yellow {
        color: #C99300; }
      .cabinet-company .company-title-container .caption-editor .form-field .form-field-password-check .password-check-hint-red {
        color: #F35E84; }

@media screen and (max-width: 500px) {
  .cabinet-company .company-title-container .caption-editor .form-field {
    margin-bottom: 28px; }
    .cabinet-company .company-title-container .caption-editor .form-field .form-label {
      font-size: 14px;
      line-height: 14px; } }
    .cabinet-company .company-title-container .caption-editor .form-field .form-button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      width: 100%; }
      .cabinet-company .company-title-container .caption-editor .form-field .form-button .text,
      .cabinet-company .company-title-container .caption-editor .form-field .form-button .icon {
        display: inline-block;
        vertical-align: top; }
      .cabinet-company .company-title-container .caption-editor .form-field .form-button .icon {
        margin-right: 0; }
      .cabinet-company .company-title-container .caption-editor .form-field .form-button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .cabinet-company .company-title-container .caption-editor .form-field .form-button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .cabinet-company .company-title-container .caption-editor .form-field .form-button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .cabinet-company .company-title-container .caption-editor .form-field .form-button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-title-container .caption-editor .form-field .form-button {
    font-weight: normal; } }
    .cabinet-company .company-title-container .caption-editor .form-field.field-error .form-label {
      color: #f32424; }
    .cabinet-company .company-title-container .caption-editor .form-field.field-error .form-control .form-input,
    .cabinet-company .company-title-container .caption-editor .form-field.field-error .form-control textarea,
    .cabinet-company .company-title-container .caption-editor .form-field.field-error .form-control select {
      border-color: #f32424; }
    .cabinet-company .company-title-container .caption-editor .form-field.field-error .form-field-error p {
      color: #f32424;
      white-space: pre-wrap; }
  .cabinet-company .company-title-container .caption-editor .form-control {
    position: relative; }
    .cabinet-company .company-title-container .caption-editor .form-control .form-input,
    .cabinet-company .company-title-container .caption-editor .form-control textarea,
    .cabinet-company .company-title-container .caption-editor .form-control select {
      width: 100%;
      height: 34px;
      line-height: 34px;
      padding: 0;
      background: none;
      border: 0;
      border-bottom: 1px solid #8d76f0;
      font-size: 17px;
      outline: none; }
      .cabinet-company .company-title-container .caption-editor .form-control .form-input::-webkit-input-placeholder,
      .cabinet-company .company-title-container .caption-editor .form-control textarea::-webkit-input-placeholder,
      .cabinet-company .company-title-container .caption-editor .form-control select::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: rgba(132, 148, 169, 0.5); }
      .cabinet-company .company-title-container .caption-editor .form-control .form-input:-moz-placeholder,
      .cabinet-company .company-title-container .caption-editor .form-control textarea:-moz-placeholder,
      .cabinet-company .company-title-container .caption-editor .form-control select:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .cabinet-company .company-title-container .caption-editor .form-control .form-input::-moz-placeholder,
      .cabinet-company .company-title-container .caption-editor .form-control textarea::-moz-placeholder,
      .cabinet-company .company-title-container .caption-editor .form-control select::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .cabinet-company .company-title-container .caption-editor .form-control .form-input:-ms-input-placeholder,
      .cabinet-company .company-title-container .caption-editor .form-control textarea:-ms-input-placeholder,
      .cabinet-company .company-title-container .caption-editor .form-control select:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(132, 148, 169, 0.5); }
      .cabinet-company .company-title-container .caption-editor .form-control .form-input::-ms-input-placeholder,
      .cabinet-company .company-title-container .caption-editor .form-control textarea::-ms-input-placeholder,
      .cabinet-company .company-title-container .caption-editor .form-control select::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(132, 148, 169, 0.5); }
      .cabinet-company .company-title-container .caption-editor .form-control .form-input::placeholder,
      .cabinet-company .company-title-container .caption-editor .form-control textarea::placeholder,
      .cabinet-company .company-title-container .caption-editor .form-control select::placeholder {
        /* Most modern browsers support this now. */
        color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .cabinet-company .company-title-container .caption-editor .form-control .form-input,
  .cabinet-company .company-title-container .caption-editor .form-control textarea,
  .cabinet-company .company-title-container .caption-editor .form-control select {
    font-size: 16px; } }
    .cabinet-company .company-title-container .caption-editor .form-control textarea {
      resize: none;
      padding: 5px 0;
      line-height: 22px; }
    .cabinet-company .company-title-container .caption-editor .form-control select {
      cursor: pointer; }
    .cabinet-company .company-title-container .caption-editor .form-control .styled-select {
      position: relative; }
      .cabinet-company .company-title-container .caption-editor .form-control .styled-select select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-button {
        position: relative;
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        width: 100%;
        height: auto;
        padding: 15px 30px;
        line-height: 20px;
        background: none;
        color: #4b4b4b;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        border-radius: 8px;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
        .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-button .text,
        .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-button .icon {
          display: inline-block;
          vertical-align: top; }
        .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-button .icon {
          margin-right: 0; }
        .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-button {
    font-weight: normal; } }
        .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-button .post-icon {
          position: relative;
          top: -1px;
          left: 8px;
          display: inline-block;
          width: 0; }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-button .post-icon:after {
            content: "";
            width: 14px;
            height: 7px;
            background: url(images/arrow-bottom.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
        .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-button:hover {
          background: none;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
      .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list {
        display: block;
        background: #fff;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        -webkit-transition: box-shadow 0.1s ease;
        -moz-transition: box-shadow 0.1s ease;
        -ms-transition: box-shadow 0.1s ease;
        -o-transition: box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease;
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
        .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 15px 50px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-button .text,
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-button .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-button .icon {
            margin-right: 0; }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-button {
    font-weight: normal; } }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-button.active .pre-icon {
            position: relative;
            left: -26px;
            display: inline-block;
            width: 0; }
            .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-button.active .pre-icon:after {
              content: "";
              width: 16px;
              height: 12px;
              background: url(images/icon-check.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-button:after {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 1px;
            background: rgba(0, 0, 0, 0.1);
            opacity: 0.5;
            filter: alpha(opacity=50);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-button:hover {
            background: #f2f5fb; }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-button:first-child:after {
            left: 23px;
            right: 23px;
            opacity: 1;
            filter: alpha(opacity=100);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-button:last-child {
            margin-bottom: 40px; }
        .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-action {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 15px 23px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          text-align: left;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-action .text,
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-action .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-action .icon {
            margin-right: 0; }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-action.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-action.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-action.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-action:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-action {
    font-weight: normal; } }
          .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list .select-list-action:hover {
            background: #f2f5fb; }
        .cabinet-company .company-title-container .caption-editor .form-control .styled-select .select-list.show {
          display: block; }
      .cabinet-company .company-title-container .caption-editor .form-control .styled-select.show-list .select-button {
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
    .cabinet-company .company-title-container .caption-editor .form-control .search-select {
      position: relative; }
      .cabinet-company .company-title-container .caption-editor .form-control .search-select select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field {
        position: relative;
        border-bottom: 1px solid #8d76f0; }
        .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field input {
          width: 100%;
          height: 34px;
          line-height: 34px;
          padding: 0;
          background: none;
          border: 0;
          border-bottom: 1px solid #8d76f0;
          font-size: 17px;
          outline: none;
          width: 90%;
          border-bottom: 0; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field input::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: rgba(132, 148, 169, 0.5); }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(132, 148, 169, 0.5); }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(132, 148, 169, 0.5); }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field input::placeholder {
            /* Most modern browsers support this now. */
            color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field input {
    font-size: 16px; } }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field input::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: #4b4b4b; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: #4b4b4b;
            opacity: 1; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #4b4b4b;
            opacity: 1; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #4b4b4b; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #4b4b4b; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field input::placeholder {
            /* Most modern browsers support this now. */
            color: #4b4b4b; }
        .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field .field-dropdown-icon {
          position: absolute;
          top: 7px;
          right: 21px;
          width: 0; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field .field-dropdown-icon:after {
            content: "";
            width: 14px;
            height: 7px;
            background: url(images/arrow-bottom.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
      .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list-container {
        display: block;
        background: #fff;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        -webkit-transition: box-shadow 0.1s ease;
        -moz-transition: box-shadow 0.1s ease;
        -ms-transition: box-shadow 0.1s ease;
        -o-transition: box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease;
        display: none;
        position: absolute;
        top: -17px;
        left: -17px;
        right: -17px;
        padding-top: 60px;
        padding-bottom: 17px;
        z-index: 1;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
      .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list {
        position: relative;
        max-height: 200px;
        overflow: auto; }
        .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 10px 17px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          text-align: left;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-button .text,
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-button .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-button .icon {
            margin-right: 0; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-button {
    font-weight: normal; } }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-button.active {
            background: #f2f5fb; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-button.selected {
            background: #f2f5fb; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-button:hover {
            background: #f2f5fb; }
        .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-action {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 10px 17px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          text-align: left;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-action .text,
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-action .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-action .icon {
            margin-right: 0; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-action.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-action.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-action.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-action:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-action {
    font-weight: normal; } }
          .cabinet-company .company-title-container .caption-editor .form-control .search-select .select-list .select-list-action:hover {
            background: #f2f5fb; }
      .cabinet-company .company-title-container .caption-editor .form-control .search-select.show-list .select-list-container {
        display: block; }
      .cabinet-company .company-title-container .caption-editor .form-control .search-select.show-list .search-field {
        z-index: 1;
        border-bottom-color: rgba(0, 0, 0, 0.1); }
    .cabinet-company .company-title-container .caption-editor .form-control .field-price-input-container {
      position: relative;
      width: 100%; }
      .cabinet-company .company-title-container .caption-editor .form-control .field-price-input-container .unit-box {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        text-align: center;
        white-space: nowrap; }
        .cabinet-company .company-title-container .caption-editor .form-control .field-price-input-container .unit-box span {
          display: inline-block; }
        .cabinet-company .company-title-container .caption-editor .form-control .field-price-input-container .unit-box .unit-background-text {
          color: transparent;
          font-size: 38px;
          max-width: 95%;
          overflow: hidden; }
        .cabinet-company .company-title-container .caption-editor .form-control .field-price-input-container .unit-box .unit-background-text-space {
          width: 24px; }
      .cabinet-company .company-title-container .caption-editor .form-control .field-price-input-container input {
        position: relative;
        height: 50px;
        line-height: 50px;
        box-sizing: border-box;
        text-align: center;
        font-size: 38px; }
      .cabinet-company .company-title-container .caption-editor .form-control .field-price-input-container .unit-ruble {
        line-height: 52px;
        font-size: 32px;
        color: #8494a9;
        opacity: 0.7;
        filter: alpha(opacity=70);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=70;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .cabinet-company .company-title-container .caption-editor .form-control .field-price-input-container .unit-ruble:after {
          content: "\20bd"; }
    .cabinet-company .company-title-container .caption-editor .form-control .colorpicker {
      position: relative; }
      .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-button {
        display: block;
        width: 105px;
        height: 30px;
        background: none;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette {
        display: block;
        background: #fff;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        -webkit-transition: box-shadow 0.1s ease;
        -moz-transition: box-shadow 0.1s ease;
        -ms-transition: box-shadow 0.1s ease;
        -o-transition: box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease;
        position: absolute;
        left: 0;
        bottom: -200px;
        width: 504px;
        padding: 32px 20px;
        z-index: 4;
        -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
        .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette .palette-header {
          margin-bottom: 40px;
          margin-left: 10px;
          font-size: 22px; }
        .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette .palette-color-buttons {
          margin-bottom: 12px; }
        .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette .palette-color-button {
          display: inline-block;
          width: 60px;
          height: 60px;
          margin: 0 12px 24px 12px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%; }
          .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette .palette-color-button.active, .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette .palette-color-button:hover {
            margin: -10px 2px 14px 2px;
            width: 80px;
            height: 80px;
            border: 10px solid #f2f5fb; }
        .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette .palette-corner-close-button {
          position: absolute;
          top: 0;
          right: 0;
          width: 80px;
          height: 80px;
          vertical-align: middle;
          text-align: center;
          font-size: 38px;
          font-family: "times new roman", verdana;
          background: none; }
        .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette .palette-bottom-buttons {
          text-align: right; }
          .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            background: none;
            border: 1px solid #4b4b4b;
            color: #4b4b4b;
            font-size: 16px;
            min-width: 191px; }
            .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
            .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
              display: inline-block;
              vertical-align: top; }
            .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
              margin-right: 0; }
            .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
    font-weight: normal; } }
            .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
              background: #f2f5fb; }
      .cabinet-company .company-title-container .caption-editor .form-control .colorpicker .picker-palette-overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7);
        z-index: 2; }
    .cabinet-company .company-title-container .caption-editor .form-control .form-autocomplete-input {
      position: relative; }
      .cabinet-company .company-title-container .caption-editor .form-control .form-autocomplete-input .form-autocomplete-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 300px;
        background: #fff;
        -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        -o-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        z-index: 1;
        overflow: auto; }
        .cabinet-company .company-title-container .caption-editor .form-control .form-autocomplete-input .form-autocomplete-dropdown button {
          background: none;
          border: none;
          width: 100%;
          padding: 8px 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          text-align: left; }
          .cabinet-company .company-title-container .caption-editor .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover {
            background: #f2f5fb; }
          .cabinet-company .company-title-container .caption-editor .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child {
            border-bottom: 0; }
    .cabinet-company .company-title-container .caption-editor .form-control .field-text-list-container .text-list-item {
      position: relative;
      margin-bottom: 10px; }
      .cabinet-company .company-title-container .caption-editor .form-control .field-text-list-container .text-list-item .remove-text-item {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b;
        position: absolute;
        top: 0;
        right: 0;
        color: #f32424;
        font-size: 22px; }
        .cabinet-company .company-title-container .caption-editor .form-control .field-text-list-container .text-list-item .remove-text-item:hover {
          color: black; }
    .cabinet-company .company-title-container .caption-editor .form-control .field-text-list-container .add-text-item {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b; }
      .cabinet-company .company-title-container .caption-editor .form-control .field-text-list-container .add-text-item:hover {
        color: black; }
    .cabinet-company .company-title-container .caption-editor .form-control .files-loader-container .files-loader-input-container {
      margin-bottom: 20px; }
    .cabinet-company .company-title-container .caption-editor .form-control .files-loader-input-container {
      position: relative; }
      .cabinet-company .company-title-container .caption-editor .form-control .files-loader-input-container .input-label-area {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        background: none;
        border: 1px solid #4b4b4b;
        color: #4b4b4b;
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px;
        width: 100%;
        height: 60px;
        line-height: 60px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        border-radius: 8px; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-input-container .input-label-area .text,
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-input-container .input-label-area .icon {
          display: inline-block;
          vertical-align: top; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-input-container .input-label-area .icon {
          margin-right: 0; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-input-container .input-label-area.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-input-container .input-label-area.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-input-container .input-label-area.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-input-container .input-label-area:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-title-container .caption-editor .form-control .files-loader-input-container .input-label-area {
    font-weight: normal; } }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-input-container .input-label-area:hover {
          background: #f2f5fb; }
      .cabinet-company .company-title-container .caption-editor .form-control .files-loader-input-container input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-company .company-title-container .caption-editor .form-control .files-loader-input-container:hover .input-label-area {
        background: #f2f5fb; }
    .cabinet-company .company-title-container .caption-editor .form-control .files-loader-list .files-list-item {
      display: flex;
      justify-content: space-between;
      text-align: left;
      margin-bottom: 10px; }
      .cabinet-company .company-title-container .caption-editor .form-control .files-loader-list .files-list-item .list-item-col {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-list .files-list-item .list-item-col.icon {
          width: 38px;
          flex-shrink: 0; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-list .files-list-item .list-item-col.caption {
          margin-right: auto;
          margin-left: 18px; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-list .files-list-item .list-item-col.price {
          flex-shrink: 0;
          text-align: right; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-list .files-list-item .list-item-col .note {
          font-size: 12px;
          color: #8494a9; }
      .cabinet-company .company-title-container .caption-editor .form-control .files-loader-list .files-list-item .icon {
        display: inline-block;
        width: 38px;
        height: 38px;
        line-height: 37px;
        text-align: center;
        font-size: 22px;
        font-weight: 500;
        color: #fff;
        background: #bed6f9;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
        font-size: 12px;
        font-weight: normal; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-list .files-list-item .icon.icon-sub-correction {
          line-height: 34.2px; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-list .files-list-item .icon.color-1 {
          background: #bed6f9; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-list .files-list-item .icon.color-2 {
          background: #b9e398; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-list .files-list-item .icon.color-3 {
          background: #e4e597; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-list .files-list-item .icon.color-4 {
          background: #e7c740; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-list .files-list-item .icon.color-5 {
          background: #e74040; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-list .files-list-item .icon.color-6 {
          background: #cf91af; }
      .cabinet-company .company-title-container .caption-editor .form-control .files-loader-list .files-list-item .caption {
        height: 38px;
        line-height: 38px;
        margin-left: 12px; }
      .cabinet-company .company-title-container .caption-editor .form-control .files-loader-list .files-list-item .remove {
        flex-shrink: 0;
        margin-left: 0; }
        .cabinet-company .company-title-container .caption-editor .form-control .files-loader-list .files-list-item .remove button {
          background: none;
          border: none;
          height: 38px;
          line-height: 38px;
          font-size: 22px;
          color: #f32424; }
    .cabinet-company .company-title-container .caption-editor .form-control .select-container .note {
      position: relative;
      padding-left: 25px;
      padding-top: 6px;
      font-size: 14px;
      color: #8494a9;
      line-height: 14px; }
      .cabinet-company .company-title-container .caption-editor .form-control .select-container .note:before {
        content: "i";
        position: absolute;
        top: 5px;
        left: 0;
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 10px;
        line-height: 14px;
        border: 1px solid #8494a9;
        border-radius: 50%;
        text-align: center; }
    .cabinet-company .company-title-container .caption-editor .form-control .input-phone {
      display: flex; }
      .cabinet-company .company-title-container .caption-editor .form-control .input-phone .phone-country-code .styled-select {
        position: inherit; }
        .cabinet-company .company-title-container .caption-editor .form-control .input-phone .phone-country-code .styled-select .select-button {
          padding: 0;
          width: 80px;
          text-align: left;
          box-shadow: none;
          line-height: 34px;
          border-bottom: 1px solid #8d76f0;
          border-radius: 0; }
          .cabinet-company .company-title-container .caption-editor .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after {
            width: 8px; }
        .cabinet-company .company-title-container .caption-editor .form-control .input-phone .phone-country-code .styled-select .select-list {
          top: 100%;
          width: 100%;
          max-height: 200px;
          margin-top: 10px;
          overflow: auto; }
          .cabinet-company .company-title-container .caption-editor .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button {
            text-align: left; }
        .cabinet-company .company-title-container .caption-editor .form-control .input-phone .phone-country-code .styled-select select {
          width: 80px; }
        .cabinet-company .company-title-container .caption-editor .form-control .input-phone .phone-country-code .styled-select.show-list .select-button {
          opacity: 1;
          filter: alpha(opacity=100);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-company .company-title-container .caption-editor .form-control .input-phone .phone-number {
        flex: 1; }
    .cabinet-company .company-title-container .caption-editor .form-control .password-input {
      position: relative; }
      .cabinet-company .company-title-container .caption-editor .form-control .password-input .eye-icon {
        font-size: 18px;
        background-color: transparent;
        color: #8494a9;
        position: absolute;
        right: 0; }

@media screen and (max-width: 500px) {
  .cabinet-company .company-title-container .caption-editor .form-control .search-select .search-field .field-dropdown-icon {
    top: 3px; } }
    .cabinet-company .company-title-container .caption-editor .form-control .datepicker-popup {
      position: absolute;
      top: 100%;
      left: 0; }
  .cabinet-company .company-title-container .caption-editor .form-errors-list {
    margin-bottom: 24px; }
    .cabinet-company .company-title-container .caption-editor .form-errors-list .error-item {
      margin-bottom: 8px;
      color: #f32424; }
      .cabinet-company .company-title-container .caption-editor .form-errors-list .error-item:last-child {
        margin-bottom: 0; }
  .cabinet-company .company-title-container .caption-editor .form-messages-list {
    margin-bottom: 24px;
    text-align: center; }
    .cabinet-company .company-title-container .caption-editor .form-messages-list .message-item {
      margin-bottom: 8px;
      color: #4dbd7d;
      font-size: 17px;
      line-height: 22px; }
      .cabinet-company .company-title-container .caption-editor .form-messages-list .message-item:last-child {
        margin-bottom: 0; }
  .cabinet-company .company-title-container .caption-editor label {
    display: none; }
  .cabinet-company .company-title-container .caption-editor .form-fieldset {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end; }
    .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field {
      flex: 1;
      margin: 0; }
      .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-text {
        flex: 2;
        margin-right: 16px; }
      .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-submit, .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-button {
        margin-left: 8px; }
      .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-button button {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        background: none;
        border: 1px solid #4b4b4b;
        color: #4b4b4b; }
        .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-button button .text,
        .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-button button .icon {
          display: inline-block;
          vertical-align: top; }
        .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-button button .icon {
          margin-right: 0; }
        .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-button button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-button button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-button button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-button button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-button button {
    font-weight: normal; } }
        .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-button button:hover {
          background: #f2f5fb; }
    .cabinet-company .company-title-container .caption-editor .form-fieldset .form-control .form-input {
      height: 50px;
      line-height: 50%;
      font-size: 17px; }

.cabinet-company .company-title {
  font-size: 22px;
  font-weight: 500;
  display: inline;
  margin-right: 8px; }

.cabinet-company .body-tabs {
  padding: 0 20px;
  margin-bottom: 1px;
  background: #fff; }
  .cabinet-company .body-tabs .tab {
    height: 56px;
    line-height: 56px;
    padding: 0 16px;
    color: #4b4b4b;
    background: none; }
    .cabinet-company .body-tabs .tab:hover {
      background: #f2f5fb; }
    .cabinet-company .body-tabs .tab.active {
      color: #4b4b4b;
      text-shadow: 0px 0px 1px #4b4b4b; }

.cabinet-company .body-tab-content {
  padding: 20px 14px;
  background: #fff; }
  .cabinet-company .body-tab-content .tab-action {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    margin-bottom: 24px;
    margin-left: 28px;
    padding: 0 45px; }
    .cabinet-company .body-tab-content .tab-action .text,
    .cabinet-company .body-tab-content .tab-action .icon {
      display: inline-block;
      vertical-align: top; }
    .cabinet-company .body-tab-content .tab-action .icon {
      margin-right: 0; }
    .cabinet-company .body-tab-content .tab-action.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .cabinet-company .body-tab-content .tab-action.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .cabinet-company .body-tab-content .tab-action.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .cabinet-company .body-tab-content .tab-action:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .body-tab-content .tab-action {
    font-weight: normal; } }
    .cabinet-company .body-tab-content .tab-action.download {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: none;
      border: 1px solid #4b4b4b;
      color: #4b4b4b;
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
      .cabinet-company .body-tab-content .tab-action.download .text,
      .cabinet-company .body-tab-content .tab-action.download .icon {
        display: inline-block;
        vertical-align: top; }
      .cabinet-company .body-tab-content .tab-action.download .icon {
        margin-right: 0; }
      .cabinet-company .body-tab-content .tab-action.download.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .cabinet-company .body-tab-content .tab-action.download.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .cabinet-company .body-tab-content .tab-action.download.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .cabinet-company .body-tab-content .tab-action.download:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .body-tab-content .tab-action.download {
    font-weight: normal; } }
      .cabinet-company .body-tab-content .tab-action.download:hover {
        background: #f2f5fb; }
  .cabinet-company .body-tab-content .confirm-popup {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background: rgba(255, 255, 255, 0.7);
    animation: popup-background-fade-in 0.8s ease; }
    .cabinet-company .body-tab-content .confirm-popup .popup-container {
      position: relative;
      display: none;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 20;
      display: block;
      width: 550px;
      margin: 0 auto;
      padding-bottom: 58px;
      top: 10%;
      max-height: 75%;
      overflow: auto;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      border-radius: 8px;
      /* Scrollbar styles */ }
      .cabinet-company .body-tab-content .confirm-popup .popup-container.show {
        display: block; }
      .cabinet-company .body-tab-content .confirm-popup .popup-container .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 25px; }

@media screen and (max-width: 500px) {
  .cabinet-company .body-tab-content .confirm-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .cabinet-company .body-tab-content .confirm-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .cabinet-company .body-tab-content .confirm-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
      .cabinet-company .body-tab-content .confirm-popup .popup-container::-webkit-scrollbar {
        width: 12px;
        height: 12px; }
      .cabinet-company .body-tab-content .confirm-popup .popup-container::-webkit-scrollbar-track {
        background: #f5f5f5;
        border-radius: 10px; }
      .cabinet-company .body-tab-content .confirm-popup .popup-container::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #ccc; }
      .cabinet-company .body-tab-content .confirm-popup .popup-container .popup-content form .form-control .datepicker-popup {
        position: fixed;
        top: 50%;
        left: 50%;
        margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .cabinet-company .body-tab-content .confirm-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
      .cabinet-company .body-tab-content .confirm-popup .popup-container .popup-content .popup-actions {
        width: 340px;
        margin: 0 auto; }
        .cabinet-company .body-tab-content .confirm-popup .popup-container .popup-content .popup-actions button {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b; }
          .cabinet-company .body-tab-content .confirm-popup .popup-container .popup-content .popup-actions button:hover {
            color: black; }
    .cabinet-company .body-tab-content .confirm-popup .header {
      padding: 32px 24px;
      font-size: 22px; }
    .cabinet-company .body-tab-content .confirm-popup .body {
      padding: 0 24px 32px 24px; }
    .cabinet-company .body-tab-content .confirm-popup.show {
      display: block; }

@media screen and (max-width: 600px) {
  .cabinet-company .body-tab-content .confirm-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .cabinet-company .body-tab-content .confirm-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .cabinet-company .body-tab-content .confirm-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
    .cabinet-company .body-tab-content .confirm-popup .popup-container {
      text-align: center;
      padding-bottom: 0; }
    .cabinet-company .body-tab-content .confirm-popup .popup-content {
      padding: 0 64px 52px 64px; }
      .cabinet-company .body-tab-content .confirm-popup .popup-content .header {
        padding: 64px 0 56px 0; }
      .cabinet-company .body-tab-content .confirm-popup .popup-content .body {
        margin-bottom: 32px; }
      .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset {
        display: flex; }
        .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field {
          flex: 1;
          margin: 0 12px; }
          .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            background: none;
            border: 1px solid #4b4b4b;
            color: #4b4b4b;
            width: 100%;
            font-size: 16px; }
            .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button .text,
            .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
              display: inline-block;
              vertical-align: top; }
            .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
              margin-right: 0; }
            .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button {
    font-weight: normal; } }
            .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
              background: #f2f5fb; }
          .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            width: 100%;
            font-size: 16px; }
            .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
            .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
              display: inline-block;
              vertical-align: top; }
            .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
              margin-right: 0; }
            .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
    .cabinet-company .body-tab-content .confirm-popup.show .popup-container {
      animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

@media screen and (max-width: 450px) {
  .cabinet-company .body-tab-content .confirm-popup .popup-content {
    padding-right: 32px;
    padding-left: 32px; }
    .cabinet-company .body-tab-content .confirm-popup .popup-content .header {
      padding: 32px 0 28px 0; }
    .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .cabinet-company .body-tab-content .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; } }
  .cabinet-company .body-tab-content .confirm-delete-popup {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background: rgba(255, 255, 255, 0.7);
    animation: popup-background-fade-in 0.8s ease; }
    .cabinet-company .body-tab-content .confirm-delete-popup .popup-container {
      position: relative;
      display: none;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 20;
      display: block;
      width: 550px;
      margin: 0 auto;
      padding-bottom: 58px;
      top: 10%;
      max-height: 75%;
      overflow: auto;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      border-radius: 8px;
      /* Scrollbar styles */ }
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-container.show {
        display: block; }
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-container .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 25px; }

@media screen and (max-width: 500px) {
  .cabinet-company .body-tab-content .confirm-delete-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .cabinet-company .body-tab-content .confirm-delete-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .cabinet-company .body-tab-content .confirm-delete-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-container::-webkit-scrollbar {
        width: 12px;
        height: 12px; }
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-container::-webkit-scrollbar-track {
        background: #f5f5f5;
        border-radius: 10px; }
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-container::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #ccc; }
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-container .popup-content form .form-control .datepicker-popup {
        position: fixed;
        top: 50%;
        left: 50%;
        margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .cabinet-company .body-tab-content .confirm-delete-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-container .popup-content .popup-actions {
        width: 340px;
        margin: 0 auto; }
        .cabinet-company .body-tab-content .confirm-delete-popup .popup-container .popup-content .popup-actions button {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b; }
          .cabinet-company .body-tab-content .confirm-delete-popup .popup-container .popup-content .popup-actions button:hover {
            color: black; }
    .cabinet-company .body-tab-content .confirm-delete-popup .header {
      padding: 32px 24px;
      font-size: 22px; }
    .cabinet-company .body-tab-content .confirm-delete-popup .body {
      padding: 0 24px 32px 24px; }
    .cabinet-company .body-tab-content .confirm-delete-popup.show {
      display: block; }

@media screen and (max-width: 600px) {
  .cabinet-company .body-tab-content .confirm-delete-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .cabinet-company .body-tab-content .confirm-delete-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .cabinet-company .body-tab-content .confirm-delete-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
    .cabinet-company .body-tab-content .confirm-delete-popup .popup-container {
      text-align: center;
      padding-bottom: 0; }
    .cabinet-company .body-tab-content .confirm-delete-popup .popup-content {
      padding: 0 64px 52px 64px; }
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .header {
        padding: 64px 0 56px 0; }
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .body {
        margin-bottom: 32px; }
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset {
        display: flex; }
        .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field {
          flex: 1;
          margin: 0 12px; }
          .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            background: none;
            border: 1px solid #4b4b4b;
            color: #4b4b4b;
            width: 100%;
            font-size: 16px; }
            .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .text,
            .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
              display: inline-block;
              vertical-align: top; }
            .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
              margin-right: 0; }
            .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
    font-weight: normal; } }
            .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
              background: #f2f5fb; }
          .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            width: 100%;
            font-size: 16px; }
            .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
            .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
              display: inline-block;
              vertical-align: top; }
            .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
              margin-right: 0; }
            .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
    .cabinet-company .body-tab-content .confirm-delete-popup.show .popup-container {
      animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

@media screen and (max-width: 450px) {
  .cabinet-company .body-tab-content .confirm-delete-popup .popup-content {
    padding-right: 32px;
    padding-left: 32px; }
    .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .header {
      padding: 32px 0 28px 0; }
    .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; } }
    .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: #f35e84;
      width: 100%;
      font-size: 16px; }
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
        display: inline-block;
        vertical-align: top; }
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
        margin-right: 0; }
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
      .cabinet-company .body-tab-content .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
        background: #e64871; }
  .cabinet-company .body-tab-content.accounts .company-tab-table .list-item:not(.list-headers) {
    cursor: pointer; }
    .cabinet-company .body-tab-content.accounts .company-tab-table .list-item:not(.list-headers):hover {
      background: #f2f5fb; }
  .cabinet-company .body-tab-content .company-tab-form {
    margin-left: 28px; }

.cabinet-company .company-tab-form {
  max-width: 348px; }
  .cabinet-company .company-tab-form .tab-form-label {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 500; }
  .cabinet-company .company-tab-form .tab-form-label-container .tab-form-label {
    display: inline-block;
    margin-right: 20px; }
  .cabinet-company .company-tab-form .tab-form-label-container .form-alter-action {
    background: none;
    border: none;
    cursor: pointer;
    color: #4b4b4b; }
    .cabinet-company .company-tab-form .tab-form-label-container .form-alter-action:hover {
      color: black; }
  .cabinet-company .company-tab-form form .form-field {
    margin-bottom: 38px;
    width: 100%; }
    .cabinet-company .company-tab-form form .form-field .form-label {
      display: block;
      font-size: 17px;
      color: #8494a9; }
    .cabinet-company .company-tab-form form .form-field.field-type-colorpicker .form-label {
      margin-bottom: 8px; }
    .cabinet-company .company-tab-form form .form-field.field-type-files .form-label {
      display: none; }
    .cabinet-company .company-tab-form form .form-field .custom-checkput {
      position: relative;
      display: inline-block;
      width: auto;
      height: auto; }
      .cabinet-company .company-tab-form form .form-field .custom-checkput input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-company .company-tab-form form .form-field .custom-checkput .checkput-icon {
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 2px solid #8494a9;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
        cursor: pointer; }
      .cabinet-company .company-tab-form form .form-field .custom-checkput.checked .checkput-icon {
        position: relative;
        display: inline-block;
        width: 22px;
        height: 22px;
        background: #81bc52;
        border: 0;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px; }
        .cabinet-company .company-tab-form form .form-field .custom-checkput.checked .checkput-icon::after {
          content: "";
          width: 16px;
          height: 12px;
          background: url(images/icon-check-white.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          position: absolute;
          top: 7px;
          left: 4px;
          width: 13px;
          height: 9px; }
    .cabinet-company .company-tab-form form .form-field.field-type-checkbox .custom-checkput, .cabinet-company .company-tab-form form .form-field.field-type-agreement .custom-checkput, .cabinet-company .company-tab-form form .form-field.field-type-license-agreement .custom-checkput {
      float: left; }
    .cabinet-company .company-tab-form form .form-field.field-type-checkbox .form-label, .cabinet-company .company-tab-form form .form-field.field-type-agreement .form-label, .cabinet-company .company-tab-form form .form-field.field-type-license-agreement .form-label {
      margin-left: 34px;
      cursor: pointer; }
    .cabinet-company .company-tab-form form .form-field.field-type-agreement button, .cabinet-company .company-tab-form form .form-field.field-type-license-agreement button {
      display: block;
      margin-left: 34px;
      height: auto;
      line-height: 22px;
      font-size: 17px;
      color: #8494a9;
      text-decoration: underline;
      background: none; }
    .cabinet-company .company-tab-form form .form-field .form-field-password-generator button {
      color: #2f80ed;
      cursor: pointer;
      background: transparent; }
    .cabinet-company .company-tab-form form .form-field .form-field-password-check {
      line-height: 16px;
      font-size: 12px;
      position: relative;
      margin-top: -2px;
      z-index: 1; }
      .cabinet-company .company-tab-form form .form-field .form-field-password-check.active-red {
        width: 30%;
        border-top: 2px solid #F35E84;
        margin-bottom: 11px; }
      .cabinet-company .company-tab-form form .form-field .form-field-password-check.active-green {
        width: 100%;
        border-top: 2px solid #5A9C76;
        margin-bottom: 11px; }
      .cabinet-company .company-tab-form form .form-field .form-field-password-check.active-yellow {
        width: 70%;
        border-top: 2px solid #C99300;
        margin-bottom: 11px; }
      .cabinet-company .company-tab-form form .form-field .form-field-password-check .password-check-hint-green {
        color: #5A9C76; }
      .cabinet-company .company-tab-form form .form-field .form-field-password-check .password-check-hint-yellow {
        color: #C99300; }
      .cabinet-company .company-tab-form form .form-field .form-field-password-check .password-check-hint-red {
        color: #F35E84; }

@media screen and (max-width: 500px) {
  .cabinet-company .company-tab-form form .form-field {
    margin-bottom: 28px; }
    .cabinet-company .company-tab-form form .form-field .form-label {
      font-size: 14px;
      line-height: 14px; } }
    .cabinet-company .company-tab-form form .form-field .form-button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      width: 100%; }
      .cabinet-company .company-tab-form form .form-field .form-button .text,
      .cabinet-company .company-tab-form form .form-field .form-button .icon {
        display: inline-block;
        vertical-align: top; }
      .cabinet-company .company-tab-form form .form-field .form-button .icon {
        margin-right: 0; }
      .cabinet-company .company-tab-form form .form-field .form-button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .cabinet-company .company-tab-form form .form-field .form-button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .cabinet-company .company-tab-form form .form-field .form-button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .cabinet-company .company-tab-form form .form-field .form-button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-tab-form form .form-field .form-button {
    font-weight: normal; } }
    .cabinet-company .company-tab-form form .form-field.field-error .form-label {
      color: #f32424; }
    .cabinet-company .company-tab-form form .form-field.field-error .form-control .form-input,
    .cabinet-company .company-tab-form form .form-field.field-error .form-control textarea,
    .cabinet-company .company-tab-form form .form-field.field-error .form-control select {
      border-color: #f32424; }
    .cabinet-company .company-tab-form form .form-field.field-error .form-field-error p {
      color: #f32424;
      white-space: pre-wrap; }
  .cabinet-company .company-tab-form form .form-control {
    position: relative; }
    .cabinet-company .company-tab-form form .form-control .form-input,
    .cabinet-company .company-tab-form form .form-control textarea,
    .cabinet-company .company-tab-form form .form-control select {
      width: 100%;
      height: 34px;
      line-height: 34px;
      padding: 0;
      background: none;
      border: 0;
      border-bottom: 1px solid #8d76f0;
      font-size: 17px;
      outline: none; }
      .cabinet-company .company-tab-form form .form-control .form-input::-webkit-input-placeholder,
      .cabinet-company .company-tab-form form .form-control textarea::-webkit-input-placeholder,
      .cabinet-company .company-tab-form form .form-control select::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: rgba(132, 148, 169, 0.5); }
      .cabinet-company .company-tab-form form .form-control .form-input:-moz-placeholder,
      .cabinet-company .company-tab-form form .form-control textarea:-moz-placeholder,
      .cabinet-company .company-tab-form form .form-control select:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .cabinet-company .company-tab-form form .form-control .form-input::-moz-placeholder,
      .cabinet-company .company-tab-form form .form-control textarea::-moz-placeholder,
      .cabinet-company .company-tab-form form .form-control select::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .cabinet-company .company-tab-form form .form-control .form-input:-ms-input-placeholder,
      .cabinet-company .company-tab-form form .form-control textarea:-ms-input-placeholder,
      .cabinet-company .company-tab-form form .form-control select:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(132, 148, 169, 0.5); }
      .cabinet-company .company-tab-form form .form-control .form-input::-ms-input-placeholder,
      .cabinet-company .company-tab-form form .form-control textarea::-ms-input-placeholder,
      .cabinet-company .company-tab-form form .form-control select::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(132, 148, 169, 0.5); }
      .cabinet-company .company-tab-form form .form-control .form-input::placeholder,
      .cabinet-company .company-tab-form form .form-control textarea::placeholder,
      .cabinet-company .company-tab-form form .form-control select::placeholder {
        /* Most modern browsers support this now. */
        color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .cabinet-company .company-tab-form form .form-control .form-input,
  .cabinet-company .company-tab-form form .form-control textarea,
  .cabinet-company .company-tab-form form .form-control select {
    font-size: 16px; } }
    .cabinet-company .company-tab-form form .form-control textarea {
      resize: none;
      padding: 5px 0;
      line-height: 22px; }
    .cabinet-company .company-tab-form form .form-control select {
      cursor: pointer; }
    .cabinet-company .company-tab-form form .form-control .styled-select {
      position: relative; }
      .cabinet-company .company-tab-form form .form-control .styled-select select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-company .company-tab-form form .form-control .styled-select .select-button {
        position: relative;
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        width: 100%;
        height: auto;
        padding: 15px 30px;
        line-height: 20px;
        background: none;
        color: #4b4b4b;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        border-radius: 8px;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
        .cabinet-company .company-tab-form form .form-control .styled-select .select-button .text,
        .cabinet-company .company-tab-form form .form-control .styled-select .select-button .icon {
          display: inline-block;
          vertical-align: top; }
        .cabinet-company .company-tab-form form .form-control .styled-select .select-button .icon {
          margin-right: 0; }
        .cabinet-company .company-tab-form form .form-control .styled-select .select-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .cabinet-company .company-tab-form form .form-control .styled-select .select-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .cabinet-company .company-tab-form form .form-control .styled-select .select-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .cabinet-company .company-tab-form form .form-control .styled-select .select-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-tab-form form .form-control .styled-select .select-button {
    font-weight: normal; } }
        .cabinet-company .company-tab-form form .form-control .styled-select .select-button .post-icon {
          position: relative;
          top: -1px;
          left: 8px;
          display: inline-block;
          width: 0; }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-button .post-icon:after {
            content: "";
            width: 14px;
            height: 7px;
            background: url(images/arrow-bottom.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
        .cabinet-company .company-tab-form form .form-control .styled-select .select-button:hover {
          background: none;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
      .cabinet-company .company-tab-form form .form-control .styled-select .select-list {
        display: block;
        background: #fff;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        -webkit-transition: box-shadow 0.1s ease;
        -moz-transition: box-shadow 0.1s ease;
        -ms-transition: box-shadow 0.1s ease;
        -o-transition: box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease;
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-tab-form form .form-control .styled-select .select-list {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
        .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 15px 50px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-button .text,
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-button .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-button .icon {
            margin-right: 0; }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-button {
    font-weight: normal; } }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-button.active .pre-icon {
            position: relative;
            left: -26px;
            display: inline-block;
            width: 0; }
            .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-button.active .pre-icon:after {
              content: "";
              width: 16px;
              height: 12px;
              background: url(images/icon-check.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-button:after {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 1px;
            background: rgba(0, 0, 0, 0.1);
            opacity: 0.5;
            filter: alpha(opacity=50);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-button:hover {
            background: #f2f5fb; }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-button:first-child:after {
            left: 23px;
            right: 23px;
            opacity: 1;
            filter: alpha(opacity=100);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-button:last-child {
            margin-bottom: 40px; }
        .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-action {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 15px 23px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          text-align: left;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-action .text,
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-action .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-action .icon {
            margin-right: 0; }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-action.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-action.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-action.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-action:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-action {
    font-weight: normal; } }
          .cabinet-company .company-tab-form form .form-control .styled-select .select-list .select-list-action:hover {
            background: #f2f5fb; }
        .cabinet-company .company-tab-form form .form-control .styled-select .select-list.show {
          display: block; }
      .cabinet-company .company-tab-form form .form-control .styled-select.show-list .select-button {
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
    .cabinet-company .company-tab-form form .form-control .search-select {
      position: relative; }
      .cabinet-company .company-tab-form form .form-control .search-select select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-company .company-tab-form form .form-control .search-select .search-field {
        position: relative;
        border-bottom: 1px solid #8d76f0; }
        .cabinet-company .company-tab-form form .form-control .search-select .search-field input {
          width: 100%;
          height: 34px;
          line-height: 34px;
          padding: 0;
          background: none;
          border: 0;
          border-bottom: 1px solid #8d76f0;
          font-size: 17px;
          outline: none;
          width: 90%;
          border-bottom: 0; }
          .cabinet-company .company-tab-form form .form-control .search-select .search-field input::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: rgba(132, 148, 169, 0.5); }
          .cabinet-company .company-tab-form form .form-control .search-select .search-field input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .cabinet-company .company-tab-form form .form-control .search-select .search-field input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .cabinet-company .company-tab-form form .form-control .search-select .search-field input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(132, 148, 169, 0.5); }
          .cabinet-company .company-tab-form form .form-control .search-select .search-field input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(132, 148, 169, 0.5); }
          .cabinet-company .company-tab-form form .form-control .search-select .search-field input::placeholder {
            /* Most modern browsers support this now. */
            color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .cabinet-company .company-tab-form form .form-control .search-select .search-field input {
    font-size: 16px; } }
          .cabinet-company .company-tab-form form .form-control .search-select .search-field input::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: #4b4b4b; }
          .cabinet-company .company-tab-form form .form-control .search-select .search-field input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: #4b4b4b;
            opacity: 1; }
          .cabinet-company .company-tab-form form .form-control .search-select .search-field input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #4b4b4b;
            opacity: 1; }
          .cabinet-company .company-tab-form form .form-control .search-select .search-field input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #4b4b4b; }
          .cabinet-company .company-tab-form form .form-control .search-select .search-field input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #4b4b4b; }
          .cabinet-company .company-tab-form form .form-control .search-select .search-field input::placeholder {
            /* Most modern browsers support this now. */
            color: #4b4b4b; }
        .cabinet-company .company-tab-form form .form-control .search-select .search-field .field-dropdown-icon {
          position: absolute;
          top: 7px;
          right: 21px;
          width: 0; }
          .cabinet-company .company-tab-form form .form-control .search-select .search-field .field-dropdown-icon:after {
            content: "";
            width: 14px;
            height: 7px;
            background: url(images/arrow-bottom.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
      .cabinet-company .company-tab-form form .form-control .search-select .select-list-container {
        display: block;
        background: #fff;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        -webkit-transition: box-shadow 0.1s ease;
        -moz-transition: box-shadow 0.1s ease;
        -ms-transition: box-shadow 0.1s ease;
        -o-transition: box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease;
        display: none;
        position: absolute;
        top: -17px;
        left: -17px;
        right: -17px;
        padding-top: 60px;
        padding-bottom: 17px;
        z-index: 1;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-tab-form form .form-control .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
      .cabinet-company .company-tab-form form .form-control .search-select .select-list {
        position: relative;
        max-height: 200px;
        overflow: auto; }
        .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 10px 17px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          text-align: left;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-button .text,
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-button .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-button .icon {
            margin-right: 0; }
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-button {
    font-weight: normal; } }
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-button.active {
            background: #f2f5fb; }
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-button.selected {
            background: #f2f5fb; }
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-button:hover {
            background: #f2f5fb; }
        .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-action {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 10px 17px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          text-align: left;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-action .text,
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-action .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-action .icon {
            margin-right: 0; }
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-action.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-action.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-action.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-action:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-action {
    font-weight: normal; } }
          .cabinet-company .company-tab-form form .form-control .search-select .select-list .select-list-action:hover {
            background: #f2f5fb; }
      .cabinet-company .company-tab-form form .form-control .search-select.show-list .select-list-container {
        display: block; }
      .cabinet-company .company-tab-form form .form-control .search-select.show-list .search-field {
        z-index: 1;
        border-bottom-color: rgba(0, 0, 0, 0.1); }
    .cabinet-company .company-tab-form form .form-control .field-price-input-container {
      position: relative;
      width: 100%; }
      .cabinet-company .company-tab-form form .form-control .field-price-input-container .unit-box {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        text-align: center;
        white-space: nowrap; }
        .cabinet-company .company-tab-form form .form-control .field-price-input-container .unit-box span {
          display: inline-block; }
        .cabinet-company .company-tab-form form .form-control .field-price-input-container .unit-box .unit-background-text {
          color: transparent;
          font-size: 38px;
          max-width: 95%;
          overflow: hidden; }
        .cabinet-company .company-tab-form form .form-control .field-price-input-container .unit-box .unit-background-text-space {
          width: 24px; }
      .cabinet-company .company-tab-form form .form-control .field-price-input-container input {
        position: relative;
        height: 50px;
        line-height: 50px;
        box-sizing: border-box;
        text-align: center;
        font-size: 38px; }
      .cabinet-company .company-tab-form form .form-control .field-price-input-container .unit-ruble {
        line-height: 52px;
        font-size: 32px;
        color: #8494a9;
        opacity: 0.7;
        filter: alpha(opacity=70);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=70;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .cabinet-company .company-tab-form form .form-control .field-price-input-container .unit-ruble:after {
          content: "\20bd"; }
    .cabinet-company .company-tab-form form .form-control .colorpicker {
      position: relative; }
      .cabinet-company .company-tab-form form .form-control .colorpicker .picker-button {
        display: block;
        width: 105px;
        height: 30px;
        background: none;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette {
        display: block;
        background: #fff;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        -webkit-transition: box-shadow 0.1s ease;
        -moz-transition: box-shadow 0.1s ease;
        -ms-transition: box-shadow 0.1s ease;
        -o-transition: box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease;
        position: absolute;
        left: 0;
        bottom: -200px;
        width: 504px;
        padding: 32px 20px;
        z-index: 4;
        -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
        .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette .palette-header {
          margin-bottom: 40px;
          margin-left: 10px;
          font-size: 22px; }
        .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette .palette-color-buttons {
          margin-bottom: 12px; }
        .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette .palette-color-button {
          display: inline-block;
          width: 60px;
          height: 60px;
          margin: 0 12px 24px 12px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%; }
          .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette .palette-color-button.active, .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette .palette-color-button:hover {
            margin: -10px 2px 14px 2px;
            width: 80px;
            height: 80px;
            border: 10px solid #f2f5fb; }
        .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette .palette-corner-close-button {
          position: absolute;
          top: 0;
          right: 0;
          width: 80px;
          height: 80px;
          vertical-align: middle;
          text-align: center;
          font-size: 38px;
          font-family: "times new roman", verdana;
          background: none; }
        .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons {
          text-align: right; }
          .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            background: none;
            border: 1px solid #4b4b4b;
            color: #4b4b4b;
            font-size: 16px;
            min-width: 191px; }
            .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
            .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
              display: inline-block;
              vertical-align: top; }
            .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
              margin-right: 0; }
            .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
    font-weight: normal; } }
            .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
              background: #f2f5fb; }
      .cabinet-company .company-tab-form form .form-control .colorpicker .picker-palette-overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7);
        z-index: 2; }
    .cabinet-company .company-tab-form form .form-control .form-autocomplete-input {
      position: relative; }
      .cabinet-company .company-tab-form form .form-control .form-autocomplete-input .form-autocomplete-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 300px;
        background: #fff;
        -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        -o-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        z-index: 1;
        overflow: auto; }
        .cabinet-company .company-tab-form form .form-control .form-autocomplete-input .form-autocomplete-dropdown button {
          background: none;
          border: none;
          width: 100%;
          padding: 8px 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          text-align: left; }
          .cabinet-company .company-tab-form form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover {
            background: #f2f5fb; }
          .cabinet-company .company-tab-form form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child {
            border-bottom: 0; }
    .cabinet-company .company-tab-form form .form-control .field-text-list-container .text-list-item {
      position: relative;
      margin-bottom: 10px; }
      .cabinet-company .company-tab-form form .form-control .field-text-list-container .text-list-item .remove-text-item {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b;
        position: absolute;
        top: 0;
        right: 0;
        color: #f32424;
        font-size: 22px; }
        .cabinet-company .company-tab-form form .form-control .field-text-list-container .text-list-item .remove-text-item:hover {
          color: black; }
    .cabinet-company .company-tab-form form .form-control .field-text-list-container .add-text-item {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b; }
      .cabinet-company .company-tab-form form .form-control .field-text-list-container .add-text-item:hover {
        color: black; }
    .cabinet-company .company-tab-form form .form-control .files-loader-container .files-loader-input-container {
      margin-bottom: 20px; }
    .cabinet-company .company-tab-form form .form-control .files-loader-input-container {
      position: relative; }
      .cabinet-company .company-tab-form form .form-control .files-loader-input-container .input-label-area {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        background: none;
        border: 1px solid #4b4b4b;
        color: #4b4b4b;
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px;
        width: 100%;
        height: 60px;
        line-height: 60px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        border-radius: 8px; }
        .cabinet-company .company-tab-form form .form-control .files-loader-input-container .input-label-area .text,
        .cabinet-company .company-tab-form form .form-control .files-loader-input-container .input-label-area .icon {
          display: inline-block;
          vertical-align: top; }
        .cabinet-company .company-tab-form form .form-control .files-loader-input-container .input-label-area .icon {
          margin-right: 0; }
        .cabinet-company .company-tab-form form .form-control .files-loader-input-container .input-label-area.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .cabinet-company .company-tab-form form .form-control .files-loader-input-container .input-label-area.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .cabinet-company .company-tab-form form .form-control .files-loader-input-container .input-label-area.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .cabinet-company .company-tab-form form .form-control .files-loader-input-container .input-label-area:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-tab-form form .form-control .files-loader-input-container .input-label-area {
    font-weight: normal; } }
        .cabinet-company .company-tab-form form .form-control .files-loader-input-container .input-label-area:hover {
          background: #f2f5fb; }
      .cabinet-company .company-tab-form form .form-control .files-loader-input-container input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-company .company-tab-form form .form-control .files-loader-input-container:hover .input-label-area {
        background: #f2f5fb; }
    .cabinet-company .company-tab-form form .form-control .files-loader-list .files-list-item {
      display: flex;
      justify-content: space-between;
      text-align: left;
      margin-bottom: 10px; }
      .cabinet-company .company-tab-form form .form-control .files-loader-list .files-list-item .list-item-col {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        .cabinet-company .company-tab-form form .form-control .files-loader-list .files-list-item .list-item-col.icon {
          width: 38px;
          flex-shrink: 0; }
        .cabinet-company .company-tab-form form .form-control .files-loader-list .files-list-item .list-item-col.caption {
          margin-right: auto;
          margin-left: 18px; }
        .cabinet-company .company-tab-form form .form-control .files-loader-list .files-list-item .list-item-col.price {
          flex-shrink: 0;
          text-align: right; }
        .cabinet-company .company-tab-form form .form-control .files-loader-list .files-list-item .list-item-col .note {
          font-size: 12px;
          color: #8494a9; }
      .cabinet-company .company-tab-form form .form-control .files-loader-list .files-list-item .icon {
        display: inline-block;
        width: 38px;
        height: 38px;
        line-height: 37px;
        text-align: center;
        font-size: 22px;
        font-weight: 500;
        color: #fff;
        background: #bed6f9;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
        font-size: 12px;
        font-weight: normal; }
        .cabinet-company .company-tab-form form .form-control .files-loader-list .files-list-item .icon.icon-sub-correction {
          line-height: 34.2px; }
        .cabinet-company .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-1 {
          background: #bed6f9; }
        .cabinet-company .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-2 {
          background: #b9e398; }
        .cabinet-company .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-3 {
          background: #e4e597; }
        .cabinet-company .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-4 {
          background: #e7c740; }
        .cabinet-company .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-5 {
          background: #e74040; }
        .cabinet-company .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-6 {
          background: #cf91af; }
      .cabinet-company .company-tab-form form .form-control .files-loader-list .files-list-item .caption {
        height: 38px;
        line-height: 38px;
        margin-left: 12px; }
      .cabinet-company .company-tab-form form .form-control .files-loader-list .files-list-item .remove {
        flex-shrink: 0;
        margin-left: 0; }
        .cabinet-company .company-tab-form form .form-control .files-loader-list .files-list-item .remove button {
          background: none;
          border: none;
          height: 38px;
          line-height: 38px;
          font-size: 22px;
          color: #f32424; }
    .cabinet-company .company-tab-form form .form-control .select-container .note {
      position: relative;
      padding-left: 25px;
      padding-top: 6px;
      font-size: 14px;
      color: #8494a9;
      line-height: 14px; }
      .cabinet-company .company-tab-form form .form-control .select-container .note:before {
        content: "i";
        position: absolute;
        top: 5px;
        left: 0;
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 10px;
        line-height: 14px;
        border: 1px solid #8494a9;
        border-radius: 50%;
        text-align: center; }
    .cabinet-company .company-tab-form form .form-control .input-phone {
      display: flex; }
      .cabinet-company .company-tab-form form .form-control .input-phone .phone-country-code .styled-select {
        position: inherit; }
        .cabinet-company .company-tab-form form .form-control .input-phone .phone-country-code .styled-select .select-button {
          padding: 0;
          width: 80px;
          text-align: left;
          box-shadow: none;
          line-height: 34px;
          border-bottom: 1px solid #8d76f0;
          border-radius: 0; }
          .cabinet-company .company-tab-form form .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after {
            width: 8px; }
        .cabinet-company .company-tab-form form .form-control .input-phone .phone-country-code .styled-select .select-list {
          top: 100%;
          width: 100%;
          max-height: 200px;
          margin-top: 10px;
          overflow: auto; }
          .cabinet-company .company-tab-form form .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button {
            text-align: left; }
        .cabinet-company .company-tab-form form .form-control .input-phone .phone-country-code .styled-select select {
          width: 80px; }
        .cabinet-company .company-tab-form form .form-control .input-phone .phone-country-code .styled-select.show-list .select-button {
          opacity: 1;
          filter: alpha(opacity=100);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-company .company-tab-form form .form-control .input-phone .phone-number {
        flex: 1; }
    .cabinet-company .company-tab-form form .form-control .password-input {
      position: relative; }
      .cabinet-company .company-tab-form form .form-control .password-input .eye-icon {
        font-size: 18px;
        background-color: transparent;
        color: #8494a9;
        position: absolute;
        right: 0; }

@media screen and (max-width: 500px) {
  .cabinet-company .company-tab-form form .form-control .search-select .search-field .field-dropdown-icon {
    top: 3px; } }
    .cabinet-company .company-tab-form form .form-control .datepicker-popup {
      position: absolute;
      top: 100%;
      left: 0; }
  .cabinet-company .company-tab-form form .form-errors-list {
    margin-bottom: 24px; }
    .cabinet-company .company-tab-form form .form-errors-list .error-item {
      margin-bottom: 8px;
      color: #f32424; }
      .cabinet-company .company-tab-form form .form-errors-list .error-item:last-child {
        margin-bottom: 0; }
  .cabinet-company .company-tab-form form .form-messages-list {
    margin-bottom: 24px;
    text-align: center; }
    .cabinet-company .company-tab-form form .form-messages-list .message-item {
      margin-bottom: 8px;
      color: #4dbd7d;
      font-size: 17px;
      line-height: 22px; }
      .cabinet-company .company-tab-form form .form-messages-list .message-item:last-child {
        margin-bottom: 0; }
  .cabinet-company .company-tab-form form .form-field .form-label {
    font-size: 14px; }
  .cabinet-company .company-tab-form .form-cancel {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    background: none;
    border: 1px solid #4b4b4b;
    color: #4b4b4b;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    width: 100%; }
    .cabinet-company .company-tab-form .form-cancel .text,
    .cabinet-company .company-tab-form .form-cancel .icon {
      display: inline-block;
      vertical-align: top; }
    .cabinet-company .company-tab-form .form-cancel .icon {
      margin-right: 0; }
    .cabinet-company .company-tab-form .form-cancel.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .cabinet-company .company-tab-form .form-cancel.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .cabinet-company .company-tab-form .form-cancel.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .cabinet-company .company-tab-form .form-cancel:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-tab-form .form-cancel {
    font-weight: normal; } }
    .cabinet-company .company-tab-form .form-cancel:hover {
      background: #f2f5fb; }

.cabinet-company .company-tab-list .list-item {
  margin-bottom: 16px; }
  .cabinet-company .company-tab-list .list-item .label {
    display: inline;
    font-size: 16px;
    font-weight: 500;
    vertical-align: top;
    margin-right: 4px; }
  .cabinet-company .company-tab-list .list-item .action-edit {
    width: 22px;
    height: 22px;
    background: url(images/icon-edit-gray.png) no-repeat center center;
    display: inline-block;
    background-size: contain;
    vertical-align: top; }
  .cabinet-company .company-tab-list .list-item .edit-actions-popup {
    position: relative;
    display: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    position: fixed;
    display: block;
    padding: 40px 80px 20px 60px; }
    .cabinet-company .company-tab-list .list-item .edit-actions-popup.show {
      display: block; }
    .cabinet-company .company-tab-list .list-item .edit-actions-popup .close-button {
      width: 19px;
      height: 19px;
      background: url(images/icon-close.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: absolute;
      top: 25px;
      right: 25px; }

@media screen and (max-width: 500px) {
  .cabinet-company .company-tab-list .list-item .edit-actions-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .cabinet-company .company-tab-list .list-item .edit-actions-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .cabinet-company .company-tab-list .list-item .edit-actions-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
    .cabinet-company .company-tab-list .list-item .edit-actions-popup .edit-action {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b;
      display: block;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 17px;
      white-space: nowrap; }
      .cabinet-company .company-tab-list .list-item .edit-actions-popup .edit-action:hover {
        color: black; }

@media screen and (max-width: 500px) {
  .cabinet-company .company-tab-list .list-item .edit-actions-popup {
    padding: 20px 40px 20px 20px; }
    .cabinet-company .company-tab-list .list-item .edit-actions-popup .edit-action {
      font-size: 14px;
      font-weight: normal;
      line-height: 17px;
      margin-bottom: 10px;
      text-align: left; }
      .cabinet-company .company-tab-list .list-item .edit-actions-popup .edit-action:last-child {
        margin-bottom: 0; }
    .cabinet-company .company-tab-list .list-item .edit-actions-popup .close-button {
      top: 10px;
      right: 10px; } }

@media screen and (max-width: 450px) {
  .cabinet-company .company-tab-list .list-item .edit-actions-popup {
    padding-top: 60px; }
    .cabinet-company .company-tab-list .list-item .edit-actions-popup .edit-action {
      font-size: 17px;
      line-height: 22px; }
    .cabinet-company .company-tab-list .list-item .edit-actions-popup .close-button {
      top: 32px;
      right: auto; } }

.cabinet-company .company-tab-table .list-item {
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  padding: 24px 28px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box; }
  .cabinet-company .company-tab-table .list-item .list-item-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .cabinet-company .company-tab-table .list-item .list-item-col.icon {
      width: 38px;
      flex-shrink: 0; }
    .cabinet-company .company-tab-table .list-item .list-item-col.caption {
      margin-right: auto;
      margin-left: 18px; }
    .cabinet-company .company-tab-table .list-item .list-item-col.price {
      flex-shrink: 0;
      text-align: right; }
    .cabinet-company .company-tab-table .list-item .list-item-col .note {
      font-size: 12px;
      color: #8494a9; }
  .cabinet-company .company-tab-table .list-item .list-item-col {
    flex: 1;
    margin-right: 16px; }
    .cabinet-company .company-tab-table .list-item .list-item-col p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px; }
    .cabinet-company .company-tab-table .list-item .list-item-col .style-box {
      height: 30px;
      width: 68px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .cabinet-company .company-tab-table .list-item .list-item-col.label {
      flex: 3; }
      .cabinet-company .company-tab-table .list-item .list-item-col.label p {
        font-size: 17px;
        font-weight: 500;
        color: #4b4b4b; }
    .cabinet-company .company-tab-table .list-item .list-item-col.actions {
      margin-right: 0;
      text-align: right; }
    .cabinet-company .company-tab-table .list-item .list-item-col.amount {
      text-align: right; }
      .cabinet-company .company-tab-table .list-item .list-item-col.amount .price {
        font-size: 0; }
        .cabinet-company .company-tab-table .list-item .list-item-col.amount .price .int,
        .cabinet-company .company-tab-table .list-item .list-item-col.amount .price .delim {
          font-size: 22px; }
        .cabinet-company .company-tab-table .list-item .list-item-col.amount .price .float,
        .cabinet-company .company-tab-table .list-item .list-item-col.amount .price .unit {
          font-size: 17px; }
        .cabinet-company .company-tab-table .list-item .list-item-col.amount .price .unit {
          margin-left: 8px; }
        .cabinet-company .company-tab-table .list-item .list-item-col.amount .price .unit-ruble:after {
          content: "\20bd"; }
        .cabinet-company .company-tab-table .list-item .list-item-col.amount .price.income, .cabinet-company .company-tab-table .list-item .list-item-col.amount .price.transfer-income {
          color: #4dbd7d; }
          .cabinet-company .company-tab-table .list-item .list-item-col.amount .price.income .int,
          .cabinet-company .company-tab-table .list-item .list-item-col.amount .price.income .delim,
          .cabinet-company .company-tab-table .list-item .list-item-col.amount .price.income .float,
          .cabinet-company .company-tab-table .list-item .list-item-col.amount .price.income .unit, .cabinet-company .company-tab-table .list-item .list-item-col.amount .price.transfer-income .int,
          .cabinet-company .company-tab-table .list-item .list-item-col.amount .price.transfer-income .delim,
          .cabinet-company .company-tab-table .list-item .list-item-col.amount .price.transfer-income .float,
          .cabinet-company .company-tab-table .list-item .list-item-col.amount .price.transfer-income .unit {
            color: #4dbd7d; }
        .cabinet-company .company-tab-table .list-item .list-item-col.amount .price .unit {
          color: #8494a9; }
    .cabinet-company .company-tab-table .list-item .list-item-col .field-flag {
      float: right;
      margin-left: 10px;
      font-size: 12px;
      font-weight: normal;
      color: #8494a9; }
  .cabinet-company .company-tab-table .list-item .action-edit {
    width: 22px;
    height: 22px;
    background: url(images/icon-edit-gray.png) no-repeat center center;
    display: inline-block;
    background-size: contain;
    vertical-align: top; }
  .cabinet-company .company-tab-table .list-item .edit-actions-popup {
    position: relative;
    display: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    position: fixed;
    display: block;
    padding: 40px 80px 20px 60px; }
    .cabinet-company .company-tab-table .list-item .edit-actions-popup.show {
      display: block; }
    .cabinet-company .company-tab-table .list-item .edit-actions-popup .close-button {
      width: 19px;
      height: 19px;
      background: url(images/icon-close.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: absolute;
      top: 25px;
      right: 25px; }

@media screen and (max-width: 500px) {
  .cabinet-company .company-tab-table .list-item .edit-actions-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .cabinet-company .company-tab-table .list-item .edit-actions-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .cabinet-company .company-tab-table .list-item .edit-actions-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
    .cabinet-company .company-tab-table .list-item .edit-actions-popup .edit-action {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b;
      display: block;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 17px;
      white-space: nowrap; }
      .cabinet-company .company-tab-table .list-item .edit-actions-popup .edit-action:hover {
        color: black; }

@media screen and (max-width: 500px) {
  .cabinet-company .company-tab-table .list-item .edit-actions-popup {
    padding: 20px 40px 20px 20px; }
    .cabinet-company .company-tab-table .list-item .edit-actions-popup .edit-action {
      font-size: 14px;
      font-weight: normal;
      line-height: 17px;
      margin-bottom: 10px;
      text-align: left; }
      .cabinet-company .company-tab-table .list-item .edit-actions-popup .edit-action:last-child {
        margin-bottom: 0; }
    .cabinet-company .company-tab-table .list-item .edit-actions-popup .close-button {
      top: 10px;
      right: 10px; } }

@media screen and (max-width: 450px) {
  .cabinet-company .company-tab-table .list-item .edit-actions-popup {
    padding-top: 60px; }
    .cabinet-company .company-tab-table .list-item .edit-actions-popup .edit-action {
      font-size: 17px;
      line-height: 22px; }
    .cabinet-company .company-tab-table .list-item .edit-actions-popup .close-button {
      top: 32px;
      right: auto; } }
  .cabinet-company .company-tab-table .list-item.list-headers {
    border-bottom: 0;
    padding-bottom: 32px; }
    .cabinet-company .company-tab-table .list-item.list-headers .list-item-col .header-col-value {
      color: #4b4b4b;
      font-size: 17px;
      font-weight: 500; }
  .cabinet-company .company-tab-table .list-item.filter-out {
    display: none; }
  .cabinet-company .company-tab-table .list-item:last-child {
    border-bottom: 0; }

.cabinet-company .company-tab-about {
  padding: 0 20px; }
  .cabinet-company .company-tab-about .about-download-button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    background: none;
    border: 1px solid #4b4b4b;
    color: #4b4b4b;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    margin-bottom: 24px; }
    .cabinet-company .company-tab-about .about-download-button .text,
    .cabinet-company .company-tab-about .about-download-button .icon {
      display: inline-block;
      vertical-align: top; }
    .cabinet-company .company-tab-about .about-download-button .icon {
      margin-right: 0; }
    .cabinet-company .company-tab-about .about-download-button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .cabinet-company .company-tab-about .about-download-button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .cabinet-company .company-tab-about .about-download-button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .cabinet-company .company-tab-about .about-download-button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company .company-tab-about .about-download-button {
    font-weight: normal; } }
    .cabinet-company .company-tab-about .about-download-button:hover {
      background: #f2f5fb; }
  .cabinet-company .company-tab-about .about-field {
    margin-bottom: 32px; }
    .cabinet-company .company-tab-about .about-field .label {
      color: #8494a9; }
    .cabinet-company .company-tab-about .about-field .value {
      font-size: 17px; }

.cabinet-company .company-tab-item-card {
  padding: 0 36px; }
  .cabinet-company .company-tab-item-card .back-link-container {
    margin-bottom: 18px; }
  .cabinet-company .company-tab-item-card .back-link {
    background: none;
    border: none;
    cursor: pointer;
    color: #4b4b4b; }
    .cabinet-company .company-tab-item-card .back-link:hover {
      color: black; }
  .cabinet-company .company-tab-item-card .card-label-container {
    margin-bottom: 36px; }
    .cabinet-company .company-tab-item-card .card-label-container .card-label {
      display: inline;
      margin-right: 8px;
      vertical-align: middle; }
    .cabinet-company .company-tab-item-card .card-label-container .edit-action {
      width: 22px;
      height: 22px;
      background: url(images/icon-edit-gray.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: relative;
      top: 2px;
      vertical-align: middle; }
  .cabinet-company .company-tab-item-card .card-label {
    font-size: 22px;
    font-weight: 500; }
  .cabinet-company .company-tab-item-card .card-field {
    margin-bottom: 32px; }
    .cabinet-company .company-tab-item-card .card-field .label {
      color: #8494a9; }
    .cabinet-company .company-tab-item-card .card-field .value {
      font-size: 17px; }

.cabinet-company-delete-popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(255, 255, 255, 0.7);
  animation: popup-background-fade-in 0.8s ease; }
  .cabinet-company-delete-popup .popup-container {
    position: relative;
    display: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    display: block;
    width: 550px;
    margin: 0 auto;
    padding-bottom: 58px;
    top: 10%;
    max-height: 75%;
    overflow: auto;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    /* Scrollbar styles */ }
    .cabinet-company-delete-popup .popup-container.show {
      display: block; }
    .cabinet-company-delete-popup .popup-container .close-button {
      width: 19px;
      height: 19px;
      background: url(images/icon-close.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: absolute;
      top: 25px;
      right: 25px; }

@media screen and (max-width: 500px) {
  .cabinet-company-delete-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .cabinet-company-delete-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .cabinet-company-delete-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
    .cabinet-company-delete-popup .popup-container::-webkit-scrollbar {
      width: 12px;
      height: 12px; }
    .cabinet-company-delete-popup .popup-container::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 10px; }
    .cabinet-company-delete-popup .popup-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #ccc; }
    .cabinet-company-delete-popup .popup-container .popup-content form .form-control .datepicker-popup {
      position: fixed;
      top: 50%;
      left: 50%;
      margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .cabinet-company-delete-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
    .cabinet-company-delete-popup .popup-container .popup-content .popup-actions {
      width: 340px;
      margin: 0 auto; }
      .cabinet-company-delete-popup .popup-container .popup-content .popup-actions button {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .cabinet-company-delete-popup .popup-container .popup-content .popup-actions button:hover {
          color: black; }
  .cabinet-company-delete-popup .header {
    padding: 32px 24px;
    font-size: 22px; }
  .cabinet-company-delete-popup .body {
    padding: 0 24px 32px 24px; }
  .cabinet-company-delete-popup.show {
    display: block; }

@media screen and (max-width: 600px) {
  .cabinet-company-delete-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .cabinet-company-delete-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .cabinet-company-delete-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
  .cabinet-company-delete-popup .popup-container {
    text-align: center;
    padding-bottom: 0; }
  .cabinet-company-delete-popup .popup-content {
    padding: 0 64px 52px 64px; }
    .cabinet-company-delete-popup .popup-content .header {
      padding: 64px 0 56px 0; }
    .cabinet-company-delete-popup .popup-content .body {
      margin-bottom: 32px; }
    .cabinet-company-delete-popup .popup-content .form-fieldset {
      display: flex; }
      .cabinet-company-delete-popup .popup-content .form-fieldset .form-field {
        flex: 1;
        margin: 0 12px; }
        .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          background: none;
          border: 1px solid #4b4b4b;
          color: #4b4b4b;
          width: 100%;
          font-size: 16px; }
          .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .text,
          .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
            margin-right: 0; }
          .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
    font-weight: normal; } }
          .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
            background: #f2f5fb; }
        .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          width: 100%;
          font-size: 16px; }
          .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
          .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
            margin-right: 0; }
          .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
  .cabinet-company-delete-popup.show .popup-container {
    animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

@media screen and (max-width: 450px) {
  .cabinet-company-delete-popup .popup-content {
    padding-right: 32px;
    padding-left: 32px; }
    .cabinet-company-delete-popup .popup-content .header {
      padding: 32px 0 28px 0; }
    .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; } }
  .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    background: #f35e84;
    width: 100%;
    font-size: 16px; }
    .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
    .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
      display: inline-block;
      vertical-align: top; }
    .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
      margin-right: 0; }
    .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
    .cabinet-company-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
      background: #e64871; }

/* CABINET ADD COMPANY */
.cabinet-add-company-layout .layout-title {
  margin-bottom: 24px; }
  .cabinet-add-company-layout .layout-title p {
    font-size: 22px;
    font-weight: 500; }

.cabinet-add-company-layout .layout-body {
  display: block;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: box-shadow 0.1s ease;
  -moz-transition: box-shadow 0.1s ease;
  -ms-transition: box-shadow 0.1s ease;
  -o-transition: box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease;
  padding: 42px 50px; }

@media screen and (max-width: 450px) {
  .cabinet-add-company-layout .layout-body {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }

.cabinet-add-company-layout .add-company-form .subform {
  max-width: 510px;
  margin-bottom: 32px; }
  .cabinet-add-company-layout .add-company-form .subform .form-field {
    margin-bottom: 38px;
    width: 100%; }
    .cabinet-add-company-layout .add-company-form .subform .form-field .form-label {
      display: block;
      font-size: 17px;
      color: #8494a9; }
    .cabinet-add-company-layout .add-company-form .subform .form-field.field-type-colorpicker .form-label {
      margin-bottom: 8px; }
    .cabinet-add-company-layout .add-company-form .subform .form-field.field-type-files .form-label {
      display: none; }
    .cabinet-add-company-layout .add-company-form .subform .form-field .custom-checkput {
      position: relative;
      display: inline-block;
      width: auto;
      height: auto; }
      .cabinet-add-company-layout .add-company-form .subform .form-field .custom-checkput input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-add-company-layout .add-company-form .subform .form-field .custom-checkput .checkput-icon {
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 2px solid #8494a9;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
        cursor: pointer; }
      .cabinet-add-company-layout .add-company-form .subform .form-field .custom-checkput.checked .checkput-icon {
        position: relative;
        display: inline-block;
        width: 22px;
        height: 22px;
        background: #81bc52;
        border: 0;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px; }
        .cabinet-add-company-layout .add-company-form .subform .form-field .custom-checkput.checked .checkput-icon::after {
          content: "";
          width: 16px;
          height: 12px;
          background: url(images/icon-check-white.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          position: absolute;
          top: 7px;
          left: 4px;
          width: 13px;
          height: 9px; }
    .cabinet-add-company-layout .add-company-form .subform .form-field.field-type-checkbox .custom-checkput, .cabinet-add-company-layout .add-company-form .subform .form-field.field-type-agreement .custom-checkput, .cabinet-add-company-layout .add-company-form .subform .form-field.field-type-license-agreement .custom-checkput {
      float: left; }
    .cabinet-add-company-layout .add-company-form .subform .form-field.field-type-checkbox .form-label, .cabinet-add-company-layout .add-company-form .subform .form-field.field-type-agreement .form-label, .cabinet-add-company-layout .add-company-form .subform .form-field.field-type-license-agreement .form-label {
      margin-left: 34px;
      cursor: pointer; }
    .cabinet-add-company-layout .add-company-form .subform .form-field.field-type-agreement button, .cabinet-add-company-layout .add-company-form .subform .form-field.field-type-license-agreement button {
      display: block;
      margin-left: 34px;
      height: auto;
      line-height: 22px;
      font-size: 17px;
      color: #8494a9;
      text-decoration: underline;
      background: none; }
    .cabinet-add-company-layout .add-company-form .subform .form-field .form-field-password-generator button {
      color: #2f80ed;
      cursor: pointer;
      background: transparent; }
    .cabinet-add-company-layout .add-company-form .subform .form-field .form-field-password-check {
      line-height: 16px;
      font-size: 12px;
      position: relative;
      margin-top: -2px;
      z-index: 1; }
      .cabinet-add-company-layout .add-company-form .subform .form-field .form-field-password-check.active-red {
        width: 30%;
        border-top: 2px solid #F35E84;
        margin-bottom: 11px; }
      .cabinet-add-company-layout .add-company-form .subform .form-field .form-field-password-check.active-green {
        width: 100%;
        border-top: 2px solid #5A9C76;
        margin-bottom: 11px; }
      .cabinet-add-company-layout .add-company-form .subform .form-field .form-field-password-check.active-yellow {
        width: 70%;
        border-top: 2px solid #C99300;
        margin-bottom: 11px; }
      .cabinet-add-company-layout .add-company-form .subform .form-field .form-field-password-check .password-check-hint-green {
        color: #5A9C76; }
      .cabinet-add-company-layout .add-company-form .subform .form-field .form-field-password-check .password-check-hint-yellow {
        color: #C99300; }
      .cabinet-add-company-layout .add-company-form .subform .form-field .form-field-password-check .password-check-hint-red {
        color: #F35E84; }

@media screen and (max-width: 500px) {
  .cabinet-add-company-layout .add-company-form .subform .form-field {
    margin-bottom: 28px; }
    .cabinet-add-company-layout .add-company-form .subform .form-field .form-label {
      font-size: 14px;
      line-height: 14px; } }
    .cabinet-add-company-layout .add-company-form .subform .form-field .form-button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      width: 100%; }
      .cabinet-add-company-layout .add-company-form .subform .form-field .form-button .text,
      .cabinet-add-company-layout .add-company-form .subform .form-field .form-button .icon {
        display: inline-block;
        vertical-align: top; }
      .cabinet-add-company-layout .add-company-form .subform .form-field .form-button .icon {
        margin-right: 0; }
      .cabinet-add-company-layout .add-company-form .subform .form-field .form-button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .cabinet-add-company-layout .add-company-form .subform .form-field .form-button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .cabinet-add-company-layout .add-company-form .subform .form-field .form-button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .cabinet-add-company-layout .add-company-form .subform .form-field .form-button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-add-company-layout .add-company-form .subform .form-field .form-button {
    font-weight: normal; } }
    .cabinet-add-company-layout .add-company-form .subform .form-field.field-error .form-label {
      color: #f32424; }
    .cabinet-add-company-layout .add-company-form .subform .form-field.field-error .form-control .form-input,
    .cabinet-add-company-layout .add-company-form .subform .form-field.field-error .form-control textarea,
    .cabinet-add-company-layout .add-company-form .subform .form-field.field-error .form-control select {
      border-color: #f32424; }
    .cabinet-add-company-layout .add-company-form .subform .form-field.field-error .form-field-error p {
      color: #f32424;
      white-space: pre-wrap; }
  .cabinet-add-company-layout .add-company-form .subform .form-control {
    position: relative; }
    .cabinet-add-company-layout .add-company-form .subform .form-control .form-input,
    .cabinet-add-company-layout .add-company-form .subform .form-control textarea,
    .cabinet-add-company-layout .add-company-form .subform .form-control select {
      width: 100%;
      height: 34px;
      line-height: 34px;
      padding: 0;
      background: none;
      border: 0;
      border-bottom: 1px solid #8d76f0;
      font-size: 17px;
      outline: none; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .form-input::-webkit-input-placeholder,
      .cabinet-add-company-layout .add-company-form .subform .form-control textarea::-webkit-input-placeholder,
      .cabinet-add-company-layout .add-company-form .subform .form-control select::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: rgba(132, 148, 169, 0.5); }
      .cabinet-add-company-layout .add-company-form .subform .form-control .form-input:-moz-placeholder,
      .cabinet-add-company-layout .add-company-form .subform .form-control textarea:-moz-placeholder,
      .cabinet-add-company-layout .add-company-form .subform .form-control select:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .form-input::-moz-placeholder,
      .cabinet-add-company-layout .add-company-form .subform .form-control textarea::-moz-placeholder,
      .cabinet-add-company-layout .add-company-form .subform .form-control select::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .form-input:-ms-input-placeholder,
      .cabinet-add-company-layout .add-company-form .subform .form-control textarea:-ms-input-placeholder,
      .cabinet-add-company-layout .add-company-form .subform .form-control select:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(132, 148, 169, 0.5); }
      .cabinet-add-company-layout .add-company-form .subform .form-control .form-input::-ms-input-placeholder,
      .cabinet-add-company-layout .add-company-form .subform .form-control textarea::-ms-input-placeholder,
      .cabinet-add-company-layout .add-company-form .subform .form-control select::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(132, 148, 169, 0.5); }
      .cabinet-add-company-layout .add-company-form .subform .form-control .form-input::placeholder,
      .cabinet-add-company-layout .add-company-form .subform .form-control textarea::placeholder,
      .cabinet-add-company-layout .add-company-form .subform .form-control select::placeholder {
        /* Most modern browsers support this now. */
        color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .cabinet-add-company-layout .add-company-form .subform .form-control .form-input,
  .cabinet-add-company-layout .add-company-form .subform .form-control textarea,
  .cabinet-add-company-layout .add-company-form .subform .form-control select {
    font-size: 16px; } }
    .cabinet-add-company-layout .add-company-form .subform .form-control textarea {
      resize: none;
      padding: 5px 0;
      line-height: 22px; }
    .cabinet-add-company-layout .add-company-form .subform .form-control select {
      cursor: pointer; }
    .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select {
      position: relative; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-button {
        position: relative;
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        width: 100%;
        height: auto;
        padding: 15px 30px;
        line-height: 20px;
        background: none;
        color: #4b4b4b;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        border-radius: 8px;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
        .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-button .text,
        .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-button .icon {
          display: inline-block;
          vertical-align: top; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-button .icon {
          margin-right: 0; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-button {
    font-weight: normal; } }
        .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-button .post-icon {
          position: relative;
          top: -1px;
          left: 8px;
          display: inline-block;
          width: 0; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-button .post-icon:after {
            content: "";
            width: 14px;
            height: 7px;
            background: url(images/arrow-bottom.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-button:hover {
          background: none;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
      .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list {
        display: block;
        background: #fff;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        -webkit-transition: box-shadow 0.1s ease;
        -moz-transition: box-shadow 0.1s ease;
        -ms-transition: box-shadow 0.1s ease;
        -o-transition: box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease;
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
        .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 15px 50px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-button .text,
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-button .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-button .icon {
            margin-right: 0; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-button {
    font-weight: normal; } }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-button.active .pre-icon {
            position: relative;
            left: -26px;
            display: inline-block;
            width: 0; }
            .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-button.active .pre-icon:after {
              content: "";
              width: 16px;
              height: 12px;
              background: url(images/icon-check.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-button:after {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 1px;
            background: rgba(0, 0, 0, 0.1);
            opacity: 0.5;
            filter: alpha(opacity=50);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-button:hover {
            background: #f2f5fb; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-button:first-child:after {
            left: 23px;
            right: 23px;
            opacity: 1;
            filter: alpha(opacity=100);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-button:last-child {
            margin-bottom: 40px; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-action {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 15px 23px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          text-align: left;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-action .text,
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-action .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-action .icon {
            margin-right: 0; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-action.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-action.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-action.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-action:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-action {
    font-weight: normal; } }
          .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list .select-list-action:hover {
            background: #f2f5fb; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select .select-list.show {
          display: block; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .styled-select.show-list .select-button {
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
    .cabinet-add-company-layout .add-company-form .subform .form-control .search-select {
      position: relative; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .search-select select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field {
        position: relative;
        border-bottom: 1px solid #8d76f0; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field input {
          width: 100%;
          height: 34px;
          line-height: 34px;
          padding: 0;
          background: none;
          border: 0;
          border-bottom: 1px solid #8d76f0;
          font-size: 17px;
          outline: none;
          width: 90%;
          border-bottom: 0; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field input::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: rgba(132, 148, 169, 0.5); }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(132, 148, 169, 0.5); }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(132, 148, 169, 0.5); }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field input::placeholder {
            /* Most modern browsers support this now. */
            color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field input {
    font-size: 16px; } }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field input::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: #4b4b4b; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: #4b4b4b;
            opacity: 1; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #4b4b4b;
            opacity: 1; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #4b4b4b; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #4b4b4b; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field input::placeholder {
            /* Most modern browsers support this now. */
            color: #4b4b4b; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field .field-dropdown-icon {
          position: absolute;
          top: 7px;
          right: 21px;
          width: 0; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field .field-dropdown-icon:after {
            content: "";
            width: 14px;
            height: 7px;
            background: url(images/arrow-bottom.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list-container {
        display: block;
        background: #fff;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        -webkit-transition: box-shadow 0.1s ease;
        -moz-transition: box-shadow 0.1s ease;
        -ms-transition: box-shadow 0.1s ease;
        -o-transition: box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease;
        display: none;
        position: absolute;
        top: -17px;
        left: -17px;
        right: -17px;
        padding-top: 60px;
        padding-bottom: 17px;
        z-index: 1;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
      .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list {
        position: relative;
        max-height: 200px;
        overflow: auto; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 10px 17px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          text-align: left;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-button .text,
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-button .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-button .icon {
            margin-right: 0; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-button {
    font-weight: normal; } }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-button.active {
            background: #f2f5fb; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-button.selected {
            background: #f2f5fb; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-button:hover {
            background: #f2f5fb; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-action {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 10px 17px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          text-align: left;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-action .text,
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-action .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-action .icon {
            margin-right: 0; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-action.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-action.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-action.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-action:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-action {
    font-weight: normal; } }
          .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .select-list .select-list-action:hover {
            background: #f2f5fb; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .search-select.show-list .select-list-container {
        display: block; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .search-select.show-list .search-field {
        z-index: 1;
        border-bottom-color: rgba(0, 0, 0, 0.1); }
    .cabinet-add-company-layout .add-company-form .subform .form-control .field-price-input-container {
      position: relative;
      width: 100%; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .field-price-input-container .unit-box {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        text-align: center;
        white-space: nowrap; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .field-price-input-container .unit-box span {
          display: inline-block; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .field-price-input-container .unit-box .unit-background-text {
          color: transparent;
          font-size: 38px;
          max-width: 95%;
          overflow: hidden; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .field-price-input-container .unit-box .unit-background-text-space {
          width: 24px; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .field-price-input-container input {
        position: relative;
        height: 50px;
        line-height: 50px;
        box-sizing: border-box;
        text-align: center;
        font-size: 38px; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .field-price-input-container .unit-ruble {
        line-height: 52px;
        font-size: 32px;
        color: #8494a9;
        opacity: 0.7;
        filter: alpha(opacity=70);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=70;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .field-price-input-container .unit-ruble:after {
          content: "\20bd"; }
    .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker {
      position: relative; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-button {
        display: block;
        width: 105px;
        height: 30px;
        background: none;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette {
        display: block;
        background: #fff;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        -webkit-transition: box-shadow 0.1s ease;
        -moz-transition: box-shadow 0.1s ease;
        -ms-transition: box-shadow 0.1s ease;
        -o-transition: box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease;
        position: absolute;
        left: 0;
        bottom: -200px;
        width: 504px;
        padding: 32px 20px;
        z-index: 4;
        -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 450px) {
  .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
        .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette .palette-header {
          margin-bottom: 40px;
          margin-left: 10px;
          font-size: 22px; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette .palette-color-buttons {
          margin-bottom: 12px; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette .palette-color-button {
          display: inline-block;
          width: 60px;
          height: 60px;
          margin: 0 12px 24px 12px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette .palette-color-button.active, .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette .palette-color-button:hover {
            margin: -10px 2px 14px 2px;
            width: 80px;
            height: 80px;
            border: 10px solid #f2f5fb; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette .palette-corner-close-button {
          position: absolute;
          top: 0;
          right: 0;
          width: 80px;
          height: 80px;
          vertical-align: middle;
          text-align: center;
          font-size: 38px;
          font-family: "times new roman", verdana;
          background: none; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette .palette-bottom-buttons {
          text-align: right; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            background: none;
            border: 1px solid #4b4b4b;
            color: #4b4b4b;
            font-size: 16px;
            min-width: 191px; }
            .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
            .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
              display: inline-block;
              vertical-align: top; }
            .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
              margin-right: 0; }
            .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
    font-weight: normal; } }
            .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
              background: #f2f5fb; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .colorpicker .picker-palette-overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7);
        z-index: 2; }
    .cabinet-add-company-layout .add-company-form .subform .form-control .form-autocomplete-input {
      position: relative; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .form-autocomplete-input .form-autocomplete-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 300px;
        background: #fff;
        -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        -o-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        z-index: 1;
        overflow: auto; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .form-autocomplete-input .form-autocomplete-dropdown button {
          background: none;
          border: none;
          width: 100%;
          padding: 8px 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          text-align: left; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover {
            background: #f2f5fb; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child {
            border-bottom: 0; }
    .cabinet-add-company-layout .add-company-form .subform .form-control .field-text-list-container .text-list-item {
      position: relative;
      margin-bottom: 10px; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .field-text-list-container .text-list-item .remove-text-item {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b;
        position: absolute;
        top: 0;
        right: 0;
        color: #f32424;
        font-size: 22px; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .field-text-list-container .text-list-item .remove-text-item:hover {
          color: black; }
    .cabinet-add-company-layout .add-company-form .subform .form-control .field-text-list-container .add-text-item {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .field-text-list-container .add-text-item:hover {
        color: black; }
    .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-container .files-loader-input-container {
      margin-bottom: 20px; }
    .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-input-container {
      position: relative; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-input-container .input-label-area {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        background: none;
        border: 1px solid #4b4b4b;
        color: #4b4b4b;
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px;
        width: 100%;
        height: 60px;
        line-height: 60px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        border-radius: 8px; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-input-container .input-label-area .text,
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-input-container .input-label-area .icon {
          display: inline-block;
          vertical-align: top; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-input-container .input-label-area .icon {
          margin-right: 0; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-input-container .input-label-area.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-input-container .input-label-area.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-input-container .input-label-area.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-input-container .input-label-area:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-input-container .input-label-area {
    font-weight: normal; } }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-input-container .input-label-area:hover {
          background: #f2f5fb; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-input-container input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-input-container:hover .input-label-area {
        background: #f2f5fb; }
    .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-list .files-list-item {
      display: flex;
      justify-content: space-between;
      text-align: left;
      margin-bottom: 10px; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-list .files-list-item .list-item-col {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-list .files-list-item .list-item-col.icon {
          width: 38px;
          flex-shrink: 0; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-list .files-list-item .list-item-col.caption {
          margin-right: auto;
          margin-left: 18px; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-list .files-list-item .list-item-col.price {
          flex-shrink: 0;
          text-align: right; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-list .files-list-item .list-item-col .note {
          font-size: 12px;
          color: #8494a9; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-list .files-list-item .icon {
        display: inline-block;
        width: 38px;
        height: 38px;
        line-height: 37px;
        text-align: center;
        font-size: 22px;
        font-weight: 500;
        color: #fff;
        background: #bed6f9;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
        font-size: 12px;
        font-weight: normal; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-list .files-list-item .icon.icon-sub-correction {
          line-height: 34.2px; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-list .files-list-item .icon.color-1 {
          background: #bed6f9; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-list .files-list-item .icon.color-2 {
          background: #b9e398; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-list .files-list-item .icon.color-3 {
          background: #e4e597; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-list .files-list-item .icon.color-4 {
          background: #e7c740; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-list .files-list-item .icon.color-5 {
          background: #e74040; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-list .files-list-item .icon.color-6 {
          background: #cf91af; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-list .files-list-item .caption {
        height: 38px;
        line-height: 38px;
        margin-left: 12px; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-list .files-list-item .remove {
        flex-shrink: 0;
        margin-left: 0; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .files-loader-list .files-list-item .remove button {
          background: none;
          border: none;
          height: 38px;
          line-height: 38px;
          font-size: 22px;
          color: #f32424; }
    .cabinet-add-company-layout .add-company-form .subform .form-control .select-container .note {
      position: relative;
      padding-left: 25px;
      padding-top: 6px;
      font-size: 14px;
      color: #8494a9;
      line-height: 14px; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .select-container .note:before {
        content: "i";
        position: absolute;
        top: 5px;
        left: 0;
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 10px;
        line-height: 14px;
        border: 1px solid #8494a9;
        border-radius: 50%;
        text-align: center; }
    .cabinet-add-company-layout .add-company-form .subform .form-control .input-phone {
      display: flex; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .input-phone .phone-country-code .styled-select {
        position: inherit; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .input-phone .phone-country-code .styled-select .select-button {
          padding: 0;
          width: 80px;
          text-align: left;
          box-shadow: none;
          line-height: 34px;
          border-bottom: 1px solid #8d76f0;
          border-radius: 0; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after {
            width: 8px; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .input-phone .phone-country-code .styled-select .select-list {
          top: 100%;
          width: 100%;
          max-height: 200px;
          margin-top: 10px;
          overflow: auto; }
          .cabinet-add-company-layout .add-company-form .subform .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button {
            text-align: left; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .input-phone .phone-country-code .styled-select select {
          width: 80px; }
        .cabinet-add-company-layout .add-company-form .subform .form-control .input-phone .phone-country-code .styled-select.show-list .select-button {
          opacity: 1;
          filter: alpha(opacity=100);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .input-phone .phone-number {
        flex: 1; }
    .cabinet-add-company-layout .add-company-form .subform .form-control .password-input {
      position: relative; }
      .cabinet-add-company-layout .add-company-form .subform .form-control .password-input .eye-icon {
        font-size: 18px;
        background-color: transparent;
        color: #8494a9;
        position: absolute;
        right: 0; }

@media screen and (max-width: 500px) {
  .cabinet-add-company-layout .add-company-form .subform .form-control .search-select .search-field .field-dropdown-icon {
    top: 3px; } }
    .cabinet-add-company-layout .add-company-form .subform .form-control .datepicker-popup {
      position: absolute;
      top: 100%;
      left: 0; }
  .cabinet-add-company-layout .add-company-form .subform .form-errors-list {
    margin-bottom: 24px; }
    .cabinet-add-company-layout .add-company-form .subform .form-errors-list .error-item {
      margin-bottom: 8px;
      color: #f32424; }
      .cabinet-add-company-layout .add-company-form .subform .form-errors-list .error-item:last-child {
        margin-bottom: 0; }
  .cabinet-add-company-layout .add-company-form .subform .form-messages-list {
    margin-bottom: 24px;
    text-align: center; }
    .cabinet-add-company-layout .add-company-form .subform .form-messages-list .message-item {
      margin-bottom: 8px;
      color: #4dbd7d;
      font-size: 17px;
      line-height: 22px; }
      .cabinet-add-company-layout .add-company-form .subform .form-messages-list .message-item:last-child {
        margin-bottom: 0; }
  .cabinet-add-company-layout .add-company-form .subform .subform-title {
    margin-bottom: 24px;
    font-size: 17px;
    font-weight: 500; }
  .cabinet-add-company-layout .add-company-form .subform .form-field .form-label {
    font-size: 14px; }

.cabinet-add-company-layout .add-company-form .form-submit-button button {
  display: inline-block;
  height: 50px;
  line-height: 48px;
  padding: 0 20px;
  text-align: center;
  vertical-align: top;
  background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
  border: 0;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  box-sizing: border-box;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
  -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
  -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
  -o-transition: background 0.1s ease, box-shadow 0.1s ease;
  transition: background 0.1s ease, box-shadow 0.1s ease;
  min-width: 340px; }
  .cabinet-add-company-layout .add-company-form .form-submit-button button .text,
  .cabinet-add-company-layout .add-company-form .form-submit-button button .icon {
    display: inline-block;
    vertical-align: top; }
  .cabinet-add-company-layout .add-company-form .form-submit-button button .icon {
    margin-right: 0; }
  .cabinet-add-company-layout .add-company-form .form-submit-button button.button-xs {
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    font-weight: normal;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    border-radius: 15px; }
  .cabinet-add-company-layout .add-company-form .form-submit-button button.button-s {
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px; }
  .cabinet-add-company-layout .add-company-form .form-submit-button button.button-l {
    height: 60px;
    line-height: 58px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px; }
  .cabinet-add-company-layout .add-company-form .form-submit-button button:hover {
    background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-add-company-layout .add-company-form .form-submit-button button {
    font-weight: normal; } }

.cabinet-add-company-layout .add-company-form .add-owner,
.cabinet-add-company-layout .add-company-form .add-account {
  background: none;
  border: none;
  cursor: pointer;
  color: #4b4b4b; }
  .cabinet-add-company-layout .add-company-form .add-owner:hover,
  .cabinet-add-company-layout .add-company-form .add-account:hover {
    color: black; }

.cabinet-add-company-layout .add-company-form .owner-item-form,
.cabinet-add-company-layout .add-company-form .account-item-form {
  position: relative; }
  .cabinet-add-company-layout .add-company-form .owner-item-form .delete-owner,
  .cabinet-add-company-layout .add-company-form .owner-item-form .delete-account,
  .cabinet-add-company-layout .add-company-form .account-item-form .delete-owner,
  .cabinet-add-company-layout .add-company-form .account-item-form .delete-account {
    background: none;
    border: none;
    cursor: pointer;
    color: #4b4b4b;
    position: absolute;
    top: 0;
    right: 0;
    color: #f32424;
    font-size: 22px; }
    .cabinet-add-company-layout .add-company-form .owner-item-form .delete-owner:hover,
    .cabinet-add-company-layout .add-company-form .owner-item-form .delete-account:hover,
    .cabinet-add-company-layout .add-company-form .account-item-form .delete-owner:hover,
    .cabinet-add-company-layout .add-company-form .account-item-form .delete-account:hover {
      color: black; }

.cabinet-add-company-layout .add-company-form .form-errors-list {
  margin-bottom: 24px; }
  .cabinet-add-company-layout .add-company-form .form-errors-list .error-item {
    margin-bottom: 8px;
    color: #f32424; }
    .cabinet-add-company-layout .add-company-form .form-errors-list .error-item:last-child {
      margin-bottom: 0; }

.cabinet-add-company-layout .add-company-form .form-messages-list .message-item {
  margin-bottom: 8px;
  color: #4dbd7d;
  font-size: 17px;
  line-height: 22px; }
  .cabinet-add-company-layout .add-company-form .form-messages-list .message-item:last-child {
    margin-bottom: 0; }

/* CABINET NOTIFICATIONS */
.cabinet-errors,
.cabinet-messages {
  display: block;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: box-shadow 0.1s ease;
  -moz-transition: box-shadow 0.1s ease;
  -ms-transition: box-shadow 0.1s ease;
  -o-transition: box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease;
  padding: 24px;
  margin-bottom: 24px; }

@media screen and (max-width: 450px) {
  .cabinet-errors,
  .cabinet-messages {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
  .cabinet-errors .error-item,
  .cabinet-messages .error-item {
    margin-bottom: 8px;
    color: #f32424; }
    .cabinet-errors .error-item:last-child,
    .cabinet-messages .error-item:last-child {
      margin-bottom: 0; }
  .cabinet-errors .message-item,
  .cabinet-messages .message-item {
    margin-bottom: 8px;
    color: #4dbd7d;
    font-size: 17px;
    line-height: 22px; }
    .cabinet-errors .message-item:last-child,
    .cabinet-messages .message-item:last-child {
      margin-bottom: 0; }

/* ADD INVESTMENT POPUP */
.add-investment-popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(255, 255, 255, 0.7);
  animation: popup-background-fade-in 0.8s ease; }
  .add-investment-popup .popup-container {
    position: relative;
    display: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    display: block;
    width: 550px;
    margin: 0 auto;
    padding-bottom: 58px;
    top: 10%;
    max-height: 75%;
    overflow: auto;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    /* Scrollbar styles */ }
    .add-investment-popup .popup-container.show {
      display: block; }
    .add-investment-popup .popup-container .close-button {
      width: 19px;
      height: 19px;
      background: url(images/icon-close.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: absolute;
      top: 25px;
      right: 25px; }

@media screen and (max-width: 500px) {
  .add-investment-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .add-investment-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .add-investment-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
    .add-investment-popup .popup-container::-webkit-scrollbar {
      width: 12px;
      height: 12px; }
    .add-investment-popup .popup-container::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 10px; }
    .add-investment-popup .popup-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #ccc; }
    .add-investment-popup .popup-container .popup-content form .form-control .datepicker-popup {
      position: fixed;
      top: 50%;
      left: 50%;
      margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .add-investment-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
    .add-investment-popup .popup-container .popup-content .popup-actions {
      width: 340px;
      margin: 0 auto; }
      .add-investment-popup .popup-container .popup-content .popup-actions button {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .add-investment-popup .popup-container .popup-content .popup-actions button:hover {
          color: black; }
  .add-investment-popup .header {
    padding: 32px 24px;
    font-size: 22px; }
  .add-investment-popup .body {
    padding: 0 24px 32px 24px; }
  .add-investment-popup.show {
    display: block; }

@media screen and (max-width: 600px) {
  .add-investment-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .add-investment-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .add-investment-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
  .add-investment-popup .popup-content form {
    width: 340px;
    margin: 0 auto; }
    .add-investment-popup .popup-content form .form-field {
      margin-bottom: 38px;
      width: 100%; }
      .add-investment-popup .popup-content form .form-field .form-label {
        display: block;
        font-size: 17px;
        color: #8494a9; }
      .add-investment-popup .popup-content form .form-field.field-type-colorpicker .form-label {
        margin-bottom: 8px; }
      .add-investment-popup .popup-content form .form-field.field-type-files .form-label {
        display: none; }
      .add-investment-popup .popup-content form .form-field .custom-checkput {
        position: relative;
        display: inline-block;
        width: auto;
        height: auto; }
        .add-investment-popup .popup-content form .form-field .custom-checkput input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .add-investment-popup .popup-content form .form-field .custom-checkput .checkput-icon {
          position: relative;
          display: inline-block;
          width: 18px;
          height: 18px;
          border: 2px solid #8494a9;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          border-radius: 3px;
          cursor: pointer; }
        .add-investment-popup .popup-content form .form-field .custom-checkput.checked .checkput-icon {
          position: relative;
          display: inline-block;
          width: 22px;
          height: 22px;
          background: #81bc52;
          border: 0;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          border-radius: 3px; }
          .add-investment-popup .popup-content form .form-field .custom-checkput.checked .checkput-icon::after {
            content: "";
            width: 16px;
            height: 12px;
            background: url(images/icon-check-white.png) no-repeat center center;
            display: inline-block;
            background-size: contain;
            position: absolute;
            top: 7px;
            left: 4px;
            width: 13px;
            height: 9px; }
      .add-investment-popup .popup-content form .form-field.field-type-checkbox .custom-checkput, .add-investment-popup .popup-content form .form-field.field-type-agreement .custom-checkput, .add-investment-popup .popup-content form .form-field.field-type-license-agreement .custom-checkput {
        float: left; }
      .add-investment-popup .popup-content form .form-field.field-type-checkbox .form-label, .add-investment-popup .popup-content form .form-field.field-type-agreement .form-label, .add-investment-popup .popup-content form .form-field.field-type-license-agreement .form-label {
        margin-left: 34px;
        cursor: pointer; }
      .add-investment-popup .popup-content form .form-field.field-type-agreement button, .add-investment-popup .popup-content form .form-field.field-type-license-agreement button {
        display: block;
        margin-left: 34px;
        height: auto;
        line-height: 22px;
        font-size: 17px;
        color: #8494a9;
        text-decoration: underline;
        background: none; }
      .add-investment-popup .popup-content form .form-field .form-field-password-generator button {
        color: #2f80ed;
        cursor: pointer;
        background: transparent; }
      .add-investment-popup .popup-content form .form-field .form-field-password-check {
        line-height: 16px;
        font-size: 12px;
        position: relative;
        margin-top: -2px;
        z-index: 1; }
        .add-investment-popup .popup-content form .form-field .form-field-password-check.active-red {
          width: 30%;
          border-top: 2px solid #F35E84;
          margin-bottom: 11px; }
        .add-investment-popup .popup-content form .form-field .form-field-password-check.active-green {
          width: 100%;
          border-top: 2px solid #5A9C76;
          margin-bottom: 11px; }
        .add-investment-popup .popup-content form .form-field .form-field-password-check.active-yellow {
          width: 70%;
          border-top: 2px solid #C99300;
          margin-bottom: 11px; }
        .add-investment-popup .popup-content form .form-field .form-field-password-check .password-check-hint-green {
          color: #5A9C76; }
        .add-investment-popup .popup-content form .form-field .form-field-password-check .password-check-hint-yellow {
          color: #C99300; }
        .add-investment-popup .popup-content form .form-field .form-field-password-check .password-check-hint-red {
          color: #F35E84; }

@media screen and (max-width: 500px) {
  .add-investment-popup .popup-content form .form-field {
    margin-bottom: 28px; }
    .add-investment-popup .popup-content form .form-field .form-label {
      font-size: 14px;
      line-height: 14px; } }
      .add-investment-popup .popup-content form .form-field .form-button {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        width: 100%; }
        .add-investment-popup .popup-content form .form-field .form-button .text,
        .add-investment-popup .popup-content form .form-field .form-button .icon {
          display: inline-block;
          vertical-align: top; }
        .add-investment-popup .popup-content form .form-field .form-button .icon {
          margin-right: 0; }
        .add-investment-popup .popup-content form .form-field .form-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .add-investment-popup .popup-content form .form-field .form-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .add-investment-popup .popup-content form .form-field .form-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .add-investment-popup .popup-content form .form-field .form-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-investment-popup .popup-content form .form-field .form-button {
    font-weight: normal; } }
      .add-investment-popup .popup-content form .form-field.field-error .form-label {
        color: #f32424; }
      .add-investment-popup .popup-content form .form-field.field-error .form-control .form-input,
      .add-investment-popup .popup-content form .form-field.field-error .form-control textarea,
      .add-investment-popup .popup-content form .form-field.field-error .form-control select {
        border-color: #f32424; }
      .add-investment-popup .popup-content form .form-field.field-error .form-field-error p {
        color: #f32424;
        white-space: pre-wrap; }
    .add-investment-popup .popup-content form .form-control {
      position: relative; }
      .add-investment-popup .popup-content form .form-control .form-input,
      .add-investment-popup .popup-content form .form-control textarea,
      .add-investment-popup .popup-content form .form-control select {
        width: 100%;
        height: 34px;
        line-height: 34px;
        padding: 0;
        background: none;
        border: 0;
        border-bottom: 1px solid #8d76f0;
        font-size: 17px;
        outline: none; }
        .add-investment-popup .popup-content form .form-control .form-input::-webkit-input-placeholder,
        .add-investment-popup .popup-content form .form-control textarea::-webkit-input-placeholder,
        .add-investment-popup .popup-content form .form-control select::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: rgba(132, 148, 169, 0.5); }
        .add-investment-popup .popup-content form .form-control .form-input:-moz-placeholder,
        .add-investment-popup .popup-content form .form-control textarea:-moz-placeholder,
        .add-investment-popup .popup-content form .form-control select:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .add-investment-popup .popup-content form .form-control .form-input::-moz-placeholder,
        .add-investment-popup .popup-content form .form-control textarea::-moz-placeholder,
        .add-investment-popup .popup-content form .form-control select::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .add-investment-popup .popup-content form .form-control .form-input:-ms-input-placeholder,
        .add-investment-popup .popup-content form .form-control textarea:-ms-input-placeholder,
        .add-investment-popup .popup-content form .form-control select:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba(132, 148, 169, 0.5); }
        .add-investment-popup .popup-content form .form-control .form-input::-ms-input-placeholder,
        .add-investment-popup .popup-content form .form-control textarea::-ms-input-placeholder,
        .add-investment-popup .popup-content form .form-control select::-ms-input-placeholder {
          /* Microsoft Edge */
          color: rgba(132, 148, 169, 0.5); }
        .add-investment-popup .popup-content form .form-control .form-input::placeholder,
        .add-investment-popup .popup-content form .form-control textarea::placeholder,
        .add-investment-popup .popup-content form .form-control select::placeholder {
          /* Most modern browsers support this now. */
          color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .add-investment-popup .popup-content form .form-control .form-input,
  .add-investment-popup .popup-content form .form-control textarea,
  .add-investment-popup .popup-content form .form-control select {
    font-size: 16px; } }
      .add-investment-popup .popup-content form .form-control textarea {
        resize: none;
        padding: 5px 0;
        line-height: 22px; }
      .add-investment-popup .popup-content form .form-control select {
        cursor: pointer; }
      .add-investment-popup .popup-content form .form-control .styled-select {
        position: relative; }
        .add-investment-popup .popup-content form .form-control .styled-select select {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .add-investment-popup .popup-content form .form-control .styled-select .select-button {
          position: relative;
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          width: 100%;
          height: auto;
          padding: 15px 30px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          border-radius: 8px;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
          .add-investment-popup .popup-content form .form-control .styled-select .select-button .text,
          .add-investment-popup .popup-content form .form-control .styled-select .select-button .icon {
            display: inline-block;
            vertical-align: top; }
          .add-investment-popup .popup-content form .form-control .styled-select .select-button .icon {
            margin-right: 0; }
          .add-investment-popup .popup-content form .form-control .styled-select .select-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .add-investment-popup .popup-content form .form-control .styled-select .select-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .add-investment-popup .popup-content form .form-control .styled-select .select-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .add-investment-popup .popup-content form .form-control .styled-select .select-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-investment-popup .popup-content form .form-control .styled-select .select-button {
    font-weight: normal; } }
          .add-investment-popup .popup-content form .form-control .styled-select .select-button .post-icon {
            position: relative;
            top: -1px;
            left: 8px;
            display: inline-block;
            width: 0; }
            .add-investment-popup .popup-content form .form-control .styled-select .select-button .post-icon:after {
              content: "";
              width: 14px;
              height: 7px;
              background: url(images/arrow-bottom.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
          .add-investment-popup .popup-content form .form-control .styled-select .select-button:hover {
            background: none;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
        .add-investment-popup .popup-content form .form-control .styled-select .select-list {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 1;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .add-investment-popup .popup-content form .form-control .styled-select .select-list {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
          .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 15px 50px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-button .text,
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-button .icon {
              display: inline-block;
              vertical-align: top; }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-button .icon {
              margin-right: 0; }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-button {
    font-weight: normal; } }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-button.active .pre-icon {
              position: relative;
              left: -26px;
              display: inline-block;
              width: 0; }
              .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-button.active .pre-icon:after {
                content: "";
                width: 16px;
                height: 12px;
                background: url(images/icon-check.png) no-repeat center center;
                display: inline-block;
                background-size: contain; }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-button:after {
              content: "";
              display: block;
              position: absolute;
              top: 100%;
              left: 0;
              right: 0;
              height: 1px;
              background: rgba(0, 0, 0, 0.1);
              opacity: 0.5;
              filter: alpha(opacity=50);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-button:hover {
              background: #f2f5fb; }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-button:first-child:after {
              left: 23px;
              right: 23px;
              opacity: 1;
              filter: alpha(opacity=100);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-button:last-child {
              margin-bottom: 40px; }
          .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-action {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 15px 23px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-action .text,
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-action .icon {
              display: inline-block;
              vertical-align: top; }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-action .icon {
              margin-right: 0; }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-action:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-action {
    font-weight: normal; } }
            .add-investment-popup .popup-content form .form-control .styled-select .select-list .select-list-action:hover {
              background: #f2f5fb; }
          .add-investment-popup .popup-content form .form-control .styled-select .select-list.show {
            display: block; }
        .add-investment-popup .popup-content form .form-control .styled-select.show-list .select-button {
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .add-investment-popup .popup-content form .form-control .search-select {
        position: relative; }
        .add-investment-popup .popup-content form .form-control .search-select select {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .add-investment-popup .popup-content form .form-control .search-select .search-field {
          position: relative;
          border-bottom: 1px solid #8d76f0; }
          .add-investment-popup .popup-content form .form-control .search-select .search-field input {
            width: 100%;
            height: 34px;
            line-height: 34px;
            padding: 0;
            background: none;
            border: 0;
            border-bottom: 1px solid #8d76f0;
            font-size: 17px;
            outline: none;
            width: 90%;
            border-bottom: 0; }
            .add-investment-popup .popup-content form .form-control .search-select .search-field input::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: rgba(132, 148, 169, 0.5); }
            .add-investment-popup .popup-content form .form-control .search-select .search-field input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .add-investment-popup .popup-content form .form-control .search-select .search-field input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .add-investment-popup .popup-content form .form-control .search-select .search-field input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: rgba(132, 148, 169, 0.5); }
            .add-investment-popup .popup-content form .form-control .search-select .search-field input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: rgba(132, 148, 169, 0.5); }
            .add-investment-popup .popup-content form .form-control .search-select .search-field input::placeholder {
              /* Most modern browsers support this now. */
              color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .add-investment-popup .popup-content form .form-control .search-select .search-field input {
    font-size: 16px; } }
            .add-investment-popup .popup-content form .form-control .search-select .search-field input::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: #4b4b4b; }
            .add-investment-popup .popup-content form .form-control .search-select .search-field input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: #4b4b4b;
              opacity: 1; }
            .add-investment-popup .popup-content form .form-control .search-select .search-field input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: #4b4b4b;
              opacity: 1; }
            .add-investment-popup .popup-content form .form-control .search-select .search-field input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #4b4b4b; }
            .add-investment-popup .popup-content form .form-control .search-select .search-field input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: #4b4b4b; }
            .add-investment-popup .popup-content form .form-control .search-select .search-field input::placeholder {
              /* Most modern browsers support this now. */
              color: #4b4b4b; }
          .add-investment-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon {
            position: absolute;
            top: 7px;
            right: 21px;
            width: 0; }
            .add-investment-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon:after {
              content: "";
              width: 14px;
              height: 7px;
              background: url(images/arrow-bottom.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
        .add-investment-popup .popup-content form .form-control .search-select .select-list-container {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          display: none;
          position: absolute;
          top: -17px;
          left: -17px;
          right: -17px;
          padding-top: 60px;
          padding-bottom: 17px;
          z-index: 1;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .add-investment-popup .popup-content form .form-control .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
        .add-investment-popup .popup-content form .form-control .search-select .select-list {
          position: relative;
          max-height: 200px;
          overflow: auto; }
          .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 10px 17px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-button .text,
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-button .icon {
              display: inline-block;
              vertical-align: top; }
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-button .icon {
              margin-right: 0; }
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-button {
    font-weight: normal; } }
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-button.active {
              background: #f2f5fb; }
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-button.selected {
              background: #f2f5fb; }
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-button:hover {
              background: #f2f5fb; }
          .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-action {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 10px 17px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-action .text,
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-action .icon {
              display: inline-block;
              vertical-align: top; }
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-action .icon {
              margin-right: 0; }
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-action:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-action {
    font-weight: normal; } }
            .add-investment-popup .popup-content form .form-control .search-select .select-list .select-list-action:hover {
              background: #f2f5fb; }
        .add-investment-popup .popup-content form .form-control .search-select.show-list .select-list-container {
          display: block; }
        .add-investment-popup .popup-content form .form-control .search-select.show-list .search-field {
          z-index: 1;
          border-bottom-color: rgba(0, 0, 0, 0.1); }
      .add-investment-popup .popup-content form .form-control .field-price-input-container {
        position: relative;
        width: 100%; }
        .add-investment-popup .popup-content form .form-control .field-price-input-container .unit-box {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          text-align: center;
          white-space: nowrap; }
          .add-investment-popup .popup-content form .form-control .field-price-input-container .unit-box span {
            display: inline-block; }
          .add-investment-popup .popup-content form .form-control .field-price-input-container .unit-box .unit-background-text {
            color: transparent;
            font-size: 38px;
            max-width: 95%;
            overflow: hidden; }
          .add-investment-popup .popup-content form .form-control .field-price-input-container .unit-box .unit-background-text-space {
            width: 24px; }
        .add-investment-popup .popup-content form .form-control .field-price-input-container input {
          position: relative;
          height: 50px;
          line-height: 50px;
          box-sizing: border-box;
          text-align: center;
          font-size: 38px; }
        .add-investment-popup .popup-content form .form-control .field-price-input-container .unit-ruble {
          line-height: 52px;
          font-size: 32px;
          color: #8494a9;
          opacity: 0.7;
          filter: alpha(opacity=70);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=70;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .add-investment-popup .popup-content form .form-control .field-price-input-container .unit-ruble:after {
            content: "\20bd"; }
      .add-investment-popup .popup-content form .form-control .colorpicker {
        position: relative; }
        .add-investment-popup .popup-content form .form-control .colorpicker .picker-button {
          display: block;
          width: 105px;
          height: 30px;
          background: none;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          position: absolute;
          left: 0;
          bottom: -200px;
          width: 504px;
          padding: 32px 20px;
          z-index: 4;
          -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 450px) {
  .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
          .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette .palette-header {
            margin-bottom: 40px;
            margin-left: 10px;
            font-size: 22px; }
          .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-buttons {
            margin-bottom: 12px; }
          .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin: 0 12px 24px 12px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            border-radius: 50%; }
            .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button.active, .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button:hover {
              margin: -10px 2px 14px 2px;
              width: 80px;
              height: 80px;
              border: 10px solid #f2f5fb; }
          .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette .palette-corner-close-button {
            position: absolute;
            top: 0;
            right: 0;
            width: 80px;
            height: 80px;
            vertical-align: middle;
            text-align: center;
            font-size: 38px;
            font-family: "times new roman", verdana;
            background: none; }
          .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons {
            text-align: right; }
            .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              background: none;
              border: 1px solid #4b4b4b;
              color: #4b4b4b;
              font-size: 16px;
              min-width: 191px; }
              .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
              .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                display: inline-block;
                vertical-align: top; }
              .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                margin-right: 0; }
              .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
    font-weight: normal; } }
              .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                background: #f2f5fb; }
        .add-investment-popup .popup-content form .form-control .colorpicker .picker-palette-overlay {
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.7);
          z-index: 2; }
      .add-investment-popup .popup-content form .form-control .form-autocomplete-input {
        position: relative; }
        .add-investment-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          max-height: 300px;
          background: #fff;
          -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          z-index: 1;
          overflow: auto; }
          .add-investment-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button {
            background: none;
            border: none;
            width: 100%;
            padding: 8px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            text-align: left; }
            .add-investment-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover {
              background: #f2f5fb; }
            .add-investment-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child {
              border-bottom: 0; }
      .add-investment-popup .popup-content form .form-control .field-text-list-container .text-list-item {
        position: relative;
        margin-bottom: 10px; }
        .add-investment-popup .popup-content form .form-control .field-text-list-container .text-list-item .remove-text-item {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b;
          position: absolute;
          top: 0;
          right: 0;
          color: #f32424;
          font-size: 22px; }
          .add-investment-popup .popup-content form .form-control .field-text-list-container .text-list-item .remove-text-item:hover {
            color: black; }
      .add-investment-popup .popup-content form .form-control .field-text-list-container .add-text-item {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .add-investment-popup .popup-content form .form-control .field-text-list-container .add-text-item:hover {
          color: black; }
      .add-investment-popup .popup-content form .form-control .files-loader-container .files-loader-input-container {
        margin-bottom: 20px; }
      .add-investment-popup .popup-content form .form-control .files-loader-input-container {
        position: relative; }
        .add-investment-popup .popup-content form .form-control .files-loader-input-container .input-label-area {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          background: none;
          border: 1px solid #4b4b4b;
          color: #4b4b4b;
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px;
          width: 100%;
          height: 60px;
          line-height: 60px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          border-radius: 8px; }
          .add-investment-popup .popup-content form .form-control .files-loader-input-container .input-label-area .text,
          .add-investment-popup .popup-content form .form-control .files-loader-input-container .input-label-area .icon {
            display: inline-block;
            vertical-align: top; }
          .add-investment-popup .popup-content form .form-control .files-loader-input-container .input-label-area .icon {
            margin-right: 0; }
          .add-investment-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .add-investment-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .add-investment-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .add-investment-popup .popup-content form .form-control .files-loader-input-container .input-label-area:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .add-investment-popup .popup-content form .form-control .files-loader-input-container .input-label-area {
    font-weight: normal; } }
          .add-investment-popup .popup-content form .form-control .files-loader-input-container .input-label-area:hover {
            background: #f2f5fb; }
        .add-investment-popup .popup-content form .form-control .files-loader-input-container input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .add-investment-popup .popup-content form .form-control .files-loader-input-container:hover .input-label-area {
          background: #f2f5fb; }
      .add-investment-popup .popup-content form .form-control .files-loader-list .files-list-item {
        display: flex;
        justify-content: space-between;
        text-align: left;
        margin-bottom: 10px; }
        .add-investment-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
          .add-investment-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.icon {
            width: 38px;
            flex-shrink: 0; }
          .add-investment-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.caption {
            margin-right: auto;
            margin-left: 18px; }
          .add-investment-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.price {
            flex-shrink: 0;
            text-align: right; }
          .add-investment-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col .note {
            font-size: 12px;
            color: #8494a9; }
        .add-investment-popup .popup-content form .form-control .files-loader-list .files-list-item .icon {
          display: inline-block;
          width: 38px;
          height: 38px;
          line-height: 37px;
          text-align: center;
          font-size: 22px;
          font-weight: 500;
          color: #fff;
          background: #bed6f9;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%;
          font-size: 12px;
          font-weight: normal; }
          .add-investment-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.icon-sub-correction {
            line-height: 34.2px; }
          .add-investment-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-1 {
            background: #bed6f9; }
          .add-investment-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-2 {
            background: #b9e398; }
          .add-investment-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-3 {
            background: #e4e597; }
          .add-investment-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-4 {
            background: #e7c740; }
          .add-investment-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-5 {
            background: #e74040; }
          .add-investment-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-6 {
            background: #cf91af; }
        .add-investment-popup .popup-content form .form-control .files-loader-list .files-list-item .caption {
          height: 38px;
          line-height: 38px;
          margin-left: 12px; }
        .add-investment-popup .popup-content form .form-control .files-loader-list .files-list-item .remove {
          flex-shrink: 0;
          margin-left: 0; }
          .add-investment-popup .popup-content form .form-control .files-loader-list .files-list-item .remove button {
            background: none;
            border: none;
            height: 38px;
            line-height: 38px;
            font-size: 22px;
            color: #f32424; }
      .add-investment-popup .popup-content form .form-control .select-container .note {
        position: relative;
        padding-left: 25px;
        padding-top: 6px;
        font-size: 14px;
        color: #8494a9;
        line-height: 14px; }
        .add-investment-popup .popup-content form .form-control .select-container .note:before {
          content: "i";
          position: absolute;
          top: 5px;
          left: 0;
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-right: 10px;
          line-height: 14px;
          border: 1px solid #8494a9;
          border-radius: 50%;
          text-align: center; }
      .add-investment-popup .popup-content form .form-control .input-phone {
        display: flex; }
        .add-investment-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select {
          position: inherit; }
          .add-investment-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-button {
            padding: 0;
            width: 80px;
            text-align: left;
            box-shadow: none;
            line-height: 34px;
            border-bottom: 1px solid #8d76f0;
            border-radius: 0; }
            .add-investment-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after {
              width: 8px; }
          .add-investment-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-list {
            top: 100%;
            width: 100%;
            max-height: 200px;
            margin-top: 10px;
            overflow: auto; }
            .add-investment-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button {
              text-align: left; }
          .add-investment-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select select {
            width: 80px; }
          .add-investment-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select.show-list .select-button {
            opacity: 1;
            filter: alpha(opacity=100);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .add-investment-popup .popup-content form .form-control .input-phone .phone-number {
          flex: 1; }
      .add-investment-popup .popup-content form .form-control .password-input {
        position: relative; }
        .add-investment-popup .popup-content form .form-control .password-input .eye-icon {
          font-size: 18px;
          background-color: transparent;
          color: #8494a9;
          position: absolute;
          right: 0; }

@media screen and (max-width: 500px) {
  .add-investment-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon {
    top: 3px; } }
      .add-investment-popup .popup-content form .form-control .datepicker-popup {
        position: absolute;
        top: 100%;
        left: 0; }
    .add-investment-popup .popup-content form .form-errors-list {
      margin-bottom: 24px; }
      .add-investment-popup .popup-content form .form-errors-list .error-item {
        margin-bottom: 8px;
        color: #f32424; }
        .add-investment-popup .popup-content form .form-errors-list .error-item:last-child {
          margin-bottom: 0; }
    .add-investment-popup .popup-content form .form-messages-list {
      margin-bottom: 24px;
      text-align: center; }
      .add-investment-popup .popup-content form .form-messages-list .message-item {
        margin-bottom: 8px;
        color: #4dbd7d;
        font-size: 17px;
        line-height: 22px; }
        .add-investment-popup .popup-content form .form-messages-list .message-item:last-child {
          margin-bottom: 0; }
  .add-investment-popup.show .popup-container {
    animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

/* REMOVE INVESTMENT POPUP */
.remove-investment-popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(255, 255, 255, 0.7);
  animation: popup-background-fade-in 0.8s ease; }
  .remove-investment-popup .popup-container {
    position: relative;
    display: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    display: block;
    width: 550px;
    margin: 0 auto;
    padding-bottom: 58px;
    top: 10%;
    max-height: 75%;
    overflow: auto;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    /* Scrollbar styles */ }
    .remove-investment-popup .popup-container.show {
      display: block; }
    .remove-investment-popup .popup-container .close-button {
      width: 19px;
      height: 19px;
      background: url(images/icon-close.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: absolute;
      top: 25px;
      right: 25px; }

@media screen and (max-width: 500px) {
  .remove-investment-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .remove-investment-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .remove-investment-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
    .remove-investment-popup .popup-container::-webkit-scrollbar {
      width: 12px;
      height: 12px; }
    .remove-investment-popup .popup-container::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 10px; }
    .remove-investment-popup .popup-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #ccc; }
    .remove-investment-popup .popup-container .popup-content form .form-control .datepicker-popup {
      position: fixed;
      top: 50%;
      left: 50%;
      margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .remove-investment-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
    .remove-investment-popup .popup-container .popup-content .popup-actions {
      width: 340px;
      margin: 0 auto; }
      .remove-investment-popup .popup-container .popup-content .popup-actions button {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .remove-investment-popup .popup-container .popup-content .popup-actions button:hover {
          color: black; }
  .remove-investment-popup .header {
    padding: 32px 24px;
    font-size: 22px; }
  .remove-investment-popup .body {
    padding: 0 24px 32px 24px; }
  .remove-investment-popup.show {
    display: block; }

@media screen and (max-width: 600px) {
  .remove-investment-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .remove-investment-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .remove-investment-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
  .remove-investment-popup .popup-container {
    text-align: center;
    padding-bottom: 0; }
  .remove-investment-popup .popup-content {
    padding: 0 64px 52px 64px; }
    .remove-investment-popup .popup-content .header {
      padding: 64px 0 56px 0; }
    .remove-investment-popup .popup-content .body {
      margin-bottom: 32px; }
    .remove-investment-popup .popup-content .form-fieldset {
      display: flex; }
      .remove-investment-popup .popup-content .form-fieldset .form-field {
        flex: 1;
        margin: 0 12px; }
        .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-button button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          background: none;
          border: 1px solid #4b4b4b;
          color: #4b4b4b;
          width: 100%;
          font-size: 16px; }
          .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-button button .text,
          .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
            display: inline-block;
            vertical-align: top; }
          .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
            margin-right: 0; }
          .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-button button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-button button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-button button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-button button {
    font-weight: normal; } }
          .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
            background: #f2f5fb; }
        .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          width: 100%;
          font-size: 16px; }
          .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
          .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
            display: inline-block;
            vertical-align: top; }
          .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
            margin-right: 0; }
          .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
  .remove-investment-popup.show .popup-container {
    animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

@media screen and (max-width: 450px) {
  .remove-investment-popup .popup-content {
    padding-right: 32px;
    padding-left: 32px; }
    .remove-investment-popup .popup-content .header {
      padding: 32px 0 28px 0; }
    .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-button button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; } }
  .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    background: #f35e84;
    width: 100%;
    font-size: 16px; }
    .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
    .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
      display: inline-block;
      vertical-align: top; }
    .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
      margin-right: 0; }
    .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
    .remove-investment-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
      background: #e64871; }

/* ACCENT PAGE */
.accent-page {
  background: url(images/accent-page.jpg) no-repeat right top;
  background-size: cover; }
  .accent-page .companies-layout .companies-header {
    background: none;
    border: 1px solid #fff; }
  .accent-page .companies-layout .companies-action button {
    color: #fff; }

/* ANALYTICS */
.analytics-section-container {
  width: 958px; }
  .analytics-section-container .analytics-plate {
    display: block;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: box-shadow 0.1s ease;
    -moz-transition: box-shadow 0.1s ease;
    -ms-transition: box-shadow 0.1s ease;
    -o-transition: box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease;
    padding: 37px 28px; }

@media screen and (max-width: 450px) {
  .analytics-section-container .analytics-plate {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
  .analytics-section-container .analytics-bar {
    margin-bottom: 40px; }
  .analytics-section-container .analytics-section {
    font-size: 0;
    line-height: 0; }
  .analytics-section-container .analytics-graph {
    display: inline-block;
    vertical-align: top;
    width: 50%; }
    .analytics-section-container .analytics-graph .graph-container {
      max-width: 340px; }

.analytics-filter {
  position: relative; }
  .analytics-filter .filter-field {
    display: inline-block;
    margin: 0 6px 12px 6px;
    vertical-align: top; }
  .analytics-filter .filter-label {
    display: block;
    color: #8494a9; }
  .analytics-filter .filter-datepicker {
    position: relative; }
    .analytics-filter .filter-datepicker .filter-label-button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px;
      background: #f2f5fb;
      color: #4b4b4b;
      font-size: 14px; }
      .analytics-filter .filter-datepicker .filter-label-button .text,
      .analytics-filter .filter-datepicker .filter-label-button .icon {
        display: inline-block;
        vertical-align: top; }
      .analytics-filter .filter-datepicker .filter-label-button .icon {
        margin-right: 0; }
      .analytics-filter .filter-datepicker .filter-label-button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .analytics-filter .filter-datepicker .filter-label-button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .analytics-filter .filter-datepicker .filter-label-button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .analytics-filter .filter-datepicker .filter-label-button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .analytics-filter .filter-datepicker .filter-label-button {
    font-weight: normal; } }
      .analytics-filter .filter-datepicker .filter-label-button .icon-date {
        width: 17px;
        height: 16px;
        background: url(images/icon-date-black.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        margin-right: 6px;
        margin-top: 6px; }
      .analytics-filter .filter-datepicker .filter-label-button:hover {
        background: #eaeff9; }
    .analytics-filter .filter-datepicker .filter-select-button {
      width: 100%;
      height: 34px;
      line-height: 34px;
      padding: 0;
      background: none;
      border: 0;
      border-bottom: 1px solid #8d76f0;
      font-size: 17px;
      outline: none;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .analytics-filter .filter-datepicker .filter-select-button::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: rgba(132, 148, 169, 0.5); }
      .analytics-filter .filter-datepicker .filter-select-button:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .analytics-filter .filter-datepicker .filter-select-button::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .analytics-filter .filter-datepicker .filter-select-button:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(132, 148, 169, 0.5); }
      .analytics-filter .filter-datepicker .filter-select-button::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(132, 148, 169, 0.5); }
      .analytics-filter .filter-datepicker .filter-select-button::placeholder {
        /* Most modern browsers support this now. */
        color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .analytics-filter .filter-datepicker .filter-select-button {
    font-size: 16px; } }
      .analytics-filter .filter-datepicker .filter-select-button .text {
        margin-right: 8px; }
      .analytics-filter .filter-datepicker .filter-select-button .icon-arrow {
        width: 14px;
        height: 7px;
        background: url(images/arrow-bottom.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
    .analytics-filter .filter-datepicker .filter-date-checklist-popup {
      position: relative;
      display: none;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 20;
      position: absolute;
      top: -24px;
      left: -24px;
      padding-bottom: 24px; }
      .analytics-filter .filter-datepicker .filter-date-checklist-popup.show {
        display: block; }
      .analytics-filter .filter-datepicker .filter-date-checklist-popup .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 25px; }

@media screen and (max-width: 500px) {
  .analytics-filter .filter-datepicker .filter-date-checklist-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .analytics-filter .filter-datepicker .filter-date-checklist-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .analytics-filter .filter-datepicker .filter-date-checklist-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
      .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist-header {
        margin: 24px 0 18px 24px;
        font-size: 14px;
        color: #8494a9;
        background: none; }
        .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist-header .icon,
        .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist-header .text {
          vertical-align: top; }
      .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist {
        min-width: 240px; }
        .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist button {
          position: relative;
          display: block;
          width: 390px;
          height: 48px;
          line-height: 48px;
          padding-right: 32px;
          background: none;
          font-size: 17px;
          color: #4b4b4b; }
          .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon {
            position: absolute;
            top: 13px;
            left: 24px;
            display: inline-block;
            width: 18px;
            height: 18px;
            border: 2px solid #8494a9;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px; }
          .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon-checked {
            position: relative;
            display: inline-block;
            width: 22px;
            height: 22px;
            background: #81bc52;
            border: 0;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px;
            position: absolute;
            top: 13px;
            left: 24px; }
            .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon-checked::after {
              content: "";
              width: 16px;
              height: 12px;
              background: url(images/icon-check-white.png) no-repeat center center;
              display: inline-block;
              background-size: contain;
              position: absolute;
              top: 7px;
              left: 4px;
              width: 13px;
              height: 9px; }
          .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist button .text {
            display: block;
            margin-left: 60px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            font-size: 14px; }
          .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist button:hover {
            background: #f2f5fb; }
          .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist button.hidden {
            display: none; }

@media screen and (max-width: 500px) {
  .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist button {
    width: 100%;
    box-sizing: border-box; }
    .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon,
    .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon-checked {
      top: 7px; } }

@media screen and (max-width: 450px) {
  .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist button {
    font-weight: normal;
    height: 36px;
    line-height: 36px; } }
        .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon {
          width: 14px;
          height: 14px;
          border: 2px solid #4f4f4f;
          border-radius: 50%;
          opacity: 0.7; }
        .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon-checked {
          width: 6px;
          height: 6px;
          border: 6px solid #4b4b4b;
          border-radius: 50%;
          background: none; }
          .analytics-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon-checked::after {
            display: none; }
      .analytics-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs {
        padding-left: 60px; }
        .analytics-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input {
          width: 100%;
          height: 34px;
          line-height: 34px;
          padding: 0;
          background: none;
          border: 0;
          border-bottom: 1px solid #8d76f0;
          font-size: 17px;
          outline: none;
          width: 67px;
          padding: 0;
          font-size: 14px !important; }
          .analytics-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: rgba(132, 148, 169, 0.5); }
          .analytics-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .analytics-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .analytics-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(132, 148, 169, 0.5); }
          .analytics-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(132, 148, 169, 0.5); }
          .analytics-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input::placeholder {
            /* Most modern browsers support this now. */
            color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .analytics-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input {
    font-size: 16px; } }
      .analytics-filter .filter-datepicker .filter-date-checklist-popup .apply-button {
        display: none; }
    .analytics-filter .filter-datepicker .datepicker-popup {
      position: absolute;
      top: 24px;
      left: 24px; }
  .analytics-filter .filter-search-string {
    position: relative; }
    .analytics-filter .filter-search-string .icon {
      position: absolute;
      top: 50%;
      right: 18px;
      margin-top: -11px;
      width: 22px;
      height: 21px;
      background: url(images/icon-filter-search-string.png) no-repeat center center;
      display: inline-block;
      background-size: contain; }
    .analytics-filter .filter-search-string input {
      position: relative;
      width: 305px;
      height: 38px;
      line-height: 38px;
      padding: 0 48px 0 16px;
      background: none;
      border: 1px solid rgba(60, 136, 238, 0.3);
      color: #4b4b4b;
      -webkit-border-radius: 19px;
      -moz-border-radius: 19px;
      -ms-border-radius: 19px;
      -o-border-radius: 19px;
      border-radius: 19px; }
  .analytics-filter .filter-checklist-buttons {
    position: relative; }
    .analytics-filter .filter-checklist-buttons .filter-label-button,
    .analytics-filter .filter-checklist-buttons .filter-checked-item,
    .analytics-filter .filter-checklist-buttons .filter-button-add {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px;
      margin-bottom: 12px;
      background: #fff;
      color: #4b4b4b;
      font-size: 14px;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
      .analytics-filter .filter-checklist-buttons .filter-label-button .text,
      .analytics-filter .filter-checklist-buttons .filter-label-button .icon,
      .analytics-filter .filter-checklist-buttons .filter-checked-item .text,
      .analytics-filter .filter-checklist-buttons .filter-checked-item .icon,
      .analytics-filter .filter-checklist-buttons .filter-button-add .text,
      .analytics-filter .filter-checklist-buttons .filter-button-add .icon {
        display: inline-block;
        vertical-align: top; }
      .analytics-filter .filter-checklist-buttons .filter-label-button .icon,
      .analytics-filter .filter-checklist-buttons .filter-checked-item .icon,
      .analytics-filter .filter-checklist-buttons .filter-button-add .icon {
        margin-right: 0; }
      .analytics-filter .filter-checklist-buttons .filter-label-button.button-xs,
      .analytics-filter .filter-checklist-buttons .filter-checked-item.button-xs,
      .analytics-filter .filter-checklist-buttons .filter-button-add.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .analytics-filter .filter-checklist-buttons .filter-label-button.button-s,
      .analytics-filter .filter-checklist-buttons .filter-checked-item.button-s,
      .analytics-filter .filter-checklist-buttons .filter-button-add.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .analytics-filter .filter-checklist-buttons .filter-label-button.button-l,
      .analytics-filter .filter-checklist-buttons .filter-checked-item.button-l,
      .analytics-filter .filter-checklist-buttons .filter-button-add.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .analytics-filter .filter-checklist-buttons .filter-label-button:hover,
      .analytics-filter .filter-checklist-buttons .filter-checked-item:hover,
      .analytics-filter .filter-checklist-buttons .filter-button-add:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .analytics-filter .filter-checklist-buttons .filter-label-button,
  .analytics-filter .filter-checklist-buttons .filter-checked-item,
  .analytics-filter .filter-checklist-buttons .filter-button-add {
    font-weight: normal; } }
      .analytics-filter .filter-checklist-buttons .filter-label-button:hover,
      .analytics-filter .filter-checklist-buttons .filter-checked-item:hover,
      .analytics-filter .filter-checklist-buttons .filter-button-add:hover {
        background: #eaeff9; }
    .analytics-filter .filter-checklist-buttons .filter-checked-item {
      margin-right: 12px; }
      .analytics-filter .filter-checklist-buttons .filter-checked-item .icon-remove {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: relative;
        top: -1px;
        width: 9px;
        height: 9px;
        margin-left: 8px; }
    .analytics-filter .filter-checklist-buttons .filter-button-add {
      width: 32px;
      padding: 0; }
      .analytics-filter .filter-checklist-buttons .filter-button-add .text {
        line-height: 26px;
        font-size: 22px; }
    .analytics-filter .filter-checklist-buttons .filter-checklist-popup {
      position: relative;
      display: none;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 20;
      position: absolute;
      top: -21px;
      left: -12px; }
      .analytics-filter .filter-checklist-buttons .filter-checklist-popup.show {
        display: block; }
      .analytics-filter .filter-checklist-buttons .filter-checklist-popup .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 25px; }

@media screen and (max-width: 500px) {
  .analytics-filter .filter-checklist-buttons .filter-checklist-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .analytics-filter .filter-checklist-buttons .filter-checklist-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .analytics-filter .filter-checklist-buttons .filter-checklist-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
      .analytics-filter .filter-checklist-buttons .filter-checklist-popup .checklist-header {
        margin: 24px 0 18px 24px;
        font-size: 14px;
        background: none; }
        .analytics-filter .filter-checklist-buttons .filter-checklist-popup .checklist-header .icon,
        .analytics-filter .filter-checklist-buttons .filter-checklist-popup .checklist-header .text {
          vertical-align: top; }
      .analytics-filter .filter-checklist-buttons .filter-checklist-popup .checklist {
        min-width: 240px;
        max-height: 220px;
        padding-bottom: 24px;
        overflow: auto; }
        .analytics-filter .filter-checklist-buttons .filter-checklist-popup .checklist button {
          position: relative;
          display: block;
          width: 390px;
          height: 48px;
          line-height: 48px;
          padding-right: 32px;
          background: none;
          font-size: 17px;
          color: #4b4b4b; }
          .analytics-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .icon {
            position: absolute;
            top: 13px;
            left: 24px;
            display: inline-block;
            width: 18px;
            height: 18px;
            border: 2px solid #8494a9;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px; }
          .analytics-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .icon-checked {
            position: relative;
            display: inline-block;
            width: 22px;
            height: 22px;
            background: #81bc52;
            border: 0;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px;
            position: absolute;
            top: 13px;
            left: 24px; }
            .analytics-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .icon-checked::after {
              content: "";
              width: 16px;
              height: 12px;
              background: url(images/icon-check-white.png) no-repeat center center;
              display: inline-block;
              background-size: contain;
              position: absolute;
              top: 7px;
              left: 4px;
              width: 13px;
              height: 9px; }
          .analytics-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .text {
            display: block;
            margin-left: 60px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            font-size: 14px; }
          .analytics-filter .filter-checklist-buttons .filter-checklist-popup .checklist button:hover {
            background: #f2f5fb; }
          .analytics-filter .filter-checklist-buttons .filter-checklist-popup .checklist button.hidden {
            display: none; }

@media screen and (max-width: 500px) {
  .analytics-filter .filter-checklist-buttons .filter-checklist-popup .checklist button {
    width: 100%;
    box-sizing: border-box; }
    .analytics-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .icon,
    .analytics-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .icon-checked {
      top: 7px; } }

@media screen and (max-width: 450px) {
  .analytics-filter .filter-checklist-buttons .filter-checklist-popup .checklist button {
    font-weight: normal;
    height: 36px;
    line-height: 36px; } }
  .analytics-filter .filter-checklist {
    position: relative; }
    .analytics-filter .filter-checklist .checklist-value-button {
      width: 100%;
      height: 34px;
      line-height: 34px;
      padding: 0;
      background: none;
      border: 0;
      border-bottom: 1px solid #8d76f0;
      font-size: 17px;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .analytics-filter .filter-checklist .checklist-value-button::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: rgba(132, 148, 169, 0.5); }
      .analytics-filter .filter-checklist .checklist-value-button:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .analytics-filter .filter-checklist .checklist-value-button::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .analytics-filter .filter-checklist .checklist-value-button:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(132, 148, 169, 0.5); }
      .analytics-filter .filter-checklist .checklist-value-button::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(132, 148, 169, 0.5); }
      .analytics-filter .filter-checklist .checklist-value-button::placeholder {
        /* Most modern browsers support this now. */
        color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .analytics-filter .filter-checklist .checklist-value-button {
    font-size: 16px; } }
      .analytics-filter .filter-checklist .checklist-value-button .text-value,
      .analytics-filter .filter-checklist .checklist-value-button .count-value {
        margin-right: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 16px; }
      .analytics-filter .filter-checklist .checklist-value-button .count-value {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        padding: 0 14px;
        height: 20px;
        line-height: 20px;
        color: #4b4b4b;
        background: #bed6f9;
        font-size: 16px;
        font-weight: normal;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px; }
        .analytics-filter .filter-checklist .checklist-value-button .count-value .text,
        .analytics-filter .filter-checklist .checklist-value-button .count-value .icon {
          display: inline-block;
          vertical-align: top; }
        .analytics-filter .filter-checklist .checklist-value-button .count-value .icon {
          margin-right: 0; }
        .analytics-filter .filter-checklist .checklist-value-button .count-value.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .analytics-filter .filter-checklist .checklist-value-button .count-value.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .analytics-filter .filter-checklist .checklist-value-button .count-value.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .analytics-filter .filter-checklist .checklist-value-button .count-value:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .analytics-filter .filter-checklist .checklist-value-button .count-value {
    font-weight: normal; } }
        .analytics-filter .filter-checklist .checklist-value-button .count-value:hover {
          color: #4b4b4b;
          background: #bed6f9; }
      .analytics-filter .filter-checklist .checklist-value-button .button-icon {
        width: 14px;
        height: 7px;
        background: url(images/arrow-bottom.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
    .analytics-filter .filter-checklist .filter-checklist-popup {
      position: relative;
      display: none;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 20;
      position: absolute;
      top: -24px;
      left: -24px; }
      .analytics-filter .filter-checklist .filter-checklist-popup.show {
        display: block; }
      .analytics-filter .filter-checklist .filter-checklist-popup .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 25px; }

@media screen and (max-width: 500px) {
  .analytics-filter .filter-checklist .filter-checklist-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .analytics-filter .filter-checklist .filter-checklist-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .analytics-filter .filter-checklist .filter-checklist-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
      .analytics-filter .filter-checklist .filter-checklist-popup .checklist-header {
        margin: 24px 0 18px 24px;
        font-size: 14px;
        color: #8494a9;
        background: none; }
        .analytics-filter .filter-checklist .filter-checklist-popup .checklist-header .icon,
        .analytics-filter .filter-checklist .filter-checklist-popup .checklist-header .text {
          vertical-align: top; }
      .analytics-filter .filter-checklist .filter-checklist-popup .search-field {
        position: relative;
        padding-left: 24px;
        margin-bottom: 8px;
        width: 190px; }
        .analytics-filter .filter-checklist .filter-checklist-popup .search-field input {
          width: 100%;
          height: 34px;
          line-height: 34px;
          padding: 0;
          background: none;
          border: 0;
          border-bottom: 1px solid #8d76f0;
          font-size: 17px;
          outline: none;
          font-size: 14px; }
          .analytics-filter .filter-checklist .filter-checklist-popup .search-field input::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: rgba(132, 148, 169, 0.5); }
          .analytics-filter .filter-checklist .filter-checklist-popup .search-field input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .analytics-filter .filter-checklist .filter-checklist-popup .search-field input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .analytics-filter .filter-checklist .filter-checklist-popup .search-field input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(132, 148, 169, 0.5); }
          .analytics-filter .filter-checklist .filter-checklist-popup .search-field input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(132, 148, 169, 0.5); }
          .analytics-filter .filter-checklist .filter-checklist-popup .search-field input::placeholder {
            /* Most modern browsers support this now. */
            color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .analytics-filter .filter-checklist .filter-checklist-popup .search-field input {
    font-size: 16px; } }
        .analytics-filter .filter-checklist .filter-checklist-popup .search-field .icon {
          width: 14px;
          height: 13px;
          background: url(images/icon-search.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          position: absolute;
          bottom: 10px;
          right: 0;
          opacity: 0.7; }
      .analytics-filter .filter-checklist .filter-checklist-popup .checklist {
        min-width: 240px;
        max-height: 220px;
        padding-bottom: 24px;
        overflow: auto; }
        .analytics-filter .filter-checklist .filter-checklist-popup .checklist button {
          position: relative;
          display: block;
          width: 390px;
          height: 48px;
          line-height: 48px;
          padding-right: 32px;
          background: none;
          font-size: 17px;
          color: #4b4b4b; }
          .analytics-filter .filter-checklist .filter-checklist-popup .checklist button .icon {
            position: absolute;
            top: 13px;
            left: 24px;
            display: inline-block;
            width: 18px;
            height: 18px;
            border: 2px solid #8494a9;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px; }
          .analytics-filter .filter-checklist .filter-checklist-popup .checklist button .icon-checked {
            position: relative;
            display: inline-block;
            width: 22px;
            height: 22px;
            background: #81bc52;
            border: 0;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px;
            position: absolute;
            top: 13px;
            left: 24px; }
            .analytics-filter .filter-checklist .filter-checklist-popup .checklist button .icon-checked::after {
              content: "";
              width: 16px;
              height: 12px;
              background: url(images/icon-check-white.png) no-repeat center center;
              display: inline-block;
              background-size: contain;
              position: absolute;
              top: 7px;
              left: 4px;
              width: 13px;
              height: 9px; }
          .analytics-filter .filter-checklist .filter-checklist-popup .checklist button .text {
            display: block;
            margin-left: 60px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            font-size: 14px; }
          .analytics-filter .filter-checklist .filter-checklist-popup .checklist button:hover {
            background: #f2f5fb; }
          .analytics-filter .filter-checklist .filter-checklist-popup .checklist button.hidden {
            display: none; }

@media screen and (max-width: 500px) {
  .analytics-filter .filter-checklist .filter-checklist-popup .checklist button {
    width: 100%;
    box-sizing: border-box; }
    .analytics-filter .filter-checklist .filter-checklist-popup .checklist button .icon,
    .analytics-filter .filter-checklist .filter-checklist-popup .checklist button .icon-checked {
      top: 7px; } }

@media screen and (max-width: 450px) {
  .analytics-filter .filter-checklist .filter-checklist-popup .checklist button {
    font-weight: normal;
    height: 36px;
    line-height: 36px; } }
      .analytics-filter .filter-checklist .filter-checklist-popup .apply-button,
      .analytics-filter .filter-checklist .filter-checklist-popup .reset-button {
        display: none; }
  .analytics-filter .filter-price-range .range-input {
    width: 100%;
    height: 34px;
    line-height: 34px;
    padding: 0;
    background: none;
    border: 0;
    border-bottom: 1px solid #8d76f0;
    font-size: 17px;
    outline: none;
    font-size: 16px; }
    .analytics-filter .filter-price-range .range-input::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: rgba(132, 148, 169, 0.5); }
    .analytics-filter .filter-price-range .range-input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: rgba(132, 148, 169, 0.5);
      opacity: 1; }
    .analytics-filter .filter-price-range .range-input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: rgba(132, 148, 169, 0.5);
      opacity: 1; }
    .analytics-filter .filter-price-range .range-input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(132, 148, 169, 0.5); }
    .analytics-filter .filter-price-range .range-input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(132, 148, 169, 0.5); }
    .analytics-filter .filter-price-range .range-input::placeholder {
      /* Most modern browsers support this now. */
      color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .analytics-filter .filter-price-range .range-input {
    font-size: 16px; } }
  .analytics-filter .filter-price-range .range-delim {
    display: inline-block;
    width: 34px;
    color: #8494a9;
    text-align: center; }
  .analytics-filter .filter-price-range-buttons {
    position: relative; }
    .analytics-filter .filter-price-range-buttons .filter-label-button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px;
      margin-bottom: 12px;
      background: #fff;
      color: #4b4b4b;
      font-size: 14px;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
      .analytics-filter .filter-price-range-buttons .filter-label-button .text,
      .analytics-filter .filter-price-range-buttons .filter-label-button .icon {
        display: inline-block;
        vertical-align: top; }
      .analytics-filter .filter-price-range-buttons .filter-label-button .icon {
        margin-right: 0; }
      .analytics-filter .filter-price-range-buttons .filter-label-button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .analytics-filter .filter-price-range-buttons .filter-label-button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .analytics-filter .filter-price-range-buttons .filter-label-button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .analytics-filter .filter-price-range-buttons .filter-label-button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .analytics-filter .filter-price-range-buttons .filter-label-button {
    font-weight: normal; } }
      .analytics-filter .filter-price-range-buttons .filter-label-button .icon {
        margin-right: 8px;
        color: #b9e398;
        font-size: 22px; }
        .analytics-filter .filter-price-range-buttons .filter-label-button .icon:after {
          content: "\20bd"; }
      .analytics-filter .filter-price-range-buttons .filter-label-button:hover {
        background: #eaeff9; }
    .analytics-filter .filter-price-range-buttons .filter-price-range-popup {
      position: relative;
      display: none;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 20;
      position: absolute;
      top: -21px;
      left: -12px; }
      .analytics-filter .filter-price-range-buttons .filter-price-range-popup.show {
        display: block; }
      .analytics-filter .filter-price-range-buttons .filter-price-range-popup .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 25px; }

@media screen and (max-width: 500px) {
  .analytics-filter .filter-price-range-buttons .filter-price-range-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .analytics-filter .filter-price-range-buttons .filter-price-range-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .analytics-filter .filter-price-range-buttons .filter-price-range-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
      .analytics-filter .filter-price-range-buttons .filter-price-range-popup .checklist-header {
        margin: 26px 0 23px 32px;
        font-size: 14px;
        background: none; }
        .analytics-filter .filter-price-range-buttons .filter-price-range-popup .checklist-header .icon,
        .analytics-filter .filter-price-range-buttons .filter-price-range-popup .checklist-header .text {
          vertical-align: top; }
        .analytics-filter .filter-price-range-buttons .filter-price-range-popup .checklist-header .icon {
          margin-right: 8px;
          color: #b9e398;
          font-size: 22px; }
          .analytics-filter .filter-price-range-buttons .filter-price-range-popup .checklist-header .icon:after {
            content: "\20bd"; }
      .analytics-filter .filter-price-range-buttons .filter-price-range-popup .popup-content {
        padding: 0 24px 24px 32px; }
      .analytics-filter .filter-price-range-buttons .filter-price-range-popup .range-selector {
        width: 300px;
        margin-bottom: 18px;
        font-size: 0;
        border-bottom: 1px solid #8d76f0; }
        .analytics-filter .filter-price-range-buttons .filter-price-range-popup .range-selector input {
          width: 125px;
          border: none;
          font-size: 22px; }
          .analytics-filter .filter-price-range-buttons .filter-price-range-popup .range-selector input.range-from {
            text-align: right; }
        .analytics-filter .filter-price-range-buttons .filter-price-range-popup .range-selector .range-delim {
          display: inline-block;
          width: 50px;
          font-size: 22px;
          text-align: center; }
      .analytics-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons {
        text-align: center; }
        .analytics-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          background: none;
          border: 1px solid #4b4b4b;
          color: #4b4b4b;
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px;
          line-height: 30px;
          margin: 0 8px; }
          .analytics-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button .text,
          .analytics-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button .icon {
            display: inline-block;
            vertical-align: top; }
          .analytics-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button .icon {
            margin-right: 0; }
          .analytics-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .analytics-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .analytics-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .analytics-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .analytics-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button {
    font-weight: normal; } }
          .analytics-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button:hover {
            background: #f2f5fb; }
  .analytics-filter .search-select {
    position: relative; }
    .analytics-filter .search-select select {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      filter: alpha(opacity=0);
      filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
    .analytics-filter .search-select .search-field {
      position: relative;
      border-bottom: 1px solid #8d76f0; }
      .analytics-filter .search-select .search-field input {
        width: 100%;
        height: 34px;
        line-height: 34px;
        padding: 0;
        background: none;
        border: 0;
        border-bottom: 1px solid #8d76f0;
        font-size: 17px;
        outline: none;
        width: 90%;
        border-bottom: 0; }
        .analytics-filter .search-select .search-field input::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: rgba(132, 148, 169, 0.5); }
        .analytics-filter .search-select .search-field input:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .analytics-filter .search-select .search-field input::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .analytics-filter .search-select .search-field input:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba(132, 148, 169, 0.5); }
        .analytics-filter .search-select .search-field input::-ms-input-placeholder {
          /* Microsoft Edge */
          color: rgba(132, 148, 169, 0.5); }
        .analytics-filter .search-select .search-field input::placeholder {
          /* Most modern browsers support this now. */
          color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .analytics-filter .search-select .search-field input {
    font-size: 16px; } }
        .analytics-filter .search-select .search-field input::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: #4b4b4b; }
        .analytics-filter .search-select .search-field input:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #4b4b4b;
          opacity: 1; }
        .analytics-filter .search-select .search-field input::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #4b4b4b;
          opacity: 1; }
        .analytics-filter .search-select .search-field input:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #4b4b4b; }
        .analytics-filter .search-select .search-field input::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #4b4b4b; }
        .analytics-filter .search-select .search-field input::placeholder {
          /* Most modern browsers support this now. */
          color: #4b4b4b; }
      .analytics-filter .search-select .search-field .field-dropdown-icon {
        position: absolute;
        top: 7px;
        right: 21px;
        width: 0; }
        .analytics-filter .search-select .search-field .field-dropdown-icon:after {
          content: "";
          width: 14px;
          height: 7px;
          background: url(images/arrow-bottom.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
    .analytics-filter .search-select .select-list-container {
      display: block;
      background: #fff;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border-radius: 10px;
      -webkit-transition: box-shadow 0.1s ease;
      -moz-transition: box-shadow 0.1s ease;
      -ms-transition: box-shadow 0.1s ease;
      -o-transition: box-shadow 0.1s ease;
      transition: box-shadow 0.1s ease;
      display: none;
      position: absolute;
      top: -17px;
      left: -17px;
      right: -17px;
      padding-top: 60px;
      padding-bottom: 17px;
      z-index: 1;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .analytics-filter .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
    .analytics-filter .search-select .select-list {
      position: relative;
      max-height: 200px;
      overflow: auto; }
      .analytics-filter .search-select .select-list .select-list-button {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px 17px;
        line-height: 20px;
        background: none;
        color: #4b4b4b;
        font-weight: normal;
        text-align: left;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0; }
        .analytics-filter .search-select .select-list .select-list-button .text,
        .analytics-filter .search-select .select-list .select-list-button .icon {
          display: inline-block;
          vertical-align: top; }
        .analytics-filter .search-select .select-list .select-list-button .icon {
          margin-right: 0; }
        .analytics-filter .search-select .select-list .select-list-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .analytics-filter .search-select .select-list .select-list-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .analytics-filter .search-select .select-list .select-list-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .analytics-filter .search-select .select-list .select-list-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .analytics-filter .search-select .select-list .select-list-button {
    font-weight: normal; } }
        .analytics-filter .search-select .select-list .select-list-button.active {
          background: #f2f5fb; }
        .analytics-filter .search-select .select-list .select-list-button.selected {
          background: #f2f5fb; }
        .analytics-filter .search-select .select-list .select-list-button:hover {
          background: #f2f5fb; }
      .analytics-filter .search-select .select-list .select-list-action {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px 17px;
        line-height: 20px;
        background: none;
        color: #4b4b4b;
        font-weight: normal;
        text-align: left;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0; }
        .analytics-filter .search-select .select-list .select-list-action .text,
        .analytics-filter .search-select .select-list .select-list-action .icon {
          display: inline-block;
          vertical-align: top; }
        .analytics-filter .search-select .select-list .select-list-action .icon {
          margin-right: 0; }
        .analytics-filter .search-select .select-list .select-list-action.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .analytics-filter .search-select .select-list .select-list-action.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .analytics-filter .search-select .select-list .select-list-action.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .analytics-filter .search-select .select-list .select-list-action:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .analytics-filter .search-select .select-list .select-list-action {
    font-weight: normal; } }
        .analytics-filter .search-select .select-list .select-list-action:hover {
          background: #f2f5fb; }
    .analytics-filter .search-select.show-list .select-list-container {
      display: block; }
    .analytics-filter .search-select.show-list .search-field {
      z-index: 1;
      border-bottom-color: rgba(0, 0, 0, 0.1); }
  .analytics-filter .filter-button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    background: none;
    color: #4b4b4b;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    line-height: 38px;
    height: 38px;
    -webkit-border-radius: 19px;
    -moz-border-radius: 19px;
    -ms-border-radius: 19px;
    -o-border-radius: 19px;
    border-radius: 19px;
    display: flex;
    align-items: center; }
    .analytics-filter .filter-button .text,
    .analytics-filter .filter-button .icon {
      display: inline-block;
      vertical-align: top; }
    .analytics-filter .filter-button .icon {
      margin-right: 0; }
    .analytics-filter .filter-button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .analytics-filter .filter-button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .analytics-filter .filter-button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .analytics-filter .filter-button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .analytics-filter .filter-button {
    font-weight: normal; } }
    .analytics-filter .filter-button:hover {
      background: none;
      color: black;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }
    .analytics-filter .filter-button:active {
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 500px) {
  .analytics-filter .filter-field {
    margin-right: 0;
    margin-left: 0; }
  .analytics-filter .filter-control .filter-datepicker .filter-date-checklist-popup {
    left: 0;
    top: 0;
    width: 100%; }
  .analytics-filter .filter-control .filter-checklist .filter-checklist-popup {
    left: 0;
    top: 0;
    width: 100%; } }

@media screen and (max-width: 450px) {
  .analytics-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
    .analytics-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button .text,
    .analytics-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button .icon {
      display: inline-block;
      vertical-align: top; }
    .analytics-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button .icon {
      margin-right: 0; }
    .analytics-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .analytics-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .analytics-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .analytics-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); } }

@media screen and (max-width: 450px) and (max-width: 450px) {
  .analytics-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button {
    font-weight: normal; } }

@media screen and (max-width: 450px) {
  .analytics-filter .filter-control .filter-datepicker .filter-date-checklist-popup .checklist-header {
    margin-top: 28px;
    margin-left: 67px;
    font-size: 16px;
    color: #8494a9; }
    .analytics-filter .filter-control .filter-datepicker .filter-date-checklist-popup .checklist-header .icon {
      display: none; }
  .analytics-filter .filter-control .filter-datepicker .filter-date-checklist-popup .checklist {
    max-height: 9999px; }
    .analytics-filter .filter-control .filter-datepicker .filter-date-checklist-popup .checklist button {
      font-weight: normal;
      color: #4b4b4b; }
  .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .checklist-header {
    margin-top: 28px;
    margin-left: 67px;
    font-size: 16px; }
  .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .checklist {
    position: absolute;
    top: 70px;
    bottom: 40px;
    left: 0;
    width: 100%;
    max-height: 9999px; }
    .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .checklist button {
      height: 48px;
      color: #4b4b4b; }
      .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .checklist button .text {
        font-size: 16px; }
      .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .checklist button .icon,
      .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .checklist button .icon-checked {
        top: 12px; }
  .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .search-field + .checklist {
    top: 120px; }
  .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .search-field {
    width: 100%;
    padding-right: 24px;
    box-sizing: border-box; }
    .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .search-field .icon {
      right: 24px; }
  .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
    .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button .text,
    .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button .icon {
      display: inline-block;
      vertical-align: top; }
    .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button .icon {
      margin-right: 0; }
    .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); } }

@media screen and (max-width: 450px) and (max-width: 450px) {
  .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button {
    font-weight: normal; } }

@media screen and (max-width: 450px) {
  .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .reset-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #4b4b4b;
    display: block;
    position: absolute;
    top: 30px;
    right: 24px; }
    .analytics-filter .filter-control .filter-checklist .filter-checklist-popup .reset-button:hover {
      color: black; } }
  .analytics-filter .filter-field {
    margin: 0 44px 28px 0; }
  .analytics-filter .field-name-date_range {
    width: 254px; }
  .analytics-filter .field-name-partners,
  .analytics-filter .field-name-categories,
  .analytics-filter .field-name-accounts {
    width: 190px; }
  .analytics-filter .field-name-accounts {
    margin-right: 0; }
  .analytics-filter .filter-checklist .filter-checklist-popup {
    top: -26px;
    left: -32px; }
  .analytics-filter .field-name-send {
    display: none; }

.analytics-bar {
  display: flex;
  justify-content: space-between; }
  .analytics-bar .bar-date-range {
    font-size: 14px;
    color: #828282;
    line-height: 18.62px;
    align-self: center; }
  .analytics-bar .bar-views {
    margin-left: auto; }
    .analytics-bar .bar-views button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: none;
      color: #4b4b4b;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px;
      margin-right: 8px;
      display: inline-flex;
      align-items: center;
      border: 1px solid transparent; }
      .analytics-bar .bar-views button .text,
      .analytics-bar .bar-views button .icon {
        display: inline-block;
        vertical-align: top; }
      .analytics-bar .bar-views button .icon {
        margin-right: 0; }
      .analytics-bar .bar-views button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .analytics-bar .bar-views button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .analytics-bar .bar-views button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .analytics-bar .bar-views button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .analytics-bar .bar-views button {
    font-weight: normal; } }
      .analytics-bar .bar-views button:hover {
        background: none;
        color: black;
        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }
      .analytics-bar .bar-views button:active {
        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }
      .analytics-bar .bar-views button.pie .icon {
        width: 21px;
        height: 22px;
        background: url(images/icon-circle-graph.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .analytics-bar .bar-views button.column .icon {
        width: 14px;
        height: 20px;
        background: url(images/icon-chart-column.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .analytics-bar .bar-views button.table .icon {
        width: 27px;
        height: 14px;
        background: url(images/icon-chart-table.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .analytics-bar .bar-views button.active {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -ms-box-shadow: none;
        -o-box-shadow: none;
        box-shadow: none;
        border: 1px solid #b9e398; }
  .analytics-bar .bar-actions {
    position: relative;
    height: 100%; }
    .analytics-bar .bar-actions .print {
      position: relative;
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: none;
      color: #4b4b4b;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px;
      display: inline-flex;
      align-items: center; }
      .analytics-bar .bar-actions .print .text,
      .analytics-bar .bar-actions .print .icon {
        display: inline-block;
        vertical-align: top; }
      .analytics-bar .bar-actions .print .icon {
        margin-right: 0; }
      .analytics-bar .bar-actions .print.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .analytics-bar .bar-actions .print.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .analytics-bar .bar-actions .print.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .analytics-bar .bar-actions .print:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .analytics-bar .bar-actions .print {
    font-weight: normal; } }
      .analytics-bar .bar-actions .print:hover {
        background: none;
        color: black;
        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }
      .analytics-bar .bar-actions .print:active {
        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }
      .analytics-bar .bar-actions .print.print .icon {
        width: 23px;
        height: 16px;
        background: url(images/icon-print.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        margin-right: 10px; }
      .analytics-bar .bar-actions .print.print .icon-close {
        line-height: 54px;
        font-size: 32px;
        display: none;
        position: absolute;
        height: 100%;
        color: #4f4f4f;
        top: 0;
        right: 20px; }
      .analytics-bar .bar-actions .print.print .dropdown-title {
        display: none;
        position: absolute;
        top: 24px;
        right: 125px;
        font-size: 14px;
        line-height: 17px;
        color: #8694AA;
        font-weight: 500; }
    .analytics-bar .bar-actions .actions-dropdown {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      min-width: 200px;
      padding: 50px 0 25px;
      background: #fff;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      border-radius: 12px; }
      .analytics-bar .bar-actions .actions-dropdown-button {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b;
        display: block;
        position: relative;
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        box-sizing: border-box;
        width: 100%;
        padding: 10px 24px;
        font-weight: normal; }
        .analytics-bar .bar-actions .actions-dropdown-button:hover {
          color: black; }
        .analytics-bar .bar-actions .actions-dropdown-button .icon {
          position: absolute;
          top: 0;
          left: 0; }
        .analytics-bar .bar-actions .actions-dropdown-button .icon-about {
          width: 22px;
          height: 23px;
          background: url(images/icon-about-black.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
        .analytics-bar .bar-actions .actions-dropdown-button .icon-companies,
        .analytics-bar .bar-actions .actions-dropdown-button .icon-categories {
          width: 21px;
          height: 22px;
          background: url(images/icon-companies-black.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
        .analytics-bar .bar-actions .actions-dropdown-button .icon-settings,
        .analytics-bar .bar-actions .actions-dropdown-button .icon-owners,
        .analytics-bar .bar-actions .actions-dropdown-button .icon-workers,
        .analytics-bar .bar-actions .actions-dropdown-button .icon-partners {
          width: 22px;
          height: 22px;
          background: url(images/icon-profile-black.svg) no-repeat center center;
          display: inline-block;
          background-size: contain; }
        .analytics-bar .bar-actions .actions-dropdown-button .icon-license,
        .analytics-bar .bar-actions .actions-dropdown-button .icon-accounts {
          width: 22px;
          height: 22px;
          background: url(images/icon-ruble-black.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
        .analytics-bar .bar-actions .actions-dropdown-button .icon-logout {
          top: 2px;
          width: 21px;
          height: 19px;
          background: url(images/icon-logout-black.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
        .analytics-bar .bar-actions .actions-dropdown-button .icon-feedback {
          width: 22px;
          height: 22px;
          background: url(images/icon-edit-black.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
        .analytics-bar .bar-actions .actions-dropdown-button .icon-telegram {
          width: 22px;
          height: 22px;
          background: url(images/icon-telegram.svg) no-repeat center center;
          display: inline-block;
          background-size: contain; }
        .analytics-bar .bar-actions .actions-dropdown-button .icon-whatsapp {
          width: 22px;
          height: 22px;
          background: url(images/icon-whatsapp.svg) no-repeat center center;
          display: inline-block;
          background-size: contain; }
        .analytics-bar .bar-actions .actions-dropdown-button .text {
          display: block;
          margin-left: 34px; }
        .analytics-bar .bar-actions .actions-dropdown-button.button-telegram {
          color: #47aaed; }
        .analytics-bar .bar-actions .actions-dropdown-button.button-whatsapp {
          color: #4cba3f; }
        .analytics-bar .bar-actions .actions-dropdown-button .icon-xlsx {
          width: 22px;
          height: 22px;
          background: url(images/icon-xlsx.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
        .analytics-bar .bar-actions .actions-dropdown-button .icon-pdf {
          width: 22px;
          height: 22px;
          background: url(images/icon-pdf.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
        .analytics-bar .bar-actions .actions-dropdown-button .icon-word {
          width: 22px;
          height: 22px;
          background: url(images/icon-word.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
        .analytics-bar .bar-actions .actions-dropdown-button .icon-print {
          width: 23px;
          height: 16px;
          background: url(images/icon-print.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
        .analytics-bar .bar-actions .actions-dropdown-button .icon {
          top: 50%;
          left: 20px;
          margin: -10px 0 0 0; }
        .analytics-bar .bar-actions .actions-dropdown-button:hover {
          background: #f2f5fb; }
    .analytics-bar .bar-actions.opened .actions-dropdown {
      display: block; }
    .analytics-bar .bar-actions.opened .print {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      -ms-box-shadow: none;
      -o-box-shadow: none;
      box-shadow: none; }
      .analytics-bar .bar-actions.opened .print .icon-close, .analytics-bar .bar-actions.opened .print .dropdown-title {
        display: block; }
      .analytics-bar .bar-actions.opened .print .text, .analytics-bar .bar-actions.opened .print .icon {
        display: none; }

.analytics-graph .graph-header {
  margin-bottom: 36px; }
  .analytics-graph .graph-header .header-title {
    display: inline-block;
    margin-right: 18px;
    font-size: 16px;
    font-weight: 500;
    vertical-align: top; }
  .analytics-graph .graph-header .price {
    font-size: 0; }
    .analytics-graph .graph-header .price .int,
    .analytics-graph .graph-header .price .delim {
      font-size: 22px; }
    .analytics-graph .graph-header .price .float,
    .analytics-graph .graph-header .price .unit {
      font-size: 17px; }
    .analytics-graph .graph-header .price .unit {
      margin-left: 8px; }
    .analytics-graph .graph-header .price .unit-ruble:after {
      content: "\20bd"; }
    .analytics-graph .graph-header .price.income, .analytics-graph .graph-header .price.transfer-income {
      color: #4dbd7d; }
      .analytics-graph .graph-header .price.income .int,
      .analytics-graph .graph-header .price.income .delim,
      .analytics-graph .graph-header .price.income .float,
      .analytics-graph .graph-header .price.income .unit, .analytics-graph .graph-header .price.transfer-income .int,
      .analytics-graph .graph-header .price.transfer-income .delim,
      .analytics-graph .graph-header .price.transfer-income .float,
      .analytics-graph .graph-header .price.transfer-income .unit {
        color: #4dbd7d; }
    .analytics-graph .graph-header .price * {
      font-weight: 500; }

.analytics-graph .graph-chart {
  margin-bottom: 60px; }
  .analytics-graph .graph-chart .pie-chart {
    width: 178px;
    height: 178px; }
  .analytics-graph .graph-chart .pie-chart-empty {
    width: 145px;
    height: 145px;
    margin-bottom: 10px;
    border: 16px solid #8494a9;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    opacity: 0.6;
    filter: alpha(opacity=60);
    filter: progid:DXImageTransform.Microsoft.Alpha Opacity=60;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
  .analytics-graph .graph-chart .column-chart {
    width: 100%;
    height: 178px; }
  .analytics-graph .graph-chart .column-chart-empty {
    width: 100%;
    height: 178px;
    margin-bottom: 10px; }
    .analytics-graph .graph-chart .column-chart-empty .line {
      width: 100%;
      height: 1px;
      margin-bottom: 21px;
      background: #8494a9;
      opacity: 0.5;
      filter: alpha(opacity=50);
      filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .analytics-graph .graph-chart .column-chart-empty .line.mid {
        opacity: 0.2;
        filter: alpha(opacity=20);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=20;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .analytics-graph .graph-chart .column-chart-empty .line.bottom {
        margin-bottom: 0;
        background: #4b4b4b;
        opacity: 1;
        filter: alpha(opacity=100);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }

.analytics-graph .chart-note {
  width: 178px;
  text-align: center;
  color: #8494a9; }
  .analytics-graph .chart-note.chart-type-column, .analytics-graph .chart-note.chart-type-table {
    text-align: left; }

.analytics-graph .graph-markers {
  margin-bottom: 32px; }

.analytics-graph .markers-list-item {
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  margin-bottom: 12px; }
  .analytics-graph .markers-list-item .list-item-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .analytics-graph .markers-list-item .list-item-col.icon {
      width: 38px;
      flex-shrink: 0; }
    .analytics-graph .markers-list-item .list-item-col.caption {
      margin-right: auto;
      margin-left: 18px; }
    .analytics-graph .markers-list-item .list-item-col.price {
      flex-shrink: 0;
      text-align: right; }
    .analytics-graph .markers-list-item .list-item-col .note {
      font-size: 12px;
      color: #8494a9; }
  .analytics-graph .markers-list-item .list-item-col.icon {
    width: 40px;
    height: 10px;
    background: #b9e398;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px; }
    .analytics-graph .markers-list-item .list-item-col.icon.color-2 {
      background: #7bb0f9; }
    .analytics-graph .markers-list-item .list-item-col.icon.color-3 {
      background: #c581dd; }
    .analytics-graph .markers-list-item .list-item-col.icon.color-4 {
      background: #e9eb86; }
    .analytics-graph .markers-list-item .list-item-col.icon.color-5 {
      background: #f671c3; }
    .analytics-graph .markers-list-item .list-item-col.icon.color-6 {
      background: #efd47b; }
    .analytics-graph .markers-list-item .list-item-col.icon.color-7 {
      background: #9da4fa; }
  .analytics-graph .markers-list-item .list-item-col.caption {
    background: none;
    border: none;
    cursor: pointer;
    color: #4b4b4b;
    font-size: 17px;
    margin-left: 21px; }
    .analytics-graph .markers-list-item .list-item-col.caption:hover {
      color: black; }
  .analytics-graph .markers-list-item .value {
    flex-shrink: 0;
    margin-left: 8px; }
    .analytics-graph .markers-list-item .value .price {
      font-size: 0; }
      .analytics-graph .markers-list-item .value .price .int,
      .analytics-graph .markers-list-item .value .price .delim {
        font-size: 17px; }
      .analytics-graph .markers-list-item .value .price .float,
      .analytics-graph .markers-list-item .value .price .unit {
        font-size: 14px; }
      .analytics-graph .markers-list-item .value .price .unit {
        margin-left: 8px; }
      .analytics-graph .markers-list-item .value .price .unit-ruble:after {
        content: "\20bd"; }
      .analytics-graph .markers-list-item .value .price.income, .analytics-graph .markers-list-item .value .price.transfer-income {
        color: #4dbd7d; }
        .analytics-graph .markers-list-item .value .price.income .int,
        .analytics-graph .markers-list-item .value .price.income .delim,
        .analytics-graph .markers-list-item .value .price.income .float,
        .analytics-graph .markers-list-item .value .price.income .unit, .analytics-graph .markers-list-item .value .price.transfer-income .int,
        .analytics-graph .markers-list-item .value .price.transfer-income .delim,
        .analytics-graph .markers-list-item .value .price.transfer-income .float,
        .analytics-graph .markers-list-item .value .price.transfer-income .unit {
          color: #4dbd7d; }
      .analytics-graph .markers-list-item .value .price .unit,
      .analytics-graph .markers-list-item .value .price .float {
        color: #8494a9; }

.analytics-graph .show-all-button {
  display: inline-block;
  height: 50px;
  line-height: 48px;
  padding: 0 20px;
  text-align: center;
  vertical-align: top;
  background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
  border: 0;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  box-sizing: border-box;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
  -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
  -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
  -o-transition: background 0.1s ease, box-shadow 0.1s ease;
  transition: background 0.1s ease, box-shadow 0.1s ease;
  background: none;
  border: 1px solid #4b4b4b;
  color: #4b4b4b;
  height: 40px;
  line-height: 38px;
  font-size: 14px;
  font-weight: normal;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  width: 100%; }
  .analytics-graph .show-all-button .text,
  .analytics-graph .show-all-button .icon {
    display: inline-block;
    vertical-align: top; }
  .analytics-graph .show-all-button .icon {
    margin-right: 0; }
  .analytics-graph .show-all-button.button-xs {
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    font-weight: normal;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    border-radius: 15px; }
  .analytics-graph .show-all-button.button-s {
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px; }
  .analytics-graph .show-all-button.button-l {
    height: 60px;
    line-height: 58px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px; }
  .analytics-graph .show-all-button:hover {
    background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .analytics-graph .show-all-button {
    font-weight: normal; } }
  .analytics-graph .show-all-button:hover {
    background: #f2f5fb; }

/* CABINET SETTINGS */
.cabinet-settings-layout .layout-title {
  margin-bottom: 24px; }
  .cabinet-settings-layout .layout-title p {
    font-size: 22px;
    font-weight: 500; }

.cabinet-settings-layout .layout-body {
  display: block;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: box-shadow 0.1s ease;
  -moz-transition: box-shadow 0.1s ease;
  -ms-transition: box-shadow 0.1s ease;
  -o-transition: box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease;
  padding: 42px 50px; }

@media screen and (max-width: 450px) {
  .cabinet-settings-layout .layout-body {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }

.cabinet-settings-layout .settings-form {
  max-width: 510px; }
  .cabinet-settings-layout .settings-form .form-field {
    margin-bottom: 38px;
    width: 100%; }
    .cabinet-settings-layout .settings-form .form-field .form-label {
      display: block;
      font-size: 17px;
      color: #8494a9; }
    .cabinet-settings-layout .settings-form .form-field.field-type-colorpicker .form-label {
      margin-bottom: 8px; }
    .cabinet-settings-layout .settings-form .form-field.field-type-files .form-label {
      display: none; }
    .cabinet-settings-layout .settings-form .form-field .custom-checkput {
      position: relative;
      display: inline-block;
      width: auto;
      height: auto; }
      .cabinet-settings-layout .settings-form .form-field .custom-checkput input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-settings-layout .settings-form .form-field .custom-checkput .checkput-icon {
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 2px solid #8494a9;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
        cursor: pointer; }
      .cabinet-settings-layout .settings-form .form-field .custom-checkput.checked .checkput-icon {
        position: relative;
        display: inline-block;
        width: 22px;
        height: 22px;
        background: #81bc52;
        border: 0;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px; }
        .cabinet-settings-layout .settings-form .form-field .custom-checkput.checked .checkput-icon::after {
          content: "";
          width: 16px;
          height: 12px;
          background: url(images/icon-check-white.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          position: absolute;
          top: 7px;
          left: 4px;
          width: 13px;
          height: 9px; }
    .cabinet-settings-layout .settings-form .form-field.field-type-checkbox .custom-checkput, .cabinet-settings-layout .settings-form .form-field.field-type-agreement .custom-checkput, .cabinet-settings-layout .settings-form .form-field.field-type-license-agreement .custom-checkput {
      float: left; }
    .cabinet-settings-layout .settings-form .form-field.field-type-checkbox .form-label, .cabinet-settings-layout .settings-form .form-field.field-type-agreement .form-label, .cabinet-settings-layout .settings-form .form-field.field-type-license-agreement .form-label {
      margin-left: 34px;
      cursor: pointer; }
    .cabinet-settings-layout .settings-form .form-field.field-type-agreement button, .cabinet-settings-layout .settings-form .form-field.field-type-license-agreement button {
      display: block;
      margin-left: 34px;
      height: auto;
      line-height: 22px;
      font-size: 17px;
      color: #8494a9;
      text-decoration: underline;
      background: none; }
    .cabinet-settings-layout .settings-form .form-field .form-field-password-generator button {
      color: #2f80ed;
      cursor: pointer;
      background: transparent; }
    .cabinet-settings-layout .settings-form .form-field .form-field-password-check {
      line-height: 16px;
      font-size: 12px;
      position: relative;
      margin-top: -2px;
      z-index: 1; }
      .cabinet-settings-layout .settings-form .form-field .form-field-password-check.active-red {
        width: 30%;
        border-top: 2px solid #F35E84;
        margin-bottom: 11px; }
      .cabinet-settings-layout .settings-form .form-field .form-field-password-check.active-green {
        width: 100%;
        border-top: 2px solid #5A9C76;
        margin-bottom: 11px; }
      .cabinet-settings-layout .settings-form .form-field .form-field-password-check.active-yellow {
        width: 70%;
        border-top: 2px solid #C99300;
        margin-bottom: 11px; }
      .cabinet-settings-layout .settings-form .form-field .form-field-password-check .password-check-hint-green {
        color: #5A9C76; }
      .cabinet-settings-layout .settings-form .form-field .form-field-password-check .password-check-hint-yellow {
        color: #C99300; }
      .cabinet-settings-layout .settings-form .form-field .form-field-password-check .password-check-hint-red {
        color: #F35E84; }

@media screen and (max-width: 500px) {
  .cabinet-settings-layout .settings-form .form-field {
    margin-bottom: 28px; }
    .cabinet-settings-layout .settings-form .form-field .form-label {
      font-size: 14px;
      line-height: 14px; } }
    .cabinet-settings-layout .settings-form .form-field .form-button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      width: 100%; }
      .cabinet-settings-layout .settings-form .form-field .form-button .text,
      .cabinet-settings-layout .settings-form .form-field .form-button .icon {
        display: inline-block;
        vertical-align: top; }
      .cabinet-settings-layout .settings-form .form-field .form-button .icon {
        margin-right: 0; }
      .cabinet-settings-layout .settings-form .form-field .form-button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .cabinet-settings-layout .settings-form .form-field .form-button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .cabinet-settings-layout .settings-form .form-field .form-button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .cabinet-settings-layout .settings-form .form-field .form-button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-settings-layout .settings-form .form-field .form-button {
    font-weight: normal; } }
    .cabinet-settings-layout .settings-form .form-field.field-error .form-label {
      color: #f32424; }
    .cabinet-settings-layout .settings-form .form-field.field-error .form-control .form-input,
    .cabinet-settings-layout .settings-form .form-field.field-error .form-control textarea,
    .cabinet-settings-layout .settings-form .form-field.field-error .form-control select {
      border-color: #f32424; }
    .cabinet-settings-layout .settings-form .form-field.field-error .form-field-error p {
      color: #f32424;
      white-space: pre-wrap; }
  .cabinet-settings-layout .settings-form .form-control {
    position: relative; }
    .cabinet-settings-layout .settings-form .form-control .form-input,
    .cabinet-settings-layout .settings-form .form-control textarea,
    .cabinet-settings-layout .settings-form .form-control select {
      width: 100%;
      height: 34px;
      line-height: 34px;
      padding: 0;
      background: none;
      border: 0;
      border-bottom: 1px solid #8d76f0;
      font-size: 17px;
      outline: none; }
      .cabinet-settings-layout .settings-form .form-control .form-input::-webkit-input-placeholder,
      .cabinet-settings-layout .settings-form .form-control textarea::-webkit-input-placeholder,
      .cabinet-settings-layout .settings-form .form-control select::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: rgba(132, 148, 169, 0.5); }
      .cabinet-settings-layout .settings-form .form-control .form-input:-moz-placeholder,
      .cabinet-settings-layout .settings-form .form-control textarea:-moz-placeholder,
      .cabinet-settings-layout .settings-form .form-control select:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .cabinet-settings-layout .settings-form .form-control .form-input::-moz-placeholder,
      .cabinet-settings-layout .settings-form .form-control textarea::-moz-placeholder,
      .cabinet-settings-layout .settings-form .form-control select::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .cabinet-settings-layout .settings-form .form-control .form-input:-ms-input-placeholder,
      .cabinet-settings-layout .settings-form .form-control textarea:-ms-input-placeholder,
      .cabinet-settings-layout .settings-form .form-control select:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(132, 148, 169, 0.5); }
      .cabinet-settings-layout .settings-form .form-control .form-input::-ms-input-placeholder,
      .cabinet-settings-layout .settings-form .form-control textarea::-ms-input-placeholder,
      .cabinet-settings-layout .settings-form .form-control select::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(132, 148, 169, 0.5); }
      .cabinet-settings-layout .settings-form .form-control .form-input::placeholder,
      .cabinet-settings-layout .settings-form .form-control textarea::placeholder,
      .cabinet-settings-layout .settings-form .form-control select::placeholder {
        /* Most modern browsers support this now. */
        color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .cabinet-settings-layout .settings-form .form-control .form-input,
  .cabinet-settings-layout .settings-form .form-control textarea,
  .cabinet-settings-layout .settings-form .form-control select {
    font-size: 16px; } }
    .cabinet-settings-layout .settings-form .form-control textarea {
      resize: none;
      padding: 5px 0;
      line-height: 22px; }
    .cabinet-settings-layout .settings-form .form-control select {
      cursor: pointer; }
    .cabinet-settings-layout .settings-form .form-control .styled-select {
      position: relative; }
      .cabinet-settings-layout .settings-form .form-control .styled-select select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-settings-layout .settings-form .form-control .styled-select .select-button {
        position: relative;
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        width: 100%;
        height: auto;
        padding: 15px 30px;
        line-height: 20px;
        background: none;
        color: #4b4b4b;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        border-radius: 8px;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
        .cabinet-settings-layout .settings-form .form-control .styled-select .select-button .text,
        .cabinet-settings-layout .settings-form .form-control .styled-select .select-button .icon {
          display: inline-block;
          vertical-align: top; }
        .cabinet-settings-layout .settings-form .form-control .styled-select .select-button .icon {
          margin-right: 0; }
        .cabinet-settings-layout .settings-form .form-control .styled-select .select-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .cabinet-settings-layout .settings-form .form-control .styled-select .select-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .cabinet-settings-layout .settings-form .form-control .styled-select .select-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .cabinet-settings-layout .settings-form .form-control .styled-select .select-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-settings-layout .settings-form .form-control .styled-select .select-button {
    font-weight: normal; } }
        .cabinet-settings-layout .settings-form .form-control .styled-select .select-button .post-icon {
          position: relative;
          top: -1px;
          left: 8px;
          display: inline-block;
          width: 0; }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-button .post-icon:after {
            content: "";
            width: 14px;
            height: 7px;
            background: url(images/arrow-bottom.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
        .cabinet-settings-layout .settings-form .form-control .styled-select .select-button:hover {
          background: none;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
      .cabinet-settings-layout .settings-form .form-control .styled-select .select-list {
        display: block;
        background: #fff;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        -webkit-transition: box-shadow 0.1s ease;
        -moz-transition: box-shadow 0.1s ease;
        -ms-transition: box-shadow 0.1s ease;
        -o-transition: box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease;
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .cabinet-settings-layout .settings-form .form-control .styled-select .select-list {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
        .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 15px 50px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-button .text,
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-button .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-button .icon {
            margin-right: 0; }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-button {
    font-weight: normal; } }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-button.active .pre-icon {
            position: relative;
            left: -26px;
            display: inline-block;
            width: 0; }
            .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-button.active .pre-icon:after {
              content: "";
              width: 16px;
              height: 12px;
              background: url(images/icon-check.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-button:after {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 1px;
            background: rgba(0, 0, 0, 0.1);
            opacity: 0.5;
            filter: alpha(opacity=50);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-button:hover {
            background: #f2f5fb; }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-button:first-child:after {
            left: 23px;
            right: 23px;
            opacity: 1;
            filter: alpha(opacity=100);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-button:last-child {
            margin-bottom: 40px; }
        .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-action {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 15px 23px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          text-align: left;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-action .text,
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-action .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-action .icon {
            margin-right: 0; }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-action.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-action.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-action.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-action:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-action {
    font-weight: normal; } }
          .cabinet-settings-layout .settings-form .form-control .styled-select .select-list .select-list-action:hover {
            background: #f2f5fb; }
        .cabinet-settings-layout .settings-form .form-control .styled-select .select-list.show {
          display: block; }
      .cabinet-settings-layout .settings-form .form-control .styled-select.show-list .select-button {
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
    .cabinet-settings-layout .settings-form .form-control .search-select {
      position: relative; }
      .cabinet-settings-layout .settings-form .form-control .search-select select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-settings-layout .settings-form .form-control .search-select .search-field {
        position: relative;
        border-bottom: 1px solid #8d76f0; }
        .cabinet-settings-layout .settings-form .form-control .search-select .search-field input {
          width: 100%;
          height: 34px;
          line-height: 34px;
          padding: 0;
          background: none;
          border: 0;
          border-bottom: 1px solid #8d76f0;
          font-size: 17px;
          outline: none;
          width: 90%;
          border-bottom: 0; }
          .cabinet-settings-layout .settings-form .form-control .search-select .search-field input::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: rgba(132, 148, 169, 0.5); }
          .cabinet-settings-layout .settings-form .form-control .search-select .search-field input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .cabinet-settings-layout .settings-form .form-control .search-select .search-field input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .cabinet-settings-layout .settings-form .form-control .search-select .search-field input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(132, 148, 169, 0.5); }
          .cabinet-settings-layout .settings-form .form-control .search-select .search-field input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(132, 148, 169, 0.5); }
          .cabinet-settings-layout .settings-form .form-control .search-select .search-field input::placeholder {
            /* Most modern browsers support this now. */
            color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .cabinet-settings-layout .settings-form .form-control .search-select .search-field input {
    font-size: 16px; } }
          .cabinet-settings-layout .settings-form .form-control .search-select .search-field input::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: #4b4b4b; }
          .cabinet-settings-layout .settings-form .form-control .search-select .search-field input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: #4b4b4b;
            opacity: 1; }
          .cabinet-settings-layout .settings-form .form-control .search-select .search-field input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #4b4b4b;
            opacity: 1; }
          .cabinet-settings-layout .settings-form .form-control .search-select .search-field input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #4b4b4b; }
          .cabinet-settings-layout .settings-form .form-control .search-select .search-field input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #4b4b4b; }
          .cabinet-settings-layout .settings-form .form-control .search-select .search-field input::placeholder {
            /* Most modern browsers support this now. */
            color: #4b4b4b; }
        .cabinet-settings-layout .settings-form .form-control .search-select .search-field .field-dropdown-icon {
          position: absolute;
          top: 7px;
          right: 21px;
          width: 0; }
          .cabinet-settings-layout .settings-form .form-control .search-select .search-field .field-dropdown-icon:after {
            content: "";
            width: 14px;
            height: 7px;
            background: url(images/arrow-bottom.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
      .cabinet-settings-layout .settings-form .form-control .search-select .select-list-container {
        display: block;
        background: #fff;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        -webkit-transition: box-shadow 0.1s ease;
        -moz-transition: box-shadow 0.1s ease;
        -ms-transition: box-shadow 0.1s ease;
        -o-transition: box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease;
        display: none;
        position: absolute;
        top: -17px;
        left: -17px;
        right: -17px;
        padding-top: 60px;
        padding-bottom: 17px;
        z-index: 1;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .cabinet-settings-layout .settings-form .form-control .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
      .cabinet-settings-layout .settings-form .form-control .search-select .select-list {
        position: relative;
        max-height: 200px;
        overflow: auto; }
        .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 10px 17px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          text-align: left;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-button .text,
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-button .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-button .icon {
            margin-right: 0; }
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-button {
    font-weight: normal; } }
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-button.active {
            background: #f2f5fb; }
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-button.selected {
            background: #f2f5fb; }
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-button:hover {
            background: #f2f5fb; }
        .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-action {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 10px 17px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          text-align: left;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-action .text,
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-action .icon {
            display: inline-block;
            vertical-align: top; }
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-action .icon {
            margin-right: 0; }
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-action.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-action.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-action.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-action:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-action {
    font-weight: normal; } }
          .cabinet-settings-layout .settings-form .form-control .search-select .select-list .select-list-action:hover {
            background: #f2f5fb; }
      .cabinet-settings-layout .settings-form .form-control .search-select.show-list .select-list-container {
        display: block; }
      .cabinet-settings-layout .settings-form .form-control .search-select.show-list .search-field {
        z-index: 1;
        border-bottom-color: rgba(0, 0, 0, 0.1); }
    .cabinet-settings-layout .settings-form .form-control .field-price-input-container {
      position: relative;
      width: 100%; }
      .cabinet-settings-layout .settings-form .form-control .field-price-input-container .unit-box {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        text-align: center;
        white-space: nowrap; }
        .cabinet-settings-layout .settings-form .form-control .field-price-input-container .unit-box span {
          display: inline-block; }
        .cabinet-settings-layout .settings-form .form-control .field-price-input-container .unit-box .unit-background-text {
          color: transparent;
          font-size: 38px;
          max-width: 95%;
          overflow: hidden; }
        .cabinet-settings-layout .settings-form .form-control .field-price-input-container .unit-box .unit-background-text-space {
          width: 24px; }
      .cabinet-settings-layout .settings-form .form-control .field-price-input-container input {
        position: relative;
        height: 50px;
        line-height: 50px;
        box-sizing: border-box;
        text-align: center;
        font-size: 38px; }
      .cabinet-settings-layout .settings-form .form-control .field-price-input-container .unit-ruble {
        line-height: 52px;
        font-size: 32px;
        color: #8494a9;
        opacity: 0.7;
        filter: alpha(opacity=70);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=70;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .cabinet-settings-layout .settings-form .form-control .field-price-input-container .unit-ruble:after {
          content: "\20bd"; }
    .cabinet-settings-layout .settings-form .form-control .colorpicker {
      position: relative; }
      .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-button {
        display: block;
        width: 105px;
        height: 30px;
        background: none;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette {
        display: block;
        background: #fff;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        -webkit-transition: box-shadow 0.1s ease;
        -moz-transition: box-shadow 0.1s ease;
        -ms-transition: box-shadow 0.1s ease;
        -o-transition: box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease;
        position: absolute;
        left: 0;
        bottom: -200px;
        width: 504px;
        padding: 32px 20px;
        z-index: 4;
        -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 450px) {
  .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
        .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette .palette-header {
          margin-bottom: 40px;
          margin-left: 10px;
          font-size: 22px; }
        .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette .palette-color-buttons {
          margin-bottom: 12px; }
        .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette .palette-color-button {
          display: inline-block;
          width: 60px;
          height: 60px;
          margin: 0 12px 24px 12px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%; }
          .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette .palette-color-button.active, .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette .palette-color-button:hover {
            margin: -10px 2px 14px 2px;
            width: 80px;
            height: 80px;
            border: 10px solid #f2f5fb; }
        .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette .palette-corner-close-button {
          position: absolute;
          top: 0;
          right: 0;
          width: 80px;
          height: 80px;
          vertical-align: middle;
          text-align: center;
          font-size: 38px;
          font-family: "times new roman", verdana;
          background: none; }
        .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette .palette-bottom-buttons {
          text-align: right; }
          .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            background: none;
            border: 1px solid #4b4b4b;
            color: #4b4b4b;
            font-size: 16px;
            min-width: 191px; }
            .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
            .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
              display: inline-block;
              vertical-align: top; }
            .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
              margin-right: 0; }
            .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
    font-weight: normal; } }
            .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
              background: #f2f5fb; }
      .cabinet-settings-layout .settings-form .form-control .colorpicker .picker-palette-overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7);
        z-index: 2; }
    .cabinet-settings-layout .settings-form .form-control .form-autocomplete-input {
      position: relative; }
      .cabinet-settings-layout .settings-form .form-control .form-autocomplete-input .form-autocomplete-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 300px;
        background: #fff;
        -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        -o-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        z-index: 1;
        overflow: auto; }
        .cabinet-settings-layout .settings-form .form-control .form-autocomplete-input .form-autocomplete-dropdown button {
          background: none;
          border: none;
          width: 100%;
          padding: 8px 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          text-align: left; }
          .cabinet-settings-layout .settings-form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover {
            background: #f2f5fb; }
          .cabinet-settings-layout .settings-form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child {
            border-bottom: 0; }
    .cabinet-settings-layout .settings-form .form-control .field-text-list-container .text-list-item {
      position: relative;
      margin-bottom: 10px; }
      .cabinet-settings-layout .settings-form .form-control .field-text-list-container .text-list-item .remove-text-item {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b;
        position: absolute;
        top: 0;
        right: 0;
        color: #f32424;
        font-size: 22px; }
        .cabinet-settings-layout .settings-form .form-control .field-text-list-container .text-list-item .remove-text-item:hover {
          color: black; }
    .cabinet-settings-layout .settings-form .form-control .field-text-list-container .add-text-item {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b; }
      .cabinet-settings-layout .settings-form .form-control .field-text-list-container .add-text-item:hover {
        color: black; }
    .cabinet-settings-layout .settings-form .form-control .files-loader-container .files-loader-input-container {
      margin-bottom: 20px; }
    .cabinet-settings-layout .settings-form .form-control .files-loader-input-container {
      position: relative; }
      .cabinet-settings-layout .settings-form .form-control .files-loader-input-container .input-label-area {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        background: none;
        border: 1px solid #4b4b4b;
        color: #4b4b4b;
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px;
        width: 100%;
        height: 60px;
        line-height: 60px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
        border-radius: 8px; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-input-container .input-label-area .text,
        .cabinet-settings-layout .settings-form .form-control .files-loader-input-container .input-label-area .icon {
          display: inline-block;
          vertical-align: top; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-input-container .input-label-area .icon {
          margin-right: 0; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-input-container .input-label-area.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-input-container .input-label-area.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-input-container .input-label-area.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-input-container .input-label-area:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-settings-layout .settings-form .form-control .files-loader-input-container .input-label-area {
    font-weight: normal; } }
        .cabinet-settings-layout .settings-form .form-control .files-loader-input-container .input-label-area:hover {
          background: #f2f5fb; }
      .cabinet-settings-layout .settings-form .form-control .files-loader-input-container input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-settings-layout .settings-form .form-control .files-loader-input-container:hover .input-label-area {
        background: #f2f5fb; }
    .cabinet-settings-layout .settings-form .form-control .files-loader-list .files-list-item {
      display: flex;
      justify-content: space-between;
      text-align: left;
      margin-bottom: 10px; }
      .cabinet-settings-layout .settings-form .form-control .files-loader-list .files-list-item .list-item-col {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-list .files-list-item .list-item-col.icon {
          width: 38px;
          flex-shrink: 0; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-list .files-list-item .list-item-col.caption {
          margin-right: auto;
          margin-left: 18px; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-list .files-list-item .list-item-col.price {
          flex-shrink: 0;
          text-align: right; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-list .files-list-item .list-item-col .note {
          font-size: 12px;
          color: #8494a9; }
      .cabinet-settings-layout .settings-form .form-control .files-loader-list .files-list-item .icon {
        display: inline-block;
        width: 38px;
        height: 38px;
        line-height: 37px;
        text-align: center;
        font-size: 22px;
        font-weight: 500;
        color: #fff;
        background: #bed6f9;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
        font-size: 12px;
        font-weight: normal; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-list .files-list-item .icon.icon-sub-correction {
          line-height: 34.2px; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-list .files-list-item .icon.color-1 {
          background: #bed6f9; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-list .files-list-item .icon.color-2 {
          background: #b9e398; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-list .files-list-item .icon.color-3 {
          background: #e4e597; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-list .files-list-item .icon.color-4 {
          background: #e7c740; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-list .files-list-item .icon.color-5 {
          background: #e74040; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-list .files-list-item .icon.color-6 {
          background: #cf91af; }
      .cabinet-settings-layout .settings-form .form-control .files-loader-list .files-list-item .caption {
        height: 38px;
        line-height: 38px;
        margin-left: 12px; }
      .cabinet-settings-layout .settings-form .form-control .files-loader-list .files-list-item .remove {
        flex-shrink: 0;
        margin-left: 0; }
        .cabinet-settings-layout .settings-form .form-control .files-loader-list .files-list-item .remove button {
          background: none;
          border: none;
          height: 38px;
          line-height: 38px;
          font-size: 22px;
          color: #f32424; }
    .cabinet-settings-layout .settings-form .form-control .select-container .note {
      position: relative;
      padding-left: 25px;
      padding-top: 6px;
      font-size: 14px;
      color: #8494a9;
      line-height: 14px; }
      .cabinet-settings-layout .settings-form .form-control .select-container .note:before {
        content: "i";
        position: absolute;
        top: 5px;
        left: 0;
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 10px;
        line-height: 14px;
        border: 1px solid #8494a9;
        border-radius: 50%;
        text-align: center; }
    .cabinet-settings-layout .settings-form .form-control .input-phone {
      display: flex; }
      .cabinet-settings-layout .settings-form .form-control .input-phone .phone-country-code .styled-select {
        position: inherit; }
        .cabinet-settings-layout .settings-form .form-control .input-phone .phone-country-code .styled-select .select-button {
          padding: 0;
          width: 80px;
          text-align: left;
          box-shadow: none;
          line-height: 34px;
          border-bottom: 1px solid #8d76f0;
          border-radius: 0; }
          .cabinet-settings-layout .settings-form .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after {
            width: 8px; }
        .cabinet-settings-layout .settings-form .form-control .input-phone .phone-country-code .styled-select .select-list {
          top: 100%;
          width: 100%;
          max-height: 200px;
          margin-top: 10px;
          overflow: auto; }
          .cabinet-settings-layout .settings-form .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button {
            text-align: left; }
        .cabinet-settings-layout .settings-form .form-control .input-phone .phone-country-code .styled-select select {
          width: 80px; }
        .cabinet-settings-layout .settings-form .form-control .input-phone .phone-country-code .styled-select.show-list .select-button {
          opacity: 1;
          filter: alpha(opacity=100);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .cabinet-settings-layout .settings-form .form-control .input-phone .phone-number {
        flex: 1; }
    .cabinet-settings-layout .settings-form .form-control .password-input {
      position: relative; }
      .cabinet-settings-layout .settings-form .form-control .password-input .eye-icon {
        font-size: 18px;
        background-color: transparent;
        color: #8494a9;
        position: absolute;
        right: 0; }

@media screen and (max-width: 500px) {
  .cabinet-settings-layout .settings-form .form-control .search-select .search-field .field-dropdown-icon {
    top: 3px; } }
    .cabinet-settings-layout .settings-form .form-control .datepicker-popup {
      position: absolute;
      top: 100%;
      left: 0; }
  .cabinet-settings-layout .settings-form .form-errors-list {
    margin-bottom: 24px; }
    .cabinet-settings-layout .settings-form .form-errors-list .error-item {
      margin-bottom: 8px;
      color: #f32424; }
      .cabinet-settings-layout .settings-form .form-errors-list .error-item:last-child {
        margin-bottom: 0; }
  .cabinet-settings-layout .settings-form .form-messages-list {
    margin-bottom: 24px;
    text-align: center; }
    .cabinet-settings-layout .settings-form .form-messages-list .message-item {
      margin-bottom: 8px;
      color: #4dbd7d;
      font-size: 17px;
      line-height: 22px; }
      .cabinet-settings-layout .settings-form .form-messages-list .message-item:last-child {
        margin-bottom: 0; }
  .cabinet-settings-layout .settings-form .password-input .eye-icon {
    top: 5px; }

/* COMPANY DICTIONARY */
.company-dictionary-layout {
  display: flex; }
  .company-dictionary-layout .dictionary-content {
    flex: 1; }
    .company-dictionary-layout .dictionary-content .layout-title {
      margin-bottom: 32px; }
      .company-dictionary-layout .dictionary-content .layout-title h1 {
        font-size: 22px;
        font-weight: 500; }
    .company-dictionary-layout .dictionary-content .layout-actions {
      margin-bottom: 40px; }
      .company-dictionary-layout .dictionary-content .layout-actions button {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px;
        min-width: 206px; }
        .company-dictionary-layout .dictionary-content .layout-actions button .text,
        .company-dictionary-layout .dictionary-content .layout-actions button .icon {
          display: inline-block;
          vertical-align: top; }
        .company-dictionary-layout .dictionary-content .layout-actions button .icon {
          margin-right: 0; }
        .company-dictionary-layout .dictionary-content .layout-actions button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .company-dictionary-layout .dictionary-content .layout-actions button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .company-dictionary-layout .dictionary-content .layout-actions button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .company-dictionary-layout .dictionary-content .layout-actions button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .layout-actions button {
    font-weight: normal; } }
        .company-dictionary-layout .dictionary-content .layout-actions button.name-bank-integration {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          background: none;
          border: 1px solid #4b4b4b;
          color: #4b4b4b;
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
          .company-dictionary-layout .dictionary-content .layout-actions button.name-bank-integration .text,
          .company-dictionary-layout .dictionary-content .layout-actions button.name-bank-integration .icon {
            display: inline-block;
            vertical-align: top; }
          .company-dictionary-layout .dictionary-content .layout-actions button.name-bank-integration .icon {
            margin-right: 0; }
          .company-dictionary-layout .dictionary-content .layout-actions button.name-bank-integration.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .company-dictionary-layout .dictionary-content .layout-actions button.name-bank-integration.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .company-dictionary-layout .dictionary-content .layout-actions button.name-bank-integration.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .company-dictionary-layout .dictionary-content .layout-actions button.name-bank-integration:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .layout-actions button.name-bank-integration {
    font-weight: normal; } }
          .company-dictionary-layout .dictionary-content .layout-actions button.name-bank-integration:hover {
            background: #f2f5fb; }
    .company-dictionary-layout .dictionary-content .layout-plate {
      display: block;
      background: #fff;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border-radius: 10px;
      -webkit-transition: box-shadow 0.1s ease;
      -moz-transition: box-shadow 0.1s ease;
      -ms-transition: box-shadow 0.1s ease;
      -o-transition: box-shadow 0.1s ease;
      transition: box-shadow 0.1s ease; }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .layout-plate {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
    .company-dictionary-layout .dictionary-content .layout-cards-list {
      padding: 34px 0; }
      .company-dictionary-layout .dictionary-content .layout-cards-list .list-item {
        position: relative;
        padding: 26px 50px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
        .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .list-item-container {
          position: relative; }
        .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title {
          margin-bottom: 3px; }
          .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title p {
            color: #0e0e0e;
            font-size: 32px; }
          .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price {
            font-size: 0;
            color: #0e0e0e; }
            .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price .int,
            .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price .delim {
              font-size: 32px; }
            .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price .float,
            .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price .unit {
              font-size: 22px; }
            .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price .unit {
              margin-left: 8px; }
            .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price .unit-ruble:after {
              content: "\20bd"; }
            .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price.income, .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price.transfer-income {
              color: #4dbd7d; }
              .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price.income .int,
              .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price.income .delim,
              .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price.income .float,
              .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price.income .unit, .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price.transfer-income .int,
              .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price.transfer-income .delim,
              .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price.transfer-income .float,
              .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price.transfer-income .unit {
                color: #4dbd7d; }
            .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-title .price .unit {
              color: #8494a9; }
        .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-field p {
          font-size: 16px; }
        .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-actions {
          position: absolute;
          top: 4px;
          right: 0; }
          .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-actions .action-edit {
            width: 22px;
            height: 22px;
            background: url(images/icon-edit-gray.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
        .company-dictionary-layout .dictionary-content .layout-cards-list .list-item .li-mark {
          display: inline-block;
          padding: 0 7px;
          background: #b9e398;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          border-radius: 3px; }

@media screen and (max-width: 590px) {
  .company-dictionary-layout .dictionary-content .layout-cards-list {
    padding: 0; }
    .company-dictionary-layout .dictionary-content .layout-cards-list .list-item {
      padding: 24px 16px; } }
    .company-dictionary-layout .dictionary-content .form-layout {
      display: block;
      background: #fff;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      border-radius: 10px;
      -webkit-transition: box-shadow 0.1s ease;
      -moz-transition: box-shadow 0.1s ease;
      -ms-transition: box-shadow 0.1s ease;
      -o-transition: box-shadow 0.1s ease;
      transition: box-shadow 0.1s ease; }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .form-layout {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
      .company-dictionary-layout .dictionary-content .form-layout .back-button {
        margin-bottom: 24px; }
        .company-dictionary-layout .dictionary-content .form-layout .back-button button {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b; }
          .company-dictionary-layout .dictionary-content .form-layout .back-button button:hover {
            color: black; }
          .company-dictionary-layout .dictionary-content .form-layout .back-button button:before {
            content: "\2190";
            margin-right: 4px; }
      .company-dictionary-layout .dictionary-content .form-layout .form-content {
        padding: 60px 50px; }
        .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field {
          margin-bottom: 38px;
          width: 100%; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-label {
            display: block;
            font-size: 17px;
            color: #8494a9; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field.field-type-colorpicker .form-label {
            margin-bottom: 8px; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field.field-type-files .form-label {
            display: none; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .custom-checkput {
            position: relative;
            display: inline-block;
            width: auto;
            height: auto; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .custom-checkput input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              filter: alpha(opacity=0);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .custom-checkput .checkput-icon {
              position: relative;
              display: inline-block;
              width: 18px;
              height: 18px;
              border: 2px solid #8494a9;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              -ms-border-radius: 3px;
              -o-border-radius: 3px;
              border-radius: 3px;
              cursor: pointer; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .custom-checkput.checked .checkput-icon {
              position: relative;
              display: inline-block;
              width: 22px;
              height: 22px;
              background: #81bc52;
              border: 0;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              -ms-border-radius: 3px;
              -o-border-radius: 3px;
              border-radius: 3px; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .custom-checkput.checked .checkput-icon::after {
                content: "";
                width: 16px;
                height: 12px;
                background: url(images/icon-check-white.png) no-repeat center center;
                display: inline-block;
                background-size: contain;
                position: absolute;
                top: 7px;
                left: 4px;
                width: 13px;
                height: 9px; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field.field-type-checkbox .custom-checkput, .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field.field-type-agreement .custom-checkput, .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field.field-type-license-agreement .custom-checkput {
            float: left; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field.field-type-checkbox .form-label, .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field.field-type-agreement .form-label, .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field.field-type-license-agreement .form-label {
            margin-left: 34px;
            cursor: pointer; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field.field-type-agreement button, .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field.field-type-license-agreement button {
            display: block;
            margin-left: 34px;
            height: auto;
            line-height: 22px;
            font-size: 17px;
            color: #8494a9;
            text-decoration: underline;
            background: none; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-field-password-generator button {
            color: #2f80ed;
            cursor: pointer;
            background: transparent; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-field-password-check {
            line-height: 16px;
            font-size: 12px;
            position: relative;
            margin-top: -2px;
            z-index: 1; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-field-password-check.active-red {
              width: 30%;
              border-top: 2px solid #F35E84;
              margin-bottom: 11px; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-field-password-check.active-green {
              width: 100%;
              border-top: 2px solid #5A9C76;
              margin-bottom: 11px; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-field-password-check.active-yellow {
              width: 70%;
              border-top: 2px solid #C99300;
              margin-bottom: 11px; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-field-password-check .password-check-hint-green {
              color: #5A9C76; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-field-password-check .password-check-hint-yellow {
              color: #C99300; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-field-password-check .password-check-hint-red {
              color: #F35E84; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field {
    margin-bottom: 28px; }
    .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-label {
      font-size: 14px;
      line-height: 14px; } }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            width: 100%; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-button .text,
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-button .icon {
              display: inline-block;
              vertical-align: top; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-button .icon {
              margin-right: 0; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field .form-button {
    font-weight: normal; } }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field.field-error .form-label {
            color: #f32424; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field.field-error .form-control .form-input,
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field.field-error .form-control textarea,
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field.field-error .form-control select {
            border-color: #f32424; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field.field-error .form-field-error p {
            color: #f32424;
            white-space: pre-wrap; }
        .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control {
          position: relative; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .form-input,
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control textarea,
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control select {
            width: 100%;
            height: 34px;
            line-height: 34px;
            padding: 0;
            background: none;
            border: 0;
            border-bottom: 1px solid #8d76f0;
            font-size: 17px;
            outline: none; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .form-input::-webkit-input-placeholder,
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control textarea::-webkit-input-placeholder,
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control select::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: rgba(132, 148, 169, 0.5); }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .form-input:-moz-placeholder,
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control textarea:-moz-placeholder,
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control select:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .form-input::-moz-placeholder,
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control textarea::-moz-placeholder,
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control select::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .form-input:-ms-input-placeholder,
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control textarea:-ms-input-placeholder,
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control select:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: rgba(132, 148, 169, 0.5); }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .form-input::-ms-input-placeholder,
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control textarea::-ms-input-placeholder,
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control select::-ms-input-placeholder {
              /* Microsoft Edge */
              color: rgba(132, 148, 169, 0.5); }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .form-input::placeholder,
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control textarea::placeholder,
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control select::placeholder {
              /* Most modern browsers support this now. */
              color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .form-input,
  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control textarea,
  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control select {
    font-size: 16px; } }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control textarea {
            resize: none;
            padding: 5px 0;
            line-height: 22px; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control select {
            cursor: pointer; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select {
            position: relative; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select select {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              filter: alpha(opacity=0);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-button {
              position: relative;
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              width: 100%;
              height: auto;
              padding: 15px 30px;
              line-height: 20px;
              background: none;
              color: #4b4b4b;
              -webkit-border-radius: 8px;
              -moz-border-radius: 8px;
              -ms-border-radius: 8px;
              -o-border-radius: 8px;
              border-radius: 8px;
              -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-button .text,
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-button .icon {
                display: inline-block;
                vertical-align: top; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-button .icon {
                margin-right: 0; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-button.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-button.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-button.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-button:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-button {
    font-weight: normal; } }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-button .post-icon {
                position: relative;
                top: -1px;
                left: 8px;
                display: inline-block;
                width: 0; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-button .post-icon:after {
                  content: "";
                  width: 14px;
                  height: 7px;
                  background: url(images/arrow-bottom.png) no-repeat center center;
                  display: inline-block;
                  background-size: contain; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-button:hover {
                background: none;
                -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list {
              display: block;
              background: #fff;
              -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
              -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
              -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
              -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
              -webkit-border-radius: 10px;
              -moz-border-radius: 10px;
              -ms-border-radius: 10px;
              -o-border-radius: 10px;
              border-radius: 10px;
              -webkit-transition: box-shadow 0.1s ease;
              -moz-transition: box-shadow 0.1s ease;
              -ms-transition: box-shadow 0.1s ease;
              -o-transition: box-shadow 0.1s ease;
              transition: box-shadow 0.1s ease;
              display: none;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              z-index: 1;
              -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button {
                display: inline-block;
                height: 50px;
                line-height: 48px;
                padding: 0 20px;
                text-align: center;
                vertical-align: top;
                background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
                border: 0;
                color: #fff;
                font-size: 17px;
                font-weight: 500;
                box-sizing: border-box;
                -webkit-border-radius: 25px;
                -moz-border-radius: 25px;
                -ms-border-radius: 25px;
                -o-border-radius: 25px;
                border-radius: 25px;
                -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
                -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
                -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
                -o-transition: background 0.1s ease, box-shadow 0.1s ease;
                transition: background 0.1s ease, box-shadow 0.1s ease;
                position: relative;
                width: 100%;
                height: auto;
                padding: 15px 50px;
                line-height: 20px;
                background: none;
                color: #4b4b4b;
                font-weight: normal;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                -ms-border-radius: 0;
                -o-border-radius: 0;
                border-radius: 0; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button .text,
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button .icon {
                  display: inline-block;
                  vertical-align: top; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button .icon {
                  margin-right: 0; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button.button-xs {
                  height: 32px;
                  line-height: 32px;
                  font-size: 12px;
                  font-weight: normal;
                  -webkit-border-radius: 15px;
                  -moz-border-radius: 15px;
                  -ms-border-radius: 15px;
                  -o-border-radius: 15px;
                  border-radius: 15px; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button.button-s {
                  height: 40px;
                  line-height: 38px;
                  font-size: 14px;
                  font-weight: normal;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  -ms-border-radius: 20px;
                  -o-border-radius: 20px;
                  border-radius: 20px; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button.button-l {
                  height: 60px;
                  line-height: 58px;
                  -webkit-border-radius: 30px;
                  -moz-border-radius: 30px;
                  -ms-border-radius: 30px;
                  -o-border-radius: 30px;
                  border-radius: 30px; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button:hover {
                  background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button {
    font-weight: normal; } }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button.active .pre-icon {
                  position: relative;
                  left: -26px;
                  display: inline-block;
                  width: 0; }
                  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button.active .pre-icon:after {
                    content: "";
                    width: 16px;
                    height: 12px;
                    background: url(images/icon-check.png) no-repeat center center;
                    display: inline-block;
                    background-size: contain; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button:after {
                  content: "";
                  display: block;
                  position: absolute;
                  top: 100%;
                  left: 0;
                  right: 0;
                  height: 1px;
                  background: rgba(0, 0, 0, 0.1);
                  opacity: 0.5;
                  filter: alpha(opacity=50);
                  filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
                  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button:hover {
                  background: #f2f5fb; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button:first-child:after {
                  left: 23px;
                  right: 23px;
                  opacity: 1;
                  filter: alpha(opacity=100);
                  filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
                  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-button:last-child {
                  margin-bottom: 40px; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action {
                display: inline-block;
                height: 50px;
                line-height: 48px;
                padding: 0 20px;
                text-align: center;
                vertical-align: top;
                background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
                border: 0;
                color: #fff;
                font-size: 17px;
                font-weight: 500;
                box-sizing: border-box;
                -webkit-border-radius: 25px;
                -moz-border-radius: 25px;
                -ms-border-radius: 25px;
                -o-border-radius: 25px;
                border-radius: 25px;
                -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
                -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
                -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
                -o-transition: background 0.1s ease, box-shadow 0.1s ease;
                transition: background 0.1s ease, box-shadow 0.1s ease;
                position: relative;
                width: 100%;
                height: auto;
                padding: 15px 23px;
                line-height: 20px;
                background: none;
                color: #4b4b4b;
                font-weight: normal;
                text-align: left;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                -ms-border-radius: 0;
                -o-border-radius: 0;
                border-radius: 0; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action .text,
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action .icon {
                  display: inline-block;
                  vertical-align: top; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action .icon {
                  margin-right: 0; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action.button-xs {
                  height: 32px;
                  line-height: 32px;
                  font-size: 12px;
                  font-weight: normal;
                  -webkit-border-radius: 15px;
                  -moz-border-radius: 15px;
                  -ms-border-radius: 15px;
                  -o-border-radius: 15px;
                  border-radius: 15px; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action.button-s {
                  height: 40px;
                  line-height: 38px;
                  font-size: 14px;
                  font-weight: normal;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  -ms-border-radius: 20px;
                  -o-border-radius: 20px;
                  border-radius: 20px; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action.button-l {
                  height: 60px;
                  line-height: 58px;
                  -webkit-border-radius: 30px;
                  -moz-border-radius: 30px;
                  -ms-border-radius: 30px;
                  -o-border-radius: 30px;
                  border-radius: 30px; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action:hover {
                  background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action {
    font-weight: normal; } }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list .select-list-action:hover {
                  background: #f2f5fb; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select .select-list.show {
                display: block; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .styled-select.show-list .select-button {
              opacity: 0;
              filter: alpha(opacity=0);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select {
            position: relative; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select select {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              filter: alpha(opacity=0);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field {
              position: relative;
              border-bottom: 1px solid #8d76f0; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field input {
                width: 100%;
                height: 34px;
                line-height: 34px;
                padding: 0;
                background: none;
                border: 0;
                border-bottom: 1px solid #8d76f0;
                font-size: 17px;
                outline: none;
                width: 90%;
                border-bottom: 0; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field input::-webkit-input-placeholder {
                  /* WebKit, Blink, Edge */
                  color: rgba(132, 148, 169, 0.5); }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field input:-moz-placeholder {
                  /* Mozilla Firefox 4 to 18 */
                  color: rgba(132, 148, 169, 0.5);
                  opacity: 1; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field input::-moz-placeholder {
                  /* Mozilla Firefox 19+ */
                  color: rgba(132, 148, 169, 0.5);
                  opacity: 1; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field input:-ms-input-placeholder {
                  /* Internet Explorer 10-11 */
                  color: rgba(132, 148, 169, 0.5); }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field input::-ms-input-placeholder {
                  /* Microsoft Edge */
                  color: rgba(132, 148, 169, 0.5); }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field input::placeholder {
                  /* Most modern browsers support this now. */
                  color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field input {
    font-size: 16px; } }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field input::-webkit-input-placeholder {
                  /* WebKit, Blink, Edge */
                  color: #4b4b4b; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field input:-moz-placeholder {
                  /* Mozilla Firefox 4 to 18 */
                  color: #4b4b4b;
                  opacity: 1; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field input::-moz-placeholder {
                  /* Mozilla Firefox 19+ */
                  color: #4b4b4b;
                  opacity: 1; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field input:-ms-input-placeholder {
                  /* Internet Explorer 10-11 */
                  color: #4b4b4b; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field input::-ms-input-placeholder {
                  /* Microsoft Edge */
                  color: #4b4b4b; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field input::placeholder {
                  /* Most modern browsers support this now. */
                  color: #4b4b4b; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field .field-dropdown-icon {
                position: absolute;
                top: 7px;
                right: 21px;
                width: 0; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field .field-dropdown-icon:after {
                  content: "";
                  width: 14px;
                  height: 7px;
                  background: url(images/arrow-bottom.png) no-repeat center center;
                  display: inline-block;
                  background-size: contain; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list-container {
              display: block;
              background: #fff;
              -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
              -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
              -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
              -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
              -webkit-border-radius: 10px;
              -moz-border-radius: 10px;
              -ms-border-radius: 10px;
              -o-border-radius: 10px;
              border-radius: 10px;
              -webkit-transition: box-shadow 0.1s ease;
              -moz-transition: box-shadow 0.1s ease;
              -ms-transition: box-shadow 0.1s ease;
              -o-transition: box-shadow 0.1s ease;
              transition: box-shadow 0.1s ease;
              display: none;
              position: absolute;
              top: -17px;
              left: -17px;
              right: -17px;
              padding-top: 60px;
              padding-bottom: 17px;
              z-index: 1;
              -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list {
              position: relative;
              max-height: 200px;
              overflow: auto; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-button {
                display: inline-block;
                height: 50px;
                line-height: 48px;
                padding: 0 20px;
                text-align: center;
                vertical-align: top;
                background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
                border: 0;
                color: #fff;
                font-size: 17px;
                font-weight: 500;
                box-sizing: border-box;
                -webkit-border-radius: 25px;
                -moz-border-radius: 25px;
                -ms-border-radius: 25px;
                -o-border-radius: 25px;
                border-radius: 25px;
                -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
                -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
                -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
                -o-transition: background 0.1s ease, box-shadow 0.1s ease;
                transition: background 0.1s ease, box-shadow 0.1s ease;
                position: relative;
                width: 100%;
                height: auto;
                padding: 10px 17px;
                line-height: 20px;
                background: none;
                color: #4b4b4b;
                font-weight: normal;
                text-align: left;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                -ms-border-radius: 0;
                -o-border-radius: 0;
                border-radius: 0; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-button .text,
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-button .icon {
                  display: inline-block;
                  vertical-align: top; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-button .icon {
                  margin-right: 0; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-button.button-xs {
                  height: 32px;
                  line-height: 32px;
                  font-size: 12px;
                  font-weight: normal;
                  -webkit-border-radius: 15px;
                  -moz-border-radius: 15px;
                  -ms-border-radius: 15px;
                  -o-border-radius: 15px;
                  border-radius: 15px; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-button.button-s {
                  height: 40px;
                  line-height: 38px;
                  font-size: 14px;
                  font-weight: normal;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  -ms-border-radius: 20px;
                  -o-border-radius: 20px;
                  border-radius: 20px; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-button.button-l {
                  height: 60px;
                  line-height: 58px;
                  -webkit-border-radius: 30px;
                  -moz-border-radius: 30px;
                  -ms-border-radius: 30px;
                  -o-border-radius: 30px;
                  border-radius: 30px; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-button:hover {
                  background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-button {
    font-weight: normal; } }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-button.active {
                  background: #f2f5fb; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-button.selected {
                  background: #f2f5fb; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-button:hover {
                  background: #f2f5fb; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-action {
                display: inline-block;
                height: 50px;
                line-height: 48px;
                padding: 0 20px;
                text-align: center;
                vertical-align: top;
                background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
                border: 0;
                color: #fff;
                font-size: 17px;
                font-weight: 500;
                box-sizing: border-box;
                -webkit-border-radius: 25px;
                -moz-border-radius: 25px;
                -ms-border-radius: 25px;
                -o-border-radius: 25px;
                border-radius: 25px;
                -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
                -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
                -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
                -o-transition: background 0.1s ease, box-shadow 0.1s ease;
                transition: background 0.1s ease, box-shadow 0.1s ease;
                position: relative;
                width: 100%;
                height: auto;
                padding: 10px 17px;
                line-height: 20px;
                background: none;
                color: #4b4b4b;
                font-weight: normal;
                text-align: left;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                -ms-border-radius: 0;
                -o-border-radius: 0;
                border-radius: 0; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-action .text,
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-action .icon {
                  display: inline-block;
                  vertical-align: top; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-action .icon {
                  margin-right: 0; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-action.button-xs {
                  height: 32px;
                  line-height: 32px;
                  font-size: 12px;
                  font-weight: normal;
                  -webkit-border-radius: 15px;
                  -moz-border-radius: 15px;
                  -ms-border-radius: 15px;
                  -o-border-radius: 15px;
                  border-radius: 15px; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-action.button-s {
                  height: 40px;
                  line-height: 38px;
                  font-size: 14px;
                  font-weight: normal;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  -ms-border-radius: 20px;
                  -o-border-radius: 20px;
                  border-radius: 20px; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-action.button-l {
                  height: 60px;
                  line-height: 58px;
                  -webkit-border-radius: 30px;
                  -moz-border-radius: 30px;
                  -ms-border-radius: 30px;
                  -o-border-radius: 30px;
                  border-radius: 30px; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-action:hover {
                  background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-action {
    font-weight: normal; } }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .select-list .select-list-action:hover {
                  background: #f2f5fb; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select.show-list .select-list-container {
              display: block; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select.show-list .search-field {
              z-index: 1;
              border-bottom-color: rgba(0, 0, 0, 0.1); }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .field-price-input-container {
            position: relative;
            width: 100%; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .field-price-input-container .unit-box {
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              text-align: center;
              white-space: nowrap; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .field-price-input-container .unit-box span {
                display: inline-block; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .field-price-input-container .unit-box .unit-background-text {
                color: transparent;
                font-size: 38px;
                max-width: 95%;
                overflow: hidden; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .field-price-input-container .unit-box .unit-background-text-space {
                width: 24px; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .field-price-input-container input {
              position: relative;
              height: 50px;
              line-height: 50px;
              box-sizing: border-box;
              text-align: center;
              font-size: 38px; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .field-price-input-container .unit-ruble {
              line-height: 52px;
              font-size: 32px;
              color: #8494a9;
              opacity: 0.7;
              filter: alpha(opacity=70);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=70;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .field-price-input-container .unit-ruble:after {
                content: "\20bd"; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker {
            position: relative; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-button {
              display: block;
              width: 105px;
              height: 30px;
              background: none;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette {
              display: block;
              background: #fff;
              -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
              -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
              -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
              -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
              -webkit-border-radius: 10px;
              -moz-border-radius: 10px;
              -ms-border-radius: 10px;
              -o-border-radius: 10px;
              border-radius: 10px;
              -webkit-transition: box-shadow 0.1s ease;
              -moz-transition: box-shadow 0.1s ease;
              -ms-transition: box-shadow 0.1s ease;
              -o-transition: box-shadow 0.1s ease;
              transition: box-shadow 0.1s ease;
              position: absolute;
              left: 0;
              bottom: -200px;
              width: 504px;
              padding: 32px 20px;
              z-index: 4;
              -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
              -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
              -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
              -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
              box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-header {
                margin-bottom: 40px;
                margin-left: 10px;
                font-size: 22px; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-color-buttons {
                margin-bottom: 12px; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-color-button {
                display: inline-block;
                width: 60px;
                height: 60px;
                margin: 0 12px 24px 12px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                border-radius: 50%; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-color-button.active, .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-color-button:hover {
                  margin: -10px 2px 14px 2px;
                  width: 80px;
                  height: 80px;
                  border: 10px solid #f2f5fb; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-corner-close-button {
                position: absolute;
                top: 0;
                right: 0;
                width: 80px;
                height: 80px;
                vertical-align: middle;
                text-align: center;
                font-size: 38px;
                font-family: "times new roman", verdana;
                background: none; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons {
                text-align: right; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
                  display: inline-block;
                  height: 50px;
                  line-height: 48px;
                  padding: 0 20px;
                  text-align: center;
                  vertical-align: top;
                  background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
                  border: 0;
                  color: #fff;
                  font-size: 17px;
                  font-weight: 500;
                  box-sizing: border-box;
                  -webkit-border-radius: 25px;
                  -moz-border-radius: 25px;
                  -ms-border-radius: 25px;
                  -o-border-radius: 25px;
                  border-radius: 25px;
                  -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
                  -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
                  -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
                  -o-transition: background 0.1s ease, box-shadow 0.1s ease;
                  transition: background 0.1s ease, box-shadow 0.1s ease;
                  background: none;
                  border: 1px solid #4b4b4b;
                  color: #4b4b4b;
                  font-size: 16px;
                  min-width: 191px; }
                  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
                  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                    display: inline-block;
                    vertical-align: top; }
                  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                    margin-right: 0; }
                  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs {
                    height: 32px;
                    line-height: 32px;
                    font-size: 12px;
                    font-weight: normal;
                    -webkit-border-radius: 15px;
                    -moz-border-radius: 15px;
                    -ms-border-radius: 15px;
                    -o-border-radius: 15px;
                    border-radius: 15px; }
                  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s {
                    height: 40px;
                    line-height: 38px;
                    font-size: 14px;
                    font-weight: normal;
                    -webkit-border-radius: 20px;
                    -moz-border-radius: 20px;
                    -ms-border-radius: 20px;
                    -o-border-radius: 20px;
                    border-radius: 20px; }
                  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l {
                    height: 60px;
                    line-height: 58px;
                    -webkit-border-radius: 30px;
                    -moz-border-radius: 30px;
                    -ms-border-radius: 30px;
                    -o-border-radius: 30px;
                    border-radius: 30px; }
                  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                    background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
    font-weight: normal; } }
                  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                    background: #f2f5fb; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .colorpicker .picker-palette-overlay {
              position: fixed;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background: rgba(255, 255, 255, 0.7);
              z-index: 2; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .form-autocomplete-input {
            position: relative; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .form-autocomplete-input .form-autocomplete-dropdown {
              position: absolute;
              top: 100%;
              left: 0;
              width: 100%;
              max-height: 300px;
              background: #fff;
              -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
              -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
              -ms-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
              -o-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
              box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
              z-index: 1;
              overflow: auto; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .form-autocomplete-input .form-autocomplete-dropdown button {
                background: none;
                border: none;
                width: 100%;
                padding: 8px 10px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                text-align: left; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover {
                  background: #f2f5fb; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child {
                  border-bottom: 0; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .field-text-list-container .text-list-item {
            position: relative;
            margin-bottom: 10px; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .field-text-list-container .text-list-item .remove-text-item {
              background: none;
              border: none;
              cursor: pointer;
              color: #4b4b4b;
              position: absolute;
              top: 0;
              right: 0;
              color: #f32424;
              font-size: 22px; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .field-text-list-container .text-list-item .remove-text-item:hover {
                color: black; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .field-text-list-container .add-text-item {
            background: none;
            border: none;
            cursor: pointer;
            color: #4b4b4b; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .field-text-list-container .add-text-item:hover {
              color: black; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-container .files-loader-input-container {
            margin-bottom: 20px; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-input-container {
            position: relative; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              background: none;
              border: 1px solid #4b4b4b;
              color: #4b4b4b;
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px;
              width: 100%;
              height: 60px;
              line-height: 60px;
              -webkit-border-radius: 8px;
              -moz-border-radius: 8px;
              -ms-border-radius: 8px;
              -o-border-radius: 8px;
              border-radius: 8px; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area .text,
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area .icon {
                display: inline-block;
                vertical-align: top; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area .icon {
                margin-right: 0; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area {
    font-weight: normal; } }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-input-container .input-label-area:hover {
                background: #f2f5fb; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-input-container input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              cursor: pointer;
              opacity: 0;
              filter: alpha(opacity=0);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-input-container:hover .input-label-area {
              background: #f2f5fb; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-list .files-list-item {
            display: flex;
            justify-content: space-between;
            text-align: left;
            margin-bottom: 10px; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-list .files-list-item .list-item-col {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-list .files-list-item .list-item-col.icon {
                width: 38px;
                flex-shrink: 0; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-list .files-list-item .list-item-col.caption {
                margin-right: auto;
                margin-left: 18px; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-list .files-list-item .list-item-col.price {
                flex-shrink: 0;
                text-align: right; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-list .files-list-item .list-item-col .note {
                font-size: 12px;
                color: #8494a9; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-list .files-list-item .icon {
              display: inline-block;
              width: 38px;
              height: 38px;
              line-height: 37px;
              text-align: center;
              font-size: 22px;
              font-weight: 500;
              color: #fff;
              background: #bed6f9;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
              border-radius: 50%;
              font-size: 12px;
              font-weight: normal; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.icon-sub-correction {
                line-height: 34.2px; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-1 {
                background: #bed6f9; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-2 {
                background: #b9e398; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-3 {
                background: #e4e597; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-4 {
                background: #e7c740; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-5 {
                background: #e74040; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-6 {
                background: #cf91af; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-list .files-list-item .caption {
              height: 38px;
              line-height: 38px;
              margin-left: 12px; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-list .files-list-item .remove {
              flex-shrink: 0;
              margin-left: 0; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .files-loader-list .files-list-item .remove button {
                background: none;
                border: none;
                height: 38px;
                line-height: 38px;
                font-size: 22px;
                color: #f32424; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .select-container .note {
            position: relative;
            padding-left: 25px;
            padding-top: 6px;
            font-size: 14px;
            color: #8494a9;
            line-height: 14px; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .select-container .note:before {
              content: "i";
              position: absolute;
              top: 5px;
              left: 0;
              display: inline-block;
              width: 14px;
              height: 14px;
              margin-right: 10px;
              line-height: 14px;
              border: 1px solid #8494a9;
              border-radius: 50%;
              text-align: center; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .input-phone {
            display: flex; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select {
              position: inherit; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select .select-button {
                padding: 0;
                width: 80px;
                text-align: left;
                box-shadow: none;
                line-height: 34px;
                border-bottom: 1px solid #8d76f0;
                border-radius: 0; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after {
                  width: 8px; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select .select-list {
                top: 100%;
                width: 100%;
                max-height: 200px;
                margin-top: 10px;
                overflow: auto; }
                .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button {
                  text-align: left; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select select {
                width: 80px; }
              .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select.show-list .select-button {
                opacity: 1;
                filter: alpha(opacity=100);
                filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
                -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .input-phone .phone-number {
              flex: 1; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .password-input {
            position: relative; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .password-input .eye-icon {
              font-size: 18px;
              background-color: transparent;
              color: #8494a9;
              position: absolute;
              right: 0; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .search-select .search-field .field-dropdown-icon {
    top: 3px; } }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .datepicker-popup {
            position: absolute;
            top: 100%;
            left: 0; }
        .company-dictionary-layout .dictionary-content .form-layout .form-content .form-errors-list {
          margin-bottom: 24px; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-errors-list .error-item {
            margin-bottom: 8px;
            color: #f32424; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-errors-list .error-item:last-child {
              margin-bottom: 0; }
        .company-dictionary-layout .dictionary-content .form-layout .form-content .form-messages-list {
          margin-bottom: 24px;
          text-align: center; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-messages-list .message-item {
            margin-bottom: 8px;
            color: #4dbd7d;
            font-size: 17px;
            line-height: 22px; }
            .company-dictionary-layout .dictionary-content .form-layout .form-content .form-messages-list .message-item:last-child {
              margin-bottom: 0; }
        .company-dictionary-layout .dictionary-content .form-layout .form-content form {
          display: inline-block;
          max-width: 350px;
          margin-right: 16px; }
        .company-dictionary-layout .dictionary-content .form-layout .form-content .header {
          font-size: 26px;
          margin-bottom: 48px; }
        .company-dictionary-layout .dictionary-content .form-layout .form-content .body {
          margin-bottom: 30px;
          font-size: 16px; }
        .company-dictionary-layout .dictionary-content .form-layout .form-content .layout-actions {
          display: inline-block; }
        .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .field-price-input-container input {
          text-align: left; }
        .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .field-price-input-container .unit-box {
          text-align: left; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .field-price-input-container .unit-box .unit-background-text {
            max-width: 100%; }
          .company-dictionary-layout .dictionary-content .form-layout .form-content .form-control .field-price-input-container .unit-box .unit-background-text-space {
            width: 8px; }
      .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        background: none;
        border: 1px solid #f35e84;
        color: #f35e84;
        min-width: 208px; }
        .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete .text,
        .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete .icon {
          display: inline-block;
          vertical-align: top; }
        .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete .icon {
          margin-right: 0; }
        .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete {
    font-weight: normal; } }
        .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete .text,
        .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete .icon {
          display: inline-block;
          vertical-align: top; }
        .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete .icon {
          margin-right: 0; }
        .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete {
    font-weight: normal; } }
        .company-dictionary-layout .dictionary-content .form-layout .layout-actions .action-delete:hover {
          background: #f2f5fb; }

@media screen and (max-width: 590px) {
  .company-dictionary-layout .dictionary-content .form-layout .form-content {
    padding: 24px 16px; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .form-layout {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
    .company-dictionary-layout .dictionary-content .form-layout .form-content {
      padding-top: 14px; }
      .company-dictionary-layout .dictionary-content .form-layout .form-content form {
        max-width: 100%;
        margin: 0; }
      .company-dictionary-layout .dictionary-content .form-layout .form-content .layout-actions {
        display: block;
        text-align: center; }
      .company-dictionary-layout .dictionary-content .form-layout .form-content .form-field.field-type-submit {
        padding-top: 16px; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .dictionary-content .layout-plate {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
  .company-dictionary-layout .dictionary-content .layout-title {
    margin-right: 16px;
    margin-left: 16px; }
  .company-dictionary-layout .dictionary-content .layout-actions {
    margin-bottom: 0;
    padding: 0 16px 28px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .company-dictionary-layout .dictionary-content.layout-add .layout-title,
  .company-dictionary-layout .dictionary-content.layout-add .layout-actions, .company-dictionary-layout .dictionary-content.layout-edit .layout-title,
  .company-dictionary-layout .dictionary-content.layout-edit .layout-actions {
    display: none; } }
    .company-dictionary-layout .dictionary-content.accounts .table-content .list-item:not(.list-headers) {
      cursor: pointer; }
      .company-dictionary-layout .dictionary-content.accounts .table-content .list-item:not(.list-headers):hover {
        background: #f2f5fb; }
  .company-dictionary-layout .table-header {
    display: none;
    margin-bottom: 36px; }
    .company-dictionary-layout .table-header p {
      font-size: 22px;
      font-weight: 500; }
  .company-dictionary-layout .tab-action {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    margin-right: 20px; }
    .company-dictionary-layout .tab-action .text,
    .company-dictionary-layout .tab-action .icon {
      display: inline-block;
      vertical-align: top; }
    .company-dictionary-layout .tab-action .icon {
      margin-right: 0; }
    .company-dictionary-layout .tab-action.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .company-dictionary-layout .tab-action.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .company-dictionary-layout .tab-action.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .company-dictionary-layout .tab-action:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .tab-action {
    font-weight: normal; } }
    .company-dictionary-layout .tab-action.download {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: none;
      border: 1px solid #4b4b4b;
      color: #4b4b4b;
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
      .company-dictionary-layout .tab-action.download .text,
      .company-dictionary-layout .tab-action.download .icon {
        display: inline-block;
        vertical-align: top; }
      .company-dictionary-layout .tab-action.download .icon {
        margin-right: 0; }
      .company-dictionary-layout .tab-action.download.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .company-dictionary-layout .tab-action.download.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .company-dictionary-layout .tab-action.download.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .company-dictionary-layout .tab-action.download:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .tab-action.download {
    font-weight: normal; } }
      .company-dictionary-layout .tab-action.download:hover {
        background: #f2f5fb; }
  .company-dictionary-layout .table-content {
    display: block;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: box-shadow 0.1s ease;
    -moz-transition: box-shadow 0.1s ease;
    -ms-transition: box-shadow 0.1s ease;
    -o-transition: box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease; }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-content {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
    .company-dictionary-layout .table-content .list-item {
      display: flex;
      justify-content: space-between;
      text-align: left;
      width: 100%;
      padding: 24px 28px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box; }
      .company-dictionary-layout .table-content .list-item .list-item-col {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        .company-dictionary-layout .table-content .list-item .list-item-col.icon {
          width: 38px;
          flex-shrink: 0; }
        .company-dictionary-layout .table-content .list-item .list-item-col.caption {
          margin-right: auto;
          margin-left: 18px; }
        .company-dictionary-layout .table-content .list-item .list-item-col.price {
          flex-shrink: 0;
          text-align: right; }
        .company-dictionary-layout .table-content .list-item .list-item-col .note {
          font-size: 12px;
          color: #8494a9; }
      .company-dictionary-layout .table-content .list-item .list-item-col {
        flex: 1;
        margin-right: 16px; }
        .company-dictionary-layout .table-content .list-item .list-item-col p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px; }
        .company-dictionary-layout .table-content .list-item .list-item-col .style-box {
          height: 30px;
          width: 68px;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .company-dictionary-layout .table-content .list-item .list-item-col.caption {
          flex: 3; }
          .company-dictionary-layout .table-content .list-item .list-item-col.caption p {
            font-size: 17px;
            font-weight: 500;
            color: #4b4b4b; }
        .company-dictionary-layout .table-content .list-item .list-item-col.actions {
          position: relative;
          overflow: visible;
          padding-left: 10px;
          flex: 0.1;
          margin-right: 0;
          text-align: right; }
          .company-dictionary-layout .table-content .list-item .list-item-col.actions .actions-button {
            position: relative;
            background: none;
            border: none;
            font-size: 17px;
            color: #8494a9; }
          .company-dictionary-layout .table-content .list-item .list-item-col.actions .actions-popup {
            position: relative;
            display: none;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            z-index: 20;
            display: none;
            position: absolute;
            top: 0;
            right: -30px;
            padding: 18px 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .company-dictionary-layout .table-content .list-item .list-item-col.actions .actions-popup.show {
              display: block; }
            .company-dictionary-layout .table-content .list-item .list-item-col.actions .actions-popup .close-button {
              width: 19px;
              height: 19px;
              background: url(images/icon-close.png) no-repeat center center;
              display: inline-block;
              background-size: contain;
              position: absolute;
              top: 25px;
              right: 25px; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .table-content .list-item .list-item-col.actions .actions-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-content .list-item .list-item-col.actions .actions-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .company-dictionary-layout .table-content .list-item .list-item-col.actions .actions-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
            .company-dictionary-layout .table-content .list-item .list-item-col.actions .actions-popup .action-item {
              position: relative;
              background: none;
              border: none;
              cursor: pointer;
              color: #4b4b4b;
              display: block;
              min-width: 260px;
              padding: 5px 0 5px 60px;
              font-size: 16px;
              text-align: left; }
              .company-dictionary-layout .table-content .list-item .list-item-col.actions .actions-popup .action-item:hover {
                color: black; }
              .company-dictionary-layout .table-content .list-item .list-item-col.actions .actions-popup .action-item::after {
                content: "";
                display: block;
                position: absolute;
                top: 5px;
                left: 25px; }
              .company-dictionary-layout .table-content .list-item .list-item-col.actions .actions-popup .action-item.action-edit::after {
                width: 22px;
                height: 22px;
                background: url(images/icon-edit-black.png) no-repeat center center;
                display: inline-block;
                background-size: contain; }
              .company-dictionary-layout .table-content .list-item .list-item-col.actions .actions-popup .action-item.action-copy::after {
                width: 19px;
                height: 20px;
                background: url(images/icon-copy-link.png) no-repeat center center;
                display: inline-block;
                background-size: contain; }
              .company-dictionary-layout .table-content .list-item .list-item-col.actions .actions-popup .action-item.action-delete::after {
                width: 19px;
                height: 19px;
                background: url(images/icon-close-black.png) no-repeat center center;
                display: inline-block;
                background-size: contain;
                top: 9px;
                left: 27px;
                width: 14px;
                height: 14px; }
          .company-dictionary-layout .table-content .list-item .list-item-col.actions.opened .actions-button {
            z-index: 30; }
          .company-dictionary-layout .table-content .list-item .list-item-col.actions.opened .actions-popup {
            display: block; }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-content .list-item .list-item-col.actions .actions-popup .close-button {
    width: 19px;
    height: 19px;
    background: url(images/icon-close.png) no-repeat center center;
    display: inline-block;
    background-size: contain;
    top: 23px;
    right: 23px;
    left: auto; } }
        .company-dictionary-layout .table-content .list-item .list-item-col.amount {
          text-align: right; }
          .company-dictionary-layout .table-content .list-item .list-item-col.amount .price {
            font-size: 0; }
            .company-dictionary-layout .table-content .list-item .list-item-col.amount .price .int,
            .company-dictionary-layout .table-content .list-item .list-item-col.amount .price .delim {
              font-size: 22px; }
            .company-dictionary-layout .table-content .list-item .list-item-col.amount .price .float,
            .company-dictionary-layout .table-content .list-item .list-item-col.amount .price .unit {
              font-size: 17px; }
            .company-dictionary-layout .table-content .list-item .list-item-col.amount .price .unit {
              margin-left: 8px; }
            .company-dictionary-layout .table-content .list-item .list-item-col.amount .price .unit-ruble:after {
              content: "\20bd"; }
            .company-dictionary-layout .table-content .list-item .list-item-col.amount .price.income, .company-dictionary-layout .table-content .list-item .list-item-col.amount .price.transfer-income {
              color: #4dbd7d; }
              .company-dictionary-layout .table-content .list-item .list-item-col.amount .price.income .int,
              .company-dictionary-layout .table-content .list-item .list-item-col.amount .price.income .delim,
              .company-dictionary-layout .table-content .list-item .list-item-col.amount .price.income .float,
              .company-dictionary-layout .table-content .list-item .list-item-col.amount .price.income .unit, .company-dictionary-layout .table-content .list-item .list-item-col.amount .price.transfer-income .int,
              .company-dictionary-layout .table-content .list-item .list-item-col.amount .price.transfer-income .delim,
              .company-dictionary-layout .table-content .list-item .list-item-col.amount .price.transfer-income .float,
              .company-dictionary-layout .table-content .list-item .list-item-col.amount .price.transfer-income .unit {
                color: #4dbd7d; }
            .company-dictionary-layout .table-content .list-item .list-item-col.amount .price .unit {
              color: #8494a9; }
      .company-dictionary-layout .table-content .list-item.list-headers {
        border-bottom: 0;
        padding-bottom: 32px; }
        .company-dictionary-layout .table-content .list-item.list-headers .list-item-col p {
          color: #4b4b4b;
          font-size: 17px;
          font-weight: 500; }
      .company-dictionary-layout .table-content .list-item.filter-out {
        display: none; }
      .company-dictionary-layout .table-content .list-item:last-child {
        border-bottom: 0; }
  .company-dictionary-layout .company-tab-list .layout-title {
    margin-bottom: 32px; }
    .company-dictionary-layout .company-tab-list .layout-title h1 {
      font-size: 22px;
      font-weight: 500; }
  .company-dictionary-layout .company-tab-list .layout-actions {
    margin-bottom: 40px; }
    .company-dictionary-layout .company-tab-list .layout-actions button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px;
      min-width: 206px; }
      .company-dictionary-layout .company-tab-list .layout-actions button .text,
      .company-dictionary-layout .company-tab-list .layout-actions button .icon {
        display: inline-block;
        vertical-align: top; }
      .company-dictionary-layout .company-tab-list .layout-actions button .icon {
        margin-right: 0; }
      .company-dictionary-layout .company-tab-list .layout-actions button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .company-dictionary-layout .company-tab-list .layout-actions button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .company-dictionary-layout .company-tab-list .layout-actions button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .company-dictionary-layout .company-tab-list .layout-actions button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .layout-actions button {
    font-weight: normal; } }
      .company-dictionary-layout .company-tab-list .layout-actions button.name-bank-integration {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        background: none;
        border: 1px solid #4b4b4b;
        color: #4b4b4b;
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
        .company-dictionary-layout .company-tab-list .layout-actions button.name-bank-integration .text,
        .company-dictionary-layout .company-tab-list .layout-actions button.name-bank-integration .icon {
          display: inline-block;
          vertical-align: top; }
        .company-dictionary-layout .company-tab-list .layout-actions button.name-bank-integration .icon {
          margin-right: 0; }
        .company-dictionary-layout .company-tab-list .layout-actions button.name-bank-integration.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .company-dictionary-layout .company-tab-list .layout-actions button.name-bank-integration.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .company-dictionary-layout .company-tab-list .layout-actions button.name-bank-integration.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .company-dictionary-layout .company-tab-list .layout-actions button.name-bank-integration:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .layout-actions button.name-bank-integration {
    font-weight: normal; } }
        .company-dictionary-layout .company-tab-list .layout-actions button.name-bank-integration:hover {
          background: #f2f5fb; }
  .company-dictionary-layout .company-tab-list .layout-plate {
    display: block;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: box-shadow 0.1s ease;
    -moz-transition: box-shadow 0.1s ease;
    -ms-transition: box-shadow 0.1s ease;
    -o-transition: box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease; }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .layout-plate {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
  .company-dictionary-layout .company-tab-list .layout-cards-list {
    padding: 34px 0; }
    .company-dictionary-layout .company-tab-list .layout-cards-list .list-item {
      position: relative;
      padding: 26px 50px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
      .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .list-item-container {
        position: relative; }
      .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title {
        margin-bottom: 3px; }
        .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title p {
          color: #0e0e0e;
          font-size: 32px; }
        .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price {
          font-size: 0;
          color: #0e0e0e; }
          .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price .int,
          .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price .delim {
            font-size: 32px; }
          .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price .float,
          .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price .unit {
            font-size: 22px; }
          .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price .unit {
            margin-left: 8px; }
          .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price .unit-ruble:after {
            content: "\20bd"; }
          .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price.income, .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price.transfer-income {
            color: #4dbd7d; }
            .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price.income .int,
            .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price.income .delim,
            .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price.income .float,
            .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price.income .unit, .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price.transfer-income .int,
            .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price.transfer-income .delim,
            .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price.transfer-income .float,
            .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price.transfer-income .unit {
              color: #4dbd7d; }
          .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title .price .unit {
            color: #8494a9; }
      .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-field p {
        font-size: 16px; }
      .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-actions {
        position: absolute;
        top: 4px;
        right: 0; }
        .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-actions .action-edit {
          width: 22px;
          height: 22px;
          background: url(images/icon-edit-gray.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
      .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-mark {
        display: inline-block;
        padding: 0 7px;
        background: #b9e398;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px; }

@media screen and (max-width: 590px) {
  .company-dictionary-layout .company-tab-list .layout-cards-list {
    padding: 0; }
    .company-dictionary-layout .company-tab-list .layout-cards-list .list-item {
      padding: 24px 16px; } }
  .company-dictionary-layout .company-tab-list .form-layout {
    display: block;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: box-shadow 0.1s ease;
    -moz-transition: box-shadow 0.1s ease;
    -ms-transition: box-shadow 0.1s ease;
    -o-transition: box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease; }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .form-layout {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
    .company-dictionary-layout .company-tab-list .form-layout .back-button {
      margin-bottom: 24px; }
      .company-dictionary-layout .company-tab-list .form-layout .back-button button {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .company-dictionary-layout .company-tab-list .form-layout .back-button button:hover {
          color: black; }
        .company-dictionary-layout .company-tab-list .form-layout .back-button button:before {
          content: "\2190";
          margin-right: 4px; }
    .company-dictionary-layout .company-tab-list .form-layout .form-content {
      padding: 60px 50px; }
      .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field {
        margin-bottom: 38px;
        width: 100%; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-label {
          display: block;
          font-size: 17px;
          color: #8494a9; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field.field-type-colorpicker .form-label {
          margin-bottom: 8px; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field.field-type-files .form-label {
          display: none; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .custom-checkput {
          position: relative;
          display: inline-block;
          width: auto;
          height: auto; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .custom-checkput input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            filter: alpha(opacity=0);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .custom-checkput .checkput-icon {
            position: relative;
            display: inline-block;
            width: 18px;
            height: 18px;
            border: 2px solid #8494a9;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px;
            cursor: pointer; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .custom-checkput.checked .checkput-icon {
            position: relative;
            display: inline-block;
            width: 22px;
            height: 22px;
            background: #81bc52;
            border: 0;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .custom-checkput.checked .checkput-icon::after {
              content: "";
              width: 16px;
              height: 12px;
              background: url(images/icon-check-white.png) no-repeat center center;
              display: inline-block;
              background-size: contain;
              position: absolute;
              top: 7px;
              left: 4px;
              width: 13px;
              height: 9px; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field.field-type-checkbox .custom-checkput, .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field.field-type-agreement .custom-checkput, .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field.field-type-license-agreement .custom-checkput {
          float: left; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field.field-type-checkbox .form-label, .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field.field-type-agreement .form-label, .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field.field-type-license-agreement .form-label {
          margin-left: 34px;
          cursor: pointer; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field.field-type-agreement button, .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field.field-type-license-agreement button {
          display: block;
          margin-left: 34px;
          height: auto;
          line-height: 22px;
          font-size: 17px;
          color: #8494a9;
          text-decoration: underline;
          background: none; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-field-password-generator button {
          color: #2f80ed;
          cursor: pointer;
          background: transparent; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-field-password-check {
          line-height: 16px;
          font-size: 12px;
          position: relative;
          margin-top: -2px;
          z-index: 1; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-field-password-check.active-red {
            width: 30%;
            border-top: 2px solid #F35E84;
            margin-bottom: 11px; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-field-password-check.active-green {
            width: 100%;
            border-top: 2px solid #5A9C76;
            margin-bottom: 11px; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-field-password-check.active-yellow {
            width: 70%;
            border-top: 2px solid #C99300;
            margin-bottom: 11px; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-field-password-check .password-check-hint-green {
            color: #5A9C76; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-field-password-check .password-check-hint-yellow {
            color: #C99300; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-field-password-check .password-check-hint-red {
            color: #F35E84; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field {
    margin-bottom: 28px; }
    .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-label {
      font-size: 14px;
      line-height: 14px; } }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          width: 100%; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-button .text,
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-button .icon {
            display: inline-block;
            vertical-align: top; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-button .icon {
            margin-right: 0; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field .form-button {
    font-weight: normal; } }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field.field-error .form-label {
          color: #f32424; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field.field-error .form-control .form-input,
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field.field-error .form-control textarea,
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field.field-error .form-control select {
          border-color: #f32424; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field.field-error .form-field-error p {
          color: #f32424;
          white-space: pre-wrap; }
      .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control {
        position: relative; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .form-input,
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control textarea,
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control select {
          width: 100%;
          height: 34px;
          line-height: 34px;
          padding: 0;
          background: none;
          border: 0;
          border-bottom: 1px solid #8d76f0;
          font-size: 17px;
          outline: none; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .form-input::-webkit-input-placeholder,
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control textarea::-webkit-input-placeholder,
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control select::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: rgba(132, 148, 169, 0.5); }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .form-input:-moz-placeholder,
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control textarea:-moz-placeholder,
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control select:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .form-input::-moz-placeholder,
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control textarea::-moz-placeholder,
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control select::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .form-input:-ms-input-placeholder,
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control textarea:-ms-input-placeholder,
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control select:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(132, 148, 169, 0.5); }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .form-input::-ms-input-placeholder,
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control textarea::-ms-input-placeholder,
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control select::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(132, 148, 169, 0.5); }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .form-input::placeholder,
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control textarea::placeholder,
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control select::placeholder {
            /* Most modern browsers support this now. */
            color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .form-input,
  .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control textarea,
  .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control select {
    font-size: 16px; } }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control textarea {
          resize: none;
          padding: 5px 0;
          line-height: 22px; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control select {
          cursor: pointer; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select {
          position: relative; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select select {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            filter: alpha(opacity=0);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-button {
            position: relative;
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            width: 100%;
            height: auto;
            padding: 15px 30px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            border-radius: 8px;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-button .text,
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-button .icon {
              display: inline-block;
              vertical-align: top; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-button .icon {
              margin-right: 0; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-button {
    font-weight: normal; } }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-button .post-icon {
              position: relative;
              top: -1px;
              left: 8px;
              display: inline-block;
              width: 0; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-button .post-icon:after {
                content: "";
                width: 14px;
                height: 7px;
                background: url(images/arrow-bottom.png) no-repeat center center;
                display: inline-block;
                background-size: contain; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-button:hover {
              background: none;
              -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
              -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
              -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
              -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list {
            display: block;
            background: #fff;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
            border-radius: 10px;
            -webkit-transition: box-shadow 0.1s ease;
            -moz-transition: box-shadow 0.1s ease;
            -ms-transition: box-shadow 0.1s ease;
            -o-transition: box-shadow 0.1s ease;
            transition: box-shadow 0.1s ease;
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-button {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              position: relative;
              width: 100%;
              height: auto;
              padding: 15px 50px;
              line-height: 20px;
              background: none;
              color: #4b4b4b;
              font-weight: normal;
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              -ms-border-radius: 0;
              -o-border-radius: 0;
              border-radius: 0; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-button .text,
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-button .icon {
                display: inline-block;
                vertical-align: top; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-button .icon {
                margin-right: 0; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-button.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-button.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-button.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-button:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-button {
    font-weight: normal; } }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-button.active .pre-icon {
                position: relative;
                left: -26px;
                display: inline-block;
                width: 0; }
                .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-button.active .pre-icon:after {
                  content: "";
                  width: 16px;
                  height: 12px;
                  background: url(images/icon-check.png) no-repeat center center;
                  display: inline-block;
                  background-size: contain; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-button:after {
                content: "";
                display: block;
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                height: 1px;
                background: rgba(0, 0, 0, 0.1);
                opacity: 0.5;
                filter: alpha(opacity=50);
                filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
                -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-button:hover {
                background: #f2f5fb; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-button:first-child:after {
                left: 23px;
                right: 23px;
                opacity: 1;
                filter: alpha(opacity=100);
                filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
                -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-button:last-child {
                margin-bottom: 40px; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-action {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              position: relative;
              width: 100%;
              height: auto;
              padding: 15px 23px;
              line-height: 20px;
              background: none;
              color: #4b4b4b;
              font-weight: normal;
              text-align: left;
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              -ms-border-radius: 0;
              -o-border-radius: 0;
              border-radius: 0; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-action .text,
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-action .icon {
                display: inline-block;
                vertical-align: top; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-action .icon {
                margin-right: 0; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-action.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-action.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-action.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-action:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-action {
    font-weight: normal; } }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list .select-list-action:hover {
                background: #f2f5fb; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select .select-list.show {
              display: block; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .styled-select.show-list .select-button {
            opacity: 0;
            filter: alpha(opacity=0);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select {
          position: relative; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select select {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            filter: alpha(opacity=0);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field {
            position: relative;
            border-bottom: 1px solid #8d76f0; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field input {
              width: 100%;
              height: 34px;
              line-height: 34px;
              padding: 0;
              background: none;
              border: 0;
              border-bottom: 1px solid #8d76f0;
              font-size: 17px;
              outline: none;
              width: 90%;
              border-bottom: 0; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field input::-webkit-input-placeholder {
                /* WebKit, Blink, Edge */
                color: rgba(132, 148, 169, 0.5); }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field input:-moz-placeholder {
                /* Mozilla Firefox 4 to 18 */
                color: rgba(132, 148, 169, 0.5);
                opacity: 1; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field input::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                color: rgba(132, 148, 169, 0.5);
                opacity: 1; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field input:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: rgba(132, 148, 169, 0.5); }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field input::-ms-input-placeholder {
                /* Microsoft Edge */
                color: rgba(132, 148, 169, 0.5); }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field input::placeholder {
                /* Most modern browsers support this now. */
                color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field input {
    font-size: 16px; } }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field input::-webkit-input-placeholder {
                /* WebKit, Blink, Edge */
                color: #4b4b4b; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field input:-moz-placeholder {
                /* Mozilla Firefox 4 to 18 */
                color: #4b4b4b;
                opacity: 1; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field input::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                color: #4b4b4b;
                opacity: 1; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field input:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #4b4b4b; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field input::-ms-input-placeholder {
                /* Microsoft Edge */
                color: #4b4b4b; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field input::placeholder {
                /* Most modern browsers support this now. */
                color: #4b4b4b; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field .field-dropdown-icon {
              position: absolute;
              top: 7px;
              right: 21px;
              width: 0; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field .field-dropdown-icon:after {
                content: "";
                width: 14px;
                height: 7px;
                background: url(images/arrow-bottom.png) no-repeat center center;
                display: inline-block;
                background-size: contain; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list-container {
            display: block;
            background: #fff;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
            border-radius: 10px;
            -webkit-transition: box-shadow 0.1s ease;
            -moz-transition: box-shadow 0.1s ease;
            -ms-transition: box-shadow 0.1s ease;
            -o-transition: box-shadow 0.1s ease;
            transition: box-shadow 0.1s ease;
            display: none;
            position: absolute;
            top: -17px;
            left: -17px;
            right: -17px;
            padding-top: 60px;
            padding-bottom: 17px;
            z-index: 1;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list {
            position: relative;
            max-height: 200px;
            overflow: auto; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-button {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              position: relative;
              width: 100%;
              height: auto;
              padding: 10px 17px;
              line-height: 20px;
              background: none;
              color: #4b4b4b;
              font-weight: normal;
              text-align: left;
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              -ms-border-radius: 0;
              -o-border-radius: 0;
              border-radius: 0; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-button .text,
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-button .icon {
                display: inline-block;
                vertical-align: top; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-button .icon {
                margin-right: 0; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-button.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-button.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-button.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-button:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-button {
    font-weight: normal; } }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-button.active {
                background: #f2f5fb; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-button.selected {
                background: #f2f5fb; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-button:hover {
                background: #f2f5fb; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-action {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              position: relative;
              width: 100%;
              height: auto;
              padding: 10px 17px;
              line-height: 20px;
              background: none;
              color: #4b4b4b;
              font-weight: normal;
              text-align: left;
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              -ms-border-radius: 0;
              -o-border-radius: 0;
              border-radius: 0; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-action .text,
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-action .icon {
                display: inline-block;
                vertical-align: top; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-action .icon {
                margin-right: 0; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-action.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-action.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-action.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-action:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-action {
    font-weight: normal; } }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .select-list .select-list-action:hover {
                background: #f2f5fb; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select.show-list .select-list-container {
            display: block; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select.show-list .search-field {
            z-index: 1;
            border-bottom-color: rgba(0, 0, 0, 0.1); }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .field-price-input-container {
          position: relative;
          width: 100%; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .field-price-input-container .unit-box {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            text-align: center;
            white-space: nowrap; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .field-price-input-container .unit-box span {
              display: inline-block; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .field-price-input-container .unit-box .unit-background-text {
              color: transparent;
              font-size: 38px;
              max-width: 95%;
              overflow: hidden; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .field-price-input-container .unit-box .unit-background-text-space {
              width: 24px; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .field-price-input-container input {
            position: relative;
            height: 50px;
            line-height: 50px;
            box-sizing: border-box;
            text-align: center;
            font-size: 38px; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .field-price-input-container .unit-ruble {
            line-height: 52px;
            font-size: 32px;
            color: #8494a9;
            opacity: 0.7;
            filter: alpha(opacity=70);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=70;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .field-price-input-container .unit-ruble:after {
              content: "\20bd"; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker {
          position: relative; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-button {
            display: block;
            width: 105px;
            height: 30px;
            background: none;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette {
            display: block;
            background: #fff;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
            border-radius: 10px;
            -webkit-transition: box-shadow 0.1s ease;
            -moz-transition: box-shadow 0.1s ease;
            -ms-transition: box-shadow 0.1s ease;
            -o-transition: box-shadow 0.1s ease;
            transition: box-shadow 0.1s ease;
            position: absolute;
            left: 0;
            bottom: -200px;
            width: 504px;
            padding: 32px 20px;
            z-index: 4;
            -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
            -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
            -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette .palette-header {
              margin-bottom: 40px;
              margin-left: 10px;
              font-size: 22px; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette .palette-color-buttons {
              margin-bottom: 12px; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette .palette-color-button {
              display: inline-block;
              width: 60px;
              height: 60px;
              margin: 0 12px 24px 12px;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
              border-radius: 50%; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette .palette-color-button.active, .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette .palette-color-button:hover {
                margin: -10px 2px 14px 2px;
                width: 80px;
                height: 80px;
                border: 10px solid #f2f5fb; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette .palette-corner-close-button {
              position: absolute;
              top: 0;
              right: 0;
              width: 80px;
              height: 80px;
              vertical-align: middle;
              text-align: center;
              font-size: 38px;
              font-family: "times new roman", verdana;
              background: none; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons {
              text-align: right; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
                display: inline-block;
                height: 50px;
                line-height: 48px;
                padding: 0 20px;
                text-align: center;
                vertical-align: top;
                background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
                border: 0;
                color: #fff;
                font-size: 17px;
                font-weight: 500;
                box-sizing: border-box;
                -webkit-border-radius: 25px;
                -moz-border-radius: 25px;
                -ms-border-radius: 25px;
                -o-border-radius: 25px;
                border-radius: 25px;
                -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
                -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
                -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
                -o-transition: background 0.1s ease, box-shadow 0.1s ease;
                transition: background 0.1s ease, box-shadow 0.1s ease;
                background: none;
                border: 1px solid #4b4b4b;
                color: #4b4b4b;
                font-size: 16px;
                min-width: 191px; }
                .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
                .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                  display: inline-block;
                  vertical-align: top; }
                .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                  margin-right: 0; }
                .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs {
                  height: 32px;
                  line-height: 32px;
                  font-size: 12px;
                  font-weight: normal;
                  -webkit-border-radius: 15px;
                  -moz-border-radius: 15px;
                  -ms-border-radius: 15px;
                  -o-border-radius: 15px;
                  border-radius: 15px; }
                .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s {
                  height: 40px;
                  line-height: 38px;
                  font-size: 14px;
                  font-weight: normal;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  -ms-border-radius: 20px;
                  -o-border-radius: 20px;
                  border-radius: 20px; }
                .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l {
                  height: 60px;
                  line-height: 58px;
                  -webkit-border-radius: 30px;
                  -moz-border-radius: 30px;
                  -ms-border-radius: 30px;
                  -o-border-radius: 30px;
                  border-radius: 30px; }
                .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                  background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
    font-weight: normal; } }
                .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                  background: #f2f5fb; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .colorpicker .picker-palette-overlay {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.7);
            z-index: 2; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .form-autocomplete-input {
          position: relative; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .form-autocomplete-input .form-autocomplete-dropdown {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            max-height: 300px;
            background: #fff;
            -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
            -ms-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
            -o-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
            z-index: 1;
            overflow: auto; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .form-autocomplete-input .form-autocomplete-dropdown button {
              background: none;
              border: none;
              width: 100%;
              padding: 8px 10px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              text-align: left; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover {
                background: #f2f5fb; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child {
                border-bottom: 0; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .field-text-list-container .text-list-item {
          position: relative;
          margin-bottom: 10px; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .field-text-list-container .text-list-item .remove-text-item {
            background: none;
            border: none;
            cursor: pointer;
            color: #4b4b4b;
            position: absolute;
            top: 0;
            right: 0;
            color: #f32424;
            font-size: 22px; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .field-text-list-container .text-list-item .remove-text-item:hover {
              color: black; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .field-text-list-container .add-text-item {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .field-text-list-container .add-text-item:hover {
            color: black; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-container .files-loader-input-container {
          margin-bottom: 20px; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-input-container {
          position: relative; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-input-container .input-label-area {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            background: none;
            border: 1px solid #4b4b4b;
            color: #4b4b4b;
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px;
            width: 100%;
            height: 60px;
            line-height: 60px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            border-radius: 8px; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-input-container .input-label-area .text,
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-input-container .input-label-area .icon {
              display: inline-block;
              vertical-align: top; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-input-container .input-label-area .icon {
              margin-right: 0; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-input-container .input-label-area.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-input-container .input-label-area.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-input-container .input-label-area.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-input-container .input-label-area:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-input-container .input-label-area {
    font-weight: normal; } }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-input-container .input-label-area:hover {
              background: #f2f5fb; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-input-container input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;
            filter: alpha(opacity=0);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-input-container:hover .input-label-area {
            background: #f2f5fb; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-list .files-list-item {
          display: flex;
          justify-content: space-between;
          text-align: left;
          margin-bottom: 10px; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-list .files-list-item .list-item-col {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-list .files-list-item .list-item-col.icon {
              width: 38px;
              flex-shrink: 0; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-list .files-list-item .list-item-col.caption {
              margin-right: auto;
              margin-left: 18px; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-list .files-list-item .list-item-col.price {
              flex-shrink: 0;
              text-align: right; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-list .files-list-item .list-item-col .note {
              font-size: 12px;
              color: #8494a9; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-list .files-list-item .icon {
            display: inline-block;
            width: 38px;
            height: 38px;
            line-height: 37px;
            text-align: center;
            font-size: 22px;
            font-weight: 500;
            color: #fff;
            background: #bed6f9;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            border-radius: 50%;
            font-size: 12px;
            font-weight: normal; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.icon-sub-correction {
              line-height: 34.2px; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-1 {
              background: #bed6f9; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-2 {
              background: #b9e398; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-3 {
              background: #e4e597; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-4 {
              background: #e7c740; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-5 {
              background: #e74040; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-list .files-list-item .icon.color-6 {
              background: #cf91af; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-list .files-list-item .caption {
            height: 38px;
            line-height: 38px;
            margin-left: 12px; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-list .files-list-item .remove {
            flex-shrink: 0;
            margin-left: 0; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .files-loader-list .files-list-item .remove button {
              background: none;
              border: none;
              height: 38px;
              line-height: 38px;
              font-size: 22px;
              color: #f32424; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .select-container .note {
          position: relative;
          padding-left: 25px;
          padding-top: 6px;
          font-size: 14px;
          color: #8494a9;
          line-height: 14px; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .select-container .note:before {
            content: "i";
            position: absolute;
            top: 5px;
            left: 0;
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-right: 10px;
            line-height: 14px;
            border: 1px solid #8494a9;
            border-radius: 50%;
            text-align: center; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .input-phone {
          display: flex; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select {
            position: inherit; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select .select-button {
              padding: 0;
              width: 80px;
              text-align: left;
              box-shadow: none;
              line-height: 34px;
              border-bottom: 1px solid #8d76f0;
              border-radius: 0; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after {
                width: 8px; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select .select-list {
              top: 100%;
              width: 100%;
              max-height: 200px;
              margin-top: 10px;
              overflow: auto; }
              .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button {
                text-align: left; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select select {
              width: 80px; }
            .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .input-phone .phone-country-code .styled-select.show-list .select-button {
              opacity: 1;
              filter: alpha(opacity=100);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .input-phone .phone-number {
            flex: 1; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .password-input {
          position: relative; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .password-input .eye-icon {
            font-size: 18px;
            background-color: transparent;
            color: #8494a9;
            position: absolute;
            right: 0; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .search-select .search-field .field-dropdown-icon {
    top: 3px; } }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .datepicker-popup {
          position: absolute;
          top: 100%;
          left: 0; }
      .company-dictionary-layout .company-tab-list .form-layout .form-content .form-errors-list {
        margin-bottom: 24px; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-errors-list .error-item {
          margin-bottom: 8px;
          color: #f32424; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-errors-list .error-item:last-child {
            margin-bottom: 0; }
      .company-dictionary-layout .company-tab-list .form-layout .form-content .form-messages-list {
        margin-bottom: 24px;
        text-align: center; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-messages-list .message-item {
          margin-bottom: 8px;
          color: #4dbd7d;
          font-size: 17px;
          line-height: 22px; }
          .company-dictionary-layout .company-tab-list .form-layout .form-content .form-messages-list .message-item:last-child {
            margin-bottom: 0; }
      .company-dictionary-layout .company-tab-list .form-layout .form-content form {
        display: inline-block;
        max-width: 350px;
        margin-right: 16px; }
      .company-dictionary-layout .company-tab-list .form-layout .form-content .header {
        font-size: 26px;
        margin-bottom: 48px; }
      .company-dictionary-layout .company-tab-list .form-layout .form-content .body {
        margin-bottom: 30px;
        font-size: 16px; }
      .company-dictionary-layout .company-tab-list .form-layout .form-content .layout-actions {
        display: inline-block; }
      .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .field-price-input-container input {
        text-align: left; }
      .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .field-price-input-container .unit-box {
        text-align: left; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .field-price-input-container .unit-box .unit-background-text {
          max-width: 100%; }
        .company-dictionary-layout .company-tab-list .form-layout .form-content .form-control .field-price-input-container .unit-box .unit-background-text-space {
          width: 8px; }
    .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: none;
      border: 1px solid #f35e84;
      color: #f35e84;
      min-width: 208px; }
      .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete .text,
      .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete .icon {
        display: inline-block;
        vertical-align: top; }
      .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete .icon {
        margin-right: 0; }
      .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete {
    font-weight: normal; } }
      .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete .text,
      .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete .icon {
        display: inline-block;
        vertical-align: top; }
      .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete .icon {
        margin-right: 0; }
      .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete {
    font-weight: normal; } }
      .company-dictionary-layout .company-tab-list .form-layout .layout-actions .action-delete:hover {
        background: #f2f5fb; }

@media screen and (max-width: 590px) {
  .company-dictionary-layout .company-tab-list .form-layout .form-content {
    padding: 24px 16px; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .form-layout {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
    .company-dictionary-layout .company-tab-list .form-layout .form-content {
      padding-top: 14px; }
      .company-dictionary-layout .company-tab-list .form-layout .form-content form {
        max-width: 100%;
        margin: 0; }
      .company-dictionary-layout .company-tab-list .form-layout .form-content .layout-actions {
        display: block;
        text-align: center; }
      .company-dictionary-layout .company-tab-list .form-layout .form-content .form-field.field-type-submit {
        padding-top: 16px; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-list .layout-plate {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
  .company-dictionary-layout .company-tab-list .layout-title {
    margin-right: 16px;
    margin-left: 16px; }
  .company-dictionary-layout .company-tab-list .layout-actions {
    margin-bottom: 0;
    padding: 0 16px 28px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .company-dictionary-layout .company-tab-list.layout-add .layout-title,
  .company-dictionary-layout .company-tab-list.layout-add .layout-actions, .company-dictionary-layout .company-tab-list.layout-edit .layout-title,
  .company-dictionary-layout .company-tab-list.layout-edit .layout-actions {
    display: none; } }
  .company-dictionary-layout .company-tab-list .layout-cards-list {
    padding-top: 0; }
    .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .list-item-container {
      min-height: 60px; }
    .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title {
      margin-bottom: 12px; }
      .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-title p {
        font-size: 26px; }
    .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-field.type,
    .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-field.role {
      display: inline-block;
      padding: 0 7px;
      background: #b9e398;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      border-radius: 3px;
      position: absolute;
      bottom: 0;
      right: 0; }
      .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-field.type.client, .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-field.type.role-3, .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-field.type.outcome,
      .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-field.role.client,
      .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-field.role.role-3,
      .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .li-field.role.outcome {
        background: #bed6f9; }
    .company-dictionary-layout .company-tab-list .layout-cards-list .list-item .style-box {
      height: 30px;
      width: 68px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .company-dictionary-layout .company-tab-list .layout-cards-list .list-item.filter-out {
      display: none; }
  .company-dictionary-layout .table-filter {
    position: relative;
    padding: 44px 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .company-dictionary-layout .table-filter .filter-field {
      display: inline-block;
      margin: 0 6px 12px 6px;
      vertical-align: top; }
    .company-dictionary-layout .table-filter .filter-label {
      display: block;
      color: #8494a9; }
    .company-dictionary-layout .table-filter .filter-datepicker {
      position: relative; }
      .company-dictionary-layout .table-filter .filter-datepicker .filter-label-button {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px;
        background: #f2f5fb;
        color: #4b4b4b;
        font-size: 14px; }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-label-button .text,
        .company-dictionary-layout .table-filter .filter-datepicker .filter-label-button .icon {
          display: inline-block;
          vertical-align: top; }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-label-button .icon {
          margin-right: 0; }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-label-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-label-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-label-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-label-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-datepicker .filter-label-button {
    font-weight: normal; } }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-label-button .icon-date {
          width: 17px;
          height: 16px;
          background: url(images/icon-date-black.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          margin-right: 6px;
          margin-top: 6px; }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-label-button:hover {
          background: #eaeff9; }
      .company-dictionary-layout .table-filter .filter-datepicker .filter-select-button {
        width: 100%;
        height: 34px;
        line-height: 34px;
        padding: 0;
        background: none;
        border: 0;
        border-bottom: 1px solid #8d76f0;
        font-size: 17px;
        outline: none;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-select-button::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: rgba(132, 148, 169, 0.5); }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-select-button:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-select-button::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-select-button:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba(132, 148, 169, 0.5); }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-select-button::-ms-input-placeholder {
          /* Microsoft Edge */
          color: rgba(132, 148, 169, 0.5); }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-select-button::placeholder {
          /* Most modern browsers support this now. */
          color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .table-filter .filter-datepicker .filter-select-button {
    font-size: 16px; } }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-select-button .text {
          margin-right: 8px; }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-select-button .icon-arrow {
          width: 14px;
          height: 7px;
          background: url(images/arrow-bottom.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
      .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup {
        position: relative;
        display: none;
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        z-index: 20;
        position: absolute;
        top: -24px;
        left: -24px;
        padding-bottom: 24px; }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup.show {
          display: block; }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .close-button {
          width: 19px;
          height: 19px;
          background: url(images/icon-close.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          position: absolute;
          top: 25px;
          right: 25px; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist-header {
          margin: 24px 0 18px 24px;
          font-size: 14px;
          color: #8494a9;
          background: none; }
          .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist-header .icon,
          .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist-header .text {
            vertical-align: top; }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist {
          min-width: 240px; }
          .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist button {
            position: relative;
            display: block;
            width: 390px;
            height: 48px;
            line-height: 48px;
            padding-right: 32px;
            background: none;
            font-size: 17px;
            color: #4b4b4b; }
            .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon {
              position: absolute;
              top: 13px;
              left: 24px;
              display: inline-block;
              width: 18px;
              height: 18px;
              border: 2px solid #8494a9;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              -ms-border-radius: 3px;
              -o-border-radius: 3px;
              border-radius: 3px; }
            .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon-checked {
              position: relative;
              display: inline-block;
              width: 22px;
              height: 22px;
              background: #81bc52;
              border: 0;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              -ms-border-radius: 3px;
              -o-border-radius: 3px;
              border-radius: 3px;
              position: absolute;
              top: 13px;
              left: 24px; }
              .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon-checked::after {
                content: "";
                width: 16px;
                height: 12px;
                background: url(images/icon-check-white.png) no-repeat center center;
                display: inline-block;
                background-size: contain;
                position: absolute;
                top: 7px;
                left: 4px;
                width: 13px;
                height: 9px; }
            .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist button .text {
              display: block;
              margin-left: 60px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              font-size: 14px; }
            .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist button:hover {
              background: #f2f5fb; }
            .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist button.hidden {
              display: none; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist button {
    width: 100%;
    box-sizing: border-box; }
    .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon,
    .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon-checked {
      top: 7px; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist button {
    font-weight: normal;
    height: 36px;
    line-height: 36px; } }
          .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon {
            width: 14px;
            height: 14px;
            border: 2px solid #4f4f4f;
            border-radius: 50%;
            opacity: 0.7; }
          .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon-checked {
            width: 6px;
            height: 6px;
            border: 6px solid #4b4b4b;
            border-radius: 50%;
            background: none; }
            .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .checklist button .icon-checked::after {
              display: none; }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs {
          padding-left: 60px; }
          .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input {
            width: 100%;
            height: 34px;
            line-height: 34px;
            padding: 0;
            background: none;
            border: 0;
            border-bottom: 1px solid #8d76f0;
            font-size: 17px;
            outline: none;
            width: 67px;
            padding: 0;
            font-size: 14px !important; }
            .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: rgba(132, 148, 169, 0.5); }
            .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: rgba(132, 148, 169, 0.5); }
            .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: rgba(132, 148, 169, 0.5); }
            .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input::placeholder {
              /* Most modern browsers support this now. */
              color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .date-range-inputs .range-control input {
    font-size: 16px; } }
        .company-dictionary-layout .table-filter .filter-datepicker .filter-date-checklist-popup .apply-button {
          display: none; }
      .company-dictionary-layout .table-filter .filter-datepicker .datepicker-popup {
        position: absolute;
        top: 24px;
        left: 24px; }
    .company-dictionary-layout .table-filter .filter-search-string {
      position: relative; }
      .company-dictionary-layout .table-filter .filter-search-string .icon {
        position: absolute;
        top: 50%;
        right: 18px;
        margin-top: -11px;
        width: 22px;
        height: 21px;
        background: url(images/icon-filter-search-string.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
      .company-dictionary-layout .table-filter .filter-search-string input {
        position: relative;
        width: 305px;
        height: 38px;
        line-height: 38px;
        padding: 0 48px 0 16px;
        background: none;
        border: 1px solid rgba(60, 136, 238, 0.3);
        color: #4b4b4b;
        -webkit-border-radius: 19px;
        -moz-border-radius: 19px;
        -ms-border-radius: 19px;
        -o-border-radius: 19px;
        border-radius: 19px; }
    .company-dictionary-layout .table-filter .filter-checklist-buttons {
      position: relative; }
      .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-label-button,
      .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checked-item,
      .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-button-add {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px;
        margin-bottom: 12px;
        background: #fff;
        color: #4b4b4b;
        font-size: 14px;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-label-button .text,
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-label-button .icon,
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checked-item .text,
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checked-item .icon,
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-button-add .text,
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-button-add .icon {
          display: inline-block;
          vertical-align: top; }
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-label-button .icon,
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checked-item .icon,
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-button-add .icon {
          margin-right: 0; }
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-label-button.button-xs,
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checked-item.button-xs,
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-button-add.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-label-button.button-s,
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checked-item.button-s,
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-button-add.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-label-button.button-l,
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checked-item.button-l,
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-button-add.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-label-button:hover,
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checked-item:hover,
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-button-add:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-label-button,
  .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checked-item,
  .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-button-add {
    font-weight: normal; } }
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-label-button:hover,
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checked-item:hover,
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-button-add:hover {
          background: #eaeff9; }
      .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checked-item {
        margin-right: 12px; }
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checked-item .icon-remove {
          width: 19px;
          height: 19px;
          background: url(images/icon-close.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          position: relative;
          top: -1px;
          width: 9px;
          height: 9px;
          margin-left: 8px; }
      .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-button-add {
        width: 32px;
        padding: 0; }
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-button-add .text {
          line-height: 26px;
          font-size: 22px; }
      .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup {
        position: relative;
        display: none;
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        z-index: 20;
        position: absolute;
        top: -21px;
        left: -12px; }
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup.show {
          display: block; }
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup .close-button {
          width: 19px;
          height: 19px;
          background: url(images/icon-close.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          position: absolute;
          top: 25px;
          right: 25px; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup .checklist-header {
          margin: 24px 0 18px 24px;
          font-size: 14px;
          background: none; }
          .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup .checklist-header .icon,
          .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup .checklist-header .text {
            vertical-align: top; }
        .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup .checklist {
          min-width: 240px;
          max-height: 220px;
          padding-bottom: 24px;
          overflow: auto; }
          .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup .checklist button {
            position: relative;
            display: block;
            width: 390px;
            height: 48px;
            line-height: 48px;
            padding-right: 32px;
            background: none;
            font-size: 17px;
            color: #4b4b4b; }
            .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .icon {
              position: absolute;
              top: 13px;
              left: 24px;
              display: inline-block;
              width: 18px;
              height: 18px;
              border: 2px solid #8494a9;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              -ms-border-radius: 3px;
              -o-border-radius: 3px;
              border-radius: 3px; }
            .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .icon-checked {
              position: relative;
              display: inline-block;
              width: 22px;
              height: 22px;
              background: #81bc52;
              border: 0;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              -ms-border-radius: 3px;
              -o-border-radius: 3px;
              border-radius: 3px;
              position: absolute;
              top: 13px;
              left: 24px; }
              .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .icon-checked::after {
                content: "";
                width: 16px;
                height: 12px;
                background: url(images/icon-check-white.png) no-repeat center center;
                display: inline-block;
                background-size: contain;
                position: absolute;
                top: 7px;
                left: 4px;
                width: 13px;
                height: 9px; }
            .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .text {
              display: block;
              margin-left: 60px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              font-size: 14px; }
            .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup .checklist button:hover {
              background: #f2f5fb; }
            .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup .checklist button.hidden {
              display: none; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup .checklist button {
    width: 100%;
    box-sizing: border-box; }
    .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .icon,
    .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup .checklist button .icon-checked {
      top: 7px; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-checklist-buttons .filter-checklist-popup .checklist button {
    font-weight: normal;
    height: 36px;
    line-height: 36px; } }
    .company-dictionary-layout .table-filter .filter-checklist {
      position: relative; }
      .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button {
        width: 100%;
        height: 34px;
        line-height: 34px;
        padding: 0;
        background: none;
        border: 0;
        border-bottom: 1px solid #8d76f0;
        font-size: 17px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: rgba(132, 148, 169, 0.5); }
        .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba(132, 148, 169, 0.5); }
        .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button::-ms-input-placeholder {
          /* Microsoft Edge */
          color: rgba(132, 148, 169, 0.5); }
        .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button::placeholder {
          /* Most modern browsers support this now. */
          color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button {
    font-size: 16px; } }
        .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button .text-value,
        .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button .count-value {
          margin-right: 12px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 16px; }
        .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button .count-value {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          padding: 0 14px;
          height: 20px;
          line-height: 20px;
          color: #4b4b4b;
          background: #bed6f9;
          font-size: 16px;
          font-weight: normal;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px; }
          .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button .count-value .text,
          .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button .count-value .icon {
            display: inline-block;
            vertical-align: top; }
          .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button .count-value .icon {
            margin-right: 0; }
          .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button .count-value.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button .count-value.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button .count-value.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button .count-value:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button .count-value {
    font-weight: normal; } }
          .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button .count-value:hover {
            color: #4b4b4b;
            background: #bed6f9; }
        .company-dictionary-layout .table-filter .filter-checklist .checklist-value-button .button-icon {
          width: 14px;
          height: 7px;
          background: url(images/arrow-bottom.png) no-repeat center center;
          display: inline-block;
          background-size: contain; }
      .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup {
        position: relative;
        display: none;
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        z-index: 20;
        position: absolute;
        top: -24px;
        left: -24px; }
        .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup.show {
          display: block; }
        .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .close-button {
          width: 19px;
          height: 19px;
          background: url(images/icon-close.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          position: absolute;
          top: 25px;
          right: 25px; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
        .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .checklist-header {
          margin: 24px 0 18px 24px;
          font-size: 14px;
          color: #8494a9;
          background: none; }
          .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .checklist-header .icon,
          .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .checklist-header .text {
            vertical-align: top; }
        .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .search-field {
          position: relative;
          padding-left: 24px;
          margin-bottom: 8px;
          width: 190px; }
          .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .search-field input {
            width: 100%;
            height: 34px;
            line-height: 34px;
            padding: 0;
            background: none;
            border: 0;
            border-bottom: 1px solid #8d76f0;
            font-size: 17px;
            outline: none;
            font-size: 14px; }
            .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .search-field input::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: rgba(132, 148, 169, 0.5); }
            .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .search-field input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .search-field input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .search-field input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: rgba(132, 148, 169, 0.5); }
            .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .search-field input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: rgba(132, 148, 169, 0.5); }
            .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .search-field input::placeholder {
              /* Most modern browsers support this now. */
              color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .search-field input {
    font-size: 16px; } }
          .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .search-field .icon {
            width: 14px;
            height: 13px;
            background: url(images/icon-search.png) no-repeat center center;
            display: inline-block;
            background-size: contain;
            position: absolute;
            bottom: 10px;
            right: 0;
            opacity: 0.7; }
        .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .checklist {
          min-width: 240px;
          max-height: 220px;
          padding-bottom: 24px;
          overflow: auto; }
          .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .checklist button {
            position: relative;
            display: block;
            width: 390px;
            height: 48px;
            line-height: 48px;
            padding-right: 32px;
            background: none;
            font-size: 17px;
            color: #4b4b4b; }
            .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .checklist button .icon {
              position: absolute;
              top: 13px;
              left: 24px;
              display: inline-block;
              width: 18px;
              height: 18px;
              border: 2px solid #8494a9;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              -ms-border-radius: 3px;
              -o-border-radius: 3px;
              border-radius: 3px; }
            .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .checklist button .icon-checked {
              position: relative;
              display: inline-block;
              width: 22px;
              height: 22px;
              background: #81bc52;
              border: 0;
              -webkit-border-radius: 3px;
              -moz-border-radius: 3px;
              -ms-border-radius: 3px;
              -o-border-radius: 3px;
              border-radius: 3px;
              position: absolute;
              top: 13px;
              left: 24px; }
              .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .checklist button .icon-checked::after {
                content: "";
                width: 16px;
                height: 12px;
                background: url(images/icon-check-white.png) no-repeat center center;
                display: inline-block;
                background-size: contain;
                position: absolute;
                top: 7px;
                left: 4px;
                width: 13px;
                height: 9px; }
            .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .checklist button .text {
              display: block;
              margin-left: 60px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              font-size: 14px; }
            .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .checklist button:hover {
              background: #f2f5fb; }
            .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .checklist button.hidden {
              display: none; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .checklist button {
    width: 100%;
    box-sizing: border-box; }
    .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .checklist button .icon,
    .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .checklist button .icon-checked {
      top: 7px; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .checklist button {
    font-weight: normal;
    height: 36px;
    line-height: 36px; } }
        .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .apply-button,
        .company-dictionary-layout .table-filter .filter-checklist .filter-checklist-popup .reset-button {
          display: none; }
    .company-dictionary-layout .table-filter .filter-price-range .range-input {
      width: 100%;
      height: 34px;
      line-height: 34px;
      padding: 0;
      background: none;
      border: 0;
      border-bottom: 1px solid #8d76f0;
      font-size: 17px;
      outline: none;
      font-size: 16px; }
      .company-dictionary-layout .table-filter .filter-price-range .range-input::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: rgba(132, 148, 169, 0.5); }
      .company-dictionary-layout .table-filter .filter-price-range .range-input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .company-dictionary-layout .table-filter .filter-price-range .range-input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: rgba(132, 148, 169, 0.5);
        opacity: 1; }
      .company-dictionary-layout .table-filter .filter-price-range .range-input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(132, 148, 169, 0.5); }
      .company-dictionary-layout .table-filter .filter-price-range .range-input::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(132, 148, 169, 0.5); }
      .company-dictionary-layout .table-filter .filter-price-range .range-input::placeholder {
        /* Most modern browsers support this now. */
        color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .table-filter .filter-price-range .range-input {
    font-size: 16px; } }
    .company-dictionary-layout .table-filter .filter-price-range .range-delim {
      display: inline-block;
      width: 34px;
      color: #8494a9;
      text-align: center; }
    .company-dictionary-layout .table-filter .filter-price-range-buttons {
      position: relative; }
      .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-label-button {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px;
        margin-bottom: 12px;
        background: #fff;
        color: #4b4b4b;
        font-size: 14px;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
        .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-label-button .text,
        .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-label-button .icon {
          display: inline-block;
          vertical-align: top; }
        .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-label-button .icon {
          margin-right: 0; }
        .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-label-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-label-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-label-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-label-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-label-button {
    font-weight: normal; } }
        .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-label-button .icon {
          margin-right: 8px;
          color: #b9e398;
          font-size: 22px; }
          .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-label-button .icon:after {
            content: "\20bd"; }
        .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-label-button:hover {
          background: #eaeff9; }
      .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup {
        position: relative;
        display: none;
        background: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        z-index: 20;
        position: absolute;
        top: -21px;
        left: -12px; }
        .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup.show {
          display: block; }
        .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .close-button {
          width: 19px;
          height: 19px;
          background: url(images/icon-close.png) no-repeat center center;
          display: inline-block;
          background-size: contain;
          position: absolute;
          top: 25px;
          right: 25px; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
        .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .checklist-header {
          margin: 26px 0 23px 32px;
          font-size: 14px;
          background: none; }
          .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .checklist-header .icon,
          .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .checklist-header .text {
            vertical-align: top; }
          .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .checklist-header .icon {
            margin-right: 8px;
            color: #b9e398;
            font-size: 22px; }
            .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .checklist-header .icon:after {
              content: "\20bd"; }
        .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .popup-content {
          padding: 0 24px 24px 32px; }
        .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .range-selector {
          width: 300px;
          margin-bottom: 18px;
          font-size: 0;
          border-bottom: 1px solid #8d76f0; }
          .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .range-selector input {
            width: 125px;
            border: none;
            font-size: 22px; }
            .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .range-selector input.range-from {
              text-align: right; }
          .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .range-selector .range-delim {
            display: inline-block;
            width: 50px;
            font-size: 22px;
            text-align: center; }
        .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons {
          text-align: center; }
          .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            background: none;
            border: 1px solid #4b4b4b;
            color: #4b4b4b;
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px;
            line-height: 30px;
            margin: 0 8px; }
            .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button .text,
            .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button .icon {
              display: inline-block;
              vertical-align: top; }
            .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button .icon {
              margin-right: 0; }
            .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button {
    font-weight: normal; } }
            .company-dictionary-layout .table-filter .filter-price-range-buttons .filter-price-range-popup .popup-buttons button:hover {
              background: #f2f5fb; }
    .company-dictionary-layout .table-filter .search-select {
      position: relative; }
      .company-dictionary-layout .table-filter .search-select select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        filter: alpha(opacity=0);
        filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .company-dictionary-layout .table-filter .search-select .search-field {
        position: relative;
        border-bottom: 1px solid #8d76f0; }
        .company-dictionary-layout .table-filter .search-select .search-field input {
          width: 100%;
          height: 34px;
          line-height: 34px;
          padding: 0;
          background: none;
          border: 0;
          border-bottom: 1px solid #8d76f0;
          font-size: 17px;
          outline: none;
          width: 90%;
          border-bottom: 0; }
          .company-dictionary-layout .table-filter .search-select .search-field input::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: rgba(132, 148, 169, 0.5); }
          .company-dictionary-layout .table-filter .search-select .search-field input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .company-dictionary-layout .table-filter .search-select .search-field input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .company-dictionary-layout .table-filter .search-select .search-field input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(132, 148, 169, 0.5); }
          .company-dictionary-layout .table-filter .search-select .search-field input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(132, 148, 169, 0.5); }
          .company-dictionary-layout .table-filter .search-select .search-field input::placeholder {
            /* Most modern browsers support this now. */
            color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .table-filter .search-select .search-field input {
    font-size: 16px; } }
          .company-dictionary-layout .table-filter .search-select .search-field input::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: #4b4b4b; }
          .company-dictionary-layout .table-filter .search-select .search-field input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: #4b4b4b;
            opacity: 1; }
          .company-dictionary-layout .table-filter .search-select .search-field input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #4b4b4b;
            opacity: 1; }
          .company-dictionary-layout .table-filter .search-select .search-field input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #4b4b4b; }
          .company-dictionary-layout .table-filter .search-select .search-field input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #4b4b4b; }
          .company-dictionary-layout .table-filter .search-select .search-field input::placeholder {
            /* Most modern browsers support this now. */
            color: #4b4b4b; }
        .company-dictionary-layout .table-filter .search-select .search-field .field-dropdown-icon {
          position: absolute;
          top: 7px;
          right: 21px;
          width: 0; }
          .company-dictionary-layout .table-filter .search-select .search-field .field-dropdown-icon:after {
            content: "";
            width: 14px;
            height: 7px;
            background: url(images/arrow-bottom.png) no-repeat center center;
            display: inline-block;
            background-size: contain; }
      .company-dictionary-layout .table-filter .search-select .select-list-container {
        display: block;
        background: #fff;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        border-radius: 10px;
        -webkit-transition: box-shadow 0.1s ease;
        -moz-transition: box-shadow 0.1s ease;
        -ms-transition: box-shadow 0.1s ease;
        -o-transition: box-shadow 0.1s ease;
        transition: box-shadow 0.1s ease;
        display: none;
        position: absolute;
        top: -17px;
        left: -17px;
        right: -17px;
        padding-top: 60px;
        padding-bottom: 17px;
        z-index: 1;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
      .company-dictionary-layout .table-filter .search-select .select-list {
        position: relative;
        max-height: 200px;
        overflow: auto; }
        .company-dictionary-layout .table-filter .search-select .select-list .select-list-button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 10px 17px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          text-align: left;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-button .text,
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-button .icon {
            display: inline-block;
            vertical-align: top; }
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-button .icon {
            margin-right: 0; }
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .search-select .select-list .select-list-button {
    font-weight: normal; } }
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-button.active {
            background: #f2f5fb; }
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-button.selected {
            background: #f2f5fb; }
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-button:hover {
            background: #f2f5fb; }
        .company-dictionary-layout .table-filter .search-select .select-list .select-list-action {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          position: relative;
          width: 100%;
          height: auto;
          padding: 10px 17px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          font-weight: normal;
          text-align: left;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
          border-radius: 0; }
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-action .text,
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-action .icon {
            display: inline-block;
            vertical-align: top; }
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-action .icon {
            margin-right: 0; }
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-action.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-action.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-action.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-action:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .search-select .select-list .select-list-action {
    font-weight: normal; } }
          .company-dictionary-layout .table-filter .search-select .select-list .select-list-action:hover {
            background: #f2f5fb; }
      .company-dictionary-layout .table-filter .search-select.show-list .select-list-container {
        display: block; }
      .company-dictionary-layout .table-filter .search-select.show-list .search-field {
        z-index: 1;
        border-bottom-color: rgba(0, 0, 0, 0.1); }
    .company-dictionary-layout .table-filter .filter-button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: none;
      color: #4b4b4b;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px;
      line-height: 38px;
      height: 38px;
      -webkit-border-radius: 19px;
      -moz-border-radius: 19px;
      -ms-border-radius: 19px;
      -o-border-radius: 19px;
      border-radius: 19px;
      display: flex;
      align-items: center; }
      .company-dictionary-layout .table-filter .filter-button .text,
      .company-dictionary-layout .table-filter .filter-button .icon {
        display: inline-block;
        vertical-align: top; }
      .company-dictionary-layout .table-filter .filter-button .icon {
        margin-right: 0; }
      .company-dictionary-layout .table-filter .filter-button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .company-dictionary-layout .table-filter .filter-button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .company-dictionary-layout .table-filter .filter-button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .company-dictionary-layout .table-filter .filter-button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-button {
    font-weight: normal; } }
      .company-dictionary-layout .table-filter .filter-button:hover {
        background: none;
        color: black;
        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }
      .company-dictionary-layout .table-filter .filter-button:active {
        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        -o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .table-filter .filter-field {
    margin-right: 0;
    margin-left: 0; }
  .company-dictionary-layout .table-filter .filter-control .filter-datepicker .filter-date-checklist-popup {
    left: 0;
    top: 0;
    width: 100%; }
  .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup {
    left: 0;
    top: 0;
    width: 100%; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
    .company-dictionary-layout .table-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button .text,
    .company-dictionary-layout .table-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button .icon {
      display: inline-block;
      vertical-align: top; }
    .company-dictionary-layout .table-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button .icon {
      margin-right: 0; }
    .company-dictionary-layout .table-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .company-dictionary-layout .table-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .company-dictionary-layout .table-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .company-dictionary-layout .table-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); } }

@media screen and (max-width: 450px) and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-control .filter-datepicker .filter-date-checklist-popup .apply-button {
    font-weight: normal; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-control .filter-datepicker .filter-date-checklist-popup .checklist-header {
    margin-top: 28px;
    margin-left: 67px;
    font-size: 16px;
    color: #8494a9; }
    .company-dictionary-layout .table-filter .filter-control .filter-datepicker .filter-date-checklist-popup .checklist-header .icon {
      display: none; }
  .company-dictionary-layout .table-filter .filter-control .filter-datepicker .filter-date-checklist-popup .checklist {
    max-height: 9999px; }
    .company-dictionary-layout .table-filter .filter-control .filter-datepicker .filter-date-checklist-popup .checklist button {
      font-weight: normal;
      color: #4b4b4b; }
  .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .checklist-header {
    margin-top: 28px;
    margin-left: 67px;
    font-size: 16px; }
  .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .checklist {
    position: absolute;
    top: 70px;
    bottom: 40px;
    left: 0;
    width: 100%;
    max-height: 9999px; }
    .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .checklist button {
      height: 48px;
      color: #4b4b4b; }
      .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .checklist button .text {
        font-size: 16px; }
      .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .checklist button .icon,
      .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .checklist button .icon-checked {
        top: 12px; }
  .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .search-field + .checklist {
    top: 120px; }
  .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .search-field {
    width: 100%;
    padding-right: 24px;
    box-sizing: border-box; }
    .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .search-field .icon {
      right: 24px; }
  .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
    .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button .text,
    .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button .icon {
      display: inline-block;
      vertical-align: top; }
    .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button .icon {
      margin-right: 0; }
    .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); } }

@media screen and (max-width: 450px) and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .apply-button {
    font-weight: normal; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .reset-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #4b4b4b;
    display: block;
    position: absolute;
    top: 30px;
    right: 24px; }
    .company-dictionary-layout .table-filter .filter-control .filter-checklist .filter-checklist-popup .reset-button:hover {
      color: black; } }
    .company-dictionary-layout .table-filter .filter-field {
      margin: 0; }
    .company-dictionary-layout .table-filter .field-name-send {
      display: none; }
  .company-dictionary-layout .company-tab-form {
    display: block;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: box-shadow 0.1s ease;
    -moz-transition: box-shadow 0.1s ease;
    -ms-transition: box-shadow 0.1s ease;
    -o-transition: box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease;
    padding: 28px; }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-form {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
    .company-dictionary-layout .company-tab-form .back-button {
      margin-bottom: 24px; }
      .company-dictionary-layout .company-tab-form .back-button button {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .company-dictionary-layout .company-tab-form .back-button button:hover {
          color: black; }
        .company-dictionary-layout .company-tab-form .back-button button:before {
          content: "\2190";
          margin-right: 4px; }
    .company-dictionary-layout .company-tab-form .form-description {
      margin-bottom: 24px; }
      .company-dictionary-layout .company-tab-form .form-description p {
        opacity: 0.7; }
    .company-dictionary-layout .company-tab-form .tab-form-label {
      margin-bottom: 48px;
      font-size: 26px; }
    .company-dictionary-layout .company-tab-form .tab-form-label-container .tab-form-label {
      display: inline-block;
      margin-right: 20px; }
    .company-dictionary-layout .company-tab-form .tab-form-label-container .form-alter-action {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b; }
      .company-dictionary-layout .company-tab-form .tab-form-label-container .form-alter-action:hover {
        color: black; }
    .company-dictionary-layout .company-tab-form form {
      display: inline-block;
      width: 500px;
      margin-right: 16px; }
      .company-dictionary-layout .company-tab-form form .form-field {
        margin-bottom: 38px;
        width: 100%; }
        .company-dictionary-layout .company-tab-form form .form-field .form-label {
          display: block;
          font-size: 17px;
          color: #8494a9; }
        .company-dictionary-layout .company-tab-form form .form-field.field-type-colorpicker .form-label {
          margin-bottom: 8px; }
        .company-dictionary-layout .company-tab-form form .form-field.field-type-files .form-label {
          display: none; }
        .company-dictionary-layout .company-tab-form form .form-field .custom-checkput {
          position: relative;
          display: inline-block;
          width: auto;
          height: auto; }
          .company-dictionary-layout .company-tab-form form .form-field .custom-checkput input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            filter: alpha(opacity=0);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .company-dictionary-layout .company-tab-form form .form-field .custom-checkput .checkput-icon {
            position: relative;
            display: inline-block;
            width: 18px;
            height: 18px;
            border: 2px solid #8494a9;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px;
            cursor: pointer; }
          .company-dictionary-layout .company-tab-form form .form-field .custom-checkput.checked .checkput-icon {
            position: relative;
            display: inline-block;
            width: 22px;
            height: 22px;
            background: #81bc52;
            border: 0;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            -o-border-radius: 3px;
            border-radius: 3px; }
            .company-dictionary-layout .company-tab-form form .form-field .custom-checkput.checked .checkput-icon::after {
              content: "";
              width: 16px;
              height: 12px;
              background: url(images/icon-check-white.png) no-repeat center center;
              display: inline-block;
              background-size: contain;
              position: absolute;
              top: 7px;
              left: 4px;
              width: 13px;
              height: 9px; }
        .company-dictionary-layout .company-tab-form form .form-field.field-type-checkbox .custom-checkput, .company-dictionary-layout .company-tab-form form .form-field.field-type-agreement .custom-checkput, .company-dictionary-layout .company-tab-form form .form-field.field-type-license-agreement .custom-checkput {
          float: left; }
        .company-dictionary-layout .company-tab-form form .form-field.field-type-checkbox .form-label, .company-dictionary-layout .company-tab-form form .form-field.field-type-agreement .form-label, .company-dictionary-layout .company-tab-form form .form-field.field-type-license-agreement .form-label {
          margin-left: 34px;
          cursor: pointer; }
        .company-dictionary-layout .company-tab-form form .form-field.field-type-agreement button, .company-dictionary-layout .company-tab-form form .form-field.field-type-license-agreement button {
          display: block;
          margin-left: 34px;
          height: auto;
          line-height: 22px;
          font-size: 17px;
          color: #8494a9;
          text-decoration: underline;
          background: none; }
        .company-dictionary-layout .company-tab-form form .form-field .form-field-password-generator button {
          color: #2f80ed;
          cursor: pointer;
          background: transparent; }
        .company-dictionary-layout .company-tab-form form .form-field .form-field-password-check {
          line-height: 16px;
          font-size: 12px;
          position: relative;
          margin-top: -2px;
          z-index: 1; }
          .company-dictionary-layout .company-tab-form form .form-field .form-field-password-check.active-red {
            width: 30%;
            border-top: 2px solid #F35E84;
            margin-bottom: 11px; }
          .company-dictionary-layout .company-tab-form form .form-field .form-field-password-check.active-green {
            width: 100%;
            border-top: 2px solid #5A9C76;
            margin-bottom: 11px; }
          .company-dictionary-layout .company-tab-form form .form-field .form-field-password-check.active-yellow {
            width: 70%;
            border-top: 2px solid #C99300;
            margin-bottom: 11px; }
          .company-dictionary-layout .company-tab-form form .form-field .form-field-password-check .password-check-hint-green {
            color: #5A9C76; }
          .company-dictionary-layout .company-tab-form form .form-field .form-field-password-check .password-check-hint-yellow {
            color: #C99300; }
          .company-dictionary-layout .company-tab-form form .form-field .form-field-password-check .password-check-hint-red {
            color: #F35E84; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .company-tab-form form .form-field {
    margin-bottom: 28px; }
    .company-dictionary-layout .company-tab-form form .form-field .form-label {
      font-size: 14px;
      line-height: 14px; } }
        .company-dictionary-layout .company-tab-form form .form-field .form-button {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          width: 100%; }
          .company-dictionary-layout .company-tab-form form .form-field .form-button .text,
          .company-dictionary-layout .company-tab-form form .form-field .form-button .icon {
            display: inline-block;
            vertical-align: top; }
          .company-dictionary-layout .company-tab-form form .form-field .form-button .icon {
            margin-right: 0; }
          .company-dictionary-layout .company-tab-form form .form-field .form-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .company-dictionary-layout .company-tab-form form .form-field .form-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .company-dictionary-layout .company-tab-form form .form-field .form-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .company-dictionary-layout .company-tab-form form .form-field .form-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-form form .form-field .form-button {
    font-weight: normal; } }
        .company-dictionary-layout .company-tab-form form .form-field.field-error .form-label {
          color: #f32424; }
        .company-dictionary-layout .company-tab-form form .form-field.field-error .form-control .form-input,
        .company-dictionary-layout .company-tab-form form .form-field.field-error .form-control textarea,
        .company-dictionary-layout .company-tab-form form .form-field.field-error .form-control select {
          border-color: #f32424; }
        .company-dictionary-layout .company-tab-form form .form-field.field-error .form-field-error p {
          color: #f32424;
          white-space: pre-wrap; }
      .company-dictionary-layout .company-tab-form form .form-control {
        position: relative; }
        .company-dictionary-layout .company-tab-form form .form-control .form-input,
        .company-dictionary-layout .company-tab-form form .form-control textarea,
        .company-dictionary-layout .company-tab-form form .form-control select {
          width: 100%;
          height: 34px;
          line-height: 34px;
          padding: 0;
          background: none;
          border: 0;
          border-bottom: 1px solid #8d76f0;
          font-size: 17px;
          outline: none; }
          .company-dictionary-layout .company-tab-form form .form-control .form-input::-webkit-input-placeholder,
          .company-dictionary-layout .company-tab-form form .form-control textarea::-webkit-input-placeholder,
          .company-dictionary-layout .company-tab-form form .form-control select::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: rgba(132, 148, 169, 0.5); }
          .company-dictionary-layout .company-tab-form form .form-control .form-input:-moz-placeholder,
          .company-dictionary-layout .company-tab-form form .form-control textarea:-moz-placeholder,
          .company-dictionary-layout .company-tab-form form .form-control select:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .company-dictionary-layout .company-tab-form form .form-control .form-input::-moz-placeholder,
          .company-dictionary-layout .company-tab-form form .form-control textarea::-moz-placeholder,
          .company-dictionary-layout .company-tab-form form .form-control select::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: rgba(132, 148, 169, 0.5);
            opacity: 1; }
          .company-dictionary-layout .company-tab-form form .form-control .form-input:-ms-input-placeholder,
          .company-dictionary-layout .company-tab-form form .form-control textarea:-ms-input-placeholder,
          .company-dictionary-layout .company-tab-form form .form-control select:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(132, 148, 169, 0.5); }
          .company-dictionary-layout .company-tab-form form .form-control .form-input::-ms-input-placeholder,
          .company-dictionary-layout .company-tab-form form .form-control textarea::-ms-input-placeholder,
          .company-dictionary-layout .company-tab-form form .form-control select::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(132, 148, 169, 0.5); }
          .company-dictionary-layout .company-tab-form form .form-control .form-input::placeholder,
          .company-dictionary-layout .company-tab-form form .form-control textarea::placeholder,
          .company-dictionary-layout .company-tab-form form .form-control select::placeholder {
            /* Most modern browsers support this now. */
            color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .company-tab-form form .form-control .form-input,
  .company-dictionary-layout .company-tab-form form .form-control textarea,
  .company-dictionary-layout .company-tab-form form .form-control select {
    font-size: 16px; } }
        .company-dictionary-layout .company-tab-form form .form-control textarea {
          resize: none;
          padding: 5px 0;
          line-height: 22px; }
        .company-dictionary-layout .company-tab-form form .form-control select {
          cursor: pointer; }
        .company-dictionary-layout .company-tab-form form .form-control .styled-select {
          position: relative; }
          .company-dictionary-layout .company-tab-form form .form-control .styled-select select {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            filter: alpha(opacity=0);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-button {
            position: relative;
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            width: 100%;
            height: auto;
            padding: 15px 30px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            border-radius: 8px;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
            .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-button .text,
            .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-button .icon {
              display: inline-block;
              vertical-align: top; }
            .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-button .icon {
              margin-right: 0; }
            .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-button {
    font-weight: normal; } }
            .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-button .post-icon {
              position: relative;
              top: -1px;
              left: 8px;
              display: inline-block;
              width: 0; }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-button .post-icon:after {
                content: "";
                width: 14px;
                height: 7px;
                background: url(images/arrow-bottom.png) no-repeat center center;
                display: inline-block;
                background-size: contain; }
            .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-button:hover {
              background: none;
              -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
              -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
              -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
              -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
          .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list {
            display: block;
            background: #fff;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
            border-radius: 10px;
            -webkit-transition: box-shadow 0.1s ease;
            -moz-transition: box-shadow 0.1s ease;
            -ms-transition: box-shadow 0.1s ease;
            -o-transition: box-shadow 0.1s ease;
            transition: box-shadow 0.1s ease;
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
            .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              position: relative;
              width: 100%;
              height: auto;
              padding: 15px 50px;
              line-height: 20px;
              background: none;
              color: #4b4b4b;
              font-weight: normal;
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              -ms-border-radius: 0;
              -o-border-radius: 0;
              border-radius: 0; }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button .text,
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button .icon {
                display: inline-block;
                vertical-align: top; }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button .icon {
                margin-right: 0; }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button {
    font-weight: normal; } }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button.active .pre-icon {
                position: relative;
                left: -26px;
                display: inline-block;
                width: 0; }
                .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button.active .pre-icon:after {
                  content: "";
                  width: 16px;
                  height: 12px;
                  background: url(images/icon-check.png) no-repeat center center;
                  display: inline-block;
                  background-size: contain; }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button:after {
                content: "";
                display: block;
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                height: 1px;
                background: rgba(0, 0, 0, 0.1);
                opacity: 0.5;
                filter: alpha(opacity=50);
                filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
                -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button:hover {
                background: #f2f5fb; }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button:first-child:after {
                left: 23px;
                right: 23px;
                opacity: 1;
                filter: alpha(opacity=100);
                filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
                -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button:last-child {
                margin-bottom: 40px; }
            .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              position: relative;
              width: 100%;
              height: auto;
              padding: 15px 23px;
              line-height: 20px;
              background: none;
              color: #4b4b4b;
              font-weight: normal;
              text-align: left;
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              -ms-border-radius: 0;
              -o-border-radius: 0;
              border-radius: 0; }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action .text,
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action .icon {
                display: inline-block;
                vertical-align: top; }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action .icon {
                margin-right: 0; }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action {
    font-weight: normal; } }
              .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action:hover {
                background: #f2f5fb; }
            .company-dictionary-layout .company-tab-form form .form-control .styled-select .select-list.show {
              display: block; }
          .company-dictionary-layout .company-tab-form form .form-control .styled-select.show-list .select-button {
            opacity: 0;
            filter: alpha(opacity=0);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .company-dictionary-layout .company-tab-form form .form-control .search-select {
          position: relative; }
          .company-dictionary-layout .company-tab-form form .form-control .search-select select {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            filter: alpha(opacity=0);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field {
            position: relative;
            border-bottom: 1px solid #8d76f0; }
            .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field input {
              width: 100%;
              height: 34px;
              line-height: 34px;
              padding: 0;
              background: none;
              border: 0;
              border-bottom: 1px solid #8d76f0;
              font-size: 17px;
              outline: none;
              width: 90%;
              border-bottom: 0; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field input::-webkit-input-placeholder {
                /* WebKit, Blink, Edge */
                color: rgba(132, 148, 169, 0.5); }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field input:-moz-placeholder {
                /* Mozilla Firefox 4 to 18 */
                color: rgba(132, 148, 169, 0.5);
                opacity: 1; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field input::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                color: rgba(132, 148, 169, 0.5);
                opacity: 1; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field input:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: rgba(132, 148, 169, 0.5); }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field input::-ms-input-placeholder {
                /* Microsoft Edge */
                color: rgba(132, 148, 169, 0.5); }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field input::placeholder {
                /* Most modern browsers support this now. */
                color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field input {
    font-size: 16px; } }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field input::-webkit-input-placeholder {
                /* WebKit, Blink, Edge */
                color: #4b4b4b; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field input:-moz-placeholder {
                /* Mozilla Firefox 4 to 18 */
                color: #4b4b4b;
                opacity: 1; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field input::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                color: #4b4b4b;
                opacity: 1; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field input:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #4b4b4b; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field input::-ms-input-placeholder {
                /* Microsoft Edge */
                color: #4b4b4b; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field input::placeholder {
                /* Most modern browsers support this now. */
                color: #4b4b4b; }
            .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field .field-dropdown-icon {
              position: absolute;
              top: 7px;
              right: 21px;
              width: 0; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field .field-dropdown-icon:after {
                content: "";
                width: 14px;
                height: 7px;
                background: url(images/arrow-bottom.png) no-repeat center center;
                display: inline-block;
                background-size: contain; }
          .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list-container {
            display: block;
            background: #fff;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
            border-radius: 10px;
            -webkit-transition: box-shadow 0.1s ease;
            -moz-transition: box-shadow 0.1s ease;
            -ms-transition: box-shadow 0.1s ease;
            -o-transition: box-shadow 0.1s ease;
            transition: box-shadow 0.1s ease;
            display: none;
            position: absolute;
            top: -17px;
            left: -17px;
            right: -17px;
            padding-top: 60px;
            padding-bottom: 17px;
            z-index: 1;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
          .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list {
            position: relative;
            max-height: 200px;
            overflow: auto; }
            .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-button {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              position: relative;
              width: 100%;
              height: auto;
              padding: 10px 17px;
              line-height: 20px;
              background: none;
              color: #4b4b4b;
              font-weight: normal;
              text-align: left;
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              -ms-border-radius: 0;
              -o-border-radius: 0;
              border-radius: 0; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-button .text,
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-button .icon {
                display: inline-block;
                vertical-align: top; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-button .icon {
                margin-right: 0; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-button.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-button.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-button.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-button:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-button {
    font-weight: normal; } }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-button.active {
                background: #f2f5fb; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-button.selected {
                background: #f2f5fb; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-button:hover {
                background: #f2f5fb; }
            .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-action {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              position: relative;
              width: 100%;
              height: auto;
              padding: 10px 17px;
              line-height: 20px;
              background: none;
              color: #4b4b4b;
              font-weight: normal;
              text-align: left;
              -webkit-border-radius: 0;
              -moz-border-radius: 0;
              -ms-border-radius: 0;
              -o-border-radius: 0;
              border-radius: 0; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-action .text,
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-action .icon {
                display: inline-block;
                vertical-align: top; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-action .icon {
                margin-right: 0; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-action.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-action.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-action.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-action:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-action {
    font-weight: normal; } }
              .company-dictionary-layout .company-tab-form form .form-control .search-select .select-list .select-list-action:hover {
                background: #f2f5fb; }
          .company-dictionary-layout .company-tab-form form .form-control .search-select.show-list .select-list-container {
            display: block; }
          .company-dictionary-layout .company-tab-form form .form-control .search-select.show-list .search-field {
            z-index: 1;
            border-bottom-color: rgba(0, 0, 0, 0.1); }
        .company-dictionary-layout .company-tab-form form .form-control .field-price-input-container {
          position: relative;
          width: 100%; }
          .company-dictionary-layout .company-tab-form form .form-control .field-price-input-container .unit-box {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            text-align: center;
            white-space: nowrap; }
            .company-dictionary-layout .company-tab-form form .form-control .field-price-input-container .unit-box span {
              display: inline-block; }
            .company-dictionary-layout .company-tab-form form .form-control .field-price-input-container .unit-box .unit-background-text {
              color: transparent;
              font-size: 38px;
              max-width: 95%;
              overflow: hidden; }
            .company-dictionary-layout .company-tab-form form .form-control .field-price-input-container .unit-box .unit-background-text-space {
              width: 24px; }
          .company-dictionary-layout .company-tab-form form .form-control .field-price-input-container input {
            position: relative;
            height: 50px;
            line-height: 50px;
            box-sizing: border-box;
            text-align: center;
            font-size: 38px; }
          .company-dictionary-layout .company-tab-form form .form-control .field-price-input-container .unit-ruble {
            line-height: 52px;
            font-size: 32px;
            color: #8494a9;
            opacity: 0.7;
            filter: alpha(opacity=70);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=70;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .company-dictionary-layout .company-tab-form form .form-control .field-price-input-container .unit-ruble:after {
              content: "\20bd"; }
        .company-dictionary-layout .company-tab-form form .form-control .colorpicker {
          position: relative; }
          .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-button {
            display: block;
            width: 105px;
            height: 30px;
            background: none;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette {
            display: block;
            background: #fff;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
            border-radius: 10px;
            -webkit-transition: box-shadow 0.1s ease;
            -moz-transition: box-shadow 0.1s ease;
            -ms-transition: box-shadow 0.1s ease;
            -o-transition: box-shadow 0.1s ease;
            transition: box-shadow 0.1s ease;
            position: absolute;
            left: 0;
            bottom: -200px;
            width: 504px;
            padding: 32px 20px;
            z-index: 4;
            -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
            -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
            -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
            .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-header {
              margin-bottom: 40px;
              margin-left: 10px;
              font-size: 22px; }
            .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-color-buttons {
              margin-bottom: 12px; }
            .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-color-button {
              display: inline-block;
              width: 60px;
              height: 60px;
              margin: 0 12px 24px 12px;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
              border-radius: 50%; }
              .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-color-button.active, .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-color-button:hover {
                margin: -10px 2px 14px 2px;
                width: 80px;
                height: 80px;
                border: 10px solid #f2f5fb; }
            .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-corner-close-button {
              position: absolute;
              top: 0;
              right: 0;
              width: 80px;
              height: 80px;
              vertical-align: middle;
              text-align: center;
              font-size: 38px;
              font-family: "times new roman", verdana;
              background: none; }
            .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons {
              text-align: right; }
              .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
                display: inline-block;
                height: 50px;
                line-height: 48px;
                padding: 0 20px;
                text-align: center;
                vertical-align: top;
                background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
                border: 0;
                color: #fff;
                font-size: 17px;
                font-weight: 500;
                box-sizing: border-box;
                -webkit-border-radius: 25px;
                -moz-border-radius: 25px;
                -ms-border-radius: 25px;
                -o-border-radius: 25px;
                border-radius: 25px;
                -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
                -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
                -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
                -o-transition: background 0.1s ease, box-shadow 0.1s ease;
                transition: background 0.1s ease, box-shadow 0.1s ease;
                background: none;
                border: 1px solid #4b4b4b;
                color: #4b4b4b;
                font-size: 16px;
                min-width: 191px; }
                .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
                .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                  display: inline-block;
                  vertical-align: top; }
                .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                  margin-right: 0; }
                .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs {
                  height: 32px;
                  line-height: 32px;
                  font-size: 12px;
                  font-weight: normal;
                  -webkit-border-radius: 15px;
                  -moz-border-radius: 15px;
                  -ms-border-radius: 15px;
                  -o-border-radius: 15px;
                  border-radius: 15px; }
                .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s {
                  height: 40px;
                  line-height: 38px;
                  font-size: 14px;
                  font-weight: normal;
                  -webkit-border-radius: 20px;
                  -moz-border-radius: 20px;
                  -ms-border-radius: 20px;
                  -o-border-radius: 20px;
                  border-radius: 20px; }
                .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l {
                  height: 60px;
                  line-height: 58px;
                  -webkit-border-radius: 30px;
                  -moz-border-radius: 30px;
                  -ms-border-radius: 30px;
                  -o-border-radius: 30px;
                  border-radius: 30px; }
                .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                  background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
    font-weight: normal; } }
                .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                  background: #f2f5fb; }
          .company-dictionary-layout .company-tab-form form .form-control .colorpicker .picker-palette-overlay {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.7);
            z-index: 2; }
        .company-dictionary-layout .company-tab-form form .form-control .form-autocomplete-input {
          position: relative; }
          .company-dictionary-layout .company-tab-form form .form-control .form-autocomplete-input .form-autocomplete-dropdown {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            max-height: 300px;
            background: #fff;
            -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
            -ms-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
            -o-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
            z-index: 1;
            overflow: auto; }
            .company-dictionary-layout .company-tab-form form .form-control .form-autocomplete-input .form-autocomplete-dropdown button {
              background: none;
              border: none;
              width: 100%;
              padding: 8px 10px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              text-align: left; }
              .company-dictionary-layout .company-tab-form form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover {
                background: #f2f5fb; }
              .company-dictionary-layout .company-tab-form form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child {
                border-bottom: 0; }
        .company-dictionary-layout .company-tab-form form .form-control .field-text-list-container .text-list-item {
          position: relative;
          margin-bottom: 10px; }
          .company-dictionary-layout .company-tab-form form .form-control .field-text-list-container .text-list-item .remove-text-item {
            background: none;
            border: none;
            cursor: pointer;
            color: #4b4b4b;
            position: absolute;
            top: 0;
            right: 0;
            color: #f32424;
            font-size: 22px; }
            .company-dictionary-layout .company-tab-form form .form-control .field-text-list-container .text-list-item .remove-text-item:hover {
              color: black; }
        .company-dictionary-layout .company-tab-form form .form-control .field-text-list-container .add-text-item {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b; }
          .company-dictionary-layout .company-tab-form form .form-control .field-text-list-container .add-text-item:hover {
            color: black; }
        .company-dictionary-layout .company-tab-form form .form-control .files-loader-container .files-loader-input-container {
          margin-bottom: 20px; }
        .company-dictionary-layout .company-tab-form form .form-control .files-loader-input-container {
          position: relative; }
          .company-dictionary-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            background: none;
            border: 1px solid #4b4b4b;
            color: #4b4b4b;
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px;
            width: 100%;
            height: 60px;
            line-height: 60px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            -ms-border-radius: 8px;
            -o-border-radius: 8px;
            border-radius: 8px; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area .text,
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area .icon {
              display: inline-block;
              vertical-align: top; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area .icon {
              margin-right: 0; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area {
    font-weight: normal; } }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area:hover {
              background: #f2f5fb; }
          .company-dictionary-layout .company-tab-form form .form-control .files-loader-input-container input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;
            filter: alpha(opacity=0);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .company-dictionary-layout .company-tab-form form .form-control .files-loader-input-container:hover .input-label-area {
            background: #f2f5fb; }
        .company-dictionary-layout .company-tab-form form .form-control .files-loader-list .files-list-item {
          display: flex;
          justify-content: space-between;
          text-align: left;
          margin-bottom: 10px; }
          .company-dictionary-layout .company-tab-form form .form-control .files-loader-list .files-list-item .list-item-col {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-list .files-list-item .list-item-col.icon {
              width: 38px;
              flex-shrink: 0; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-list .files-list-item .list-item-col.caption {
              margin-right: auto;
              margin-left: 18px; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-list .files-list-item .list-item-col.price {
              flex-shrink: 0;
              text-align: right; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-list .files-list-item .list-item-col .note {
              font-size: 12px;
              color: #8494a9; }
          .company-dictionary-layout .company-tab-form form .form-control .files-loader-list .files-list-item .icon {
            display: inline-block;
            width: 38px;
            height: 38px;
            line-height: 37px;
            text-align: center;
            font-size: 22px;
            font-weight: 500;
            color: #fff;
            background: #bed6f9;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            border-radius: 50%;
            font-size: 12px;
            font-weight: normal; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-list .files-list-item .icon.icon-sub-correction {
              line-height: 34.2px; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-1 {
              background: #bed6f9; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-2 {
              background: #b9e398; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-3 {
              background: #e4e597; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-4 {
              background: #e7c740; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-5 {
              background: #e74040; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-6 {
              background: #cf91af; }
          .company-dictionary-layout .company-tab-form form .form-control .files-loader-list .files-list-item .caption {
            height: 38px;
            line-height: 38px;
            margin-left: 12px; }
          .company-dictionary-layout .company-tab-form form .form-control .files-loader-list .files-list-item .remove {
            flex-shrink: 0;
            margin-left: 0; }
            .company-dictionary-layout .company-tab-form form .form-control .files-loader-list .files-list-item .remove button {
              background: none;
              border: none;
              height: 38px;
              line-height: 38px;
              font-size: 22px;
              color: #f32424; }
        .company-dictionary-layout .company-tab-form form .form-control .select-container .note {
          position: relative;
          padding-left: 25px;
          padding-top: 6px;
          font-size: 14px;
          color: #8494a9;
          line-height: 14px; }
          .company-dictionary-layout .company-tab-form form .form-control .select-container .note:before {
            content: "i";
            position: absolute;
            top: 5px;
            left: 0;
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-right: 10px;
            line-height: 14px;
            border: 1px solid #8494a9;
            border-radius: 50%;
            text-align: center; }
        .company-dictionary-layout .company-tab-form form .form-control .input-phone {
          display: flex; }
          .company-dictionary-layout .company-tab-form form .form-control .input-phone .phone-country-code .styled-select {
            position: inherit; }
            .company-dictionary-layout .company-tab-form form .form-control .input-phone .phone-country-code .styled-select .select-button {
              padding: 0;
              width: 80px;
              text-align: left;
              box-shadow: none;
              line-height: 34px;
              border-bottom: 1px solid #8d76f0;
              border-radius: 0; }
              .company-dictionary-layout .company-tab-form form .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after {
                width: 8px; }
            .company-dictionary-layout .company-tab-form form .form-control .input-phone .phone-country-code .styled-select .select-list {
              top: 100%;
              width: 100%;
              max-height: 200px;
              margin-top: 10px;
              overflow: auto; }
              .company-dictionary-layout .company-tab-form form .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button {
                text-align: left; }
            .company-dictionary-layout .company-tab-form form .form-control .input-phone .phone-country-code .styled-select select {
              width: 80px; }
            .company-dictionary-layout .company-tab-form form .form-control .input-phone .phone-country-code .styled-select.show-list .select-button {
              opacity: 1;
              filter: alpha(opacity=100);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .company-dictionary-layout .company-tab-form form .form-control .input-phone .phone-number {
            flex: 1; }
        .company-dictionary-layout .company-tab-form form .form-control .password-input {
          position: relative; }
          .company-dictionary-layout .company-tab-form form .form-control .password-input .eye-icon {
            font-size: 18px;
            background-color: transparent;
            color: #8494a9;
            position: absolute;
            right: 0; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .company-tab-form form .form-control .search-select .search-field .field-dropdown-icon {
    top: 3px; } }
        .company-dictionary-layout .company-tab-form form .form-control .datepicker-popup {
          position: absolute;
          top: 100%;
          left: 0; }
      .company-dictionary-layout .company-tab-form form .form-errors-list {
        margin-bottom: 24px; }
        .company-dictionary-layout .company-tab-form form .form-errors-list .error-item {
          margin-bottom: 8px;
          color: #f32424; }
          .company-dictionary-layout .company-tab-form form .form-errors-list .error-item:last-child {
            margin-bottom: 0; }
      .company-dictionary-layout .company-tab-form form .form-messages-list {
        margin-bottom: 24px;
        text-align: center; }
        .company-dictionary-layout .company-tab-form form .form-messages-list .message-item {
          margin-bottom: 8px;
          color: #4dbd7d;
          font-size: 17px;
          line-height: 22px; }
          .company-dictionary-layout .company-tab-form form .form-messages-list .message-item:last-child {
            margin-bottom: 0; }
      .company-dictionary-layout .company-tab-form form .form-field .form-label {
        font-size: 14px; }
      .company-dictionary-layout .company-tab-form form .form-field.field-name-income-style, .company-dictionary-layout .company-tab-form form .form-field.field-name-outcome-style {
        margin-top: -63px;
        margin-left: 100px; }
        .company-dictionary-layout .company-tab-form form .form-field.field-name-income-style .form-label, .company-dictionary-layout .company-tab-form form .form-field.field-name-outcome-style .form-label {
          display: none; }
    .company-dictionary-layout .company-tab-form .form-button {
      max-width: 340px; }
    .company-dictionary-layout .company-tab-form .form-cancel {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: none;
      border: 1px solid #4b4b4b;
      color: #4b4b4b;
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px;
      width: 100%; }
      .company-dictionary-layout .company-tab-form .form-cancel .text,
      .company-dictionary-layout .company-tab-form .form-cancel .icon {
        display: inline-block;
        vertical-align: top; }
      .company-dictionary-layout .company-tab-form .form-cancel .icon {
        margin-right: 0; }
      .company-dictionary-layout .company-tab-form .form-cancel.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .company-dictionary-layout .company-tab-form .form-cancel.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .company-dictionary-layout .company-tab-form .form-cancel.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .company-dictionary-layout .company-tab-form .form-cancel:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-form .form-cancel {
    font-weight: normal; } }
      .company-dictionary-layout .company-tab-form .form-cancel:hover {
        background: #f2f5fb; }
    .company-dictionary-layout .company-tab-form .form-delete,
    .company-dictionary-layout .company-tab-form .form-remove-integration {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: none;
      border: 1px solid #f35e84;
      color: #f35e84;
      min-width: 208px;
      margin-right: 30px; }
      .company-dictionary-layout .company-tab-form .form-delete .text,
      .company-dictionary-layout .company-tab-form .form-delete .icon,
      .company-dictionary-layout .company-tab-form .form-remove-integration .text,
      .company-dictionary-layout .company-tab-form .form-remove-integration .icon {
        display: inline-block;
        vertical-align: top; }
      .company-dictionary-layout .company-tab-form .form-delete .icon,
      .company-dictionary-layout .company-tab-form .form-remove-integration .icon {
        margin-right: 0; }
      .company-dictionary-layout .company-tab-form .form-delete.button-xs,
      .company-dictionary-layout .company-tab-form .form-remove-integration.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .company-dictionary-layout .company-tab-form .form-delete.button-s,
      .company-dictionary-layout .company-tab-form .form-remove-integration.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .company-dictionary-layout .company-tab-form .form-delete.button-l,
      .company-dictionary-layout .company-tab-form .form-remove-integration.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .company-dictionary-layout .company-tab-form .form-delete:hover,
      .company-dictionary-layout .company-tab-form .form-remove-integration:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-form .form-delete,
  .company-dictionary-layout .company-tab-form .form-remove-integration {
    font-weight: normal; } }
      .company-dictionary-layout .company-tab-form .form-delete .text,
      .company-dictionary-layout .company-tab-form .form-delete .icon,
      .company-dictionary-layout .company-tab-form .form-remove-integration .text,
      .company-dictionary-layout .company-tab-form .form-remove-integration .icon {
        display: inline-block;
        vertical-align: top; }
      .company-dictionary-layout .company-tab-form .form-delete .icon,
      .company-dictionary-layout .company-tab-form .form-remove-integration .icon {
        margin-right: 0; }
      .company-dictionary-layout .company-tab-form .form-delete.button-xs,
      .company-dictionary-layout .company-tab-form .form-remove-integration.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .company-dictionary-layout .company-tab-form .form-delete.button-s,
      .company-dictionary-layout .company-tab-form .form-remove-integration.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .company-dictionary-layout .company-tab-form .form-delete.button-l,
      .company-dictionary-layout .company-tab-form .form-remove-integration.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .company-dictionary-layout .company-tab-form .form-delete:hover,
      .company-dictionary-layout .company-tab-form .form-remove-integration:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-form .form-delete,
  .company-dictionary-layout .company-tab-form .form-remove-integration {
    font-weight: normal; } }
      .company-dictionary-layout .company-tab-form .form-delete:hover,
      .company-dictionary-layout .company-tab-form .form-remove-integration:hover {
        background: #f2f5fb; }
  .company-dictionary-layout .company-tab-item-card {
    display: block;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: box-shadow 0.1s ease;
    -moz-transition: box-shadow 0.1s ease;
    -ms-transition: box-shadow 0.1s ease;
    -o-transition: box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease;
    padding: 28px; }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .company-tab-item-card {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
    .company-dictionary-layout .company-tab-item-card .back-link-container {
      margin-bottom: 18px; }
    .company-dictionary-layout .company-tab-item-card .back-link {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b; }
      .company-dictionary-layout .company-tab-item-card .back-link:hover {
        color: black; }
    .company-dictionary-layout .company-tab-item-card .card-label-container {
      margin-bottom: 36px; }
      .company-dictionary-layout .company-tab-item-card .card-label-container .card-label {
        display: inline;
        margin-right: 8px;
        vertical-align: middle; }
      .company-dictionary-layout .company-tab-item-card .card-label-container .edit-action {
        width: 22px;
        height: 22px;
        background: url(images/icon-edit-gray.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: relative;
        top: 2px;
        vertical-align: middle; }
    .company-dictionary-layout .company-tab-item-card .card-label {
      font-size: 22px;
      font-weight: 500; }
    .company-dictionary-layout .company-tab-item-card .card-field {
      margin-bottom: 32px; }
      .company-dictionary-layout .company-tab-item-card .card-field .label {
        color: #8494a9; }
      .company-dictionary-layout .company-tab-item-card .card-field .value {
        font-size: 17px; }
  .company-dictionary-layout .confirm-popup {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background: rgba(255, 255, 255, 0.7);
    animation: popup-background-fade-in 0.8s ease; }
    .company-dictionary-layout .confirm-popup .popup-container {
      position: relative;
      display: none;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 20;
      display: block;
      width: 550px;
      margin: 0 auto;
      padding-bottom: 58px;
      top: 10%;
      max-height: 75%;
      overflow: auto;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      border-radius: 8px;
      /* Scrollbar styles */ }
      .company-dictionary-layout .confirm-popup .popup-container.show {
        display: block; }
      .company-dictionary-layout .confirm-popup .popup-container .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 25px; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .confirm-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .confirm-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .company-dictionary-layout .confirm-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
      .company-dictionary-layout .confirm-popup .popup-container::-webkit-scrollbar {
        width: 12px;
        height: 12px; }
      .company-dictionary-layout .confirm-popup .popup-container::-webkit-scrollbar-track {
        background: #f5f5f5;
        border-radius: 10px; }
      .company-dictionary-layout .confirm-popup .popup-container::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #ccc; }
      .company-dictionary-layout .confirm-popup .popup-container .popup-content form .form-control .datepicker-popup {
        position: fixed;
        top: 50%;
        left: 50%;
        margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .confirm-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
      .company-dictionary-layout .confirm-popup .popup-container .popup-content .popup-actions {
        width: 340px;
        margin: 0 auto; }
        .company-dictionary-layout .confirm-popup .popup-container .popup-content .popup-actions button {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b; }
          .company-dictionary-layout .confirm-popup .popup-container .popup-content .popup-actions button:hover {
            color: black; }
    .company-dictionary-layout .confirm-popup .header {
      padding: 32px 24px;
      font-size: 22px; }
    .company-dictionary-layout .confirm-popup .body {
      padding: 0 24px 32px 24px; }
    .company-dictionary-layout .confirm-popup.show {
      display: block; }

@media screen and (max-width: 600px) {
  .company-dictionary-layout .confirm-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .company-dictionary-layout .confirm-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .confirm-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
    .company-dictionary-layout .confirm-popup .popup-container {
      text-align: center;
      padding-bottom: 0; }
    .company-dictionary-layout .confirm-popup .popup-content {
      padding: 0 64px 52px 64px; }
      .company-dictionary-layout .confirm-popup .popup-content .header {
        padding: 64px 0 56px 0; }
      .company-dictionary-layout .confirm-popup .popup-content .body {
        margin-bottom: 32px; }
      .company-dictionary-layout .confirm-popup .popup-content .form-fieldset {
        display: flex; }
        .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field {
          flex: 1;
          margin: 0 12px; }
          .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            background: none;
            border: 1px solid #4b4b4b;
            color: #4b4b4b;
            width: 100%;
            font-size: 16px; }
            .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button .text,
            .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
              display: inline-block;
              vertical-align: top; }
            .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
              margin-right: 0; }
            .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button {
    font-weight: normal; } }
            .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
              background: #f2f5fb; }
          .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            width: 100%;
            font-size: 16px; }
            .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
            .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
              display: inline-block;
              vertical-align: top; }
            .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
              margin-right: 0; }
            .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
    .company-dictionary-layout .confirm-popup.show .popup-container {
      animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .confirm-popup .popup-content {
    padding-right: 32px;
    padding-left: 32px; }
    .company-dictionary-layout .confirm-popup .popup-content .header {
      padding: 32px 0 28px 0; }
    .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .company-dictionary-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; } }
  .company-dictionary-layout .confirm-delete-popup {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background: rgba(255, 255, 255, 0.7);
    animation: popup-background-fade-in 0.8s ease; }
    .company-dictionary-layout .confirm-delete-popup .popup-container {
      position: relative;
      display: none;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 20;
      display: block;
      width: 550px;
      margin: 0 auto;
      padding-bottom: 58px;
      top: 10%;
      max-height: 75%;
      overflow: auto;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      border-radius: 8px;
      /* Scrollbar styles */ }
      .company-dictionary-layout .confirm-delete-popup .popup-container.show {
        display: block; }
      .company-dictionary-layout .confirm-delete-popup .popup-container .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 25px; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .confirm-delete-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .confirm-delete-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .company-dictionary-layout .confirm-delete-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
      .company-dictionary-layout .confirm-delete-popup .popup-container::-webkit-scrollbar {
        width: 12px;
        height: 12px; }
      .company-dictionary-layout .confirm-delete-popup .popup-container::-webkit-scrollbar-track {
        background: #f5f5f5;
        border-radius: 10px; }
      .company-dictionary-layout .confirm-delete-popup .popup-container::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #ccc; }
      .company-dictionary-layout .confirm-delete-popup .popup-container .popup-content form .form-control .datepicker-popup {
        position: fixed;
        top: 50%;
        left: 50%;
        margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .company-dictionary-layout .confirm-delete-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
      .company-dictionary-layout .confirm-delete-popup .popup-container .popup-content .popup-actions {
        width: 340px;
        margin: 0 auto; }
        .company-dictionary-layout .confirm-delete-popup .popup-container .popup-content .popup-actions button {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b; }
          .company-dictionary-layout .confirm-delete-popup .popup-container .popup-content .popup-actions button:hover {
            color: black; }
    .company-dictionary-layout .confirm-delete-popup .header {
      padding: 32px 24px;
      font-size: 22px; }
    .company-dictionary-layout .confirm-delete-popup .body {
      padding: 0 24px 32px 24px; }
    .company-dictionary-layout .confirm-delete-popup.show {
      display: block; }

@media screen and (max-width: 600px) {
  .company-dictionary-layout .confirm-delete-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .company-dictionary-layout .confirm-delete-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .confirm-delete-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
    .company-dictionary-layout .confirm-delete-popup .popup-container {
      text-align: center;
      padding-bottom: 0; }
    .company-dictionary-layout .confirm-delete-popup .popup-content {
      padding: 0 64px 52px 64px; }
      .company-dictionary-layout .confirm-delete-popup .popup-content .header {
        padding: 64px 0 56px 0; }
      .company-dictionary-layout .confirm-delete-popup .popup-content .body {
        margin-bottom: 32px; }
      .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset {
        display: flex; }
        .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field {
          flex: 1;
          margin: 0 12px; }
          .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            background: none;
            border: 1px solid #4b4b4b;
            color: #4b4b4b;
            width: 100%;
            font-size: 16px; }
            .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .text,
            .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
              display: inline-block;
              vertical-align: top; }
            .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
              margin-right: 0; }
            .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
    font-weight: normal; } }
            .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
              background: #f2f5fb; }
          .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            width: 100%;
            font-size: 16px; }
            .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
            .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
              display: inline-block;
              vertical-align: top; }
            .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
              margin-right: 0; }
            .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
    .company-dictionary-layout .confirm-delete-popup.show .popup-container {
      animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .confirm-delete-popup .popup-content {
    padding-right: 32px;
    padding-left: 32px; }
    .company-dictionary-layout .confirm-delete-popup .popup-content .header {
      padding: 32px 0 28px 0; }
    .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; } }
    .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: #f35e84;
      width: 100%;
      font-size: 16px; }
      .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
      .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
        display: inline-block;
        vertical-align: top; }
      .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
        margin-right: 0; }
      .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
      .company-dictionary-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
        background: #e64871; }

/* COMPANY ABOUT */
.company-about-layout {
  display: flex; }
  .company-about-layout .layout-content {
    flex: 1; }
  .company-about-layout .table-header {
    display: none;
    margin-bottom: 36px; }
    .company-about-layout .table-header p {
      font-size: 22px;
      font-weight: 500; }
  .company-about-layout .tab-action {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    margin-bottom: 40px;
    margin-right: 20px; }
    .company-about-layout .tab-action .text,
    .company-about-layout .tab-action .icon {
      display: inline-block;
      vertical-align: top; }
    .company-about-layout .tab-action .icon {
      margin-right: 0; }
    .company-about-layout .tab-action.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .company-about-layout .tab-action.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .company-about-layout .tab-action.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .company-about-layout .tab-action:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-about-layout .tab-action {
    font-weight: normal; } }
    .company-about-layout .tab-action.action-delete {
      background: #f35e84; }
      .company-about-layout .tab-action.action-delete:hover {
        background: #e64871; }
  .company-about-layout .about-download-button {
    display: inline-block;
    height: 50px;
    line-height: 48px;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
    border: 0;
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    border-radius: 25px;
    -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
    -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
    -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
    -o-transition: background 0.1s ease, box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease;
    background: none;
    border: 1px solid #4b4b4b;
    color: #4b4b4b;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    font-weight: normal;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    margin-bottom: 40px; }
    .company-about-layout .about-download-button .text,
    .company-about-layout .about-download-button .icon {
      display: inline-block;
      vertical-align: top; }
    .company-about-layout .about-download-button .icon {
      margin-right: 0; }
    .company-about-layout .about-download-button.button-xs {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: normal;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      border-radius: 15px; }
    .company-about-layout .about-download-button.button-s {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .company-about-layout .about-download-button.button-l {
      height: 60px;
      line-height: 58px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      -ms-border-radius: 30px;
      -o-border-radius: 30px;
      border-radius: 30px; }
    .company-about-layout .about-download-button:hover {
      background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-about-layout .about-download-button {
    font-weight: normal; } }
    .company-about-layout .about-download-button:hover {
      background: #f2f5fb; }
  .company-about-layout .about-content {
    display: block;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: box-shadow 0.1s ease;
    -moz-transition: box-shadow 0.1s ease;
    -ms-transition: box-shadow 0.1s ease;
    -o-transition: box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease;
    padding: 28px; }

@media screen and (max-width: 450px) {
  .company-about-layout .about-content {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
  .company-about-layout .about-field {
    margin-bottom: 16px; }
    .company-about-layout .about-field .label {
      color: #8494a9; }
    .company-about-layout .about-field .value {
      font-size: 17px; }
  .company-about-layout .company-tab-form {
    display: block;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: box-shadow 0.1s ease;
    -moz-transition: box-shadow 0.1s ease;
    -ms-transition: box-shadow 0.1s ease;
    -o-transition: box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease;
    padding: 28px; }

@media screen and (max-width: 450px) {
  .company-about-layout .company-tab-form {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
    .company-about-layout .company-tab-form .form-content {
      max-width: 348px; }
    .company-about-layout .company-tab-form .form-field.field-name-contacts_header {
      margin-top: 30px; }
    .company-about-layout .company-tab-form .tab-form-label,
    .company-about-layout .company-tab-form .form-field.field-name-contacts_header p {
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: 500; }
    .company-about-layout .company-tab-form .tab-form-label-container .tab-form-label {
      display: inline-block;
      margin-right: 20px; }
    .company-about-layout .company-tab-form .tab-form-label-container .form-alter-action {
      background: none;
      border: none;
      cursor: pointer;
      color: #4b4b4b; }
      .company-about-layout .company-tab-form .tab-form-label-container .form-alter-action:hover {
        color: black; }
    .company-about-layout .company-tab-form form .form-field {
      margin-bottom: 38px;
      width: 100%; }
      .company-about-layout .company-tab-form form .form-field .form-label {
        display: block;
        font-size: 17px;
        color: #8494a9; }
      .company-about-layout .company-tab-form form .form-field.field-type-colorpicker .form-label {
        margin-bottom: 8px; }
      .company-about-layout .company-tab-form form .form-field.field-type-files .form-label {
        display: none; }
      .company-about-layout .company-tab-form form .form-field .custom-checkput {
        position: relative;
        display: inline-block;
        width: auto;
        height: auto; }
        .company-about-layout .company-tab-form form .form-field .custom-checkput input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .company-about-layout .company-tab-form form .form-field .custom-checkput .checkput-icon {
          position: relative;
          display: inline-block;
          width: 18px;
          height: 18px;
          border: 2px solid #8494a9;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          border-radius: 3px;
          cursor: pointer; }
        .company-about-layout .company-tab-form form .form-field .custom-checkput.checked .checkput-icon {
          position: relative;
          display: inline-block;
          width: 22px;
          height: 22px;
          background: #81bc52;
          border: 0;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          border-radius: 3px; }
          .company-about-layout .company-tab-form form .form-field .custom-checkput.checked .checkput-icon::after {
            content: "";
            width: 16px;
            height: 12px;
            background: url(images/icon-check-white.png) no-repeat center center;
            display: inline-block;
            background-size: contain;
            position: absolute;
            top: 7px;
            left: 4px;
            width: 13px;
            height: 9px; }
      .company-about-layout .company-tab-form form .form-field.field-type-checkbox .custom-checkput, .company-about-layout .company-tab-form form .form-field.field-type-agreement .custom-checkput, .company-about-layout .company-tab-form form .form-field.field-type-license-agreement .custom-checkput {
        float: left; }
      .company-about-layout .company-tab-form form .form-field.field-type-checkbox .form-label, .company-about-layout .company-tab-form form .form-field.field-type-agreement .form-label, .company-about-layout .company-tab-form form .form-field.field-type-license-agreement .form-label {
        margin-left: 34px;
        cursor: pointer; }
      .company-about-layout .company-tab-form form .form-field.field-type-agreement button, .company-about-layout .company-tab-form form .form-field.field-type-license-agreement button {
        display: block;
        margin-left: 34px;
        height: auto;
        line-height: 22px;
        font-size: 17px;
        color: #8494a9;
        text-decoration: underline;
        background: none; }
      .company-about-layout .company-tab-form form .form-field .form-field-password-generator button {
        color: #2f80ed;
        cursor: pointer;
        background: transparent; }
      .company-about-layout .company-tab-form form .form-field .form-field-password-check {
        line-height: 16px;
        font-size: 12px;
        position: relative;
        margin-top: -2px;
        z-index: 1; }
        .company-about-layout .company-tab-form form .form-field .form-field-password-check.active-red {
          width: 30%;
          border-top: 2px solid #F35E84;
          margin-bottom: 11px; }
        .company-about-layout .company-tab-form form .form-field .form-field-password-check.active-green {
          width: 100%;
          border-top: 2px solid #5A9C76;
          margin-bottom: 11px; }
        .company-about-layout .company-tab-form form .form-field .form-field-password-check.active-yellow {
          width: 70%;
          border-top: 2px solid #C99300;
          margin-bottom: 11px; }
        .company-about-layout .company-tab-form form .form-field .form-field-password-check .password-check-hint-green {
          color: #5A9C76; }
        .company-about-layout .company-tab-form form .form-field .form-field-password-check .password-check-hint-yellow {
          color: #C99300; }
        .company-about-layout .company-tab-form form .form-field .form-field-password-check .password-check-hint-red {
          color: #F35E84; }

@media screen and (max-width: 500px) {
  .company-about-layout .company-tab-form form .form-field {
    margin-bottom: 28px; }
    .company-about-layout .company-tab-form form .form-field .form-label {
      font-size: 14px;
      line-height: 14px; } }
      .company-about-layout .company-tab-form form .form-field .form-button {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        width: 100%; }
        .company-about-layout .company-tab-form form .form-field .form-button .text,
        .company-about-layout .company-tab-form form .form-field .form-button .icon {
          display: inline-block;
          vertical-align: top; }
        .company-about-layout .company-tab-form form .form-field .form-button .icon {
          margin-right: 0; }
        .company-about-layout .company-tab-form form .form-field .form-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .company-about-layout .company-tab-form form .form-field .form-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .company-about-layout .company-tab-form form .form-field .form-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .company-about-layout .company-tab-form form .form-field .form-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-about-layout .company-tab-form form .form-field .form-button {
    font-weight: normal; } }
      .company-about-layout .company-tab-form form .form-field.field-error .form-label {
        color: #f32424; }
      .company-about-layout .company-tab-form form .form-field.field-error .form-control .form-input,
      .company-about-layout .company-tab-form form .form-field.field-error .form-control textarea,
      .company-about-layout .company-tab-form form .form-field.field-error .form-control select {
        border-color: #f32424; }
      .company-about-layout .company-tab-form form .form-field.field-error .form-field-error p {
        color: #f32424;
        white-space: pre-wrap; }
    .company-about-layout .company-tab-form form .form-control {
      position: relative; }
      .company-about-layout .company-tab-form form .form-control .form-input,
      .company-about-layout .company-tab-form form .form-control textarea,
      .company-about-layout .company-tab-form form .form-control select {
        width: 100%;
        height: 34px;
        line-height: 34px;
        padding: 0;
        background: none;
        border: 0;
        border-bottom: 1px solid #8d76f0;
        font-size: 17px;
        outline: none; }
        .company-about-layout .company-tab-form form .form-control .form-input::-webkit-input-placeholder,
        .company-about-layout .company-tab-form form .form-control textarea::-webkit-input-placeholder,
        .company-about-layout .company-tab-form form .form-control select::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: rgba(132, 148, 169, 0.5); }
        .company-about-layout .company-tab-form form .form-control .form-input:-moz-placeholder,
        .company-about-layout .company-tab-form form .form-control textarea:-moz-placeholder,
        .company-about-layout .company-tab-form form .form-control select:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .company-about-layout .company-tab-form form .form-control .form-input::-moz-placeholder,
        .company-about-layout .company-tab-form form .form-control textarea::-moz-placeholder,
        .company-about-layout .company-tab-form form .form-control select::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .company-about-layout .company-tab-form form .form-control .form-input:-ms-input-placeholder,
        .company-about-layout .company-tab-form form .form-control textarea:-ms-input-placeholder,
        .company-about-layout .company-tab-form form .form-control select:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba(132, 148, 169, 0.5); }
        .company-about-layout .company-tab-form form .form-control .form-input::-ms-input-placeholder,
        .company-about-layout .company-tab-form form .form-control textarea::-ms-input-placeholder,
        .company-about-layout .company-tab-form form .form-control select::-ms-input-placeholder {
          /* Microsoft Edge */
          color: rgba(132, 148, 169, 0.5); }
        .company-about-layout .company-tab-form form .form-control .form-input::placeholder,
        .company-about-layout .company-tab-form form .form-control textarea::placeholder,
        .company-about-layout .company-tab-form form .form-control select::placeholder {
          /* Most modern browsers support this now. */
          color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .company-about-layout .company-tab-form form .form-control .form-input,
  .company-about-layout .company-tab-form form .form-control textarea,
  .company-about-layout .company-tab-form form .form-control select {
    font-size: 16px; } }
      .company-about-layout .company-tab-form form .form-control textarea {
        resize: none;
        padding: 5px 0;
        line-height: 22px; }
      .company-about-layout .company-tab-form form .form-control select {
        cursor: pointer; }
      .company-about-layout .company-tab-form form .form-control .styled-select {
        position: relative; }
        .company-about-layout .company-tab-form form .form-control .styled-select select {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .company-about-layout .company-tab-form form .form-control .styled-select .select-button {
          position: relative;
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          width: 100%;
          height: auto;
          padding: 15px 30px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          border-radius: 8px;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
          .company-about-layout .company-tab-form form .form-control .styled-select .select-button .text,
          .company-about-layout .company-tab-form form .form-control .styled-select .select-button .icon {
            display: inline-block;
            vertical-align: top; }
          .company-about-layout .company-tab-form form .form-control .styled-select .select-button .icon {
            margin-right: 0; }
          .company-about-layout .company-tab-form form .form-control .styled-select .select-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .company-about-layout .company-tab-form form .form-control .styled-select .select-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .company-about-layout .company-tab-form form .form-control .styled-select .select-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .company-about-layout .company-tab-form form .form-control .styled-select .select-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-about-layout .company-tab-form form .form-control .styled-select .select-button {
    font-weight: normal; } }
          .company-about-layout .company-tab-form form .form-control .styled-select .select-button .post-icon {
            position: relative;
            top: -1px;
            left: 8px;
            display: inline-block;
            width: 0; }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-button .post-icon:after {
              content: "";
              width: 14px;
              height: 7px;
              background: url(images/arrow-bottom.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
          .company-about-layout .company-tab-form form .form-control .styled-select .select-button:hover {
            background: none;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
        .company-about-layout .company-tab-form form .form-control .styled-select .select-list {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 1;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .company-about-layout .company-tab-form form .form-control .styled-select .select-list {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
          .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 15px 50px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button .text,
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button .icon {
              display: inline-block;
              vertical-align: top; }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button .icon {
              margin-right: 0; }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button {
    font-weight: normal; } }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button.active .pre-icon {
              position: relative;
              left: -26px;
              display: inline-block;
              width: 0; }
              .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button.active .pre-icon:after {
                content: "";
                width: 16px;
                height: 12px;
                background: url(images/icon-check.png) no-repeat center center;
                display: inline-block;
                background-size: contain; }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button:after {
              content: "";
              display: block;
              position: absolute;
              top: 100%;
              left: 0;
              right: 0;
              height: 1px;
              background: rgba(0, 0, 0, 0.1);
              opacity: 0.5;
              filter: alpha(opacity=50);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button:hover {
              background: #f2f5fb; }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button:first-child:after {
              left: 23px;
              right: 23px;
              opacity: 1;
              filter: alpha(opacity=100);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-button:last-child {
              margin-bottom: 40px; }
          .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 15px 23px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action .text,
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action .icon {
              display: inline-block;
              vertical-align: top; }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action .icon {
              margin-right: 0; }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action {
    font-weight: normal; } }
            .company-about-layout .company-tab-form form .form-control .styled-select .select-list .select-list-action:hover {
              background: #f2f5fb; }
          .company-about-layout .company-tab-form form .form-control .styled-select .select-list.show {
            display: block; }
        .company-about-layout .company-tab-form form .form-control .styled-select.show-list .select-button {
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .company-about-layout .company-tab-form form .form-control .search-select {
        position: relative; }
        .company-about-layout .company-tab-form form .form-control .search-select select {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .company-about-layout .company-tab-form form .form-control .search-select .search-field {
          position: relative;
          border-bottom: 1px solid #8d76f0; }
          .company-about-layout .company-tab-form form .form-control .search-select .search-field input {
            width: 100%;
            height: 34px;
            line-height: 34px;
            padding: 0;
            background: none;
            border: 0;
            border-bottom: 1px solid #8d76f0;
            font-size: 17px;
            outline: none;
            width: 90%;
            border-bottom: 0; }
            .company-about-layout .company-tab-form form .form-control .search-select .search-field input::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: rgba(132, 148, 169, 0.5); }
            .company-about-layout .company-tab-form form .form-control .search-select .search-field input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .company-about-layout .company-tab-form form .form-control .search-select .search-field input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .company-about-layout .company-tab-form form .form-control .search-select .search-field input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: rgba(132, 148, 169, 0.5); }
            .company-about-layout .company-tab-form form .form-control .search-select .search-field input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: rgba(132, 148, 169, 0.5); }
            .company-about-layout .company-tab-form form .form-control .search-select .search-field input::placeholder {
              /* Most modern browsers support this now. */
              color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .company-about-layout .company-tab-form form .form-control .search-select .search-field input {
    font-size: 16px; } }
            .company-about-layout .company-tab-form form .form-control .search-select .search-field input::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: #4b4b4b; }
            .company-about-layout .company-tab-form form .form-control .search-select .search-field input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: #4b4b4b;
              opacity: 1; }
            .company-about-layout .company-tab-form form .form-control .search-select .search-field input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: #4b4b4b;
              opacity: 1; }
            .company-about-layout .company-tab-form form .form-control .search-select .search-field input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #4b4b4b; }
            .company-about-layout .company-tab-form form .form-control .search-select .search-field input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: #4b4b4b; }
            .company-about-layout .company-tab-form form .form-control .search-select .search-field input::placeholder {
              /* Most modern browsers support this now. */
              color: #4b4b4b; }
          .company-about-layout .company-tab-form form .form-control .search-select .search-field .field-dropdown-icon {
            position: absolute;
            top: 7px;
            right: 21px;
            width: 0; }
            .company-about-layout .company-tab-form form .form-control .search-select .search-field .field-dropdown-icon:after {
              content: "";
              width: 14px;
              height: 7px;
              background: url(images/arrow-bottom.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
        .company-about-layout .company-tab-form form .form-control .search-select .select-list-container {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          display: none;
          position: absolute;
          top: -17px;
          left: -17px;
          right: -17px;
          padding-top: 60px;
          padding-bottom: 17px;
          z-index: 1;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .company-about-layout .company-tab-form form .form-control .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
        .company-about-layout .company-tab-form form .form-control .search-select .select-list {
          position: relative;
          max-height: 200px;
          overflow: auto; }
          .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 10px 17px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-button .text,
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-button .icon {
              display: inline-block;
              vertical-align: top; }
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-button .icon {
              margin-right: 0; }
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-button {
    font-weight: normal; } }
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-button.active {
              background: #f2f5fb; }
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-button.selected {
              background: #f2f5fb; }
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-button:hover {
              background: #f2f5fb; }
          .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-action {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 10px 17px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-action .text,
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-action .icon {
              display: inline-block;
              vertical-align: top; }
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-action .icon {
              margin-right: 0; }
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-action.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-action.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-action.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-action:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-action {
    font-weight: normal; } }
            .company-about-layout .company-tab-form form .form-control .search-select .select-list .select-list-action:hover {
              background: #f2f5fb; }
        .company-about-layout .company-tab-form form .form-control .search-select.show-list .select-list-container {
          display: block; }
        .company-about-layout .company-tab-form form .form-control .search-select.show-list .search-field {
          z-index: 1;
          border-bottom-color: rgba(0, 0, 0, 0.1); }
      .company-about-layout .company-tab-form form .form-control .field-price-input-container {
        position: relative;
        width: 100%; }
        .company-about-layout .company-tab-form form .form-control .field-price-input-container .unit-box {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          text-align: center;
          white-space: nowrap; }
          .company-about-layout .company-tab-form form .form-control .field-price-input-container .unit-box span {
            display: inline-block; }
          .company-about-layout .company-tab-form form .form-control .field-price-input-container .unit-box .unit-background-text {
            color: transparent;
            font-size: 38px;
            max-width: 95%;
            overflow: hidden; }
          .company-about-layout .company-tab-form form .form-control .field-price-input-container .unit-box .unit-background-text-space {
            width: 24px; }
        .company-about-layout .company-tab-form form .form-control .field-price-input-container input {
          position: relative;
          height: 50px;
          line-height: 50px;
          box-sizing: border-box;
          text-align: center;
          font-size: 38px; }
        .company-about-layout .company-tab-form form .form-control .field-price-input-container .unit-ruble {
          line-height: 52px;
          font-size: 32px;
          color: #8494a9;
          opacity: 0.7;
          filter: alpha(opacity=70);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=70;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .company-about-layout .company-tab-form form .form-control .field-price-input-container .unit-ruble:after {
            content: "\20bd"; }
      .company-about-layout .company-tab-form form .form-control .colorpicker {
        position: relative; }
        .company-about-layout .company-tab-form form .form-control .colorpicker .picker-button {
          display: block;
          width: 105px;
          height: 30px;
          background: none;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          position: absolute;
          left: 0;
          bottom: -200px;
          width: 504px;
          padding: 32px 20px;
          z-index: 4;
          -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 450px) {
  .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
          .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-header {
            margin-bottom: 40px;
            margin-left: 10px;
            font-size: 22px; }
          .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-color-buttons {
            margin-bottom: 12px; }
          .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-color-button {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin: 0 12px 24px 12px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            border-radius: 50%; }
            .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-color-button.active, .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-color-button:hover {
              margin: -10px 2px 14px 2px;
              width: 80px;
              height: 80px;
              border: 10px solid #f2f5fb; }
          .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-corner-close-button {
            position: absolute;
            top: 0;
            right: 0;
            width: 80px;
            height: 80px;
            vertical-align: middle;
            text-align: center;
            font-size: 38px;
            font-family: "times new roman", verdana;
            background: none; }
          .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons {
            text-align: right; }
            .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              background: none;
              border: 1px solid #4b4b4b;
              color: #4b4b4b;
              font-size: 16px;
              min-width: 191px; }
              .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
              .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                display: inline-block;
                vertical-align: top; }
              .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                margin-right: 0; }
              .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
    font-weight: normal; } }
              .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                background: #f2f5fb; }
        .company-about-layout .company-tab-form form .form-control .colorpicker .picker-palette-overlay {
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.7);
          z-index: 2; }
      .company-about-layout .company-tab-form form .form-control .form-autocomplete-input {
        position: relative; }
        .company-about-layout .company-tab-form form .form-control .form-autocomplete-input .form-autocomplete-dropdown {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          max-height: 300px;
          background: #fff;
          -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          z-index: 1;
          overflow: auto; }
          .company-about-layout .company-tab-form form .form-control .form-autocomplete-input .form-autocomplete-dropdown button {
            background: none;
            border: none;
            width: 100%;
            padding: 8px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            text-align: left; }
            .company-about-layout .company-tab-form form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover {
              background: #f2f5fb; }
            .company-about-layout .company-tab-form form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child {
              border-bottom: 0; }
      .company-about-layout .company-tab-form form .form-control .field-text-list-container .text-list-item {
        position: relative;
        margin-bottom: 10px; }
        .company-about-layout .company-tab-form form .form-control .field-text-list-container .text-list-item .remove-text-item {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b;
          position: absolute;
          top: 0;
          right: 0;
          color: #f32424;
          font-size: 22px; }
          .company-about-layout .company-tab-form form .form-control .field-text-list-container .text-list-item .remove-text-item:hover {
            color: black; }
      .company-about-layout .company-tab-form form .form-control .field-text-list-container .add-text-item {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .company-about-layout .company-tab-form form .form-control .field-text-list-container .add-text-item:hover {
          color: black; }
      .company-about-layout .company-tab-form form .form-control .files-loader-container .files-loader-input-container {
        margin-bottom: 20px; }
      .company-about-layout .company-tab-form form .form-control .files-loader-input-container {
        position: relative; }
        .company-about-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          background: none;
          border: 1px solid #4b4b4b;
          color: #4b4b4b;
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px;
          width: 100%;
          height: 60px;
          line-height: 60px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          border-radius: 8px; }
          .company-about-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area .text,
          .company-about-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area .icon {
            display: inline-block;
            vertical-align: top; }
          .company-about-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area .icon {
            margin-right: 0; }
          .company-about-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .company-about-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .company-about-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .company-about-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-about-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area {
    font-weight: normal; } }
          .company-about-layout .company-tab-form form .form-control .files-loader-input-container .input-label-area:hover {
            background: #f2f5fb; }
        .company-about-layout .company-tab-form form .form-control .files-loader-input-container input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .company-about-layout .company-tab-form form .form-control .files-loader-input-container:hover .input-label-area {
          background: #f2f5fb; }
      .company-about-layout .company-tab-form form .form-control .files-loader-list .files-list-item {
        display: flex;
        justify-content: space-between;
        text-align: left;
        margin-bottom: 10px; }
        .company-about-layout .company-tab-form form .form-control .files-loader-list .files-list-item .list-item-col {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
          .company-about-layout .company-tab-form form .form-control .files-loader-list .files-list-item .list-item-col.icon {
            width: 38px;
            flex-shrink: 0; }
          .company-about-layout .company-tab-form form .form-control .files-loader-list .files-list-item .list-item-col.caption {
            margin-right: auto;
            margin-left: 18px; }
          .company-about-layout .company-tab-form form .form-control .files-loader-list .files-list-item .list-item-col.price {
            flex-shrink: 0;
            text-align: right; }
          .company-about-layout .company-tab-form form .form-control .files-loader-list .files-list-item .list-item-col .note {
            font-size: 12px;
            color: #8494a9; }
        .company-about-layout .company-tab-form form .form-control .files-loader-list .files-list-item .icon {
          display: inline-block;
          width: 38px;
          height: 38px;
          line-height: 37px;
          text-align: center;
          font-size: 22px;
          font-weight: 500;
          color: #fff;
          background: #bed6f9;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%;
          font-size: 12px;
          font-weight: normal; }
          .company-about-layout .company-tab-form form .form-control .files-loader-list .files-list-item .icon.icon-sub-correction {
            line-height: 34.2px; }
          .company-about-layout .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-1 {
            background: #bed6f9; }
          .company-about-layout .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-2 {
            background: #b9e398; }
          .company-about-layout .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-3 {
            background: #e4e597; }
          .company-about-layout .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-4 {
            background: #e7c740; }
          .company-about-layout .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-5 {
            background: #e74040; }
          .company-about-layout .company-tab-form form .form-control .files-loader-list .files-list-item .icon.color-6 {
            background: #cf91af; }
        .company-about-layout .company-tab-form form .form-control .files-loader-list .files-list-item .caption {
          height: 38px;
          line-height: 38px;
          margin-left: 12px; }
        .company-about-layout .company-tab-form form .form-control .files-loader-list .files-list-item .remove {
          flex-shrink: 0;
          margin-left: 0; }
          .company-about-layout .company-tab-form form .form-control .files-loader-list .files-list-item .remove button {
            background: none;
            border: none;
            height: 38px;
            line-height: 38px;
            font-size: 22px;
            color: #f32424; }
      .company-about-layout .company-tab-form form .form-control .select-container .note {
        position: relative;
        padding-left: 25px;
        padding-top: 6px;
        font-size: 14px;
        color: #8494a9;
        line-height: 14px; }
        .company-about-layout .company-tab-form form .form-control .select-container .note:before {
          content: "i";
          position: absolute;
          top: 5px;
          left: 0;
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-right: 10px;
          line-height: 14px;
          border: 1px solid #8494a9;
          border-radius: 50%;
          text-align: center; }
      .company-about-layout .company-tab-form form .form-control .input-phone {
        display: flex; }
        .company-about-layout .company-tab-form form .form-control .input-phone .phone-country-code .styled-select {
          position: inherit; }
          .company-about-layout .company-tab-form form .form-control .input-phone .phone-country-code .styled-select .select-button {
            padding: 0;
            width: 80px;
            text-align: left;
            box-shadow: none;
            line-height: 34px;
            border-bottom: 1px solid #8d76f0;
            border-radius: 0; }
            .company-about-layout .company-tab-form form .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after {
              width: 8px; }
          .company-about-layout .company-tab-form form .form-control .input-phone .phone-country-code .styled-select .select-list {
            top: 100%;
            width: 100%;
            max-height: 200px;
            margin-top: 10px;
            overflow: auto; }
            .company-about-layout .company-tab-form form .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button {
              text-align: left; }
          .company-about-layout .company-tab-form form .form-control .input-phone .phone-country-code .styled-select select {
            width: 80px; }
          .company-about-layout .company-tab-form form .form-control .input-phone .phone-country-code .styled-select.show-list .select-button {
            opacity: 1;
            filter: alpha(opacity=100);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .company-about-layout .company-tab-form form .form-control .input-phone .phone-number {
          flex: 1; }
      .company-about-layout .company-tab-form form .form-control .password-input {
        position: relative; }
        .company-about-layout .company-tab-form form .form-control .password-input .eye-icon {
          font-size: 18px;
          background-color: transparent;
          color: #8494a9;
          position: absolute;
          right: 0; }

@media screen and (max-width: 500px) {
  .company-about-layout .company-tab-form form .form-control .search-select .search-field .field-dropdown-icon {
    top: 3px; } }
      .company-about-layout .company-tab-form form .form-control .datepicker-popup {
        position: absolute;
        top: 100%;
        left: 0; }
    .company-about-layout .company-tab-form form .form-errors-list {
      margin-bottom: 24px; }
      .company-about-layout .company-tab-form form .form-errors-list .error-item {
        margin-bottom: 8px;
        color: #f32424; }
        .company-about-layout .company-tab-form form .form-errors-list .error-item:last-child {
          margin-bottom: 0; }
    .company-about-layout .company-tab-form form .form-messages-list {
      margin-bottom: 24px;
      text-align: center; }
      .company-about-layout .company-tab-form form .form-messages-list .message-item {
        margin-bottom: 8px;
        color: #4dbd7d;
        font-size: 17px;
        line-height: 22px; }
        .company-about-layout .company-tab-form form .form-messages-list .message-item:last-child {
          margin-bottom: 0; }
    .company-about-layout .company-tab-form form .form-field .form-label {
      font-size: 14px; }
    .company-about-layout .company-tab-form .form-cancel {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: none;
      border: 1px solid #4b4b4b;
      color: #4b4b4b;
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px;
      width: 100%; }
      .company-about-layout .company-tab-form .form-cancel .text,
      .company-about-layout .company-tab-form .form-cancel .icon {
        display: inline-block;
        vertical-align: top; }
      .company-about-layout .company-tab-form .form-cancel .icon {
        margin-right: 0; }
      .company-about-layout .company-tab-form .form-cancel.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .company-about-layout .company-tab-form .form-cancel.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .company-about-layout .company-tab-form .form-cancel.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .company-about-layout .company-tab-form .form-cancel:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-about-layout .company-tab-form .form-cancel {
    font-weight: normal; } }
      .company-about-layout .company-tab-form .form-cancel:hover {
        background: #f2f5fb; }
  .company-about-layout .confirm-popup {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background: rgba(255, 255, 255, 0.7);
    animation: popup-background-fade-in 0.8s ease; }
    .company-about-layout .confirm-popup .popup-container {
      position: relative;
      display: none;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 20;
      display: block;
      width: 550px;
      margin: 0 auto;
      padding-bottom: 58px;
      top: 10%;
      max-height: 75%;
      overflow: auto;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      border-radius: 8px;
      /* Scrollbar styles */ }
      .company-about-layout .confirm-popup .popup-container.show {
        display: block; }
      .company-about-layout .confirm-popup .popup-container .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 25px; }

@media screen and (max-width: 500px) {
  .company-about-layout .confirm-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .company-about-layout .confirm-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .company-about-layout .confirm-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
      .company-about-layout .confirm-popup .popup-container::-webkit-scrollbar {
        width: 12px;
        height: 12px; }
      .company-about-layout .confirm-popup .popup-container::-webkit-scrollbar-track {
        background: #f5f5f5;
        border-radius: 10px; }
      .company-about-layout .confirm-popup .popup-container::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #ccc; }
      .company-about-layout .confirm-popup .popup-container .popup-content form .form-control .datepicker-popup {
        position: fixed;
        top: 50%;
        left: 50%;
        margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .company-about-layout .confirm-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
      .company-about-layout .confirm-popup .popup-container .popup-content .popup-actions {
        width: 340px;
        margin: 0 auto; }
        .company-about-layout .confirm-popup .popup-container .popup-content .popup-actions button {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b; }
          .company-about-layout .confirm-popup .popup-container .popup-content .popup-actions button:hover {
            color: black; }
    .company-about-layout .confirm-popup .header {
      padding: 32px 24px;
      font-size: 22px; }
    .company-about-layout .confirm-popup .body {
      padding: 0 24px 32px 24px; }
    .company-about-layout .confirm-popup.show {
      display: block; }

@media screen and (max-width: 600px) {
  .company-about-layout .confirm-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .company-about-layout .confirm-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .company-about-layout .confirm-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
    .company-about-layout .confirm-popup .popup-container {
      text-align: center;
      padding-bottom: 0; }
    .company-about-layout .confirm-popup .popup-content {
      padding: 0 64px 52px 64px; }
      .company-about-layout .confirm-popup .popup-content .header {
        padding: 64px 0 56px 0; }
      .company-about-layout .confirm-popup .popup-content .body {
        margin-bottom: 32px; }
      .company-about-layout .confirm-popup .popup-content .form-fieldset {
        display: flex; }
        .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field {
          flex: 1;
          margin: 0 12px; }
          .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            background: none;
            border: 1px solid #4b4b4b;
            color: #4b4b4b;
            width: 100%;
            font-size: 16px; }
            .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button .text,
            .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
              display: inline-block;
              vertical-align: top; }
            .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
              margin-right: 0; }
            .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button {
    font-weight: normal; } }
            .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
              background: #f2f5fb; }
          .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            width: 100%;
            font-size: 16px; }
            .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
            .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
              display: inline-block;
              vertical-align: top; }
            .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
              margin-right: 0; }
            .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
    .company-about-layout .confirm-popup.show .popup-container {
      animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

@media screen and (max-width: 450px) {
  .company-about-layout .confirm-popup .popup-content {
    padding-right: 32px;
    padding-left: 32px; }
    .company-about-layout .confirm-popup .popup-content .header {
      padding: 32px 0 28px 0; }
    .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-button button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .company-about-layout .confirm-popup .popup-content .form-fieldset .form-field.field-type-submit button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; } }
  .company-about-layout .confirm-delete-popup {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background: rgba(255, 255, 255, 0.7);
    animation: popup-background-fade-in 0.8s ease; }
    .company-about-layout .confirm-delete-popup .popup-container {
      position: relative;
      display: none;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 20;
      display: block;
      width: 550px;
      margin: 0 auto;
      padding-bottom: 58px;
      top: 10%;
      max-height: 75%;
      overflow: auto;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      border-radius: 8px;
      /* Scrollbar styles */ }
      .company-about-layout .confirm-delete-popup .popup-container.show {
        display: block; }
      .company-about-layout .confirm-delete-popup .popup-container .close-button {
        width: 19px;
        height: 19px;
        background: url(images/icon-close.png) no-repeat center center;
        display: inline-block;
        background-size: contain;
        position: absolute;
        top: 25px;
        right: 25px; }

@media screen and (max-width: 500px) {
  .company-about-layout .confirm-delete-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .company-about-layout .confirm-delete-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .company-about-layout .confirm-delete-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
      .company-about-layout .confirm-delete-popup .popup-container::-webkit-scrollbar {
        width: 12px;
        height: 12px; }
      .company-about-layout .confirm-delete-popup .popup-container::-webkit-scrollbar-track {
        background: #f5f5f5;
        border-radius: 10px; }
      .company-about-layout .confirm-delete-popup .popup-container::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #ccc; }
      .company-about-layout .confirm-delete-popup .popup-container .popup-content form .form-control .datepicker-popup {
        position: fixed;
        top: 50%;
        left: 50%;
        margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .company-about-layout .confirm-delete-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
      .company-about-layout .confirm-delete-popup .popup-container .popup-content .popup-actions {
        width: 340px;
        margin: 0 auto; }
        .company-about-layout .confirm-delete-popup .popup-container .popup-content .popup-actions button {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b; }
          .company-about-layout .confirm-delete-popup .popup-container .popup-content .popup-actions button:hover {
            color: black; }
    .company-about-layout .confirm-delete-popup .header {
      padding: 32px 24px;
      font-size: 22px; }
    .company-about-layout .confirm-delete-popup .body {
      padding: 0 24px 32px 24px; }
    .company-about-layout .confirm-delete-popup.show {
      display: block; }

@media screen and (max-width: 600px) {
  .company-about-layout .confirm-delete-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .company-about-layout .confirm-delete-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .company-about-layout .confirm-delete-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
    .company-about-layout .confirm-delete-popup .popup-container {
      text-align: center;
      padding-bottom: 0; }
    .company-about-layout .confirm-delete-popup .popup-content {
      padding: 0 64px 52px 64px; }
      .company-about-layout .confirm-delete-popup .popup-content .header {
        padding: 64px 0 56px 0; }
      .company-about-layout .confirm-delete-popup .popup-content .body {
        margin-bottom: 32px; }
      .company-about-layout .confirm-delete-popup .popup-content .form-fieldset {
        display: flex; }
        .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field {
          flex: 1;
          margin: 0 12px; }
          .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            background: none;
            border: 1px solid #4b4b4b;
            color: #4b4b4b;
            width: 100%;
            font-size: 16px; }
            .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .text,
            .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
              display: inline-block;
              vertical-align: top; }
            .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button .icon {
              margin-right: 0; }
            .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
    font-weight: normal; } }
            .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button:hover {
              background: #f2f5fb; }
          .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            width: 100%;
            font-size: 16px; }
            .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
            .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
              display: inline-block;
              vertical-align: top; }
            .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
              margin-right: 0; }
            .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
    .company-about-layout .confirm-delete-popup.show .popup-container {
      animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

@media screen and (max-width: 450px) {
  .company-about-layout .confirm-delete-popup .popup-content {
    padding-right: 32px;
    padding-left: 32px; }
    .company-about-layout .confirm-delete-popup .popup-content .header {
      padding: 32px 0 28px 0; }
    .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-button button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; }
    .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
      height: 40px;
      line-height: 38px;
      font-size: 14px;
      font-weight: normal;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border-radius: 20px; } }
    .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      background: #f35e84;
      width: 100%;
      font-size: 16px; }
      .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .text,
      .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
        display: inline-block;
        vertical-align: top; }
      .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button .icon {
        margin-right: 0; }
      .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button {
    font-weight: normal; } }
      .company-about-layout .confirm-delete-popup .popup-content .form-fieldset .form-field.field-type-submit button:hover {
        background: #e64871; }

/* CABINET LICENSE */
.cabinet-license-layout .layout-title {
  margin-bottom: 24px; }
  .cabinet-license-layout .layout-title p {
    font-size: 22px;
    font-weight: 500; }

.cabinet-license-layout .layout-body {
  display: block;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: box-shadow 0.1s ease;
  -moz-transition: box-shadow 0.1s ease;
  -ms-transition: box-shadow 0.1s ease;
  -o-transition: box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease;
  padding: 42px 50px;
  margin-bottom: 40px; }

@media screen and (max-width: 450px) {
  .cabinet-license-layout .layout-body {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }

.cabinet-license-layout .license-tariffs-list {
  display: flex; }
  .cabinet-license-layout .license-tariffs-list .tariff-item {
    display: block;
    background: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: box-shadow 0.1s ease;
    -moz-transition: box-shadow 0.1s ease;
    -ms-transition: box-shadow 0.1s ease;
    -o-transition: box-shadow 0.1s ease;
    transition: box-shadow 0.1s ease;
    flex: 1;
    margin: 0 15px;
    padding: 40px 20px;
    text-align: center;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 450px) {
  .cabinet-license-layout .license-tariffs-list .tariff-item {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
    .cabinet-license-layout .license-tariffs-list .tariff-item .caption {
      font-size: 22px;
      margin-bottom: 18px; }
    .cabinet-license-layout .license-tariffs-list .tariff-item .price {
      font-size: 0;
      position: relative;
      display: inline-block;
      margin-bottom: 26px; }
      .cabinet-license-layout .license-tariffs-list .tariff-item .price .int,
      .cabinet-license-layout .license-tariffs-list .tariff-item .price .delim {
        font-size: 38px; }
      .cabinet-license-layout .license-tariffs-list .tariff-item .price .float,
      .cabinet-license-layout .license-tariffs-list .tariff-item .price .unit {
        font-size: 22px; }
      .cabinet-license-layout .license-tariffs-list .tariff-item .price .unit {
        margin-left: 8px; }
      .cabinet-license-layout .license-tariffs-list .tariff-item .price .unit-ruble:after {
        content: "\20bd"; }
      .cabinet-license-layout .license-tariffs-list .tariff-item .price.income, .cabinet-license-layout .license-tariffs-list .tariff-item .price.transfer-income {
        color: #4dbd7d; }
        .cabinet-license-layout .license-tariffs-list .tariff-item .price.income .int,
        .cabinet-license-layout .license-tariffs-list .tariff-item .price.income .delim,
        .cabinet-license-layout .license-tariffs-list .tariff-item .price.income .float,
        .cabinet-license-layout .license-tariffs-list .tariff-item .price.income .unit, .cabinet-license-layout .license-tariffs-list .tariff-item .price.transfer-income .int,
        .cabinet-license-layout .license-tariffs-list .tariff-item .price.transfer-income .delim,
        .cabinet-license-layout .license-tariffs-list .tariff-item .price.transfer-income .float,
        .cabinet-license-layout .license-tariffs-list .tariff-item .price.transfer-income .unit {
          color: #4dbd7d; }
    .cabinet-license-layout .license-tariffs-list .tariff-item .body {
      font-size: 16px; }
    .cabinet-license-layout .license-tariffs-list .tariff-item .button {
      display: inline-block;
      height: 50px;
      line-height: 48px;
      padding: 0 20px;
      text-align: center;
      vertical-align: top;
      background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
      border: 0;
      color: #fff;
      font-size: 17px;
      font-weight: 500;
      box-sizing: border-box;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      border-radius: 25px;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
      -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
      -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
      -o-transition: background 0.1s ease, box-shadow 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease;
      margin-top: 68px;
      width: 200px;
      font-size: 14px; }
      .cabinet-license-layout .license-tariffs-list .tariff-item .button .text,
      .cabinet-license-layout .license-tariffs-list .tariff-item .button .icon {
        display: inline-block;
        vertical-align: top; }
      .cabinet-license-layout .license-tariffs-list .tariff-item .button .icon {
        margin-right: 0; }
      .cabinet-license-layout .license-tariffs-list .tariff-item .button.button-xs {
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: normal;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        -ms-border-radius: 15px;
        -o-border-radius: 15px;
        border-radius: 15px; }
      .cabinet-license-layout .license-tariffs-list .tariff-item .button.button-s {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
      .cabinet-license-layout .license-tariffs-list .tariff-item .button.button-l {
        height: 60px;
        line-height: 58px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px; }
      .cabinet-license-layout .license-tariffs-list .tariff-item .button:hover {
        background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .cabinet-license-layout .license-tariffs-list .tariff-item .button {
    font-weight: normal; } }

.cabinet-license-layout .orders-list {
  padding: 0; }
  .cabinet-license-layout .orders-list .list-item {
    padding: 26px 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .cabinet-license-layout .orders-list .list-item .list-item-container {
      position: relative;
      min-height: 60px; }
    .cabinet-license-layout .orders-list .list-item .li-title {
      margin-bottom: 12px; }
      .cabinet-license-layout .orders-list .list-item .li-title p {
        font-size: 26px; }
    .cabinet-license-layout .orders-list .list-item .li-field.status {
      display: inline-block;
      padding: 0 7px;
      background: #b9e398;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      border-radius: 3px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.1); }
    .cabinet-license-layout .orders-list .list-item .li-field.price {
      font-size: 0; }
      .cabinet-license-layout .orders-list .list-item .li-field.price .int,
      .cabinet-license-layout .orders-list .list-item .li-field.price .delim {
        font-size: 22px; }
      .cabinet-license-layout .orders-list .list-item .li-field.price .float,
      .cabinet-license-layout .orders-list .list-item .li-field.price .unit {
        font-size: 14px; }
      .cabinet-license-layout .orders-list .list-item .li-field.price .unit {
        margin-left: 8px; }
      .cabinet-license-layout .orders-list .list-item .li-field.price .unit-ruble:after {
        content: "\20bd"; }
      .cabinet-license-layout .orders-list .list-item .li-field.price.income, .cabinet-license-layout .orders-list .list-item .li-field.price.transfer-income {
        color: #4dbd7d; }
        .cabinet-license-layout .orders-list .list-item .li-field.price.income .int,
        .cabinet-license-layout .orders-list .list-item .li-field.price.income .delim,
        .cabinet-license-layout .orders-list .list-item .li-field.price.income .float,
        .cabinet-license-layout .orders-list .list-item .li-field.price.income .unit, .cabinet-license-layout .orders-list .list-item .li-field.price.transfer-income .int,
        .cabinet-license-layout .orders-list .list-item .li-field.price.transfer-income .delim,
        .cabinet-license-layout .orders-list .list-item .li-field.price.transfer-income .float,
        .cabinet-license-layout .orders-list .list-item .li-field.price.transfer-income .unit {
          color: #4dbd7d; }
    .cabinet-license-layout .orders-list .list-item.status-new .li-field.status {
      background: #bed6f9; }
    .cabinet-license-layout .orders-list .list-item.status-success .li-field.status {
      background: #b9e398; }
    .cabinet-license-layout .orders-list .list-item.status-error .li-field.status, .cabinet-license-layout .orders-list .list-item.status-expired .li-field.status {
      background: #fbadad; }

/* FLOAT NOTIFICATION */
.notification-float-container {
  display: block;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: box-shadow 0.1s ease;
  -moz-transition: box-shadow 0.1s ease;
  -ms-transition: box-shadow 0.1s ease;
  -o-transition: box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease;
  position: fixed;
  top: 100px;
  left: 50%;
  width: 280px;
  padding: 34px 20px 40px 20px;
  margin-left: -160px;
  text-align: center;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 450px) {
  .notification-float-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
  .notification-float-container .icon {
    display: inline-block;
    width: 150px;
    height: 44px;
    margin-bottom: 10px;
    background: url(images/logo.png) no-repeat center center;
    background-size: contain; }
  .notification-float-container .message,
  .notification-float-container .error {
    margin-bottom: 20px;
    font-size: 17px; }
  .notification-float-container .message {
    color: #4dbd7d; }
  .notification-float-container .error {
    color: #f32424; }
  .notification-float-container .link {
    background: none;
    border: none;
    cursor: pointer;
    color: #4b4b4b; }
    .notification-float-container .link:hover {
      color: black; }

/* LICENSE EXPIRED POPUP */
.license-expired-popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(255, 255, 255, 0.7);
  animation: popup-background-fade-in 0.8s ease; }
  .license-expired-popup .popup-container {
    position: relative;
    display: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    display: block;
    width: 550px;
    margin: 0 auto;
    padding-bottom: 58px;
    top: 10%;
    max-height: 75%;
    overflow: auto;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    /* Scrollbar styles */ }
    .license-expired-popup .popup-container.show {
      display: block; }
    .license-expired-popup .popup-container .close-button {
      width: 19px;
      height: 19px;
      background: url(images/icon-close.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: absolute;
      top: 25px;
      right: 25px; }

@media screen and (max-width: 500px) {
  .license-expired-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .license-expired-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .license-expired-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
    .license-expired-popup .popup-container::-webkit-scrollbar {
      width: 12px;
      height: 12px; }
    .license-expired-popup .popup-container::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 10px; }
    .license-expired-popup .popup-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #ccc; }
    .license-expired-popup .popup-container .popup-content form .form-control .datepicker-popup {
      position: fixed;
      top: 50%;
      left: 50%;
      margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .license-expired-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
    .license-expired-popup .popup-container .popup-content .popup-actions {
      width: 340px;
      margin: 0 auto; }
      .license-expired-popup .popup-container .popup-content .popup-actions button {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .license-expired-popup .popup-container .popup-content .popup-actions button:hover {
          color: black; }
  .license-expired-popup .header {
    padding: 32px 24px;
    font-size: 22px; }
  .license-expired-popup .body {
    padding: 0 24px 32px 24px; }
  .license-expired-popup.show {
    display: block; }

@media screen and (max-width: 600px) {
  .license-expired-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .license-expired-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .license-expired-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
  .license-expired-popup .popup-content {
    padding: 54px 64px 0 64px; }
    .license-expired-popup .popup-content .message {
      color: #f32424;
      margin-bottom: 20px;
      font-size: 17px; }
    .license-expired-popup .popup-content .body {
      padding: 0; }
    .license-expired-popup .popup-content .actions {
      margin-top: 40px; }
      .license-expired-popup .popup-content .actions .action {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        padding: 0 40px;
        font-size: 16px; }
        .license-expired-popup .popup-content .actions .action .text,
        .license-expired-popup .popup-content .actions .action .icon {
          display: inline-block;
          vertical-align: top; }
        .license-expired-popup .popup-content .actions .action .icon {
          margin-right: 0; }
        .license-expired-popup .popup-content .actions .action.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .license-expired-popup .popup-content .actions .action.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .license-expired-popup .popup-content .actions .action.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .license-expired-popup .popup-content .actions .action:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .license-expired-popup .popup-content .actions .action {
    font-weight: normal; } }
  .license-expired-popup.show .popup-container {
    animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

/* LICENSE AGREEMENT POPUP */
#dogma-application .license-agreement-popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(255, 255, 255, 0.7);
  animation: popup-background-fade-in 0.8s ease; }
  #dogma-application .license-agreement-popup .popup-container {
    position: relative;
    display: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    display: block;
    width: 550px;
    margin: 0 auto;
    padding-bottom: 58px;
    top: 10%;
    max-height: 75%;
    overflow: auto;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    /* Scrollbar styles */ }
    #dogma-application .license-agreement-popup .popup-container.show {
      display: block; }
    #dogma-application .license-agreement-popup .popup-container .close-button {
      width: 19px;
      height: 19px;
      background: url(images/icon-close.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: absolute;
      top: 25px;
      right: 25px; }

@media screen and (max-width: 500px) {
  #dogma-application .license-agreement-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  #dogma-application .license-agreement-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    #dogma-application .license-agreement-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
    #dogma-application .license-agreement-popup .popup-container::-webkit-scrollbar {
      width: 12px;
      height: 12px; }
    #dogma-application .license-agreement-popup .popup-container::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 10px; }
    #dogma-application .license-agreement-popup .popup-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #ccc; }
    #dogma-application .license-agreement-popup .popup-container .popup-content form .form-control .datepicker-popup {
      position: fixed;
      top: 50%;
      left: 50%;
      margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  #dogma-application .license-agreement-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
    #dogma-application .license-agreement-popup .popup-container .popup-content .popup-actions {
      width: 340px;
      margin: 0 auto; }
      #dogma-application .license-agreement-popup .popup-container .popup-content .popup-actions button {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        #dogma-application .license-agreement-popup .popup-container .popup-content .popup-actions button:hover {
          color: black; }
  #dogma-application .license-agreement-popup .header {
    padding: 32px 24px;
    font-size: 22px; }
  #dogma-application .license-agreement-popup .body {
    padding: 0 24px 32px 24px; }
  #dogma-application .license-agreement-popup.show {
    display: block; }

@media screen and (max-width: 600px) {
  #dogma-application .license-agreement-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    #dogma-application .license-agreement-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  #dogma-application .license-agreement-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
  #dogma-application .license-agreement-popup .popup-container {
    width: auto;
    max-width: 1220px; }
  #dogma-application .license-agreement-popup .popup-content {
    padding: 54px 64px 0 64px; }
  #dogma-application .license-agreement-popup .header {
    padding: 0 0 30px 0; }
  #dogma-application .license-agreement-popup.show .popup-container {
    animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

/* SOCIAL LINKS */
.social-links {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 1; }
  .social-links a {
    display: inline-block;
    margin-left: 20px;
    vertical-align: middle; }
    .social-links a.telegram {
      width: 22px;
      height: 22px;
      background: url(images/icon-telegram.svg) no-repeat center center;
      display: inline-block;
      background-size: contain;
      width: 32px;
      height: 32px; }
    .social-links a.whatsapp {
      width: 22px;
      height: 22px;
      background: url(images/icon-whatsapp.svg) no-repeat center center;
      display: inline-block;
      background-size: contain; }

/* BANK INTEGRATION POPUP */
.payment-account-add-bank-integration-popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(255, 255, 255, 0.7);
  animation: popup-background-fade-in 0.8s ease; }
  .payment-account-add-bank-integration-popup .popup-container {
    position: relative;
    display: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 20;
    display: block;
    width: 550px;
    margin: 0 auto;
    padding-bottom: 58px;
    top: 10%;
    max-height: 75%;
    overflow: auto;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    /* Scrollbar styles */ }
    .payment-account-add-bank-integration-popup .popup-container.show {
      display: block; }
    .payment-account-add-bank-integration-popup .popup-container .close-button {
      width: 19px;
      height: 19px;
      background: url(images/icon-close.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      position: absolute;
      top: 25px;
      right: 25px; }

@media screen and (max-width: 500px) {
  .payment-account-add-bank-integration-popup .popup-container {
    right: 15px; } }

@media screen and (max-width: 450px) {
  .payment-account-add-bank-integration-popup .popup-container {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0 !important; }
    .payment-account-add-bank-integration-popup .popup-container .close-button {
      width: 25px;
      height: 15px;
      background: url(images/arrow-back.png) no-repeat center center;
      display: inline-block;
      background-size: contain;
      top: 32px;
      left: 23px;
      right: auto; } }
    .payment-account-add-bank-integration-popup .popup-container::-webkit-scrollbar {
      width: 12px;
      height: 12px; }
    .payment-account-add-bank-integration-popup .popup-container::-webkit-scrollbar-track {
      background: #f5f5f5;
      border-radius: 10px; }
    .payment-account-add-bank-integration-popup .popup-container::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #ccc; }
    .payment-account-add-bank-integration-popup .popup-container .popup-content form .form-control .datepicker-popup {
      position: fixed;
      top: 50%;
      left: 50%;
      margin: -195px 0 0 -195px; }

@media screen and (max-width: 500px) {
  .payment-account-add-bank-integration-popup .popup-container .popup-content form .form-control .datepicker-popup {
    width: 92%;
    left: 3%;
    margin-left: 0; } }
    .payment-account-add-bank-integration-popup .popup-container .popup-content .popup-actions {
      width: 340px;
      margin: 0 auto; }
      .payment-account-add-bank-integration-popup .popup-container .popup-content .popup-actions button {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .payment-account-add-bank-integration-popup .popup-container .popup-content .popup-actions button:hover {
          color: black; }
  .payment-account-add-bank-integration-popup .header {
    padding: 32px 24px;
    font-size: 22px; }
  .payment-account-add-bank-integration-popup .body {
    padding: 0 24px 32px 24px; }
  .payment-account-add-bank-integration-popup.show {
    display: block; }

@media screen and (max-width: 600px) {
  .payment-account-add-bank-integration-popup .popup-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%; }
    .payment-account-add-bank-integration-popup .popup-container .header {
      padding: 19px 24px; } }

@media screen and (max-width: 450px) {
  .payment-account-add-bank-integration-popup .popup-container .header {
    padding-top: 27px;
    padding-left: 65px; } }
  .payment-account-add-bank-integration-popup .popup-content form {
    width: 340px;
    margin: 0 auto; }
    .payment-account-add-bank-integration-popup .popup-content form .form-field {
      margin-bottom: 38px;
      width: 100%; }
      .payment-account-add-bank-integration-popup .popup-content form .form-field .form-label {
        display: block;
        font-size: 17px;
        color: #8494a9; }
      .payment-account-add-bank-integration-popup .popup-content form .form-field.field-type-colorpicker .form-label {
        margin-bottom: 8px; }
      .payment-account-add-bank-integration-popup .popup-content form .form-field.field-type-files .form-label {
        display: none; }
      .payment-account-add-bank-integration-popup .popup-content form .form-field .custom-checkput {
        position: relative;
        display: inline-block;
        width: auto;
        height: auto; }
        .payment-account-add-bank-integration-popup .popup-content form .form-field .custom-checkput input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .payment-account-add-bank-integration-popup .popup-content form .form-field .custom-checkput .checkput-icon {
          position: relative;
          display: inline-block;
          width: 18px;
          height: 18px;
          border: 2px solid #8494a9;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          border-radius: 3px;
          cursor: pointer; }
        .payment-account-add-bank-integration-popup .popup-content form .form-field .custom-checkput.checked .checkput-icon {
          position: relative;
          display: inline-block;
          width: 22px;
          height: 22px;
          background: #81bc52;
          border: 0;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          -ms-border-radius: 3px;
          -o-border-radius: 3px;
          border-radius: 3px; }
          .payment-account-add-bank-integration-popup .popup-content form .form-field .custom-checkput.checked .checkput-icon::after {
            content: "";
            width: 16px;
            height: 12px;
            background: url(images/icon-check-white.png) no-repeat center center;
            display: inline-block;
            background-size: contain;
            position: absolute;
            top: 7px;
            left: 4px;
            width: 13px;
            height: 9px; }
      .payment-account-add-bank-integration-popup .popup-content form .form-field.field-type-checkbox .custom-checkput, .payment-account-add-bank-integration-popup .popup-content form .form-field.field-type-agreement .custom-checkput, .payment-account-add-bank-integration-popup .popup-content form .form-field.field-type-license-agreement .custom-checkput {
        float: left; }
      .payment-account-add-bank-integration-popup .popup-content form .form-field.field-type-checkbox .form-label, .payment-account-add-bank-integration-popup .popup-content form .form-field.field-type-agreement .form-label, .payment-account-add-bank-integration-popup .popup-content form .form-field.field-type-license-agreement .form-label {
        margin-left: 34px;
        cursor: pointer; }
      .payment-account-add-bank-integration-popup .popup-content form .form-field.field-type-agreement button, .payment-account-add-bank-integration-popup .popup-content form .form-field.field-type-license-agreement button {
        display: block;
        margin-left: 34px;
        height: auto;
        line-height: 22px;
        font-size: 17px;
        color: #8494a9;
        text-decoration: underline;
        background: none; }
      .payment-account-add-bank-integration-popup .popup-content form .form-field .form-field-password-generator button {
        color: #2f80ed;
        cursor: pointer;
        background: transparent; }
      .payment-account-add-bank-integration-popup .popup-content form .form-field .form-field-password-check {
        line-height: 16px;
        font-size: 12px;
        position: relative;
        margin-top: -2px;
        z-index: 1; }
        .payment-account-add-bank-integration-popup .popup-content form .form-field .form-field-password-check.active-red {
          width: 30%;
          border-top: 2px solid #F35E84;
          margin-bottom: 11px; }
        .payment-account-add-bank-integration-popup .popup-content form .form-field .form-field-password-check.active-green {
          width: 100%;
          border-top: 2px solid #5A9C76;
          margin-bottom: 11px; }
        .payment-account-add-bank-integration-popup .popup-content form .form-field .form-field-password-check.active-yellow {
          width: 70%;
          border-top: 2px solid #C99300;
          margin-bottom: 11px; }
        .payment-account-add-bank-integration-popup .popup-content form .form-field .form-field-password-check .password-check-hint-green {
          color: #5A9C76; }
        .payment-account-add-bank-integration-popup .popup-content form .form-field .form-field-password-check .password-check-hint-yellow {
          color: #C99300; }
        .payment-account-add-bank-integration-popup .popup-content form .form-field .form-field-password-check .password-check-hint-red {
          color: #F35E84; }

@media screen and (max-width: 500px) {
  .payment-account-add-bank-integration-popup .popup-content form .form-field {
    margin-bottom: 28px; }
    .payment-account-add-bank-integration-popup .popup-content form .form-field .form-label {
      font-size: 14px;
      line-height: 14px; } }
      .payment-account-add-bank-integration-popup .popup-content form .form-field .form-button {
        display: inline-block;
        height: 50px;
        line-height: 48px;
        padding: 0 20px;
        text-align: center;
        vertical-align: top;
        background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
        border: 0;
        color: #fff;
        font-size: 17px;
        font-weight: 500;
        box-sizing: border-box;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
        border-radius: 25px;
        -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
        -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
        -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
        -o-transition: background 0.1s ease, box-shadow 0.1s ease;
        transition: background 0.1s ease, box-shadow 0.1s ease;
        width: 100%; }
        .payment-account-add-bank-integration-popup .popup-content form .form-field .form-button .text,
        .payment-account-add-bank-integration-popup .popup-content form .form-field .form-button .icon {
          display: inline-block;
          vertical-align: top; }
        .payment-account-add-bank-integration-popup .popup-content form .form-field .form-button .icon {
          margin-right: 0; }
        .payment-account-add-bank-integration-popup .popup-content form .form-field .form-button.button-xs {
          height: 32px;
          line-height: 32px;
          font-size: 12px;
          font-weight: normal;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .payment-account-add-bank-integration-popup .popup-content form .form-field .form-button.button-s {
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px; }
        .payment-account-add-bank-integration-popup .popup-content form .form-field .form-button.button-l {
          height: 60px;
          line-height: 58px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          border-radius: 30px; }
        .payment-account-add-bank-integration-popup .popup-content form .form-field .form-button:hover {
          background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .payment-account-add-bank-integration-popup .popup-content form .form-field .form-button {
    font-weight: normal; } }
      .payment-account-add-bank-integration-popup .popup-content form .form-field.field-error .form-label {
        color: #f32424; }
      .payment-account-add-bank-integration-popup .popup-content form .form-field.field-error .form-control .form-input,
      .payment-account-add-bank-integration-popup .popup-content form .form-field.field-error .form-control textarea,
      .payment-account-add-bank-integration-popup .popup-content form .form-field.field-error .form-control select {
        border-color: #f32424; }
      .payment-account-add-bank-integration-popup .popup-content form .form-field.field-error .form-field-error p {
        color: #f32424;
        white-space: pre-wrap; }
    .payment-account-add-bank-integration-popup .popup-content form .form-control {
      position: relative; }
      .payment-account-add-bank-integration-popup .popup-content form .form-control .form-input,
      .payment-account-add-bank-integration-popup .popup-content form .form-control textarea,
      .payment-account-add-bank-integration-popup .popup-content form .form-control select {
        width: 100%;
        height: 34px;
        line-height: 34px;
        padding: 0;
        background: none;
        border: 0;
        border-bottom: 1px solid #8d76f0;
        font-size: 17px;
        outline: none; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .form-input::-webkit-input-placeholder,
        .payment-account-add-bank-integration-popup .popup-content form .form-control textarea::-webkit-input-placeholder,
        .payment-account-add-bank-integration-popup .popup-content form .form-control select::-webkit-input-placeholder {
          /* WebKit, Blink, Edge */
          color: rgba(132, 148, 169, 0.5); }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .form-input:-moz-placeholder,
        .payment-account-add-bank-integration-popup .popup-content form .form-control textarea:-moz-placeholder,
        .payment-account-add-bank-integration-popup .popup-content form .form-control select:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .form-input::-moz-placeholder,
        .payment-account-add-bank-integration-popup .popup-content form .form-control textarea::-moz-placeholder,
        .payment-account-add-bank-integration-popup .popup-content form .form-control select::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: rgba(132, 148, 169, 0.5);
          opacity: 1; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .form-input:-ms-input-placeholder,
        .payment-account-add-bank-integration-popup .popup-content form .form-control textarea:-ms-input-placeholder,
        .payment-account-add-bank-integration-popup .popup-content form .form-control select:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba(132, 148, 169, 0.5); }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .form-input::-ms-input-placeholder,
        .payment-account-add-bank-integration-popup .popup-content form .form-control textarea::-ms-input-placeholder,
        .payment-account-add-bank-integration-popup .popup-content form .form-control select::-ms-input-placeholder {
          /* Microsoft Edge */
          color: rgba(132, 148, 169, 0.5); }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .form-input::placeholder,
        .payment-account-add-bank-integration-popup .popup-content form .form-control textarea::placeholder,
        .payment-account-add-bank-integration-popup .popup-content form .form-control select::placeholder {
          /* Most modern browsers support this now. */
          color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .payment-account-add-bank-integration-popup .popup-content form .form-control .form-input,
  .payment-account-add-bank-integration-popup .popup-content form .form-control textarea,
  .payment-account-add-bank-integration-popup .popup-content form .form-control select {
    font-size: 16px; } }
      .payment-account-add-bank-integration-popup .popup-content form .form-control textarea {
        resize: none;
        padding: 5px 0;
        line-height: 22px; }
      .payment-account-add-bank-integration-popup .popup-content form .form-control select {
        cursor: pointer; }
      .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select {
        position: relative; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select select {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-button {
          position: relative;
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          width: 100%;
          height: auto;
          padding: 15px 30px;
          line-height: 20px;
          background: none;
          color: #4b4b4b;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          border-radius: 8px;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-button .text,
          .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-button .icon {
            display: inline-block;
            vertical-align: top; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-button .icon {
            margin-right: 0; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-button.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-button.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-button.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-button:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-button {
    font-weight: normal; } }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-button .post-icon {
            position: relative;
            top: -1px;
            left: 8px;
            display: inline-block;
            width: 0; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-button .post-icon:after {
              content: "";
              width: 14px;
              height: 7px;
              background: url(images/arrow-bottom.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-button:hover {
            background: none;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 1;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 15px 50px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-button .text,
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-button .icon {
              display: inline-block;
              vertical-align: top; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-button .icon {
              margin-right: 0; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-button {
    font-weight: normal; } }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-button.active .pre-icon {
              position: relative;
              left: -26px;
              display: inline-block;
              width: 0; }
              .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-button.active .pre-icon:after {
                content: "";
                width: 16px;
                height: 12px;
                background: url(images/icon-check.png) no-repeat center center;
                display: inline-block;
                background-size: contain; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-button:after {
              content: "";
              display: block;
              position: absolute;
              top: 100%;
              left: 0;
              right: 0;
              height: 1px;
              background: rgba(0, 0, 0, 0.1);
              opacity: 0.5;
              filter: alpha(opacity=50);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=50;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-button:hover {
              background: #f2f5fb; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-button:first-child:after {
              left: 23px;
              right: 23px;
              opacity: 1;
              filter: alpha(opacity=100);
              filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
              -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-button:last-child {
              margin-bottom: 40px; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-action {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 15px 23px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-action .text,
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-action .icon {
              display: inline-block;
              vertical-align: top; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-action .icon {
              margin-right: 0; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-action.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-action:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-action {
    font-weight: normal; } }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list .select-list-action:hover {
              background: #f2f5fb; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select .select-list.show {
            display: block; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .styled-select.show-list .select-button {
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
      .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select {
        position: relative; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select select {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field {
          position: relative;
          border-bottom: 1px solid #8d76f0; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field input {
            width: 100%;
            height: 34px;
            line-height: 34px;
            padding: 0;
            background: none;
            border: 0;
            border-bottom: 1px solid #8d76f0;
            font-size: 17px;
            outline: none;
            width: 90%;
            border-bottom: 0; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field input::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: rgba(132, 148, 169, 0.5); }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: rgba(132, 148, 169, 0.5);
              opacity: 1; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: rgba(132, 148, 169, 0.5); }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: rgba(132, 148, 169, 0.5); }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field input::placeholder {
              /* Most modern browsers support this now. */
              color: rgba(132, 148, 169, 0.5); }

@media screen and (max-width: 500px) {
  .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field input {
    font-size: 16px; } }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field input::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: #4b4b4b; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field input:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: #4b4b4b;
              opacity: 1; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field input::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: #4b4b4b;
              opacity: 1; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field input:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #4b4b4b; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field input::-ms-input-placeholder {
              /* Microsoft Edge */
              color: #4b4b4b; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field input::placeholder {
              /* Most modern browsers support this now. */
              color: #4b4b4b; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon {
            position: absolute;
            top: 7px;
            right: 21px;
            width: 0; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon:after {
              content: "";
              width: 14px;
              height: 7px;
              background: url(images/arrow-bottom.png) no-repeat center center;
              display: inline-block;
              background-size: contain; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list-container {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          display: none;
          position: absolute;
          top: -17px;
          left: -17px;
          right: -17px;
          padding-top: 60px;
          padding-bottom: 17px;
          z-index: 1;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

@media screen and (max-width: 450px) {
  .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list-container {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list {
          position: relative;
          max-height: 200px;
          overflow: auto; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-button {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 10px 17px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-button .text,
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-button .icon {
              display: inline-block;
              vertical-align: top; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-button .icon {
              margin-right: 0; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-button.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-button:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-button {
    font-weight: normal; } }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-button.active {
              background: #f2f5fb; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-button.selected {
              background: #f2f5fb; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-button:hover {
              background: #f2f5fb; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-action {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            padding: 0 20px;
            text-align: center;
            vertical-align: top;
            background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
            border: 0;
            color: #fff;
            font-size: 17px;
            font-weight: 500;
            box-sizing: border-box;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            border-radius: 25px;
            -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
            -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
            -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
            -o-transition: background 0.1s ease, box-shadow 0.1s ease;
            transition: background 0.1s ease, box-shadow 0.1s ease;
            position: relative;
            width: 100%;
            height: auto;
            padding: 10px 17px;
            line-height: 20px;
            background: none;
            color: #4b4b4b;
            font-weight: normal;
            text-align: left;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
            border-radius: 0; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-action .text,
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-action .icon {
              display: inline-block;
              vertical-align: top; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-action .icon {
              margin-right: 0; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-xs {
              height: 32px;
              line-height: 32px;
              font-size: 12px;
              font-weight: normal;
              -webkit-border-radius: 15px;
              -moz-border-radius: 15px;
              -ms-border-radius: 15px;
              -o-border-radius: 15px;
              border-radius: 15px; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-s {
              height: 40px;
              line-height: 38px;
              font-size: 14px;
              font-weight: normal;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              border-radius: 20px; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-action.button-l {
              height: 60px;
              line-height: 58px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
              border-radius: 30px; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-action:hover {
              background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-action {
    font-weight: normal; } }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .select-list .select-list-action:hover {
              background: #f2f5fb; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select.show-list .select-list-container {
          display: block; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select.show-list .search-field {
          z-index: 1;
          border-bottom-color: rgba(0, 0, 0, 0.1); }
      .payment-account-add-bank-integration-popup .popup-content form .form-control .field-price-input-container {
        position: relative;
        width: 100%; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .field-price-input-container .unit-box {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          text-align: center;
          white-space: nowrap; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .field-price-input-container .unit-box span {
            display: inline-block; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .field-price-input-container .unit-box .unit-background-text {
            color: transparent;
            font-size: 38px;
            max-width: 95%;
            overflow: hidden; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .field-price-input-container .unit-box .unit-background-text-space {
            width: 24px; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .field-price-input-container input {
          position: relative;
          height: 50px;
          line-height: 50px;
          box-sizing: border-box;
          text-align: center;
          font-size: 38px; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .field-price-input-container .unit-ruble {
          line-height: 52px;
          font-size: 32px;
          color: #8494a9;
          opacity: 0.7;
          filter: alpha(opacity=70);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=70;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .field-price-input-container .unit-ruble:after {
            content: "\20bd"; }
      .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker {
        position: relative; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-button {
          display: block;
          width: 105px;
          height: 30px;
          background: none;
          -webkit-border-radius: 15px;
          -moz-border-radius: 15px;
          -ms-border-radius: 15px;
          -o-border-radius: 15px;
          border-radius: 15px; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette {
          display: block;
          background: #fff;
          -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          border-radius: 10px;
          -webkit-transition: box-shadow 0.1s ease;
          -moz-transition: box-shadow 0.1s ease;
          -ms-transition: box-shadow 0.1s ease;
          -o-transition: box-shadow 0.1s ease;
          transition: box-shadow 0.1s ease;
          position: absolute;
          left: 0;
          bottom: -200px;
          width: 504px;
          padding: 32px 20px;
          z-index: 4;
          -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

@media screen and (max-width: 450px) {
  .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -ms-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14); } }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette .palette-header {
            margin-bottom: 40px;
            margin-left: 10px;
            font-size: 22px; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-buttons {
            margin-bottom: 12px; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin: 0 12px 24px 12px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            border-radius: 50%; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button.active, .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette .palette-color-button:hover {
              margin: -10px 2px 14px 2px;
              width: 80px;
              height: 80px;
              border: 10px solid #f2f5fb; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette .palette-corner-close-button {
            position: absolute;
            top: 0;
            right: 0;
            width: 80px;
            height: 80px;
            vertical-align: middle;
            text-align: center;
            font-size: 38px;
            font-family: "times new roman", verdana;
            background: none; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons {
            text-align: right; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
              display: inline-block;
              height: 50px;
              line-height: 48px;
              padding: 0 20px;
              text-align: center;
              vertical-align: top;
              background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
              border: 0;
              color: #fff;
              font-size: 17px;
              font-weight: 500;
              box-sizing: border-box;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              border-radius: 25px;
              -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
              -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
              -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
              -o-transition: background 0.1s ease, box-shadow 0.1s ease;
              transition: background 0.1s ease, box-shadow 0.1s ease;
              background: none;
              border: 1px solid #4b4b4b;
              color: #4b4b4b;
              font-size: 16px;
              min-width: 191px; }
              .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .text,
              .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                display: inline-block;
                vertical-align: top; }
              .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button .icon {
                margin-right: 0; }
              .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-xs {
                height: 32px;
                line-height: 32px;
                font-size: 12px;
                font-weight: normal;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                -ms-border-radius: 15px;
                -o-border-radius: 15px;
                border-radius: 15px; }
              .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-s {
                height: 40px;
                line-height: 38px;
                font-size: 14px;
                font-weight: normal;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -ms-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px; }
              .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button.button-l {
                height: 60px;
                line-height: 58px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px; }
              .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button {
    font-weight: normal; } }
              .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette .palette-bottom-buttons button:hover {
                background: #f2f5fb; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .colorpicker .picker-palette-overlay {
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.7);
          z-index: 2; }
      .payment-account-add-bank-integration-popup .popup-content form .form-control .form-autocomplete-input {
        position: relative; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          max-height: 300px;
          background: #fff;
          -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -ms-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          -o-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
          z-index: 1;
          overflow: auto; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button {
            background: none;
            border: none;
            width: 100%;
            padding: 8px 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            text-align: left; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:hover {
              background: #f2f5fb; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .form-autocomplete-input .form-autocomplete-dropdown button:last-child {
              border-bottom: 0; }
      .payment-account-add-bank-integration-popup .popup-content form .form-control .field-text-list-container .text-list-item {
        position: relative;
        margin-bottom: 10px; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .field-text-list-container .text-list-item .remove-text-item {
          background: none;
          border: none;
          cursor: pointer;
          color: #4b4b4b;
          position: absolute;
          top: 0;
          right: 0;
          color: #f32424;
          font-size: 22px; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .field-text-list-container .text-list-item .remove-text-item:hover {
            color: black; }
      .payment-account-add-bank-integration-popup .popup-content form .form-control .field-text-list-container .add-text-item {
        background: none;
        border: none;
        cursor: pointer;
        color: #4b4b4b; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .field-text-list-container .add-text-item:hover {
          color: black; }
      .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-container .files-loader-input-container {
        margin-bottom: 20px; }
      .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-input-container {
        position: relative; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-input-container .input-label-area {
          display: inline-block;
          height: 50px;
          line-height: 48px;
          padding: 0 20px;
          text-align: center;
          vertical-align: top;
          background: linear-gradient(77.78deg, #6880fc 0.81%, #fc58cc 104.4%);
          border: 0;
          color: #fff;
          font-size: 17px;
          font-weight: 500;
          box-sizing: border-box;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          border-radius: 25px;
          -webkit-transition: background 0.1s ease, box-shadow 0.1s ease;
          -moz-transition: background 0.1s ease, box-shadow 0.1s ease;
          -ms-transition: background 0.1s ease, box-shadow 0.1s ease;
          -o-transition: background 0.1s ease, box-shadow 0.1s ease;
          transition: background 0.1s ease, box-shadow 0.1s ease;
          background: none;
          border: 1px solid #4b4b4b;
          color: #4b4b4b;
          height: 40px;
          line-height: 38px;
          font-size: 14px;
          font-weight: normal;
          -webkit-border-radius: 20px;
          -moz-border-radius: 20px;
          -ms-border-radius: 20px;
          -o-border-radius: 20px;
          border-radius: 20px;
          width: 100%;
          height: 60px;
          line-height: 60px;
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          -ms-border-radius: 8px;
          -o-border-radius: 8px;
          border-radius: 8px; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-input-container .input-label-area .text,
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-input-container .input-label-area .icon {
            display: inline-block;
            vertical-align: top; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-input-container .input-label-area .icon {
            margin-right: 0; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-xs {
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: normal;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            border-radius: 15px; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-s {
            height: 40px;
            line-height: 38px;
            font-size: 14px;
            font-weight: normal;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -ms-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-input-container .input-label-area.button-l {
            height: 60px;
            line-height: 58px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            border-radius: 30px; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-input-container .input-label-area:hover {
            background: linear-gradient(77.78deg, #5f76ea 0.81%, #ec51bf 104.4%); }

@media screen and (max-width: 450px) {
  .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-input-container .input-label-area {
    font-weight: normal; } }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-input-container .input-label-area:hover {
            background: #f2f5fb; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-input-container input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          opacity: 0;
          filter: alpha(opacity=0);
          filter: progid:DXImageTransform.Microsoft.Alpha Opacity=0;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-input-container:hover .input-label-area {
          background: #f2f5fb; }
      .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-list .files-list-item {
        display: flex;
        justify-content: space-between;
        text-align: left;
        margin-bottom: 10px; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.icon {
            width: 38px;
            flex-shrink: 0; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.caption {
            margin-right: auto;
            margin-left: 18px; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col.price {
            flex-shrink: 0;
            text-align: right; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-list .files-list-item .list-item-col .note {
            font-size: 12px;
            color: #8494a9; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-list .files-list-item .icon {
          display: inline-block;
          width: 38px;
          height: 38px;
          line-height: 37px;
          text-align: center;
          font-size: 22px;
          font-weight: 500;
          color: #fff;
          background: #bed6f9;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          border-radius: 50%;
          font-size: 12px;
          font-weight: normal; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.icon-sub-correction {
            line-height: 34.2px; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-1 {
            background: #bed6f9; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-2 {
            background: #b9e398; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-3 {
            background: #e4e597; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-4 {
            background: #e7c740; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-5 {
            background: #e74040; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-list .files-list-item .icon.color-6 {
            background: #cf91af; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-list .files-list-item .caption {
          height: 38px;
          line-height: 38px;
          margin-left: 12px; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-list .files-list-item .remove {
          flex-shrink: 0;
          margin-left: 0; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .files-loader-list .files-list-item .remove button {
            background: none;
            border: none;
            height: 38px;
            line-height: 38px;
            font-size: 22px;
            color: #f32424; }
      .payment-account-add-bank-integration-popup .popup-content form .form-control .select-container .note {
        position: relative;
        padding-left: 25px;
        padding-top: 6px;
        font-size: 14px;
        color: #8494a9;
        line-height: 14px; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .select-container .note:before {
          content: "i";
          position: absolute;
          top: 5px;
          left: 0;
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-right: 10px;
          line-height: 14px;
          border: 1px solid #8494a9;
          border-radius: 50%;
          text-align: center; }
      .payment-account-add-bank-integration-popup .popup-content form .form-control .input-phone {
        display: flex; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select {
          position: inherit; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-button {
            padding: 0;
            width: 80px;
            text-align: left;
            box-shadow: none;
            line-height: 34px;
            border-bottom: 1px solid #8d76f0;
            border-radius: 0; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-button .post-icon:after {
              width: 8px; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-list {
            top: 100%;
            width: 100%;
            max-height: 200px;
            margin-top: 10px;
            overflow: auto; }
            .payment-account-add-bank-integration-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select .select-list .select-list-button {
              text-align: left; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select select {
            width: 80px; }
          .payment-account-add-bank-integration-popup .popup-content form .form-control .input-phone .phone-country-code .styled-select.show-list .select-button {
            opacity: 1;
            filter: alpha(opacity=100);
            filter: progid:DXImageTransform.Microsoft.Alpha Opacity=100;
            -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$value*100)'; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .input-phone .phone-number {
          flex: 1; }
      .payment-account-add-bank-integration-popup .popup-content form .form-control .password-input {
        position: relative; }
        .payment-account-add-bank-integration-popup .popup-content form .form-control .password-input .eye-icon {
          font-size: 18px;
          background-color: transparent;
          color: #8494a9;
          position: absolute;
          right: 0; }

@media screen and (max-width: 500px) {
  .payment-account-add-bank-integration-popup .popup-content form .form-control .search-select .search-field .field-dropdown-icon {
    top: 3px; } }
      .payment-account-add-bank-integration-popup .popup-content form .form-control .datepicker-popup {
        position: absolute;
        top: 100%;
        left: 0; }
    .payment-account-add-bank-integration-popup .popup-content form .form-errors-list {
      margin-bottom: 24px; }
      .payment-account-add-bank-integration-popup .popup-content form .form-errors-list .error-item {
        margin-bottom: 8px;
        color: #f32424; }
        .payment-account-add-bank-integration-popup .popup-content form .form-errors-list .error-item:last-child {
          margin-bottom: 0; }
    .payment-account-add-bank-integration-popup .popup-content form .form-messages-list {
      margin-bottom: 24px;
      text-align: center; }
      .payment-account-add-bank-integration-popup .popup-content form .form-messages-list .message-item {
        margin-bottom: 8px;
        color: #4dbd7d;
        font-size: 17px;
        line-height: 22px; }
        .payment-account-add-bank-integration-popup .popup-content form .form-messages-list .message-item:last-child {
          margin-bottom: 0; }
    .payment-account-add-bank-integration-popup .popup-content form .form-field .form-label {
      margin-bottom: 15px; }
  .payment-account-add-bank-integration-popup.show .popup-container {
    animation: popup-fade-in-margin 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1; }

@media screen and (max-width: 1660px) {
  .company-layout .company-page-right-col,
  .transactions-layout .company-page-right-col {
    margin-left: 24px; }
  .investments-plate {
    width: 956px; } }

@media screen and (max-width: 1240px) {
  .page-limiter {
    width: 964px; }
  .companies-sidebar {
    display: none; }
  .cabinet-sidebar {
    width: 260px; }
  .investments-content {
    width: 100%; }
  .investments-plate {
    width: 100%;
    overflow: auto; }
    .investments-plate .investments-table {
      min-width: 900px; }
  .page-header .page-header-limiter {
    width: 964px; }
  .page-header .page-header-logo {
    width: 150px; }
  .page-header .page-header-center .page-header-menu {
    margin-right: 0; }
    .page-header .page-header-center .page-header-menu button {
      margin-right: 34px; }
  .company-dictionary-layout .table-header,
  .company-about-layout .table-header {
    display: block; }
  .cabinet-breadcrumbs {
    display: flex; } }

@media screen and (max-width: 990px) {
  .page-limiter {
    width: auto;
    margin-left: 16px;
    margin-right: 16px; }
  .page-header .page-header-logo {
    width: 115px;
    flex: none;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0 48px 0 24px; }
  .page-header .page-header-userbar {
    width: 200px;
    flex: none;
    flex-grow: 0;
    flex-shrink: 0; }
    .page-header .page-header-userbar .userbar-button .icon {
      margin-right: 8px; }
    .page-header .page-header-userbar .userbar-button .text {
      max-width: 150px; }
  .page-header .page-header-center .page-header-menu {
    margin-right: 20px; }
    .page-header .page-header-center .page-header-menu button {
      margin-right: 16px; }
  .company-layout {
    display: block; }
    .company-layout .companies-sidebar {
      display: none; }
    .company-layout .company-page-left-col {
      margin-bottom: 32px; }
    .company-layout .company-page-right-col {
      margin-left: 0; }
    .company-layout .company-page-left-col,
    .company-layout .company-page-right-col {
      width: 100%; }
  .targets-layout .targets-content {
    width: 100%; } }

@media screen and (max-width: 840px) {
  .page-header .page-header-logo {
    margin-right: 32px; }
  .page-header .page-header-userbar {
    width: auto;
    margin-right: 24px;
    margin-left: 0; }
    .page-header .page-header-userbar .userbar-button .icon {
      top: 3px;
      width: 24px;
      height: 24px;
      margin-right: 0; }
    .page-header .page-header-userbar .userbar-button .text {
      display: none; }
  .page-header .page-header-feedback-bar {
    width: auto; }
    .page-header .page-header-feedback-bar .feedback-bar-button .icon {
      top: 3px;
      width: 24px;
      height: 24px; }
  .page-header .page-header-limiter {
    width: auto; }
  .page-header-menu button {
    margin-right: 20px;
    font-size: 14px; }
  .transactions-layout .company-page-right-col {
    display: none; }
  .analytics-section-container {
    width: 100%; }
    .analytics-section-container .analytics-graph {
      width: 100%;
      margin-bottom: 40px; }
      .analytics-section-container .analytics-graph .graph-container {
        max-width: 100%;
        width: 100%; }
      .analytics-section-container .analytics-graph .graph-chart {
        margin-bottom: 40px; }
  .cabinet-layout {
    justify-content: flex-start;
    margin-top: 70px; }
    .cabinet-layout .cabinet-sidebar {
      display: none; }
    .cabinet-layout .license-notification {
      margin-bottom: 24px; }
  .license-notification .notification-logo {
    display: none; }
  .license-notification .notification-actions {
    margin-right: 0; }
  .license-notification .notification-header {
    margin-top: 0; }
  .company-dictionary-layout .company-tab-form .form-actions {
    left: 0; } }

@media screen and (max-width: 760px) {
  .page-header .page-header-center .page-header-menu button {
    margin-right: 10px;
    font-size: 14px; } }

@media screen and (max-width: 720px) {
  .dashboard {
    padding-bottom: 64px; }
  .page-header .page-header-center {
    justify-content: flex-end;
    margin-left: auto;
    flex: 1; }
  .investments-plate {
    padding: 0; }
    .investments-plate .investments-table {
      min-width: 0; }
      .investments-plate .investments-table .investments-headers {
        display: none; }
      .investments-plate .investments-table .investments-list .investment-container {
        padding: 16px 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1); }
        .investments-plate .investments-table .investments-list .investment-container.opened .investment-item .list-item-col.icon-dropdown .dropdown-show {
          display: none; }
        .investments-plate .investments-table .investments-list .investment-container.opened .investment-item .list-item-col.icon-dropdown .dropdown-hide {
          display: inline-block; }
        .investments-plate .investments-table .investments-list .investment-container.opened .investment-item:after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 36px;
          width: 2px;
          height: 100%;
          padding-bottom: 28px;
          background: #bed6f9; }
        .investments-plate .investments-table .investments-list .investment-container.opened.color-1 .investment-item .list-item-col.caption:after {
          border-color: #bed6f9; }
        .investments-plate .investments-table .investments-list .investment-container.opened.color-1 .investment-item:after {
          background: #bed6f9; }
        .investments-plate .investments-table .investments-list .investment-container.opened.color-2 .investment-item .list-item-col.caption:after {
          border-color: #b9e398; }
        .investments-plate .investments-table .investments-list .investment-container.opened.color-2 .investment-item:after {
          background: #b9e398; }
        .investments-plate .investments-table .investments-list .investment-container.opened.color-3 .investment-item .list-item-col.caption:after {
          border-color: #e4e597; }
        .investments-plate .investments-table .investments-list .investment-container.opened.color-3 .investment-item:after {
          background: #e4e597; }
        .investments-plate .investments-table .investments-list .investment-container.opened.color-4 .investment-item .list-item-col.caption:after {
          border-color: #e7c740; }
        .investments-plate .investments-table .investments-list .investment-container.opened.color-4 .investment-item:after {
          background: #e7c740; }
        .investments-plate .investments-table .investments-list .investment-container.opened.color-5 .investment-item .list-item-col.caption:after {
          border-color: #e74040; }
        .investments-plate .investments-table .investments-list .investment-container.opened.color-5 .investment-item:after {
          background: #e74040; }
        .investments-plate .investments-table .investments-list .investment-container.opened.color-6 .investment-item .list-item-col.caption:after {
          border-color: #cf91af; }
        .investments-plate .investments-table .investments-list .investment-container.opened.color-6 .investment-item:after {
          background: #cf91af; }
        .investments-plate .investments-table .investments-list .investment-container.no-children.opened .investment-item:after {
          display: none; }
      .investments-plate .investments-table .investments-list .investment-item {
        position: relative;
        display: block;
        width: auto;
        padding-left: 70px; }
        .investments-plate .investments-table .investments-list .investment-item label {
          display: block;
          line-height: 18px;
          margin-bottom: 6px; }
        .investments-plate .investments-table .investments-list .investment-item .list-item-col {
          height: auto;
          line-height: 18px;
          padding: 0; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col p {
            line-height: 18px; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon {
            position: absolute;
            top: 0;
            left: 0;
            padding-left: 18px;
            z-index: 1; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.caption {
            line-height: 38px;
            padding-left: 0;
            margin-bottom: 12px; }
            .investments-plate .investments-table .investments-list .investment-item .list-item-col.caption p {
              line-height: 38px; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.sum, .investments-plate .investments-table .investments-list .investment-item .list-item-col.date, .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit {
            text-align: left; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.date {
            margin-bottom: 20px; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.profit {
            position: absolute;
            top: 50px;
            left: 50%; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.comment {
            padding-left: 0; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.edit {
            position: absolute;
            top: 0;
            right: 20px; }
          .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon-dropdown {
            position: absolute;
            right: 0;
            bottom: 0; }
            .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon-dropdown .dropdown-show {
              display: inline-block; }
      .investments-plate .investments-table .investments-list .investment-dropdown {
        padding-top: 16px; }
        .investments-plate .investments-table .investments-list .investment-dropdown .investment-item {
          padding: 16px 0 16px 70px; }
          .investments-plate .investments-table .investments-list .investment-dropdown .investment-item .list-item-col.caption {
            position: relative;
            overflow: visible; }
            .investments-plate .investments-table .investments-list .investment-dropdown .investment-item .list-item-col.caption p {
              padding: 0; }
            .investments-plate .investments-table .investments-list .investment-dropdown .investment-item .list-item-col.caption:after {
              content: "";
              display: block;
              position: absolute;
              top: 8px;
              left: -34px;
              width: 20px;
              height: 10px;
              border-left: 2px solid #bed6f9;
              border-bottom: 2px solid #bed6f9; }
          .investments-plate .investments-table .investments-list .investment-dropdown .investment-item .list-item-col.edit {
            top: 20px; }
          .investments-plate .investments-table .investments-list .investment-dropdown .investment-item .list-item-col.profit {
            top: 66px; }
          .investments-plate .investments-table .investments-list .investment-dropdown .investment-item.last-child:after {
            display: none; }
        .investments-plate .investments-table .investments-list .investment-dropdown .investment-actions {
          padding-left: 70px; }
  .targets-table .list-item.headers {
    display: none; }
  .targets-table .list-item {
    position: relative;
    display: block; }
    .targets-table .list-item .list-item-col {
      margin-bottom: 6px; }
      .targets-table .list-item .list-item-col.caption {
        margin-left: 0;
        margin-right: 40px;
        margin-bottom: 12px; }
        .targets-table .list-item .list-item-col.caption p {
          white-space: normal; }
      .targets-table .list-item .list-item-col.date {
        float: left;
        margin-right: 20px; }
        .targets-table .list-item .list-item-col.date label {
          display: block; }
      .targets-table .list-item .list-item-col.date_to label {
        display: block; }
      .targets-table .list-item .list-item-col.amount {
        text-align: left; }
      .targets-table .list-item .list-item-col.actions {
        position: absolute;
        top: 20px;
        right: 20px; }
  .cabinet-license-layout .layout-body {
    padding: 20px 10px; }
  .social-links {
    bottom: 70px;
    right: 16px; } }

@media screen and (max-width: 650px) {
  .transactions-layout .company-page-left-col {
    width: 100%; }
    .transactions-layout .company-page-left-col .operations-actions {
      width: auto; }
  .company-dictionary-layout .company-tab-form form {
    width: 100%; }
  .cabinet-license-layout .layout-body {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 0;
    margin: 0 -15px 30px -15px; }
  .analytics-bar {
    flex-direction: column; }
    .analytics-bar .bar-views,
    .analytics-bar .bar-actions {
      margin: 10px auto; } }

@media screen and (max-width: 590px) {
  .accent-page {
    background: none; }
    .accent-page .auth-float-container {
      position: relative;
      left: 0;
      top: 0;
      width: auto;
      margin: 0; }
    .accent-page .companies-layout .companies-header {
      border: 0;
      background: url(images/accent-plate.jpg) no-repeat #6880fc; }
    .accent-page .companies-layout .companies-action button {
      color: #4b4b4b; }
  .company-dictionary-layout .table-content .list-item {
    position: relative;
    display: block; }
    .company-dictionary-layout .table-content .list-item .list-item-col {
      white-space: normal;
      overflow: visible; }
      .company-dictionary-layout .table-content .list-item .list-item-col p {
        white-space: normal;
        overflow: visible;
        font-size: 14px;
        color: #4f4f4f; }
      .company-dictionary-layout .table-content .list-item .list-item-col.caption p, .company-dictionary-layout .table-content .list-item .list-item-col.label p {
        font-size: 15px;
        color: #4b4b4b; }
      .company-dictionary-layout .table-content .list-item .list-item-col.style {
        position: absolute;
        top: 20px;
        right: 40px; }
      .company-dictionary-layout .table-content .list-item .list-item-col.actions {
        position: absolute;
        top: 20px;
        right: 20px; }
  .company-dictionary-layout .table-content .list-headers {
    display: none; }
  .company-dictionary-layout .categories .table-content .list-item .list-item-col.label {
    padding-right: 120px; }
  .company-about-layout .tab-action {
    margin-bottom: 20px; }
  .targets-result-banner .popup-container {
    padding-bottom: 0; }
  .targets-result-banner .popup-content {
    height: 100%; }
    .targets-result-banner .popup-content .header {
      position: relative;
      height: 50%;
      padding: 0 20px; }
      .targets-result-banner .popup-content .header p {
        position: absolute;
        left: 0;
        bottom: 50px;
        width: 100%;
        line-height: 32px;
        text-align: center;
        font-size: 32px; }
      .targets-result-banner .popup-content .header .header-icon {
        position: absolute;
        bottom: 110px;
        left: 50%;
        margin-left: -30px;
        margin-bottom: 0; }
  .targets-result-banner .popup-buttons {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%; }
  .cabinet-license-layout .license-tariffs-list {
    flex-direction: column; }
    .cabinet-license-layout .license-tariffs-list .tariff-item {
      margin-bottom: 20px; } }

@media screen and (max-width: 500px) {
  .page-limiter {
    margin-top: 80px; }
    .page-limiter.company-layout {
      margin-top: 57px; }
    .page-limiter.cabinet-layout .page-body {
      max-width: 100%; }
  .operations-dated-list .operations-date {
    padding: 0 12px;
    margin: 0; }
  .operations-dated-list .operations-list-item {
    padding: 0 12px; }
    .operations-dated-list .operations-list-item .list-item-col.icon {
      display: none; }
    .operations-dated-list .operations-list-item .list-item-col.caption {
      margin-left: 0; }
      .operations-dated-list .operations-list-item .list-item-col.caption .text {
        font-size: 14px; }
  .company-layout .company-page-left-col .companies-header {
    height: 172px;
    margin-bottom: 25px;
    margin-left: -16px;
    margin-right: -16px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
    .company-layout .company-page-left-col .companies-header .companies-selector {
      top: 24px;
      left: 18px; }
      .company-layout .company-page-left-col .companies-header .companies-selector button {
        max-width: 100%;
        padding: 0 18px;
        font-size: 14px;
        text-align: left; }
    .company-layout .company-page-left-col .companies-header .companies-selector-popup {
      position: fixed;
      left: 0 !important;
      top: 56px !important;
      right: 0 !important;
      bottom: 50px !important;
      min-width: 300px !important;
      overflow: auto;
      z-index: 5;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      -ms-box-shadow: none;
      -o-box-shadow: none;
      box-shadow: none; }
      .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-limiter {
        width: 320px;
        margin: 0 auto; }
      .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-button {
        max-width: 80%;
        padding: 0 18px;
        margin: 25px 0 23px 18px;
        font-size: 14px;
        text-align: left; }
      .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-delim {
        margin: 16px 0; }
      .company-layout .company-page-left-col .companies-header .companies-selector-popup .checklist {
        padding-bottom: 6px; }
    .company-layout .company-page-left-col .companies-header .header-content {
      left: 18px;
      bottom: 16px; }
    .company-layout .company-page-left-col .companies-header .price-value {
      font-weight: normal; }
      .company-layout .company-page-left-col .companies-header .price-value .int,
      .company-layout .company-page-left-col .companies-header .price-value .delim {
        font-size: 38px;
        line-height: 38px; }
  .company-layout .company-page-left-col .operations .operations-header {
    padding: 24px 12px; }
  .company-layout .company-page-right-col .filter {
    text-align: center; }
  .companies-layout .companies-header {
    height: 105px;
    margin-bottom: 25px; }
    .companies-layout .companies-header .header-content {
      bottom: 16px;
      left: 24px; }
    .companies-layout .companies-header .price-value {
      font-weight: normal; }
      .companies-layout .companies-header .price-value .int,
      .companies-layout .companies-header .price-value .delim {
        font-size: 38px;
        line-height: 38px; }
  .companies-list .companies-list-item {
    padding: 16px 14px;
    margin-bottom: 12px; }
    .companies-list .companies-list-item .list-item-col.price {
      width: auto;
      margin-left: 10px; }
      .companies-list .companies-list-item .list-item-col.price .int,
      .companies-list .companies-list-item .list-item-col.price .delim {
        font-size: 17px; }
      .companies-list .companies-list-item .list-item-col.price .float,
      .companies-list .companies-list-item .list-item-col.price .unit {
        font-size: 12px; }
      .companies-list .companies-list-item .list-item-col.price .unit {
        margin-left: 4px; }
    .companies-list .companies-list-item .icon-content {
      width: 28px;
      height: 28px;
      line-height: 28px;
      font-size: 14px; }
    .companies-list .companies-list-item .list-item-col.caption {
      margin-left: 0;
      font-size: 14px;
      font-weight: normal; }
  .graphs-plate .graphs-income-outcome {
    font-size: 0; }
  .graphs-plate .graphs-delim {
    display: none; }
  .graphs-plate .graph-container {
    display: inline-block;
    width: 50%;
    padding: 24px 0;
    vertical-align: top; }
    .graphs-plate .graph-container .stats-graph-compact {
      padding: 0 12px; }
  .auth-float-container {
    width: 92%;
    margin-left: 0;
    left: 3%; }
  .operations-filter .filter-field.field-name-search_string {
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
    .operations-filter .filter-field.field-name-search_string .filter-control input {
      width: 100%;
      box-sizing: border-box; }
  .operations-filter .filter-field.field-name-date_range, .operations-filter .filter-field.field-name-accounts {
    width: 100%;
    margin-right: 0; }
  .operations-filter .filter-field.field-name-partners, .operations-filter .filter-field.field-name-categories {
    width: 100%; }
  .operations-filter .filter-field.field-name-price_range {
    width: 100%;
    margin-right: 0; }
    .operations-filter .filter-field.field-name-price_range .range-control {
      display: flex; }
    .operations-filter .filter-field.field-name-price_range .range-delim {
      flex: 1; }
  .company-payment-accounts .account-list-item {
    padding: 6px 10px; }
    .company-payment-accounts .account-list-item .icon .icon-content {
      width: 28px;
      height: 28px;
      line-height: 28px;
      font-size: 14px; }
    .company-payment-accounts .account-list-item .list-item-col.caption {
      margin-left: 0; }
    .company-payment-accounts .account-list-item .price .int,
    .company-payment-accounts .account-list-item .price .delim {
      font-size: 17px; }
    .company-payment-accounts .account-list-item .price .float,
    .company-payment-accounts .account-list-item .price .unit {
      font-size: 12px; }
    .company-payment-accounts .account-list-item .price .unit {
      margin-left: 4px; }
  .company-payment-accounts .accounts-action {
    margin-left: 35px; }
  .company-layout .operations-actions,
  .transactions-layout .operations-actions {
    margin-bottom: 20px; }
    .company-layout .operations-actions button,
    .transactions-layout .operations-actions button {
      margin-bottom: 12px; }
  .cabinet-settings-layout .layout-title p {
    font-size: 17px;
    line-height: 22px; }
  .cabinet-settings-layout .layout-body {
    padding: 30px 20px; }
  .license-notification {
    flex-direction: column; }
    .license-notification .notification-header {
      margin: 0; }
    .license-notification .notification-actions {
      margin: 0; }
  .cabinet-layout .companies-selector-container
.companies-selector-popup
.selector-action-container {
    margin-left: 50px; }
  .cabinet-company .company-title-container .caption-editor .form-fieldset {
    flex-wrap: wrap; }
    .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-text {
      flex: 0 0 100%;
      margin-bottom: 10px;
      margin-right: 0; }
    .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-submit, .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-button {
      margin: 0; }
      .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-submit .form-button, .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-button .form-button {
        height: 40px;
        line-height: 38px;
        font-size: 14px;
        font-weight: normal;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px; }
    .cabinet-company .company-title-container .caption-editor .form-fieldset .form-field.field-type-submit {
      margin-right: 10px; }
  .cabinet-company .body-tabs {
    width: 100%;
    overflow: auto;
    padding: 0; }
    .cabinet-company .body-tabs .body-tabs-scroller {
      white-space: nowrap; }
  .cabinet-company .body-tab-content.categories .company-tab-table .list-item .list-item-col.label {
    margin-right: 120px; }
  .cabinet-company .company-tab-about {
    padding: 0; }
  .cabinet-company .company-tab-table .tab-action {
    margin-left: 16px;
    margin-bottom: 12px; }
  .cabinet-company .company-tab-table .list-item {
    position: relative;
    flex-direction: column;
    padding: 12px 16px; }
    .cabinet-company .company-tab-table .list-item .list-item-col.label p {
      font-size: 16px;
      font-weight: normal; }
    .cabinet-company .company-tab-table .list-item .list-item-col.style {
      position: absolute;
      top: 11px;
      right: 20px; }
    .cabinet-company .company-tab-table .list-item .list-item-col.amount {
      text-align: left; }
    .cabinet-company .company-tab-table .list-item .list-item-col.actions {
      position: absolute;
      top: 15px;
      right: 0; }
    .cabinet-company .company-tab-table .list-item .list-item-col:not(.label) p {
      font-size: 14px;
      opacity: 0.8; }
    .cabinet-company .company-tab-table .list-item.list-headers {
      display: none; }
  .cabinet-company .company-tab-item-card {
    padding: 0; }
  .cabinet-add-company-layout .layout-body {
    padding: 24px 16px; }
  .cabinet-add-company-layout .add-company-form .form-submit-button button {
    min-width: 0;
    width: 100%; }
  .investments-actions {
    margin-bottom: 20px; }
  .analytics-filter .filter-label {
    line-height: 12px;
    font-size: 12px; }
  .analytics-filter .filter-field {
    margin: 0 0 20px 0;
    width: 100%; }
  .analytics-filter .filter-datepicker .filter-select-button {
    height: 28px;
    line-height: 28px;
    font-size: 16px; }
  .analytics-filter .filter-checklist .checklist-value-button {
    height: 28px;
    line-height: 28px;
    font-size: 16px; }
  .analytics-section-container .analytics-plate {
    padding: 20px 16px; }
  .analytics-bar .bar-views button.print,
  .analytics-bar .bar-actions button.print {
    display: none; }
  .analytics-graph {
    margin-bottom: 20px; }
    .analytics-graph .graph-header {
      margin-bottom: 20px; }
    .analytics-graph .graph-chart {
      margin-bottom: 20px; }
      .analytics-graph .graph-chart .pie-chart {
        width: 140px;
        height: 140px; }
      .analytics-graph .graph-chart .pie-chart-empty {
        width: 120px;
        height: 120px;
        border-width: 14px; }
    .analytics-graph .chart-note {
      width: 120px; }
    .analytics-graph .graph-markers {
      margin-bottom: 12px; }
    .analytics-graph .markers-list-item {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 15px; }
      .analytics-graph .markers-list-item .list-item-col.caption {
        font-size: 16px;
        margin-left: 0; }
      .analytics-graph .markers-list-item .value {
        margin-left: 0;
        color: #4b4b4b;
        font-size: 14px; }
      .analytics-graph .markers-list-item .icon {
        margin-bottom: 5px; }
  .company-dictionary-layout .company-tab-form form .form-field.field-name-income-style, .company-dictionary-layout .company-tab-form form .form-field.field-name-outcome-style {
    margin-top: -47px; } }

@media screen and (max-width: 450px) {
  .dashboard:not(.accent-page) {
    background: none; }
  .page-header .page-header-logo {
    margin-left: 14px; }
  .company-layout .operations,
  .transactions-layout .operations {
    margin-top: 0; }
  .company-layout .operations-actions-container,
  .transactions-layout .operations-actions-container {
    height: 0;
    margin-bottom: 0; }
  .company-layout .operations-actions,
  .transactions-layout .operations-actions {
    position: fixed;
    bottom: 50px;
    top: auto !important;
    left: 0;
    right: 0;
    width: auto !important;
    padding: 0 !important;
    text-align: center;
    z-index: 1; }
    .company-layout .operations-actions button,
    .transactions-layout .operations-actions button {
      height: 40px;
      line-height: 40px; }
    .company-layout .operations-actions .action-add-operation,
    .transactions-layout .operations-actions .action-add-operation {
      line-height: 44px; }
      .company-layout .operations-actions .action-add-operation .action-label,
      .transactions-layout .operations-actions .action-add-operation .action-label {
        display: none; }
      .company-layout .operations-actions .action-add-operation .action-icon,
      .transactions-layout .operations-actions .action-add-operation .action-icon {
        width: 29px;
        height: 16px;
        background: url(images/add-operation-mobile.png) no-repeat center center;
        display: inline-block;
        background-size: contain; }
    .company-layout .operations-actions .action-upload,
    .transactions-layout .operations-actions .action-upload {
      display: none; }
  .auth-float-container {
    top: 7%;
    margin-top: 0; }
    .auth-float-container .auth-form {
      margin-bottom: 50px; }
  .auth-links .auth-link {
    margin-bottom: 20px; }
  .cabinet-company .body-tab-content {
    padding: 0; }
    .cabinet-company .body-tab-content .company-tab-form {
      margin: 0; }
    .cabinet-company .body-tab-content .company-tab-table .list-item {
      padding-right: 0;
      padding-left: 0; }
    .cabinet-company .body-tab-content .company-tab-table .tab-action {
      margin-left: 0; }
  .analytics-section-container .analytics-plate {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 0; }
  .analytics-graph .graph-header {
    text-align: center; }
    .analytics-graph .graph-header .header-title {
      margin-right: 10px; }
  .analytics-graph .graph-chart .pie-chart,
  .analytics-graph .graph-chart .pie-chart-empty {
    margin: 0 auto; }
  .analytics-graph .graph-chart .chart-note {
    width: auto; }
  .transactions-layout {
    margin-left: 0;
    margin-right: 0; }
    .transactions-layout .company-page-left-col .operations {
      background: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      -ms-box-shadow: none;
      -o-box-shadow: none;
      box-shadow: none;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0; }
      .transactions-layout .company-page-left-col .operations .operations-list-item,
      .transactions-layout .company-page-left-col .operations .operations-date {
        padding: 0 20px; }
    .transactions-layout .operations-actions-container.sticky .operations-actions {
      background: none;
      box-shadow: none; }
  .datepicker-popup {
    width: auto;
    padding: 19px 0; }
  .add-income-popup .popup-container .header,
  .add-outcome-popup .popup-container .header,
  .edit-operation-popup .popup-container .header,
  .upload-popup .popup-container .header,
  .add-company-popup .popup-container .header {
    padding: 28px 24px 26px 65px;
    font-size: 16px; }
  .company-layout .company-page-left-col .companies-header .companies-selector-popup .selector-limiter {
    width: auto;
    margin: 0 20px; }
  .company-layout .operations-actions-container.sticky .operations-actions {
    background: none;
    box-shadow: none; }
  .company-about-layout .about-content {
    padding: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
  .company-dictionary-layout .table-content {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none; }
    .company-dictionary-layout .table-content .list-item {
      padding: 20px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 0; }
  .company-dictionary-layout .table-filter {
    padding: 20px 10px; }
    .company-dictionary-layout .table-filter .filter-field {
      width: 100%; }
      .company-dictionary-layout .table-filter .filter-field input {
        width: 100%;
        box-sizing: border-box; }
  .company-dictionary-layout .layout-actions .tab-action {
    margin-bottom: 10px; }
  .investments-layout {
    margin-left: 0;
    margin-right: 0; }
    .investments-layout .investments-actions {
      margin-right: 16px;
      margin-left: 16px; }
  .investments-plate {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
    .investments-plate .investments-table .investments-list .investment-item .list-item-col.icon {
      padding-left: 17px; }
  .targets-table .list-item {
    padding: 16px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: none; }
    .targets-table .list-item .list-item-col.actions {
      top: 16px;
      right: 0; }
  .cabinet-breadcrumbs .breadcrumbs-item {
    padding-left: 10px; }
  .targets-layout,
  .company-dictionary-layout {
    margin-right: 0;
    margin-left: 0; }
  .company-dictionary-layout .company-tab-form {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0; }
    .company-dictionary-layout .company-tab-form form {
      margin-right: 0; }
    .company-dictionary-layout .company-tab-form .form-actions {
      display: block;
      text-align: center; }
  .company-dictionary-layout .dictionary-content.layout-form .layout-title,
  .company-dictionary-layout .dictionary-content.layout-form .layout-actions {
    display: none; }
  .cabinet-license-layout .layout-body {
    margin: 0 0 40px 0; }
  .cabinet-license-layout .orders-list .list-item {
    padding: 20px 0; }
  .cabinet-license-layout .license-tariffs-list .tariff-item {
    background: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 30px 0;
    margin: 0;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .cabinet-license-layout .license-tariffs-list .tariff-item .button {
      margin-top: 30px; } }

.print-section {
  display: none; }

@media print {
  .print-section {
    display: block; }
    .print-section .section-header {
      margin: 30px 0;
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
      text-align: center; }
  .companies-sidebar,
  .page-header,
  .analytics-filter,
  .bar-views,
  .bar-actions,
  .social-links {
    display: none; }
  .page-limiter {
    margin: 0; }
  .dashboard,
  .analytics-plate {
    background: none; } }
